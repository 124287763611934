import { ApolloError } from '@apollo/client';
import Raven from 'raven-js';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ERROR_FALLBACK_KEY, IS_PRODUCTION } from '../../lib/constants';
import { GET_COLOR_DANGER_1, GET_COLOR_MAIN_2, GET_PADDING_2 } from '../../lib/themeGetters';
import { I18nFn } from '../I18nProvider/I18nProvider';

interface IProps {
  error?: ApolloError | null;
  className?: string;
  closable?: boolean;
  isFull?: boolean;
  i18n: I18nFn;
}

const Heading4 = styled.h5`
  color: inherit;
`;

const Heading6 = styled.h6`
  color: inherit;
`;

const Wrapper = styled.div`
  /* display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border: 1px ${GET_COLOR_DANGER_1} solid;
  padding: ${GET_PADDING_2}; */
`;

const BlockContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: ${GET_PADDING_2};
  background-color: ${GET_COLOR_MAIN_2};
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

const ErrorIcon = () => {
  return (
    <svg width="100" height="91" viewBox="0 0 100 91" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M48.3285 4.16754C49.0893 2.79071 51.0685 2.7907 51.8294 4.16754L96.518 85.0326C97.2547 86.3656 96.2905 88 94.7676 88H5.39035C3.86736 88 2.90321 86.3656 3.63986 85.0326L48.3285 4.16754Z"
        stroke="#F66700"
        strokeWidth="6"
        strokeLinejoin="round"
      />
      <circle cx="50.0789" cy="71.9739" r="6.86842" fill="#F66700" />
      <path d="M50.079 28.4736V55.9473" stroke="#F66700" strokeWidth="6" strokeLinecap="round" />
    </svg>
  );
};

class ErrorMessageBlock extends React.Component<IProps> {
  private joinMessages = (arr: any) => {
    return arr
      .map((err: any) => (typeof err?.message === 'string' ? err.message : null))
      .filter((message: string | null) => message !== null)
      .map((message: string, index: number) => (
        <div>
          {index + 1}. {message}
        </div>
      ));
  };

  componentDidMount() {
    const { error } = this.props;
    if (error && IS_PRODUCTION) {
      Raven.captureException(error.message);
    }
  }

  private getMessageFromError = (error: ApolloError) => {
    const { networkError, graphQLErrors, message } = error;

    if (networkError && graphQLErrors.length === 0) {
      console.warn('Network error:\n', networkError);
      const message = `${networkError.message}\n`;

      // @ts-ignore
      if (networkError.result && networkError.result.errors) {
        return {
          title: message,
          // @ts-ignore
          description: this.joinMessages(networkError.result.errors),
        };
      }

      return {
        title: this.props.i18n('network_error'),
        description: message,
      };
    }

    if (graphQLErrors.length === 1) {
      const [exactError] = graphQLErrors;

      const code = exactError.extensions && exactError.extensions.code;
      const title = this.props.i18n(code || ERROR_FALLBACK_KEY);
      const description = exactError.message;

      return { title, description };
    }

    return {
      title: message,
      description: graphQLErrors.length ? this.joinMessages(graphQLErrors) : undefined,
    };
  };

  private getMessage = () => {
    const { error, i18n } = this.props;

    let title = i18n(ERROR_FALLBACK_KEY);
    let description = i18n('no_error_details_found');

    if (error) {
      const message = this.getMessageFromError(error);

      if (message.title) {
        title = message.title;
      }

      if (message.description) {
        description = message.description;
      }
    }

    return {
      title: title,
      description: description,
    };
  };

  private getClassName = (): string => {
    const { className, isFull } = this.props;
    const classNames = [];

    if (className) {
      classNames.push(className);
    }

    if (isFull) {
      classNames.push('h-100', 'w-100', 'd-flex', 'justify-content-center', 'align-items-center');
    }

    return classNames.join(' ');
  };

  public render() {
    const { error } = this.props;
    if (!error) {
      return null;
    }

    // const { title, description } = this.getMessage();
    const code = error.graphQLErrors?.[0]?.extensions?.code;
    const className = this.getClassName();
    const header = this.props.i18n('error_message');
    // const AlertComp = closable ? UncontrolledAlert : Alert;
    const message = this.getMessage();

    return (
      <Wrapper className={className}>
        <BlockContainer>
          <Heading4>{header}</Heading4>
          <Heading6 className="mb-0">
            {code && code === 'INTERNAL_SERVER_ERROR'
              ? message.description
              : this.props.i18n(code) || message.description}
          </Heading6>
          <div className="mt-3">
            <ErrorIcon />
          </div>
        </BlockContainer>
      </Wrapper>
    );
  }
}

const ErrorMessage: React.FC<Omit<IProps, 'i18n'>> = props => {
  const { error, className, closable, isFull } = props;
  const { t } = useTranslation();
  return <ErrorMessageBlock error={error} className={className} closable={closable} isFull={isFull} i18n={t} />;
};

export default ErrorMessage;
