import { ApolloError, MutationResult, QueryResult } from '@apollo/client';
import { useCallback } from 'react';

export interface IInfoFromResult {
  isLoading: boolean;
  error: ApolloError | null;
}

export type GetInfoFromResultsFn = (results: (MutationResult<any> | QueryResult<any, any>)[]) => IInfoFromResult;

export const useGetInfoFromResults = (): GetInfoFromResultsFn => {
  return useCallback(results => {
    const loadingStates = results.map(result => result.loading);
    const errors = results.map(result => result.error);

    return {
      isLoading: loadingStates.includes(true),
      error: errors.find(err => err !== null) ?? null,
    };
  }, []);
};
