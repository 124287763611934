import React from 'react';
import { useTheme } from 'styled-components';

interface IProps {
  color?: string;
  width?: number;
  height?: number;
}

const CartIcon: React.FC<IProps> = props => {
  const theme = useTheme();
  const { width = 24, height = 24 } = props;
  const color = props.color || theme.colors.secondary1;
  return (
    <svg width={width} height={height} viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.21 7.00002L11.83 0.44002C11.64 0.16002 11.32 0.0200195 11 0.0200195C10.68 0.0200195 10.36 0.16002 10.17 0.45002L5.79 7.00002H1C0.45 7.00002 0 7.45002 0 8.00002C0 8.09002 0.01 8.18002 0.04 8.27002L2.58 17.54C2.81 18.38 3.58 19 4.5 19H17.5C18.42 19 19.19 18.38 19.43 17.54L21.97 8.27002L22 8.00002C22 7.45002 21.55 7.00002 21 7.00002H16.21ZM8 7.00002L11 2.60002L14 7.00002H8ZM9 13C9 14.1 9.9 15 11 15C12.1 15 13 14.1 13 13C13 11.9 12.1 11 11 11C9.9 11 9 11.9 9 13Z"
        fill={color}
      />
    </svg>
  );
};

export default React.memo(CartIcon);
