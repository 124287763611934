import React from 'react';
import { useTheme } from 'styled-components';

interface IProps {
  color?: string;
  width?: number;
  height?: number;
}

const DotsIcon: React.FC<IProps> = props => {
  const { width = 6, height = 24 } = props;
  const theme = useTheme();
  const color = props.color || theme.colors.secondary1;
  return (
    <svg width={width} height={height} viewBox="0 0 6 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 5C4.375 5 5.5 3.875 5.5 2.5C5.5 1.125 4.375 0 3 0C1.625 0 0.5 1.125 0.5 2.5C0.5 3.875 1.625 5 3 5ZM3 7.5C1.625 7.5 0.5 8.625 0.5 10C0.5 11.375 1.625 12.5 3 12.5C4.375 12.5 5.5 11.375 5.5 10C5.5 8.625 4.375 7.5 3 7.5ZM0.5 17.5C0.5 16.125 1.625 15 3 15C4.375 15 5.5 16.125 5.5 17.5C5.5 18.875 4.375 20 3 20C1.625 20 0.5 18.875 0.5 17.5Z"
        fill={color}
      />
    </svg>
  );
};

export default React.memo(DotsIcon);
