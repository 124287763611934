import React from 'react';
import { useTheme } from 'styled-components';

interface IProps {
  color?: string;
  width?: number;
  height?: number;
}

const Sun: React.FC<IProps> = props => {
  const { width = 24, height = 24 } = props;
  const theme = useTheme();
  const color = props.color || theme.colors.secondary1;
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12" r="6" fill={color} />
      <path d="M12 4V2" stroke={color} strokeWidth="2" strokeLinecap="round" />
      <path d="M18 6L19 5" stroke={color} strokeWidth="2" strokeLinecap="round" />
      <path d="M6 6L5 5" stroke={color} strokeWidth="2" strokeLinecap="round" />
      <path d="M19 19L18 18" stroke={color} strokeWidth="2" strokeLinecap="round" />
      <path d="M5 19L6 18" stroke={color} strokeWidth="2" strokeLinecap="round" />
      <path d="M12.0001 22L11.9999 20" stroke={color} strokeWidth="2" strokeLinecap="round" />
      <path d="M20 12H22" stroke={color} strokeWidth="2" strokeLinecap="round" />
      <path d="M2.02002 12H3.99988" stroke={color} strokeWidth="2" strokeLinecap="round" />
    </svg>
  );
};

export default React.memo(Sun);
