import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import { Menu, X } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import {
  GET_COLOR_CONTRAST_1,
  GET_COLOR_MAIN_2,
  GET_COLOR_PRIMARY_1,
  GET_FONT_WEIGHT_SEMI_BOLD,
  GET_PADDING_3,
  GET_PADDING_4,
} from '../../lib/themeGetters';
import Col from '../Col/Col';
import HeaderCart from '../HeaderCart/HeaderCart';
import HeaderLangToggle from '../HeaderLangToggle/HeaderLangToggle';
import HeaderUser from '../HeaderUser/HeaderUser';
import { Title } from '../Rcm/Rcm';
import Row from '../Row/Row';
import ThemeToggle from '../ThemeToggle/ThemeToggle';

interface IProps {
  className?: string;
}

const TRANSITION_TIME = 500;

const BurgerOverlay = styled.div<{ $isOpened?: boolean }>`
  position: fixed;
  z-index: 1500;
  top: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  right: ${p => (p.$isOpened ? '1vw' : '-100vw')};
  background-color: ${p => (p.$isOpened ? 'rgba(0, 0, 0, 0.5)' : 'rgba(0, 0, 0, 0)')};
  width: 100vw;
  height: 100vh;
  transition: background-color ${TRANSITION_TIME}ms ease;
`;

const StyledBurgerContainer = styled.div<{ $isOpened?: boolean }>`
  background-color: ${GET_COLOR_MAIN_2};
  overflow-y: auto;
  right: 0;
  top: 0;
  width: 90%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  padding-top: 1rem;
  position: fixed;
  transition: right ${TRANSITION_TIME}ms ease;
  right: ${p => (p.$isOpened ? '1vw' : '-200vw')};
  @media (min-width: ${p => p.theme.breakpoints.md}px) {
    width: 45%;
  }
`;

const StyledBurgerContent = styled.div`
  z-index: 1501;
  flex: 1;
  display: flex;
  justify-self: center;
  flex-direction: column;
  margin-top: 2rem;
`;

const NavLabel = styled.span`
  font-size: 1.2em;
  font-weight: ${GET_FONT_WEIGHT_SEMI_BOLD};
`;

const MenuItem = styled(NavLink)<{ $isLast?: boolean }>`
  display: block;
  color: ${GET_COLOR_CONTRAST_1};
  font-weight: ${GET_FONT_WEIGHT_SEMI_BOLD};
  &.active {
    color: ${GET_COLOR_PRIMARY_1};
  }
`;

const Header = styled(Row)`
  // padding: calc(${GET_PADDING_3} * 0.75) ${GET_PADDING_4} !important;
`;

const NavBurger: React.FC<IProps> = props => {
  const { className } = props;
  const [opened, setOpened] = useState(false);
  const { t } = useTranslation();
  const parent = useMemo(() => document.getElementById('root'), []);

  const toggleNav = useCallback(() => {
    if (opened) {
      setTimeout(() => {
        document.body.className = '';
      }, 0);
    } else {
      document.body.className = 'overflow-hidden';
    }

    setOpened(!opened);
  }, [opened]);

  const navs = useMemo(
    () => [
      { to: '/tickets', label: t('label_tickets') },
      { to: '/tours', label: t('label_tours') },
      { to: '/membership', label: t('label_membership') },
      { to: '/parkings', label: t('label_parkings') },
      { to: '/loyalty', label: t('label_loyalty') },
      { to: '/family-sector', label: t('label_familySector') },
      { to: '/profile', label: t('label_profile') },
    ],
    [t]
  );

  const renderCloseButton = useCallback(() => {
    return (
      <div className="d-flex align-items-start justify-content-end">
        <X className="cursor-pointer" />
      </div>
    );
  }, []);

  const renderMobileLeft = useCallback(
    () => (
      <div className="d-flex flex-grow-1 align-items-center">
        <HeaderCart />
        <div className="ml-4 d-flex align-items-center">
          <HeaderUser />
        </div>
      </div>
    ),
    []
  );

  const renderLangToggle = useCallback(() => <HeaderLangToggle contentClassName="d-sm-none d-flex" />, []);

  const renderMobileRight = useCallback(
    () => (
      <div className="d-flex flex-grow-1 align-items-center">
        <ThemeToggle />
        <div className="ml-4 d-flex align-items-center">{renderLangToggle()}</div>
      </div>
    ),
    [renderLangToggle]
  );

  const renderMobileNavBlock = useCallback(
    () => (
      <Row className="mt-3 mx-4 d-sm-none d-flex">
        <Col className="d-flex align-items-center justify-content-start" xs={6}>
          {renderMobileLeft()}
        </Col>
        <Col className="d-flex align-items-center justify-content-end" xs={6}>
          {renderMobileRight()}
        </Col>
      </Row>
    ),
    [renderMobileLeft, renderMobileRight]
  );

  const handleNavigation = useCallback(() => {
    toggleNav();
  }, [toggleNav]);

  const renderElement = useCallback(
    (item: { to: string; label: string }) => {
      const { to, label } = item;
      return (
        <MenuItem key={label} onClick={handleNavigation} className="px-5 my-3" to={to}>
          <NavLabel>{label}</NavLabel>
        </MenuItem>
      );
    },
    [handleNavigation]
  );

  const renderHeader = useCallback(
    () => (
      <Header className="mx-4">
        <Col xs={9}>
          <div className="mx-1">
            <Title>{t('h_menu')}</Title>
          </div>
        </Col>
        <Col className="d-flex justify-content-end" xs={3}>
          {renderCloseButton()}
        </Col>
      </Header>
    ),
    [renderCloseButton, t]
  );

  const child = useMemo(
    () => (
      <BurgerOverlay onClick={toggleNav} $isOpened={opened}>
        <StyledBurgerContainer $isOpened={opened}>
          {renderHeader()}
          {renderMobileNavBlock()}
          <StyledBurgerContent>{navs.map(renderElement)}</StyledBurgerContent>
        </StyledBurgerContainer>
      </BurgerOverlay>
    ),
    [navs, opened, renderElement, renderHeader, renderMobileNavBlock, toggleNav]
  );

  const el = useMemo(() => document && document.createElement('div'), []);

  const Portal = useMemo(() => (parent && el ? ReactDOM.createPortal(child, el) : null), [child, el, parent]);

  useEffect(() => {
    if (!parent || !el) {
      return;
    }
    parent.appendChild(el);

    return () => {
      if (!parent) {
        return;
      }

      parent.removeChild(el);
    };
  }, [el, parent]);

  return (
    <div className={className}>
      <Menu width={28} height={28} onClick={toggleNav} />
      {Portal}
    </div>
  );
};

export default React.memo(NavBurger);
