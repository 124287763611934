import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { FACEBOOK_LINK, INSTAGRAM_LINK, VK_LNIK } from '../../lib/constants';
import Card from '../Card/Card';
import Col from '../Col/Col';
import Container from '../Container/Container';
import Row from '../Row/Row';

const Circle = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 32px;
  margin-left: 8px;
  margin-right: 8px;
  cursor: pointer;
`;

const SponsorsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  padding-top: 10px;

  @media (max-width: ${p => p.theme.breakpoints.sm}px) {
    flex-direction: column;
    align-items: flex-start
  }
`

const SponsorImage = styled.img`
  cursor: pointer;
  max-width: 115px;
  @media (max-width: ${p => p.theme.breakpoints.sm}px) {
    margin: 10px 0;
  }
`

const sponsors = [
  [{
    name: 'rosseti',
    link: 'https://www.rosseti.ru/'
  },
  {
    name: 'rosseti_msk',
    link: 'https://rossetimr.ru/'
  },
  {
    name: 'rosseti_center',
    link: 'https://www.mrsk-1.ru/'
  },
  {
    name: 'rosseti_tumen',
    link: 'https://www.te.ru/'
  },
  {
    name: 'rosseti_volga',
    link: 'https://www.rossetivolga.ru/'
  }],
  [
    {
      name: 'veb_green',
      link: 'https://veb.ru/'
    },
    {
      name: 'apotheka',
      link: 'https://goldnapotheka.com/'
    },
    {
      name: 'russia_post_blue-2023',
      link: 'https://www.pochta.ru/'
    },
    {
      name: 'psb',
      link: 'https://www.psbank.ru/'
    },
    {
      name: 'dr_diesel',
      link: 'https://doctordiesel.ru/'
    },
  ]
]

interface IProps { }

interface IFooterLink {
  text: string;
  link: string;
}

const StyledFooter = styled.footer`
  margin-top: auto;
`;

const Footer: React.FC<IProps> = props => {
  const { t } = useTranslation();

  const handleSocialClick = useCallback(
    (network: 'instagram' | 'facebook' | 'vk') => () => {
      let path: string = '/';
      if (network === 'facebook') {
        path = FACEBOOK_LINK;
      }
      if (network === 'instagram') {
        path = INSTAGRAM_LINK;
      }
      if (network === 'vk') {
        path = VK_LNIK;
      }
      // @ts-ignore
      window.open(path, '_blank').focus();
    },
    []
  );

  const renderInfo = useCallback(() => {
    const year = new Date().getFullYear();

    return (
      <div>
        <NavLink to={'/'}>
          2001—{year} © {t('footer_coNavLinkyright_text_without_years')}
        </NavLink>
        <div style={{ display: 'flex', marginTop: '24px' }}>
          {/* <Circle
            onClick={handleSocialClick('instagram')}
            src="/images/icons/instIcon.png"
            srcSet="/images/icons/instIcon@2x.png 2x, /images/icons/instIcon@3x.png 3x"
            alt="instagram"
          />
          <Circle
            onClick={handleSocialClick('facebook')}
            src="/images/icons/fbIcon.png"
            srcSet="/images/icons/fbIcon@2x.png 2x, /images/icons/fbIcon@3x.png 3x"
            alt="facebook"
          /> */}
          <Circle
            onClick={handleSocialClick('vk')}
            src="/images/icons/vkIcon.png"
            srcSet="/images/icons/vkIcon@2x.png 2x, /images/icons/vkIcon@3x.png 3x"
            alt="vk"
          />
        </div>
      </div>
    );
  }, [handleSocialClick, t]);

  const renderLink = useCallback((item: IFooterLink, index: number) => {
    return (
      <div className="mb-2" key={index}>
        <NavLink to={item.link}>{item.text}</NavLink>
      </div>
    );
  }, []);

  const renderLinks = useCallback(() => {
    const links: IFooterLink[] = [
      {
        text: t('policy'),
        link: '/policy',
      },
      {
        text: t('match_policy'),
        link: '/match-policy',
      },
      {
        text: t('match_rules'),
        link: '/match-rules',
      },
    ];

    return <div className="text-right">{links.map(renderLink)}</div>;
  }, [renderLink, t]);

  const handleSponsorClick = useCallback((link: string) => () => {
    // @ts-ignore
    window.open(link, '_blank').focus();
  }, [])

  return (
    <StyledFooter>

      <Container>
        <Card noPadding>
          <div style={{ paddingTop: 20 }}>
            {sponsors.map(row => {
            return (<SponsorsContainer>
              {row.map(i => (
                <Col style={{ textAlign: 'center' }}>
                  <SponsorImage key={i.name} onClick={handleSponsorClick(i.link)} src={`/images/sponsors/${i.name}.png`} alt={i.name}></SponsorImage>
                </Col>
              ))}
              </SponsorsContainer>
            )
            })}
            </div>
        </Card>
        <Row>
          <Col xs={12}>
            <div className="d-lg-flex justify-content-between align-items-start pb-5 pt-2">
              {renderInfo()}
              {renderLinks()}
            </div>
          </Col>
        </Row>
      </Container>
    </StyledFooter>
  );
};

export default React.memo(Footer);
