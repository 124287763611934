import React, { PropsWithChildren, useCallback, useMemo, useRef } from 'react';
import { TimerContext } from '../../contexts/contexts';
import { DEFAULT_RESET_TIMEOUT } from '../../lib/constants';
import { getFromLocalStorage, LocalStorageMap, writeToLocalStorage } from '../../lib/localStorage';

const STORAGE_KEY = 'resetTimeout';

interface IProps {
  storageKey?: keyof LocalStorageMap;
}

const TimerProvider: React.FC<PropsWithChildren<IProps>> = props => {
  const { storageKey, children } = props;

  const currentStorageKey = useMemo(() => storageKey || STORAGE_KEY, [storageKey]);
  const [timer, setTimer] = React.useState(0);
  const timerRef = useRef<{ timer: NodeJS.Timeout | null; hydrated: boolean }>({ timer: null, hydrated: false })
    .current;

  const writeTimer = useCallback((time: number) => {
    setTimer(time);
  }, []);

  const runTimer = React.useCallback(
    (oldTime?: number) => {
      let time = oldTime || DEFAULT_RESET_TIMEOUT;
      writeTimer(time);
      if (!oldTime) {
        writeToLocalStorage(currentStorageKey, Date.now());
      }

      timerRef.timer = setInterval(() => {
        if (time <= 1) {
          writeTimer(0);
          if (timerRef.timer) {
            clearInterval(timerRef.timer);

            timerRef.timer = null;
          }
          writeToLocalStorage(currentStorageKey, null);
          return;
        }

        time -= 1;
        setTimer(time);
      }, 1000);
    },
    [currentStorageKey, timerRef, writeTimer]
  );

  const clearTimer = useCallback(() => {
    if (timerRef.timer) {
      clearInterval(timerRef.timer);
      writeTimer(0);
    }
  }, [timerRef, writeTimer]);

  const hydrateStoredTimer = useCallback(() => {
    timerRef.hydrated = true;
    const storedTimer = getFromLocalStorage(currentStorageKey);
    const currentDate = Date.now();
    if (typeof storedTimer !== 'number') {
      return;
    }
    const timeDiff = Math.round((currentDate - storedTimer) / 1000);
    if (timeDiff > DEFAULT_RESET_TIMEOUT) {
      return;
    }
    const timeout = DEFAULT_RESET_TIMEOUT - timeDiff;
    const timeoutToShow = timeout <= 0 ? undefined : timeout;
    runTimer(timeoutToShow);
  }, [currentStorageKey, runTimer, timerRef]);

  const startTimer = useCallback(() => {
    if (timerRef.timer) {
      return;
    }
    runTimer();
  }, [runTimer, timerRef]);

  if (!timerRef.hydrated) {
    hydrateStoredTimer();
  }

  const ctx = useMemo(() => ({ startTimer, timer, clearTimer, isTimerEnabled: timer > 0 }), [
    startTimer,
    timer,
    clearTimer,
  ]);

  return <TimerContext.Provider value={ctx}>{children}</TimerContext.Provider>;
};

export default React.memo(TimerProvider);
