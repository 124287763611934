import { memo } from 'react';
import { Modal as StrapModal, Popover } from 'reactstrap';
import styled, { useTheme } from 'styled-components';
import {
  GET_BORDER_RADIUS_1,
  GET_BOX_SHADOW_2,
  GET_COLOR_CONTRAST_1,
  GET_COLOR_MAIN_1,
  GET_COLOR_MAIN_2,
  GET_COLOR_SECONDARY_3,
} from '../../lib/themeGetters';
import ArrowIcon from '../ArrowIcon/ArrowIcon';

export const Image = styled.img`
  display: block;
  width: 100%;
  border-radius: ${GET_BORDER_RADIUS_1}px;
`;

export const TextContrast1 = styled.span`
  color: ${GET_COLOR_CONTRAST_1};
`;

export const TextMain1 = styled.span`
  color: ${GET_COLOR_MAIN_1};
`;

const ArrowButtonContainer = styled.div`
  position: relative;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: opacity 200ms ease;
  &:hover {
    opacity: 0.7;
  }
`;

const LeftArrowContainer = styled.div`
  position: absolute;
  left: -15px;
  background-color: ${p => GET_COLOR_SECONDARY_3(p)};
  top: 40%;
  z-index: 9;
  border-radius: 10%;
  box-shadow: 0px 1px 2px 1px rgb(50 50 50 / 30%);
`;

const RightArrowContainer = styled.div`
  position: absolute;
  right: -15px;
  top: 40%;
  background-color: ${p => GET_COLOR_SECONDARY_3(p)};
  border-radius: 10%;
  box-shadow: 0px 1px 2px 1px rgb(50 50 50 / 30%);
`;
interface ArrowProps {
  onClick?: () => void;
}

export const RightArrow = memo((props: ArrowProps) => {
  const { onClick } = props;
  const theme = useTheme();
  return (
    <RightArrowContainer onClick={onClick}>
      <ArrowButtonContainer>
        <ArrowIcon color={GET_COLOR_CONTRAST_1({ theme })} direction="right" />
      </ArrowButtonContainer>
    </RightArrowContainer>
  );
});

export const LeftArrow = memo((props: ArrowProps) => {
  const { onClick } = props;
  const theme = useTheme();
  return (
    <LeftArrowContainer onClick={onClick}>
      <ArrowButtonContainer>
        <ArrowIcon color={GET_COLOR_CONTRAST_1({ theme })} direction="left" />
      </ArrowButtonContainer>
    </LeftArrowContainer>
  );
});

export const Modal = styled(StrapModal)`
  .modal-content {
    background-color: ${GET_COLOR_MAIN_1};
  }
`;

export const StyledPopover = styled(Popover)`
  .popover {
    border-radius: 8px;
    border-width: 0px;
    box-shadow: ${GET_BOX_SHADOW_2};
    background-color: ${GET_COLOR_MAIN_2};
    z-index: 9;
  }
  .arrow {
    /* ::after {
      border-bottom-color: ${GET_COLOR_MAIN_2} !important;
    } */
  }
`;

export const WebArenaLogo = styled.div`
  width: 117.8px;
  height: 80px;
  background-image: url('/images/web_arena.png');
  background-position: center;
  background-size: cover;
`;
