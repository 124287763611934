import React from 'react';
import { useTheme } from 'styled-components';

interface IProps {
  color?: string;
  width?: number;
  height?: number;
}

const Moon: React.FC<IProps> = props => {
  const { width = 22, height = 22 } = props;
  const theme = useTheme();
  const color = props.color || theme.colors.secondary1;
  return (
    <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.9722 1.5502C9.60246 2.13362 7.90698 4.26904 7.90698 6.74419C7.90698 9.69827 10.3017 12.093 13.2558 12.093C15.731 12.093 17.8664 10.3975 18.4498 8.02781C18.6283 7.30259 19.6677 7.32712 19.8118 8.05996C19.9366 8.69461 20 9.34344 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C10.6566 0 11.3054 0.0633693 11.94 0.188192C12.6729 0.332325 12.6974 1.37165 11.9722 1.5502Z"
        fill={color}
      />
    </svg>
  );
};

export default React.memo(Moon);
