import React from 'react';
import Container from '../Container/Container';
import Row from '../Row/Row';
import Col from '../Col/Col';
import styled from 'styled-components';
import { GET_COLOR_MAIN_1, GET_PADDING_2 } from '../../lib/themeGetters';
import HeaderMenu from '../HeaderMenu/HeaderMenu';
import HeaderActions from '../HeaderActions/HeaderActions';
import { Link } from 'react-router-dom';
import { WebArenaLogo } from '../Common/Common';

interface IProps {}

const Wrapper = styled.header`
  min-height: 7.5rem;
  padding: ${GET_PADDING_2};
  display: flex;
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: ${GET_COLOR_MAIN_1};
`;

const StyledCol = styled(Col)`
  margin-top: -0.5rem;
  padding-top: 0.5rem;
  background-color: ${GET_COLOR_MAIN_1};
`;

const Logo = styled.img`
  min-height: 80px;
`;

const Header: React.FC<IProps> = () => {
  return (
    <Wrapper>
      <Container>
        <Row>
          <StyledCol xs={12}>
            <Row className="d-flex justify-content-between align-items-center flex-wrap">
              <Col className="d-flex" xs={4} md={4} lg={1}>
                <Link to="/">
                  <Logo
                    src="/images/logo/logo.png"
                    srcSet="/images/logo/logo@2x.png 2x, /images/logo/logo@3x.png 3x"
                    alt="CSKA logo"
                  />
                </Link>
                <div className="d-sm-flex d-xs-flex d-md-flex d-lg-none ml-4">
                  <WebArenaLogo />
                </div>
              </Col>
              <Col className="d-none d-lg-block" md={10} lg={9} xl={8}>
                <HeaderMenu />
              </Col>
              <Col className="d-flex" xs={8} md={8} lg={2} xl={3}>
                <HeaderActions />
              </Col>
            </Row>
          </StyledCol>
        </Row>
      </Container>
    </Wrapper>
  );
};

export default React.memo(Header);
