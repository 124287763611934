import React, { BaseSyntheticEvent, useCallback, useEffect, useState } from 'react';
import { Popover } from 'react-tiny-popover';

interface IProps {
  content: JSX.Element;
  buttonContent?: JSX.Element;
  isOpen?: boolean;
}

const Popper: React.FC<IProps> = props => {
  const { content, buttonContent, isOpen = false } = props;
  const [isPopoverOpen, setIsPopoverOpen] = useState(isOpen);

  useEffect(() => {
    setIsPopoverOpen(isOpen);
  }, [isOpen]);

  const handleToggle = useCallback(
    (evt: BaseSyntheticEvent<MouseEvent>) => {
      evt.preventDefault();
      evt.stopPropagation();

      setIsPopoverOpen(!isPopoverOpen);
    },
    [isPopoverOpen]
  );

  const handleClickOutside = useCallback((evt: MouseEvent) => {
    evt.preventDefault();
    evt.stopPropagation();

    setIsPopoverOpen(false);
  }, []);

  return (
    <Popover
      isOpen={isPopoverOpen}
      positions={['bottom']}
      content={content}
      padding={15}
      onClickOutside={handleClickOutside}
      containerStyle={{ zIndex: '1500' }} // to overindexing other layouts e.g nav burger
    >
      <div onClick={handleToggle}>{buttonContent}</div>
    </Popover>
  );
};

export default React.memo(Popper);
