import { ApolloProvider } from '@apollo/client';
import React from 'react';
import { configureApollo } from '../../lib/configureApollo';
import ThemeProvider from '../ThemeProvider/ThemeProvider';
import AuthProvider from '../AuthProvider/AuthProvider';
import I18nProvider from '../I18nProvider/I18nProvider';
import LayoutProvider from '../LayoutProvider/LayoutProvider';
import Routes from '../Routes/Routes';
import InitializationLoader from '../InitializationLoader/InitializationLoader';
import NotificationProvider from '../NotificationProvider/NotificationProvider';
import EventTypesConfigProvider from '../EventTypesConfigProvider/EventTypesConfigProvider';
import TimerProvider from '../TimerProvider/TimerProvider';
import SeatsReserveProvider from '../SeatsReserveProvider/SeatsReserveProvider';
import CartProvider from '../CartProvider/CartProvider';
import CloudpaymentsModalProvider from '../CloudpaymentsModalProvider/CloudpaymentsModalProvider';
import CheckMatchAccessProvider from '../CheckMatchAccessProvider/CheckMatchAccessProvider';

const client = configureApollo();

function App() {
  return (
    <ApolloProvider client={client}>
      <I18nProvider>
        <AuthProvider>
          <SeatsReserveProvider>
            <TimerProvider>
              <CartProvider>
                <ThemeProvider>
                  <LayoutProvider>
                    <NotificationProvider>
                      <CloudpaymentsModalProvider>
                        <EventTypesConfigProvider>
                          <InitializationLoader>
                            <CheckMatchAccessProvider>
                              <Routes />
                            </CheckMatchAccessProvider>
                          </InitializationLoader>
                        </EventTypesConfigProvider>
                      </CloudpaymentsModalProvider>
                    </NotificationProvider>
                  </LayoutProvider>
                </ThemeProvider>
              </CartProvider>
            </TimerProvider>
          </SeatsReserveProvider>
        </AuthProvider>
      </I18nProvider>
    </ApolloProvider>
  );
}

export default React.memo(App);
