import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { SeatsContext, TSeatsReserveObject } from '../../contexts/contexts';
import { useGetInfoFromResults } from '../../hooks/useGetInfoFromResults';
import useEntityTimer from '../../hooks/useEntityTimer';
import {
  CreateMyEventReserveMutationVariables,
  DeleteMyEventReserveMutationVariables,
  LoyaltyDiscountFragment,
  ReserveFragment,
  useCreateMyEventReserveMutation,
  useDeleteMyEventReserveMutation,
  useMyEventReservesLazyQuery,
} from '../../types/graphql';
import { AuthContext } from '../AuthProvider/AuthProvider';
import { SEATS_RESERVE_TIME, STORAGE_KEY_RESERVE_TIME } from '../../lib/constants';

const refetchMyEvents = ['myEventReserves', 'eventSector'];

interface IProps {}

const SeatsReserveProvider: React.FC<IProps> = props => {
  const [isReservesLoading, setIsReservesLoading] = useState(true);
  const [data, setData] = useState<{ seats: TSeatsReserveObject }>({ seats: {} });
  const getInfo = useGetInfoFromResults();

  const user = useContext(AuthContext);
  const userId = user.profile?.id;

  const [getMyReserves, myReservesResult] = useMyEventReservesLazyQuery({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    onCompleted: data => {
      rehydrateMyReservesById(data.myEventReserves);
    },
  });

  const reserves = myReservesResult.data?.myEventReserves;
  const refetch = myReservesResult.refetch;
  const notifyOnReserveExpire = useCallback(() => {
    if (reserves && reserves.length > 0) {
      if (refetch) {
        refetch();
      }
    }
  }, [refetch, reserves]);

  const reservesTimer = useEntityTimer(STORAGE_KEY_RESERVE_TIME, SEATS_RESERVE_TIME, notifyOnReserveExpire);

  const allMyReserves = myReservesResult.data?.myEventReserves;

  const [deleteMyEventReserveMutation, deleteMyEventReserveResult] = useDeleteMyEventReserveMutation({
    refetchQueries: refetchMyEvents,
    awaitRefetchQueries: true,
  });
  const [createMyEventReserveMutation, createMyEventReserveResult] = useCreateMyEventReserveMutation({
    refetchQueries: refetchMyEvents,
    awaitRefetchQueries: true,
  });

  const { isLoading: loading, error } = getInfo([deleteMyEventReserveResult, createMyEventReserveResult]);

  const isLoading = myReservesResult.loading || loading || !!isReservesLoading;

  const rehydrateMyReservesById = useCallback((reserves: ReserveFragment[]) => {
    setIsReservesLoading(true);
    // очищаем состояние + очищаем лояльность при любом изменении кол-ва мест.
    const newData = reserves.reduce((acc: TSeatsReserveObject, reserve) => {
      const eventId = reserve.eventId;
      if (!acc[eventId]) {
        acc[eventId] = { reserves: [reserve] };
      } else if (acc[eventId].reserves) {
        acc[eventId].reserves.push(reserve);
      }
      return acc;
    }, {});
    setIsReservesLoading(false);
    setData({ seats: newData });
  }, []);

  const setLoyaltyDiscount = useCallback(
    (eventId: number, loyaltyDiscount: LoyaltyDiscountFragment | null) => {
      const newData = { ...data, seats: { ...data.seats, [eventId]: { ...data.seats[eventId], loyaltyDiscount } } };
      setData(newData);
    },
    [data]
  );

  useEffect(() => {
    // if (userId) {
    //   getMyReserves();
    // }
    getMyReserves();
  }, [userId, getMyReserves]);

  const rehydrateTimers = reservesTimer.rehydrateTimers;
  const rehydrateCalled = reservesTimer.rehydrateCalled;
  const myReservesCalled = myReservesResult.called;
  useEffect(() => {
    if (userId && myReservesResult.called && !rehydrateCalled && reserves && reserves.length > 0) {
      rehydrateTimers();
    }
  }, [userId, getMyReserves, myReservesCalled, rehydrateCalled, myReservesResult.called, rehydrateTimers, reserves]);

  const getDataByEventId = useCallback(
    (eventId: number) => {
      if (data.seats[eventId]) {
        const info = data.seats[eventId];
        return info;
      }
    },
    [data]
  );

  const getLoyaltyDiscount = useCallback(
    (eventId: number) => {
      if (!data.seats[eventId]) {
        return null;
      }
      const info = data.seats[eventId];
      const loyalty = info.loyaltyDiscount;
      return loyalty;
    },
    [data]
  );

  const getEventReserves = useCallback(
    (eventId: number) => {
      if (!data.seats[eventId]) {
        return;
      }
      const info = data.seats[eventId];
      const reserves = info.reserves;
      return reserves;
    },
    [data]
  );

  const removeEntityTimer = reservesTimer.removeEntityTimer;
  const stopTimer = reservesTimer.stopTimer;
  const deleteMyEventReserve = useCallback(
    (variables: DeleteMyEventReserveMutationVariables) => {
      setIsReservesLoading(true);
      const promise = deleteMyEventReserveMutation({ variables });
      if (reserves && reserves.length > 1) {
        promise.then(() => removeEntityTimer());
      } else if (reserves && reserves.length === 1) {
        stopTimer();
      }
      promise.catch(() => setIsReservesLoading(false));
      return promise;
    },
    [deleteMyEventReserveMutation, removeEntityTimer, reserves, stopTimer]
  );
  const addEntityTimer = reservesTimer.addEntityTimer;
  const createMyEventReserve = useCallback(
    (variables: CreateMyEventReserveMutationVariables) => {
      setIsReservesLoading(true);
      const promise = createMyEventReserveMutation({ variables });
      promise.then(() => addEntityTimer());

      promise.catch(() => {
        setIsReservesLoading(false);
      });
      return promise;
    },
    [createMyEventReserveMutation, addEntityTimer]
  );

  const refetchReserves = useCallback(async () => {
    if (myReservesResult?.refetch) {
      return myReservesResult.refetch().finally(() => {
        setIsReservesLoading(false);
      });
    }
  }, [myReservesResult]);
  const seats = data.seats;
  const getAllMappedReserves = useCallback(() => seats, [seats]);

  const getReservesLength = useCallback(() => (allMyReserves ? allMyReserves.length : 0), [allMyReserves]);

  const allReserves = useMemo(() => allMyReserves || [], [allMyReserves]);

  const ctx = useMemo(() => {
    return {
      isLoading,
      error: error ?? undefined,
      getDataByEventId,
      getLoyaltyDiscount,
      getEventReserves,
      setLoyaltyDiscount,
      createMyEventReserve,
      deleteMyEventReserve,
      refetchReserves,
      getAllMappedReserves,
      getReservesLength,
      allReserves,
    };
  }, [
    isLoading,
    error,
    getDataByEventId,
    getLoyaltyDiscount,
    getEventReserves,
    setLoyaltyDiscount,
    createMyEventReserve,
    deleteMyEventReserve,
    refetchReserves,
    getAllMappedReserves,
    getReservesLength,
    allReserves,
  ]);

  return <SeatsContext.Provider value={ctx}>{props.children}</SeatsContext.Provider>;
};

export default React.memo(SeatsReserveProvider);
