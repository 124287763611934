import React, { useMemo } from 'react';
import { EventTypesConfigFragment, useEventTypesConfigQuery } from '../../types/graphql';

interface IProps {}

export interface EventTypesConfigContextValue extends EventTypesConfigFragment {
  isLoading: boolean;
}

export const EventTypesConfigContext = React.createContext<EventTypesConfigContextValue>({
  isLoading: false,
});

const EventTypesConfigProvider: React.FC<IProps> = props => {
  const result = useEventTypesConfigQuery();

  const isLoading = result.loading;
  const eventTypesConfig = result?.data?.eventTypesConfig ?? null;

  const value = useMemo(
    (): EventTypesConfigContextValue => ({
      isLoading,
      ...eventTypesConfig,
    }),
    [isLoading, eventTypesConfig]
  );

  return <EventTypesConfigContext.Provider value={value}>{props.children}</EventTypesConfigContext.Provider>;
};

export default React.memo(EventTypesConfigProvider);
