import React, { PropsWithChildren, useCallback } from 'react';
import styled from 'styled-components';
import {
  GET_BORDER_RADIUS_1,
  GET_BOX_DANGER_SHADOW_1,
  GET_BOX_SHADOW_3,
  GET_COLOR_MAIN_2,
  GET_FONT_WEIGHT_BOLD,
  GET_MARGIN_5,
  GET_PADDING_3,
  GET_PADDING_4,
} from '../../lib/themeGetters';

interface IProps {
  className?: string;
  title?: string | null | JSX.Element;
  extra?: React.ReactNode;
  fullHeight?: boolean;
  noShadow?: boolean;
  noPadding?: boolean;
  error?: boolean;
}

export interface ICardProps extends PropsWithChildren<IProps> {}

const StyledCard = styled.div<{ fullHeight?: boolean; error?: boolean; noShadow?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
  transition: box-shadow 0.5s ease-out;
  height: ${p => (p.fullHeight ? `calc(100% - ${GET_MARGIN_5(p)})` : 'unset')};
  background-color: ${GET_COLOR_MAIN_2};
  border-radius: ${GET_BORDER_RADIUS_1}px;
  margin-bottom: ${GET_MARGIN_5};
  box-shadow: ${props => {
    if (props.noShadow) {
      return 'none';
    }
    if (props.error) {
      return GET_BOX_DANGER_SHADOW_1;
    } else {
      return GET_BOX_SHADOW_3;
    }
  }};
`;

const CardHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: ${GET_PADDING_3} ${GET_PADDING_4};
`;

const CardBody = styled.div<{ noPadding?: boolean; }>`
  height: 100%;
  padding: ${props => {
    if (props.noPadding) {
      return '0'
    }
    return GET_PADDING_3(props) + ' ' + GET_PADDING_4(props);
  }};
`;

const TitleWrapper = styled.div``;
const Title = styled.h3`
  font-weight: ${GET_FONT_WEIGHT_BOLD};
  margin-bottom: 0;
`;
const ExtraWrapper = styled.div``;
const Extra = styled.div``;

const Card: React.FC<ICardProps> = props => {
  const { title, className, extra, fullHeight, children, error, noShadow, noPadding } = props;

  const renderHeader = useCallback(() => {
    if (!extra && !title) {
      return null;
    }

    return (
      <CardHeader>
        <TitleWrapper>{title && <Title>{title}</Title>}</TitleWrapper>
        <ExtraWrapper>{extra && <Extra>{extra}</Extra>}</ExtraWrapper>
      </CardHeader>
    );
  }, [title, extra]);

  return (
    <StyledCard error={error} fullHeight={fullHeight} className={className} noShadow={noShadow}>
      {renderHeader()}
      <CardBody noPadding={noPadding}>{children}</CardBody>
    </StyledCard>
  );
};

export default React.memo(Card);
