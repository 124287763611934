import { EventFullFragment, EventOrderFragment, EventSectorSeatFragment } from '../types/graphql';
import { EventOrderTicketFragment } from '../types/local';

const currencyCode = 'RUB';
const yandexMetrikaId = 82552255;
const dataLayer = (window as any).dataLayer || [];

export function ymAdd(order: EventOrderFragment): void {
  try {
    //@ts-ignore
    dataLayer.push({
      ecommerce: {
        currencyCode,
        add: {
          products: getProductsByOrder(order),
        },
      },
    });
  } catch (e) {
    console.log(e);
  }
}

export function ymPurchaseEventOrder(order: EventOrderFragment): void {
  //@ts-ignore
  window && window.ym && window.ym(yandexMetrikaId, 'reachGoal', 'purchase_success');
  try {
    //@ts-ignore
    dataLayer.push({
      ecommerce: {
        currencyCode,
        purchase: {
          actionField: {
            id: order.code,
          },
          products: getProductsByOrder(order),
        },
      },
    });
  } catch (e) {
    console.log(e);
  }
}

function getEventType(order: EventOrderFragment, ticket: EventOrderTicketFragment): string {
  if (!order || !order.event) {
    return '';
  }

  if (order.event.eventTypeId === 1 || order.event.eventTypeId === 2) {
    const category = order.event.eventTypeId === 2 ? ['Билет на матч'] : ['Абонементы'];
    // TODO: возможно придется в зависимости от сектора менять категорию
    category.push('Зрители');
    category.push(`Сектор ${ticket.sector}`);
    category.push(`Ряд ${ticket.row}`);
    return category.join('/');
  }

  if (order.event.eventTypeId === 3) {
    return 'Экскурсии/Тур по стадиону/Билет на экскурсию';
  }
  if (order.event.eventTypeId === 4) {
    const category = ['Парковки'];
    if (order.event.title.toLocaleLowerCase().includes('подземная')) {
      category.push('Подземная');
    }
    if (order.event.title.toLocaleLowerCase().includes('наземная')) {
      category.push('Наземная');
    }
    category.push('Билет на парковку');
    return category.join('/');
  }
  return '';
}

function getProductsByOrder(order: EventOrderFragment): any[] {
  return order.tickets.map(ticket => {
    const category = getEventType(order, ticket);
    return {
      id: ticket.id,
      name: order.event.title,
      price: ticket.price,
      category,
    };
  });
}

export function metrikaGoToEvent(event: EventFullFragment) {
  //@ts-ignore
  window && window.ym && window.ym(yandexMetrikaId, 'reachGoal', 'event_open');
  return true;
}

export function metrikaSelectSector(sector: EventSectorSeatFragment) {
  //@ts-ignore
  window && window.ym && window.ym(yandexMetrikaId, 'reachGoal', 'select_sector');
  return true;
}

export function metrikaOrderStart(event?: EventFullFragment) {
  //@ts-ignore
  window && window.ym && window.ym(yandexMetrikaId, 'reachGoal', 'order_start');
  return true;
}

export function metrikaRegister() {
  //@ts-ignore
  window && window.ym && window.ym(yandexMetrikaId, 'reachGoal', 'register');
  return true;
}

export function metrikaRegisterSuccess() {
  //@ts-ignore
  window && window.ym && window.ym(yandexMetrikaId, 'reachGoal', 'register_form_success');
  return true;
}

export function metrikaConfirmPhone() {
  //@ts-ignore
  window && window.ym && window.ym(yandexMetrikaId, 'reachGoal', 'confirm_phone');
  return true;
}

export function metrikaAuthStart() {
  //@ts-ignore
  window && window.ym && window.ym(yandexMetrikaId, 'reachGoal', 'auth_start');
  return true;
}

export function metrikaAuthSuccess() {
  //@ts-ignore
  window && window.ym && window.ym(yandexMetrikaId, 'reachGoal', 'auth_success');
  return true;
}

export function metrikaPucrchaseStart() {
  //@ts-ignore
  window && window.ym && window.ym(yandexMetrikaId, 'reachGoal', 'purchase_start');
  return true;
}
