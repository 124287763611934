import { useCallback, useRef, useState } from 'react';

function useTimer() {
  const [time, setTime] = useState(0);
  const timerRef = useRef<{ timer: null | NodeJS.Timeout }>({ timer: null }).current;
  const runTimer = useCallback(
    (startTime: number) => {
      if (timerRef.timer) {
        clearInterval(timerRef.timer);
      }
      let tickTime = startTime;
      setTime(tickTime);
      timerRef.timer = setInterval(() => {
        if (tickTime <= 1) {
          setTime(0);
          if (timerRef.timer) {
            clearInterval(timerRef.timer);
            timerRef.timer = null;
          }
          return;
        }

        tickTime -= 1;
        setTime(tickTime);
      }, 1000);
    },
    [timerRef]
  );

  return { runTimer, time };
}

export default useTimer;
