import { parseJSON } from './parseJSON';
import { IS_BROWSER } from './constants';
import { LocalStorageNotFoundError } from '../errors';
import { ThemeNames } from '../components/ThemeProvider/ThemeProvider';
import { LocaleISO } from '../types/local';
import { UserFragment } from '../types/graphql';
import { Resource } from 'i18next';

export interface LocalStorageMap extends LocalStorageTimerMap {
  accessToken: string;
  refreshToken: string;
  locale: LocaleISO;
  translations: Resource;
  themeName: ThemeNames;
  user: UserFragment;
  resetTimeout: number | null;
  translationsUpdatedAt_ru: number;
  translationsUpdatedAt_en: number;
  session: string;
  isCustomTermsOfSeviceShown: boolean;
  // key is seatId, endTime for endTime - Date.now()
}

export interface LocalStorageTimerMap {
  reserves_time_key: number | null;
  check_season_ticket_key: number | null;
}

export type LocalStorageKeys = keyof LocalStorageMap;

// const storageProxyContainer: Record<LocalStorageKeys, LocalStorageMap> = {};

// const localStorageProxy = <TKey extends LocalStorageKeys>(key: TKey): null | LocalStorageMap[TKey] => {
//   if (key in storageProxyContainer) {
//     return storageProxyContainer[key]
//   }
//   return getFromLocalStorage(key);
// }

export const getFromLocalStorage = <TKey extends LocalStorageKeys>(key: TKey): null | LocalStorageMap[TKey] => {
  if (!IS_BROWSER || !window || !window.localStorage) {
    return null;
  }

  let result = window.localStorage.getItem(key);
  if (!result) {
    return null;
  }

  return parseJSON(result) as null | LocalStorageMap[TKey];
};

export const writeToLocalStorage = <TKey extends LocalStorageKeys>(
  key: TKey,
  data: string | LocalStorageMap[TKey],
  stringify = true
) => {
  if (!IS_BROWSER || !window || !window.localStorage) {
    throw new LocalStorageNotFoundError();
  }

  let value = data as string;
  if (stringify) {
    value = JSON.stringify(data);
  }

  return window.localStorage.setItem(key, value);
};

export const removeFromLocalStorage = <TKey extends LocalStorageKeys>(key: TKey) => {
  if (!IS_BROWSER || !window || !window.localStorage) {
    throw new LocalStorageNotFoundError();
  }

  return window.localStorage.removeItem(key);
};
