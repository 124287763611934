import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import { GET_COLOR_MAIN_1 } from '../../lib/themeGetters';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import { AuthContext } from '../AuthProvider/AuthProvider';
import { useHistory } from 'react-router-dom';

interface IProps {}

const LayoutWrapper = styled.div`
  background-color: ${GET_COLOR_MAIN_1};
`;

const PrivateLayout: React.FC<IProps> = props => {
  const authContext = useContext(AuthContext);
  const user = authContext.profile;
  const history = useHistory();
  useEffect(() => {
    if (!user) {
      history.push('/login');
    }
  }, [history, user]);
  if (!user) {
    return null;
  }
  return (
    <LayoutWrapper>
      <Header />
      <div>{props.children}</div>
      <Footer />
    </LayoutWrapper>
  );
};

export default React.memo(PrivateLayout);
