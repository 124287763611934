/* eslint-disable */
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  BigInt: any;
  /** A date string, such as 2007-12-03, compliant with the `full-date` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Date: any;
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: any;
  /** SCALARS */
  JSON: any;
  /** The `UUID` scalar type represents UUID values as specified by [RFC 4122](https://tools.ietf.org/html/rfc4122). */
  UUID: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type Acl = {
  __typename?: 'ACL';
  id: Scalars['String'];
  parentId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type Ad = {
  __typename?: 'Ad';
  id: Scalars['String'];
  title: Scalars['String'];
  images?: Maybe<Array<File>>;
  url: Scalars['String'];
};

export type AddUserDeviceInput = {
  userId: Scalars['Int'];
  deviceId: Scalars['String'];
  installId: Scalars['String'];
  bundleId?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  deviceLocale?: Maybe<Scalars['String']>;
  deviceName?: Maybe<Scalars['String']>;
  deviceModel?: Maybe<Scalars['String']>;
  installReferrer?: Maybe<Scalars['String']>;
  manufacturer?: Maybe<Scalars['String']>;
  systemName?: Maybe<Scalars['String']>;
  systemVersion?: Maybe<Scalars['String']>;
  appVersion?: Maybe<Scalars['String']>;
  userAgent?: Maybe<Scalars['String']>;
  pushToken?: Maybe<Scalars['String']>;
  expoPushToken?: Maybe<Scalars['String']>;
};

export enum AppStoreEnum {
  APP_STORE = 'APP_STORE',
  GOOGLE_PLAY = 'GOOGLE_PLAY',
}

export type ApplicationDocumentCreateInput = {
  type: UserDocumentType;
  data?: Maybe<Scalars['JSON']>;
};

export type ApplyLoyaltyPromoCodeResult = {
  __typename?: 'ApplyLoyaltyPromoCodeResult';
  promoCode: LoyaltyPromoCode;
  promo: LoyaltyPromo;
};

export type Article = {
  __typename?: 'Article';
  id: Scalars['Int'];
  clientId: Scalars['Int'];
  categoryId: Scalars['Int'];
  state: ArticleState;
  publishedAt?: Maybe<Scalars['DateTime']>;
  localeId: Scalars['Int'];
  url?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  category: ArticleCategory;
  tags?: Maybe<Array<Scalars['String']>>;
  blocks?: Maybe<Array<ArticleBlock>>;
  preview?: Maybe<Array<ArticleBlock>>;
  authors?: Maybe<Array<ArticleAuthor>>;
  links?: Maybe<Array<ArticleLink>>;
  log?: Maybe<Array<ArticleLog>>;
  title: Scalars['String'];
  locale: SystemLocale;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type ArticleArticleTag = {
  __typename?: 'ArticleArticleTag';
  id: Scalars['Int'];
  articleId: Scalars['Int'];
  tagId: Scalars['Int'];
  tag: ArticleTag;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type ArticleArticleTagConnection = DefaultConnection & {
  __typename?: 'ArticleArticleTagConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<ArticleArticleTag>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type ArticleArticleTagCreateInput = {
  articleId: Scalars['Int'];
  tagId: Scalars['Int'];
};

export type ArticleArticleTagUpdateInput = {
  articleId?: Maybe<Scalars['Int']>;
  tagId?: Maybe<Scalars['Int']>;
};

export type ArticleAuthor = {
  __typename?: 'ArticleAuthor';
  id: Scalars['Int'];
  articleId: Scalars['Int'];
  userId?: Maybe<Scalars['Int']>;
  displayName?: Maybe<Scalars['String']>;
  role: ArticleAuthorRole;
  user: SystemUser;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type ArticleAuthorConnection = DefaultConnection & {
  __typename?: 'ArticleAuthorConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<ArticleAuthor>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type ArticleAuthorCreateInput = {
  articleId: Scalars['Int'];
  userId?: Maybe<Scalars['Int']>;
  displayName?: Maybe<Scalars['String']>;
  role?: Maybe<ArticleAuthorRole>;
};

export enum ArticleAuthorRole {
  AUTHOR = 'AUTHOR',
  EDITOR = 'EDITOR',
  JOURNALIST = 'JOURNALIST',
  PHOTOGRAPHER = 'PHOTOGRAPHER',
  PHOTO_EDITOR = 'PHOTO_EDITOR',
}

export type ArticleAuthorUpdateInput = {
  articleId?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['Int']>;
  displayName?: Maybe<Scalars['String']>;
  role?: Maybe<ArticleAuthorRole>;
};

export type ArticleBlock = {
  __typename?: 'ArticleBlock';
  id: Scalars['Int'];
  articleId: Scalars['Int'];
  authorId: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  type: ArticleBlockType;
  data?: Maybe<Scalars['JSON']>;
  isPreview: Scalars['Boolean'];
  sort: Scalars['Int'];
  versions?: Maybe<Array<ArticleBlock>>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type ArticleBlockAuthor = {
  __typename?: 'ArticleBlockAuthor';
  blockId: Scalars['Int'];
  author: SystemUser;
};

export type ArticleBlockAuthorsConnection = DefaultConnection & {
  __typename?: 'ArticleBlockAuthorsConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<ArticleBlockAuthor>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type ArticleBlockConnection = DefaultConnection & {
  __typename?: 'ArticleBlockConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<ArticleBlock>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type ArticleBlockCreateInput = {
  articleId: Scalars['Int'];
  authorId: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  type?: Maybe<ArticleBlockType>;
  data?: Maybe<Scalars['JSON']>;
  isPreview?: Maybe<Scalars['Boolean']>;
  sort?: Maybe<Scalars['Int']>;
};

export type ArticleBlockTitle = {
  __typename?: 'ArticleBlockTitle';
  locale: Scalars['String'];
  value: Scalars['String'];
};

export enum ArticleBlockType {
  TITLE = 'TITLE',
  SUB_TITLE = 'SUB_TITLE',
  INCUT = 'INCUT',
  HTML = 'HTML',
  PHOTO_GALLERY = 'PHOTO_GALLERY',
  ENTITY = 'ENTITY',
  EMBED = 'EMBED',
  VIDEO = 'VIDEO',
  CODE = 'CODE',
  LINK = 'LINK',
  TEXT = 'TEXT',
  PDF = 'PDF',
  ACTIONS = 'ACTIONS',
}

export type ArticleBlockUpdateInput = {
  articleId?: Maybe<Scalars['Int']>;
  authorId?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<ArticleBlockType>;
  data?: Maybe<Scalars['JSON']>;
  isPreview?: Maybe<Scalars['Boolean']>;
  sort?: Maybe<Scalars['Int']>;
};

export type ArticleBlocksUpdateInput = {
  id: Scalars['Int'];
  articleId?: Maybe<Scalars['Int']>;
  authorId?: Maybe<Scalars['Int']>;
  locale?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<ArticleBlockType>;
  data?: Maybe<Scalars['JSON']>;
  isPreview?: Maybe<Scalars['Boolean']>;
  sort?: Maybe<Scalars['Int']>;
};

export type ArticleCategoriesConnection = {
  __typename?: 'ArticleCategoriesConnection';
  /** Общее количество элементов в запросе с учетом фильтров */
  total: Scalars['Int'];
  /** Лимит выборки */
  limit: Scalars['Int'];
  /** Указатель (пропуск элементов) */
  cursor: Scalars['Int'];
  /** Список элементов */
  edges: Array<ArticleCategory>;
  /** Список полей для сортировки */
  sortable?: Maybe<Array<Scalars['String']>>;
  /** Список полей для строкового поиска */
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type ArticleCategory = {
  __typename?: 'ArticleCategory';
  id: Scalars['Int'];
  clientId: Scalars['Int'];
  name: Scalars['String'];
  parentId?: Maybe<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type ArticleCategoryConnection = DefaultConnection & {
  __typename?: 'ArticleCategoryConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<ArticleCategory>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type ArticleCategoryCreateInput = {
  name?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['Int']>;
};

export type ArticleCategoryUpdateInput = {
  name?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['Int']>;
};

export type ArticleCategoryWhereInput = {
  name?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['Int']>;
};

export type ArticleCreateInput = {
  categoryId: Scalars['Int'];
  state?: Maybe<ArticleState>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  localeId: Scalars['Int'];
  url?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
};

export type ArticleLink = {
  __typename?: 'ArticleLink';
  id: Scalars['Int'];
  articleId: Scalars['Int'];
  entityId: Scalars['Int'];
  entityType: ArticleLinkEntityType;
  entity: ArticleLinkEntity;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type ArticleLinkConnection = DefaultConnection & {
  __typename?: 'ArticleLinkConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<ArticleLink>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type ArticleLinkCreateInput = {
  articleId: Scalars['Int'];
  entityId: Scalars['Int'];
  entityType?: Maybe<ArticleLinkEntityType>;
};

export type ArticleLinkEntity = TournamentMatch | TournamentTour | TournamentTeam | TournamentMatchEvent;

export type ArticleLinkEntityRecord =
  | TournamentMatch
  | TournamentTour
  | TournamentTeam
  | TournamentMatchEvent
  | TournamentTeamMember;

export enum ArticleLinkEntityType {
  MATCH = 'MATCH',
  TOUR = 'TOUR',
  TEAM = 'TEAM',
  EVENT = 'EVENT',
  PLAYER = 'PLAYER',
}

export type ArticleLinkEntityTypeConnection = DefaultConnection & {
  __typename?: 'ArticleLinkEntityTypeConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<ArticleLinkEntityRecord>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type ArticleLinkInput = {
  entityId: Scalars['Int'];
  entityType: EntityType;
};

export type ArticleLinkUpdateInput = {
  articleId?: Maybe<Scalars['Int']>;
  entityId?: Maybe<Scalars['Int']>;
  entityType?: Maybe<ArticleLinkEntityType>;
};

export type ArticleLog = {
  __typename?: 'ArticleLog';
  id: Scalars['Int'];
  articleId: Scalars['Int'];
  authorId: Scalars['Int'];
  state: ArticleLogState;
  data?: Maybe<Scalars['JSON']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export enum ArticleLogState {
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED',
  DELETED = 'DELETED',
  CHECKING = 'CHECKING',
  FACT_CHECKING = 'FACT_CHECKING',
}

export type ArticleSocial = {
  __typename?: 'ArticleSocial';
  id: Scalars['Int'];
  title: Scalars['String'];
  text: Scalars['String'];
  imageUrl: Scalars['String'];
  previewUrl: Scalars['String'];
  actions: Array<ArticleSocialAction>;
};

export type ArticleSocialAction = {
  __typename?: 'ArticleSocialAction';
  id: Scalars['String'];
  title: Scalars['String'];
  url?: Maybe<Scalars['String']>;
};

export enum ArticleSocials {
  VK = 'VK',
  INSTAGRAM = 'INSTAGRAM',
  TWITTER = 'TWITTER',
  YOUTUBE = 'YOUTUBE',
  CLUB_NEWS = 'CLUB_NEWS',
  CLUB_VIDEOS = 'CLUB_VIDEOS',
  CLUB_PHOTOS = 'CLUB_PHOTOS',
  PLAYERS = 'PLAYERS',
  PROGRAMS = 'PROGRAMS',
  CSKA_DSA = 'CSKA_DSA',
  CSKA_KID_CLUB = 'CSKA_KID_CLUB',
  MATCH_DAY = 'MATCH_DAY',
}

export enum ArticleState {
  DRAFT = 'DRAFT',
  CHECKING = 'CHECKING',
  DELETED = 'DELETED',
  FACT_CHECKING = 'FACT_CHECKING',
  PUBLISHED = 'PUBLISHED',
}

export type ArticleStoriesConnection = DefaultConnection & {
  __typename?: 'ArticleStoriesConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<ArticleSocial>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type ArticleTag = {
  __typename?: 'ArticleTag';
  id: Scalars['Int'];
  clientId: Scalars['Int'];
  name: Scalars['String'];
  source?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type ArticleTagConnection = DefaultConnection & {
  __typename?: 'ArticleTagConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<ArticleTag>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type ArticleTagCreateInput = {
  name: Scalars['String'];
  source?: Maybe<Scalars['String']>;
};

export type ArticleTagUpdateInput = {
  name?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
};

export type ArticleUpdateInput = {
  categoryId?: Maybe<Scalars['Int']>;
  state?: Maybe<ArticleState>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  localeId?: Maybe<Scalars['Int']>;
  url?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
};

export type ArticleWhereInput = {
  authorId?: Maybe<Array<Scalars['Int']>>;
  categoryId?: Maybe<Array<Scalars['Int']>>;
  state?: Maybe<Array<ArticleState>>;
  tags?: Maybe<Array<Scalars['String']>>;
  links?: Maybe<Array<ArticleLinkInput>>;
  locale?: Maybe<Array<Scalars['String']>>;
  publishedAt?: Maybe<Array<Scalars['DateTime']>>;
};

export type ArticlesConnection = DefaultConnection & {
  __typename?: 'ArticlesConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<Article>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type AuthPayload = {
  __typename?: 'AuthPayload';
  accessToken: Scalars['String'];
  refreshToken: Scalars['String'];
  currentUser: User;
};

export type CashierCheckReceiptInput = {
  orderId?: Maybe<Scalars['Int']>;
  orderType?: Maybe<PaymentTransactionOrderType>;
  userId?: Maybe<Scalars['Int']>;
  providerId: Scalars['Int'];
  note?: Maybe<Scalars['String']>;
  receipt?: Maybe<PaymentReceiptInput>;
};

export type Catalogue = {
  __typename?: 'Catalogue';
  id: Scalars['Int'];
  clientId: Scalars['Int'];
  state: CatalogueState;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  preview?: Maybe<Scalars['String']>;
  localeIdx?: Maybe<Array<Scalars['Int']>>;
  currencyIdx?: Maybe<Array<Scalars['Int']>>;
  locales?: Maybe<Array<Locale>>;
  currencies?: Maybe<Array<Currency>>;
  items: Scalars['Int'];
  data: Array<ExtensionData>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type CatalogueCategory = {
  __typename?: 'CatalogueCategory';
  id: Scalars['Int'];
  name: Scalars['String'];
  preview?: Maybe<Scalars['String']>;
  catalogueId: Scalars['Int'];
  state: CatalogueCategoryState;
  data: Array<ExtensionData>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type CatalogueCategoryBatchUpdateInput = {
  id: Scalars['Int'];
  state?: Maybe<CatalogueItemState>;
  sort?: Maybe<Scalars['Int']>;
};

export type CatalogueCategoryConnection = DefaultConnection & {
  __typename?: 'CatalogueCategoryConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<CatalogueCategory>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type CatalogueCategoryCreateInput = {
  name: Scalars['String'];
  preview?: Maybe<Scalars['String']>;
  catalogueId: Scalars['Int'];
  state?: Maybe<CatalogueCategoryState>;
  data?: Maybe<Array<ExtensionUpdateDataInput>>;
};

export type CatalogueCategoryFieldsConnection = DefaultConnection & {
  __typename?: 'CatalogueCategoryFieldsConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<ExtensionField>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export enum CatalogueCategoryState {
  INACTIVE = 'INACTIVE',
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
}

export type CatalogueCategoryUpdateInput = {
  name?: Maybe<Scalars['String']>;
  preview?: Maybe<Scalars['String']>;
  catalogueId?: Maybe<Scalars['Int']>;
  state?: Maybe<CatalogueCategoryState>;
  data?: Maybe<Array<ExtensionUpdateDataInput>>;
};

export type CatalogueConnection = DefaultConnection & {
  __typename?: 'CatalogueConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<Catalogue>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type CatalogueCreateInput = {
  state?: Maybe<CatalogueState>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  preview?: Maybe<Scalars['String']>;
  localeIdx?: Maybe<Array<Scalars['Int']>>;
  currencyIdx?: Maybe<Array<Scalars['Int']>>;
  data?: Maybe<Array<ExtensionUpdateDataInput>>;
};

export type CatalogueFieldsConnection = DefaultConnection & {
  __typename?: 'CatalogueFieldsConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<ExtensionField>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type CatalogueItem = {
  __typename?: 'CatalogueItem';
  id: Scalars['Int'];
  catalogueId: Scalars['Int'];
  state: CatalogueItemState;
  name: Scalars['String'];
  preview?: Maybe<Scalars['String']>;
  prices?: Maybe<Array<CatalogueItemPrice>>;
  data: Array<ExtensionData>;
  parentId: Array<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type CatalogueItemBatchUpdateInput = {
  id: Scalars['Int'];
  state?: Maybe<CatalogueItemState>;
  sort?: Maybe<Scalars['Int']>;
  data?: Maybe<Array<ExtensionUpdateDataInput>>;
};

export type CatalogueItemConnection = DefaultConnection & {
  __typename?: 'CatalogueItemConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<CatalogueItem>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type CatalogueItemCreateInput = {
  catalogueId: Scalars['Int'];
  state?: Maybe<CatalogueItemState>;
  name: Scalars['String'];
  preview?: Maybe<Scalars['String']>;
  parentId?: Maybe<Array<Scalars['Int']>>;
  data?: Maybe<Array<ExtensionUpdateDataInput>>;
};

export type CatalogueItemFieldsConnection = DefaultConnection & {
  __typename?: 'CatalogueItemFieldsConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<ExtensionField>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type CatalogueItemPrice = {
  __typename?: 'CatalogueItemPrice';
  id: Scalars['Int'];
  currencyId?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['Float']>;
  catalogueItemId: Scalars['Int'];
  currency: Currency;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type CatalogueItemPriceConnection = DefaultConnection & {
  __typename?: 'CatalogueItemPriceConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<CatalogueItemPrice>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type CatalogueItemPriceCreateInput = {
  currencyId?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['Float']>;
  catalogueItemId: Scalars['Int'];
};

export type CatalogueItemPriceUpdateInput = {
  currencyId?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['Float']>;
  catalogueItemId?: Maybe<Scalars['Int']>;
};

export enum CatalogueItemState {
  INACTIVE = 'INACTIVE',
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
}

export type CatalogueItemUpdateInput = {
  catalogueId?: Maybe<Scalars['Int']>;
  state?: Maybe<CatalogueItemState>;
  name?: Maybe<Scalars['String']>;
  preview?: Maybe<Scalars['String']>;
  parentId?: Maybe<Array<Scalars['Int']>>;
  data?: Maybe<Array<ExtensionUpdateDataInput>>;
};

export type CatalogueRelation = {
  __typename?: 'CatalogueRelation';
  id: Scalars['Int'];
  sort: Scalars['Int'];
  itemId: Scalars['Int'];
  type: CatalogueRelationType;
  parentId?: Maybe<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type CatalogueRelationConnection = DefaultConnection & {
  __typename?: 'CatalogueRelationConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<CatalogueRelation>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type CatalogueRelationCreateInput = {
  sort?: Maybe<Scalars['Int']>;
  itemId: Scalars['Int'];
  type?: Maybe<CatalogueRelationType>;
  parentId?: Maybe<Scalars['Int']>;
};

export enum CatalogueRelationType {
  CATEGORY = 'CATEGORY',
  ITEM = 'ITEM',
}

export type CatalogueRelationUpdateInput = {
  sort?: Maybe<Scalars['Int']>;
  itemId?: Maybe<Scalars['Int']>;
  type?: Maybe<CatalogueRelationType>;
  parentId?: Maybe<Scalars['Int']>;
};

export enum CatalogueState {
  INACTIVE = 'INACTIVE',
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
}

export type CatalogueSummary = {
  __typename?: 'CatalogueSummary';
  itemsCount: Scalars['Int'];
  topItems?: Maybe<Array<CatalogueItem>>;
};

export type CatalogueSummaryWidget = {
  __typename?: 'CatalogueSummaryWidget';
  catalogue: Catalogue;
  summary: CatalogueSummary;
};

export type CatalogueUpdateInput = {
  state?: Maybe<CatalogueState>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  preview?: Maybe<Scalars['String']>;
  localeIdx?: Maybe<Array<Scalars['Int']>>;
  currencyIdx?: Maybe<Array<Scalars['Int']>>;
  data?: Maybe<Array<ExtensionUpdateDataInput>>;
};

export type Catering = {
  __typename?: 'Catering';
  id: Scalars['Int'];
  clientId: Scalars['Int'];
  eventPlaceId?: Maybe<Scalars['Int']>;
  commerceShopId?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  state: CateringState;
  sectors: Array<Scalars['Int']>;
  printReceiptTemplateId?: Maybe<Scalars['Int']>;
  startSale?: Maybe<Scalars['DateTime']>;
  endSale?: Maybe<Scalars['DateTime']>;
  eventPlace?: Maybe<EventPlace>;
  commerceShop?: Maybe<CommerceShop>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type CateringConnection = DefaultConnection & {
  __typename?: 'CateringConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<Catering>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type CateringCreateInput = {
  eventPlaceId?: Maybe<Scalars['Int']>;
  commerceShopId?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  state?: Maybe<CateringState>;
  sectors?: Maybe<Array<Scalars['Int']>>;
  printReceiptTemplateId?: Maybe<Scalars['Int']>;
  startSale?: Maybe<Scalars['DateTime']>;
  endSale?: Maybe<Scalars['DateTime']>;
};

export enum CateringState {
  INACTIVE = 'INACTIVE',
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
}

export type CateringUpdateInput = {
  eventPlaceId?: Maybe<Scalars['Int']>;
  commerceShopId?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  state?: Maybe<CateringState>;
  sectors?: Maybe<Array<Scalars['Int']>>;
  printReceiptTemplateId?: Maybe<Scalars['Int']>;
  startSale?: Maybe<Scalars['DateTime']>;
  endSale?: Maybe<Scalars['DateTime']>;
};

export type CheckFanIdResult = {
  __typename?: 'CheckFanIdResult';
  fanCode: Scalars['String'];
  fanGUID?: Maybe<Scalars['String']>;
  isSuccess: Scalars['Boolean'];
  errorMessage?: Maybe<Scalars['String']>;
};

export type CheckMatchAccessPayload = {
  sector: Scalars['String'];
  row: Scalars['String'];
  seat: Scalars['String'];
};

export type CheckMatchAccessResponse = {
  __typename?: 'CheckMatchAccessResponse';
  result: Scalars['Boolean'];
  seatName?: Maybe<Scalars['String']>;
};

export type CheckOrderPaymentInput = {
  /** Номер заказа */
  orderId: Scalars['Int'];
  /** Тип заказа */
  orderType: PaymentTransactionOrderType;
};

export type CheckSeasonTicketPayload = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  middleName: Scalars['String'];
  sector: Scalars['String'];
  row: Scalars['String'];
  seat: Scalars['String'];
};

export type CheckSeasonTicketResponse = {
  __typename?: 'CheckSeasonTicketResponse';
  result: Scalars['Boolean'];
};

export type CheckUserInput = {
  email?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export type Client = {
  __typename?: 'Client';
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  type?: Maybe<ClientType>;
  logo?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  colors?: Maybe<Array<Maybe<Scalars['String']>>>;
  domain?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type ClientPublicProfile = {
  __typename?: 'ClientPublicProfile';
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  settings?: Maybe<ClientPublicSettings>;
};

export type ClientPublicSettings = {
  __typename?: 'ClientPublicSettings';
  name?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  colors?: Maybe<Array<Scalars['String']>>;
  locales?: Maybe<Array<Scalars['Int']>>;
  defaultLocale?: Maybe<LocaleEnum>;
  domain?: Maybe<Scalars['String']>;
};

export type ClientSettings = {
  __typename?: 'ClientSettings';
  name?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  domain?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  defaultLocale: LocaleEnum;
  /** Идентификатор платежного терминала. Сейчас он у клиента один, а позже будет возможность назначить несколько и основной */
  paymentGatewayId: Scalars['Int'];
  clientId: Scalars['ID'];
  colors?: Maybe<Array<Scalars['String']>>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type ClientSettingsRental = {
  __typename?: 'ClientSettingsRental';
  initialPrepayment: Scalars['Int'];
};

export enum ClientType {
  INDIVIDUAL = 'INDIVIDUAL',
  LEGAL_ENTITY = 'LEGAL_ENTITY',
}

export type ClubComics = {
  __typename?: 'ClubComics';
  title: Scalars['String'];
  preview: Scalars['String'];
  images: Array<Scalars['String']>;
};

export type ClubFaq = {
  __typename?: 'ClubFAQ';
  question: Scalars['String'];
  answer: Scalars['String'];
  category?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
};

export type ClubFaqCategories = {
  __typename?: 'ClubFAQCategories';
  questions: Array<ClubFaq>;
  category?: Maybe<Scalars['String']>;
};

export type ClubInfoDsa = {
  __typename?: 'ClubInfoDSA';
  logo: Scalars['String'];
  incut: Scalars['String'];
  roadToStadium: Array<ClubInfoDsaRoadToStadium>;
  about: Array<ClubInfoDsaAbout>;
  contacts: ClubInfoDsaContacts;
};

export type ClubInfoDsaAbout = {
  __typename?: 'ClubInfoDSAAbout';
  type: Scalars['String'];
  text: Scalars['String'];
};

export type ClubInfoDsaContacts = {
  __typename?: 'ClubInfoDSAContacts';
  location: Scalars['String'];
  locationUrl: Scalars['String'];
  email: Scalars['String'];
  workingHours: Scalars['String'];
  socialNetworks: Array<ClubInfoDsaSocialNetwork>;
};

export type ClubInfoDsaRoadToStadium = {
  __typename?: 'ClubInfoDSARoadToStadium';
  type: Scalars['String'];
  text: Scalars['String'];
};

export type ClubInfoDsaSocialNetwork = {
  __typename?: 'ClubInfoDSASocialNetwork';
  type: Scalars['String'];
  url: Scalars['String'];
};

export type CommerceOrder = {
  __typename?: 'CommerceOrder';
  id: Scalars['Int'];
  shopId: Scalars['Int'];
  clientId: Scalars['Int'];
  state: CommerceOrderState;
  paymentState: CommerceOrderPaymentState;
  paymentStartedAt?: Maybe<Scalars['DateTime']>;
  userId: Scalars['Int'];
  user: User;
  externalEntityId?: Maybe<Scalars['Int']>;
  code?: Maybe<Scalars['String']>;
  total: Scalars['Float'];
  paidAmount: Scalars['Float'];
  items?: Maybe<Array<CommerceOrderItem>>;
  data?: Maybe<Array<CommerceOrderData>>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type CommerceOrderConnection = DefaultConnection & {
  __typename?: 'CommerceOrderConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<CommerceOrder>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type CommerceOrderCreateInput = {
  shopId: Scalars['Int'];
  state?: Maybe<CommerceOrderState>;
  paymentState?: Maybe<CommerceOrderPaymentState>;
  userId: Scalars['Int'];
  externalEntityId?: Maybe<Scalars['Int']>;
  code?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Float']>;
  items?: Maybe<Array<Maybe<OrderItemInput>>>;
  data?: Maybe<Array<Maybe<OrderDataInput>>>;
};

export type CommerceOrderData = {
  __typename?: 'CommerceOrderData';
  id: Scalars['Int'];
  value?: Maybe<Scalars['String']>;
  orderFieldId: Scalars['Int'];
  orderItemId?: Maybe<Scalars['Int']>;
  orderId: Scalars['Int'];
  key?: Maybe<Scalars['String']>;
  field: CommerceOrderField;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type CommerceOrderDataConnection = DefaultConnection & {
  __typename?: 'CommerceOrderDataConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<CommerceOrderData>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type CommerceOrderDataCreateInput = {
  value?: Maybe<Scalars['String']>;
  orderFieldId: Scalars['Int'];
  orderItemId?: Maybe<Scalars['Int']>;
  orderId: Scalars['Int'];
};

export type CommerceOrderDataUpdateInput = {
  value?: Maybe<Scalars['String']>;
  orderFieldId?: Maybe<Scalars['Int']>;
  orderItemId?: Maybe<Scalars['Int']>;
  orderId?: Maybe<Scalars['Int']>;
};

export type CommerceOrderDatasUpdateInput = {
  id: Scalars['Int'];
  value?: Maybe<Scalars['String']>;
  orderFieldId?: Maybe<Scalars['Int']>;
  orderItemId?: Maybe<Scalars['Int']>;
  orderId?: Maybe<Scalars['Int']>;
};

export type CommerceOrderField = {
  __typename?: 'CommerceOrderField';
  id: Scalars['Int'];
  shopId: Scalars['Int'];
  name: Scalars['String'];
  dataType: CommerceOrderFieldDataType;
  hasLocale: Scalars['Boolean'];
  sort: Scalars['Int'];
  translations?: Maybe<Array<CommerceOrderFieldTranslation>>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type CommerceOrderFieldConnection = DefaultConnection & {
  __typename?: 'CommerceOrderFieldConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<CommerceOrderField>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type CommerceOrderFieldCreateInput = {
  shopId: Scalars['Int'];
  name: Scalars['String'];
  dataType: CommerceOrderFieldDataType;
  hasLocale?: Maybe<Scalars['Boolean']>;
  sort?: Maybe<Scalars['Int']>;
};

export enum CommerceOrderFieldDataType {
  BOOLEAN = 'BOOLEAN',
  NUMBER = 'NUMBER',
  STRING = 'STRING',
  ENUM = 'ENUM',
  ARRAY = 'ARRAY',
  FILES = 'FILES',
}

export type CommerceOrderFieldTranslation = {
  __typename?: 'CommerceOrderFieldTranslation';
  id: Scalars['Int'];
  localeId?: Maybe<Scalars['Int']>;
  value: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type CommerceOrderFieldTranslationConnection = DefaultConnection & {
  __typename?: 'CommerceOrderFieldTranslationConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<CommerceOrderFieldTranslation>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type CommerceOrderFieldUpdateInput = {
  shopId?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  dataType?: Maybe<CommerceOrderFieldDataType>;
  hasLocale?: Maybe<Scalars['Boolean']>;
  sort?: Maybe<Scalars['Int']>;
};

export type CommerceOrderItem = {
  __typename?: 'CommerceOrderItem';
  id: Scalars['Int'];
  orderId: Scalars['Int'];
  state: CommerceOrderItemState;
  catalogueItemId: Scalars['Int'];
  discountId?: Maybe<Scalars['Int']>;
  currencyId: Scalars['Int'];
  qty: Scalars['Int'];
  price: Scalars['Float'];
  discountedPrice?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
  catalogueItem: CatalogueItem;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type CommerceOrderItemConnection = DefaultConnection & {
  __typename?: 'CommerceOrderItemConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<CommerceOrderItem>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type CommerceOrderItemCreateInput = {
  orderId: Scalars['Int'];
  state?: Maybe<CommerceOrderItemState>;
  catalogueItemId: Scalars['Int'];
  qty: Scalars['Int'];
};

export enum CommerceOrderItemState {
  INACTIVE = 'INACTIVE',
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
}

export type CommerceOrderItemUpdateInput = {
  orderId?: Maybe<Scalars['Int']>;
  state?: Maybe<CommerceOrderItemState>;
  catalogueItemId?: Maybe<Scalars['Int']>;
  qty?: Maybe<Scalars['Int']>;
};

export type CommerceOrderLog = {
  __typename?: 'CommerceOrderLog';
  id: Scalars['Int'];
  orderId: Scalars['Int'];
  orderState: CommerceOrderLogOrderState;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type CommerceOrderLogConnection = DefaultConnection & {
  __typename?: 'CommerceOrderLogConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<CommerceOrderLog>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export enum CommerceOrderLogOrderState {
  NEW = 'NEW',
  PROCESSING = 'PROCESSING',
  ON_DELIVERY = 'ON_DELIVERY',
  DELIVERED = 'DELIVERED',
  DONE = 'DONE',
  CANCELLED = 'CANCELLED',
}

export enum CommerceOrderPaymentState {
  UNPAID = 'UNPAID',
  PAID = 'PAID',
}

export enum CommerceOrderState {
  NEW = 'NEW',
  PROCESSING = 'PROCESSING',
  ON_DELIVERY = 'ON_DELIVERY',
  DELIVERED = 'DELIVERED',
  DONE = 'DONE',
  CANCELLED = 'CANCELLED',
}

export type CommerceOrderStatesWidgetData = {
  __typename?: 'CommerceOrderStatesWidgetData';
  state: CommerceOrderState;
  count: Scalars['Int'];
  total: Scalars['Float'];
};

export type CommerceOrderUpdateInput = {
  shopId?: Maybe<Scalars['Int']>;
  state?: Maybe<CommerceOrderState>;
  paymentState?: Maybe<CommerceOrderPaymentState>;
  paymentStartedAt?: Maybe<Scalars['DateTime']>;
  paidAmount?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Int']>;
  externalEntityId?: Maybe<Scalars['Int']>;
  code?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Float']>;
};

export type CommerceSettings = {
  __typename?: 'CommerceSettings';
  id: Scalars['Int'];
  clientId: Scalars['Int'];
  access?: Maybe<Scalars['JSON']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type CommerceSettingsConnection = DefaultConnection & {
  __typename?: 'CommerceSettingsConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<CommerceSettings>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type CommerceSettingsCreateInput = {
  access?: Maybe<Scalars['JSON']>;
};

export type CommerceSettingsUpdateInput = {
  access?: Maybe<Scalars['JSON']>;
};

export type CommerceShop = {
  __typename?: 'CommerceShop';
  id: Scalars['Int'];
  clientId: Scalars['Int'];
  state: CommerceShopState;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  codeMask?: Maybe<Scalars['String']>;
  catalogueId: Scalars['Int'];
  currencyId?: Maybe<Scalars['Int']>;
  saleEnabled: Scalars['Boolean'];
  settings?: Maybe<Scalars['JSON']>;
  catalogue?: Maybe<Catalogue>;
  currency?: Maybe<Currency>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type CommerceShopConnection = DefaultConnection & {
  __typename?: 'CommerceShopConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<CommerceShop>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type CommerceShopCreateInput = {
  state?: Maybe<CommerceShopState>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  codeMask?: Maybe<Scalars['String']>;
  catalogueId: Scalars['Int'];
  currencyId?: Maybe<Scalars['Int']>;
  saleEnabled?: Maybe<Scalars['Boolean']>;
  settings?: Maybe<Scalars['JSON']>;
};

export type CommerceShopGraph = {
  __typename?: 'CommerceShopGraph';
  name: Scalars['String'];
  data?: Maybe<Array<CommerceShopGraphData>>;
};

export type CommerceShopGraphData = {
  __typename?: 'CommerceShopGraphData';
  date: Scalars['DateTime'];
  total: Scalars['Float'];
  paidAmount: Scalars['Float'];
};

export type CommerceShopInfo = {
  __typename?: 'CommerceShopInfo';
  id: Scalars['Int'];
  clientId: Scalars['Int'];
  state: CommerceShopState;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  codeMask?: Maybe<Scalars['String']>;
  catalogueId: Scalars['Int'];
  currencyId?: Maybe<Scalars['Int']>;
  saleEnabled: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  items: Scalars['Int'];
  orders: Scalars['Int'];
};

export enum CommerceShopState {
  INACTIVE = 'INACTIVE',
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
}

export type CommerceShopSummary = {
  __typename?: 'CommerceShopSummary';
  ordersCount: Scalars['Int'];
  itemsCount: Scalars['Int'];
  topItems?: Maybe<Array<CatalogueItem>>;
};

export type CommerceShopUpdateInput = {
  state?: Maybe<CommerceShopState>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  codeMask?: Maybe<Scalars['String']>;
  catalogueId?: Maybe<Scalars['Int']>;
  currencyId?: Maybe<Scalars['Int']>;
  saleEnabled?: Maybe<Scalars['Boolean']>;
  settings?: Maybe<Scalars['JSON']>;
};

export type CommerceSummaryWidget = {
  __typename?: 'CommerceSummaryWidget';
  shop: CommerceShop;
  summary: CommerceShopSummary;
  graphs?: Maybe<Array<CommerceShopGraph>>;
};

export type CommerceTemplate = {
  __typename?: 'CommerceTemplate';
  id: Scalars['Int'];
  clientId: Scalars['Int'];
  name: Scalars['String'];
  preview?: Maybe<Scalars['String']>;
  body: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type CommerceTemplateConnection = DefaultConnection & {
  __typename?: 'CommerceTemplateConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<CommerceTemplate>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type CommerceTemplateCreateInput = {
  name: Scalars['String'];
  preview?: Maybe<Scalars['String']>;
  body: Scalars['String'];
};

export type CommerceTemplateUpdateInput = {
  name?: Maybe<Scalars['String']>;
  preview?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
};

export type CommerceWidget = {
  __typename?: 'CommerceWidget';
  shops: Array<CommerceShopInfo>;
};

export type CommonGraph = {
  __typename?: 'CommonGraph';
  name: Scalars['String'];
  dataset: Array<CommonGraphDataset>;
};

export type CommonGraphDataset = {
  __typename?: 'CommonGraphDataset';
  label: Scalars['String'];
  data: Array<CommonGraphDatasetData>;
};

export type CommonGraphDatasetData = {
  __typename?: 'CommonGraphDatasetData';
  x: Scalars['Date'];
  y: Scalars['Int'];
};

export type ConfirmOrderPaymentInput = {
  amount: Scalars['Float'];
  currencyId: Scalars['Int'];
  providerId: Scalars['Int'];
  orderId: Scalars['Int'];
  orderType: PaymentTransactionOrderType;
  /** Статус платежа: true - успешный, false - ошибка оплаты */
  isSuccess: Scalars['Boolean'];
  data?: Maybe<Scalars['JSON']>;
};

export type ConfirmOrderPaymentResponse = {
  __typename?: 'ConfirmOrderPaymentResponse';
  code: Scalars['String'];
  transaction: PaymentTransaction;
  user: User;
};

export type Constant = {
  __typename?: 'Constant';
  name: Scalars['String'];
  value: Scalars['Int'];
};

export type Constants = {
  __typename?: 'Constants';
  MAX_PARTIAL_MAILING_QUEUE_DATA: Scalars['Int'];
};

export type ContentTranslation = {
  __typename?: 'ContentTranslation';
  id: Scalars['Int'];
  clientId: Scalars['Int'];
  channel?: Maybe<Scalars['String']>;
  localeId: Scalars['Int'];
  key: Scalars['String'];
  value: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type ContentTranslationConnection = DefaultConnection & {
  __typename?: 'ContentTranslationConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<ContentTranslation>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type ContentTranslationCreateInput = {
  channel?: Maybe<Scalars['String']>;
  localeId: Scalars['Int'];
  key: Scalars['String'];
  value: Scalars['String'];
};

export type ContentTranslationSaveInput = {
  channel?: Maybe<Scalars['String']>;
  key: Scalars['String'];
  value: Scalars['String'];
  localeId: Scalars['Int'];
};

export type ContentTranslationUpdateInput = {
  channel?: Maybe<Scalars['String']>;
  localeId?: Maybe<Scalars['Int']>;
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type CoordsInput = {
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export type CreateExtensionFieldInput = {
  relationId: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  dataType: EntityDataType;
  isReadOnly?: Maybe<Scalars['Boolean']>;
  i18n?: Maybe<Scalars['Int']>;
  sort: Scalars['Int'];
  translations?: Maybe<Array<ExtensionFieldTranslationInput>>;
};

export type CreateSystemUserInput = {
  username: Scalars['String'];
  email: Scalars['String'];
  password: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  state?: Maybe<SystemUserState>;
};

export type CreateUserDeviceInput = {
  userId?: Maybe<Scalars['Int']>;
  deviceId: Scalars['String'];
  installId: Scalars['String'];
  bundleId?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  deviceLocale?: Maybe<Scalars['String']>;
  deviceName?: Maybe<Scalars['String']>;
  deviceModel?: Maybe<Scalars['String']>;
  installReferrer?: Maybe<Scalars['String']>;
  manufacturer?: Maybe<Scalars['String']>;
  systemName?: Maybe<Scalars['String']>;
  systemVersion?: Maybe<Scalars['String']>;
  appVersion?: Maybe<Scalars['String']>;
  userAgent?: Maybe<Scalars['String']>;
  pushToken?: Maybe<Scalars['String']>;
  expoPushToken?: Maybe<Scalars['String']>;
  APNSPushToken?: Maybe<Scalars['String']>;
};

export type CreateUserLocationInput = {
  userId?: Maybe<Scalars['Int']>;
  deviceId?: Maybe<Scalars['String']>;
  coords?: Maybe<Scalars['JSON']>;
};

export type CryptogramPaymentInput = {
  amount: Scalars['Float'];
  /** Идентификатор заказа */
  orderId?: Maybe<Scalars['Int']>;
  /** Код заказа */
  orderCode?: Maybe<Scalars['String']>;
  orderType?: Maybe<PaymentTransactionOrderType>;
  userId?: Maybe<Scalars['Int']>;
  cryptogram: Scalars['String'];
  cardHolderName: Scalars['String'];
  expiresAt?: Maybe<Scalars['String']>;
  currencyId: Scalars['Int'];
  providerId: Scalars['Int'];
  IPAddress: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  saveBankCard?: Maybe<Scalars['Boolean']>;
  note?: Maybe<Scalars['String']>;
  receipt?: Maybe<PaymentReceiptInput>;
};

export type Currency = {
  __typename?: 'Currency';
  id: Scalars['Int'];
  iso3: Scalars['String'];
  name: Scalars['String'];
  sign: Scalars['String'];
  isDefault: Scalars['Boolean'];
  isVirtual: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type DatePicker = {
  start: Scalars['String'];
  finish: Scalars['String'];
};

export type DefaultConnection = {
  total?: Maybe<Scalars['Int']>;
  cursor: Scalars['Int'];
  limit: Scalars['Int'];
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type DefaultQueryInput = {
  filters?: Maybe<Array<FilterInput>>;
  /** Сортировка */
  sort?: Maybe<Array<Maybe<DefaultSortInput>>>;
  /** Пропуск элементов */
  cursor?: Maybe<Scalars['Int']>;
  /** Лимит выборки */
  limit?: Maybe<Scalars['Int']>;
  /** Строковый поиск */
  search?: Maybe<Scalars['String']>;
  /** Выдывать все поля? */
  allFields?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
  /** Получение только необходимых аттрибутов модели */
  attributes?: Maybe<Array<Scalars['String']>>;
};

/** Сортировка данных */
export type DefaultSortInput = {
  /** поле сортировки */
  field: Scalars['String'];
  /** Направление сортировки */
  direction: SortingDirection;
};

export enum DeviceOsEnum {
  IOS = 'iOS',
  ANDROID = 'Android',
  OTHER = 'other',
}

export type Dispute = {
  __typename?: 'Dispute';
  id: Scalars['Int'];
  clientId: Scalars['Int'];
  state: DisputeState;
  result: DisputeResult;
  confirmation: DisputeConfirmation;
  userId: Scalars['Int'];
  matchId: Scalars['Int'];
  amount: Scalars['Int'];
  winAmount: Scalars['Int'];
  text: Scalars['String'];
  user: User;
  match: TournamentMatch;
  responses: Array<Maybe<DisputeDisputeResponse>>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export enum DisputeConfirmation {
  WAITING = 'WAITING',
  WIN = 'WIN',
  LOSS = 'LOSS',
}

export type DisputeConnection = DefaultConnection & {
  __typename?: 'DisputeConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<Dispute>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type DisputeCreateInput = {
  matchId: Scalars['Int'];
  amount: Scalars['Int'];
  text: Scalars['String'];
  userId?: Maybe<Scalars['Int']>;
};

export type DisputeDisputeResponse = {
  __typename?: 'DisputeDisputeResponse';
  id: Scalars['Int'];
  clientId: Scalars['Int'];
  disputeId: Scalars['Int'];
  state: DisputeDisputeResponseState;
  userId: Scalars['Int'];
  amount: Scalars['Int'];
  comment: Scalars['String'];
  respondedAt: Scalars['DateTime'];
  user: User;
  dispute: Dispute;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type DisputeDisputeResponseConnection = DefaultConnection & {
  __typename?: 'DisputeDisputeResponseConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<DisputeDisputeResponse>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export enum DisputeDisputeResponseState {
  ACTIVE = 'ACTIVE',
  WIN = 'WIN',
  LOSS = 'LOSS',
  WAITING = 'WAITING',
  CANCELLED = 'CANCELLED',
}

export type DisputeRating = {
  __typename?: 'DisputeRating';
  clientId: Scalars['Int'];
  userId: Scalars['Int'];
  position: Scalars['Int'];
  sumPoints: Scalars['Int'];
  countPoints: Scalars['Int'];
  user: User;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type DisputeRatingBySeason = {
  __typename?: 'DisputeRatingBySeason';
  clientId: Scalars['Int'];
  userId: Scalars['Int'];
  seasonId: Scalars['Int'];
  position: Scalars['Int'];
  sumPoints: Scalars['Int'];
  countPoints: Scalars['Int'];
  user: User;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type DisputeRatingBySeasonConnection = DefaultConnection & {
  __typename?: 'DisputeRatingBySeasonConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<DisputeRatingBySeason>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type DisputeRatingByTour = {
  __typename?: 'DisputeRatingByTour';
  clientId: Scalars['Int'];
  userId: Scalars['Int'];
  position: Scalars['Int'];
  tourId: Scalars['Int'];
  sumPoints: Scalars['Int'];
  countPoints: Scalars['Int'];
  user: User;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type DisputeRatingByTourConnection = DefaultConnection & {
  __typename?: 'DisputeRatingByTourConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<DisputeRatingByTour>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type DisputeRatingConnection = DefaultConnection & {
  __typename?: 'DisputeRatingConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<DisputeRating>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type DisputeRatingTotal = {
  __typename?: 'DisputeRatingTotal';
  clientId: Scalars['Int'];
  user: User;
  positionBySeason?: Maybe<Scalars['Int']>;
  positionByTour?: Maybe<Scalars['Int']>;
  position?: Maybe<Scalars['Int']>;
  sumPointsBySeason?: Maybe<Scalars['Int']>;
  sumPointsByTour?: Maybe<Scalars['Int']>;
  sumPoints?: Maybe<Scalars['Int']>;
  userId: Scalars['Int'];
  countBySeason?: Maybe<Scalars['Int']>;
  countByTour?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['Int']>;
};

export type DisputeResponseCreateInput = {
  disputeId: Scalars['Int'];
  amount: Scalars['Int'];
  comment: Scalars['String'];
  userId?: Maybe<Scalars['Int']>;
};

export type DisputeResponseUpdateInput = {
  disputeId?: Maybe<Scalars['Int']>;
  state?: Maybe<DisputeDisputeResponseState>;
  userId?: Maybe<Scalars['Int']>;
  amount?: Maybe<Scalars['Int']>;
  comment?: Maybe<Scalars['String']>;
  respondedAt?: Maybe<Scalars['DateTime']>;
};

export enum DisputeResult {
  WIN = 'WIN',
  LOSS = 'LOSS',
  CANCELLED = 'CANCELLED',
  MODERATION = 'MODERATION',
  WAITING = 'WAITING',
}

export type DisputeSettings = {
  __typename?: 'DisputeSettings';
  id: Scalars['Int'];
  clientId: Scalars['Int'];
  pushTemplates?: Maybe<Scalars['JSON']>;
  simulators?: Maybe<Scalars['JSON']>;
  transportId?: Maybe<Scalars['Int']>;
  paymentCurrencyId?: Maybe<Scalars['Int']>;
  registrationRewardAmount?: Maybe<Scalars['Float']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type DisputeSettingsUpdateInput = {
  pushTemplates?: Maybe<Scalars['JSON']>;
  simulators?: Maybe<Scalars['JSON']>;
  transportId?: Maybe<Scalars['Int']>;
  paymentCurrencyId?: Maybe<Scalars['Int']>;
  registrationRewardAmount?: Maybe<Scalars['Float']>;
};

export type DisputeStat = {
  __typename?: 'DisputeStat';
  seasonId: Scalars['Int'];
  count: Scalars['Int'];
};

export enum DisputeState {
  ACTIVE = 'ACTIVE',
  LOCKED = 'LOCKED',
  FINISHED = 'FINISHED',
  DELETED = 'DELETED',
  ARCHIVED = 'ARCHIVED',
}

export type DisputeUpdateInput = {
  state?: Maybe<DisputeState>;
  result?: Maybe<DisputeResult>;
  confirmation?: Maybe<DisputeConfirmation>;
  userId?: Maybe<Scalars['Int']>;
  matchId?: Maybe<Scalars['Int']>;
  amount?: Maybe<Scalars['Int']>;
  winAmount?: Maybe<Scalars['Int']>;
  text?: Maybe<Scalars['String']>;
};

export type EntityDataInput = {
  key: Scalars['String'];
  value?: Maybe<Scalars['String']>;
  type?: Maybe<EntityDataType>;
};

export enum EntityDataType {
  BOOLEAN = 'BOOLEAN',
  NUMBER = 'NUMBER',
  STRING = 'STRING',
  ENUM = 'ENUM',
  ARRAY = 'ARRAY',
  FILES = 'FILES',
  JSON = 'JSON',
}

export enum EntityType {
  MATCH = 'MATCH',
  TOUR = 'TOUR',
  TEAM = 'TEAM',
  EVENT = 'EVENT',
  PLAYER = 'PLAYER',
}

export type Event = {
  __typename?: 'Event';
  id: Scalars['Int'];
  clientId: Scalars['Int'];
  state: EventState;
  title: Scalars['String'];
  eventTypeId: Scalars['Int'];
  eventPlaceId: Scalars['Int'];
  tournamentMatchId?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  saleStartDate?: Maybe<Scalars['DateTime']>;
  saleFinishDate?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  finishDate?: Maybe<Scalars['DateTime']>;
  availableSlots?: Maybe<Scalars['Int']>;
  saleLimit?: Maybe<Scalars['Int']>;
  templates?: Maybe<Scalars['JSON']>;
  agreement?: Maybe<Scalars['String']>;
  whitelist: Scalars['Boolean'];
  isShowPublic: Scalars['Boolean'];
  isFanIdRequired: Scalars['Boolean'];
  linkedEventId?: Maybe<Scalars['Int']>;
  isViewOnly?: Maybe<Scalars['Boolean']>;
  data: Array<ExtensionData>;
  eventType: EventType;
  eventPlace: EventPlace;
  eventAccess?: Maybe<EventEventAccess>;
  tournamentMatch?: Maybe<TournamentMatch>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  loyaltyDiscount?: Maybe<LoyaltyDiscount>;
  price?: Maybe<EventPrice>;
  promo?: Maybe<LoyaltyPromo>;
  parkings?: Maybe<Array<Scalars['Int']>>;
  parkingType?: Maybe<EventParkingType>;
  isSaleEnabled?: Maybe<Scalars['Boolean']>;
};

export type EventConnection = DefaultConnection & {
  __typename?: 'EventConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<Event>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type EventCreateInput = {
  state?: Maybe<EventState>;
  title: Scalars['String'];
  eventTypeId: Scalars['Int'];
  eventPlaceId: Scalars['Int'];
  tournamentMatchId?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  saleStartDate?: Maybe<Scalars['DateTime']>;
  saleFinishDate?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  finishDate?: Maybe<Scalars['DateTime']>;
  availableSlots?: Maybe<Scalars['Int']>;
  saleLimit?: Maybe<Scalars['Int']>;
  templates?: Maybe<Scalars['JSON']>;
  agreement?: Maybe<Scalars['String']>;
  whitelist?: Maybe<Scalars['Boolean']>;
  isShowPublic?: Maybe<Scalars['Boolean']>;
  isFanIdRequired?: Maybe<Scalars['Boolean']>;
  linkedEventId?: Maybe<Scalars['Int']>;
  data?: Maybe<Array<ExtensionUpdateDataInput>>;
};

export type EventEventAccess = {
  __typename?: 'EventEventAccess';
  id: Scalars['Int'];
  eventId: Scalars['Int'];
  isVisibleToPublic: Scalars['Boolean'];
  userGroupToSector?: Maybe<Scalars['JSON']>;
  sectorUserSeatLimit?: Maybe<Scalars['JSON']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type EventEventAccessConnection = DefaultConnection & {
  __typename?: 'EventEventAccessConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<EventEventAccess>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type EventEventAccessCreateInput = {
  eventId: Scalars['Int'];
  isVisibleToPublic: Scalars['Boolean'];
  userGroupToSector?: Maybe<Scalars['JSON']>;
  sectorUserSeatLimit?: Maybe<Scalars['JSON']>;
};

export type EventEventAccessUpdateInput = {
  eventId?: Maybe<Scalars['Int']>;
  isVisibleToPublic?: Maybe<Scalars['Boolean']>;
  userGroupToSector?: Maybe<Scalars['JSON']>;
  sectorUserSeatLimit?: Maybe<Scalars['JSON']>;
};

export type EventEventWhitelist = {
  __typename?: 'EventEventWhitelist';
  id: Scalars['Int'];
  eventId: Scalars['Int'];
  userId: Scalars['Int'];
  sector?: Maybe<Scalars['String']>;
  row?: Maybe<Scalars['String']>;
  seat?: Maybe<Scalars['String']>;
  expiryDate?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type EventEventWhitelistConnection = DefaultConnection & {
  __typename?: 'EventEventWhitelistConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<EventEventWhitelist>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type EventEventWhitelistCreateInput = {
  eventId: Scalars['Int'];
  userId: Scalars['Int'];
  sector?: Maybe<Scalars['String']>;
  row?: Maybe<Scalars['String']>;
  seat?: Maybe<Scalars['String']>;
  expiryDate?: Maybe<Scalars['DateTime']>;
};

export type EventEventWhitelistUpdateInput = {
  eventId?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['Int']>;
  sector?: Maybe<Scalars['String']>;
  row?: Maybe<Scalars['String']>;
  seat?: Maybe<Scalars['String']>;
  expiryDate?: Maybe<Scalars['DateTime']>;
};

export type EventFieldsConnection = DefaultConnection & {
  __typename?: 'EventFieldsConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<ExtensionField>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type EventOrder = {
  __typename?: 'EventOrder';
  id: Scalars['Int'];
  clientId: Scalars['Int'];
  state: EventOrderState;
  paymentState: EventOrderPaymentState;
  discountId?: Maybe<Scalars['Int']>;
  userId: Scalars['Int'];
  eventId: Scalars['Int'];
  code: Scalars['String'];
  total: Scalars['Float'];
  paymentStartedAt?: Maybe<Scalars['DateTime']>;
  paidAmount: Scalars['Float'];
  currencyId: Scalars['Int'];
  reservedTill: Scalars['DateTime'];
  isFiscal?: Maybe<Scalars['Boolean']>;
  meta?: Maybe<Scalars['JSON']>;
  tickets: Array<EventOrderTicket>;
  data: Array<ExtensionData>;
  user: User;
  event: Event;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type EventOrderConnection = DefaultConnection & {
  __typename?: 'EventOrderConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<EventOrder>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type EventOrderCreateInput = {
  state?: Maybe<EventOrderState>;
  paymentState?: Maybe<EventOrderPaymentState>;
  discountId?: Maybe<Scalars['Int']>;
  userId: Scalars['Int'];
  eventId: Scalars['Int'];
  code: Scalars['String'];
  total: Scalars['Float'];
  paymentStartedAt?: Maybe<Scalars['DateTime']>;
  paidAmount: Scalars['Float'];
  currencyId: Scalars['Int'];
  reservedTill: Scalars['DateTime'];
  isFiscal?: Maybe<Scalars['Boolean']>;
  meta?: Maybe<Scalars['JSON']>;
  data?: Maybe<Array<ExtensionUpdateDataInput>>;
};

export type EventOrderFieldsConnection = DefaultConnection & {
  __typename?: 'EventOrderFieldsConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<ExtensionField>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type EventOrderPayloadInput = {
  /** кол-во билетов для тура и экскурсии */
  quantity?: Maybe<Scalars['Int']>;
  /** Источник заказа: WEB, IOS, ANDROID */
  source?: Maybe<Scalars['String']>;
  /** промокод */
  promocode?: Maybe<Scalars['String']>;
  /** идентификатор заказа лояльности */
  loyaltyOrderId?: Maybe<Scalars['Int']>;
  issuePromocode?: Maybe<Scalars['Int']>;
};

export enum EventOrderPaymentState {
  UNPAID = 'UNPAID',
  PAID = 'PAID',
}

export enum EventOrderState {
  NEW = 'NEW',
  PROCESSING = 'PROCESSING',
  ON_DELIVERY = 'ON_DELIVERY',
  DELIVERED = 'DELIVERED',
  DONE = 'DONE',
  CANCELLED = 'CANCELLED',
}

export type EventOrderTicket = {
  __typename?: 'EventOrderTicket';
  id: Scalars['Int'];
  eventOrderId: Scalars['Int'];
  eventId: Scalars['Int'];
  barcode?: Maybe<Scalars['String']>;
  price: Scalars['Float'];
  quantity: Scalars['Int'];
  discountId?: Maybe<Scalars['Int']>;
  data: Array<ExtensionData>;
  eventOrder: EventOrder;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  pathToPDF?: Maybe<Scalars['String']>;
  pathToPKPass?: Maybe<Scalars['String']>;
  sector?: Maybe<Scalars['String']>;
  row?: Maybe<Scalars['Int']>;
  seat?: Maybe<Scalars['Int']>;
  fanCode?: Maybe<Scalars['String']>;
};

export type EventOrderTicketConnection = DefaultConnection & {
  __typename?: 'EventOrderTicketConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<EventOrderTicket>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type EventOrderTicketCreateInput = {
  eventOrderId: Scalars['Int'];
  eventId: Scalars['Int'];
  barcode?: Maybe<Scalars['String']>;
  price: Scalars['Float'];
  quantity: Scalars['Int'];
  discountId?: Maybe<Scalars['Int']>;
  data?: Maybe<Array<ExtensionUpdateDataInput>>;
};

export type EventOrderTicketFieldsConnection = DefaultConnection & {
  __typename?: 'EventOrderTicketFieldsConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<ExtensionField>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type EventOrderTicketUpdateInput = {
  eventOrderId?: Maybe<Scalars['Int']>;
  eventId?: Maybe<Scalars['Int']>;
  barcode?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Int']>;
  discountId?: Maybe<Scalars['Int']>;
  data?: Maybe<Array<ExtensionUpdateDataInput>>;
};

export type EventOrderUpdateInput = {
  state?: Maybe<EventOrderState>;
  paymentState?: Maybe<EventOrderPaymentState>;
  discountId?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['Int']>;
  eventId?: Maybe<Scalars['Int']>;
  code?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Float']>;
  paymentStartedAt?: Maybe<Scalars['DateTime']>;
  paidAmount?: Maybe<Scalars['Float']>;
  currencyId?: Maybe<Scalars['Int']>;
  reservedTill?: Maybe<Scalars['DateTime']>;
  isFiscal?: Maybe<Scalars['Boolean']>;
  meta?: Maybe<Scalars['JSON']>;
  data?: Maybe<Array<ExtensionUpdateDataInput>>;
};

export enum EventParkingType {
  /** Наземная */
  UNDERGROUND = 'UNDERGROUND',
  /** Подземная */
  GROUND = 'GROUND',
}

export type EventPlace = {
  __typename?: 'EventPlace';
  id: Scalars['Int'];
  clientId: Scalars['Int'];
  preview?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  address?: Maybe<Scalars['String']>;
  localeIdx?: Maybe<Array<Scalars['Int']>>;
  locales?: Maybe<Array<SystemLocale>>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type EventPlaceConnection = DefaultConnection & {
  __typename?: 'EventPlaceConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<EventPlace>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type EventPlaceCreateInput = {
  preview?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  address?: Maybe<Scalars['String']>;
  localeIdx?: Maybe<Array<Scalars['Int']>>;
};

export type EventPlaceSector = {
  __typename?: 'EventPlaceSector';
  id: Scalars['Int'];
  eventPlaceId: Scalars['Int'];
  name: Scalars['String'];
  isSectorSaleEnabled: Scalars['Boolean'];
  userSeatLimit?: Maybe<Scalars['Int']>;
  seatsCount?: Maybe<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  /** поле обязательно только в query allEventSectorForSale */
  isAllowed?: Maybe<Scalars['Boolean']>;
};

export type EventPlaceSectorConnection = DefaultConnection & {
  __typename?: 'EventPlaceSectorConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<EventPlaceSector>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type EventPlaceSectorCreateInput = {
  eventPlaceId: Scalars['Int'];
  name: Scalars['String'];
  isSectorSaleEnabled: Scalars['Boolean'];
  userSeatLimit?: Maybe<Scalars['Int']>;
  seatsCount?: Maybe<Scalars['Int']>;
};

export type EventPlaceSectorUpdateInput = {
  eventPlaceId?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  isSectorSaleEnabled?: Maybe<Scalars['Boolean']>;
  userSeatLimit?: Maybe<Scalars['Int']>;
  seatsCount?: Maybe<Scalars['Int']>;
};

export type EventPlaceUpdateInput = {
  preview?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  localeIdx?: Maybe<Array<Scalars['Int']>>;
};

export type EventPrice = {
  __typename?: 'EventPrice';
  value: Scalars['Int'];
  discountValue?: Maybe<Scalars['Int']>;
};

export type EventReserve = {
  __typename?: 'EventReserve';
  id: Scalars['Int'];
  clientId: Scalars['Int'];
  uuid: Scalars['UUID'];
  eventId: Scalars['Int'];
  userId: Scalars['Int'];
  expiryDate: Scalars['DateTime'];
  meta?: Maybe<Scalars['JSON']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  seats: Array<EventReserveSeat>;
};

export type EventReserveConnection = DefaultConnection & {
  __typename?: 'EventReserveConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<EventReserve>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type EventReserveCreateInput = {
  uuid?: Maybe<Scalars['UUID']>;
  eventId: Scalars['Int'];
  userId: Scalars['Int'];
  expiryDate: Scalars['DateTime'];
  meta?: Maybe<Scalars['JSON']>;
};

export type EventReserveSeat = {
  __typename?: 'EventReserveSeat';
  seatId: Scalars['Int'];
  sector: Scalars['String'];
  row: Scalars['Int'];
  seat: Scalars['Int'];
  infotechEventId: Scalars['String'];
  price: Scalars['Int'];
};

export type EventReserveUpdateInput = {
  uuid?: Maybe<Scalars['UUID']>;
  eventId?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['Int']>;
  expiryDate?: Maybe<Scalars['DateTime']>;
  meta?: Maybe<Scalars['JSON']>;
};

export type EventSector = {
  __typename?: 'EventSector';
  key: Scalars['String'];
  name: Scalars['String'];
  category: EventSectorCategory;
  quantity?: Maybe<Scalars['Int']>;
  rowsCount: Scalars['Int'];
  eventSectorPrice?: Maybe<EventSectorPrice>;
  discountSectorPrice?: Maybe<EventSectorPrice>;
  discountAvailableSlots?: Maybe<Scalars['Int']>;
  isSectorSaleEnabled: Scalars['Boolean'];
  sectorId?: Maybe<Scalars['Int']>;
  rotation: Scalars['Int'];
  loyaltyDiscount?: Maybe<LoyaltyDiscount>;
};

export enum EventSectorCategory {
  VIP = 'VIP',
  FAMILY_SECTOR = 'FAMILY_SECTOR',
  SECTOR = 'SECTOR',
  LODGE = 'LODGE',
  FAN_LOUNGE = 'FAN_LOUNGE',
}

export type EventSectorPrice = {
  __typename?: 'EventSectorPrice';
  priceCategoryId: Scalars['Int'];
  priceMin: Scalars['Float'];
  priceMax: Scalars['Float'];
  priceDiscount: Scalars['Float'];
};

export type EventSectorRow = {
  __typename?: 'EventSectorRow';
  row: Scalars['Int'];
  seats: Array<EventSectorSeat>;
};

export type EventSectorSeat = {
  __typename?: 'EventSectorSeat';
  seatId?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  row: Scalars['Int'];
  seat?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['Float']>;
  isReserved?: Maybe<Scalars['Boolean']>;
  discount?: Maybe<ReserveDiscount>;
};

export type EventSession = {
  __typename?: 'EventSession';
  id: Scalars['String'];
};

export type EventSettings = {
  __typename?: 'EventSettings';
  id: Scalars['Int'];
  clientId?: Maybe<Scalars['Int']>;
  pdf?: Maybe<Scalars['JSON']>;
  applePass?: Maybe<Scalars['JSON']>;
  email?: Maybe<Scalars['JSON']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type EventSettingsUpdateInput = {
  pdf?: Maybe<Scalars['JSON']>;
  applePass?: Maybe<Scalars['JSON']>;
  email?: Maybe<Scalars['JSON']>;
};

export enum EventState {
  INACTIVE = 'INACTIVE',
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
}

export type EventType = {
  __typename?: 'EventType';
  id: Scalars['Int'];
  clientId: Scalars['Int'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  templates?: Maybe<Scalars['JSON']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type EventTypeConnection = DefaultConnection & {
  __typename?: 'EventTypeConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<EventType>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type EventTypeCreateInput = {
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  templates?: Maybe<Scalars['JSON']>;
};

export type EventTypeUpdateInput = {
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  templates?: Maybe<Scalars['JSON']>;
};

export type EventTypesConfig = {
  __typename?: 'EventTypesConfig';
  abonement?: Maybe<EventType>;
  parking?: Maybe<EventType>;
  tour?: Maybe<EventType>;
  match?: Maybe<EventType>;
  concert?: Maybe<EventType>;
};

export type EventUpdateInput = {
  state?: Maybe<EventState>;
  title?: Maybe<Scalars['String']>;
  eventTypeId?: Maybe<Scalars['Int']>;
  eventPlaceId?: Maybe<Scalars['Int']>;
  tournamentMatchId?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  saleStartDate?: Maybe<Scalars['DateTime']>;
  saleFinishDate?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  finishDate?: Maybe<Scalars['DateTime']>;
  availableSlots?: Maybe<Scalars['Int']>;
  saleLimit?: Maybe<Scalars['Int']>;
  templates?: Maybe<Scalars['JSON']>;
  agreement?: Maybe<Scalars['String']>;
  whitelist?: Maybe<Scalars['Boolean']>;
  isShowPublic?: Maybe<Scalars['Boolean']>;
  isFanIdRequired?: Maybe<Scalars['Boolean']>;
  linkedEventId?: Maybe<Scalars['Int']>;
  data?: Maybe<Array<ExtensionUpdateDataInput>>;
};

/** Данные для виджета с активными событиями. */
export type EventWidgetActiveEvents = {
  __typename?: 'EventWidgetActiveEvents';
  /** Количество активных событий. Все у которых статус != FINISHED */
  activeCount: Scalars['Int'];
  /** Событий сегодня. */
  todayCount: Scalars['Int'];
};

export type EventWidgetEventTypesSales = {
  __typename?: 'EventWidgetEventTypesSales';
  ticketTotalSale: Scalars['Int'];
  graphs: Array<WidgetSummaryGraph>;
};

export type EventWidgetNearestMatch = {
  __typename?: 'EventWidgetNearestMatch';
  ticketTotalSale: Scalars['Int'];
  event: Event;
};

export type EventWidgetSales = {
  __typename?: 'EventWidgetSales';
  eventSaleCount: Scalars['Int'];
  ticketTotalSale: Scalars['Int'];
};

export type EventWidgetTicketSales = {
  __typename?: 'EventWidgetTicketSales';
  summary: EventWidgetTicketSalesTotals;
  graphs: Array<CommonGraph>;
};

export type EventWidgetTicketSalesInput = {
  eventId?: Maybe<Scalars['Int']>;
  dates?: Maybe<Array<Scalars['DateTime']>>;
};

export type EventWidgetTicketSalesTotals = {
  __typename?: 'EventWidgetTicketSalesTotals';
  /** Всего билетов для продажи */
  totalCount: Scalars['Int'];
  /** Количество проданных билетов */
  soldCount: Scalars['Int'];
  /** Количество билетов свободных для продажи */
  availableCount: Scalars['Int'];
  /** Сумма проданных билетов */
  soldAmount: Scalars['Float'];
};

export type Excursion = {
  __typename?: 'Excursion';
  about: Scalars['String'];
  image: Scalars['String'];
};

export type ExportFields = {
  __typename?: 'ExportFields';
  name: Scalars['String'];
  title: Scalars['String'];
  isExtension: Scalars['Boolean'];
};

export type ExportFieldsInput = {
  name: Scalars['String'];
  title: Scalars['String'];
  isExtension: Scalars['Boolean'];
};

/** Формат экспортируемых данных */
export enum ExportFormat {
  CSV = 'CSV',
  XLS = 'XLS',
  XLSX = 'XLSX',
  JSON = 'JSON',
}

export type ExportResponse = {
  __typename?: 'ExportResponse';
  model: Scalars['String'];
  fields: Array<ExportFields>;
  fileUrl?: Maybe<Scalars['String']>;
  format?: Maybe<ExportFormat>;
};

export type ExportSettingsInput = {
  format: ExportFormat;
  fields: Array<ExportFieldsInput>;
};

export type ExportUnisenderSettingsInput = {
  listId?: Maybe<Scalars['String']>;
  listTitle?: Maybe<Scalars['String']>;
  fields: Array<ExportFieldsInput>;
};

export type ExtensionData = {
  __typename?: 'ExtensionData';
  id: Scalars['Int'];
  fieldId: Scalars['Int'];
  localeId?: Maybe<Scalars['Int']>;
  instanceId: Scalars['Int'];
  value?: Maybe<Scalars['JSON']>;
  key: Scalars['String'];
  field: ExtensionField;
  translations: Array<ExtensionDataTranslation>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type ExtensionDataTranslation = {
  __typename?: 'ExtensionDataTranslation';
  id: Scalars['Int'];
  localeId: Scalars['Int'];
  dataId: Scalars['Int'];
  value: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type ExtensionField = {
  __typename?: 'ExtensionField';
  id: Scalars['Int'];
  relationId: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  dataType: EntityDataType;
  isReadOnly: Scalars['Boolean'];
  i18n?: Maybe<Scalars['Int']>;
  sort: Scalars['Int'];
  translations: Array<ExtensionFieldTranslation>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type ExtensionFieldTranslation = {
  __typename?: 'ExtensionFieldTranslation';
  id: Scalars['Int'];
  localeId: Scalars['Int'];
  fieldId: Scalars['Int'];
  value: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type ExtensionFieldTranslationInput = {
  localeId: Scalars['Int'];
  value: Scalars['String'];
};

export type ExtensionUpdateDataInput = {
  key?: Maybe<Scalars['String']>;
  fieldId?: Maybe<Scalars['Int']>;
  localeId?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['String']>;
};

export type File = {
  __typename?: 'File';
  url?: Maybe<Scalars['String']>;
  file?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
};

export enum FilterDataType {
  ARRAY = 'ARRAY',
  STRING = 'STRING',
  NUMBER = 'NUMBER',
  BOOLEAN = 'BOOLEAN',
  DATE = 'DATE',
  TIME = 'TIME',
  ENUM = 'ENUM',
}

export type FilterInput = {
  field: Scalars['String'];
  dataType?: Maybe<FilterDataType>;
  isRange?: Maybe<Scalars['Boolean']>;
  isExclude?: Maybe<Scalars['Boolean']>;
  isSearch?: Maybe<Scalars['Boolean']>;
  value: Scalars['String'];
};

export type FilterItem = {
  __typename?: 'FilterItem';
  field: Scalars['String'];
  type: FilterType;
  dataType: FilterDataType;
  options?: Maybe<Array<Maybe<FilterItemOption>>>;
  isRangeAvailable: Scalars['Boolean'];
  isExcludAvailable: Scalars['Boolean'];
  min?: Maybe<Scalars['Int']>;
  max?: Maybe<Scalars['Int']>;
  step?: Maybe<Scalars['Int']>;
};

export type FilterItemOption = {
  __typename?: 'FilterItemOption';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export enum FilterType {
  INPUT = 'INPUT',
  SELECT = 'SELECT',
  SELECT_MULTIPLE = 'SELECT_MULTIPLE',
  CHECKBOX = 'CHECKBOX',
  RANGE = 'RANGE',
}

export type FoodItem = {
  __typename?: 'FoodItem';
  id: Scalars['Int'];
  state: CatalogueItemState;
  name: Scalars['String'];
  preview?: Maybe<Scalars['String']>;
  eventId?: Maybe<Array<Scalars['Int']>>;
  price?: Maybe<CatalogueItemPrice>;
  parentId: Array<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  categoryId?: Maybe<Scalars['String']>;
  images: Array<File>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type FoodItemConnection = DefaultConnection & {
  __typename?: 'FoodItemConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<FoodItem>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type FoodOrder = {
  __typename?: 'FoodOrder';
  id: Scalars['Int'];
  shopId: Scalars['Int'];
  clientId: Scalars['Int'];
  state: CommerceOrderState;
  paymentState: CommerceOrderPaymentState;
  userId: Scalars['Int'];
  user: User;
  externalEntityId?: Maybe<Scalars['Int']>;
  code?: Maybe<Scalars['String']>;
  total: Scalars['Float'];
  paidAmount: Scalars['Float'];
  /** Сектор */
  sector: Scalars['Int'];
  /** Ряд */
  row: Scalars['Int'];
  /** Место */
  seat: Scalars['Int'];
  /** Способ доставки */
  deliveryMethod: FoodOrderDeliveryMethod;
  items?: Maybe<Array<FoodOrderItem>>;
  data?: Maybe<Array<CommerceOrderData>>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type FoodOrderConnection = DefaultConnection & {
  __typename?: 'FoodOrderConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<FoodOrder>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type FoodOrderCreateInput = {
  state?: Maybe<CommerceOrderState>;
  paymentState?: Maybe<CommerceOrderPaymentState>;
  /** Способ доставки */
  deliveryMethod?: Maybe<FoodOrderDeliveryMethod>;
  total?: Maybe<Scalars['Float']>;
  items: Array<OrderItemInput>;
  data?: Maybe<Array<OrderDataInput>>;
};

/** Способы доставки */
export enum FoodOrderDeliveryMethod {
  /** Фудкорт */
  FOOD_COURT = 'FOOD_COURT',
  /** На сектор */
  SECTOR = 'SECTOR',
}

export type FoodOrderItem = {
  __typename?: 'FoodOrderItem';
  id: Scalars['Int'];
  state: CatalogueItemState;
  name: Scalars['String'];
  preview?: Maybe<Scalars['String']>;
  eventId?: Maybe<Array<Scalars['Int']>>;
  /** Кол-во заказанного товара из каталога в заказе */
  quantity: Scalars['Int'];
  /** цена товара за 1 единицу на момент заказа */
  price: Scalars['Float'];
  /** Cумма позиции в заказе */
  total: Scalars['Float'];
  currencyId: Scalars['Int'];
  parentId: Array<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  categoryId?: Maybe<Scalars['String']>;
  images: Array<File>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type FoodSale = {
  __typename?: 'FoodSale';
  isEnabled: Scalars['Boolean'];
  saleStartDate: Scalars['DateTime'];
  saleFinishDate: Scalars['DateTime'];
  event: Event;
  foodSectors: Array<FoodSector>;
  /** Способ доставки */
  deliveryMethod: Array<FoodOrderDeliveryMethod>;
  /** Ограничения по суммам заказа */
  allowedPrices: FoodSaleAllowedPrices;
};

export type FoodSaleAllowedPrices = {
  __typename?: 'FoodSaleAllowedPrices';
  /** Минимальная сумма заказа на сектор */
  minSectorPrice: Scalars['Float'];
  /** Максимальная сумма заказа на сектор */
  maxSectorPrice: Scalars['Float'];
  /** Минимальная сумма заказа на фудкорт */
  minFoodCourtPrice: Scalars['Float'];
  /** Максимальная сумма заказа на фудкорт */
  maxFoodCourtPrice: Scalars['Float'];
};

export type FoodSector = {
  __typename?: 'FoodSector';
  sector: Scalars['Int'];
  rows: Array<FoodSectorRow>;
};

export type FoodSectorRow = {
  __typename?: 'FoodSectorRow';
  row: Scalars['Int'];
  seats: Array<Scalars['Int']>;
};

export type GenericObject = {
  __typename?: 'GenericObject';
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
};

export type GeoPoint = {
  __typename?: 'GeoPoint';
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
};

export type ImportColumns = {
  __typename?: 'ImportColumns';
  sort: Scalars['Int'];
  name: Scalars['String'];
  field: Scalars['String'];
};

export type ImportFields = {
  __typename?: 'ImportFields';
  name: Scalars['String'];
  title: Scalars['String'];
  isExtension: Scalars['Boolean'];
  isRequired: Scalars['Boolean'];
};

export type ImportFieldsInput = {
  name: Scalars['String'];
  title: Scalars['String'];
  isExtension: Scalars['Boolean'];
};

export type ImportFile = {
  __typename?: 'ImportFile';
  name: Scalars['String'];
  size: Scalars['Int'];
  rowsCount: Scalars['Int'];
  mimeType: Scalars['String'];
  colSeparator?: Maybe<Scalars['String']>;
  rowSeparator?: Maybe<Scalars['String']>;
};

export type ImportFileInput = {
  name: Scalars['String'];
  size: Scalars['Int'];
  rowsCount: Scalars['Int'];
  mimeType: Scalars['String'];
  colSeparator?: Maybe<Scalars['String']>;
  rowSeparator?: Maybe<Scalars['String']>;
};

export type ImportInput = {
  file: ImportFileInput;
  columns: Array<ImportFieldsInput>;
  model: Scalars['String'];
  additional?: Maybe<Scalars['JSON']>;
};

export type ImportUploadResponse = {
  __typename?: 'ImportUploadResponse';
  file: ImportFile;
  selection: Array<Scalars['JSON']>;
  columns: Array<ImportColumns>;
  model: Scalars['String'];
  fields: Array<ImportFields>;
};

export type InitPaymentGatewayInput = {
  /** Номер заказа */
  orderId: Scalars['Int'];
  /** Тип заказа */
  orderType: PaymentTransactionOrderType;
  /** Url для возврата */
  redirectUrl: Scalars['String'];
  /** Комментарий к оплате */
  comment?: Maybe<Scalars['String']>;
};

export type InitPaymentGatewayResponse = {
  __typename?: 'InitPaymentGatewayResponse';
  /** Идентификатор заказа */
  orderId: Scalars['Int'];
  /** Тип заказа - ECOMMERCE, TICKET */
  orderType: PaymentTransactionOrderType;
  /** Код заказа */
  code?: Maybe<Scalars['String']>;
  /** Провайдер платежа */
  provider: PaymentProviderEnum;
  /** Настройки провайдера */
  settings?: Maybe<Scalars['JSON']>;
};

export type InitPaymentInput = {
  providerId: Scalars['Int'];
  backref: Scalars['String'];
  orderId: Scalars['Int'];
  orderType: PaymentTransactionOrderType;
  comment?: Maybe<Scalars['String']>;
};

export type InitPaymentResponse = {
  __typename?: 'InitPaymentResponse';
  providerId: Scalars['Int'];
  amount: Scalars['Float'];
  orderId: Scalars['Int'];
  orderType: PaymentTransactionOrderType;
  code?: Maybe<Scalars['String']>;
  /** Данные платежа от платежного провайдера */
  data?: Maybe<Scalars['JSON']>;
};

export type KidsClubInfo = {
  __typename?: 'KidsClubInfo';
  faq: Array<ClubFaqCategories>;
  comics: Array<ClubComics>;
};

export enum LastPurchaseEnum {
  LESS_THAN_MONTH = 'less_than_month',
  MORE_THAN_MONTH = 'more_than_month',
  LESS_THAN_HALF_YEAR = 'less_than_half_year',
  MORE_THAN_HALF_YEAR = 'more_than_half_year',
}

export type Locale = {
  __typename?: 'Locale';
  id: Scalars['Int'];
  iso: Scalars['String'];
  title: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export enum LocaleEnum {
  RU = 'RU',
  EN = 'EN',
}

export type LoyaltyAccountSummary = {
  __typename?: 'LoyaltyAccountSummary';
  applicationsCount: Scalars['Int'];
  accountsCount: Scalars['Int'];
  processedCount: Scalars['Int'];
  unprocessedCount: Scalars['Int'];
};

export type LoyaltyApplication = {
  __typename?: 'LoyaltyApplication';
  id: Scalars['Int'];
  clientId: Scalars['Int'];
  partnerId: Scalars['Int'];
  userId: Scalars['Int'];
  state: LoyaltyApplicationState;
  meta?: Maybe<Scalars['JSON']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type LoyaltyApplicationConnection = DefaultConnection & {
  __typename?: 'LoyaltyApplicationConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<LoyaltyApplication>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type LoyaltyApplicationCreateInput = {
  partnerId: Scalars['Int'];
  userId: Scalars['Int'];
  state?: Maybe<LoyaltyApplicationState>;
  meta?: Maybe<Scalars['JSON']>;
};

export enum LoyaltyApplicationState {
  NEW = 'NEW',
  PROCESSING = 'PROCESSING',
  ACCEPTED = 'ACCEPTED',
  DECLINED = 'DECLINED',
}

export type LoyaltyApplicationUpdateInput = {
  partnerId?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['Int']>;
  state?: Maybe<LoyaltyApplicationState>;
  meta?: Maybe<Scalars['JSON']>;
};

export type LoyaltyDiscount = {
  __typename?: 'LoyaltyDiscount';
  order?: Maybe<LoyaltyOrder>;
  discountPercent?: Maybe<Scalars['Float']>;
};

export type LoyaltyItem = {
  __typename?: 'LoyaltyItem';
  id: Scalars['Int'];
  state: CatalogueItemState;
  name: Scalars['String'];
  preview?: Maybe<Scalars['String']>;
  eventId?: Maybe<Array<Scalars['Int']>>;
  price?: Maybe<CatalogueItemPrice>;
  parentId: Array<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  information?: Maybe<Scalars['String']>;
  marketingId?: Maybe<Scalars['String']>;
  popularity?: Maybe<Scalars['Int']>;
  images: Array<File>;
  sectors: Array<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type LoyaltyItemConnection = DefaultConnection & {
  __typename?: 'LoyaltyItemConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<LoyaltyItem>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type LoyaltyOrder = {
  __typename?: 'LoyaltyOrder';
  id: Scalars['Int'];
  shopId: Scalars['Int'];
  clientId: Scalars['Int'];
  state: CommerceOrderState;
  paymentState: CommerceOrderPaymentState;
  userId: Scalars['Int'];
  user: User;
  externalEntityId?: Maybe<Scalars['Int']>;
  code?: Maybe<Scalars['String']>;
  total: Scalars['Float'];
  paidAmount: Scalars['Float'];
  items?: Maybe<Array<LoyaltyItem>>;
  data?: Maybe<Array<CommerceOrderData>>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type LoyaltyOrderConnection = DefaultConnection & {
  __typename?: 'LoyaltyOrderConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<LoyaltyOrder>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type LoyaltyOrderCreateInput = {
  state?: Maybe<CommerceOrderState>;
  paymentState?: Maybe<CommerceOrderPaymentState>;
  total?: Maybe<Scalars['Float']>;
  items: Array<OrderItemInput>;
  data?: Maybe<Array<OrderDataInput>>;
};

export type LoyaltyPartner = {
  __typename?: 'LoyaltyPartner';
  id: Scalars['Int'];
  clientId: Scalars['Int'];
  state: LoyaltyPartnerState;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  shopId?: Maybe<Scalars['Int']>;
  providerId?: Maybe<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type LoyaltyPartnerConnection = DefaultConnection & {
  __typename?: 'LoyaltyPartnerConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<LoyaltyPartner>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type LoyaltyPartnerCreateInput = {
  state?: Maybe<LoyaltyPartnerState>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  shopId?: Maybe<Scalars['Int']>;
  providerId?: Maybe<Scalars['Int']>;
};

export type LoyaltyPartnerEntityConnection = DefaultConnection & {
  __typename?: 'LoyaltyPartnerEntityConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<Scalars['String']>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export enum LoyaltyPartnerState {
  INACTIVE = 'INACTIVE',
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
}

export type LoyaltyPartnerUpdateInput = {
  state?: Maybe<LoyaltyPartnerState>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  shopId?: Maybe<Scalars['Int']>;
  providerId?: Maybe<Scalars['Int']>;
};

export type LoyaltyPromo = {
  __typename?: 'LoyaltyPromo';
  id: Scalars['Int'];
  clientId: Scalars['Int'];
  eventId?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  description: Scalars['String'];
  imageUri?: Maybe<Scalars['String']>;
  state: LoyaltyPromoState;
  amount?: Maybe<Scalars['Float']>;
  currencyId?: Maybe<Scalars['Int']>;
  code?: Maybe<Scalars['String']>;
  codeType: LoyaltyPromoCodeType;
  codesCount: Scalars['Int'];
  startDate: Scalars['DateTime'];
  finishDate: Scalars['DateTime'];
  promoCodeStartDate?: Maybe<Scalars['DateTime']>;
  promoCodeFinishDate?: Maybe<Scalars['DateTime']>;
  /** Max amount of currency for a payment */
  discountMaxAmount?: Maybe<Scalars['Float']>;
  /** Discount in percent for trip */
  discountPercent?: Maybe<Scalars['Float']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type LoyaltyPromoCode = {
  __typename?: 'LoyaltyPromoCode';
  id: Scalars['Int'];
  promoId: Scalars['Int'];
  userId?: Maybe<Scalars['Int']>;
  state: LoyaltyPromoCodeState;
  code: Scalars['String'];
  amount?: Maybe<Scalars['Float']>;
  amountUsed?: Maybe<Scalars['Float']>;
  currencyId?: Maybe<Scalars['Int']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  appliedAt?: Maybe<Scalars['DateTime']>;
  promo: LoyaltyPromo;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type LoyaltyPromoCodeConnection = DefaultConnection & {
  __typename?: 'LoyaltyPromoCodeConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<LoyaltyPromoCode>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type LoyaltyPromoCodeCreateInput = {
  promoId: Scalars['Int'];
  userId?: Maybe<Scalars['Int']>;
  state?: Maybe<LoyaltyPromoCodeState>;
  code: Scalars['String'];
  amount?: Maybe<Scalars['Float']>;
  amountUsed?: Maybe<Scalars['Float']>;
  currencyId?: Maybe<Scalars['Int']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  appliedAt?: Maybe<Scalars['DateTime']>;
};

export enum LoyaltyPromoCodeState {
  NEW = 'NEW',
  EXPIRED = 'EXPIRED',
  PROCESSING = 'PROCESSING',
  APPLIED = 'APPLIED',
}

export enum LoyaltyPromoCodeType {
  UNIQUE = 'UNIQUE',
  SINGLE = 'SINGLE',
}

export type LoyaltyPromoCodeUpdateInput = {
  promoId?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['Int']>;
  state?: Maybe<LoyaltyPromoCodeState>;
  code?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  amountUsed?: Maybe<Scalars['Float']>;
  currencyId?: Maybe<Scalars['Int']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  appliedAt?: Maybe<Scalars['DateTime']>;
};

export type LoyaltyPromoConnection = DefaultConnection & {
  __typename?: 'LoyaltyPromoConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<LoyaltyPromo>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type LoyaltyPromoCreateInput = {
  eventId?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  imageUri?: Maybe<Scalars['String']>;
  state?: Maybe<LoyaltyPromoState>;
  amount?: Maybe<Scalars['Float']>;
  currencyId?: Maybe<Scalars['Int']>;
  code?: Maybe<Scalars['String']>;
  codeType: LoyaltyPromoCodeType;
  codesCount?: Maybe<Scalars['Int']>;
  startDate: Scalars['DateTime'];
  finishDate: Scalars['DateTime'];
  promoCodeStartDate?: Maybe<Scalars['DateTime']>;
  promoCodeFinishDate?: Maybe<Scalars['DateTime']>;
  discountMaxAmount?: Maybe<Scalars['Float']>;
  discountPercent?: Maybe<Scalars['Float']>;
};

export enum LoyaltyPromoState {
  DRAFT = 'DRAFT',
  ACTIVE = 'ACTIVE',
  FINISHED = 'FINISHED',
  ARCHIVED = 'ARCHIVED',
}

export type LoyaltyPromoUpdateInput = {
  eventId?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  imageUri?: Maybe<Scalars['String']>;
  state?: Maybe<LoyaltyPromoState>;
  amount?: Maybe<Scalars['Float']>;
  currencyId?: Maybe<Scalars['Int']>;
  code?: Maybe<Scalars['String']>;
  codeType?: Maybe<LoyaltyPromoCodeType>;
  codesCount?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['DateTime']>;
  finishDate?: Maybe<Scalars['DateTime']>;
  promoCodeStartDate?: Maybe<Scalars['DateTime']>;
  promoCodeFinishDate?: Maybe<Scalars['DateTime']>;
  discountMaxAmount?: Maybe<Scalars['Float']>;
  discountPercent?: Maybe<Scalars['Float']>;
};

export type LoyaltySummaryGraph = {
  __typename?: 'LoyaltySummaryGraph';
  name: Scalars['String'];
  dataset: Array<LoyaltySummaryGraphDataset>;
};

export type LoyaltySummaryGraphDataset = {
  __typename?: 'LoyaltySummaryGraphDataset';
  label: Scalars['String'];
  data: Array<LoyaltySummaryGraphDatasetData>;
};

export type LoyaltySummaryGraphDatasetData = {
  __typename?: 'LoyaltySummaryGraphDatasetData';
  x: Scalars['Date'];
  y: Scalars['Int'];
};

export type LoyaltySummaryWidget = {
  __typename?: 'LoyaltySummaryWidget';
  partner: LoyaltyPartner;
  summary: LoyaltyAccountSummary;
  graphs?: Maybe<Array<LoyaltySummaryGraph>>;
};

export type LoyaltySummaryWidgetInput = {
  id?: Maybe<Array<Scalars['Int']>>;
  date?: Maybe<Array<Scalars['DateTime']>>;
};

/** Источники покупки товара пользователем */
export enum MarketPlaceEnum {
  TICKET = 'ticket',
  SHOP = 'shop',
}

export type MatchEventSubscription = {
  __typename?: 'MatchEventSubscription';
  deviceId: Scalars['Int'];
  subscription: Array<MatchEventSubscriptionSubscription>;
};

export type MatchEventSubscriptionSubscription = {
  __typename?: 'MatchEventSubscriptionSubscription';
  eventName: TournamentMatchEventEvent;
  teamIdx?: Maybe<Array<Scalars['Int']>>;
  isSubscribed?: Maybe<Scalars['Boolean']>;
};

export type MessengerMailing = {
  __typename?: 'MessengerMailing';
  id: Scalars['Int'];
  clientId: Scalars['Int'];
  name: Scalars['String'];
  sendAfter?: Maybe<Scalars['DateTime']>;
  sender?: Maybe<Scalars['String']>;
  channel: MessengerMailingChannel;
  state: MessengerMailingState;
  subject?: Maybe<Scalars['String']>;
  richText?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  templateId?: Maybe<Scalars['Int']>;
  transportId?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['String']>;
  metaDesign?: Maybe<Scalars['JSON']>;
  currentTask?: Maybe<MessengerQueueTask>;
  transport?: Maybe<MessengerTransport>;
  stats?: Maybe<Array<MessengerQueueNotificationStat>>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type MessengerMailingActivity = {
  __typename?: 'MessengerMailingActivity';
  id: Scalars['Int'];
  mailingId: Scalars['Int'];
  userId: Scalars['Int'];
  action: Scalars['String'];
  meta?: Maybe<Scalars['JSON']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type MessengerMailingActivityConnection = DefaultConnection & {
  __typename?: 'MessengerMailingActivityConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<MessengerMailingActivity>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type MessengerMailingActivityCreateInput = {
  mailingId: Scalars['Int'];
  userId: Scalars['Int'];
  action: Scalars['String'];
  meta?: Maybe<Scalars['JSON']>;
};

export type MessengerMailingActivityUpdateInput = {
  mailingId?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['Int']>;
  action?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
};

export enum MessengerMailingChannel {
  EMAIL = 'EMAIL',
  SERVICE_EMAIL = 'SERVICE_EMAIL',
  SMS = 'SMS',
  PUSH = 'PUSH',
  TELEGRAM = 'TELEGRAM',
  SLACK = 'SLACK',
}

export type MessengerMailingChannelStat = {
  __typename?: 'MessengerMailingChannelStat';
  channel: MessengerMailingChannel;
  value: Scalars['Int'];
};

export type MessengerMailingConnection = DefaultConnection & {
  __typename?: 'MessengerMailingConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<MessengerMailing>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type MessengerMailingCreateInput = {
  name: Scalars['String'];
  sendAfter?: Maybe<Scalars['DateTime']>;
  sender?: Maybe<Scalars['String']>;
  channel: MessengerMailingChannel;
  currentTask?: Maybe<Scalars['String']>;
  state?: Maybe<MessengerMailingState>;
  subject?: Maybe<Scalars['String']>;
  richText?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  templateId?: Maybe<Scalars['Int']>;
  transportId?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['String']>;
  metaDesign?: Maybe<Scalars['JSON']>;
};

export type MessengerMailingRedirect = {
  __typename?: 'MessengerMailingRedirect';
  id: Scalars['Int'];
  clientId: Scalars['Int'];
  mailingId: Scalars['Int'];
  userId: Scalars['Int'];
  utmCampaign?: Maybe<Scalars['String']>;
  utmId?: Maybe<Scalars['String']>;
  redirectAddress?: Maybe<Scalars['String']>;
  redirectsCount?: Maybe<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type MessengerMailingRedirectAnalytics = {
  __typename?: 'MessengerMailingRedirectAnalytics';
  utmCampaign: Scalars['String'];
  redirectsCount: Scalars['Int'];
  uniqueCount: Scalars['Int'];
};

export type MessengerMailingRedirectConnection = DefaultConnection & {
  __typename?: 'MessengerMailingRedirectConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<MessengerMailingRedirect>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type MessengerMailingRedirectCreateInput = {
  mailingId: Scalars['Int'];
  userId: Scalars['Int'];
  utmCampaign?: Maybe<Scalars['String']>;
  utmId?: Maybe<Scalars['String']>;
  redirectAddress?: Maybe<Scalars['String']>;
  redirectsCount?: Maybe<Scalars['Int']>;
};

export enum MessengerMailingState {
  INACTIVE = 'INACTIVE',
  ACTIVE = 'ACTIVE',
  PAUSED = 'PAUSED',
  QUEUE_PROCESSING = 'QUEUE_PROCESSING',
  CANCELLED = 'CANCELLED',
  FINISHED = 'FINISHED',
  START = 'START',
}

export type MessengerMailingUpdateInput = {
  name?: Maybe<Scalars['String']>;
  sendAfter?: Maybe<Scalars['DateTime']>;
  sender?: Maybe<Scalars['String']>;
  channel?: Maybe<MessengerMailingChannel>;
  currentTask?: Maybe<Scalars['String']>;
  state?: Maybe<MessengerMailingState>;
  subject?: Maybe<Scalars['String']>;
  richText?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  templateId?: Maybe<Scalars['Int']>;
  transportId?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['String']>;
  metaDesign?: Maybe<Scalars['JSON']>;
};

export type MessengerProvider = {
  __typename?: 'MessengerProvider';
  id: Scalars['Int'];
  name: Scalars['String'];
  channel: MessengerProviderChannel;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export enum MessengerProviderChannel {
  EMAIL = 'EMAIL',
  SERVICE_EMAIL = 'SERVICE_EMAIL',
  SMS = 'SMS',
  PUSH = 'PUSH',
  TELEGRAM = 'TELEGRAM',
  SLACK = 'SLACK',
}

export type MessengerProviderConnection = DefaultConnection & {
  __typename?: 'MessengerProviderConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<MessengerProvider>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type MessengerProviderCreateInput = {
  name: Scalars['String'];
  channel: MessengerProviderChannel;
};

export type MessengerProviderUpdateInput = {
  name?: Maybe<Scalars['String']>;
  channel?: Maybe<MessengerProviderChannel>;
};

export type MessengerQueueNotification = {
  __typename?: 'MessengerQueueNotification';
  id: Scalars['String'];
  clientId: Scalars['Int'];
  mailingId?: Maybe<Scalars['Int']>;
  mailing?: Maybe<MessengerMailing>;
  userId?: Maybe<Scalars['Int']>;
  state: MessengerQueueNotificationState;
  channel: MessengerQueueNotificationChannel;
  willSendAt?: Maybe<Scalars['DateTime']>;
  sender?: Maybe<Scalars['String']>;
  recipient?: Maybe<Scalars['JSON']>;
  payload?: Maybe<Scalars['JSON']>;
  envelope?: Maybe<Scalars['JSON']>;
  transportId?: Maybe<Scalars['Int']>;
  transportMessageId?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  richText?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export enum MessengerQueueNotificationChannel {
  EMAIL = 'EMAIL',
  SERVICE_EMAIL = 'SERVICE_EMAIL',
  SMS = 'SMS',
  PUSH = 'PUSH',
  TELEGRAM = 'TELEGRAM',
  SLACK = 'SLACK',
}

export type MessengerQueueNotificationConnection = DefaultConnection & {
  __typename?: 'MessengerQueueNotificationConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<MessengerQueueNotification>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type MessengerQueueNotificationCreateInput = {
  mailingId?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['Int']>;
  state?: Maybe<MessengerQueueNotificationState>;
  channel: MessengerQueueNotificationChannel;
  willSendAt?: Maybe<Scalars['DateTime']>;
  sender?: Maybe<Scalars['String']>;
  recipient?: Maybe<Scalars['JSON']>;
  payload?: Maybe<Scalars['JSON']>;
  envelope?: Maybe<Scalars['JSON']>;
  transportId?: Maybe<Scalars['Int']>;
  transportMessageId?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  richText?: Maybe<Scalars['String']>;
};

export type MessengerQueueNotificationStat = {
  __typename?: 'MessengerQueueNotificationStat';
  state: MessengerQueueNotificationState;
  value: Scalars['Int'];
};

export enum MessengerQueueNotificationState {
  READY = 'READY',
  PROCESSING = 'PROCESSING',
  SENT = 'SENT',
  RECEIVED = 'RECEIVED',
  OPENED = 'OPENED',
  BOUNCED = 'BOUNCED',
  SPAM = 'SPAM',
  DELETED = 'DELETED',
}

export type MessengerQueueNotificationUpdateInput = {
  mailingId?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['Int']>;
  state?: Maybe<MessengerQueueNotificationState>;
  channel?: Maybe<MessengerQueueNotificationChannel>;
  willSendAt?: Maybe<Scalars['DateTime']>;
  sender?: Maybe<Scalars['String']>;
  recipient?: Maybe<Scalars['JSON']>;
  payload?: Maybe<Scalars['JSON']>;
  envelope?: Maybe<Scalars['JSON']>;
  transportId?: Maybe<Scalars['Int']>;
  transportMessageId?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  richText?: Maybe<Scalars['String']>;
};

export type MessengerQueueTask = {
  __typename?: 'MessengerQueueTask';
  id: Scalars['Int'];
  mailingId?: Maybe<Scalars['Int']>;
  operation: MessengerQueueTaskOperation;
  state: MessengerQueueTaskState;
  itemsToProcess: Scalars['Int'];
  itemsProcessed: Scalars['Int'];
  itemsInvalid: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type MessengerQueueTaskConnection = DefaultConnection & {
  __typename?: 'MessengerQueueTaskConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<MessengerQueueTask>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type MessengerQueueTaskCreateInput = {
  mailingId?: Maybe<Scalars['Int']>;
  operation: MessengerQueueTaskOperation;
  state: MessengerQueueTaskState;
  itemsToProcess?: Maybe<Scalars['Int']>;
  itemsProcessed?: Maybe<Scalars['Int']>;
  itemsInvalid?: Maybe<Scalars['Int']>;
};

export enum MessengerQueueTaskOperation {
  ADD = 'ADD',
  DELETE = 'DELETE',
  START = 'START',
  STOP = 'STOP',
  PAUSE = 'PAUSE',
}

export enum MessengerQueueTaskState {
  NEW = 'NEW',
  PROCESSING = 'PROCESSING',
  FINISHED = 'FINISHED',
  CANCELLED = 'CANCELLED',
}

export type MessengerQueueTaskUpdateInput = {
  mailingId?: Maybe<Scalars['Int']>;
  operation?: Maybe<MessengerQueueTaskOperation>;
  state?: Maybe<MessengerQueueTaskState>;
  itemsToProcess?: Maybe<Scalars['Int']>;
  itemsProcessed?: Maybe<Scalars['Int']>;
  itemsInvalid?: Maybe<Scalars['Int']>;
};

export type MessengerSetting = {
  __typename?: 'MessengerSetting';
  id: Scalars['Int'];
  clientId: Scalars['Int'];
  testers: Scalars['JSON'];
  defaultSender: Scalars['JSON'];
  defaultTransport?: Maybe<Scalars['JSON']>;
  defaultSubscription?: Maybe<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  /** Настройки отправителей */
  emailSenders?: Maybe<Array<Scalars['String']>>;
  smsSenders?: Maybe<Array<Scalars['String']>>;
  pushSenders?: Maybe<Array<Scalars['String']>>;
  /** Виртуальные поля */
  emailDefault?: Maybe<Scalars['String']>;
  smsDefault?: Maybe<Scalars['String']>;
  pushDefault?: Maybe<Scalars['String']>;
  unsubscribeUrl?: Maybe<Scalars['String']>;
};

export type MessengerSettingUpdateInput = {
  /** Настройки отправителей */
  emailSenders?: Maybe<Array<Scalars['String']>>;
  smsSenders?: Maybe<Array<Scalars['String']>>;
  pushSenders?: Maybe<Array<Scalars['String']>>;
  testers?: Maybe<Scalars['JSON']>;
  defaultSender?: Maybe<Scalars['JSON']>;
  defaultTransport?: Maybe<Scalars['JSON']>;
  defaultSubscription?: Maybe<Scalars['Int']>;
};

export type MessengerTemplate = {
  __typename?: 'MessengerTemplate';
  id: Scalars['Int'];
  clientId: Scalars['Int'];
  state: MessengerTemplateState;
  channel: MessengerTemplateChannel;
  sender: Scalars['String'];
  name: Scalars['String'];
  title: Scalars['String'];
  richText?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  preview?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  externalTemplateId?: Maybe<Scalars['String']>;
  templateProviderId?: Maybe<Scalars['Int']>;
  templateProvider?: Maybe<MessengerTemplateProvider>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export enum MessengerTemplateChannel {
  EMAIL = 'EMAIL',
  SERVICE_EMAIL = 'SERVICE_EMAIL',
  SMS = 'SMS',
  PUSH = 'PUSH',
  TELEGRAM = 'TELEGRAM',
  SLACK = 'SLACK',
}

export type MessengerTemplateConnection = DefaultConnection & {
  __typename?: 'MessengerTemplateConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<MessengerTemplate>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type MessengerTemplateCreateInput = {
  state?: Maybe<MessengerTemplateState>;
  channel: MessengerTemplateChannel;
  sender: Scalars['String'];
  name: Scalars['String'];
  title: Scalars['String'];
  richText?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  preview?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  externalTemplateId?: Maybe<Scalars['String']>;
  templateProviderId?: Maybe<Scalars['Int']>;
};

export type MessengerTemplateProvider = {
  __typename?: 'MessengerTemplateProvider';
  id: Scalars['Int'];
  clientId: Scalars['Int'];
  state: MessengerTemplateProviderState;
  name: Scalars['String'];
  settings?: Maybe<Scalars['JSON']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type MessengerTemplateProviderConnection = DefaultConnection & {
  __typename?: 'MessengerTemplateProviderConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<MessengerTemplateProvider>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export enum MessengerTemplateProviderState {
  INACTIVE = 'INACTIVE',
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
}

export type MessengerTemplateProviderUpdateInput = {
  state?: Maybe<MessengerTemplateProviderState>;
  name?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['JSON']>;
};

export enum MessengerTemplateState {
  INACTIVE = 'INACTIVE',
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
}

export type MessengerTemplateUpdateInput = {
  state?: Maybe<MessengerTemplateState>;
  channel?: Maybe<MessengerTemplateChannel>;
  sender?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  richText?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  preview?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  externalTemplateId?: Maybe<Scalars['String']>;
  templateProviderId?: Maybe<Scalars['Int']>;
};

export type MessengerTransport = {
  __typename?: 'MessengerTransport';
  id: Scalars['Int'];
  clientId: Scalars['Int'];
  name: Scalars['String'];
  settings: Scalars['JSON'];
  providerId: Scalars['Int'];
  state: MessengerTransportState;
  channel: MessengerTransportChannel;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export enum MessengerTransportChannel {
  EMAIL = 'EMAIL',
  SERVICE_EMAIL = 'SERVICE_EMAIL',
  SMS = 'SMS',
  PUSH = 'PUSH',
  TELEGRAM = 'TELEGRAM',
  SLACK = 'SLACK',
}

export type MessengerTransportConnection = DefaultConnection & {
  __typename?: 'MessengerTransportConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<MessengerTransport>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type MessengerTransportCreateInput = {
  name: Scalars['String'];
  settings: Scalars['JSON'];
  providerId: Scalars['Int'];
  state?: Maybe<MessengerTransportState>;
  channel: MessengerTransportChannel;
};

export enum MessengerTransportState {
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED',
}

export type MessengerTransportUpdateInput = {
  name?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['JSON']>;
  providerId?: Maybe<Scalars['Int']>;
  state?: Maybe<MessengerTransportState>;
  channel?: Maybe<MessengerTransportChannel>;
};

export type MobileTranslation = {
  __typename?: 'MobileTranslation';
  id: Scalars['Int'];
  locale: Scalars['String'];
  key: Scalars['String'];
  value: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type MobileTranslationSaveInput = {
  key: Scalars['String'];
  value: Scalars['String'];
  locale: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  test?: Maybe<Scalars['String']>;
  /** Обновление блоков статей */
  updateArticleBlocks?: Maybe<Scalars['Int']>;
  saveArticleTags: Array<ArticleArticleTag>;
  createArticle: Article;
  updateArticle: Article;
  deleteArticle: Article;
  createArticleArticleTag: ArticleArticleTag;
  updateArticleArticleTag: ArticleArticleTag;
  deleteArticleArticleTag: ArticleArticleTag;
  createArticleAuthor: ArticleAuthor;
  updateArticleAuthor: ArticleAuthor;
  deleteArticleAuthor: ArticleAuthor;
  createArticleBlock: ArticleBlock;
  updateArticleBlock: ArticleBlock;
  deleteArticleBlock: ArticleBlock;
  createArticleCategory: ArticleCategory;
  updateArticleCategory: ArticleCategory;
  deleteArticleCategory: ArticleCategory;
  createArticleLink: ArticleLink;
  updateArticleLink: ArticleLink;
  deleteArticleLink: ArticleLink;
  createArticleTag: ArticleTag;
  updateArticleTag: ArticleTag;
  deleteArticleTag: ArticleTag;
  /** Массовое обновление товаров каталога */
  updateCatalogueItemBatch: Scalars['Int'];
  /** Массовое обновление категорий */
  updateCatalogueCategoryBatch: Scalars['Int'];
  createCatalogue: Catalogue;
  updateCatalogue: Catalogue;
  deleteCatalogue: Catalogue;
  createCatalogueField: ExtensionField;
  updateCatalogueField: ExtensionField;
  deleteCatalogueField: Scalars['Boolean'];
  createCatalogueCategory: CatalogueCategory;
  updateCatalogueCategory: CatalogueCategory;
  deleteCatalogueCategory: CatalogueCategory;
  createCatalogueCategoryField: ExtensionField;
  updateCatalogueCategoryField: ExtensionField;
  deleteCatalogueCategoryField: Scalars['Boolean'];
  createCatalogueItem: CatalogueItem;
  updateCatalogueItem: CatalogueItem;
  deleteCatalogueItem: CatalogueItem;
  createCatalogueItemField: ExtensionField;
  updateCatalogueItemField: ExtensionField;
  deleteCatalogueItemField: Scalars['Boolean'];
  createCatalogueItemPrice: CatalogueItemPrice;
  updateCatalogueItemPrice: CatalogueItemPrice;
  deleteCatalogueItemPrice: CatalogueItemPrice;
  createCatalogueRelation: CatalogueRelation;
  updateCatalogueRelation: CatalogueRelation;
  deleteCatalogueRelation: CatalogueRelation;
  createCatering: Catering;
  updateCatering: Catering;
  deleteCatering: Catering;
  createCommerceOrderData: CommerceOrderData;
  updateCommerceOrderData: CommerceOrderData;
  deleteCommerceOrderData: CommerceOrderData;
  createCommerceOrderField: CommerceOrderField;
  updateCommerceOrderField: CommerceOrderField;
  deleteCommerceOrderField: CommerceOrderField;
  createCommerceOrderItem: CommerceOrderItem;
  updateCommerceOrderItem: CommerceOrderItem;
  deleteCommerceOrderItem: CommerceOrderItem;
  createCommerceSettings: CommerceSettings;
  updateCommerceSettings: CommerceSettings;
  deleteCommerceSettings: CommerceSettings;
  createCommerceShop: CommerceShop;
  updateCommerceShop: CommerceShop;
  deleteCommerceShop: CommerceShop;
  createCommerceTemplate: CommerceTemplate;
  updateCommerceTemplate: CommerceTemplate;
  deleteCommerceTemplate: CommerceTemplate;
  createCommerceOrder: CommerceOrder;
  cancelCommerceOrder: CommerceOrder;
  updateCommerceOrder: CommerceOrder;
  deleteCommerceOrder: CommerceOrder;
  /** Обновление данных полей у заказа */
  updateCommerceOrderDatas: Scalars['Int'];
  /** Печать заказа */
  commerceOrderPrint?: Maybe<Scalars['String']>;
  createContentTranslation: ContentTranslation;
  updateContentTranslation: ContentTranslation;
  deleteContentTranslation: ContentTranslation;
  /**
   * Сохранение новых переводов в массиве.
   * Если перевод с таким ключом, локалью и ID клиента присутствует, он будет обновлен.
   * Если отсутствует - создан.
   */
  saveContentTranslations: Array<ContentTranslation>;
  createDispute: Dispute;
  createDisputeDisputeResponse: DisputeDisputeResponse;
  updateDispute: Dispute;
  updateDisputeDisputeResponse: DisputeDisputeResponse;
  confirmDispute: Dispute;
  confirmDisputeDisputeResponse: DisputeDisputeResponse;
  confirmDisputeDisputeModerator: Dispute;
  archivingDisputesFromSeason: Scalars['Int'];
  saveDisputeSettings: DisputeSettings;
  updateDisputeSettings: DisputeSettings;
  /**
   * Генерация сопровождающих докмуентов для билета:
   * — PDF
   * — Apple Wallet pass
   * — Google Wallet (TODO)
   */
  generateDocsByEventOrder: Scalars['Boolean'];
  /** Импорт пользователей в белый список */
  importToEventWhitelist: Scalars['Int'];
  /** Добавление пользователей в группу из билетов. */
  addUsersToGroupFromTickets: Array<SystemAsyncTask>;
  cancelEventOrder: EventOrder;
  createEventOrderTicketsByOrderId: Array<EventOrderTicket>;
  createEvent: Event;
  updateEvent: Event;
  deleteEvent: Event;
  createEventField: ExtensionField;
  updateEventField: ExtensionField;
  deleteEventField: Scalars['Boolean'];
  createEventEventAccess: EventEventAccess;
  updateEventEventAccess: EventEventAccess;
  deleteEventEventAccess: EventEventAccess;
  createEventEventWhitelist: EventEventWhitelist;
  updateEventEventWhitelist: EventEventWhitelist;
  deleteEventEventWhitelist: EventEventWhitelist;
  createEventOrder: EventOrder;
  updateEventOrder: EventOrder;
  deleteEventOrder: EventOrder;
  createEventOrderField: ExtensionField;
  updateEventOrderField: ExtensionField;
  deleteEventOrderField: Scalars['Boolean'];
  createEventOrderTicket: EventOrderTicket;
  updateEventOrderTicket: EventOrderTicket;
  deleteEventOrderTicket: EventOrderTicket;
  createEventOrderTicketField: ExtensionField;
  updateEventOrderTicketField: ExtensionField;
  deleteEventOrderTicketField: Scalars['Boolean'];
  createEventPlace: EventPlace;
  updateEventPlace: EventPlace;
  deleteEventPlace: EventPlace;
  createEventPlaceSector: EventPlaceSector;
  updateEventPlaceSector: EventPlaceSector;
  deleteEventPlaceSector: EventPlaceSector;
  createEventReserve: EventReserve;
  updateEventReserve: EventReserve;
  deleteEventReserve: EventReserve;
  updateEventSettings: EventSettings;
  createEventType: EventType;
  updateEventType: EventType;
  deleteEventType: EventType;
  /** Удаление билетов по id заказа */
  deleteEventOrderTicketByOrderId: Scalars['Int'];
  /** Экспорт пользователей в Unisender */
  exportUsersToUnisender: ExportResponse;
  unisenderCreateList: UnisenderList;
  createPaymentAccountByApplicationCode?: Maybe<PaymentAccount>;
  createLoyaltyApplication: LoyaltyApplication;
  updateLoyaltyApplication: LoyaltyApplication;
  deleteLoyaltyApplication: LoyaltyApplication;
  createLoyaltyPartner: LoyaltyPartner;
  updateLoyaltyPartner: LoyaltyPartner;
  deleteLoyaltyPartner: LoyaltyPartner;
  createLoyaltyPromo: LoyaltyPromo;
  updateLoyaltyPromo: LoyaltyPromo;
  deleteLoyaltyPromo: LoyaltyPromo;
  createLoyaltyPromoCode: LoyaltyPromoCode;
  updateLoyaltyPromoCode: LoyaltyPromoCode;
  deleteLoyaltyPromoCode: LoyaltyPromoCode;
  /**
   * Генерация промо-кодов. Вернем количество сгенерированных.
   * Если всего 100 и 50 уже сгенерированы, то сгенерируем 50
   */
  generateLoyaltyPromoCodes: Scalars['Int'];
  /**
   * Очистить промо-коды. Вернем количество очищенных.
   * Будут очищены только непримененные
   */
  resetLoyaltyPromoCodes: Scalars['Int'];
  /** Применение промо-кода. */
  applyLoyaltyPromoCode: ApplyLoyaltyPromoCodeResult;
  /** Импорт промокодов из файла */
  importPromocodes?: Maybe<Scalars['String']>;
  /** Экспорт промокодов */
  exportPromocodes: Scalars['String'];
  createMessengerMailing: MessengerMailing;
  updateMessengerMailing: MessengerMailing;
  deleteMessengerMailing: MessengerMailing;
  createMessengerMailingActivity: MessengerMailingActivity;
  updateMessengerMailingActivity: MessengerMailingActivity;
  deleteMessengerMailingActivity: MessengerMailingActivity;
  createMessengerMailingRedirect: MessengerMailingRedirect;
  createMessengerProvider: MessengerProvider;
  updateMessengerProvider: MessengerProvider;
  deleteMessengerProvider: MessengerProvider;
  createMessengerQueueTask: MessengerQueueTask;
  updateMessengerQueueTask: MessengerQueueTask;
  deleteMessengerQueueTask: MessengerQueueTask;
  createMessengerTemplate: MessengerTemplate;
  updateMessengerTemplate: MessengerTemplate;
  deleteMessengerTemplate: MessengerTemplate;
  updateMessengerTemplateProvider: MessengerTemplateProvider;
  createMessengerTransport: MessengerTransport;
  updateMessengerTransport: MessengerTransport;
  deleteMessengerTransport: MessengerTransport;
  /** Отправка push-сообщения по пользователям */
  sendPushByUser?: Maybe<Scalars['String']>;
  /** Отправка сообщения пользователю */
  sendNotification: MessengerQueueNotification;
  /** Отправка push-сообщения по подпискам */
  sendPushBySubscriptions?: Maybe<Scalars['String']>;
  addToMessengerQueue: MessengerQueueTask;
  deleteFromMessengerQueue: Scalars['Int'];
  updateMessengerSetting: MessengerSetting;
  /** Импорт получателей рассылки из файла */
  importRecipientsToQueueNotification: MessengerQueueTask;
  /** Тестовая отправка рассылки */
  testMessengerMailing?: Maybe<Scalars['String']>;
  createMessengerQueueNotification: MessengerQueueNotification;
  updateMessengerQueueNotification: MessengerQueueNotification;
  deleteMessengerQueueNotification: MessengerQueueNotification;
  saveRedirectOnMail: Scalars['Boolean'];
  /** Импорт шаблонов уведомлений */
  importTemplateNotifications?: Maybe<Scalars['String']>;
  /**
   * Сохранение новых переводов в массиве.
   * Если перевод с таким ключом, локалью и ID клиента присутствует, он будет обновлен.
   * Если отсутствует - создан.
   */
  saveMobileTranslations?: Maybe<Array<MobileTranslation>>;
  /** Удаление перевода по id */
  deleteMobileTranslation: MobileTranslation;
  depositToAccount: ProviderResponse;
  chargeFromAccount: ProviderResponse;
  updatePaymentAccountByPartnerCode?: Maybe<Scalars['String']>;
  syncPaymentAccountBalanceByPartnerCode: Scalars['Int'];
  /** Синхронизация платежного аккаунта */
  syncPaymentAccount: PaymentAccount;
  /** Синхронизация платежных аккаунтов по пользователю */
  syncPaymentAccounts: Array<PaymentAccount>;
  createPaymentAccount: PaymentAccount;
  updatePaymentAccount: PaymentAccount;
  deletePaymentAccount: PaymentAccount;
  createPaymentBankCard: PaymentBankCard;
  updatePaymentBankCard: PaymentBankCard;
  deletePaymentBankCard: PaymentBankCard;
  createPaymentChannel: PaymentChannel;
  updatePaymentChannel: PaymentChannel;
  deletePaymentChannel: PaymentChannel;
  createPaymentCurrency: PaymentCurrency;
  updatePaymentCurrency: PaymentCurrency;
  deletePaymentCurrency: PaymentCurrency;
  createPaymentGateway: PaymentGateway;
  updatePaymentGateway: PaymentGateway;
  deletePaymentGateway: PaymentGateway;
  createPaymentProvider: PaymentProvider;
  updatePaymentProvider: PaymentProvider;
  deletePaymentProvider: PaymentProvider;
  createPaymentTransaction: PaymentTransaction;
  updatePaymentTransaction: PaymentTransaction;
  deletePaymentTransaction: PaymentTransaction;
  createPaymentTransactionLog: PaymentTransactionLog;
  updatePaymentTransactionLog: PaymentTransactionLog;
  deletePaymentTransactionLog: PaymentTransactionLog;
  /** Покупка заказа */
  payOrder: CommerceOrder;
  /** Возврат по заказу */
  refundOrder: Array<PaymentTransaction>;
  /** Операция списания с банковской карты по сформированной криптограмме */
  chargeByBankCard?: Maybe<PaymentResponse>;
  /** Проверка прохождения 3D Secure аутентификации */
  process3DSecure?: Maybe<PaymentTransaction>;
  /** Отправка чека по составу заказа */
  sendCashierCheckByOrder?: Maybe<PaymentReceiptResponse>;
  /** Отправка чека по приходу */
  sendCashierCheckByReceipt?: Maybe<PaymentReceiptResponse>;
  /** Инициализация платежа */
  initPayment?: Maybe<InitPaymentResponse>;
  paymentWebHook?: Maybe<PaymentWebHookResponse>;
  confirmOrderPayment: ConfirmOrderPaymentResponse;
  paymentConfirmPurchase: Scalars['Boolean'];
  /** Проверка платежа по номеру заказа. Также создаст транзакцию если ее нет */
  validatePaymentByOrderId: PaymentInfo;
  startQuizAttempt: QuizAttempt;
  finishQuizAttempt: QuizAttempt;
  resetQuizAttempts: Scalars['Boolean'];
  createQuizQuestion: QuizQuestion;
  updateQuizQuestion: QuizQuestion;
  deleteQuizQuestion: QuizQuestion;
  createQuizQuestionAnswer: QuizQuestionAnswer;
  updateQuizQuestionAnswer: QuizQuestionAnswer;
  deleteQuizQuestionAnswer: QuizQuestionAnswer;
  createQuiz: Quiz;
  updateQuiz: Quiz;
  deleteQuiz: Quiz;
  createQuizAttemptAnswer: QuizAttemptAnswer;
  createSocialNetwork: SocialNetwork;
  updateSocialNetwork: SocialNetwork;
  deleteSocialNetwork: SocialNetwork;
  createSocialNewsSetting: SocialNewsSetting;
  updateSocialNewsSetting: SocialNewsSetting;
  deleteSocialNewsSetting: SocialNewsSetting;
  updateSystemAsyncTask: SystemAsyncTask;
  createSystemPrinter: SystemPrinter;
  updateSystemPrinter: SystemPrinter;
  deleteSystemPrinter: SystemPrinter;
  createSystemTask: SystemTask;
  updateSystemTask: SystemTask;
  deleteSystemTask: SystemTask;
  createSystemTranslation: SystemTranslation;
  updateSystemTranslation: SystemTranslation;
  deleteSystemTranslation: SystemTranslation;
  createSystemWebHook: SystemWebHook;
  updateSystemWebHook: SystemWebHook;
  deleteSystemWebHook: SystemWebHook;
  /** Обновление системных переводов. */
  updateSystemTranslations?: Maybe<Array<SystemTranslation>>;
  saveSystemTranslation: SystemTranslation;
  /** Логин в систему. */
  systemLogin: SystemLogin;
  /** Выход из ERP-системы пользователя. */
  systemLogout: Scalars['Boolean'];
  /** Создание пользователя. */
  createSystemUser: SystemUser;
  /** Редактирование пользователя. */
  updateSystemUser: SystemUser;
  /** Назначить права доступа. */
  grantAccess: Scalars['Boolean'];
  /** Убрать права доступа. */
  revokeAccess: Scalars['Boolean'];
  /** Изменить настройки пользователя */
  updateUserSettings: UserSettings;
  /** Вызов вебхука по id */
  triggerSystemWebHook?: Maybe<Scalars['String']>;
  createTemplateApplePass: TemplateApplePass;
  updateTemplateApplePass: TemplateApplePass;
  deleteTemplateApplePass: TemplateApplePass;
  createTemplateNotification: TemplateNotification;
  updateTemplateNotification: TemplateNotification;
  deleteTemplateNotification: TemplateNotification;
  createTemplatePDF: TemplatePdf;
  updateTemplatePDF: TemplatePdf;
  deleteTemplatePDF: TemplatePdf;
  updateTemplateProvider: TemplateProvider;
  createTournamentDispute: TournamentDispute;
  updateTournamentDispute: TournamentDispute;
  deleteTournamentDispute: TournamentDispute;
  createTournamentDisputeResponse: TournamentDisputeResponse;
  updateTournamentDisputeResponse: TournamentDisputeResponse;
  deleteTournamentDisputeResponse: TournamentDisputeResponse;
  createTournamentDisqualification: TournamentDisqualification;
  updateTournamentDisqualification: TournamentDisqualification;
  deleteTournamentDisqualification: TournamentDisqualification;
  createTournamentGroup: TournamentGroup;
  updateTournamentGroup: TournamentGroup;
  deleteTournamentGroup: TournamentGroup;
  createTournamentMatch: TournamentMatch;
  updateTournamentMatch: TournamentMatch;
  deleteTournamentMatch: TournamentMatch;
  createTournamentMatchField: ExtensionField;
  updateTournamentMatchField: ExtensionField;
  deleteTournamentMatchField: Scalars['Boolean'];
  createTournamentMatchEvent: TournamentMatchEvent;
  updateTournamentMatchEvent: TournamentMatchEvent;
  deleteTournamentMatchEvent: TournamentMatchEvent;
  createTournamentMatchLineUp: TournamentMatchLineUp;
  updateTournamentMatchLineUp: TournamentMatchLineUp;
  deleteTournamentMatchLineUp: TournamentMatchLineUp;
  createTournamentMatchProtocol: TournamentMatchProtocol;
  updateTournamentMatchProtocol: TournamentMatchProtocol;
  deleteTournamentMatchProtocol: TournamentMatchProtocol;
  createTournamentMatchStat: TournamentMatchStat;
  updateTournamentMatchStat: TournamentMatchStat;
  deleteTournamentMatchStat: TournamentMatchStat;
  createTournamentPlayerStat: TournamentPlayerStat;
  updateTournamentPlayerStat: TournamentPlayerStat;
  deleteTournamentPlayerStat: TournamentPlayerStat;
  createTournamentReferee: TournamentReferee;
  updateTournamentReferee: TournamentReferee;
  deleteTournamentReferee: TournamentReferee;
  createTournamentSeason: TournamentSeason;
  updateTournamentSeason: TournamentSeason;
  deleteTournamentSeason: TournamentSeason;
  createTournamentSeasonApplicationForm: TournamentSeasonApplicationForm;
  updateTournamentSeasonApplicationForm: TournamentSeasonApplicationForm;
  deleteTournamentSeasonApplicationForm: TournamentSeasonApplicationForm;
  createTournamentSeasonTeam: TournamentSeasonTeam;
  updateTournamentSeasonTeam: TournamentSeasonTeam;
  deleteTournamentSeasonTeam: TournamentSeasonTeam;
  createTournamentSeasonTeamMember: TournamentSeasonTeamMember;
  updateTournamentSeasonTeamMember: TournamentSeasonTeamMember;
  deleteTournamentSeasonTeamMember: TournamentSeasonTeamMember;
  createTournamentSeasonTeamStat: TournamentSeasonTeamStat;
  updateTournamentSeasonTeamStat: TournamentSeasonTeamStat;
  deleteTournamentSeasonTeamStat: TournamentSeasonTeamStat;
  updateTournamentSetting: TournamentSetting;
  createTournamentStadium: TournamentStadium;
  updateTournamentStadium: TournamentStadium;
  deleteTournamentStadium: TournamentStadium;
  createTournamentTeam: TournamentTeam;
  updateTournamentTeam: TournamentTeam;
  deleteTournamentTeam: TournamentTeam;
  createTournamentTeamField: ExtensionField;
  updateTournamentTeamField: ExtensionField;
  deleteTournamentTeamField: Scalars['Boolean'];
  createTournamentTeamGroup: TournamentTeamGroup;
  updateTournamentTeamGroup: TournamentTeamGroup;
  deleteTournamentTeamGroup: TournamentTeamGroup;
  createTournamentTeamHistory: TournamentTeamHistory;
  updateTournamentTeamHistory: TournamentTeamHistory;
  deleteTournamentTeamHistory: TournamentTeamHistory;
  createTournamentTeamMember: TournamentTeamMember;
  updateTournamentTeamMember: TournamentTeamMember;
  deleteTournamentTeamMember: TournamentTeamMember;
  createTournamentTeamMemberField: ExtensionField;
  updateTournamentTeamMemberField: ExtensionField;
  deleteTournamentTeamMemberField: Scalars['Boolean'];
  createTournamentTeamMemberSocialAccount: TournamentTeamMemberSocialAccount;
  updateTournamentTeamMemberSocialAccount: TournamentTeamMemberSocialAccount;
  deleteTournamentTeamMemberSocialAccount: TournamentTeamMemberSocialAccount;
  createTournamentTour: TournamentTour;
  updateTournamentTour: TournamentTour;
  deleteTournamentTour: TournamentTour;
  createTournament: Tournament;
  updateTournament: Tournament;
  deleteTournament: Tournament;
  createTournamentField: ExtensionField;
  updateTournamentField: ExtensionField;
  deleteTournamentField: Scalars['Boolean'];
  createTournamentTranslation: TournamentTranslation;
  updateTournamentTranslation: TournamentTranslation;
  deleteTournamentTranslation: TournamentTranslation;
  /** Импорт данных по матчу */
  importTournamentMatchData?: Maybe<Scalars['String']>;
  /** Синхронизация статистики */
  syncTournamentSeasonStats?: Maybe<Scalars['String']>;
  /** Синхронизация событий и протоколов */
  syncTournamentMatchEvents?: Maybe<Scalars['String']>;
  /** Импорт статистики команд по сезону */
  importTournamentSeasonTeamStats: Scalars['Int'];
  /** Синхронизация команд по сезону */
  syncTournamentSeasonTeams: Scalars['Int'];
  /** Импорт статистики игроков по сезону */
  importTournamentPlayerStats: Scalars['Int'];
  /** Импорт составов на матч */
  importTournamentMatchLineups: Scalars['Int'];
  /** Импорт событий матча */
  importTournamentMatchEvents: Scalars['Int'];
  /** Импорт статистики матча */
  importTournamentMatchStats: Scalars['Int'];
  /**
   * Синхронизация тура по сезону:
   * - Вычисляет текущий тур
   * - Обновляет туры
   * - Возвращает текущий или последний тур сезона
   */
  syncTournamentTour: TournamentTour;
  createUserApplicationLogComment: UserApplicationLog;
  createUserAnalyticData: UserAnalyticData;
  updateUserAnalyticData: UserAnalyticData;
  createUserAnalyticField: UserAnalyticField;
  updateUserAnalyticField: UserAnalyticField;
  deleteUserAnalyticField: UserAnalyticField;
  createUserApplication: UserApplication;
  updateUserApplication: UserApplication;
  deleteUserApplication: UserApplication;
  createUserApplicationField: ExtensionField;
  updateUserApplicationField: ExtensionField;
  deleteUserApplicationField: Scalars['Boolean'];
  createUserApplicationType: UserApplicationType;
  updateUserApplicationType: UserApplicationType;
  deleteUserApplicationType: UserApplicationType;
  createUserDocument: UserDocument;
  updateUserDocument: UserDocument;
  deleteUserDocument: UserDocument;
  createUserGroup: UserGroup;
  updateUserGroup: UserGroup;
  deleteUserGroup: UserGroup;
  createUserKid: UserKid;
  updateUserKid: UserKid;
  deleteUserKid: UserKid;
  createUserSocialAccount: UserSocialAccount;
  updateUserSocialAccount: UserSocialAccount;
  deleteUserSocialAccount: UserSocialAccount;
  createUserSubscriber: UserSubscriber;
  updateUserSubscriber: UserSubscriber;
  deleteUserSubscriber: UserSubscriber;
  createUserSubscribersLog: UserSubscribersLog;
  updateUserSubscribersLog: UserSubscribersLog;
  deleteUserSubscribersLog: UserSubscribersLog;
  createUserSubscription: UserSubscription;
  updateUserSubscription: UserSubscription;
  deleteUserSubscription: UserSubscription;
  createUserField: ExtensionField;
  updateUserField: ExtensionField;
  deleteUserField: Scalars['Boolean'];
  subscribeToMatchEvents: MatchEventSubscription;
  /**
   *  Валидация телефона по пинкоду:
   * 1. Передаем телефон, создаем запись в confirmation, отправляем код
   * 2. Вместе с телефоном передаем код для проверки.
   * — Если проверка прошла, то сохраняем пользователю телефон
   */
  validatePhone: Scalars['Boolean'];
  /** Отправка одноразовго пин-кода по смс. */
  sendPin: Scalars['Boolean'];
  /** Отправка одноразовго пин-кода по звонку. */
  sendPinByCall: Scalars['Boolean'];
  /**
   * Проверка пин кода на соответствие отправленного сервером.
   * В случае успеха возвращает токен для аутентификации всех последующих запросов и текущего пользователя.
   * Допускается 3 проверки, потом пин сбрасывается
   */
  validatePin: AuthPayload;
  /** Сохранение настроек клиента */
  saveUserSettings: UserSetting;
  /**
   * Добавление пользователей в подписку.
   * — Можно добавить всех просто не передав query
   * — Можно добавить по фильтру из query
   * — Можно добавить список пользователей передав в том же query массив userId
   *
   * Метод вернет количество добавленных.
   */
  addToUsersSubscription: Scalars['Int'];
  /**
   * Удаление пользователей из подписки.
   * — Можно удалить всех, просто не передав массив пользователей
   * — Можно удалить список пользователей передав массив userId
   * Метод вернет количество удаленных.
   */
  deleteFromUsersSubscription: Scalars['Int'];
  /** Массовая подписка пользователей */
  createUserSubscribers: Scalars['Int'];
  /** Массовая отписка */
  deleteUserSubscribers: Scalars['Int'];
  /** Импорт подписчиков из файла */
  importUserSubscribers: Scalars['Int'];
  unsubscribeUser: Array<UserSubscriber>;
  unsubscribeUserByEmail: Array<UserSubscriber>;
  unsubscribeUserByEmails: Scalars['Boolean'];
  /** Добавление пользователей в группу (группы) по запросу. */
  addUsersToGroup: Array<SystemAsyncTask>;
  /** Добавление пользователя в группу (группы) */
  addUserToGroup: Scalars['Int'];
  /** Удаление пользователей из группы (групп) по запросу. */
  deleteUserFromGroup: Array<SystemAsyncTask>;
  /** Создание пользователя */
  createUser: User;
  /** Редактирование пользователя */
  updateUser: User;
  /** Регистрация нового пользователя */
  registerUser: UserAuthResponse;
  /** Регистрация нового пользователя через Oauth */
  registerUserSocial: UserSocialAuthResponse;
  /** Авторизация */
  loginUser: UserAuthResponse;
  /** Авторизация через master пароль клиента */
  masterLoginUser: UserAuthResponse;
  /** Авторизация нового пользователя через Oauth */
  loginUserSocial: UserSocialAuthResponse;
  /** Обновление accessToken по refreshToken */
  updateUserAccessToken: UserAuthResponse;
  /** Обновление refreshToken */
  updateUserRefreshToken: UserAuthResponse;
  resetUserPassword: Scalars['Boolean'];
  confirmUserEmail: Scalars['Boolean'];
  /** Удалить пользователя */
  deleteUserById: Scalars['Boolean'];
  /** Добавить пользователя в категорию */
  addUserToCategory?: Maybe<Scalars['Boolean']>;
  /** Удалить пользователя из категории */
  removeUserToCategory?: Maybe<Scalars['Boolean']>;
  /**
   * @deprecated
   * Добавить устройство пользователя
   */
  addUserDevice: UserDevice;
  /** Добавление устройства пользователя */
  createUserDevice: UserDevice;
  /**
   * @deprecated
   * Добавить местоположение пользователя
   */
  addUserLocation?: Maybe<UserLocation>;
  /** Добавление местоположение пользователя */
  createUserLocation: UserLocation;
  /** Импорт заказов и билетов пользователя */
  importUserOrdersAndTickets?: Maybe<UserImportResponse>;
  /** Импорт пользователей */
  importUsers: SystemAsyncTask;
  /** Создание пользовательского поля */
  createUserDataField?: Maybe<UserDataField>;
  /** Обновление пользовательского поля */
  updateUserDataField?: Maybe<UserDataField>;
  /** Удаление пользовательского поля */
  deleteUserDataField?: Maybe<UserDataField>;
  /** Массовое создание данных аналитики по пользователям */
  createUserAnalyticDataBatch?: Maybe<Scalars['String']>;
  /** Обновление подписок пользователя */
  updateMySubscriptions: UserSubscriptionConnection;
  /** Обновление подписок пользователя */
  updateUserSubscriptions: UserSubscriptionConnection;
  /** Загрузка и временное хранение файла для импорта пользователей */
  uploadFileToImportUsers: ImportUploadResponse;
  /** Экспорт пользователей */
  exportUsers: ExportResponse;
  updateCommerceOrders: Scalars['Int'];
  /** Создание сессии для клиента, для последующего резервирования мест на события */
  initMyEventSession: EventSession;
  /** Создания заказа события пользователя */
  createMyEventOrder: EventOrder;
  /** Отмена заказа события пользователя */
  cancelMyEventOrder: EventOrder;
  /** Создания пользователем резерва на событие */
  createMyEventReserve: Array<Reserve>;
  /** Удаление пользователем резерва на событие */
  deleteMyEventReserve: Array<Reserve>;
  /** Синхронизация заказов из инфотеха */
  syncMyEventOrders: Scalars['Int'];
  /** Синхронизация заказов из инфотеха по пользователю */
  syncUserEventOrders: Scalars['Int'];
  /** Проверка доступа к событию */
  checkMatchAccess: CheckMatchAccessResponse;
  /** Проверка абонемента */
  checkSeasonTicket: CheckSeasonTicketResponse;
  /** Добавление ПК в уже проданный билет/абонемент */
  setTicketFanId: EventOrder;
  /** Создание заказа еды */
  createFoodOrder: FoodOrder;
  /** Отмена заказа еды */
  cancelFoodOrder: FoodOrder;
  /** Создание заказа лояльности */
  createLoyaltyOrder: LoyaltyOrder;
  /** Отмена заказа лояльности */
  cancelLoyaltyOrder: LoyaltyOrder;
  /** Синхронизация аккаунтов лояльности пользователя */
  syncMyLoyaltyAccounts: Array<PaymentAccount>;
  /** Отправка сообщения на экран стадиона по товару лояльности */
  createStadiumMessage: LoyaltyOrder;
  /** Инициализация платежа */
  initPaymentGateway: InitPaymentGatewayResponse;
  validateOrderPayment: PaymentOrderResult;
  validateOrderPayments: Scalars['Boolean'];
  saveWebTranslations: Array<Translation>;
  saveUserSourceSettings: Scalars['Boolean'];
  /** Заявка на вступление в детский клуб */
  createApplicationKid: UserApplication;
  /** Заявка на вступление в детский сектор */
  createApplicationFamilySector: UserApplication;
  /** Редактирование заявки на вступление в детский сектор */
  updateApplicationFamilySector: UserApplication;
  /** Отмена заявки на вступление в детский сектор */
  cancelApplicationFamilySector: UserApplication;
  /** Заявка на проведение урока */
  createApplicationLesson: UserApplication;
  /** Заявка для проведения мероприятия */
  createApplicationEvent: UserApplication;
  /** Вопрос клубу */
  createApplicationQuestion: UserApplication;
  /** Заявка - Армейцы без границ */
  createApplicationDSAMessage: UserApplication;
  /** Обрнатная связь с разработчиками */
  createApplicationFeedbackDeveloper: UserApplication;
  /** Отправка сообщения на экран стадиона по заявке */
  createApplicationScreenStadium: UserApplication;
  deleteUser: Scalars['Boolean'];
};

export type MutationUpdateArticleBlocksArgs = {
  data: Array<ArticleBlocksUpdateInput>;
};

export type MutationSaveArticleTagsArgs = {
  data: SaveArticleTagsInput;
};

export type MutationCreateArticleArgs = {
  data: ArticleCreateInput;
};

export type MutationUpdateArticleArgs = {
  id: Scalars['Int'];
  data: ArticleUpdateInput;
};

export type MutationDeleteArticleArgs = {
  id: Scalars['Int'];
};

export type MutationCreateArticleArticleTagArgs = {
  data: ArticleArticleTagCreateInput;
};

export type MutationUpdateArticleArticleTagArgs = {
  id: Scalars['Int'];
  data: ArticleArticleTagUpdateInput;
};

export type MutationDeleteArticleArticleTagArgs = {
  id: Scalars['Int'];
};

export type MutationCreateArticleAuthorArgs = {
  data: ArticleAuthorCreateInput;
};

export type MutationUpdateArticleAuthorArgs = {
  id: Scalars['Int'];
  data: ArticleAuthorUpdateInput;
};

export type MutationDeleteArticleAuthorArgs = {
  id: Scalars['Int'];
};

export type MutationCreateArticleBlockArgs = {
  data: ArticleBlockCreateInput;
};

export type MutationUpdateArticleBlockArgs = {
  id: Scalars['Int'];
  data: ArticleBlockUpdateInput;
};

export type MutationDeleteArticleBlockArgs = {
  id: Scalars['Int'];
};

export type MutationCreateArticleCategoryArgs = {
  data: ArticleCategoryCreateInput;
};

export type MutationUpdateArticleCategoryArgs = {
  id: Scalars['Int'];
  data: ArticleCategoryUpdateInput;
};

export type MutationDeleteArticleCategoryArgs = {
  id: Scalars['Int'];
};

export type MutationCreateArticleLinkArgs = {
  data: ArticleLinkCreateInput;
};

export type MutationUpdateArticleLinkArgs = {
  id: Scalars['Int'];
  data: ArticleLinkUpdateInput;
};

export type MutationDeleteArticleLinkArgs = {
  id: Scalars['Int'];
};

export type MutationCreateArticleTagArgs = {
  data: ArticleTagCreateInput;
};

export type MutationUpdateArticleTagArgs = {
  id: Scalars['Int'];
  data: ArticleTagUpdateInput;
};

export type MutationDeleteArticleTagArgs = {
  id: Scalars['Int'];
};

export type MutationUpdateCatalogueItemBatchArgs = {
  data: Array<CatalogueItemBatchUpdateInput>;
};

export type MutationUpdateCatalogueCategoryBatchArgs = {
  data: Array<CatalogueCategoryBatchUpdateInput>;
};

export type MutationCreateCatalogueArgs = {
  data: CatalogueCreateInput;
};

export type MutationUpdateCatalogueArgs = {
  id: Scalars['Int'];
  data: CatalogueUpdateInput;
};

export type MutationDeleteCatalogueArgs = {
  id: Scalars['Int'];
};

export type MutationCreateCatalogueFieldArgs = {
  data: CreateExtensionFieldInput;
};

export type MutationUpdateCatalogueFieldArgs = {
  id: Scalars['Int'];
  data: UpdateExtensionFieldInput;
};

export type MutationDeleteCatalogueFieldArgs = {
  id: Scalars['Int'];
};

export type MutationCreateCatalogueCategoryArgs = {
  data: CatalogueCategoryCreateInput;
};

export type MutationUpdateCatalogueCategoryArgs = {
  id: Scalars['Int'];
  data: CatalogueCategoryUpdateInput;
};

export type MutationDeleteCatalogueCategoryArgs = {
  id: Scalars['Int'];
};

export type MutationCreateCatalogueCategoryFieldArgs = {
  data: CreateExtensionFieldInput;
};

export type MutationUpdateCatalogueCategoryFieldArgs = {
  id: Scalars['Int'];
  data: UpdateExtensionFieldInput;
};

export type MutationDeleteCatalogueCategoryFieldArgs = {
  id: Scalars['Int'];
};

export type MutationCreateCatalogueItemArgs = {
  data: CatalogueItemCreateInput;
};

export type MutationUpdateCatalogueItemArgs = {
  id: Scalars['Int'];
  data: CatalogueItemUpdateInput;
};

export type MutationDeleteCatalogueItemArgs = {
  id: Scalars['Int'];
};

export type MutationCreateCatalogueItemFieldArgs = {
  data: CreateExtensionFieldInput;
};

export type MutationUpdateCatalogueItemFieldArgs = {
  id: Scalars['Int'];
  data: UpdateExtensionFieldInput;
};

export type MutationDeleteCatalogueItemFieldArgs = {
  id: Scalars['Int'];
};

export type MutationCreateCatalogueItemPriceArgs = {
  data: CatalogueItemPriceCreateInput;
};

export type MutationUpdateCatalogueItemPriceArgs = {
  id: Scalars['Int'];
  data: CatalogueItemPriceUpdateInput;
};

export type MutationDeleteCatalogueItemPriceArgs = {
  id: Scalars['Int'];
};

export type MutationCreateCatalogueRelationArgs = {
  data: CatalogueRelationCreateInput;
};

export type MutationUpdateCatalogueRelationArgs = {
  id: Scalars['Int'];
  data: CatalogueRelationUpdateInput;
};

export type MutationDeleteCatalogueRelationArgs = {
  id: Scalars['Int'];
};

export type MutationCreateCateringArgs = {
  data: CateringCreateInput;
};

export type MutationUpdateCateringArgs = {
  id: Scalars['Int'];
  data: CateringUpdateInput;
};

export type MutationDeleteCateringArgs = {
  id: Scalars['Int'];
};

export type MutationCreateCommerceOrderDataArgs = {
  data: CommerceOrderDataCreateInput;
};

export type MutationUpdateCommerceOrderDataArgs = {
  id: Scalars['Int'];
  data: CommerceOrderDataUpdateInput;
};

export type MutationDeleteCommerceOrderDataArgs = {
  id: Scalars['Int'];
};

export type MutationCreateCommerceOrderFieldArgs = {
  data: CommerceOrderFieldCreateInput;
};

export type MutationUpdateCommerceOrderFieldArgs = {
  id: Scalars['Int'];
  data: CommerceOrderFieldUpdateInput;
};

export type MutationDeleteCommerceOrderFieldArgs = {
  id: Scalars['Int'];
};

export type MutationCreateCommerceOrderItemArgs = {
  data: CommerceOrderItemCreateInput;
};

export type MutationUpdateCommerceOrderItemArgs = {
  id: Scalars['Int'];
  data: CommerceOrderItemUpdateInput;
};

export type MutationDeleteCommerceOrderItemArgs = {
  id: Scalars['Int'];
};

export type MutationCreateCommerceSettingsArgs = {
  data: CommerceSettingsCreateInput;
};

export type MutationUpdateCommerceSettingsArgs = {
  id: Scalars['Int'];
  data: CommerceSettingsUpdateInput;
};

export type MutationDeleteCommerceSettingsArgs = {
  id: Scalars['Int'];
};

export type MutationCreateCommerceShopArgs = {
  data: CommerceShopCreateInput;
};

export type MutationUpdateCommerceShopArgs = {
  id: Scalars['Int'];
  data: CommerceShopUpdateInput;
};

export type MutationDeleteCommerceShopArgs = {
  id: Scalars['Int'];
};

export type MutationCreateCommerceTemplateArgs = {
  data: CommerceTemplateCreateInput;
};

export type MutationUpdateCommerceTemplateArgs = {
  id: Scalars['Int'];
  data: CommerceTemplateUpdateInput;
};

export type MutationDeleteCommerceTemplateArgs = {
  id: Scalars['Int'];
};

export type MutationCreateCommerceOrderArgs = {
  data: CommerceOrderCreateInput;
};

export type MutationCancelCommerceOrderArgs = {
  id: Scalars['Int'];
};

export type MutationUpdateCommerceOrderArgs = {
  id: Scalars['Int'];
  data: CommerceOrderUpdateInput;
};

export type MutationDeleteCommerceOrderArgs = {
  id: Scalars['Int'];
};

export type MutationUpdateCommerceOrderDatasArgs = {
  data: Array<CommerceOrderDatasUpdateInput>;
};

export type MutationCommerceOrderPrintArgs = {
  id: Scalars['Int'];
  printerId: Scalars['Int'];
  templateId: Scalars['Int'];
};

export type MutationCreateContentTranslationArgs = {
  data: ContentTranslationCreateInput;
};

export type MutationUpdateContentTranslationArgs = {
  id: Scalars['Int'];
  data: ContentTranslationUpdateInput;
};

export type MutationDeleteContentTranslationArgs = {
  id: Scalars['Int'];
};

export type MutationSaveContentTranslationsArgs = {
  data: Array<ContentTranslationSaveInput>;
};

export type MutationCreateDisputeArgs = {
  data: DisputeCreateInput;
};

export type MutationCreateDisputeDisputeResponseArgs = {
  data: DisputeResponseCreateInput;
};

export type MutationUpdateDisputeArgs = {
  id: Scalars['Int'];
  data: DisputeUpdateInput;
};

export type MutationUpdateDisputeDisputeResponseArgs = {
  id: Scalars['Int'];
  data: DisputeResponseUpdateInput;
};

export type MutationConfirmDisputeArgs = {
  id: Scalars['Int'];
  isWin: Scalars['Boolean'];
};

export type MutationConfirmDisputeDisputeResponseArgs = {
  id: Scalars['Int'];
  isWin: Scalars['Boolean'];
};

export type MutationConfirmDisputeDisputeModeratorArgs = {
  id: Scalars['Int'];
  result: DisputeResult;
};

export type MutationArchivingDisputesFromSeasonArgs = {
  seasonId: Scalars['Int'];
};

export type MutationSaveDisputeSettingsArgs = {
  data: DisputeSettingsUpdateInput;
};

export type MutationUpdateDisputeSettingsArgs = {
  id: Scalars['Int'];
  data: DisputeSettingsUpdateInput;
};

export type MutationGenerateDocsByEventOrderArgs = {
  userId: Scalars['Int'];
  notify: Scalars['Boolean'];
  orderId?: Maybe<Scalars['Int']>;
  ticketId?: Maybe<Scalars['Int']>;
};

export type MutationImportToEventWhitelistArgs = {
  eventId: Scalars['Int'];
  file: Scalars['Upload'];
};

export type MutationAddUsersToGroupFromTicketsArgs = {
  groupId: Array<Scalars['Int']>;
  query: DefaultQueryInput;
};

export type MutationCancelEventOrderArgs = {
  id: Scalars['Int'];
};

export type MutationCreateEventOrderTicketsByOrderIdArgs = {
  orderId: Scalars['Int'];
  data: Array<EventOrderTicketCreateInput>;
};

export type MutationCreateEventArgs = {
  data: EventCreateInput;
};

export type MutationUpdateEventArgs = {
  id: Scalars['Int'];
  data: EventUpdateInput;
};

export type MutationDeleteEventArgs = {
  id: Scalars['Int'];
};

export type MutationCreateEventFieldArgs = {
  data: CreateExtensionFieldInput;
};

export type MutationUpdateEventFieldArgs = {
  id: Scalars['Int'];
  data: UpdateExtensionFieldInput;
};

export type MutationDeleteEventFieldArgs = {
  id: Scalars['Int'];
};

export type MutationCreateEventEventAccessArgs = {
  data: EventEventAccessCreateInput;
};

export type MutationUpdateEventEventAccessArgs = {
  id: Scalars['Int'];
  data: EventEventAccessUpdateInput;
};

export type MutationDeleteEventEventAccessArgs = {
  id: Scalars['Int'];
};

export type MutationCreateEventEventWhitelistArgs = {
  data: EventEventWhitelistCreateInput;
};

export type MutationUpdateEventEventWhitelistArgs = {
  id: Scalars['Int'];
  data: EventEventWhitelistUpdateInput;
};

export type MutationDeleteEventEventWhitelistArgs = {
  id: Scalars['Int'];
};

export type MutationCreateEventOrderArgs = {
  data: EventOrderCreateInput;
};

export type MutationUpdateEventOrderArgs = {
  id: Scalars['Int'];
  data: EventOrderUpdateInput;
};

export type MutationDeleteEventOrderArgs = {
  id: Scalars['Int'];
};

export type MutationCreateEventOrderFieldArgs = {
  data: CreateExtensionFieldInput;
};

export type MutationUpdateEventOrderFieldArgs = {
  id: Scalars['Int'];
  data: UpdateExtensionFieldInput;
};

export type MutationDeleteEventOrderFieldArgs = {
  id: Scalars['Int'];
};

export type MutationCreateEventOrderTicketArgs = {
  data: EventOrderTicketCreateInput;
};

export type MutationUpdateEventOrderTicketArgs = {
  id: Scalars['Int'];
  data: EventOrderTicketUpdateInput;
};

export type MutationDeleteEventOrderTicketArgs = {
  id: Scalars['Int'];
};

export type MutationCreateEventOrderTicketFieldArgs = {
  data: CreateExtensionFieldInput;
};

export type MutationUpdateEventOrderTicketFieldArgs = {
  id: Scalars['Int'];
  data: UpdateExtensionFieldInput;
};

export type MutationDeleteEventOrderTicketFieldArgs = {
  id: Scalars['Int'];
};

export type MutationCreateEventPlaceArgs = {
  data: EventPlaceCreateInput;
};

export type MutationUpdateEventPlaceArgs = {
  id: Scalars['Int'];
  data: EventPlaceUpdateInput;
};

export type MutationDeleteEventPlaceArgs = {
  id: Scalars['Int'];
};

export type MutationCreateEventPlaceSectorArgs = {
  data: EventPlaceSectorCreateInput;
};

export type MutationUpdateEventPlaceSectorArgs = {
  id: Scalars['Int'];
  data: EventPlaceSectorUpdateInput;
};

export type MutationDeleteEventPlaceSectorArgs = {
  id: Scalars['Int'];
};

export type MutationCreateEventReserveArgs = {
  data: EventReserveCreateInput;
};

export type MutationUpdateEventReserveArgs = {
  id: Scalars['Int'];
  data: EventReserveUpdateInput;
};

export type MutationDeleteEventReserveArgs = {
  id: Scalars['Int'];
};

export type MutationUpdateEventSettingsArgs = {
  id: Scalars['Int'];
  data: EventSettingsUpdateInput;
};

export type MutationCreateEventTypeArgs = {
  data: EventTypeCreateInput;
};

export type MutationUpdateEventTypeArgs = {
  id: Scalars['Int'];
  data: EventTypeUpdateInput;
};

export type MutationDeleteEventTypeArgs = {
  id: Scalars['Int'];
};

export type MutationDeleteEventOrderTicketByOrderIdArgs = {
  orderId: Scalars['Int'];
};

export type MutationExportUsersToUnisenderArgs = {
  query?: Maybe<DefaultQueryInput>;
  settings: ExportUnisenderSettingsInput;
};

export type MutationUnisenderCreateListArgs = {
  data: UnisenderCreateListInput;
};

export type MutationCreatePaymentAccountByApplicationCodeArgs = {
  userId: Scalars['Int'];
  partnerId: Scalars['Int'];
  providerCode: Scalars['String'];
};

export type MutationCreateLoyaltyApplicationArgs = {
  data: LoyaltyApplicationCreateInput;
};

export type MutationUpdateLoyaltyApplicationArgs = {
  id: Scalars['Int'];
  data: LoyaltyApplicationUpdateInput;
};

export type MutationDeleteLoyaltyApplicationArgs = {
  id: Scalars['Int'];
};

export type MutationCreateLoyaltyPartnerArgs = {
  data: LoyaltyPartnerCreateInput;
};

export type MutationUpdateLoyaltyPartnerArgs = {
  id: Scalars['Int'];
  data: LoyaltyPartnerUpdateInput;
};

export type MutationDeleteLoyaltyPartnerArgs = {
  id: Scalars['Int'];
};

export type MutationCreateLoyaltyPromoArgs = {
  data: LoyaltyPromoCreateInput;
};

export type MutationUpdateLoyaltyPromoArgs = {
  id: Scalars['Int'];
  data: LoyaltyPromoUpdateInput;
};

export type MutationDeleteLoyaltyPromoArgs = {
  id: Scalars['Int'];
};

export type MutationCreateLoyaltyPromoCodeArgs = {
  data: LoyaltyPromoCodeCreateInput;
};

export type MutationUpdateLoyaltyPromoCodeArgs = {
  id: Scalars['Int'];
  data: LoyaltyPromoCodeUpdateInput;
};

export type MutationDeleteLoyaltyPromoCodeArgs = {
  id: Scalars['Int'];
};

export type MutationGenerateLoyaltyPromoCodesArgs = {
  promoId: Scalars['Int'];
  count: Scalars['Int'];
  length: Scalars['Int'];
};

export type MutationResetLoyaltyPromoCodesArgs = {
  promoId: Scalars['Int'];
};

export type MutationApplyLoyaltyPromoCodeArgs = {
  code: Scalars['String'];
};

export type MutationImportPromocodesArgs = {
  promoId: Scalars['Int'];
  file: Scalars['Upload'];
};

export type MutationExportPromocodesArgs = {
  promoId: Scalars['Int'];
  format: ExportFormat;
};

export type MutationCreateMessengerMailingArgs = {
  data: MessengerMailingCreateInput;
};

export type MutationUpdateMessengerMailingArgs = {
  id: Scalars['Int'];
  data: MessengerMailingUpdateInput;
};

export type MutationDeleteMessengerMailingArgs = {
  id: Scalars['Int'];
};

export type MutationCreateMessengerMailingActivityArgs = {
  data: MessengerMailingActivityCreateInput;
};

export type MutationUpdateMessengerMailingActivityArgs = {
  id: Scalars['Int'];
  data: MessengerMailingActivityUpdateInput;
};

export type MutationDeleteMessengerMailingActivityArgs = {
  id: Scalars['Int'];
};

export type MutationCreateMessengerMailingRedirectArgs = {
  data: MessengerMailingRedirectCreateInput;
};

export type MutationCreateMessengerProviderArgs = {
  data: MessengerProviderCreateInput;
};

export type MutationUpdateMessengerProviderArgs = {
  id: Scalars['Int'];
  data: MessengerProviderUpdateInput;
};

export type MutationDeleteMessengerProviderArgs = {
  id: Scalars['Int'];
};

export type MutationCreateMessengerQueueTaskArgs = {
  data: MessengerQueueTaskCreateInput;
};

export type MutationUpdateMessengerQueueTaskArgs = {
  id: Scalars['Int'];
  data: MessengerQueueTaskUpdateInput;
};

export type MutationDeleteMessengerQueueTaskArgs = {
  id: Scalars['Int'];
};

export type MutationCreateMessengerTemplateArgs = {
  data: MessengerTemplateCreateInput;
};

export type MutationUpdateMessengerTemplateArgs = {
  id: Scalars['Int'];
  data: MessengerTemplateUpdateInput;
};

export type MutationDeleteMessengerTemplateArgs = {
  id: Scalars['Int'];
};

export type MutationUpdateMessengerTemplateProviderArgs = {
  id: Scalars['Int'];
  data: MessengerTemplateProviderUpdateInput;
};

export type MutationCreateMessengerTransportArgs = {
  data: MessengerTransportCreateInput;
};

export type MutationUpdateMessengerTransportArgs = {
  id: Scalars['Int'];
  data: MessengerTransportUpdateInput;
};

export type MutationDeleteMessengerTransportArgs = {
  id: Scalars['Int'];
};

export type MutationSendPushByUserArgs = {
  data: UserPushInput;
};

export type MutationSendNotificationArgs = {
  data: SendNotificationInput;
};

export type MutationSendPushBySubscriptionsArgs = {
  data: SubscriptionPushInput;
};

export type MutationAddToMessengerQueueArgs = {
  mailingId: Scalars['Int'];
  userId?: Maybe<Array<Scalars['Int']>>;
  query?: Maybe<DefaultQueryInput>;
};

export type MutationDeleteFromMessengerQueueArgs = {
  mailingId: Scalars['Int'];
  userId?: Maybe<Array<Scalars['Int']>>;
};

export type MutationUpdateMessengerSettingArgs = {
  data: MessengerSettingUpdateInput;
};

export type MutationImportRecipientsToQueueNotificationArgs = {
  mailingId: Scalars['Int'];
  file: Scalars['Upload'];
  subscriptionId?: Maybe<Scalars['Int']>;
};

export type MutationTestMessengerMailingArgs = {
  mailingId: Scalars['Int'];
};

export type MutationCreateMessengerQueueNotificationArgs = {
  data: MessengerQueueNotificationCreateInput;
};

export type MutationUpdateMessengerQueueNotificationArgs = {
  id: Scalars['String'];
  data: MessengerQueueNotificationUpdateInput;
};

export type MutationDeleteMessengerQueueNotificationArgs = {
  id: Scalars['String'];
};

export type MutationSaveRedirectOnMailArgs = {
  data: SaveRedirectOnMailInput;
};

export type MutationImportTemplateNotificationsArgs = {
  providerId?: Maybe<Scalars['Int']>;
  templateId?: Maybe<Scalars['Int']>;
};

export type MutationSaveMobileTranslationsArgs = {
  data?: Maybe<Array<MobileTranslationSaveInput>>;
};

export type MutationDeleteMobileTranslationArgs = {
  id: Scalars['Int'];
};

export type MutationDepositToAccountArgs = {
  data: PaymentAccountOperationInput;
};

export type MutationChargeFromAccountArgs = {
  data: PaymentAccountOperationInput;
};

export type MutationUpdatePaymentAccountByPartnerCodeArgs = {
  where: PaymentAccountWhereInput;
  data: PaymentAccountUpdateInput;
};

export type MutationSyncPaymentAccountBalanceByPartnerCodeArgs = {
  where: PaymentAccountWhereInput;
};

export type MutationSyncPaymentAccountArgs = {
  id: Scalars['Int'];
};

export type MutationSyncPaymentAccountsArgs = {
  userId: Scalars['Int'];
  currencyId?: Maybe<Scalars['Int']>;
};

export type MutationCreatePaymentAccountArgs = {
  data: PaymentAccountCreateInput;
};

export type MutationUpdatePaymentAccountArgs = {
  id: Scalars['Int'];
  data: PaymentAccountUpdateInput;
};

export type MutationDeletePaymentAccountArgs = {
  id: Scalars['Int'];
};

export type MutationCreatePaymentBankCardArgs = {
  data: PaymentBankCardCreateInput;
};

export type MutationUpdatePaymentBankCardArgs = {
  id: Scalars['Int'];
  data: PaymentBankCardUpdateInput;
};

export type MutationDeletePaymentBankCardArgs = {
  id: Scalars['Int'];
};

export type MutationCreatePaymentChannelArgs = {
  data: PaymentChannelCreateInput;
};

export type MutationUpdatePaymentChannelArgs = {
  id: Scalars['Int'];
  data: PaymentChannelUpdateInput;
};

export type MutationDeletePaymentChannelArgs = {
  id: Scalars['Int'];
};

export type MutationCreatePaymentCurrencyArgs = {
  data: PaymentCurrencyCreateInput;
};

export type MutationUpdatePaymentCurrencyArgs = {
  id: Scalars['Int'];
  data: PaymentCurrencyUpdateInput;
};

export type MutationDeletePaymentCurrencyArgs = {
  id: Scalars['Int'];
};

export type MutationCreatePaymentGatewayArgs = {
  data: PaymentGatewayCreateInput;
};

export type MutationUpdatePaymentGatewayArgs = {
  id: Scalars['Int'];
  data: PaymentGatewayUpdateInput;
};

export type MutationDeletePaymentGatewayArgs = {
  id: Scalars['Int'];
};

export type MutationCreatePaymentProviderArgs = {
  data: PaymentProviderCreateInput;
};

export type MutationUpdatePaymentProviderArgs = {
  id: Scalars['Int'];
  data: PaymentProviderUpdateInput;
};

export type MutationDeletePaymentProviderArgs = {
  id: Scalars['Int'];
};

export type MutationCreatePaymentTransactionArgs = {
  data: PaymentTransactionCreateInput;
};

export type MutationUpdatePaymentTransactionArgs = {
  id: Scalars['Int'];
  data: PaymentTransactionUpdateInput;
};

export type MutationDeletePaymentTransactionArgs = {
  id: Scalars['Int'];
};

export type MutationCreatePaymentTransactionLogArgs = {
  data: PaymentTransactionLogCreateInput;
};

export type MutationUpdatePaymentTransactionLogArgs = {
  id: Scalars['Int'];
  data: PaymentTransactionLogUpdateInput;
};

export type MutationDeletePaymentTransactionLogArgs = {
  id: Scalars['Int'];
};

export type MutationPayOrderArgs = {
  data: PayOrderInput;
};

export type MutationRefundOrderArgs = {
  data: RefundOrderInput;
};

export type MutationChargeByBankCardArgs = {
  data: CryptogramPaymentInput;
};

export type MutationProcess3DSecureArgs = {
  data: Process3DSecureInput;
};

export type MutationSendCashierCheckByOrderArgs = {
  orderId: Scalars['Int'];
  orderType: PaymentTransactionOrderType;
  type: PaymentReceiptType;
};

export type MutationSendCashierCheckByReceiptArgs = {
  data: CashierCheckReceiptInput;
};

export type MutationInitPaymentArgs = {
  data: InitPaymentInput;
};

export type MutationPaymentWebHookArgs = {
  data: PaymentWebHookInput;
};

export type MutationConfirmOrderPaymentArgs = {
  data: ConfirmOrderPaymentInput;
};

export type MutationPaymentConfirmPurchaseArgs = {
  store?: Maybe<AppStoreEnum>;
  receipts: Array<Scalars['String']>;
};

export type MutationValidatePaymentByOrderIdArgs = {
  providerId: Scalars['Int'];
  orderId: Scalars['Int'];
  orderType: PaymentTransactionOrderType;
};

export type MutationStartQuizAttemptArgs = {
  quizId: Scalars['Int'];
};

export type MutationFinishQuizAttemptArgs = {
  id: Scalars['Int'];
};

export type MutationResetQuizAttemptsArgs = {
  data: QuizResetAttemptsInput;
};

export type MutationCreateQuizQuestionArgs = {
  data: QuizQuestionCreateInput;
};

export type MutationUpdateQuizQuestionArgs = {
  id: Scalars['Int'];
  data: QuizQuestionUpdateInput;
};

export type MutationDeleteQuizQuestionArgs = {
  id: Scalars['Int'];
};

export type MutationCreateQuizQuestionAnswerArgs = {
  data: QuizQuestionAnswerCreateInput;
};

export type MutationUpdateQuizQuestionAnswerArgs = {
  id: Scalars['Int'];
  data: QuizQuestionAnswerUpdateInput;
};

export type MutationDeleteQuizQuestionAnswerArgs = {
  id: Scalars['Int'];
};

export type MutationCreateQuizArgs = {
  data: QuizCreateInput;
};

export type MutationUpdateQuizArgs = {
  id: Scalars['Int'];
  data: QuizUpdateInput;
};

export type MutationDeleteQuizArgs = {
  id: Scalars['Int'];
};

export type MutationCreateQuizAttemptAnswerArgs = {
  data: QuizAttemptAnswerCreateInput;
};

export type MutationCreateSocialNetworkArgs = {
  data: SocialNetworkCreateInput;
};

export type MutationUpdateSocialNetworkArgs = {
  id: Scalars['Int'];
  data: SocialNetworkUpdateInput;
};

export type MutationDeleteSocialNetworkArgs = {
  id: Scalars['Int'];
};

export type MutationCreateSocialNewsSettingArgs = {
  data: SocialNewsSettingCreateInput;
};

export type MutationUpdateSocialNewsSettingArgs = {
  id: Scalars['Int'];
  data: SocialNewsSettingUpdateInput;
};

export type MutationDeleteSocialNewsSettingArgs = {
  id: Scalars['Int'];
};

export type MutationUpdateSystemAsyncTaskArgs = {
  id: Scalars['Int'];
  data: SystemAsyncTaskUpdateInput;
};

export type MutationCreateSystemPrinterArgs = {
  data: SystemPrinterCreateInput;
};

export type MutationUpdateSystemPrinterArgs = {
  id: Scalars['Int'];
  data: SystemPrinterUpdateInput;
};

export type MutationDeleteSystemPrinterArgs = {
  id: Scalars['Int'];
};

export type MutationCreateSystemTaskArgs = {
  data: SystemTaskCreateInput;
};

export type MutationUpdateSystemTaskArgs = {
  id: Scalars['UUID'];
  data: SystemTaskUpdateInput;
};

export type MutationDeleteSystemTaskArgs = {
  id: Scalars['UUID'];
};

export type MutationCreateSystemTranslationArgs = {
  data: SystemTranslationCreateInput;
};

export type MutationUpdateSystemTranslationArgs = {
  id: Scalars['Int'];
  data: SystemTranslationUpdateInput;
};

export type MutationDeleteSystemTranslationArgs = {
  id: Scalars['Int'];
};

export type MutationCreateSystemWebHookArgs = {
  data: SystemWebHookCreateInput;
};

export type MutationUpdateSystemWebHookArgs = {
  id: Scalars['Int'];
  data: SystemWebHookUpdateInput;
};

export type MutationDeleteSystemWebHookArgs = {
  id: Scalars['Int'];
};

export type MutationUpdateSystemTranslationsArgs = {
  data: Array<SystemTranslationUpdateInput>;
};

export type MutationSaveSystemTranslationArgs = {
  data: SystemTranslationSaveInput;
};

export type MutationSystemLoginArgs = {
  username: Scalars['String'];
  password: Scalars['String'];
  clientId?: Maybe<Scalars['Int']>;
};

export type MutationCreateSystemUserArgs = {
  data: CreateSystemUserInput;
};

export type MutationUpdateSystemUserArgs = {
  id: Scalars['ID'];
  data: UpdateSystemUserInput;
};

export type MutationGrantAccessArgs = {
  userId: Scalars['ID'];
  aclId: Scalars['ID'];
};

export type MutationRevokeAccessArgs = {
  userId: Scalars['ID'];
  aclId: Scalars['ID'];
};

export type MutationUpdateUserSettingsArgs = {
  data: UpdateUserSettingsInput;
};

export type MutationTriggerSystemWebHookArgs = {
  id: Scalars['Int'];
  entityId?: Maybe<Scalars['Int']>;
  payload?: Maybe<SystemWebHookPayloadInput>;
};

export type MutationCreateTemplateApplePassArgs = {
  data: TemplateApplePassCreateInput;
};

export type MutationUpdateTemplateApplePassArgs = {
  id: Scalars['Int'];
  data: TemplateApplePassUpdateInput;
};

export type MutationDeleteTemplateApplePassArgs = {
  id: Scalars['Int'];
};

export type MutationCreateTemplateNotificationArgs = {
  data: TemplateNotificationCreateInput;
};

export type MutationUpdateTemplateNotificationArgs = {
  id: Scalars['Int'];
  data: TemplateNotificationUpdateInput;
};

export type MutationDeleteTemplateNotificationArgs = {
  id: Scalars['Int'];
};

export type MutationCreateTemplatePdfArgs = {
  data: TemplatePdfCreateInput;
};

export type MutationUpdateTemplatePdfArgs = {
  id: Scalars['Int'];
  data: TemplatePdfUpdateInput;
};

export type MutationDeleteTemplatePdfArgs = {
  id: Scalars['Int'];
};

export type MutationUpdateTemplateProviderArgs = {
  id: Scalars['Int'];
  data: TemplateProviderUpdateInput;
};

export type MutationCreateTournamentDisputeArgs = {
  data: TournamentDisputeCreateInput;
};

export type MutationUpdateTournamentDisputeArgs = {
  id: Scalars['Int'];
  data: TournamentDisputeUpdateInput;
};

export type MutationDeleteTournamentDisputeArgs = {
  id: Scalars['Int'];
};

export type MutationCreateTournamentDisputeResponseArgs = {
  data: TournamentDisputeResponseCreateInput;
};

export type MutationUpdateTournamentDisputeResponseArgs = {
  id: Scalars['Int'];
  data: TournamentDisputeResponseUpdateInput;
};

export type MutationDeleteTournamentDisputeResponseArgs = {
  id: Scalars['Int'];
};

export type MutationCreateTournamentDisqualificationArgs = {
  data: TournamentDisqualificationCreateInput;
};

export type MutationUpdateTournamentDisqualificationArgs = {
  id: Scalars['Int'];
  data: TournamentDisqualificationUpdateInput;
};

export type MutationDeleteTournamentDisqualificationArgs = {
  id: Scalars['Int'];
};

export type MutationCreateTournamentGroupArgs = {
  data: TournamentGroupCreateInput;
};

export type MutationUpdateTournamentGroupArgs = {
  id: Scalars['Int'];
  data: TournamentGroupUpdateInput;
};

export type MutationDeleteTournamentGroupArgs = {
  id: Scalars['Int'];
};

export type MutationCreateTournamentMatchArgs = {
  data: TournamentMatchCreateInput;
};

export type MutationUpdateTournamentMatchArgs = {
  id: Scalars['Int'];
  data: TournamentMatchUpdateInput;
};

export type MutationDeleteTournamentMatchArgs = {
  id: Scalars['Int'];
};

export type MutationCreateTournamentMatchFieldArgs = {
  data: CreateExtensionFieldInput;
};

export type MutationUpdateTournamentMatchFieldArgs = {
  id: Scalars['Int'];
  data: UpdateExtensionFieldInput;
};

export type MutationDeleteTournamentMatchFieldArgs = {
  id: Scalars['Int'];
};

export type MutationCreateTournamentMatchEventArgs = {
  data: TournamentMatchEventCreateInput;
};

export type MutationUpdateTournamentMatchEventArgs = {
  id: Scalars['Int'];
  data: TournamentMatchEventUpdateInput;
};

export type MutationDeleteTournamentMatchEventArgs = {
  id: Scalars['Int'];
};

export type MutationCreateTournamentMatchLineUpArgs = {
  data: TournamentMatchLineUpCreateInput;
};

export type MutationUpdateTournamentMatchLineUpArgs = {
  id: Scalars['Int'];
  data: TournamentMatchLineUpUpdateInput;
};

export type MutationDeleteTournamentMatchLineUpArgs = {
  id: Scalars['Int'];
};

export type MutationCreateTournamentMatchProtocolArgs = {
  data: TournamentMatchProtocolCreateInput;
};

export type MutationUpdateTournamentMatchProtocolArgs = {
  id: Scalars['Int'];
  data: TournamentMatchProtocolUpdateInput;
};

export type MutationDeleteTournamentMatchProtocolArgs = {
  id: Scalars['Int'];
};

export type MutationCreateTournamentMatchStatArgs = {
  data: TournamentMatchStatCreateInput;
};

export type MutationUpdateTournamentMatchStatArgs = {
  id: Scalars['Int'];
  data: TournamentMatchStatUpdateInput;
};

export type MutationDeleteTournamentMatchStatArgs = {
  id: Scalars['Int'];
};

export type MutationCreateTournamentPlayerStatArgs = {
  data: TournamentPlayerStatCreateInput;
};

export type MutationUpdateTournamentPlayerStatArgs = {
  id: Scalars['Int'];
  data: TournamentPlayerStatUpdateInput;
};

export type MutationDeleteTournamentPlayerStatArgs = {
  id: Scalars['Int'];
};

export type MutationCreateTournamentRefereeArgs = {
  data: TournamentRefereeCreateInput;
};

export type MutationUpdateTournamentRefereeArgs = {
  id: Scalars['Int'];
  data: TournamentRefereeUpdateInput;
};

export type MutationDeleteTournamentRefereeArgs = {
  id: Scalars['Int'];
};

export type MutationCreateTournamentSeasonArgs = {
  data: TournamentSeasonCreateInput;
};

export type MutationUpdateTournamentSeasonArgs = {
  id: Scalars['Int'];
  data: TournamentSeasonUpdateInput;
};

export type MutationDeleteTournamentSeasonArgs = {
  id: Scalars['Int'];
};

export type MutationCreateTournamentSeasonApplicationFormArgs = {
  data: TournamentSeasonApplicationFormCreateInput;
};

export type MutationUpdateTournamentSeasonApplicationFormArgs = {
  id: Scalars['Int'];
  data: TournamentSeasonApplicationFormUpdateInput;
};

export type MutationDeleteTournamentSeasonApplicationFormArgs = {
  id: Scalars['Int'];
};

export type MutationCreateTournamentSeasonTeamArgs = {
  data: TournamentSeasonTeamCreateInput;
};

export type MutationUpdateTournamentSeasonTeamArgs = {
  id: Scalars['Int'];
  data: TournamentSeasonTeamUpdateInput;
};

export type MutationDeleteTournamentSeasonTeamArgs = {
  id: Scalars['Int'];
};

export type MutationCreateTournamentSeasonTeamMemberArgs = {
  data: TournamentSeasonTeamMemberCreateInput;
};

export type MutationUpdateTournamentSeasonTeamMemberArgs = {
  id: Scalars['Int'];
  data: TournamentSeasonTeamMemberUpdateInput;
};

export type MutationDeleteTournamentSeasonTeamMemberArgs = {
  id: Scalars['Int'];
};

export type MutationCreateTournamentSeasonTeamStatArgs = {
  data: TournamentSeasonTeamStatCreateInput;
};

export type MutationUpdateTournamentSeasonTeamStatArgs = {
  id: Scalars['Int'];
  data: TournamentSeasonTeamStatUpdateInput;
};

export type MutationDeleteTournamentSeasonTeamStatArgs = {
  id: Scalars['Int'];
};

export type MutationUpdateTournamentSettingArgs = {
  id: Scalars['Int'];
  data: TournamentSettingUpdateInput;
};

export type MutationCreateTournamentStadiumArgs = {
  data: TournamentStadiumCreateInput;
};

export type MutationUpdateTournamentStadiumArgs = {
  id: Scalars['Int'];
  data: TournamentStadiumUpdateInput;
};

export type MutationDeleteTournamentStadiumArgs = {
  id: Scalars['Int'];
};

export type MutationCreateTournamentTeamArgs = {
  data: TournamentTeamCreateInput;
};

export type MutationUpdateTournamentTeamArgs = {
  id: Scalars['Int'];
  data: TournamentTeamUpdateInput;
};

export type MutationDeleteTournamentTeamArgs = {
  id: Scalars['Int'];
};

export type MutationCreateTournamentTeamFieldArgs = {
  data: CreateExtensionFieldInput;
};

export type MutationUpdateTournamentTeamFieldArgs = {
  id: Scalars['Int'];
  data: UpdateExtensionFieldInput;
};

export type MutationDeleteTournamentTeamFieldArgs = {
  id: Scalars['Int'];
};

export type MutationCreateTournamentTeamGroupArgs = {
  data: TournamentTeamGroupCreateInput;
};

export type MutationUpdateTournamentTeamGroupArgs = {
  id: Scalars['Int'];
  data: TournamentTeamGroupUpdateInput;
};

export type MutationDeleteTournamentTeamGroupArgs = {
  id: Scalars['Int'];
};

export type MutationCreateTournamentTeamHistoryArgs = {
  data: TournamentTeamHistoryCreateInput;
};

export type MutationUpdateTournamentTeamHistoryArgs = {
  id: Scalars['Int'];
  data: TournamentTeamHistoryUpdateInput;
};

export type MutationDeleteTournamentTeamHistoryArgs = {
  id: Scalars['Int'];
};

export type MutationCreateTournamentTeamMemberArgs = {
  data: TournamentTeamMemberCreateInput;
};

export type MutationUpdateTournamentTeamMemberArgs = {
  id: Scalars['Int'];
  data: TournamentTeamMemberUpdateInput;
};

export type MutationDeleteTournamentTeamMemberArgs = {
  id: Scalars['Int'];
};

export type MutationCreateTournamentTeamMemberFieldArgs = {
  data: CreateExtensionFieldInput;
};

export type MutationUpdateTournamentTeamMemberFieldArgs = {
  id: Scalars['Int'];
  data: UpdateExtensionFieldInput;
};

export type MutationDeleteTournamentTeamMemberFieldArgs = {
  id: Scalars['Int'];
};

export type MutationCreateTournamentTeamMemberSocialAccountArgs = {
  data: TournamentTeamMemberSocialAccountCreateInput;
};

export type MutationUpdateTournamentTeamMemberSocialAccountArgs = {
  id: Scalars['Int'];
  data: TournamentTeamMemberSocialAccountUpdateInput;
};

export type MutationDeleteTournamentTeamMemberSocialAccountArgs = {
  id: Scalars['Int'];
};

export type MutationCreateTournamentTourArgs = {
  data: TournamentTourCreateInput;
};

export type MutationUpdateTournamentTourArgs = {
  id: Scalars['Int'];
  data: TournamentTourUpdateInput;
};

export type MutationDeleteTournamentTourArgs = {
  id: Scalars['Int'];
};

export type MutationCreateTournamentArgs = {
  data: TournamentCreateInput;
};

export type MutationUpdateTournamentArgs = {
  id: Scalars['Int'];
  data: TournamentUpdateInput;
};

export type MutationDeleteTournamentArgs = {
  id: Scalars['Int'];
};

export type MutationCreateTournamentFieldArgs = {
  data: CreateExtensionFieldInput;
};

export type MutationUpdateTournamentFieldArgs = {
  id: Scalars['Int'];
  data: UpdateExtensionFieldInput;
};

export type MutationDeleteTournamentFieldArgs = {
  id: Scalars['Int'];
};

export type MutationCreateTournamentTranslationArgs = {
  data: TournamentTranslationCreateInput;
};

export type MutationUpdateTournamentTranslationArgs = {
  id: Scalars['Int'];
  data: TournamentTranslationUpdateInput;
};

export type MutationDeleteTournamentTranslationArgs = {
  id: Scalars['Int'];
};

export type MutationImportTournamentMatchDataArgs = {
  type: TournamentMatchDataType;
};

export type MutationSyncTournamentSeasonStatsArgs = {
  seasonId: Scalars['Int'];
};

export type MutationImportTournamentSeasonTeamStatsArgs = {
  data: Array<TournamentSeasonTeamStatCreateInput>;
};

export type MutationSyncTournamentSeasonTeamsArgs = {
  data: Array<TournamentSeasonTeamCreateInput>;
};

export type MutationImportTournamentPlayerStatsArgs = {
  data: Array<TournamentPlayerStatCreateInput>;
};

export type MutationImportTournamentMatchLineupsArgs = {
  matchId: Scalars['Int'];
  data: Array<TournamentMatchLineUpCreateInput>;
};

export type MutationImportTournamentMatchEventsArgs = {
  matchId: Scalars['Int'];
  data: Array<TournamentMatchEventCreateInput>;
  notificate?: Maybe<Scalars['Boolean']>;
};

export type MutationImportTournamentMatchStatsArgs = {
  matchId: Scalars['Int'];
  data: Array<TournamentMatchStatCreateInput>;
};

export type MutationSyncTournamentTourArgs = {
  seasonId: Scalars['Int'];
};

export type MutationCreateUserApplicationLogCommentArgs = {
  applicationId: Scalars['Int'];
  comment: Scalars['String'];
};

export type MutationCreateUserAnalyticDataArgs = {
  data: UserAnalyticDataCreateInput;
};

export type MutationUpdateUserAnalyticDataArgs = {
  id: Scalars['Int'];
  data: UserAnalyticDataUpdateInput;
};

export type MutationCreateUserAnalyticFieldArgs = {
  data: UserAnalyticFieldCreateInput;
};

export type MutationUpdateUserAnalyticFieldArgs = {
  id: Scalars['Int'];
  data: UserAnalyticFieldUpdateInput;
};

export type MutationDeleteUserAnalyticFieldArgs = {
  id: Scalars['Int'];
};

export type MutationCreateUserApplicationArgs = {
  data: UserApplicationCreateInput;
};

export type MutationUpdateUserApplicationArgs = {
  id: Scalars['Int'];
  data: UserApplicationUpdateInput;
};

export type MutationDeleteUserApplicationArgs = {
  id: Scalars['Int'];
};

export type MutationCreateUserApplicationFieldArgs = {
  data: CreateExtensionFieldInput;
};

export type MutationUpdateUserApplicationFieldArgs = {
  id: Scalars['Int'];
  data: UpdateExtensionFieldInput;
};

export type MutationDeleteUserApplicationFieldArgs = {
  id: Scalars['Int'];
};

export type MutationCreateUserApplicationTypeArgs = {
  data: UserApplicationTypeCreateInput;
};

export type MutationUpdateUserApplicationTypeArgs = {
  id: Scalars['Int'];
  data: UserApplicationTypeUpdateInput;
};

export type MutationDeleteUserApplicationTypeArgs = {
  id: Scalars['Int'];
};

export type MutationCreateUserDocumentArgs = {
  data: UserDocumentCreateInput;
};

export type MutationUpdateUserDocumentArgs = {
  id: Scalars['Int'];
  data: UserDocumentUpdateInput;
};

export type MutationDeleteUserDocumentArgs = {
  id: Scalars['Int'];
};

export type MutationCreateUserGroupArgs = {
  data: UserGroupCreateInput;
};

export type MutationUpdateUserGroupArgs = {
  id: Scalars['Int'];
  data: UserGroupUpdateInput;
};

export type MutationDeleteUserGroupArgs = {
  id: Scalars['Int'];
};

export type MutationCreateUserKidArgs = {
  data: UserKidCreateInput;
};

export type MutationUpdateUserKidArgs = {
  id: Scalars['Int'];
  data: UserKidUpdateInput;
};

export type MutationDeleteUserKidArgs = {
  id: Scalars['Int'];
};

export type MutationCreateUserSocialAccountArgs = {
  data: UserSocialAccountCreateInput;
};

export type MutationUpdateUserSocialAccountArgs = {
  id: Scalars['Int'];
  data: UserSocialAccountUpdateInput;
};

export type MutationDeleteUserSocialAccountArgs = {
  id: Scalars['Int'];
};

export type MutationCreateUserSubscriberArgs = {
  data: UserSubscriberCreateInput;
};

export type MutationUpdateUserSubscriberArgs = {
  id: Scalars['String'];
  data: UserSubscriberUpdateInput;
};

export type MutationDeleteUserSubscriberArgs = {
  id: Scalars['String'];
};

export type MutationCreateUserSubscribersLogArgs = {
  data: UserSubscribersLogCreateInput;
};

export type MutationUpdateUserSubscribersLogArgs = {
  id: Scalars['Int'];
  data: UserSubscribersLogUpdateInput;
};

export type MutationDeleteUserSubscribersLogArgs = {
  id: Scalars['Int'];
};

export type MutationCreateUserSubscriptionArgs = {
  data: UserSubscriptionCreateInput;
};

export type MutationUpdateUserSubscriptionArgs = {
  id: Scalars['Int'];
  data: UserSubscriptionUpdateInput;
};

export type MutationDeleteUserSubscriptionArgs = {
  id: Scalars['Int'];
};

export type MutationCreateUserFieldArgs = {
  data: CreateExtensionFieldInput;
};

export type MutationUpdateUserFieldArgs = {
  id: Scalars['Int'];
  data: UpdateExtensionFieldInput;
};

export type MutationDeleteUserFieldArgs = {
  id: Scalars['Int'];
};

export type MutationSubscribeToMatchEventsArgs = {
  data: SubscribeToMatchEventsInput;
};

export type MutationValidatePhoneArgs = {
  phone: Scalars['String'];
  code?: Maybe<Scalars['String']>;
};

export type MutationSendPinArgs = {
  phone: Scalars['String'];
  deviceId?: Maybe<Scalars['String']>;
};

export type MutationSendPinByCallArgs = {
  phone: Scalars['String'];
  deviceId?: Maybe<Scalars['String']>;
};

export type MutationValidatePinArgs = {
  phone: Scalars['String'];
  pin: Scalars['String'];
  deviceId: Scalars['ID'];
  expoPushToken?: Maybe<Scalars['String']>;
  coords?: Maybe<CoordsInput>;
};

export type MutationSaveUserSettingsArgs = {
  data: SaveUserSettingsInput;
};

export type MutationAddToUsersSubscriptionArgs = {
  subscriptionId: Scalars['Int'];
  query?: Maybe<DefaultQueryInput>;
};

export type MutationDeleteFromUsersSubscriptionArgs = {
  subscriptionId: Scalars['Int'];
  userId?: Maybe<Array<Scalars['Int']>>;
};

export type MutationCreateUserSubscribersArgs = {
  subscriptionId: Scalars['Int'];
  userId: Array<Scalars['Int']>;
  modifierId?: Maybe<Scalars['Int']>;
};

export type MutationDeleteUserSubscribersArgs = {
  id: Array<Scalars['String']>;
};

export type MutationImportUserSubscribersArgs = {
  subscriptionId: Scalars['Int'];
  file: Scalars['Upload'];
};

export type MutationUnsubscribeUserArgs = {
  userId: Scalars['Int'];
};

export type MutationUnsubscribeUserByEmailArgs = {
  email: Scalars['String'];
};

export type MutationUnsubscribeUserByEmailsArgs = {
  emails: Array<Scalars['String']>;
};

export type MutationAddUsersToGroupArgs = {
  groupId: Array<Scalars['Int']>;
  query: DefaultQueryInput;
};

export type MutationAddUserToGroupArgs = {
  userId: Scalars['Int'];
  groupId: Array<Scalars['Int']>;
};

export type MutationDeleteUserFromGroupArgs = {
  groupId: Array<Scalars['Int']>;
  query: DefaultQueryInput;
};

export type MutationCreateUserArgs = {
  data?: Maybe<UserCreateInput>;
};

export type MutationUpdateUserArgs = {
  id: Scalars['Int'];
  data: UserUpdateInput;
};

export type MutationRegisterUserArgs = {
  data: UserCreateInput;
};

export type MutationRegisterUserSocialArgs = {
  type: UserSocialAccountSocialNetwork;
  data: SocialAuthData;
};

export type MutationLoginUserArgs = {
  username: Scalars['String'];
  password: Scalars['String'];
};

export type MutationMasterLoginUserArgs = {
  username: Scalars['String'];
  password: Scalars['String'];
};

export type MutationLoginUserSocialArgs = {
  type: UserSocialAccountSocialNetwork;
  data: SocialAuthData;
};

export type MutationUpdateUserAccessTokenArgs = {
  refreshToken: Scalars['String'];
};

export type MutationUpdateUserRefreshTokenArgs = {
  refreshToken: Scalars['String'];
};

export type MutationResetUserPasswordArgs = {
  email?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  confirmationKey?: Maybe<Scalars['String']>;
};

export type MutationConfirmUserEmailArgs = {
  email: Scalars['String'];
  confirmationKey?: Maybe<Scalars['String']>;
};

export type MutationDeleteUserByIdArgs = {
  id: Scalars['Int'];
};

export type MutationAddUserToCategoryArgs = {
  userId?: Maybe<Scalars['Int']>;
  categoryId?: Maybe<Scalars['Int']>;
};

export type MutationRemoveUserToCategoryArgs = {
  userId?: Maybe<Scalars['Int']>;
  categoryId?: Maybe<Scalars['Int']>;
};

export type MutationAddUserDeviceArgs = {
  data: AddUserDeviceInput;
};

export type MutationCreateUserDeviceArgs = {
  data: CreateUserDeviceInput;
};

export type MutationAddUserLocationArgs = {
  data: UserLocationInput;
};

export type MutationCreateUserLocationArgs = {
  data: CreateUserLocationInput;
};

export type MutationImportUserOrdersAndTicketsArgs = {
  id: Scalars['Int'];
};

export type MutationImportUsersArgs = {
  data: ImportInput;
};

export type MutationCreateUserDataFieldArgs = {
  data: UserDataFieldCreateInput;
};

export type MutationUpdateUserDataFieldArgs = {
  id: Scalars['Int'];
  data: UserDataFieldUpdateInput;
};

export type MutationDeleteUserDataFieldArgs = {
  id: Scalars['Int'];
};

export type MutationCreateUserAnalyticDataBatchArgs = {
  data: Array<UserAnalyticDataCreateInput>;
};

export type MutationUpdateMySubscriptionsArgs = {
  subscriptionId: Array<Scalars['Int']>;
};

export type MutationUpdateUserSubscriptionsArgs = {
  userId: Scalars['Int'];
  subscriptionId: Array<Scalars['Int']>;
};

export type MutationUploadFileToImportUsersArgs = {
  file: Scalars['Upload'];
};

export type MutationExportUsersArgs = {
  query?: Maybe<DefaultQueryInput>;
  settings?: Maybe<ExportSettingsInput>;
};

export type MutationUpdateCommerceOrdersArgs = {
  idx: Array<Scalars['Int']>;
  data: CommerceOrderUpdateInput;
};

export type MutationCreateMyEventOrderArgs = {
  eventId: Scalars['Int'];
  payload: EventOrderPayloadInput;
};

export type MutationCancelMyEventOrderArgs = {
  orderId: Scalars['Int'];
};

export type MutationCreateMyEventReserveArgs = {
  eventId: Scalars['Int'];
  sectorId: Scalars['Int'];
  seatId?: Maybe<Scalars['Int']>;
  priceCategoryId?: Maybe<Scalars['String']>;
  fanCode?: Maybe<Scalars['String']>;
  fanGUID?: Maybe<Scalars['String']>;
};

export type MutationDeleteMyEventReserveArgs = {
  eventId: Scalars['Int'];
  sectorId: Scalars['Int'];
  seatId?: Maybe<Scalars['Int']>;
};

export type MutationSyncUserEventOrdersArgs = {
  userId: Scalars['Int'];
};

export type MutationCheckMatchAccessArgs = {
  data?: Maybe<CheckMatchAccessPayload>;
};

export type MutationCheckSeasonTicketArgs = {
  data: CheckSeasonTicketPayload;
};

export type MutationSetTicketFanIdArgs = {
  fanCode: Scalars['String'];
  fanGUID: Scalars['String'];
  orderId: Scalars['Int'];
  barcode?: Maybe<Scalars['String']>;
  ticketId?: Maybe<Scalars['Int']>;
};

export type MutationCreateFoodOrderArgs = {
  data: FoodOrderCreateInput;
};

export type MutationCancelFoodOrderArgs = {
  id: Scalars['Int'];
};

export type MutationCreateLoyaltyOrderArgs = {
  data: LoyaltyOrderCreateInput;
};

export type MutationCancelLoyaltyOrderArgs = {
  id: Scalars['Int'];
};

export type MutationCreateStadiumMessageArgs = {
  message: Scalars['String'];
  image?: Maybe<Scalars['String']>;
};

export type MutationInitPaymentGatewayArgs = {
  data: InitPaymentGatewayInput;
};

export type MutationValidateOrderPaymentArgs = {
  data: CheckOrderPaymentInput;
};

export type MutationSaveWebTranslationsArgs = {
  data?: Maybe<Array<TranslationSaveInput>>;
};

export type MutationSaveUserSourceSettingsArgs = {
  sources: Array<ArticleSocials>;
};

export type MutationCreateApplicationKidArgs = {
  data: UserApplicationKidCreateInput;
};

export type MutationCreateApplicationFamilySectorArgs = {
  data: UserApplicationFamilySectorCreateInput;
};

export type MutationUpdateApplicationFamilySectorArgs = {
  id: Scalars['Int'];
  data: UserApplicationFamilySectorUpdateInput;
};

export type MutationCancelApplicationFamilySectorArgs = {
  id: Scalars['Int'];
};

export type MutationCreateApplicationLessonArgs = {
  data: UserApplicationEventCreateInput;
};

export type MutationCreateApplicationEventArgs = {
  data: UserApplicationEventCreateInput;
};

export type MutationCreateApplicationQuestionArgs = {
  data: UserApplicationEventCreateInput;
};

export type MutationCreateApplicationDsaMessageArgs = {
  data: UserApplicationEventCreateInput;
};

export type MutationCreateApplicationFeedbackDeveloperArgs = {
  data: UserApplicationFeedbackDeveloperCreateInput;
};

export type MutationCreateApplicationScreenStadiumArgs = {
  data: UserApplicationScreenStadiumCreateInput;
};

/** Пункт меню в навигации по ERP-системе. */
export type Navigation = {
  __typename?: 'Navigation';
  id: Scalars['Int'];
  /** Идентифкатор родителя. 0 — корневой пункт меню. */
  parentId: Scalars['Int'];
  /** Для того, чтобы меню было i18n тут передается имя для дальнейшего перевода в ERP. Например, users */
  name: Scalars['String'];
  /** Относительный url в меню. */
  url: Scalars['String'];
  /** Иконка пункта меню. */
  icon: Scalars['String'];
  /** Порядок в списке. */
  sort: Scalars['Int'];
  /** Дочерние пункты меню */
  children?: Maybe<Array<Navigation>>;
};

export type NewUsersWidget = {
  __typename?: 'NewUsersWidget';
  total: Scalars['Int'];
  graphs: Array<WidgetSummaryGraph>;
};

export type Notification = {
  __typename?: 'Notification';
  id: Scalars['Int'];
  title: Scalars['String'];
  body?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  iconUrl?: Maybe<Scalars['String']>;
  /** Альтернативный лого */
  altIconUrl?: Maybe<Scalars['String']>;
};

export type NotificationConnection = DefaultConnection & {
  __typename?: 'NotificationConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<Notification>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

/** Транспорт уведомлений */
export enum NotificationTransportEnum {
  EMAIL = 'EMAIL',
  SMS = 'SMS',
  PUSH = 'PUSH',
}

export type OrderDataInput = {
  key: Scalars['String'];
  value: Scalars['String'];
};

export type OrderItemInput = {
  catalogueItemId: Scalars['Int'];
  discountId?: Maybe<Scalars['Int']>;
  currencyId?: Maybe<Scalars['Int']>;
  qty: Scalars['Int'];
  price?: Maybe<Scalars['Float']>;
};

export enum OrderStateEnum {
  NEW = 'NEW',
  PAID = 'PAID',
  ARCHIEVE = 'ARCHIEVE',
}

export type PayOrderInput = {
  orderId: Scalars['Int'];
  orderType: PaymentTransactionOrderType;
};

export type PaymentAccount = {
  __typename?: 'PaymentAccount';
  id: Scalars['Int'];
  state: PaymentAccountState;
  userId: Scalars['Int'];
  currencyId: Scalars['Int'];
  balance: Scalars['Float'];
  spent: Scalars['Float'];
  providerId?: Maybe<Scalars['Int']>;
  providerCode?: Maybe<Scalars['String']>;
  user: User;
  currency: PaymentCurrency;
  provider?: Maybe<PaymentProvider>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type PaymentAccountBalance = {
  __typename?: 'PaymentAccountBalance';
  provider?: Maybe<PaymentProvider>;
  currency: Currency;
  balance: Scalars['Int'];
  providerId: Scalars['Int'];
  currencyId: Scalars['Int'];
};

export type PaymentAccountConnection = DefaultConnection & {
  __typename?: 'PaymentAccountConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<PaymentAccount>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type PaymentAccountCreateInput = {
  state?: Maybe<PaymentAccountState>;
  userId: Scalars['Int'];
  currencyId: Scalars['Int'];
  balance?: Maybe<Scalars['Float']>;
  spent?: Maybe<Scalars['Float']>;
  providerId?: Maybe<Scalars['Int']>;
  providerCode?: Maybe<Scalars['String']>;
};

export type PaymentAccountOperationInput = {
  accountId: Scalars['Int'];
  amount: Scalars['Int'];
  /** Добавляемый комментарий к платежной транзакции */
  comment?: Maybe<Scalars['String']>;
};

export enum PaymentAccountState {
  INACTIVE = 'INACTIVE',
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
}

export type PaymentAccountUpdateInput = {
  state?: Maybe<PaymentAccountState>;
  userId?: Maybe<Scalars['Int']>;
  currencyId?: Maybe<Scalars['Int']>;
  balance?: Maybe<Scalars['Float']>;
  spent?: Maybe<Scalars['Float']>;
  providerId?: Maybe<Scalars['Int']>;
  providerCode?: Maybe<Scalars['String']>;
};

export type PaymentAccountWhereInput = {
  currencyId: Scalars['Int'];
  providerId: Scalars['Int'];
  providerCode: Scalars['String'];
};

export type PaymentBankCard = {
  __typename?: 'PaymentBankCard';
  id: Scalars['Int'];
  state: PaymentBankCardState;
  userId: Scalars['Int'];
  number: Scalars['String'];
  issuedBy?: Maybe<Scalars['String']>;
  expiredAt?: Maybe<Scalars['String']>;
  cardHolderName?: Maybe<Scalars['String']>;
  token: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type PaymentBankCardConnection = DefaultConnection & {
  __typename?: 'PaymentBankCardConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<PaymentBankCard>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type PaymentBankCardCreateInput = {
  state?: Maybe<PaymentBankCardState>;
  userId: Scalars['Int'];
  number: Scalars['String'];
  issuedBy?: Maybe<Scalars['String']>;
  expiredAt?: Maybe<Scalars['String']>;
  cardHolderName?: Maybe<Scalars['String']>;
  token: Scalars['String'];
};

export enum PaymentBankCardState {
  INACTIVE = 'INACTIVE',
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
}

export type PaymentBankCardUpdateInput = {
  state?: Maybe<PaymentBankCardState>;
  userId?: Maybe<Scalars['Int']>;
  number?: Maybe<Scalars['String']>;
  issuedBy?: Maybe<Scalars['String']>;
  expiredAt?: Maybe<Scalars['String']>;
  cardHolderName?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
};

/** Тип предмета расчета. */
export enum PaymentBasis {
  /** Неизвестный. */
  UNKNOWN = 'UNKNOWN',
  /** Товар. */
  COMMODITY = 'COMMODITY',
  /** Работа. */
  JOB = 'JOB',
  /** Услуга. */
  SERVICE = 'SERVICE',
  /** Платеж. */
  PAYMENT = 'PAYMENT',
  /** Составной предмет расчета. */
  COMPOSITE = 'COMPOSITE',
  /** Иной предмет расчета. */
  ANOTHER = 'ANOTHER',
}

export type PaymentChannel = {
  __typename?: 'PaymentChannel';
  id: Scalars['Int'];
  clientId: Scalars['Int'];
  state: PaymentChannelState;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type PaymentChannelConnection = DefaultConnection & {
  __typename?: 'PaymentChannelConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<PaymentChannel>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type PaymentChannelCreateInput = {
  state?: Maybe<PaymentChannelState>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
};

export enum PaymentChannelState {
  INACTIVE = 'INACTIVE',
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
}

export type PaymentChannelSummaryWidget = {
  __typename?: 'PaymentChannelSummaryWidget';
  channel: PaymentChannel;
  graphs: Array<WidgetSummaryGraph>;
  /** Сумма успешных операций (списаний) */
  sum: Scalars['Float'];
};

export type PaymentChannelUpdateInput = {
  state?: Maybe<PaymentChannelState>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type PaymentCurrency = {
  __typename?: 'PaymentCurrency';
  id: Scalars['Int'];
  clientId: Scalars['Int'];
  iso3: Scalars['String'];
  name: Scalars['String'];
  sign: Scalars['String'];
  isDefault: Scalars['Boolean'];
  isVirtual: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type PaymentCurrencyConnection = DefaultConnection & {
  __typename?: 'PaymentCurrencyConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<PaymentCurrency>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type PaymentCurrencyCreateInput = {
  iso3: Scalars['String'];
  name: Scalars['String'];
  sign: Scalars['String'];
  isDefault: Scalars['Boolean'];
  isVirtual: Scalars['Boolean'];
};

export type PaymentCurrencySummaryWidget = {
  __typename?: 'PaymentCurrencySummaryWidget';
  currency: PaymentCurrency;
  graphs: Array<WidgetSummaryGraph>;
  /** Сумма успешных операций (списаний) */
  sum: Scalars['Float'];
};

export type PaymentCurrencyUpdateInput = {
  iso3?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  sign?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isVirtual?: Maybe<Scalars['Boolean']>;
};

export type PaymentDataPsb = {
  __typename?: 'PaymentDataPSB';
  amount?: Maybe<Scalars['Int']>;
  currency?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['String']>;
  desc?: Maybe<Scalars['String']>;
  terminal?: Maybe<Scalars['String']>;
  trtype?: Maybe<Scalars['Int']>;
  merch_name?: Maybe<Scalars['String']>;
  merchant?: Maybe<Scalars['BigInt']>;
  email?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['BigInt']>;
  nonce?: Maybe<Scalars['String']>;
  backref?: Maybe<Scalars['String']>;
  notify_url?: Maybe<Scalars['String']>;
  p_sign?: Maybe<Scalars['String']>;
};

export type PaymentGateway = {
  __typename?: 'PaymentGateway';
  id: Scalars['Int'];
  state: PaymentGatewayState;
  /** Linking to client. If no null then it is client-specific */
  clientId?: Maybe<Scalars['Int']>;
  /** Gateway name. CloudPayments, BranTree etc */
  name: Scalars['String'];
  /** Gateway description. Optional */
  description?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type PaymentGatewayConnection = DefaultConnection & {
  __typename?: 'PaymentGatewayConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<PaymentGateway>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type PaymentGatewayCreateInput = {
  state?: Maybe<PaymentGatewayState>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
};

export type PaymentGatewayProvider = {
  __typename?: 'PaymentGatewayProvider';
  id: Scalars['Int'];
  name: Scalars['String'];
};

export enum PaymentGatewayState {
  INACTIVE = 'INACTIVE',
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
}

export type PaymentGatewaySummaryWidget = {
  __typename?: 'PaymentGatewaySummaryWidget';
  gateway: PaymentGateway;
  graphs: Array<WidgetSummaryGraph>;
  /** Сумма успешных операций (списаний) */
  sum: Scalars['Float'];
};

export type PaymentGatewayUpdateInput = {
  state?: Maybe<PaymentGatewayState>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type PaymentInAppPurchase = {
  __typename?: 'PaymentInAppPurchase';
  id: Scalars['Int'];
  clientId: Scalars['Int'];
  productId: Scalars['String'];
  store: PaymentInAppPurchaseStore;
  title: Scalars['String'];
  currencyId?: Maybe<Scalars['Int']>;
  amount?: Maybe<Scalars['Float']>;
  currency: PaymentCurrency;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type PaymentInAppPurchaseConnection = DefaultConnection & {
  __typename?: 'PaymentInAppPurchaseConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<PaymentInAppPurchase>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export enum PaymentInAppPurchaseStore {
  APP_STORE = 'APP_STORE',
  GOOGLE_PLAY = 'GOOGLE_PLAY',
}

export type PaymentInAppTransaction = {
  __typename?: 'PaymentInAppTransaction';
  id: Scalars['Int'];
  state: PaymentInAppTransactionState;
  side: PaymentInAppTransactionSide;
  userId: Scalars['Int'];
  currencyId?: Maybe<Scalars['Int']>;
  paymentTransactionId?: Maybe<Scalars['String']>;
  store: PaymentInAppTransactionStore;
  amount?: Maybe<Scalars['Float']>;
  comment?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  response?: Maybe<Scalars['JSON']>;
  user: User;
  currency: PaymentCurrency;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type PaymentInAppTransactionConnection = DefaultConnection & {
  __typename?: 'PaymentInAppTransactionConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<PaymentInAppTransaction>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export enum PaymentInAppTransactionSide {
  CHARGE = 'CHARGE',
  DEPOSIT = 'DEPOSIT',
}

export enum PaymentInAppTransactionState {
  AUTH = 'AUTH',
  HOLD = 'HOLD',
  PROCESSED = 'PROCESSED',
  FAILED = 'FAILED',
  CANCELLED = 'CANCELLED',
}

export enum PaymentInAppTransactionStore {
  APP_STORE = 'APP_STORE',
  GOOGLE_PLAY = 'GOOGLE_PLAY',
}

export type PaymentInfo = {
  __typename?: 'PaymentInfo';
  success: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
};

/** Тип метода расчета. */
export enum PaymentMethod {
  /** Неизвестный. */
  UNKNOWN = 'UNKNOWN',
  /** Полная предоплата. */
  FULL_PREPAYMENT = 'FULL_PREPAYMENT',
  /** Частичная предоплата. */
  PARTIAL_PREPAYMENT = 'PARTIAL_PREPAYMENT',
  /** Аванс. */
  ADVANCE = 'ADVANCE',
  /** Полный расчет. */
  FULL = 'FULL',
  /** Полный расчет и кредит. */
  PARTIAL_PAY_AND_CREDIT = 'PARTIAL_PAY_AND_CREDIT',
  /** Кредит. */
  CREDIT = 'CREDIT',
  /** Оплата кредита. */
  CREDIT_PAYMENT = 'CREDIT_PAYMENT',
}

export type PaymentOrderResult = {
  __typename?: 'PaymentOrderResult';
  eventOrder?: Maybe<EventOrder>;
  commerceOrder?: Maybe<CommerceOrder>;
  orderId: Scalars['Int'];
  success: Scalars['Boolean'];
};

export type PaymentProvider = {
  __typename?: 'PaymentProvider';
  id: Scalars['Int'];
  gatewayId: Scalars['Int'];
  clientId: Scalars['Int'];
  state: PaymentProviderState;
  name: Scalars['String'];
  settings?: Maybe<Scalars['JSON']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type PaymentProviderConnection = DefaultConnection & {
  __typename?: 'PaymentProviderConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<PaymentProvider>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type PaymentProviderCreateInput = {
  gatewayId: Scalars['Int'];
  state?: Maybe<PaymentProviderState>;
  name: Scalars['String'];
  settings?: Maybe<Scalars['JSON']>;
};

export enum PaymentProviderEnum {
  CLOUDPAYMENTS = 'CLOUDPAYMENTS',
  PSB = 'PSB',
}

export enum PaymentProviderState {
  INACTIVE = 'INACTIVE',
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
}

export type PaymentProviderSummaryWidget = {
  __typename?: 'PaymentProviderSummaryWidget';
  provider: PaymentProvider;
  graphs: Array<WidgetSummaryGraph>;
  /** Сумма успешных операций (списаний) */
  sum: Scalars['Float'];
};

export type PaymentProviderUpdateInput = {
  gatewayId?: Maybe<Scalars['Int']>;
  state?: Maybe<PaymentProviderState>;
  name?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['JSON']>;
};

export type PaymentReceiptInput = {
  type: PaymentReceiptType;
  phone?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  inn?: Maybe<Scalars['String']>;
  taxationSystem?: Maybe<TaxationSystemType>;
  items?: Maybe<Array<PaymentReceiptItemInput>>;
};

export type PaymentReceiptItem = {
  __typename?: 'PaymentReceiptItem';
  taxRate: TaxRateType;
  label: Scalars['String'];
  quantity: Scalars['Int'];
  price: Scalars['Float'];
  totalAmount: Scalars['Float'];
  measureUnit: Scalars['String'];
  paymentMethod: PaymentMethod;
  paymentBasis: PaymentBasis;
};

export type PaymentReceiptItemInput = {
  taxRate: TaxRateType;
  label: Scalars['String'];
  quantity: Scalars['Int'];
  price: Scalars['Float'];
  totalAmount: Scalars['Float'];
  measureUnit: Scalars['String'];
  paymentMethod: PaymentMethod;
  paymentBasis: PaymentBasis;
};

export type PaymentReceiptResponse = {
  __typename?: 'PaymentReceiptResponse';
  type: PaymentReceiptType;
  phone?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  inn?: Maybe<Scalars['String']>;
  taxationSystem?: Maybe<TaxationSystemType>;
  items?: Maybe<Array<PaymentReceiptItem>>;
};

/** Тип кассового чека. */
export enum PaymentReceiptType {
  /** Приход. */
  INCOME = 'INCOME',
  /** Возврат прихода. */
  INCOME_RETURN = 'INCOME_RETURN',
  /** Расход. */
  EXPENSE = 'EXPENSE',
  /** Возврат расхода. */
  EXPENSE_RETURN = 'EXPENSE_RETURN',
}

export type PaymentResponse = PaymentTransaction | Secure3DResponse;

export type PaymentResult = {
  __typename?: 'PaymentResult';
  amount: Scalars['Float'];
};

export type PaymentTransaction = {
  __typename?: 'PaymentTransaction';
  id: Scalars['Int'];
  state: PaymentTransactionState;
  side: PaymentTransactionSide;
  channelId?: Maybe<Scalars['Int']>;
  userId: Scalars['Int'];
  accountId: Scalars['Int'];
  currencyId: Scalars['Int'];
  providerId?: Maybe<Scalars['Int']>;
  amount: Scalars['Float'];
  bankCardId?: Maybe<Scalars['Int']>;
  paymentTransactionId?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['String']>;
  orderType?: Maybe<PaymentTransactionOrderType>;
  comment?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  response?: Maybe<Scalars['JSON']>;
  provider?: Maybe<PaymentProvider>;
  user: User;
  currency: PaymentCurrency;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type PaymentTransactionConnection = DefaultConnection & {
  __typename?: 'PaymentTransactionConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<PaymentTransaction>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type PaymentTransactionCreateInput = {
  state: PaymentTransactionState;
  side: PaymentTransactionSide;
  channelId?: Maybe<Scalars['Int']>;
  userId: Scalars['Int'];
  accountId: Scalars['Int'];
  currencyId: Scalars['Int'];
  providerId?: Maybe<Scalars['Int']>;
  amount: Scalars['Float'];
  bankCardId?: Maybe<Scalars['Int']>;
  paymentTransactionId?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['String']>;
  orderType?: Maybe<PaymentTransactionOrderType>;
  comment?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  response?: Maybe<Scalars['JSON']>;
};

export type PaymentTransactionLog = {
  __typename?: 'PaymentTransactionLog';
  id: Scalars['Int'];
  transactionId: Scalars['Int'];
  systemUserId: Scalars['Int'];
  operation: PaymentTransactionLogOperation;
  isSuccess: Scalars['Boolean'];
  amount: Scalars['Float'];
  error?: Maybe<Scalars['JSON']>;
  response?: Maybe<Scalars['JSON']>;
  meta?: Maybe<Scalars['JSON']>;
  paymentCheck?: Maybe<Scalars['JSON']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type PaymentTransactionLogConnection = DefaultConnection & {
  __typename?: 'PaymentTransactionLogConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<PaymentTransactionLog>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type PaymentTransactionLogCreateInput = {
  transactionId: Scalars['Int'];
  systemUserId: Scalars['Int'];
  operation: PaymentTransactionLogOperation;
  isSuccess: Scalars['Boolean'];
  amount: Scalars['Float'];
  error?: Maybe<Scalars['JSON']>;
  response?: Maybe<Scalars['JSON']>;
  meta?: Maybe<Scalars['JSON']>;
  paymentCheck?: Maybe<Scalars['JSON']>;
};

export enum PaymentTransactionLogOperation {
  AUTH = 'AUTH',
  CONFIRM_AUTH = 'CONFIRM_AUTH',
  HOLD = 'HOLD',
  CONFIRM_HOLD = 'CONFIRM_HOLD',
  CHARGE = 'CHARGE',
  DEPOSIT = 'DEPOSIT',
  REFUND = 'REFUND',
  CANCEL = 'CANCEL',
}

export type PaymentTransactionLogUpdateInput = {
  transactionId?: Maybe<Scalars['Int']>;
  systemUserId?: Maybe<Scalars['Int']>;
  operation?: Maybe<PaymentTransactionLogOperation>;
  isSuccess?: Maybe<Scalars['Boolean']>;
  amount?: Maybe<Scalars['Float']>;
  error?: Maybe<Scalars['JSON']>;
  response?: Maybe<Scalars['JSON']>;
  meta?: Maybe<Scalars['JSON']>;
  paymentCheck?: Maybe<Scalars['JSON']>;
};

export enum PaymentTransactionOrderType {
  ECOMMERCE = 'ECOMMERCE',
  TICKET = 'TICKET',
}

export enum PaymentTransactionSide {
  CHARGE = 'CHARGE',
  DEPOSIT = 'DEPOSIT',
}

export enum PaymentTransactionState {
  AUTH = 'AUTH',
  HOLD = 'HOLD',
  PROCESSED = 'PROCESSED',
  FAILED = 'FAILED',
  CANCELLED = 'CANCELLED',
}

export type PaymentTransactionUpdateInput = {
  state?: Maybe<PaymentTransactionState>;
  side?: Maybe<PaymentTransactionSide>;
  channelId?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['Int']>;
  accountId?: Maybe<Scalars['Int']>;
  currencyId?: Maybe<Scalars['Int']>;
  providerId?: Maybe<Scalars['Int']>;
  amount?: Maybe<Scalars['Float']>;
  bankCardId?: Maybe<Scalars['Int']>;
  paymentTransactionId?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['String']>;
  orderType?: Maybe<PaymentTransactionOrderType>;
  comment?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  response?: Maybe<Scalars['JSON']>;
};

export type PaymentWebHookInput = {
  providerId: Scalars['Int'];
  /** Сериализованный объект с данными */
  data: Scalars['String'];
};

export type PaymentWebHookResponse = {
  __typename?: 'PaymentWebHookResponse';
  status: Scalars['Boolean'];
  data?: Maybe<Scalars['JSON']>;
};

export type Printer = {
  __typename?: 'Printer';
  id: Scalars['Int'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  isDefault: Scalars['Boolean'];
};

/** Данные проверки 3DSecure */
export type Process3DSecureInput = {
  providerId: Scalars['Int'];
  currencyId: Scalars['Int'];
  providerTransactionId: Scalars['String'];
  payload: Scalars['String'];
};

export type ProviderResponse = {
  __typename?: 'ProviderResponse';
  amount: Scalars['Int'];
};

export type Query = {
  __typename?: 'Query';
  apiVersion?: Maybe<Scalars['String']>;
  filters?: Maybe<Array<FilterItem>>;
  widgets?: Maybe<WidgetsResponse>;
  /** Получение списка статей с отбором, сортировкой и пагинацией. */
  allArticles: ArticlesConnection;
  /** Получение статьи по ID. */
  article?: Maybe<Article>;
  /** Получение списка авторов блоков статей с отбором, сортировкой и пагинацией. */
  allArticleBlockAuthors?: Maybe<ArticleBlockAuthorsConnection>;
  /**
   * Получение списка сущностей по типу ArticleLinkType
   * через виртуальное поле v_entityType: "TEAM" | "TOUR", "MATCH", "EVENT", "PLAYER"
   *
   * В edges вернеться один из типов ArticleLinkEntityRecord
   * в зависимости от переданного поля
   */
  allEntityRecordsByArticleLinkType: ArticleLinkEntityTypeConnection;
  allArticleArticleTags: ArticleArticleTagConnection;
  articleArticleTags: Array<ArticleArticleTag>;
  articleArticleTag: ArticleArticleTag;
  allArticleAuthors: ArticleAuthorConnection;
  articleAuthors: Array<ArticleAuthor>;
  articleAuthor: ArticleAuthor;
  allArticleBlocks: ArticleBlockConnection;
  articleBlocks: Array<ArticleBlock>;
  articleBlock: ArticleBlock;
  allArticleCategories: ArticleCategoryConnection;
  articleCategories: Array<ArticleCategory>;
  articleCategory: ArticleCategory;
  allArticleLinks: ArticleLinkConnection;
  articleLinks: Array<ArticleLink>;
  articleLink: ArticleLink;
  articleLogs: Array<ArticleLog>;
  allArticleTags: ArticleTagConnection;
  articleTags: Array<ArticleTag>;
  articleTag: ArticleTag;
  /**
   * Получение товаров каталога
   * по идентификатору события
   */
  catalogueItemsByEvent: Array<CatalogueItem>;
  allCatalogues: CatalogueConnection;
  catalogues: Array<Catalogue>;
  catalogue: Catalogue;
  allCatalogueFields: CatalogueFieldsConnection;
  allCatalogueCategories: CatalogueCategoryConnection;
  catalogueCategories: Array<CatalogueCategory>;
  catalogueCategory: CatalogueCategory;
  allCatalogueCategoryFields: CatalogueCategoryFieldsConnection;
  allCatalogueItems: CatalogueItemConnection;
  catalogueItems: Array<CatalogueItem>;
  catalogueItem: CatalogueItem;
  allCatalogueItemFields: CatalogueItemFieldsConnection;
  allCatalogueItemPrices: CatalogueItemPriceConnection;
  catalogueItemPrices: Array<CatalogueItemPrice>;
  catalogueItemPrice: CatalogueItemPrice;
  allCatalogueRelations: CatalogueRelationConnection;
  catalogueRelations: Array<CatalogueRelation>;
  catalogueRelation: CatalogueRelation;
  catalogueSummaryWidget?: Maybe<Array<CatalogueSummaryWidget>>;
  allCaterings: CateringConnection;
  caterings: Array<Catering>;
  catering: Catering;
  allCommerceOrderDatas: CommerceOrderDataConnection;
  commerceOrderDatas: Array<CommerceOrderData>;
  commerceOrderData: CommerceOrderData;
  allCommerceOrderFields: CommerceOrderFieldConnection;
  commerceOrderFields: Array<CommerceOrderField>;
  commerceOrderField: CommerceOrderField;
  allCommerceOrderFieldTranslations: CommerceOrderFieldTranslationConnection;
  allCommerceOrderItems: CommerceOrderItemConnection;
  commerceOrderItems: Array<CommerceOrderItem>;
  commerceOrderItem: CommerceOrderItem;
  allCommerceOrderLogs: CommerceOrderLogConnection;
  allCommerceSettingses: CommerceSettingsConnection;
  commerceSettingses: Array<CommerceSettings>;
  commerceSettings: CommerceSettings;
  allCommerceShops: CommerceShopConnection;
  commerceShops: Array<CommerceShop>;
  commerceShop: CommerceShop;
  allCommerceTemplates: CommerceTemplateConnection;
  commerceTemplates: Array<CommerceTemplate>;
  commerceTemplate: CommerceTemplate;
  allCommerceOrders: CommerceOrderConnection;
  commerceOrder: CommerceOrder;
  /** Фильтры по заказам коммерции */
  commerceOrderFilters?: Maybe<Array<FilterItem>>;
  commerceSummaryWidget?: Maybe<Array<CommerceSummaryWidget>>;
  commerceOrderStatesWidget?: Maybe<Array<CommerceOrderStatesWidgetData>>;
  allContentTranslations: ContentTranslationConnection;
  contentTranslations: Array<ContentTranslation>;
  contentTranslation: ContentTranslation;
  /** Получение метки времени последнего обновления перевода */
  contentTranslationsUpdatedAt: Scalars['DateTime'];
  disputeSettings: DisputeSettings;
  disputeRatingsTotal: Array<DisputeRatingTotal>;
  myDisputesAndResponses: Array<Dispute>;
  disputeStat: Array<DisputeStat>;
  allDisputes: DisputeConnection;
  dispute: Dispute;
  allDisputeDisputeResponses: DisputeDisputeResponseConnection;
  disputeDisputeResponse: DisputeDisputeResponse;
  allDisputeRatings: DisputeRatingConnection;
  allDisputeRatingBySeasons: DisputeRatingBySeasonConnection;
  allDisputeRatingByTours: DisputeRatingByTourConnection;
  /**
   * Список секторов по событию.
   * Пока временный метод, пока мы не придумаем нормальную модель данных для хранения секторов
   */
  allEventSectorForSale?: Maybe<Array<EventPlaceSector>>;
  allEvents: EventConnection;
  events: Array<Event>;
  event: Event;
  allEventFields: EventFieldsConnection;
  allEventEventAccesses: EventEventAccessConnection;
  eventEventAccesses: Array<EventEventAccess>;
  eventEventAccess: EventEventAccess;
  allEventEventWhitelists: EventEventWhitelistConnection;
  eventEventWhitelists: Array<EventEventWhitelist>;
  eventEventWhitelist: EventEventWhitelist;
  allEventOrders: EventOrderConnection;
  eventOrders: Array<EventOrder>;
  eventOrder: EventOrder;
  allEventOrderFields: EventOrderFieldsConnection;
  allEventOrderTickets: EventOrderTicketConnection;
  eventOrderTickets: Array<EventOrderTicket>;
  eventOrderTicket: EventOrderTicket;
  allEventOrderTicketFields: EventOrderTicketFieldsConnection;
  allEventPlaces: EventPlaceConnection;
  eventPlaces: Array<EventPlace>;
  eventPlace: EventPlace;
  allEventPlaceSectors: EventPlaceSectorConnection;
  eventPlaceSectors: Array<EventPlaceSector>;
  eventPlaceSector: EventPlaceSector;
  allEventReserves: EventReserveConnection;
  eventReserves: Array<EventReserve>;
  eventReserve: EventReserve;
  allEventTypes: EventTypeConnection;
  eventTypes: Array<EventType>;
  eventType: EventType;
  /** Виджет с активными событиями. */
  eventWidgetActiveEvents: EventWidgetActiveEvents;
  /** Виджет со следующим событием. Выведен в отдельный виджет, чтобы потом улучшать. */
  eventWidgetNextEvent?: Maybe<Event>;
  /** Виджет с аналитикой по продажам билетов */
  eventWidgetTicketSales?: Maybe<EventWidgetTicketSales>;
  /** Виджет ближайших матчей */
  eventWidgetNearestMatches: Array<EventWidgetNearestMatch>;
  /** Виджет событий в продаже */
  eventWidgetSales: EventWidgetSales;
  /** Виджет аналитики продаж с разбивкой по месяцам и типам событий */
  eventWidgetEventTypesSales: EventWidgetEventTypesSales;
  unisenderLists: Array<UnisenderList>;
  allLoyaltyPartnerEntities: LoyaltyPartnerEntityConnection;
  applicationFilters?: Maybe<Array<FilterItem>>;
  loyaltyPartnerEntityByProviderCode?: Maybe<Scalars['String']>;
  allLoyaltyApplications: LoyaltyApplicationConnection;
  loyaltyApplications: Array<LoyaltyApplication>;
  loyaltyApplication: LoyaltyApplication;
  allLoyaltyPartners: LoyaltyPartnerConnection;
  loyaltyPartners: Array<LoyaltyPartner>;
  loyaltyPartner: LoyaltyPartner;
  allLoyaltyPromos: LoyaltyPromoConnection;
  loyaltyPromos: Array<LoyaltyPromo>;
  loyaltyPromo: LoyaltyPromo;
  allLoyaltyPromoCodes: LoyaltyPromoCodeConnection;
  loyaltyPromoCodes: Array<LoyaltyPromoCode>;
  loyaltyPromoCode: LoyaltyPromoCode;
  loyaltySummaryWidget?: Maybe<Array<LoyaltySummaryWidget>>;
  allMessengerMailings: MessengerMailingConnection;
  messengerMailings: Array<MessengerMailing>;
  messengerMailing: MessengerMailing;
  allMessengerMailingActivities: MessengerMailingActivityConnection;
  messengerMailingActivities: Array<MessengerMailingActivity>;
  messengerMailingActivity: MessengerMailingActivity;
  allMessengerMailingRedirects: MessengerMailingRedirectConnection;
  messengerMailingRedirect: MessengerMailingRedirect;
  allMessengerProviders: MessengerProviderConnection;
  messengerProviders: Array<MessengerProvider>;
  messengerProvider: MessengerProvider;
  allMessengerQueueTasks: MessengerQueueTaskConnection;
  messengerQueueTasks: Array<MessengerQueueTask>;
  messengerQueueTask: MessengerQueueTask;
  allMessengerTemplates: MessengerTemplateConnection;
  messengerTemplates: Array<MessengerTemplate>;
  messengerTemplate: MessengerTemplate;
  allMessengerTemplateProviders: MessengerTemplateProviderConnection;
  messengerTemplateProvider: MessengerTemplateProvider;
  allMessengerTransports: MessengerTransportConnection;
  messengerTransports: Array<MessengerTransport>;
  messengerTransport: MessengerTransport;
  /** Вывод статистики сообщений по рассылке */
  messengerMailingStat: Array<MessengerQueueNotificationStat>;
  /** Вывод статистики каналов по рассылкам */
  messengerMailingChannels: Array<MessengerMailingChannelStat>;
  /** Настройки клиента */
  messengerSetting: MessengerSetting;
  messengerSettings: Array<MessengerSetting>;
  allMessengerQueueNotifications: MessengerQueueNotificationConnection;
  messengerQueueNotifications: Array<MessengerQueueNotification>;
  messengerQueueNotification: MessengerQueueNotification;
  redirectAnalytics: Array<MessengerMailingRedirectAnalytics>;
  /** Получение списка переводов */
  allMobileTranslations?: Maybe<Array<MobileTranslation>>;
  /** Получение перевода по id */
  mobileTranslation: MobileTranslation;
  mobileTranslationsUpdatedAt: Scalars['DateTime'];
  accountBalance: ProviderResponse;
  allPaymentAccounts: PaymentAccountConnection;
  paymentAccounts: Array<PaymentAccount>;
  paymentAccount: PaymentAccount;
  allPaymentBankCards: PaymentBankCardConnection;
  paymentBankCards: Array<PaymentBankCard>;
  paymentBankCard: PaymentBankCard;
  allPaymentChannels: PaymentChannelConnection;
  paymentChannels: Array<PaymentChannel>;
  paymentChannel: PaymentChannel;
  allPaymentCurrencies: PaymentCurrencyConnection;
  paymentCurrencies: Array<PaymentCurrency>;
  paymentCurrency: PaymentCurrency;
  allPaymentGatewaies: PaymentGatewayConnection;
  paymentGatewaies: Array<PaymentGateway>;
  paymentGateway: PaymentGateway;
  allPaymentInAppPurchases: PaymentInAppPurchaseConnection;
  paymentInAppPurchase: PaymentInAppPurchase;
  allPaymentInAppTransactions: PaymentInAppTransactionConnection;
  paymentInAppTransaction: PaymentInAppTransaction;
  allPaymentProviders: PaymentProviderConnection;
  paymentProviders: Array<PaymentProvider>;
  paymentProvider: PaymentProvider;
  allPaymentTransactions: PaymentTransactionConnection;
  paymentTransactions: Array<PaymentTransaction>;
  paymentTransaction: PaymentTransaction;
  allPaymentTransactionLogs: PaymentTransactionLogConnection;
  paymentTransactionLogs: Array<PaymentTransactionLog>;
  paymentTransactionLog: PaymentTransactionLog;
  /** Получение лога транзакции по идентификатору транзакции */
  paymentTransactionLogByTransactionId: PaymentTransactionLog;
  /** Виджет количества и суммы транзакций с разбивкой по месяцам и каналам продаж */
  paymentChannelSummaryWidget: Array<PaymentChannelSummaryWidget>;
  /** Виджет продаж по валюте на все время с группировкой по месяцу */
  paymentCurrencySummaryWidget: Array<PaymentCurrencySummaryWidget>;
  /** Виджет продаж по провайдерам на все время с группировкой по месяцу */
  paymentProviderSummaryWidget: Array<PaymentProviderSummaryWidget>;
  /** Виджет продаж по провайдерам на все время с группировкой по месяцу */
  paymentGatewaySummaryWidget: Array<PaymentGatewaySummaryWidget>;
  allQuizAttempts: QuizAttemptConnection;
  quizAttempts: Array<QuizAttempt>;
  quizAttempt: QuizAttempt;
  allQuizAttemptAnswers: QuizAttemptAnswerConnection;
  quizAttemptAnswers: Array<QuizAttemptAnswer>;
  quizAttemptAnswer: QuizAttemptAnswer;
  allQuizQuestions: QuizQuestionConnection;
  quizQuestions: Array<QuizQuestion>;
  quizQuestion: QuizQuestion;
  allQuizQuestionAnswers: QuizQuestionAnswerConnection;
  quizQuestionAnswers: Array<QuizQuestionAnswer>;
  quizQuestionAnswer: QuizQuestionAnswer;
  allQuizes: QuizConnection;
  quizes: Array<Quiz>;
  quiz: Quiz;
  allSocialNetworks: SocialNetworkConnection;
  socialNetworks: Array<SocialNetwork>;
  socialNetwork: SocialNetwork;
  allSocialNewsSettings: SocialNewsSettingConnection;
  socialNewsSettings: Array<SocialNewsSetting>;
  socialNewsSetting: SocialNewsSetting;
  /** Вернет или публичный профиль клиента или ничего. Потому что профиль может быть скрыт. */
  clientPublicProfile?: Maybe<ClientPublicProfile>;
  allSystemAsyncTasks: SystemAsyncTaskConnection;
  systemAsyncTask: SystemAsyncTask;
  systemCountries: Array<SystemCountry>;
  allSystemPrinters: SystemPrinterConnection;
  systemPrinters: Array<SystemPrinter>;
  systemPrinter: SystemPrinter;
  allSystemTasks: SystemTaskConnection;
  systemTasks: Array<SystemTask>;
  systemTask: SystemTask;
  allSystemTranslations: SystemTranslationConnection;
  systemTranslations: Array<SystemTranslation>;
  systemTranslation: SystemTranslation;
  allSystemWebHooks: SystemWebHookConnection;
  systemWebHooks: Array<SystemWebHook>;
  systemWebHook: SystemWebHook;
  allSystemLocales: SystemLocaleConnection;
  systemLocales: Array<SystemLocale>;
  systemLocale: SystemLocale;
  getConstants: Constants;
  /** Список констант от сервера. */
  allSystemConstants: Array<Constant>;
  allPrinters: Array<Printer>;
  /** Получение даты последнего обновления системного словаря. */
  systemTranslationsUpdatedAt: Scalars['DateTime'];
  /** Список пользователей с отбором, сортировкой и пагинацией. */
  allSystemUsers: SystemUsersConnection;
  /** Список групп пользователей с отбором, сортировкой и пагинацией. */
  allSystemUserGroups: SystemUserGroupsConnection;
  systemUser: SystemUser;
  /** Справочник с доступами. */
  acl: Array<Acl>;
  /** Навигация по ERP-системе. */
  navigation: Array<Navigation>;
  /**
   * Справочник с доступами для конкретного пользователя.
   * Обязательно передавать и клиента тоже, потому что доступ на уровне связки Пользователь-Клиент
   */
  systemUserACL: Array<Acl>;
  systemUserProfile: SystemUser;
  systemUserSettings: UserSettings;
  allTemplateApplePasses: TemplateApplePassConnection;
  templateApplePasses: Array<TemplateApplePass>;
  templateApplePass: TemplateApplePass;
  allTemplateNotifications: TemplateNotificationConnection;
  templateNotifications: Array<TemplateNotification>;
  templateNotification: TemplateNotification;
  allTemplatePDFs: TemplatePdfConnection;
  templatePDFs: Array<TemplatePdf>;
  templatePDF: TemplatePdf;
  allTemplateProviders: TemplateProviderConnection;
  templateProvider: TemplateProvider;
  allTournamentDisputes: TournamentDisputeConnection;
  tournamentDisputes: Array<TournamentDispute>;
  tournamentDispute: TournamentDispute;
  allTournamentDisputeResponses: TournamentDisputeResponseConnection;
  tournamentDisputeResponses: Array<TournamentDisputeResponse>;
  tournamentDisputeResponse: TournamentDisputeResponse;
  allTournamentDisqualifications: TournamentDisqualificationConnection;
  tournamentDisqualification: TournamentDisqualification;
  allTournamentGroups: TournamentGroupConnection;
  tournamentGroups: Array<TournamentGroup>;
  tournamentGroup: TournamentGroup;
  allTournamentMatches: TournamentMatchConnection;
  tournamentMatches: Array<TournamentMatch>;
  tournamentMatch: TournamentMatch;
  allTournamentMatchFields: TournamentMatchFieldsConnection;
  allTournamentMatchEvents: TournamentMatchEventConnection;
  tournamentMatchEvent: TournamentMatchEvent;
  tournamentMatchLineUps: Array<TournamentMatchLineUp>;
  allTournamentMatchProtocols: TournamentMatchProtocolConnection;
  tournamentMatchProtocols: Array<TournamentMatchProtocol>;
  tournamentMatchProtocol: TournamentMatchProtocol;
  allTournamentMatchStats: TournamentMatchStatConnection;
  tournamentMatchStats: Array<TournamentMatchStat>;
  tournamentMatchStat: TournamentMatchStat;
  allTournamentPlayerStats: TournamentPlayerStatConnection;
  tournamentPlayerStats: Array<TournamentPlayerStat>;
  tournamentPlayerStat: TournamentPlayerStat;
  allTournamentReferees: TournamentRefereeConnection;
  allTournamentSeasons: TournamentSeasonConnection;
  tournamentSeason: TournamentSeason;
  allTournamentSeasonApplicationForms: TournamentSeasonApplicationFormConnection;
  tournamentSeasonApplicationForms: Array<TournamentSeasonApplicationForm>;
  tournamentSeasonApplicationForm: TournamentSeasonApplicationForm;
  allTournamentSeasonTeams: TournamentSeasonTeamConnection;
  tournamentSeasonTeams: Array<TournamentSeasonTeam>;
  tournamentSeasonTeam: TournamentSeasonTeam;
  allTournamentSeasonTeamMembers: TournamentSeasonTeamMemberConnection;
  tournamentSeasonTeamMembers: Array<TournamentSeasonTeamMember>;
  tournamentSeasonTeamMember: TournamentSeasonTeamMember;
  allTournamentSeasonTeamStats: TournamentSeasonTeamStatConnection;
  tournamentSeasonTeamStats: Array<TournamentSeasonTeamStat>;
  tournamentSeasonTeamStat: TournamentSeasonTeamStat;
  allTournamentStadiums: TournamentStadiumConnection;
  tournamentStadiums: Array<TournamentStadium>;
  tournamentStadium: TournamentStadium;
  allTournamentTeams: TournamentTeamConnection;
  tournamentTeams: Array<TournamentTeam>;
  tournamentTeam: TournamentTeam;
  allTournamentTeamFields: TournamentTeamFieldsConnection;
  allTournamentTeamGroups: TournamentTeamGroupConnection;
  tournamentTeamGroups: Array<TournamentTeamGroup>;
  tournamentTeamGroup: TournamentTeamGroup;
  allTournamentTeamHistories: TournamentTeamHistoryConnection;
  tournamentTeamHistories: Array<TournamentTeamHistory>;
  tournamentTeamHistory: TournamentTeamHistory;
  allTournamentTeamMembers: TournamentTeamMemberConnection;
  tournamentTeamMembers: Array<TournamentTeamMember>;
  tournamentTeamMember: TournamentTeamMember;
  allTournamentTeamMemberFields: TournamentTeamMemberFieldsConnection;
  allTournamentTeamMemberSocialAccounts: TournamentTeamMemberSocialAccountConnection;
  tournamentTeamMemberSocialAccounts: Array<TournamentTeamMemberSocialAccount>;
  tournamentTeamMemberSocialAccount: TournamentTeamMemberSocialAccount;
  allTournamentTours: TournamentTourConnection;
  tournamentTours: Array<TournamentTour>;
  tournamentTour: TournamentTour;
  allTournaments: TournamentConnection;
  tournaments: Array<Tournament>;
  tournament: Tournament;
  allTournamentFields: TournamentFieldsConnection;
  allTournamentTranslations: TournamentTranslationConnection;
  tournamentTranslations: Array<TournamentTranslation>;
  tournamentTranslation: TournamentTranslation;
  /**
   * Получение списка матчей по диапазону старта матча
   * Дополнительная фильтрация по seasonId
   */
  tournamentMatchesByStartTime: Array<TournamentMatch>;
  tournamentSettingByTournamentId: TournamentSetting;
  /** Получение списка данных по типу */
  tournamentExtensionData: Array<ExtensionData>;
  userApplicationStats: Array<UserApplicationStats>;
  userApplicationLogs: Array<UserApplicationLog>;
  allUserAnalyticFields: UserAnalyticFieldConnection;
  userAnalyticFields: Array<UserAnalyticField>;
  userAnalyticField: UserAnalyticField;
  allUserApplications: UserApplicationConnection;
  userApplications: Array<UserApplication>;
  userApplication: UserApplication;
  allUserApplicationFields: UserApplicationFieldsConnection;
  allUserApplicationTypes: UserApplicationTypeConnection;
  userApplicationTypes: Array<UserApplicationType>;
  userApplicationType: UserApplicationType;
  allUserDocuments: UserDocumentConnection;
  userDocuments: Array<UserDocument>;
  userDocument: UserDocument;
  allUserGroups: UserGroupConnection;
  userGroups: Array<UserGroup>;
  userGroup: UserGroup;
  allUserKids: UserKidConnection;
  userKids: Array<UserKid>;
  userKid: UserKid;
  allUserMatchEventSubscribers: UserMatchEventSubscriberConnection;
  allUserSocialAccounts: UserSocialAccountConnection;
  userSocialAccounts: Array<UserSocialAccount>;
  userSocialAccount: UserSocialAccount;
  allUserSubscribers: UserSubscriberConnection;
  userSubscribers: Array<UserSubscriber>;
  userSubscriber: UserSubscriber;
  allUserSubscribersLogs: UserSubscribersLogConnection;
  userSubscribersLogs: Array<UserSubscribersLog>;
  userSubscribersLog: UserSubscribersLog;
  allUserSubscriptions: UserSubscriptionConnection;
  userSubscriptions: Array<UserSubscription>;
  userSubscription: UserSubscription;
  allUserFields: UserFieldsConnection;
  myMatchEventSubscriptions: MatchEventSubscription;
  /** Получение настроек клиента */
  userSettings?: Maybe<UserSetting>;
  /**
   * Специальный роут для получения «своего профиля» для внешних API.
   * Авторизация и ID пользователя берется из токена x-customer-token
   */
  userProfile: User;
  /** Список пользователей */
  allUsers?: Maybe<UserConnection>;
  /** Список пользователей с поиском, фильтрацией, сортировкой и пагинацией */
  users?: Maybe<UsersConnection>;
  /** Получение пользователя по id */
  user: User;
  /** Список устройств пользователей */
  allUserDevices: UserDeviceConnection;
  /** Получить все устройства пользователя */
  userDevicesByUserId: Array<UserDevice>;
  /** Получить все координаты пользователя */
  userLocationsByUserId: Array<UserLocation>;
  /** Список пользовательских полей */
  userDataFields: Array<UserDataField>;
  /** Проверка пользователя */
  checkUser?: Maybe<User>;
  /** Виджет новых болельщиков */
  newUsersWidget: NewUsersWidget;
  ads: Array<Ad>;
  ad: Ad;
  /** Список новостей (фото) */
  allArticlePhotos: ArticlesConnection;
  /** Список новостей (видео) */
  allArticleVideos: ArticlesConnection;
  /** Список новостей (видеотрансляции) */
  allArticleVideoStreams: ArticlesConnection;
  /** Список новостей (медиа) */
  allArticleMedia: ArticlesConnection;
  /** Список новостей (сторис) */
  allArticleStories: ArticleStoriesConnection;
  /** Список новостей (текст) */
  allArticleNews: ArticlesConnection;
  /** Информация - армейцы без границ */
  clubInfoDSA: ClubInfoDsa;
  /** Детский клуб */
  kidsClubInfo: KidsClubInfo;
  /** Проверка персонального идентификатора клиента */
  checkFanId: Array<CheckFanIdResult>;
  /** Вывод списка резервов пользователя */
  infotechEvents: Scalars['JSON'];
  /** Вывод списка резервов пользователя */
  myEventReserves: Array<Reserve>;
  /** Вывод списка заказов пользователя на события */
  allMyEventOrders: EventOrderConnection;
  /** Вывод списка заказов пользователя на события */
  myEventOrder: EventOrder;
  /** Список секторов по событию с фильтрацией по идентификатору сектора в билетной системе */
  eventSectors: Array<EventSector>;
  /** Получение сектора по событию и идентификатору сектора в билетной системе */
  eventSector: EventSector;
  /** Список мест по сектору события */
  eventSectorSeats: Array<EventSectorRow>;
  /** Виджет событий для продаж */
  ticketSaleWidget: TicketSaleWidget;
  eventTypesConfig: EventTypesConfig;
  /** Вывод списка заказов еды пользователя */
  allMyFoodOrders: FoodOrderConnection;
  /** Вывод заказа еды пользователя по id */
  myFoodOrder: FoodOrder;
  /** Список товаров каталога еды */
  allFoodItems: FoodItemConnection;
  /** Товар из каталога по id */
  foodItem: FoodItem;
  /** Статус продажи еды */
  foodSale: FoodSale;
  /** Вывод доступных секторов для заказа еды */
  foodSectors: Array<FoodSector>;
  /** Вывод списка моих заказов */
  allMyLoyaltyOrders: LoyaltyOrderConnection;
  /** Вывод списка заказов пользователей */
  allLoyaltyOrders: LoyaltyOrderConnection;
  /** Список товаров каталога лояльности */
  allLoyaltyItems: LoyaltyItemConnection;
  /** Товар лояльности по id */
  loyaltyItem: LoyaltyItem;
  /** Проверка промокода */
  checkPromocode?: Maybe<LoyaltyPromoCode>;
  /** Список стадионов */
  stadium: Stadium;
  /**
   * Список ближайших матчей
   * ATTENTION: аргументы не работают
   */
  nearestTournamentMatches: TournamentMatchConnection;
  /** Список актуальных матчей (по последним сезонам) */
  allActiveTournamentMatches: TournamentMatchConnection;
  /** Состав команды на сезон */
  seasonTeamMembers: Array<TournamentTeamMember>;
  /** Турнирная таблица по сезону */
  seasonTable: Array<TournamentSeasonTeamStat>;
  matchDay?: Maybe<TournamentMatchDay>;
  /** @DEPRECATED Список матчей для виджета карусель  */
  carouselWidgetMatches: Array<TournamentMatch>;
  allWebTranslations: Array<Translation>;
  /** Последняя дата обновления перевода для сайта */
  webTranslationsUpdatedAt: Scalars['DateTime'];
  /** Список подписок пользователя */
  allMySubscriptions: UserSubscriptionConnection;
  /** Список уведомлений пользователя */
  allMyNotifications: NotificationConnection;
  /** Список моих заявок по семейному сектору */
  myApplicationsFamilySector: Array<UserApplication>;
  /** Список моих заявок по отправке сообщения на экран стадиона */
  myApplicationsScreenStadium: Array<UserApplication>;
  getMyIP: Scalars['String'];
};

export type QueryFiltersArgs = {
  queryName: Scalars['String'];
};

export type QueryAllArticlesArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryArticleArgs = {
  id: Scalars['Int'];
};

export type QueryAllArticleBlockAuthorsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryAllEntityRecordsByArticleLinkTypeArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryAllArticleArticleTagsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryArticleArticleTagsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryArticleArticleTagArgs = {
  id: Scalars['Int'];
};

export type QueryAllArticleAuthorsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryArticleAuthorsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryArticleAuthorArgs = {
  id: Scalars['Int'];
};

export type QueryAllArticleBlocksArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryArticleBlocksArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryArticleBlockArgs = {
  id: Scalars['Int'];
};

export type QueryAllArticleCategoriesArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryArticleCategoriesArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryArticleCategoryArgs = {
  id: Scalars['Int'];
};

export type QueryAllArticleLinksArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryArticleLinksArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryArticleLinkArgs = {
  id: Scalars['Int'];
};

export type QueryArticleLogsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryAllArticleTagsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryArticleTagsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryArticleTagArgs = {
  id: Scalars['Int'];
};

export type QueryCatalogueItemsByEventArgs = {
  eventId: Scalars['Int'];
};

export type QueryAllCataloguesArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryCataloguesArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryCatalogueArgs = {
  id: Scalars['Int'];
};

export type QueryAllCatalogueFieldsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryAllCatalogueCategoriesArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryCatalogueCategoriesArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryCatalogueCategoryArgs = {
  id: Scalars['Int'];
};

export type QueryAllCatalogueCategoryFieldsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryAllCatalogueItemsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryCatalogueItemsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryCatalogueItemArgs = {
  id: Scalars['Int'];
};

export type QueryAllCatalogueItemFieldsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryAllCatalogueItemPricesArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryCatalogueItemPricesArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryCatalogueItemPriceArgs = {
  id: Scalars['Int'];
};

export type QueryAllCatalogueRelationsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryCatalogueRelationsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryCatalogueRelationArgs = {
  id: Scalars['Int'];
};

export type QueryCatalogueSummaryWidgetArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryAllCateringsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryCateringsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryCateringArgs = {
  id: Scalars['Int'];
};

export type QueryAllCommerceOrderDatasArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryCommerceOrderDatasArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryCommerceOrderDataArgs = {
  id: Scalars['Int'];
};

export type QueryAllCommerceOrderFieldsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryCommerceOrderFieldsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryCommerceOrderFieldArgs = {
  id: Scalars['Int'];
};

export type QueryAllCommerceOrderFieldTranslationsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryAllCommerceOrderItemsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryCommerceOrderItemsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryCommerceOrderItemArgs = {
  id: Scalars['Int'];
};

export type QueryAllCommerceOrderLogsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryAllCommerceSettingsesArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryCommerceSettingsesArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryCommerceSettingsArgs = {
  id: Scalars['Int'];
};

export type QueryAllCommerceShopsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryCommerceShopsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryCommerceShopArgs = {
  id: Scalars['Int'];
};

export type QueryAllCommerceTemplatesArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryCommerceTemplatesArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryCommerceTemplateArgs = {
  id: Scalars['Int'];
};

export type QueryAllCommerceOrdersArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryCommerceOrderArgs = {
  id: Scalars['Int'];
};

export type QueryCommerceOrderFiltersArgs = {
  shopId: Scalars['Int'];
};

export type QueryCommerceSummaryWidgetArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryCommerceOrderStatesWidgetArgs = {
  shopId: Scalars['Int'];
};

export type QueryAllContentTranslationsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryContentTranslationsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryContentTranslationArgs = {
  id: Scalars['Int'];
};

export type QueryContentTranslationsUpdatedAtArgs = {
  localeId: Scalars['Int'];
  channel?: Maybe<Scalars['String']>;
};

export type QueryDisputeRatingsTotalArgs = {
  limit?: Maybe<Scalars['Int']>;
  cursor?: Maybe<Scalars['Int']>;
  type?: Maybe<RatingType>;
};

export type QueryMyDisputesAndResponsesArgs = {
  limit?: Maybe<Scalars['Int']>;
  cursor?: Maybe<Scalars['Int']>;
  state?: Maybe<Scalars['String']>;
  seasonId?: Maybe<Scalars['Int']>;
};

export type QueryDisputeStatArgs = {
  userId: Scalars['Int'];
  states?: Maybe<Array<Scalars['String']>>;
};

export type QueryAllDisputesArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryDisputeArgs = {
  id: Scalars['Int'];
};

export type QueryAllDisputeDisputeResponsesArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryDisputeDisputeResponseArgs = {
  id: Scalars['Int'];
};

export type QueryAllDisputeRatingsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryAllDisputeRatingBySeasonsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryAllDisputeRatingByToursArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryAllEventSectorForSaleArgs = {
  eventId: Scalars['Int'];
};

export type QueryAllEventsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryEventsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryEventArgs = {
  id: Scalars['Int'];
};

export type QueryAllEventFieldsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryAllEventEventAccessesArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryEventEventAccessesArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryEventEventAccessArgs = {
  id: Scalars['Int'];
};

export type QueryAllEventEventWhitelistsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryEventEventWhitelistsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryEventEventWhitelistArgs = {
  id: Scalars['Int'];
};

export type QueryAllEventOrdersArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryEventOrdersArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryEventOrderArgs = {
  id: Scalars['Int'];
};

export type QueryAllEventOrderFieldsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryAllEventOrderTicketsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryEventOrderTicketsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryEventOrderTicketArgs = {
  id: Scalars['Int'];
};

export type QueryAllEventOrderTicketFieldsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryAllEventPlacesArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryEventPlacesArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryEventPlaceArgs = {
  id: Scalars['Int'];
};

export type QueryAllEventPlaceSectorsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryEventPlaceSectorsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryEventPlaceSectorArgs = {
  id: Scalars['Int'];
};

export type QueryAllEventReservesArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryEventReservesArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryEventReserveArgs = {
  id: Scalars['Int'];
};

export type QueryAllEventTypesArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryEventTypesArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryEventTypeArgs = {
  id: Scalars['Int'];
};

export type QueryEventWidgetTicketSalesArgs = {
  query?: Maybe<EventWidgetTicketSalesInput>;
};

export type QueryEventWidgetNearestMatchesArgs = {
  placeId?: Maybe<Scalars['Int']>;
};

export type QueryEventWidgetSalesArgs = {
  placeId?: Maybe<Scalars['Int']>;
};

export type QueryEventWidgetEventTypesSalesArgs = {
  placeId?: Maybe<Scalars['Int']>;
  dates?: Maybe<Array<Scalars['String']>>;
};

export type QueryAllLoyaltyPartnerEntitiesArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryApplicationFiltersArgs = {
  partnerId: Scalars['Int'];
};

export type QueryLoyaltyPartnerEntityByProviderCodeArgs = {
  providerId?: Maybe<Scalars['Int']>;
  providerCode?: Maybe<Scalars['String']>;
};

export type QueryAllLoyaltyApplicationsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryLoyaltyApplicationsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryLoyaltyApplicationArgs = {
  id: Scalars['Int'];
};

export type QueryAllLoyaltyPartnersArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryLoyaltyPartnersArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryLoyaltyPartnerArgs = {
  id: Scalars['Int'];
};

export type QueryAllLoyaltyPromosArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryLoyaltyPromosArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryLoyaltyPromoArgs = {
  id: Scalars['Int'];
};

export type QueryAllLoyaltyPromoCodesArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryLoyaltyPromoCodesArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryLoyaltyPromoCodeArgs = {
  id: Scalars['Int'];
};

export type QueryLoyaltySummaryWidgetArgs = {
  where?: Maybe<LoyaltySummaryWidgetInput>;
};

export type QueryAllMessengerMailingsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryMessengerMailingsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryMessengerMailingArgs = {
  id: Scalars['Int'];
};

export type QueryAllMessengerMailingActivitiesArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryMessengerMailingActivitiesArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryMessengerMailingActivityArgs = {
  id: Scalars['Int'];
};

export type QueryAllMessengerMailingRedirectsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryMessengerMailingRedirectArgs = {
  id: Scalars['Int'];
};

export type QueryAllMessengerProvidersArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryMessengerProvidersArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryMessengerProviderArgs = {
  id: Scalars['Int'];
};

export type QueryAllMessengerQueueTasksArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryMessengerQueueTasksArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryMessengerQueueTaskArgs = {
  id: Scalars['Int'];
};

export type QueryAllMessengerTemplatesArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryMessengerTemplatesArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryMessengerTemplateArgs = {
  id: Scalars['Int'];
};

export type QueryAllMessengerTemplateProvidersArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryMessengerTemplateProviderArgs = {
  id: Scalars['Int'];
};

export type QueryAllMessengerTransportsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryMessengerTransportsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryMessengerTransportArgs = {
  id: Scalars['Int'];
};

export type QueryMessengerMailingStatArgs = {
  mailingId?: Maybe<Scalars['Int']>;
};

export type QueryAllMessengerQueueNotificationsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryMessengerQueueNotificationsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryMessengerQueueNotificationArgs = {
  id: Scalars['String'];
};

export type QueryRedirectAnalyticsArgs = {
  data: RedirectAnalyticsInput;
};

export type QueryAllMobileTranslationsArgs = {
  locale: Scalars['String'];
};

export type QueryMobileTranslationArgs = {
  id: Scalars['Int'];
};

export type QueryMobileTranslationsUpdatedAtArgs = {
  locale: Scalars['String'];
};

export type QueryAccountBalanceArgs = {
  accountId: Scalars['Int'];
};

export type QueryAllPaymentAccountsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryPaymentAccountsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryPaymentAccountArgs = {
  id: Scalars['Int'];
};

export type QueryAllPaymentBankCardsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryPaymentBankCardsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryPaymentBankCardArgs = {
  id: Scalars['Int'];
};

export type QueryAllPaymentChannelsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryPaymentChannelsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryPaymentChannelArgs = {
  id: Scalars['Int'];
};

export type QueryAllPaymentCurrenciesArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryPaymentCurrenciesArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryPaymentCurrencyArgs = {
  id: Scalars['Int'];
};

export type QueryAllPaymentGatewaiesArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryPaymentGatewaiesArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryPaymentGatewayArgs = {
  id: Scalars['Int'];
};

export type QueryAllPaymentInAppPurchasesArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryPaymentInAppPurchaseArgs = {
  id: Scalars['Int'];
};

export type QueryAllPaymentInAppTransactionsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryPaymentInAppTransactionArgs = {
  id: Scalars['Int'];
};

export type QueryAllPaymentProvidersArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryPaymentProvidersArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryPaymentProviderArgs = {
  id: Scalars['Int'];
};

export type QueryAllPaymentTransactionsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryPaymentTransactionsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryPaymentTransactionArgs = {
  id: Scalars['Int'];
};

export type QueryAllPaymentTransactionLogsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryPaymentTransactionLogsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryPaymentTransactionLogArgs = {
  id: Scalars['Int'];
};

export type QueryPaymentTransactionLogByTransactionIdArgs = {
  transactionId: Scalars['Int'];
};

export type QueryAllQuizAttemptsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryQuizAttemptsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryQuizAttemptArgs = {
  id: Scalars['Int'];
};

export type QueryAllQuizAttemptAnswersArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryQuizAttemptAnswersArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryQuizAttemptAnswerArgs = {
  id: Scalars['Int'];
};

export type QueryAllQuizQuestionsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryQuizQuestionsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryQuizQuestionArgs = {
  id: Scalars['Int'];
};

export type QueryAllQuizQuestionAnswersArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryQuizQuestionAnswersArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryQuizQuestionAnswerArgs = {
  id: Scalars['Int'];
};

export type QueryAllQuizesArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryQuizesArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryQuizArgs = {
  id: Scalars['Int'];
};

export type QueryAllSocialNetworksArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QuerySocialNetworksArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QuerySocialNetworkArgs = {
  id: Scalars['Int'];
};

export type QueryAllSocialNewsSettingsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QuerySocialNewsSettingsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QuerySocialNewsSettingArgs = {
  id: Scalars['Int'];
};

export type QueryClientPublicProfileArgs = {
  name?: Maybe<Scalars['String']>;
  domain?: Maybe<Scalars['String']>;
};

export type QueryAllSystemAsyncTasksArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QuerySystemAsyncTaskArgs = {
  id: Scalars['Int'];
};

export type QuerySystemCountriesArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryAllSystemPrintersArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QuerySystemPrintersArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QuerySystemPrinterArgs = {
  id: Scalars['Int'];
};

export type QueryAllSystemTasksArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QuerySystemTasksArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QuerySystemTaskArgs = {
  id: Scalars['UUID'];
};

export type QueryAllSystemTranslationsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QuerySystemTranslationsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QuerySystemTranslationArgs = {
  id: Scalars['Int'];
};

export type QueryAllSystemWebHooksArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QuerySystemWebHooksArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QuerySystemWebHookArgs = {
  id: Scalars['Int'];
};

export type QueryAllSystemLocalesArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QuerySystemLocalesArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QuerySystemLocaleArgs = {
  id: Scalars['Int'];
};

export type QuerySystemTranslationsUpdatedAtArgs = {
  locale: Scalars['String'];
};

export type QueryAllSystemUsersArgs = {
  include?: Maybe<SystemUsersWhereInput>;
  sort?: Maybe<Array<DefaultSortInput>>;
  search?: Maybe<Scalars['String']>;
  cursor?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type QueryAllSystemUserGroupsArgs = {
  where?: Maybe<SystemUsersWhereInput>;
  sort?: Maybe<Array<DefaultSortInput>>;
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
};

export type QuerySystemUserArgs = {
  id: Scalars['ID'];
};

export type QuerySystemUserAclArgs = {
  userId: Scalars['ID'];
  clientId: Scalars['ID'];
};

export type QueryAllTemplateApplePassesArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryTemplateApplePassesArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryTemplateApplePassArgs = {
  id: Scalars['Int'];
};

export type QueryAllTemplateNotificationsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryTemplateNotificationsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryTemplateNotificationArgs = {
  id: Scalars['Int'];
};

export type QueryAllTemplatePdFsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryTemplatePdFsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryTemplatePdfArgs = {
  id: Scalars['Int'];
};

export type QueryAllTemplateProvidersArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryTemplateProviderArgs = {
  id: Scalars['Int'];
};

export type QueryAllTournamentDisputesArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryTournamentDisputesArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryTournamentDisputeArgs = {
  id: Scalars['Int'];
};

export type QueryAllTournamentDisputeResponsesArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryTournamentDisputeResponsesArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryTournamentDisputeResponseArgs = {
  id: Scalars['Int'];
};

export type QueryAllTournamentDisqualificationsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryTournamentDisqualificationArgs = {
  id: Scalars['Int'];
};

export type QueryAllTournamentGroupsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryTournamentGroupsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryTournamentGroupArgs = {
  id: Scalars['Int'];
};

export type QueryAllTournamentMatchesArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryTournamentMatchesArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryTournamentMatchArgs = {
  id: Scalars['Int'];
};

export type QueryAllTournamentMatchFieldsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryAllTournamentMatchEventsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryTournamentMatchEventArgs = {
  id: Scalars['Int'];
};

export type QueryTournamentMatchLineUpsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryAllTournamentMatchProtocolsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryTournamentMatchProtocolsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryTournamentMatchProtocolArgs = {
  id: Scalars['Int'];
};

export type QueryAllTournamentMatchStatsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryTournamentMatchStatsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryTournamentMatchStatArgs = {
  id: Scalars['Int'];
};

export type QueryAllTournamentPlayerStatsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryTournamentPlayerStatsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryTournamentPlayerStatArgs = {
  id: Scalars['Int'];
};

export type QueryAllTournamentRefereesArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryAllTournamentSeasonsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryTournamentSeasonArgs = {
  id: Scalars['Int'];
};

export type QueryAllTournamentSeasonApplicationFormsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryTournamentSeasonApplicationFormsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryTournamentSeasonApplicationFormArgs = {
  id: Scalars['Int'];
};

export type QueryAllTournamentSeasonTeamsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryTournamentSeasonTeamsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryTournamentSeasonTeamArgs = {
  id: Scalars['Int'];
};

export type QueryAllTournamentSeasonTeamMembersArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryTournamentSeasonTeamMembersArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryTournamentSeasonTeamMemberArgs = {
  id: Scalars['Int'];
};

export type QueryAllTournamentSeasonTeamStatsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryTournamentSeasonTeamStatsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryTournamentSeasonTeamStatArgs = {
  id: Scalars['Int'];
};

export type QueryAllTournamentStadiumsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryTournamentStadiumsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryTournamentStadiumArgs = {
  id: Scalars['Int'];
};

export type QueryAllTournamentTeamsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryTournamentTeamsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryTournamentTeamArgs = {
  id: Scalars['Int'];
};

export type QueryAllTournamentTeamFieldsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryAllTournamentTeamGroupsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryTournamentTeamGroupsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryTournamentTeamGroupArgs = {
  id: Scalars['Int'];
};

export type QueryAllTournamentTeamHistoriesArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryTournamentTeamHistoriesArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryTournamentTeamHistoryArgs = {
  id: Scalars['Int'];
};

export type QueryAllTournamentTeamMembersArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryTournamentTeamMembersArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryTournamentTeamMemberArgs = {
  id: Scalars['Int'];
};

export type QueryAllTournamentTeamMemberFieldsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryAllTournamentTeamMemberSocialAccountsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryTournamentTeamMemberSocialAccountsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryTournamentTeamMemberSocialAccountArgs = {
  id: Scalars['Int'];
};

export type QueryAllTournamentToursArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryTournamentToursArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryTournamentTourArgs = {
  id: Scalars['Int'];
};

export type QueryAllTournamentsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryTournamentsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryTournamentArgs = {
  id: Scalars['Int'];
};

export type QueryAllTournamentFieldsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryAllTournamentTranslationsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryTournamentTranslationsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryTournamentTranslationArgs = {
  id: Scalars['Int'];
};

export type QueryTournamentMatchesByStartTimeArgs = {
  seasonId: Scalars['Int'];
  from: Scalars['DateTime'];
  to: Scalars['DateTime'];
};

export type QueryTournamentSettingByTournamentIdArgs = {
  tournamentId: Scalars['Int'];
};

export type QueryTournamentExtensionDataArgs = {
  instanceType: TournamentInstanceType;
  fieldId?: Maybe<Scalars['Int']>;
  instanceId?: Maybe<Scalars['Int']>;
};

export type QueryUserApplicationLogsArgs = {
  applicationId: Scalars['Int'];
};

export type QueryAllUserAnalyticFieldsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryUserAnalyticFieldsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryUserAnalyticFieldArgs = {
  id: Scalars['Int'];
};

export type QueryAllUserApplicationsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryUserApplicationsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryUserApplicationArgs = {
  id: Scalars['Int'];
};

export type QueryAllUserApplicationFieldsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryAllUserApplicationTypesArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryUserApplicationTypesArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryUserApplicationTypeArgs = {
  id: Scalars['Int'];
};

export type QueryAllUserDocumentsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryUserDocumentsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryUserDocumentArgs = {
  id: Scalars['Int'];
};

export type QueryAllUserGroupsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryUserGroupsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryUserGroupArgs = {
  id: Scalars['Int'];
};

export type QueryAllUserKidsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryUserKidsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryUserKidArgs = {
  id: Scalars['Int'];
};

export type QueryAllUserMatchEventSubscribersArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryAllUserSocialAccountsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryUserSocialAccountsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryUserSocialAccountArgs = {
  id: Scalars['Int'];
};

export type QueryAllUserSubscribersArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryUserSubscribersArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryUserSubscriberArgs = {
  id: Scalars['String'];
};

export type QueryAllUserSubscribersLogsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryUserSubscribersLogsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryUserSubscribersLogArgs = {
  id: Scalars['Int'];
};

export type QueryAllUserSubscriptionsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryUserSubscriptionsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryUserSubscriptionArgs = {
  id: Scalars['Int'];
};

export type QueryAllUserFieldsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryMyMatchEventSubscriptionsArgs = {
  deviceId: Scalars['Int'];
};

export type QueryAllUsersArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryUsersArgs = {
  where?: Maybe<UserWhereInput>;
  sort?: Maybe<DefaultSortInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type QueryUserArgs = {
  id: Scalars['Int'];
};

export type QueryAllUserDevicesArgs = {
  search?: Maybe<Scalars['String']>;
  cursor?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  include?: Maybe<UserDeviceWhereInput>;
  exclude?: Maybe<UserDeviceWhereInput>;
  ranges?: Maybe<Array<Scalars['String']>>;
  sort?: Maybe<Array<DefaultSortInput>>;
};

export type QueryUserDevicesByUserIdArgs = {
  userId: Scalars['Int'];
};

export type QueryUserLocationsByUserIdArgs = {
  userId: Scalars['Int'];
};

export type QueryCheckUserArgs = {
  data: CheckUserInput;
};

export type QueryNewUsersWidgetArgs = {
  dates?: Maybe<Array<Scalars['String']>>;
};

export type QueryAdsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryAdArgs = {
  id: Scalars['String'];
};

export type QueryAllArticlePhotosArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryAllArticleVideosArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryAllArticleVideoStreamsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryAllArticleMediaArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryAllArticleStoriesArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryAllArticleNewsArgs = {
  socials: Array<ArticleSocials>;
  query?: Maybe<DefaultQueryInput>;
};

export type QueryCheckFanIdArgs = {
  fanId: Array<Scalars['String']>;
};

export type QueryAllMyEventOrdersArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryMyEventOrderArgs = {
  id: Scalars['Int'];
};

export type QueryEventSectorsArgs = {
  eventId: Scalars['Int'];
  sectorId?: Maybe<Array<Scalars['Int']>>;
};

export type QueryEventSectorArgs = {
  eventId: Scalars['Int'];
  sectorId: Scalars['Int'];
};

export type QueryEventSectorSeatsArgs = {
  eventId: Scalars['Int'];
  sectorId: Scalars['Int'];
  sectorKey?: Maybe<Scalars['String']>;
};

export type QueryAllMyFoodOrdersArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryMyFoodOrderArgs = {
  id: Scalars['Int'];
};

export type QueryAllFoodItemsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryFoodItemArgs = {
  id: Scalars['Int'];
};

export type QueryAllMyLoyaltyOrdersArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryAllLoyaltyOrdersArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryAllLoyaltyItemsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryLoyaltyItemArgs = {
  id: Scalars['Int'];
};

export type QueryCheckPromocodeArgs = {
  promoId: Scalars['Int'];
  code: Scalars['String'];
};

export type QueryNearestTournamentMatchesArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QueryAllActiveTournamentMatchesArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type QuerySeasonTeamMembersArgs = {
  teamId: Scalars['Int'];
  seasonId?: Maybe<Scalars['Int']>;
};

export type QuerySeasonTableArgs = {
  seasonId: Scalars['Int'];
};

export type QueryAllWebTranslationsArgs = {
  locale: Scalars['String'];
};

export type QueryWebTranslationsUpdatedAtArgs = {
  locale: Scalars['String'];
};

export type QueryAllMySubscriptionsArgs = {
  query?: Maybe<DefaultQueryInput>;
};

export type Quiz = {
  __typename?: 'Quiz';
  id: Scalars['Int'];
  clientId: Scalars['Int'];
  autoCreated?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  coverImageUrl?: Maybe<Scalars['String']>;
  maxAttempts: Scalars['Int'];
  startedAt?: Maybe<Scalars['DateTime']>;
  finishedAt?: Maybe<Scalars['DateTime']>;
  type: QuizType;
  state: QuizState;
  questions: Array<QuizQuestion>;
  attempts: Array<QuizAttempt>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type QuizAttempt = {
  __typename?: 'QuizAttempt';
  id: Scalars['Int'];
  quizId: Scalars['Int'];
  userId: Scalars['Int'];
  startedAt: Scalars['DateTime'];
  finishedAt?: Maybe<Scalars['DateTime']>;
  answered: Scalars['Int'];
  totalQuestions: Scalars['Int'];
  totalCorrectAnswers: Scalars['Int'];
  answers: Array<QuizAttemptAnswer>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type QuizAttemptAnswer = {
  __typename?: 'QuizAttemptAnswer';
  id: Scalars['Int'];
  attemptId: Scalars['Int'];
  userId: Scalars['Int'];
  questionId: Scalars['Int'];
  questionAnswerId: Scalars['Int'];
  isCorrect?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type QuizAttemptAnswerConnection = DefaultConnection & {
  __typename?: 'QuizAttemptAnswerConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<QuizAttemptAnswer>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type QuizAttemptAnswerCreateInput = {
  attemptId: Scalars['Int'];
  questionId: Scalars['Int'];
  questionAnswerId: Scalars['Int'];
};

export type QuizAttemptConnection = DefaultConnection & {
  __typename?: 'QuizAttemptConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<QuizAttempt>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type QuizConnection = DefaultConnection & {
  __typename?: 'QuizConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<Quiz>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type QuizCreateInput = {
  autoCreated?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  coverImageUrl?: Maybe<Scalars['String']>;
  maxAttempts?: Maybe<Scalars['Int']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  finishedAt?: Maybe<Scalars['DateTime']>;
  type: QuizType;
  state?: Maybe<QuizState>;
};

export type QuizQuestion = {
  __typename?: 'QuizQuestion';
  id: Scalars['Int'];
  quizId: Scalars['Int'];
  title: Scalars['String'];
  text?: Maybe<Scalars['String']>;
  images?: Maybe<Scalars['JSON']>;
  isMultiple: Scalars['Boolean'];
  sort: Scalars['Int'];
  answers: Array<QuizQuestionAnswer>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  /** Поле добавлено в шлзе специально для iOS. там были проблемы с форматом JSON */
  imagesArray?: Maybe<Array<Scalars['String']>>;
};

export type QuizQuestionAnswer = {
  __typename?: 'QuizQuestionAnswer';
  id: Scalars['Int'];
  questionId: Scalars['Int'];
  text?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  isCorrect?: Maybe<Scalars['Boolean']>;
  sort: Scalars['Int'];
  stats?: Maybe<Scalars['Float']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type QuizQuestionAnswerConnection = DefaultConnection & {
  __typename?: 'QuizQuestionAnswerConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<QuizQuestionAnswer>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type QuizQuestionAnswerCreateInput = {
  questionId: Scalars['Int'];
  text?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  isCorrect?: Maybe<Scalars['Boolean']>;
  sort?: Maybe<Scalars['Int']>;
};

export type QuizQuestionAnswerUpdateInput = {
  questionId?: Maybe<Scalars['Int']>;
  text?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  isCorrect?: Maybe<Scalars['Boolean']>;
  sort?: Maybe<Scalars['Int']>;
};

export type QuizQuestionConnection = DefaultConnection & {
  __typename?: 'QuizQuestionConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<QuizQuestion>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type QuizQuestionCreateInput = {
  quizId: Scalars['Int'];
  title: Scalars['String'];
  text?: Maybe<Scalars['String']>;
  images?: Maybe<Scalars['JSON']>;
  isMultiple: Scalars['Boolean'];
  sort?: Maybe<Scalars['Int']>;
};

export type QuizQuestionUpdateInput = {
  quizId?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  images?: Maybe<Scalars['JSON']>;
  isMultiple?: Maybe<Scalars['Boolean']>;
  sort?: Maybe<Scalars['Int']>;
};

export type QuizResetAttemptsInput = {
  userId?: Maybe<Scalars['Int']>;
  quizId?: Maybe<Scalars['Int']>;
};

export enum QuizState {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  FINISHED = 'FINISHED',
  DELETED = 'DELETED',
}

export enum QuizType {
  QUIZ = 'QUIZ',
  POLL = 'POLL',
}

export type QuizUpdateInput = {
  autoCreated?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  coverImageUrl?: Maybe<Scalars['String']>;
  maxAttempts?: Maybe<Scalars['Int']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  finishedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<QuizType>;
  state?: Maybe<QuizState>;
};

export enum RatingType {
  SEASON = 'season',
  TOTAL = 'total',
  TOUR = 'tour',
}

export type RedirectAnalyticsInput = {
  mailingId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<DatePicker>;
};

export type RefundOrderInput = {
  orderId: Scalars['Int'];
  orderType: PaymentTransactionOrderType;
};

export type Reserve = {
  __typename?: 'Reserve';
  seatName: Scalars['String'];
  seatId: Scalars['Int'];
  sectorId: Scalars['Int'];
  price: Scalars['Float'];
  eventId: Scalars['Int'];
  sector: Scalars['String'];
  row: Scalars['Int'];
  seat: Scalars['Int'];
  priceCategoryId: Scalars['String'];
  discount: ReserveDiscount;
};

export type ReserveDiscount = {
  __typename?: 'ReserveDiscount';
  percent: Scalars['Int'];
  price: Scalars['Float'];
  category: ReserveDiscountCategory;
};

export type ReserveDiscountCategory = {
  __typename?: 'ReserveDiscountCategory';
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type SaveArticleTagsInput = {
  articleId: Scalars['Int'];
  tags: Array<Scalars['String']>;
  isAppend?: Maybe<Scalars['Boolean']>;
};

export type SaveRedirectOnMailInput = {
  hash?: Maybe<Scalars['String']>;
  utmCampaign?: Maybe<Scalars['String']>;
  utmId?: Maybe<Scalars['String']>;
  redirectUrl?: Maybe<Scalars['String']>;
};

export type SaveUserSettingsInput = {
  emailSettings?: Maybe<Scalars['JSON']>;
  defaultGroupId?: Maybe<Scalars['Int']>;
  pushTemplate?: Maybe<Scalars['Int']>;
  emailTemplate?: Maybe<Scalars['Int']>;
  pushTransport?: Maybe<Scalars['Int']>;
  emailTransport?: Maybe<Scalars['Int']>;
  emailSender?: Maybe<Scalars['String']>;
};

/** Модель ответа при необходимости прохождения процедуры 3DSecure. */
export type Secure3DResponse = {
  __typename?: 'Secure3DResponse';
  /** Тип платежного провайдера. */
  provider: PaymentGatewayProvider;
  /** Ответ провайдера. */
  providerResponse?: Maybe<Scalars['JSON']>;
  redirectUrl: Scalars['String'];
};

/** Отправка сообщения пользователю через любой канал */
export type SendNotificationInput = {
  /** Обязательный параметр */
  userId: Scalars['Int'];
  /** При указании шаблона, поля title, text, richText будут проигнорированы */
  templateId?: Maybe<Scalars['Int']>;
  /** При указании канала шаблон будет проигнорирован! Потому что в шаблоне указывается канал */
  channel?: Maybe<MessengerQueueNotificationChannel>;
  /** Отправитель */
  sender?: Maybe<Scalars['String']>;
  /** Данные будут использованы при компиляции title, text, richText из шаблона или сообщения */
  data?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  richText?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  /** Требуется ли процессинг title, text, richText */
  isNeedToProcess?: Maybe<Scalars['Boolean']>;
};

export type SocialAuthData = {
  accessToken: Scalars['String'];
  accessTokenSecret?: Maybe<Scalars['String']>;
  payload?: Maybe<Scalars['JSON']>;
};

export type SocialNetwork = {
  __typename?: 'SocialNetwork';
  id: Scalars['Int'];
  name: Scalars['String'];
  state: SocialNetworkState;
  description?: Maybe<Scalars['String']>;
  logoUrl?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type SocialNetworkConnection = DefaultConnection & {
  __typename?: 'SocialNetworkConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<SocialNetwork>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type SocialNetworkCreateInput = {
  name: Scalars['String'];
  state?: Maybe<SocialNetworkState>;
  description?: Maybe<Scalars['String']>;
  logoUrl?: Maybe<Scalars['String']>;
};

export enum SocialNetworkState {
  INACTIVE = 'INACTIVE',
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
}

export type SocialNetworkUpdateInput = {
  name?: Maybe<Scalars['String']>;
  state?: Maybe<SocialNetworkState>;
  description?: Maybe<Scalars['String']>;
  logoUrl?: Maybe<Scalars['String']>;
};

export type SocialNewsSetting = {
  __typename?: 'SocialNewsSetting';
  id: Scalars['Int'];
  clientId: Scalars['Int'];
  socialNetworkId: Scalars['Int'];
  url: Scalars['String'];
  entityType: SocialNewsSettingEntityType;
  entityId: Scalars['Int'];
  articleCategoryId: Scalars['Int'];
  localeId: Scalars['Int'];
  settings?: Maybe<Scalars['JSON']>;
  username?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  state: SocialNewsSettingState;
  error?: Maybe<Scalars['JSON']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type SocialNewsSettingConnection = DefaultConnection & {
  __typename?: 'SocialNewsSettingConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<SocialNewsSetting>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type SocialNewsSettingCreateInput = {
  socialNetworkId: Scalars['Int'];
  url: Scalars['String'];
  entityType: SocialNewsSettingEntityType;
  entityId: Scalars['Int'];
  articleCategoryId: Scalars['Int'];
  localeId: Scalars['Int'];
  settings?: Maybe<Scalars['JSON']>;
  username?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  state?: Maybe<SocialNewsSettingState>;
  error?: Maybe<Scalars['JSON']>;
};

export enum SocialNewsSettingEntityType {
  TEAM = 'TEAM',
  TEAM_MEMBER = 'TEAM_MEMBER',
  TOURNAMENT = 'TOURNAMENT',
}

export enum SocialNewsSettingState {
  INACTIVE = 'INACTIVE',
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
}

export type SocialNewsSettingUpdateInput = {
  socialNetworkId?: Maybe<Scalars['Int']>;
  url?: Maybe<Scalars['String']>;
  entityType?: Maybe<SocialNewsSettingEntityType>;
  entityId?: Maybe<Scalars['Int']>;
  articleCategoryId?: Maybe<Scalars['Int']>;
  localeId?: Maybe<Scalars['Int']>;
  settings?: Maybe<Scalars['JSON']>;
  username?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  state?: Maybe<SocialNewsSettingState>;
  error?: Maybe<Scalars['JSON']>;
};

/** Профиль из социальной сети */
export type SocialProfile = {
  __typename?: 'SocialProfile';
  id: Scalars['Int'];
  userId?: Maybe<Scalars['Int']>;
  type?: Maybe<SocialProfileTypeEnum>;
  data?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

/** Тип профиля из социальной сети */
export enum SocialProfileTypeEnum {
  VK = 'vk',
  INSTAGRAM = 'instagram',
  FACEBOOK = 'facebook',
  TWITTER = 'twitter',
  OK = 'ok',
}

/** Направление сортировки */
export enum SortingDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}

export type Stadium = {
  __typename?: 'Stadium';
  name: Scalars['String'];
  year: Scalars['Int'];
  volume: Scalars['Int'];
  stadiumImage: Scalars['String'];
  awards: Array<Scalars['String']>;
  aboutStadium: Scalars['String'];
  excursion: Excursion;
};

export type SubscribeToMatchEventsInput = {
  deviceId: Scalars['Int'];
  subscription: Array<SubscribeToMatchEventsSubscriptionInput>;
};

export type SubscribeToMatchEventsSubscriptionInput = {
  eventName: TournamentMatchEventEvent;
  teamIdx: Array<Scalars['Int']>;
};

export type SubscriptionPushInput = {
  subscriptionId: Array<Scalars['Int']>;
  modifierId?: Maybe<Array<Scalars['Int']>>;
  templateId?: Maybe<Scalars['Int']>;
  transportId?: Maybe<Scalars['Int']>;
  data?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

export type SystemAsyncTask = {
  __typename?: 'SystemAsyncTask';
  id: Scalars['Int'];
  clientId: Scalars['Int'];
  entity: SystemAsyncTaskEntity;
  entityId: Scalars['String'];
  operation: Scalars['String'];
  state: SystemAsyncTaskState;
  progress: Scalars['JSON'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type SystemAsyncTaskConnection = DefaultConnection & {
  __typename?: 'SystemAsyncTaskConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<SystemAsyncTask>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export enum SystemAsyncTaskEntity {
  SUBSCRIPTION = 'SUBSCRIPTION',
  MESSENGER_QUEUE = 'MESSENGER_QUEUE',
  EVENT_WHITELIST = 'EVENT_WHITELIST',
  USER_GROUP = 'USER_GROUP',
  USER = 'USER',
}

export enum SystemAsyncTaskState {
  NEW = 'NEW',
  PROCESSING = 'PROCESSING',
  FINISHED = 'FINISHED',
  CANCELLED = 'CANCELLED',
}

export type SystemAsyncTaskUpdateInput = {
  entity?: Maybe<SystemAsyncTaskEntity>;
  entityId?: Maybe<Scalars['String']>;
  operation?: Maybe<Scalars['String']>;
  state?: Maybe<SystemAsyncTaskState>;
  progress?: Maybe<Scalars['JSON']>;
};

export type SystemCountry = {
  __typename?: 'SystemCountry';
  id: Scalars['Int'];
  name: Scalars['String'];
  iso2: Scalars['String'];
  iso3: Scalars['String'];
  phoneCode: Scalars['String'];
  icon: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type SystemLocale = {
  __typename?: 'SystemLocale';
  id: Scalars['Int'];
  iso: Scalars['String'];
  title: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type SystemLocaleConnection = DefaultConnection & {
  __typename?: 'SystemLocaleConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<SystemLocale>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type SystemLogin = {
  __typename?: 'SystemLogin';
  currentUser: SystemUser;
  accessToken?: Maybe<Scalars['String']>;
  refreshToken?: Maybe<Scalars['String']>;
  clients?: Maybe<Array<Client>>;
  clientId?: Maybe<Scalars['Int']>;
};

export type SystemPrinter = {
  __typename?: 'SystemPrinter';
  id: Scalars['Int'];
  clientId: Scalars['Int'];
  name: Scalars['String'];
  title: Scalars['String'];
  model: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type SystemPrinterConnection = DefaultConnection & {
  __typename?: 'SystemPrinterConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<SystemPrinter>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type SystemPrinterCreateInput = {
  name: Scalars['String'];
  title: Scalars['String'];
  model: Scalars['String'];
};

export type SystemPrinterUpdateInput = {
  name?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
};

export type SystemTask = {
  __typename?: 'SystemTask';
  id: Scalars['UUID'];
  name: Scalars['String'];
  state: SystemTaskState;
  errors?: Maybe<Scalars['JSON']>;
  payload?: Maybe<Scalars['JSON']>;
  settings?: Maybe<Scalars['JSON']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type SystemTaskConnection = DefaultConnection & {
  __typename?: 'SystemTaskConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<SystemTask>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type SystemTaskCreateInput = {
  name: Scalars['String'];
  state?: Maybe<SystemTaskState>;
  errors?: Maybe<Scalars['JSON']>;
  payload?: Maybe<Scalars['JSON']>;
  settings?: Maybe<Scalars['JSON']>;
};

export enum SystemTaskState {
  PROCESSING = 'PROCESSING',
  DONE = 'DONE',
  CANCELLED = 'CANCELLED',
}

export type SystemTaskUpdateInput = {
  name?: Maybe<Scalars['String']>;
  state?: Maybe<SystemTaskState>;
  errors?: Maybe<Scalars['JSON']>;
  payload?: Maybe<Scalars['JSON']>;
  settings?: Maybe<Scalars['JSON']>;
};

export type SystemTranslation = {
  __typename?: 'SystemTranslation';
  id: Scalars['Int'];
  locale: Scalars['String'];
  key: Scalars['String'];
  value: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type SystemTranslationConnection = DefaultConnection & {
  __typename?: 'SystemTranslationConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<SystemTranslation>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type SystemTranslationCreateInput = {
  locale: Scalars['String'];
  key: Scalars['String'];
  value: Scalars['String'];
};

export type SystemTranslationSaveInput = {
  key: Scalars['String'];
  value: Scalars['String'];
  locale: Scalars['String'];
};

export type SystemTranslationUpdateInput = {
  locale?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type SystemUser = {
  __typename?: 'SystemUser';
  id: Scalars['ID'];
  state: SystemUserState;
  email: Scalars['String'];
  username: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  locale: LocaleEnum;
  clients?: Maybe<Array<Client>>;
  ACL?: Maybe<Array<Acl>>;
};

export type SystemUserGroup = {
  __typename?: 'SystemUserGroup';
  id: Scalars['ID'];
  state: SystemUserState;
  name: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

/** Информация для пагинации. */
export type SystemUserGroupsConnection = {
  __typename?: 'SystemUserGroupsConnection';
  total: Scalars['Int'];
  cursor: Scalars['Int'];
  limit: Scalars['Int'];
  sortable?: Maybe<Array<Scalars['String']>>;
  edges: Array<SystemUserGroup>;
};

export enum SystemUserState {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  BLOCKED = 'BLOCKED',
}

/** Информация для пагинации. */
export type SystemUsersConnection = {
  __typename?: 'SystemUsersConnection';
  total: Scalars['Int'];
  cursor: Scalars['Int'];
  limit: Scalars['Int'];
  sortable?: Maybe<Array<Scalars['String']>>;
  edges: Array<SystemUser>;
};

/** Поля отбора пользователей. */
export type SystemUsersWhereInput = {
  id?: Maybe<Array<Scalars['ID']>>;
  state?: Maybe<Array<Maybe<SystemUserState>>>;
  createdAt?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
};

export type SystemWebHook = {
  __typename?: 'SystemWebHook';
  id: Scalars['Int'];
  clientId?: Maybe<Scalars['Int']>;
  title: Scalars['String'];
  entity: SystemWebHookEntity;
  operation: SystemWebHookOperation;
  url: Scalars['String'];
  headers?: Maybe<Scalars['JSON']>;
  payload?: Maybe<Scalars['JSON']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type SystemWebHookConnection = DefaultConnection & {
  __typename?: 'SystemWebHookConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<SystemWebHook>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type SystemWebHookCreateInput = {
  title: Scalars['String'];
  entity: SystemWebHookEntity;
  operation: SystemWebHookOperation;
  url: Scalars['String'];
  headers?: Maybe<Scalars['JSON']>;
  payload?: Maybe<Scalars['JSON']>;
};

export enum SystemWebHookEntity {
  EVENT = 'EVENT',
  ORDER = 'ORDER',
  USER = 'USER',
  TICKET = 'TICKET',
}

export enum SystemWebHookOperation {
  IMPORT = 'IMPORT',
  LOAD = 'LOAD',
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
  SYNC = 'SYNC',
}

export type SystemWebHookPayloadInput = {
  /** Параметры в строке запроса */
  params?: Maybe<Scalars['JSON']>;
  /** Данные в теле запроса */
  body?: Maybe<Scalars['JSON']>;
};

export type SystemWebHookUpdateInput = {
  title?: Maybe<Scalars['String']>;
  entity?: Maybe<SystemWebHookEntity>;
  operation?: Maybe<SystemWebHookOperation>;
  url?: Maybe<Scalars['String']>;
  headers?: Maybe<Scalars['JSON']>;
  payload?: Maybe<Scalars['JSON']>;
};

export enum TaxRateType {
  /** Без НДС. */
  NO_VAT = 'NO_VAT',
  /** НДС 0%. */
  VAT_0 = 'VAT_0',
  /** НДС 10%. */
  VAT_10 = 'VAT_10',
  /** НДС 20%. */
  VAT_20 = 'VAT_20',
  /** Расчетный НДС 10/110. */
  VAT_110 = 'VAT_110',
  /** Расчетный НДС 20/120. */
  VAT_120 = 'VAT_120',
}

/** Тип системы налогообложения. */
export enum TaxationSystemType {
  /** Общая. */
  GENERAL = 'GENERAL',
  /** Упрощенная (доход). */
  SIMPLIFIED_INCOME = 'SIMPLIFIED_INCOME',
  /** Упрощенная (доход минус расход). */
  SIMPLIFIED_INCOME_EXPENSE = 'SIMPLIFIED_INCOME_EXPENSE',
  /** Единый налог на вмененный доход. */
  ENVD = 'ENVD',
  /** Единый сельскохозяйственный налог. */
  ESN = 'ESN',
  /** Патентная. */
  PATENT = 'PATENT',
}

export type TeamMemberStatisticTotal = {
  __typename?: 'TeamMemberStatisticTotal';
  games: Scalars['Int'];
  goals: Scalars['Int'];
  yellowCards: Scalars['Int'];
  redCards: Scalars['Int'];
};

export type TeamMemberStatisticTournament = {
  __typename?: 'TeamMemberStatisticTournament';
  tournament: Tournament;
  tournamentName: Scalars['String'];
  total: TeamMemberStatisticTotal;
};

export type TeamMemberStatistics = {
  __typename?: 'TeamMemberStatistics';
  total: TeamMemberStatisticTotal;
  tournaments: Array<TeamMemberStatisticTournament>;
};

export type TemplateApplePass = {
  __typename?: 'TemplateApplePass';
  id: Scalars['Int'];
  clientId?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  pass?: Maybe<Scalars['JSON']>;
  files?: Maybe<Scalars['JSON']>;
  certificates?: Maybe<Scalars['JSON']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type TemplateApplePassConnection = DefaultConnection & {
  __typename?: 'TemplateApplePassConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<TemplateApplePass>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type TemplateApplePassCreateInput = {
  preview?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  pass?: Maybe<Scalars['JSON']>;
  files?: Maybe<Scalars['JSON']>;
  certificates?: Maybe<Scalars['JSON']>;
};

export type TemplateApplePassUpdateInput = {
  preview?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  pass?: Maybe<Scalars['JSON']>;
  files?: Maybe<Scalars['JSON']>;
  certificates?: Maybe<Scalars['JSON']>;
};

export type TemplateNotification = {
  __typename?: 'TemplateNotification';
  id: Scalars['Int'];
  clientId: Scalars['Int'];
  state: TemplateNotificationState;
  channel: TemplateNotificationChannel;
  sender: Scalars['String'];
  name: Scalars['String'];
  title: Scalars['String'];
  richText?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  preview?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  externalTemplateId?: Maybe<Scalars['String']>;
  providerId?: Maybe<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export enum TemplateNotificationChannel {
  EMAIL = 'EMAIL',
  SERVICE_EMAIL = 'SERVICE_EMAIL',
  SMS = 'SMS',
  PUSH = 'PUSH',
  TELEGRAM = 'TELEGRAM',
  SLACK = 'SLACK',
}

export type TemplateNotificationConnection = DefaultConnection & {
  __typename?: 'TemplateNotificationConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<TemplateNotification>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type TemplateNotificationCreateInput = {
  state?: Maybe<TemplateNotificationState>;
  channel: TemplateNotificationChannel;
  sender: Scalars['String'];
  name: Scalars['String'];
  title: Scalars['String'];
  richText?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  preview?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  externalTemplateId?: Maybe<Scalars['String']>;
  providerId?: Maybe<Scalars['Int']>;
};

export enum TemplateNotificationState {
  INACTIVE = 'INACTIVE',
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
}

export type TemplateNotificationUpdateInput = {
  state?: Maybe<TemplateNotificationState>;
  channel?: Maybe<TemplateNotificationChannel>;
  sender?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  richText?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  preview?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  externalTemplateId?: Maybe<Scalars['String']>;
  providerId?: Maybe<Scalars['Int']>;
};

export type TemplatePdf = {
  __typename?: 'TemplatePDF';
  id: Scalars['Int'];
  clientId: Scalars['Int'];
  name: Scalars['String'];
  preview?: Maybe<Scalars['String']>;
  body: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type TemplatePdfConnection = DefaultConnection & {
  __typename?: 'TemplatePDFConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<TemplatePdf>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type TemplatePdfCreateInput = {
  name: Scalars['String'];
  preview?: Maybe<Scalars['String']>;
  body: Scalars['String'];
};

export type TemplatePdfUpdateInput = {
  name?: Maybe<Scalars['String']>;
  preview?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
};

export type TemplateProvider = {
  __typename?: 'TemplateProvider';
  id: Scalars['Int'];
  clientId: Scalars['Int'];
  state: TemplateProviderState;
  name: Scalars['String'];
  settings?: Maybe<Scalars['JSON']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type TemplateProviderConnection = DefaultConnection & {
  __typename?: 'TemplateProviderConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<TemplateProvider>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export enum TemplateProviderState {
  INACTIVE = 'INACTIVE',
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
}

export type TemplateProviderUpdateInput = {
  state?: Maybe<TemplateProviderState>;
  name?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['JSON']>;
};

export type TicketSaleWidget = {
  __typename?: 'TicketSaleWidget';
  total: Scalars['Int'];
  event?: Maybe<Event>;
};

export type Tournament = {
  __typename?: 'Tournament';
  id: Scalars['Int'];
  clientId: Scalars['Int'];
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  preview?: Maybe<Scalars['String']>;
  sport: TournamentSport;
  state: TournamentState;
  system: TournamentSystem;
  sort?: Maybe<Scalars['Int']>;
  meta?: Maybe<Scalars['JSON']>;
  seasons: Array<TournamentSeason>;
  currentSeason?: Maybe<TournamentSeason>;
  data: Array<ExtensionData>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  /** Иконка кубка */
  cupIcon: Scalars['String'];
  /** Логотип турнира */
  logo?: Maybe<Scalars['String']>;
  currentSeasonTable: Array<TournamentSeasonTeamStat>;
};

export type TournamentConnection = DefaultConnection & {
  __typename?: 'TournamentConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<Tournament>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type TournamentCreateInput = {
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  preview?: Maybe<Scalars['String']>;
  sport: TournamentSport;
  state: TournamentState;
  system: TournamentSystem;
  sort?: Maybe<Scalars['Int']>;
  meta?: Maybe<Scalars['JSON']>;
  data?: Maybe<Array<ExtensionUpdateDataInput>>;
};

export type TournamentDispute = {
  __typename?: 'TournamentDispute';
  id: Scalars['Int'];
  state: TournamentDisputeState;
  result: TournamentDisputeResult;
  confirmation: TournamentDisputeConfirmation;
  userId: Scalars['Int'];
  matchId: Scalars['Int'];
  amount: Scalars['Int'];
  winAmount: Scalars['Int'];
  text: Scalars['String'];
  user: User;
  match: TournamentMatch;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export enum TournamentDisputeConfirmation {
  WAITING = 'WAITING',
  WIN = 'WIN',
  LOSS = 'LOSS',
}

export type TournamentDisputeConnection = DefaultConnection & {
  __typename?: 'TournamentDisputeConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<TournamentDispute>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type TournamentDisputeCreateInput = {
  state?: Maybe<TournamentDisputeState>;
  result?: Maybe<TournamentDisputeResult>;
  confirmation?: Maybe<TournamentDisputeConfirmation>;
  userId: Scalars['Int'];
  matchId: Scalars['Int'];
  amount: Scalars['Int'];
  winAmount?: Maybe<Scalars['Int']>;
  text: Scalars['String'];
};

export type TournamentDisputeResponse = {
  __typename?: 'TournamentDisputeResponse';
  id: Scalars['Int'];
  disputeId: Scalars['Int'];
  state: TournamentDisputeResponseState;
  userId: Scalars['Int'];
  amount: Scalars['Int'];
  comment: Scalars['String'];
  respondedAt: Scalars['DateTime'];
  user: User;
  dispute: TournamentDispute;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type TournamentDisputeResponseConnection = DefaultConnection & {
  __typename?: 'TournamentDisputeResponseConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<TournamentDisputeResponse>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type TournamentDisputeResponseCreateInput = {
  disputeId: Scalars['Int'];
  state?: Maybe<TournamentDisputeResponseState>;
  userId: Scalars['Int'];
  amount: Scalars['Int'];
  comment: Scalars['String'];
  respondedAt?: Maybe<Scalars['DateTime']>;
};

export enum TournamentDisputeResponseState {
  ACTIVE = 'ACTIVE',
  WIN = 'WIN',
  LOSS = 'LOSS',
  WAITING = 'WAITING',
}

export type TournamentDisputeResponseUpdateInput = {
  disputeId?: Maybe<Scalars['Int']>;
  state?: Maybe<TournamentDisputeResponseState>;
  userId?: Maybe<Scalars['Int']>;
  amount?: Maybe<Scalars['Int']>;
  comment?: Maybe<Scalars['String']>;
  respondedAt?: Maybe<Scalars['DateTime']>;
};

export enum TournamentDisputeResult {
  WIN = 'WIN',
  LOSS = 'LOSS',
  CANCELLED = 'CANCELLED',
  MODERATION = 'MODERATION',
  WAITING = 'WAITING',
}

export enum TournamentDisputeState {
  ACTIVE = 'ACTIVE',
  LOCKED = 'LOCKED',
  FINISHED = 'FINISHED',
  DELETED = 'DELETED',
  ARCHIVED = 'ARCHIVED',
}

export type TournamentDisputeUpdateInput = {
  state?: Maybe<TournamentDisputeState>;
  result?: Maybe<TournamentDisputeResult>;
  confirmation?: Maybe<TournamentDisputeConfirmation>;
  userId?: Maybe<Scalars['Int']>;
  matchId?: Maybe<Scalars['Int']>;
  amount?: Maybe<Scalars['Int']>;
  winAmount?: Maybe<Scalars['Int']>;
  text?: Maybe<Scalars['String']>;
};

export type TournamentDisqualification = {
  __typename?: 'TournamentDisqualification';
  id: Scalars['Int'];
  playerId: Scalars['Int'];
  reason: Scalars['String'];
  tournamentId: Scalars['Int'];
  seasonId: Scalars['Int'];
  resolutionDate: Scalars['DateTime'];
  /** Величина дисквалификации (кол-во матчей) */
  disqualificationValue: Scalars['Int'];
  leftMatches: Scalars['Int'];
  state: TournamentDisqualificationState;
  meta?: Maybe<Scalars['JSON']>;
  player: TournamentTeamMember;
  tournament: Tournament;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type TournamentDisqualificationConnection = DefaultConnection & {
  __typename?: 'TournamentDisqualificationConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<TournamentDisqualification>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type TournamentDisqualificationCreateInput = {
  playerId: Scalars['Int'];
  reason: Scalars['String'];
  tournamentId: Scalars['Int'];
  seasonId: Scalars['Int'];
  resolutionDate: Scalars['DateTime'];
  disqualificationValue: Scalars['Int'];
  leftMatches: Scalars['Int'];
  state: TournamentDisqualificationState;
  meta?: Maybe<Scalars['JSON']>;
};

export enum TournamentDisqualificationState {
  CURRENT = 'CURRENT',
  FINISH = 'FINISH',
}

export type TournamentDisqualificationUpdateInput = {
  playerId?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  tournamentId?: Maybe<Scalars['Int']>;
  seasonId?: Maybe<Scalars['Int']>;
  resolutionDate?: Maybe<Scalars['DateTime']>;
  disqualificationValue?: Maybe<Scalars['Int']>;
  leftMatches?: Maybe<Scalars['Int']>;
  state?: Maybe<TournamentDisqualificationState>;
  meta?: Maybe<Scalars['JSON']>;
};

export type TournamentFieldsConnection = DefaultConnection & {
  __typename?: 'TournamentFieldsConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<ExtensionField>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type TournamentGroup = {
  __typename?: 'TournamentGroup';
  id: Scalars['Int'];
  name: Scalars['String'];
  key?: Maybe<Scalars['String']>;
  seasonId: Scalars['Int'];
  stage: TournamentGroupStage;
  sort: Scalars['Int'];
  season: TournamentSeason;
  matches: Array<TournamentMatch>;
  teamStats: Array<TournamentSeasonTeamStat>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type TournamentGroupConnection = DefaultConnection & {
  __typename?: 'TournamentGroupConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<TournamentGroup>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type TournamentGroupCreateInput = {
  name: Scalars['String'];
  key?: Maybe<Scalars['String']>;
  seasonId: Scalars['Int'];
  stage?: Maybe<TournamentGroupStage>;
  sort?: Maybe<Scalars['Int']>;
};

export enum TournamentGroupStage {
  QUALIFICATION = 'QUALIFICATION',
  GROUPS = 'GROUPS',
  PLAY_OFF = 'PLAY_OFF',
  UNKNOWN = 'UNKNOWN',
}

export type TournamentGroupUpdateInput = {
  name?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  seasonId?: Maybe<Scalars['Int']>;
  stage?: Maybe<TournamentGroupStage>;
  sort?: Maybe<Scalars['Int']>;
};

export enum TournamentInstanceType {
  TEAM_MEMBER = 'TEAM_MEMBER',
  TEAM = 'TEAM',
  MATCH = 'MATCH',
}

export type TournamentMatch = {
  __typename?: 'TournamentMatch';
  id: Scalars['Int'];
  team1Id: Scalars['Int'];
  team2Id: Scalars['Int'];
  title?: Maybe<Scalars['String']>;
  team1IdGoals?: Maybe<Scalars['Int']>;
  team2IdGoals?: Maybe<Scalars['Int']>;
  team1IdShootoutGoals?: Maybe<Scalars['Int']>;
  team2IdShootoutGoals?: Maybe<Scalars['Int']>;
  isOvertime: Scalars['Boolean'];
  isPenaltyShootout: Scalars['Boolean'];
  seasonId: Scalars['Int'];
  groupId?: Maybe<Scalars['Int']>;
  tourId?: Maybe<Scalars['Int']>;
  state: TournamentMatchState;
  meta?: Maybe<Scalars['JSON']>;
  videoStreams?: Maybe<Scalars['JSON']>;
  stadiumName?: Maybe<Scalars['String']>;
  stadiumAddress?: Maybe<Scalars['String']>;
  stadium?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  startTime?: Maybe<Scalars['DateTime']>;
  finishDate: Scalars['DateTime'];
  startedAt?: Maybe<Scalars['DateTime']>;
  finishedAt?: Maybe<Scalars['DateTime']>;
  result?: Maybe<Scalars['String']>;
  team1: TournamentTeam;
  team2: TournamentTeam;
  tour: TournamentTour;
  season: TournamentSeason;
  group?: Maybe<TournamentGroup>;
  matchEvents: Array<TournamentMatchEvent>;
  matchStats: Array<TournamentMatchStat>;
  playerStat?: Maybe<TournamentPlayerStat>;
  disputes: Array<TournamentDispute>;
  disputeResponses: Array<TournamentDisputeResponse>;
  data: Array<ExtensionData>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  isCSKAWin?: Maybe<Scalars['Boolean']>;
  eventId?: Maybe<Scalars['Int']>;
  isMatchDayEnabled?: Maybe<Scalars['Boolean']>;
  event?: Maybe<Event>;
};

export type TournamentMatchConnection = DefaultConnection & {
  __typename?: 'TournamentMatchConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<TournamentMatch>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type TournamentMatchCreateInput = {
  team1Id: Scalars['Int'];
  team2Id: Scalars['Int'];
  title?: Maybe<Scalars['String']>;
  team1IdGoals?: Maybe<Scalars['Int']>;
  team2IdGoals?: Maybe<Scalars['Int']>;
  team1IdShootoutGoals?: Maybe<Scalars['Int']>;
  team2IdShootoutGoals?: Maybe<Scalars['Int']>;
  isOvertime?: Maybe<Scalars['Boolean']>;
  isPenaltyShootout?: Maybe<Scalars['Boolean']>;
  seasonId: Scalars['Int'];
  groupId?: Maybe<Scalars['Int']>;
  tourId?: Maybe<Scalars['Int']>;
  state: TournamentMatchState;
  meta?: Maybe<Scalars['JSON']>;
  videoStreams?: Maybe<Scalars['JSON']>;
  stadiumName?: Maybe<Scalars['String']>;
  stadiumAddress?: Maybe<Scalars['String']>;
  stadium?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  startTime?: Maybe<Scalars['DateTime']>;
  finishDate: Scalars['DateTime'];
  startedAt?: Maybe<Scalars['DateTime']>;
  finishedAt?: Maybe<Scalars['DateTime']>;
  result?: Maybe<Scalars['String']>;
  data?: Maybe<Array<ExtensionUpdateDataInput>>;
};

export enum TournamentMatchDataType {
  PROTOCOL = 'PROTOCOL',
  EVENT = 'EVENT',
  LINEUP = 'LINEUP',
  ALL = 'ALL',
}

export type TournamentMatchDay = {
  __typename?: 'TournamentMatchDay';
  tournamentMatch?: Maybe<TournamentMatch>;
  program?: Maybe<Article>;
  navigation: TournamentMatchDayNavigation;
  menu: Array<Scalars['String']>;
  activities: Array<TournamentMatchDayActivity>;
  partnerQuiz?: Maybe<TournamentMatchDayPartnerQuiz>;
  schema: TournamentMatchDaySchema;
  stadiumMessagePrice: Scalars['Float'];
};

export type TournamentMatchDayActivity = {
  __typename?: 'TournamentMatchDayActivity';
  id: Scalars['Int'];
  title: Scalars['String'];
  /** Этаж */
  floor?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  altIcon?: Maybe<Scalars['String']>;
  coverImageUrl?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  action?: Maybe<TournamentMatchDayActivityAction>;
  coords?: Maybe<Array<Scalars['Float']>>;
};

export type TournamentMatchDayActivityAction = {
  __typename?: 'TournamentMatchDayActivityAction';
  title: Scalars['String'];
  action: Scalars['String'];
};

export type TournamentMatchDayNavigation = {
  __typename?: 'TournamentMatchDayNavigation';
  entrances: Array<TournamentMatchDayNavigationEntrance>;
  parkings: Array<Maybe<TournamentMatchDayNavigationParking>>;
  metro: Array<TournamentMatchDayNavigationMetro>;
  stadiumNavigationInstructions: Array<TournamentMatchDayNavigationStadiumNavigationInstruction>;
  navigationToSector: Array<TournamentMatchDayNavigationNavigationToSector>;
  tribunes: Array<TournamentMatchDayNavigationTribune>;
};

export type TournamentMatchDayNavigationEntrance = {
  __typename?: 'TournamentMatchDayNavigationEntrance';
  id: Scalars['Int'];
  name: Scalars['String'];
  label: Scalars['String'];
  coords: Array<Scalars['Float']>;
  icon?: Maybe<Scalars['String']>;
  altIcon?: Maybe<Scalars['String']>;
  sectors?: Maybe<Array<Scalars['String']>>;
};

export type TournamentMatchDayNavigationMetro = {
  __typename?: 'TournamentMatchDayNavigationMetro';
  id: Scalars['Int'];
  name: Scalars['String'];
  coords: Array<Scalars['Float']>;
  icon?: Maybe<Scalars['String']>;
  altIcon?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  entranceId: Array<Scalars['Int']>;
};

export type TournamentMatchDayNavigationNavigationToSector = {
  __typename?: 'TournamentMatchDayNavigationNavigationToSector';
  entranceId: Scalars['Int'];
  sector: Scalars['String'];
  instructions: Array<Scalars['Int']>;
};

export type TournamentMatchDayNavigationParking = {
  __typename?: 'TournamentMatchDayNavigationParking';
  id: Scalars['Int'];
  name: Scalars['String'];
  coords: Array<Scalars['Float']>;
  icon?: Maybe<Scalars['String']>;
  altIcon?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  sectors?: Maybe<Array<Scalars['String']>>;
};

export type TournamentMatchDayNavigationStadiumNavigationInstruction = {
  __typename?: 'TournamentMatchDayNavigationStadiumNavigationInstruction';
  id: Scalars['Int'];
  text: Scalars['String'];
  floor: Scalars['Int'];
  path?: Maybe<TournamentMatchDayNavigationStadiumNavigationInstructionPath>;
};

export type TournamentMatchDayNavigationStadiumNavigationInstructionPath = {
  __typename?: 'TournamentMatchDayNavigationStadiumNavigationInstructionPath';
  startIcon?: Maybe<Scalars['String']>;
  finishIcon?: Maybe<Scalars['String']>;
  lines: Array<TournamentMatchDayNavigationStadiumNavigationInstructionPathLines>;
};

export type TournamentMatchDayNavigationStadiumNavigationInstructionPathLines = {
  __typename?: 'TournamentMatchDayNavigationStadiumNavigationInstructionPathLines';
  coords: Array<Array<Scalars['Float']>>;
  color: Scalars['String'];
};

export type TournamentMatchDayNavigationTribune = {
  __typename?: 'TournamentMatchDayNavigationTribune';
  name: Scalars['String'];
  label: Scalars['String'];
  sectors: Array<Scalars['String']>;
};

export type TournamentMatchDayPartnerQuiz = {
  __typename?: 'TournamentMatchDayPartnerQuiz';
  name: Scalars['String'];
  url: Scalars['String'];
  imageUrl?: Maybe<Scalars['String']>;
};

export type TournamentMatchDaySchema = {
  __typename?: 'TournamentMatchDaySchema';
  secondFloor: Array<TournamentMatchDaySchemaLegend>;
};

export type TournamentMatchDaySchemaLegend = {
  __typename?: 'TournamentMatchDaySchemaLegend';
  name: Scalars['String'];
  color: Scalars['String'];
};

export type TournamentMatchEvent = {
  __typename?: 'TournamentMatchEvent';
  id: Scalars['Int'];
  matchId: Scalars['Int'];
  datetime: Scalars['DateTime'];
  minute: Scalars['Int'];
  additionalTime?: Maybe<Scalars['Int']>;
  event: TournamentMatchEventEvent;
  teamMemberId?: Maybe<Scalars['Int']>;
  teamId?: Maybe<Scalars['Int']>;
  linkedMatchEventId?: Maybe<Scalars['Int']>;
  comment?: Maybe<Scalars['String']>;
  isProtocol: Scalars['Boolean'];
  meta?: Maybe<Scalars['JSON']>;
  match: TournamentMatch;
  player?: Maybe<TournamentTeamMember>;
  team?: Maybe<TournamentTeam>;
  linkedMatchEvent?: Maybe<TournamentMatchEvent>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type TournamentMatchEventConnection = DefaultConnection & {
  __typename?: 'TournamentMatchEventConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<TournamentMatchEvent>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type TournamentMatchEventCreateInput = {
  matchId: Scalars['Int'];
  datetime?: Maybe<Scalars['DateTime']>;
  minute: Scalars['Int'];
  additionalTime?: Maybe<Scalars['Int']>;
  event: TournamentMatchEventEvent;
  teamMemberId?: Maybe<Scalars['Int']>;
  teamId?: Maybe<Scalars['Int']>;
  linkedMatchEventId?: Maybe<Scalars['Int']>;
  comment?: Maybe<Scalars['String']>;
  isProtocol?: Maybe<Scalars['Boolean']>;
  meta?: Maybe<Scalars['JSON']>;
};

export enum TournamentMatchEventEvent {
  AUTO_GOAL = 'AUTO_GOAL',
  CORNER_KICK = 'CORNER_KICK',
  FREE_KICK = 'FREE_KICK',
  GOAL = 'GOAL',
  IN = 'IN',
  MATCH_FINISH = 'MATCH_FINISH',
  MATCH_HALFTIME = 'MATCH_HALFTIME',
  MATCH_START = 'MATCH_START',
  OUT = 'OUT',
  PASS = 'PASS',
  ASSIST = 'ASSIST',
  PENALTY = 'PENALTY',
  PENALTY_NO = 'PENALTY_NO',
  RED_CARD = 'RED_CARD',
  TRAUMA = 'TRAUMA',
  YELLOW_CARD = 'YELLOW_CARD',
  YELLOW_CARD2 = 'YELLOW_CARD2',
  COMMENT = 'COMMENT',
  VAR = 'VAR',
  VIDEO = 'VIDEO',
  FINAL = 'FINAL',
}

export type TournamentMatchEventUpdateInput = {
  matchId?: Maybe<Scalars['Int']>;
  datetime?: Maybe<Scalars['DateTime']>;
  minute?: Maybe<Scalars['Int']>;
  additionalTime?: Maybe<Scalars['Int']>;
  event?: Maybe<TournamentMatchEventEvent>;
  teamMemberId?: Maybe<Scalars['Int']>;
  teamId?: Maybe<Scalars['Int']>;
  linkedMatchEventId?: Maybe<Scalars['Int']>;
  comment?: Maybe<Scalars['String']>;
  isProtocol?: Maybe<Scalars['Boolean']>;
  meta?: Maybe<Scalars['JSON']>;
};

export type TournamentMatchFieldsConnection = DefaultConnection & {
  __typename?: 'TournamentMatchFieldsConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<ExtensionField>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type TournamentMatchLineUp = {
  __typename?: 'TournamentMatchLineUp';
  id: Scalars['Int'];
  matchId: Scalars['Int'];
  teamId?: Maybe<Scalars['Int']>;
  positionId: Scalars['Int'];
  teamMemberId?: Maybe<Scalars['Int']>;
  refereeId?: Maybe<Scalars['Int']>;
  type?: Maybe<TournamentMatchLineUpType>;
  initialRole?: Maybe<TournamentMatchLineUpInitialRole>;
  finalRole?: Maybe<TournamentMatchLineUpFinalRole>;
  initialState?: Maybe<TournamentMatchLineUpInitialState>;
  finalState?: Maybe<TournamentMatchLineUpFinalState>;
  number?: Maybe<Scalars['Int']>;
  isTeamCaptain?: Maybe<Scalars['Boolean']>;
  meta?: Maybe<Scalars['JSON']>;
  teamMember?: Maybe<TournamentTeamMember>;
  team?: Maybe<TournamentTeam>;
  referee?: Maybe<TournamentReferee>;
  position: GenericObject;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type TournamentMatchLineUpCreateInput = {
  matchId: Scalars['Int'];
  teamId?: Maybe<Scalars['Int']>;
  positionId?: Maybe<Scalars['Int']>;
  teamMemberId?: Maybe<Scalars['Int']>;
  refereeId?: Maybe<Scalars['Int']>;
  type?: Maybe<TournamentMatchLineUpType>;
  initialRole?: Maybe<TournamentMatchLineUpInitialRole>;
  finalRole?: Maybe<TournamentMatchLineUpFinalRole>;
  initialState?: Maybe<TournamentMatchLineUpInitialState>;
  finalState?: Maybe<TournamentMatchLineUpFinalState>;
  number?: Maybe<Scalars['Int']>;
  isTeamCaptain?: Maybe<Scalars['Boolean']>;
  meta?: Maybe<Scalars['JSON']>;
};

export enum TournamentMatchLineUpFinalRole {
  DEFENDER = 'DEFENDER',
  HALFBACK = 'HALFBACK',
  GOALKEEPER = 'GOALKEEPER',
  FORWARD = 'FORWARD',
}

export enum TournamentMatchLineUpFinalState {
  MAIN = 'MAIN',
  SUBSTITUTE = 'SUBSTITUTE',
}

export enum TournamentMatchLineUpInitialRole {
  DEFENDER = 'DEFENDER',
  HALFBACK = 'HALFBACK',
  GOALKEEPER = 'GOALKEEPER',
  FORWARD = 'FORWARD',
}

export enum TournamentMatchLineUpInitialState {
  MAIN = 'MAIN',
  SUBSTITUTE = 'SUBSTITUTE',
}

export enum TournamentMatchLineUpType {
  PLAYER = 'PLAYER',
  COACH = 'COACH',
  REPRESENTATIVE = 'REPRESENTATIVE',
  REFEREE = 'REFEREE',
  MEDIC = 'MEDIC',
}

export type TournamentMatchLineUpUpdateInput = {
  matchId?: Maybe<Scalars['Int']>;
  teamId?: Maybe<Scalars['Int']>;
  positionId?: Maybe<Scalars['Int']>;
  teamMemberId?: Maybe<Scalars['Int']>;
  refereeId?: Maybe<Scalars['Int']>;
  type?: Maybe<TournamentMatchLineUpType>;
  initialRole?: Maybe<TournamentMatchLineUpInitialRole>;
  finalRole?: Maybe<TournamentMatchLineUpFinalRole>;
  initialState?: Maybe<TournamentMatchLineUpInitialState>;
  finalState?: Maybe<TournamentMatchLineUpFinalState>;
  number?: Maybe<Scalars['Int']>;
  isTeamCaptain?: Maybe<Scalars['Boolean']>;
  meta?: Maybe<Scalars['JSON']>;
};

export type TournamentMatchProtocol = {
  __typename?: 'TournamentMatchProtocol';
  id: Scalars['Int'];
  matchId: Scalars['Int'];
  minute?: Maybe<Scalars['Int']>;
  event: TournamentMatchProtocolEvent;
  teamMemberId: Scalars['Int'];
  teamId: Scalars['Int'];
  meta?: Maybe<Scalars['JSON']>;
  match: TournamentMatch;
  player?: Maybe<TournamentTeamMember>;
  team?: Maybe<TournamentTeam>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type TournamentMatchProtocolConnection = DefaultConnection & {
  __typename?: 'TournamentMatchProtocolConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<TournamentMatchProtocol>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type TournamentMatchProtocolCreateInput = {
  matchId: Scalars['Int'];
  minute?: Maybe<Scalars['Int']>;
  event: TournamentMatchProtocolEvent;
  teamMemberId: Scalars['Int'];
  teamId: Scalars['Int'];
  meta?: Maybe<Scalars['JSON']>;
};

export enum TournamentMatchProtocolEvent {
  AUTO_GOAL = 'AUTO_GOAL',
  CORNER_KICK = 'CORNER_KICK',
  FREE_KICK = 'FREE_KICK',
  GOAL = 'GOAL',
  IN = 'IN',
  MATCH_FINISH = 'MATCH_FINISH',
  MATCH_HALFTIME = 'MATCH_HALFTIME',
  MATCH_START = 'MATCH_START',
  OUT = 'OUT',
  PASS = 'PASS',
  ASSIST = 'ASSIST',
  PENALTY = 'PENALTY',
  PENALTY_NO = 'PENALTY_NO',
  RED_CARD = 'RED_CARD',
  TRAUMA = 'TRAUMA',
  YELLOW_CARD = 'YELLOW_CARD',
  YELLOW_CARD2 = 'YELLOW_CARD2',
  COMMENT = 'COMMENT',
  VAR = 'VAR',
  VIDEO = 'VIDEO',
  FINAL = 'FINAL',
}

export type TournamentMatchProtocolUpdateInput = {
  matchId?: Maybe<Scalars['Int']>;
  minute?: Maybe<Scalars['Int']>;
  event?: Maybe<TournamentMatchProtocolEvent>;
  teamMemberId?: Maybe<Scalars['Int']>;
  teamId?: Maybe<Scalars['Int']>;
  meta?: Maybe<Scalars['JSON']>;
};

export type TournamentMatchStat = {
  __typename?: 'TournamentMatchStat';
  id: Scalars['Int'];
  matchId: Scalars['Int'];
  team1Id?: Maybe<Scalars['Int']>;
  team2Id?: Maybe<Scalars['Int']>;
  type: TournamentMatchStatType;
  team1Value: Scalars['Int'];
  team2Value: Scalars['Int'];
  team1Comparative: Scalars['Float'];
  team2Comparative: Scalars['Float'];
  sort: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type TournamentMatchStatConnection = DefaultConnection & {
  __typename?: 'TournamentMatchStatConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<TournamentMatchStat>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type TournamentMatchStatCreateInput = {
  matchId: Scalars['Int'];
  team1Id?: Maybe<Scalars['Int']>;
  team2Id?: Maybe<Scalars['Int']>;
  type: TournamentMatchStatType;
  team1Value: Scalars['Int'];
  team2Value: Scalars['Int'];
  team1Comparative?: Maybe<Scalars['Float']>;
  team2Comparative?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['Int']>;
};

export enum TournamentMatchStatType {
  SHOT = 'SHOT',
  SHOTIN = 'SHOTIN',
  FAULT = 'FAULT',
  CORNER = 'CORNER',
  OFFSIDE = 'OFFSIDE',
  POSSESSION = 'POSSESSION',
  BLOCKED_SHOT = 'BLOCKED_SHOT',
  FREE_KICK = 'FREE_KICK',
  GOAL_KICK = 'GOAL_KICK',
  THROW_IN = 'THROW_IN',
  YELLOW_CARDS = 'YELLOW_CARDS',
  RED_CARDS = 'RED_CARDS',
}

export type TournamentMatchStatUpdateInput = {
  matchId?: Maybe<Scalars['Int']>;
  team1Id?: Maybe<Scalars['Int']>;
  team2Id?: Maybe<Scalars['Int']>;
  type?: Maybe<TournamentMatchStatType>;
  team1Value?: Maybe<Scalars['Int']>;
  team2Value?: Maybe<Scalars['Int']>;
  team1Comparative?: Maybe<Scalars['Float']>;
  team2Comparative?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['Int']>;
};

export enum TournamentMatchState {
  PENDING = 'PENDING',
  PROGRESS = 'PROGRESS',
  FINISHED = 'FINISHED',
  HALF_TIME = 'HALF_TIME',
  OVER_TIME = 'OVER_TIME',
  CANCELED = 'CANCELED',
  FIRST_TIME = 'FIRST_TIME',
  SECOND_TIME = 'SECOND_TIME',
  MOVED = 'MOVED',
  PAUSE = 'PAUSE',
  PENALTY_SHOOTOUT = 'PENALTY_SHOOTOUT',
}

export type TournamentMatchUpdateInput = {
  team1Id?: Maybe<Scalars['Int']>;
  team2Id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  team1IdGoals?: Maybe<Scalars['Int']>;
  team2IdGoals?: Maybe<Scalars['Int']>;
  team1IdShootoutGoals?: Maybe<Scalars['Int']>;
  team2IdShootoutGoals?: Maybe<Scalars['Int']>;
  isOvertime?: Maybe<Scalars['Boolean']>;
  isPenaltyShootout?: Maybe<Scalars['Boolean']>;
  seasonId?: Maybe<Scalars['Int']>;
  groupId?: Maybe<Scalars['Int']>;
  tourId?: Maybe<Scalars['Int']>;
  state?: Maybe<TournamentMatchState>;
  meta?: Maybe<Scalars['JSON']>;
  videoStreams?: Maybe<Scalars['JSON']>;
  stadiumName?: Maybe<Scalars['String']>;
  stadiumAddress?: Maybe<Scalars['String']>;
  stadium?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  startTime?: Maybe<Scalars['DateTime']>;
  finishDate?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  finishedAt?: Maybe<Scalars['DateTime']>;
  result?: Maybe<Scalars['String']>;
  data?: Maybe<Array<ExtensionUpdateDataInput>>;
};

export type TournamentPlayerStat = {
  __typename?: 'TournamentPlayerStat';
  id: Scalars['Int'];
  playerId: Scalars['Int'];
  seasonId: Scalars['Int'];
  games: Scalars['Int'];
  goals: Scalars['Int'];
  penaltyKicks: Scalars['Int'];
  passes: Scalars['Int'];
  yellowCards: Scalars['Int'];
  redCards: Scalars['Int'];
  missedGoals: Scalars['Int'];
  shutouts: Scalars['Int'];
  position?: Maybe<Scalars['Int']>;
  bombardierValue: Scalars['Float'];
  assistantValue: Scalars['Float'];
  goalsAndPassesValue: Scalars['Float'];
  goalkeeperValue: Scalars['Float'];
  warningValue: Scalars['Float'];
  meta?: Maybe<Scalars['JSON']>;
  player: TournamentTeamMember;
  season: TournamentSeason;
  allGoals: Scalars['Int'];
  goalsAndPasses: Scalars['Int'];
  avgGoals: Scalars['Float'];
  avgPasses: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type TournamentPlayerStatConnection = DefaultConnection & {
  __typename?: 'TournamentPlayerStatConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<TournamentPlayerStat>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type TournamentPlayerStatCreateInput = {
  playerId: Scalars['Int'];
  seasonId: Scalars['Int'];
  games?: Maybe<Scalars['Int']>;
  goals?: Maybe<Scalars['Int']>;
  penaltyKicks?: Maybe<Scalars['Int']>;
  passes?: Maybe<Scalars['Int']>;
  yellowCards?: Maybe<Scalars['Int']>;
  redCards?: Maybe<Scalars['Int']>;
  missedGoals?: Maybe<Scalars['Int']>;
  shutouts?: Maybe<Scalars['Int']>;
  position?: Maybe<Scalars['Int']>;
  bombardierValue?: Maybe<Scalars['Float']>;
  assistantValue?: Maybe<Scalars['Float']>;
  goalsAndPassesValue?: Maybe<Scalars['Float']>;
  goalkeeperValue?: Maybe<Scalars['Float']>;
  warningValue?: Maybe<Scalars['Float']>;
  meta?: Maybe<Scalars['JSON']>;
  allGoals?: Maybe<Scalars['Int']>;
  goalsAndPasses?: Maybe<Scalars['Int']>;
  avgGoals?: Maybe<Scalars['Float']>;
  avgPasses?: Maybe<Scalars['Float']>;
};

export type TournamentPlayerStatUpdateInput = {
  playerId?: Maybe<Scalars['Int']>;
  seasonId?: Maybe<Scalars['Int']>;
  games?: Maybe<Scalars['Int']>;
  goals?: Maybe<Scalars['Int']>;
  penaltyKicks?: Maybe<Scalars['Int']>;
  passes?: Maybe<Scalars['Int']>;
  yellowCards?: Maybe<Scalars['Int']>;
  redCards?: Maybe<Scalars['Int']>;
  missedGoals?: Maybe<Scalars['Int']>;
  shutouts?: Maybe<Scalars['Int']>;
  position?: Maybe<Scalars['Int']>;
  bombardierValue?: Maybe<Scalars['Float']>;
  assistantValue?: Maybe<Scalars['Float']>;
  goalsAndPassesValue?: Maybe<Scalars['Float']>;
  goalkeeperValue?: Maybe<Scalars['Float']>;
  warningValue?: Maybe<Scalars['Float']>;
  meta?: Maybe<Scalars['JSON']>;
  allGoals?: Maybe<Scalars['Int']>;
  goalsAndPasses?: Maybe<Scalars['Int']>;
  avgGoals?: Maybe<Scalars['Float']>;
  avgPasses?: Maybe<Scalars['Float']>;
};

export type TournamentReferee = {
  __typename?: 'TournamentReferee';
  id: Scalars['Int'];
  typeId: Scalars['Int'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  middleName?: Maybe<Scalars['String']>;
  birthday: Scalars['String'];
  preview?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  type: GenericObject;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type TournamentRefereeConnection = DefaultConnection & {
  __typename?: 'TournamentRefereeConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<TournamentReferee>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type TournamentRefereeCreateInput = {
  typeId?: Maybe<Scalars['Int']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  middleName?: Maybe<Scalars['String']>;
  birthday: Scalars['String'];
  preview?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
};

export type TournamentRefereeUpdateInput = {
  typeId?: Maybe<Scalars['Int']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['String']>;
  preview?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
};

export type TournamentSeason = {
  __typename?: 'TournamentSeason';
  id: Scalars['Int'];
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  tournamentId: Scalars['Int'];
  startDate?: Maybe<Scalars['DateTime']>;
  finishDate?: Maybe<Scalars['DateTime']>;
  regulation?: Maybe<Scalars['String']>;
  state: TournamentSeasonState;
  sort?: Maybe<Scalars['Int']>;
  meta?: Maybe<Scalars['JSON']>;
  tournament: Tournament;
  teams: Array<TournamentTeam>;
  tours: Array<TournamentTour>;
  matches: Array<TournamentMatch>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type TournamentSeasonApplicationForm = {
  __typename?: 'TournamentSeasonApplicationForm';
  id: Scalars['Int'];
  seasonId: Scalars['Int'];
  teamId: Scalars['Int'];
  meta?: Maybe<Scalars['JSON']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type TournamentSeasonApplicationFormConnection = DefaultConnection & {
  __typename?: 'TournamentSeasonApplicationFormConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<TournamentSeasonApplicationForm>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type TournamentSeasonApplicationFormCreateInput = {
  seasonId: Scalars['Int'];
  teamId: Scalars['Int'];
  meta?: Maybe<Scalars['JSON']>;
};

export type TournamentSeasonApplicationFormUpdateInput = {
  seasonId?: Maybe<Scalars['Int']>;
  teamId?: Maybe<Scalars['Int']>;
  meta?: Maybe<Scalars['JSON']>;
};

export type TournamentSeasonConnection = DefaultConnection & {
  __typename?: 'TournamentSeasonConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<TournamentSeason>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type TournamentSeasonCreateInput = {
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  tournamentId: Scalars['Int'];
  startDate?: Maybe<Scalars['DateTime']>;
  finishDate?: Maybe<Scalars['DateTime']>;
  regulation?: Maybe<Scalars['String']>;
  state: TournamentSeasonState;
  sort?: Maybe<Scalars['Int']>;
  meta?: Maybe<Scalars['JSON']>;
};

export enum TournamentSeasonState {
  FUTURE = 'FUTURE',
  CURRENT = 'CURRENT',
  FINISHED = 'FINISHED',
}

export type TournamentSeasonTeam = {
  __typename?: 'TournamentSeasonTeam';
  id: Scalars['Int'];
  seasonId: Scalars['Int'];
  teamId: Scalars['Int'];
  team: TournamentTeam;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type TournamentSeasonTeamConnection = DefaultConnection & {
  __typename?: 'TournamentSeasonTeamConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<TournamentSeasonTeam>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type TournamentSeasonTeamCreateInput = {
  seasonId: Scalars['Int'];
  teamId: Scalars['Int'];
};

export type TournamentSeasonTeamMember = {
  __typename?: 'TournamentSeasonTeamMember';
  id: Scalars['Int'];
  seasonId: Scalars['Int'];
  teamId: Scalars['Int'];
  teamMemberId: Scalars['Int'];
  state?: Maybe<TournamentSeasonTeamMemberState>;
  teamMember: TournamentTeamMember;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type TournamentSeasonTeamMemberConnection = DefaultConnection & {
  __typename?: 'TournamentSeasonTeamMemberConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<TournamentSeasonTeamMember>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type TournamentSeasonTeamMemberCreateInput = {
  seasonId: Scalars['Int'];
  teamId: Scalars['Int'];
  teamMemberId: Scalars['Int'];
  state?: Maybe<TournamentSeasonTeamMemberState>;
};

export enum TournamentSeasonTeamMemberState {
  INACTIVE = 'INACTIVE',
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
}

export type TournamentSeasonTeamMemberUpdateInput = {
  seasonId?: Maybe<Scalars['Int']>;
  teamId?: Maybe<Scalars['Int']>;
  teamMemberId?: Maybe<Scalars['Int']>;
  state?: Maybe<TournamentSeasonTeamMemberState>;
};

export type TournamentSeasonTeamStat = {
  __typename?: 'TournamentSeasonTeamStat';
  id: Scalars['Int'];
  seasonId: Scalars['Int'];
  groupId?: Maybe<Scalars['Int']>;
  position: Scalars['Int'];
  teamId: Scalars['Int'];
  points: Scalars['Int'];
  games: Scalars['Int'];
  win: Scalars['Int'];
  draw: Scalars['Int'];
  loss: Scalars['Int'];
  goals: Scalars['Int'];
  missedGoals: Scalars['Int'];
  yellowCards: Scalars['Int'];
  redCards: Scalars['Int'];
  shutouts: Scalars['Int'];
  sort?: Maybe<Scalars['Float']>;
  meta?: Maybe<Scalars['JSON']>;
  team: TournamentTeam;
  season: TournamentSeason;
  group?: Maybe<TournamentGroup>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type TournamentSeasonTeamStatConnection = DefaultConnection & {
  __typename?: 'TournamentSeasonTeamStatConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<TournamentSeasonTeamStat>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type TournamentSeasonTeamStatCreateInput = {
  seasonId: Scalars['Int'];
  groupId?: Maybe<Scalars['Int']>;
  position?: Maybe<Scalars['Int']>;
  teamId: Scalars['Int'];
  points?: Maybe<Scalars['Int']>;
  games?: Maybe<Scalars['Int']>;
  win?: Maybe<Scalars['Int']>;
  draw?: Maybe<Scalars['Int']>;
  loss?: Maybe<Scalars['Int']>;
  goals?: Maybe<Scalars['Int']>;
  missedGoals?: Maybe<Scalars['Int']>;
  yellowCards?: Maybe<Scalars['Int']>;
  redCards?: Maybe<Scalars['Int']>;
  shutouts?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['Float']>;
  meta?: Maybe<Scalars['JSON']>;
};

export type TournamentSeasonTeamStatUpdateInput = {
  seasonId?: Maybe<Scalars['Int']>;
  groupId?: Maybe<Scalars['Int']>;
  position?: Maybe<Scalars['Int']>;
  teamId?: Maybe<Scalars['Int']>;
  points?: Maybe<Scalars['Int']>;
  games?: Maybe<Scalars['Int']>;
  win?: Maybe<Scalars['Int']>;
  draw?: Maybe<Scalars['Int']>;
  loss?: Maybe<Scalars['Int']>;
  goals?: Maybe<Scalars['Int']>;
  missedGoals?: Maybe<Scalars['Int']>;
  yellowCards?: Maybe<Scalars['Int']>;
  redCards?: Maybe<Scalars['Int']>;
  shutouts?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['Float']>;
  meta?: Maybe<Scalars['JSON']>;
};

export type TournamentSeasonTeamUpdateInput = {
  seasonId?: Maybe<Scalars['Int']>;
  teamId?: Maybe<Scalars['Int']>;
};

export type TournamentSeasonUpdateInput = {
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  tournamentId?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['DateTime']>;
  finishDate?: Maybe<Scalars['DateTime']>;
  regulation?: Maybe<Scalars['String']>;
  state?: Maybe<TournamentSeasonState>;
  sort?: Maybe<Scalars['Int']>;
  meta?: Maybe<Scalars['JSON']>;
};

export type TournamentSetting = {
  __typename?: 'TournamentSetting';
  id: Scalars['Int'];
  clientId: Scalars['Int'];
  tournamentId: Scalars['Int'];
  pushEventSubscriptions?: Maybe<Scalars['JSON']>;
  pushEventTemplates?: Maybe<Scalars['JSON']>;
  transportId?: Maybe<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type TournamentSettingUpdateInput = {
  tournamentId?: Maybe<Scalars['Int']>;
  pushEventSubscriptions?: Maybe<Scalars['JSON']>;
  pushEventTemplates?: Maybe<Scalars['JSON']>;
  transportId?: Maybe<Scalars['Int']>;
};

export enum TournamentSport {
  FOOTBALL = 'FOOTBALL',
  HOCKEY = 'HOCKEY',
  BASKETBALL = 'BASKETBALL',
  TENNIS = 'TENNIS',
}

export type TournamentStadium = {
  __typename?: 'TournamentStadium';
  id: Scalars['Int'];
  teamId?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  lon?: Maybe<Scalars['Float']>;
  builtIn?: Maybe<Scalars['DateTime']>;
  capacity?: Maybe<Scalars['Int']>;
  team?: Maybe<TournamentTeam>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type TournamentStadiumConnection = DefaultConnection & {
  __typename?: 'TournamentStadiumConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<TournamentStadium>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type TournamentStadiumCreateInput = {
  teamId?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  lon?: Maybe<Scalars['Float']>;
  builtIn?: Maybe<Scalars['DateTime']>;
  capacity?: Maybe<Scalars['Int']>;
};

export type TournamentStadiumUpdateInput = {
  teamId?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  lon?: Maybe<Scalars['Float']>;
  builtIn?: Maybe<Scalars['DateTime']>;
  capacity?: Maybe<Scalars['Int']>;
};

export enum TournamentState {
  FUTURE = 'FUTURE',
  CURRENT = 'CURRENT',
  FINISHED = 'FINISHED',
}

export enum TournamentSystem {
  ROUND = 'ROUND',
  PLAYOFF = 'PLAYOFF',
}

export type TournamentTeam = {
  __typename?: 'TournamentTeam';
  id: Scalars['Int'];
  clientId: Scalars['Int'];
  name: Scalars['String'];
  logo?: Maybe<Scalars['String']>;
  coverPhoto?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  history?: Maybe<Scalars['String']>;
  foundingDate?: Maybe<Scalars['String']>;
  altLogo?: Maybe<Scalars['String']>;
  stadium?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  teamMembers: Array<TournamentTeamMember>;
  teamHistories: Array<TournamentTeamHistory>;
  teamStats: Array<TournamentSeasonTeamStat>;
  teamForm?: Maybe<Array<TournamentTeamForm>>;
  teamMembersCount: Scalars['Int'];
  data: Array<ExtensionData>;
  seasons: Array<TournamentSeason>;
  homeStadium?: Maybe<TournamentStadium>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type TournamentTeamConnection = DefaultConnection & {
  __typename?: 'TournamentTeamConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<TournamentTeam>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type TournamentTeamCreateInput = {
  name: Scalars['String'];
  logo?: Maybe<Scalars['String']>;
  coverPhoto?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  history?: Maybe<Scalars['String']>;
  foundingDate?: Maybe<Scalars['String']>;
  altLogo?: Maybe<Scalars['String']>;
  stadium?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  teamForm?: Maybe<Array<Scalars['String']>>;
  meta?: Maybe<Scalars['JSON']>;
  data?: Maybe<Array<ExtensionUpdateDataInput>>;
};

export type TournamentTeamFieldsConnection = DefaultConnection & {
  __typename?: 'TournamentTeamFieldsConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<ExtensionField>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export enum TournamentTeamForm {
  WIN = 'WIN',
  DRAW = 'DRAW',
  LOSE = 'LOSE',
}

export type TournamentTeamGroup = {
  __typename?: 'TournamentTeamGroup';
  id: Scalars['Int'];
  groupId: Scalars['Int'];
  teamId: Scalars['Int'];
  group: TournamentGroup;
  team: TournamentTeam;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type TournamentTeamGroupConnection = DefaultConnection & {
  __typename?: 'TournamentTeamGroupConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<TournamentTeamGroup>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type TournamentTeamGroupCreateInput = {
  groupId: Scalars['Int'];
  teamId: Scalars['Int'];
};

export type TournamentTeamGroupUpdateInput = {
  groupId?: Maybe<Scalars['Int']>;
  teamId?: Maybe<Scalars['Int']>;
};

export type TournamentTeamHistory = {
  __typename?: 'TournamentTeamHistory';
  id: Scalars['Int'];
  teamId: Scalars['Int'];
  field?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  translationId?: Maybe<Scalars['Int']>;
  meta?: Maybe<Scalars['JSON']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type TournamentTeamHistoryConnection = DefaultConnection & {
  __typename?: 'TournamentTeamHistoryConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<TournamentTeamHistory>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type TournamentTeamHistoryCreateInput = {
  teamId: Scalars['Int'];
  field?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  translationId?: Maybe<Scalars['Int']>;
  meta?: Maybe<Scalars['JSON']>;
};

export type TournamentTeamHistoryUpdateInput = {
  teamId?: Maybe<Scalars['Int']>;
  field?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  translationId?: Maybe<Scalars['Int']>;
  meta?: Maybe<Scalars['JSON']>;
};

export type TournamentTeamMember = {
  __typename?: 'TournamentTeamMember';
  id: Scalars['Int'];
  type: TournamentTeamMemberType;
  positionId: Scalars['Int'];
  teamId: Scalars['Int'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  middleName?: Maybe<Scalars['String']>;
  birthday: Scalars['String'];
  weight?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  number?: Maybe<Scalars['Int']>;
  role?: Maybe<TournamentTeamMemberRole>;
  bio?: Maybe<Scalars['String']>;
  citizenship?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  team: TournamentTeam;
  playerStats: Array<TournamentPlayerStat>;
  data: Array<ExtensionData>;
  position: GenericObject;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  statistics?: Maybe<TeamMemberStatistics>;
};

export type TournamentTeamMemberConnection = DefaultConnection & {
  __typename?: 'TournamentTeamMemberConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<TournamentTeamMember>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type TournamentTeamMemberCreateInput = {
  type: TournamentTeamMemberType;
  positionId?: Maybe<Scalars['Int']>;
  teamId: Scalars['Int'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  middleName?: Maybe<Scalars['String']>;
  birthday: Scalars['String'];
  weight?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  number?: Maybe<Scalars['Int']>;
  role?: Maybe<TournamentTeamMemberRole>;
  bio?: Maybe<Scalars['String']>;
  citizenship?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  data?: Maybe<Array<ExtensionUpdateDataInput>>;
};

export type TournamentTeamMemberFieldsConnection = DefaultConnection & {
  __typename?: 'TournamentTeamMemberFieldsConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<ExtensionField>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export enum TournamentTeamMemberRole {
  DEFENDER = 'DEFENDER',
  HALFBACK = 'HALFBACK',
  GOALKEEPER = 'GOALKEEPER',
  FORWARD = 'FORWARD',
}

export type TournamentTeamMemberSocialAccount = {
  __typename?: 'TournamentTeamMemberSocialAccount';
  id: Scalars['Int'];
  state: TournamentTeamMemberSocialAccountState;
  socialNetwork: TournamentTeamMemberSocialAccountSocialNetwork;
  socialNetworkId: Scalars['String'];
  username?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  teamMemberId: Scalars['Int'];
  payload: Scalars['String'];
  expiresAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  teamMember: TournamentTeamMember;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type TournamentTeamMemberSocialAccountConnection = DefaultConnection & {
  __typename?: 'TournamentTeamMemberSocialAccountConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<TournamentTeamMemberSocialAccount>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type TournamentTeamMemberSocialAccountCreateInput = {
  state?: Maybe<TournamentTeamMemberSocialAccountState>;
  socialNetwork: TournamentTeamMemberSocialAccountSocialNetwork;
  socialNetworkId: Scalars['String'];
  username?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  teamMemberId: Scalars['Int'];
  payload: Scalars['String'];
  expiresAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export enum TournamentTeamMemberSocialAccountSocialNetwork {
  GOOGLE = 'GOOGLE',
  YOUTUBE = 'YOUTUBE',
  FACEBOOK = 'FACEBOOK',
  INSTAGRAM = 'INSTAGRAM',
  TWITCH = 'TWITCH',
  TWITTER = 'TWITTER',
  VK = 'VK',
}

export enum TournamentTeamMemberSocialAccountState {
  INACTIVE = 'INACTIVE',
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
}

export type TournamentTeamMemberSocialAccountUpdateInput = {
  state?: Maybe<TournamentTeamMemberSocialAccountState>;
  socialNetwork?: Maybe<TournamentTeamMemberSocialAccountSocialNetwork>;
  socialNetworkId?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  teamMemberId?: Maybe<Scalars['Int']>;
  payload?: Maybe<Scalars['String']>;
  expiresAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export enum TournamentTeamMemberType {
  PLAYER = 'PLAYER',
  COACH = 'COACH',
  REPRESENTATIVE = 'REPRESENTATIVE',
}

export type TournamentTeamMemberUpdateInput = {
  type?: Maybe<TournamentTeamMemberType>;
  positionId?: Maybe<Scalars['Int']>;
  teamId?: Maybe<Scalars['Int']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  number?: Maybe<Scalars['Int']>;
  role?: Maybe<TournamentTeamMemberRole>;
  bio?: Maybe<Scalars['String']>;
  citizenship?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  data?: Maybe<Array<ExtensionUpdateDataInput>>;
};

export type TournamentTeamUpdateInput = {
  name?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  coverPhoto?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  history?: Maybe<Scalars['String']>;
  foundingDate?: Maybe<Scalars['String']>;
  altLogo?: Maybe<Scalars['String']>;
  stadium?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  teamForm?: Maybe<Array<Scalars['String']>>;
  meta?: Maybe<Scalars['JSON']>;
  data?: Maybe<Array<ExtensionUpdateDataInput>>;
};

export type TournamentTour = {
  __typename?: 'TournamentTour';
  id: Scalars['Int'];
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  tournamentId: Scalars['Int'];
  seasonId: Scalars['Int'];
  number: Scalars['Int'];
  startDate?: Maybe<Scalars['DateTime']>;
  finishDate?: Maybe<Scalars['DateTime']>;
  state: TournamentTourState;
  sort?: Maybe<Scalars['Int']>;
  meta?: Maybe<Scalars['JSON']>;
  matches: Array<TournamentMatch>;
  season: TournamentSeason;
  tournament: Tournament;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type TournamentTourConnection = DefaultConnection & {
  __typename?: 'TournamentTourConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<TournamentTour>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type TournamentTourCreateInput = {
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  tournamentId: Scalars['Int'];
  seasonId: Scalars['Int'];
  number: Scalars['Int'];
  startDate?: Maybe<Scalars['DateTime']>;
  finishDate?: Maybe<Scalars['DateTime']>;
  state: TournamentTourState;
  sort?: Maybe<Scalars['Int']>;
  meta?: Maybe<Scalars['JSON']>;
};

export enum TournamentTourState {
  FUTURE = 'FUTURE',
  CURRENT = 'CURRENT',
  FINISHED = 'FINISHED',
}

export type TournamentTourUpdateInput = {
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  tournamentId?: Maybe<Scalars['Int']>;
  seasonId?: Maybe<Scalars['Int']>;
  number?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['DateTime']>;
  finishDate?: Maybe<Scalars['DateTime']>;
  state?: Maybe<TournamentTourState>;
  sort?: Maybe<Scalars['Int']>;
  meta?: Maybe<Scalars['JSON']>;
};

export type TournamentTranslation = {
  __typename?: 'TournamentTranslation';
  id: Scalars['Int'];
  entity: TournamentTranslationEntity;
  entityId: Scalars['Int'];
  localeId: Scalars['Int'];
  field: Scalars['String'];
  value?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type TournamentTranslationConnection = DefaultConnection & {
  __typename?: 'TournamentTranslationConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<TournamentTranslation>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type TournamentTranslationCreateInput = {
  entity: TournamentTranslationEntity;
  entityId: Scalars['Int'];
  localeId: Scalars['Int'];
  field: Scalars['String'];
  value?: Maybe<Scalars['String']>;
};

export enum TournamentTranslationEntity {
  TOURNAMENT = 'TOURNAMENT',
  MATCH = 'MATCH',
  TEAM = 'TEAM',
  TEAM_MEMBER = 'TEAM_MEMBER',
}

export type TournamentTranslationUpdateInput = {
  entity?: Maybe<TournamentTranslationEntity>;
  entityId?: Maybe<Scalars['Int']>;
  localeId?: Maybe<Scalars['Int']>;
  field?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type TournamentUpdateInput = {
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  preview?: Maybe<Scalars['String']>;
  sport?: Maybe<TournamentSport>;
  state?: Maybe<TournamentState>;
  system?: Maybe<TournamentSystem>;
  sort?: Maybe<Scalars['Int']>;
  meta?: Maybe<Scalars['JSON']>;
  data?: Maybe<Array<ExtensionUpdateDataInput>>;
};

export type Translation = {
  __typename?: 'Translation';
  id: Scalars['Int'];
  locale: Scalars['String'];
  key: Scalars['String'];
  value: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type TranslationSaveInput = {
  key: Scalars['String'];
  value: Scalars['String'];
  locale: Scalars['String'];
};

export type UnisenderCreateListInput = {
  title: Scalars['String'];
};

export type UnisenderList = {
  __typename?: 'UnisenderList';
  id?: Maybe<Scalars['ID']>;
  title: Scalars['String'];
};

export type UpdateExtensionFieldInput = {
  relationId?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  dataType?: Maybe<EntityDataType>;
  isReadOnly?: Maybe<Scalars['Boolean']>;
  i18n?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['Int']>;
  translations?: Maybe<Array<ExtensionFieldTranslationInput>>;
};

export type UpdateSystemUserInput = {
  username?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  state?: Maybe<SystemUserState>;
};

export type UpdateUserSettingsInput = {
  locale?: Maybe<LocaleEnum>;
};

export type User = {
  __typename?: 'User';
  id: Scalars['Int'];
  uuid?: Maybe<Scalars['UUID']>;
  /** Состояние */
  state: UserStateEnum;
  username: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  /** Пол */
  gender?: Maybe<UserGenderEnum>;
  /** Устройства пользователя */
  devices?: Maybe<Array<UserDevice>>;
  /** Группы пользователя */
  groups?: Maybe<Array<UserGroup>>;
  /** Счета пользователя */
  accounts: Array<PaymentAccount>;
  /** Заказы пользователя */
  orders: Array<CommerceOrder>;
  isVerifiedEmail: Scalars['Boolean'];
  isVerifiedPhone: Scalars['Boolean'];
  source: Scalars['String'];
  data: Array<ExtensionData>;
  analytics: Array<ExtensionData>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  loyaltyCatalogueAffordableItemsCount: Scalars['Int'];
  loyaltyAccount?: Maybe<PaymentAccount>;
  loyaltyAccounts: Array<PaymentAccount>;
  isProfileComplete: Scalars['Boolean'];
  userOrders: UserOrders;
};

export type UserAnalyticData = {
  __typename?: 'UserAnalyticData';
  id: Scalars['Int'];
  fieldId: Scalars['Int'];
  instanceId: Scalars['Int'];
  value?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type UserAnalyticDataCreateInput = {
  fieldId: Scalars['Int'];
  instanceId: Scalars['Int'];
  value?: Maybe<Scalars['String']>;
};

export type UserAnalyticDataUpdateInput = {
  fieldId?: Maybe<Scalars['Int']>;
  instanceId?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['String']>;
};

export type UserAnalyticField = {
  __typename?: 'UserAnalyticField';
  id: Scalars['Int'];
  clientId: Scalars['Int'];
  relationId: Scalars['Int'];
  name: Scalars['String'];
  dataType: UserAnalyticFieldDataType;
  isReadOnly: Scalars['Boolean'];
  sort: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type UserAnalyticFieldConnection = DefaultConnection & {
  __typename?: 'UserAnalyticFieldConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<UserAnalyticField>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type UserAnalyticFieldCreateInput = {
  relationId?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  dataType: UserAnalyticFieldDataType;
  isReadOnly?: Maybe<Scalars['Boolean']>;
  sort: Scalars['Int'];
};

export enum UserAnalyticFieldDataType {
  BOOLEAN = 'BOOLEAN',
  NUMBER = 'NUMBER',
  STRING = 'STRING',
  ENUM = 'ENUM',
  ARRAY = 'ARRAY',
  FILES = 'FILES',
  JSON = 'JSON',
}

export type UserAnalyticFieldUpdateInput = {
  relationId?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  dataType?: Maybe<UserAnalyticFieldDataType>;
  isReadOnly?: Maybe<Scalars['Boolean']>;
  sort?: Maybe<Scalars['Int']>;
};

export type UserApplication = {
  __typename?: 'UserApplication';
  id: Scalars['Int'];
  clientId: Scalars['Int'];
  userId?: Maybe<Scalars['Int']>;
  typeId: Scalars['Int'];
  payload?: Maybe<Scalars['JSON']>;
  state: UserApplicationState;
  comment?: Maybe<Scalars['String']>;
  assigneeId?: Maybe<Scalars['Int']>;
  data: Array<ExtensionData>;
  user?: Maybe<User>;
  type: UserApplicationType;
  assignee?: Maybe<SystemUser>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type UserApplicationConnection = DefaultConnection & {
  __typename?: 'UserApplicationConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<UserApplication>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type UserApplicationCreateInput = {
  userId?: Maybe<Scalars['Int']>;
  typeId: Scalars['Int'];
  payload?: Maybe<Scalars['JSON']>;
  state?: Maybe<UserApplicationState>;
  comment?: Maybe<Scalars['String']>;
  assigneeId?: Maybe<Scalars['Int']>;
  data?: Maybe<Array<ExtensionUpdateDataInput>>;
};

export type UserApplicationEventCreateInput = {
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  comment: Scalars['String'];
};

export type UserApplicationFamilySectorCreateInput = {
  parentName: Scalars['String'];
  kidName: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  birthday: Scalars['DateTime'];
  isSeasonTicket: Scalars['Boolean'];
  seasonTicket?: Maybe<Scalars['String']>;
  isSeasonTicketPast: Scalars['Boolean'];
  gender?: Maybe<UserKidGender>;
  height?: Maybe<Scalars['Int']>;
  comment?: Maybe<Scalars['String']>;
  documents: Array<Scalars['String']>;
};

export type UserApplicationFamilySectorUpdateInput = {
  parentName?: Maybe<Scalars['String']>;
  kidName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['DateTime']>;
  isSeasonTicket?: Maybe<Scalars['Boolean']>;
  seasonTicket?: Maybe<Scalars['String']>;
  isSeasonTicketPast?: Maybe<Scalars['Boolean']>;
  gender?: Maybe<UserKidGender>;
  height?: Maybe<Scalars['Int']>;
  comment?: Maybe<Scalars['String']>;
  documents?: Maybe<Array<Scalars['String']>>;
};

export type UserApplicationFeedbackDeveloperCreateInput = {
  name: Scalars['String'];
  text: Scalars['String'];
};

export type UserApplicationFieldsConnection = DefaultConnection & {
  __typename?: 'UserApplicationFieldsConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<ExtensionField>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type UserApplicationKidCreateInput = {
  parentName: Scalars['String'];
  kidName: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  birthday: Scalars['DateTime'];
  isSeasonTicket: Scalars['Boolean'];
  seasonTicket?: Maybe<Scalars['String']>;
  isSeasonTicketPast: Scalars['Boolean'];
  gender?: Maybe<UserKidGender>;
  height?: Maybe<Scalars['Int']>;
  comment?: Maybe<Scalars['String']>;
};

export type UserApplicationLog = {
  __typename?: 'UserApplicationLog';
  id: Scalars['Int'];
  applicationId: Scalars['Int'];
  userId?: Maybe<Scalars['Int']>;
  systemUserId?: Maybe<Scalars['Int']>;
  type: UserApplicationLogType;
  data?: Maybe<Scalars['JSON']>;
  comment?: Maybe<Scalars['String']>;
  application: UserApplication;
  user?: Maybe<User>;
  systemUser?: Maybe<SystemUser>;
  createdAt: Scalars['DateTime'];
};

export enum UserApplicationLogType {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  COMMENT = 'COMMENT',
}

export type UserApplicationScreenStadiumCreateInput = {
  text: Scalars['String'];
};

export enum UserApplicationState {
  ACCEPTED = 'ACCEPTED',
  DECLINED = 'DECLINED',
  NEW = 'NEW',
  DELETED = 'DELETED',
}

export type UserApplicationStats = {
  __typename?: 'UserApplicationStats';
  type: UserApplicationType;
  accepted: Scalars['Int'];
  declined: Scalars['Int'];
  new: Scalars['Int'];
  deleted: Scalars['Int'];
};

export type UserApplicationType = {
  __typename?: 'UserApplicationType';
  id: Scalars['Int'];
  clientId: Scalars['Int'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['JSON']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type UserApplicationTypeConnection = DefaultConnection & {
  __typename?: 'UserApplicationTypeConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<UserApplicationType>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type UserApplicationTypeCreateInput = {
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['JSON']>;
};

export type UserApplicationTypeUpdateInput = {
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['JSON']>;
};

export type UserApplicationUpdateInput = {
  userId?: Maybe<Scalars['Int']>;
  typeId?: Maybe<Scalars['Int']>;
  payload?: Maybe<Scalars['JSON']>;
  state?: Maybe<UserApplicationState>;
  comment?: Maybe<Scalars['String']>;
  assigneeId?: Maybe<Scalars['Int']>;
  data?: Maybe<Array<ExtensionUpdateDataInput>>;
};

export type UserAuthResponse = {
  __typename?: 'UserAuthResponse';
  user: User;
  refreshToken: Scalars['String'];
  accessToken: Scalars['String'];
};

export type UserConnection = DefaultConnection & {
  __typename?: 'UserConnection';
  /** Общее количество элементов в запросе с учетом фильтров */
  total: Scalars['Int'];
  /** Лимит выборки */
  limit: Scalars['Int'];
  /** Указатель (пропуск элементов) */
  cursor: Scalars['Int'];
  /** Список элементов */
  edges: Array<User>;
  /** Список полей для сортировки */
  sortable?: Maybe<Array<Scalars['String']>>;
  /** Список полей для строкового поиска */
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type UserCreateInput = {
  id?: Maybe<Scalars['Int']>;
  clientId?: Maybe<Scalars['Int']>;
  username: Scalars['String'];
  password?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  data?: Maybe<Array<ExtensionUpdateDataInput>>;
  gender?: Maybe<UserGenderEnum>;
  source?: Maybe<Scalars['String']>;
  state?: Maybe<UserStateEnum>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UserData = {
  __typename?: 'UserData';
  key: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type UserDataField = {
  __typename?: 'UserDataField';
  id: Scalars['Int'];
  name: Scalars['String'];
  title: Scalars['String'];
  dataType?: Maybe<UserDataFieldDataType>;
  options?: Maybe<Array<Scalars['String']>>;
  isSystem: Scalars['Boolean'];
  defaultValue?: Maybe<Scalars['String']>;
};

export type UserDataFieldCreateInput = {
  name: Scalars['String'];
  dataType: UserDataFieldDataType;
  title: Scalars['String'];
  options?: Maybe<Scalars['String']>;
  isSystem?: Maybe<Scalars['Boolean']>;
  defaultValue?: Maybe<Scalars['String']>;
};

export enum UserDataFieldDataType {
  NUMBER = 'NUMBER',
  STRING = 'STRING',
  ENUM = 'ENUM',
  BOOLEAN = 'BOOLEAN',
}

export type UserDataFieldUpdateInput = {
  name?: Maybe<Scalars['String']>;
  dataType?: Maybe<UserDataFieldDataType>;
  title?: Maybe<Scalars['String']>;
  options?: Maybe<Scalars['String']>;
  isSystem?: Maybe<Scalars['Boolean']>;
  defaultValue?: Maybe<Scalars['String']>;
};

/** Устройство пользователя */
export type UserDevice = {
  __typename?: 'UserDevice';
  id: Scalars['Int'];
  userId?: Maybe<Scalars['Int']>;
  deviceId: Scalars['String'];
  installId: Scalars['String'];
  bundleId?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  deviceLocale?: Maybe<Scalars['String']>;
  deviceName?: Maybe<Scalars['String']>;
  deviceModel?: Maybe<Scalars['String']>;
  installReferrer?: Maybe<Scalars['String']>;
  manufacturer?: Maybe<Scalars['String']>;
  systemName?: Maybe<Scalars['String']>;
  systemVersion?: Maybe<Scalars['String']>;
  appVersion?: Maybe<Scalars['String']>;
  userAgent?: Maybe<Scalars['String']>;
  pushToken?: Maybe<Scalars['String']>;
  expoPushToken?: Maybe<Scalars['String']>;
  APNSPushToken?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type UserDeviceConnection = DefaultConnection & {
  __typename?: 'UserDeviceConnection';
  total: Scalars['Int'];
  cursor: Scalars['Int'];
  limit: Scalars['Int'];
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
  edges: Array<UserDevice>;
};

export type UserDeviceWhereInput = {
  clientId?: Maybe<Array<Scalars['Int']>>;
  userId?: Maybe<Array<Scalars['Int']>>;
  deviceId?: Maybe<Array<Scalars['String']>>;
  installId?: Maybe<Array<Scalars['String']>>;
  bundleId?: Maybe<Array<Scalars['String']>>;
  brand?: Maybe<Array<Scalars['String']>>;
  deviceLocale?: Maybe<Array<Scalars['String']>>;
  deviceName?: Maybe<Array<Scalars['String']>>;
  deviceModel?: Maybe<Array<Scalars['String']>>;
  installReferrer?: Maybe<Array<Scalars['String']>>;
  manufacturer?: Maybe<Array<Scalars['String']>>;
  systemName?: Maybe<Array<Scalars['String']>>;
  systemVersion?: Maybe<Array<Scalars['String']>>;
  appVersion?: Maybe<Array<Scalars['String']>>;
  userAgent?: Maybe<Array<Scalars['String']>>;
  pushToken?: Maybe<Array<Scalars['String']>>;
  expoPushToken?: Maybe<Array<Scalars['String']>>;
};

export type UserDocument = {
  __typename?: 'UserDocument';
  id: Scalars['Int'];
  clientId: Scalars['Int'];
  userId: Scalars['Int'];
  kidId?: Maybe<Scalars['Int']>;
  type: UserDocumentType;
  data?: Maybe<Scalars['JSON']>;
  state?: Maybe<UserDocumentState>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type UserDocumentConnection = DefaultConnection & {
  __typename?: 'UserDocumentConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<UserDocument>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type UserDocumentCreateInput = {
  userId: Scalars['Int'];
  kidId?: Maybe<Scalars['Int']>;
  type: UserDocumentType;
  data?: Maybe<Scalars['JSON']>;
  state?: Maybe<UserDocumentState>;
};

export enum UserDocumentState {
  INACTIVE = 'INACTIVE',
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
}

export enum UserDocumentType {
  PASSPORT = 'PASSPORT',
  BIRTH_CERTIFICATE = 'BIRTH_CERTIFICATE',
  FAN_ID = 'FAN_ID',
}

export type UserDocumentUpdateInput = {
  userId?: Maybe<Scalars['Int']>;
  kidId?: Maybe<Scalars['Int']>;
  type?: Maybe<UserDocumentType>;
  data?: Maybe<Scalars['JSON']>;
  state?: Maybe<UserDocumentState>;
};

export type UserFieldsConnection = DefaultConnection & {
  __typename?: 'UserFieldsConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<ExtensionField>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export enum UserFilterDataType {
  STRING = 'string',
  NUMBER = 'number',
  BOOLEAN = 'boolean',
  ARRAY = 'array',
}

export enum UserFilterType {
  SELECT = 'select',
  CHECKBOX = 'checkbox',
  RADIO = 'radio',
  TEXT = 'text',
  INPUT = 'input',
  MULTISELECT = 'multiselect',
  RANGESLIDER = 'rangeSlider',
}

export enum UserGenderEnum {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
}

export type UserGroup = {
  __typename?: 'UserGroup';
  id: Scalars['Int'];
  clientId: Scalars['Int'];
  name: Scalars['String'];
  sort: Scalars['Int'];
  usersCount: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type UserGroupConnection = DefaultConnection & {
  __typename?: 'UserGroupConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<UserGroup>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type UserGroupCreateInput = {
  name: Scalars['String'];
  sort?: Maybe<Scalars['Int']>;
  usersCount?: Maybe<Scalars['Int']>;
};

export type UserGroupUpdateInput = {
  name?: Maybe<Scalars['String']>;
  sort?: Maybe<Scalars['Int']>;
  usersCount?: Maybe<Scalars['Int']>;
};

export type UserImportResponse = {
  __typename?: 'UserImportResponse';
  orders?: Maybe<Scalars['Int']>;
  tickets?: Maybe<Scalars['Int']>;
};

/** Доход пользователя */
export enum UserIncomeEnum {
  LOW = 'low',
  MEDIUM = 'medium',
  HIGH = 'high',
}

export type UserKid = {
  __typename?: 'UserKid';
  id: Scalars['Int'];
  clientId: Scalars['Int'];
  userId: Scalars['Int'];
  state: UserKidState;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  middleName?: Maybe<Scalars['String']>;
  birthday: Scalars['DateTime'];
  gender?: Maybe<UserKidGender>;
  isSeasonTicket?: Maybe<Scalars['Boolean']>;
  seasonTicket?: Maybe<Scalars['String']>;
  isSeasonTicketPast?: Maybe<Scalars['Boolean']>;
  height?: Maybe<Scalars['Int']>;
  user: User;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type UserKidConnection = DefaultConnection & {
  __typename?: 'UserKidConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<UserKid>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type UserKidCreateInput = {
  userId: Scalars['Int'];
  state?: Maybe<UserKidState>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  middleName?: Maybe<Scalars['String']>;
  birthday: Scalars['DateTime'];
  gender?: Maybe<UserKidGender>;
  isSeasonTicket?: Maybe<Scalars['Boolean']>;
  seasonTicket?: Maybe<Scalars['String']>;
  isSeasonTicketPast?: Maybe<Scalars['Boolean']>;
  height?: Maybe<Scalars['Int']>;
};

export enum UserKidGender {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
}

export enum UserKidState {
  ACCEPTED = 'ACCEPTED',
  DECLINED = 'DECLINED',
  NEW = 'NEW',
  DELETED = 'DELETED',
}

export type UserKidUpdateInput = {
  userId?: Maybe<Scalars['Int']>;
  state?: Maybe<UserKidState>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<UserKidGender>;
  isSeasonTicket?: Maybe<Scalars['Boolean']>;
  seasonTicket?: Maybe<Scalars['String']>;
  isSeasonTicketPast?: Maybe<Scalars['Boolean']>;
  height?: Maybe<Scalars['Int']>;
};

/** Местоположение пользователя */
export type UserLocation = {
  __typename?: 'UserLocation';
  id: Scalars['Int'];
  userId?: Maybe<Scalars['Int']>;
  deviceId?: Maybe<Scalars['String']>;
  coords?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UserLocationInput = {
  userId?: Maybe<Scalars['Int']>;
  deviceId?: Maybe<Scalars['String']>;
  coords?: Maybe<Scalars['JSON']>;
};

export type UserMatchEventSubscriber = {
  __typename?: 'UserMatchEventSubscriber';
  deviceId: Scalars['Int'];
  clientId: Scalars['Int'];
  eventName: UserMatchEventSubscriberEventName;
  teamIdx?: Maybe<Array<Scalars['Int']>>;
  device: UserDevice;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type UserMatchEventSubscriberConnection = DefaultConnection & {
  __typename?: 'UserMatchEventSubscriberConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<UserMatchEventSubscriber>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export enum UserMatchEventSubscriberEventName {
  AUTO_GOAL = 'AUTO_GOAL',
  CORNER_KICK = 'CORNER_KICK',
  FREE_KICK = 'FREE_KICK',
  GOAL = 'GOAL',
  IN = 'IN',
  MATCH_FINISH = 'MATCH_FINISH',
  MATCH_HALFTIME = 'MATCH_HALFTIME',
  MATCH_START = 'MATCH_START',
  OUT = 'OUT',
  PASS = 'PASS',
  ASSIST = 'ASSIST',
  PENALTY = 'PENALTY',
  PENALTY_NO = 'PENALTY_NO',
  RED_CARD = 'RED_CARD',
  TRAUMA = 'TRAUMA',
  YELLOW_CARD = 'YELLOW_CARD',
  YELLOW_CARD2 = 'YELLOW_CARD2',
  COMMENT = 'COMMENT',
  VAR = 'VAR',
  VIDEO = 'VIDEO',
  FINAL = 'FINAL',
}

export type UserOrders = {
  __typename?: 'UserOrders';
  eventOrders: Array<EventOrder>;
  foodOrders: Array<FoodOrder>;
};

export type UserPublicProfile = {
  __typename?: 'UserPublicProfile';
  id: Scalars['ID'];
  username: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  locale: LocaleEnum;
  avatar?: Maybe<Scalars['String']>;
};

export type UserPushInput = {
  userId: Array<Scalars['Int']>;
  templateId?: Maybe<Scalars['Int']>;
  transportId?: Maybe<Scalars['Int']>;
  data?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

export type UserSetting = {
  __typename?: 'UserSetting';
  id: Scalars['Int'];
  clientId: Scalars['Int'];
  emailSettings: Scalars['JSON'];
  defaultGroupId?: Maybe<Scalars['Int']>;
  defaultGroup?: Maybe<UserGroup>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  pushTemplate?: Maybe<Scalars['Int']>;
  emailTemplate?: Maybe<Scalars['Int']>;
  pushTransport?: Maybe<Scalars['Int']>;
  emailTransport?: Maybe<Scalars['Int']>;
  emailSender?: Maybe<Scalars['String']>;
};

export type UserSettings = {
  __typename?: 'UserSettings';
  locale: LocaleEnum;
};

export type UserSocialAccount = {
  __typename?: 'UserSocialAccount';
  id: Scalars['Int'];
  clientId: Scalars['Int'];
  userId?: Maybe<Scalars['Int']>;
  state: UserSocialAccountState;
  socialNetwork: UserSocialAccountSocialNetwork;
  socialNetworkId: Scalars['String'];
  payload: Scalars['String'];
  expiresAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type UserSocialAccountConnection = DefaultConnection & {
  __typename?: 'UserSocialAccountConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<UserSocialAccount>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type UserSocialAccountCreateInput = {
  userId?: Maybe<Scalars['Int']>;
  state?: Maybe<UserSocialAccountState>;
  socialNetwork: UserSocialAccountSocialNetwork;
  socialNetworkId: Scalars['String'];
  payload: Scalars['String'];
  expiresAt?: Maybe<Scalars['DateTime']>;
};

export enum UserSocialAccountSocialNetwork {
  TWITCH = 'TWITCH',
  GOOGLE = 'GOOGLE',
  INSTAGRAM = 'INSTAGRAM',
  VK = 'VK',
  FACEBOOK = 'FACEBOOK',
  TWITTER = 'TWITTER',
  RPL = 'RPL',
  APPLE = 'APPLE',
}

export enum UserSocialAccountState {
  INACTIVE = 'INACTIVE',
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
}

export type UserSocialAccountUpdateInput = {
  userId?: Maybe<Scalars['Int']>;
  state?: Maybe<UserSocialAccountState>;
  socialNetwork?: Maybe<UserSocialAccountSocialNetwork>;
  socialNetworkId?: Maybe<Scalars['String']>;
  payload?: Maybe<Scalars['String']>;
  expiresAt?: Maybe<Scalars['DateTime']>;
};

export type UserSocialAuthResponse = {
  __typename?: 'UserSocialAuthResponse';
  socialAccount: UserSocialAccount;
  user: User;
  refreshToken: Scalars['String'];
  accessToken: Scalars['String'];
};

/** Состояние пользователя */
export enum UserStateEnum {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  DELETED = 'DELETED',
  BLOCKED = 'BLOCKED',
}

export type UserSubscriber = {
  __typename?: 'UserSubscriber';
  /** Complex key: userId_subscriptionId_modifierId */
  id: Scalars['String'];
  clientId: Scalars['Int'];
  userId: Scalars['Int'];
  subscriptionId: Scalars['Int'];
  /** Entity reference */
  modifierId?: Maybe<Scalars['Int']>;
  user: User;
  subscription: UserSubscription;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type UserSubscriberConnection = DefaultConnection & {
  __typename?: 'UserSubscriberConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<UserSubscriber>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type UserSubscriberCreateInput = {
  userId: Scalars['Int'];
  subscriptionId: Scalars['Int'];
  modifierId?: Maybe<Scalars['Int']>;
};

export type UserSubscriberUpdateInput = {
  userId?: Maybe<Scalars['Int']>;
  subscriptionId?: Maybe<Scalars['Int']>;
  modifierId?: Maybe<Scalars['Int']>;
};

export type UserSubscribersLog = {
  __typename?: 'UserSubscribersLog';
  id: Scalars['Int'];
  userId: Scalars['Int'];
  subscriptionId: Scalars['Int'];
  state: UserSubscribersLogState;
  reason: Scalars['String'];
  systemUserId: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type UserSubscribersLogConnection = DefaultConnection & {
  __typename?: 'UserSubscribersLogConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<UserSubscribersLog>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type UserSubscribersLogCreateInput = {
  userId: Scalars['Int'];
  subscriptionId: Scalars['Int'];
  state: UserSubscribersLogState;
  reason: Scalars['String'];
  systemUserId: Scalars['Int'];
};

export enum UserSubscribersLogState {
  ADDED = 'ADDED',
  REMOVED = 'REMOVED',
}

export type UserSubscribersLogUpdateInput = {
  userId?: Maybe<Scalars['Int']>;
  subscriptionId?: Maybe<Scalars['Int']>;
  state?: Maybe<UserSubscribersLogState>;
  reason?: Maybe<Scalars['String']>;
  systemUserId?: Maybe<Scalars['Int']>;
};

export type UserSubscription = {
  __typename?: 'UserSubscription';
  id: Scalars['Int'];
  clientId: Scalars['Int'];
  state: UserSubscriptionState;
  name: Scalars['String'];
  access: UserSubscriptionAccess;
  description?: Maybe<Scalars['String']>;
  subscribersCount: Scalars['Int'];
  isSubscriber?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export enum UserSubscriptionAccess {
  PUBLIC = 'PUBLIC',
  PRIVATE = 'PRIVATE',
}

export type UserSubscriptionConnection = DefaultConnection & {
  __typename?: 'UserSubscriptionConnection';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  cursor: Scalars['Int'];
  edges: Array<UserSubscription>;
  sortable?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Array<Scalars['String']>>;
};

export type UserSubscriptionCreateInput = {
  state?: Maybe<UserSubscriptionState>;
  name: Scalars['String'];
  access: UserSubscriptionAccess;
  description?: Maybe<Scalars['String']>;
  isSubscriber?: Maybe<Scalars['Boolean']>;
};

export enum UserSubscriptionState {
  INACTIVE = 'INACTIVE',
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
}

export type UserSubscriptionUpdateInput = {
  state?: Maybe<UserSubscriptionState>;
  name?: Maybe<Scalars['String']>;
  access?: Maybe<UserSubscriptionAccess>;
  description?: Maybe<Scalars['String']>;
  isSubscriber?: Maybe<Scalars['Boolean']>;
};

export type UserUpdateInput = {
  id?: Maybe<Scalars['Int']>;
  clientId?: Maybe<Scalars['Int']>;
  username?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  gender?: Maybe<UserGenderEnum>;
  source?: Maybe<Scalars['String']>;
  state?: Maybe<UserStateEnum>;
  data?: Maybe<Array<ExtensionUpdateDataInput>>;
};

/** Список доступных полей для фильтрации пользователей */
export type UserWhereInput = {
  id?: Maybe<Array<Scalars['Int']>>;
  uuid?: Maybe<Array<Scalars['UUID']>>;
  state?: Maybe<Array<UserStateEnum>>;
  gender?: Maybe<Array<UserGenderEnum>>;
  birthday?: Maybe<Array<Scalars['DateTime']>>;
  email?: Maybe<Array<Scalars['String']>>;
  phone?: Maybe<Array<Scalars['String']>>;
  firstName?: Maybe<Array<Scalars['String']>>;
  lastName?: Maybe<Array<Scalars['String']>>;
  middleName?: Maybe<Array<Scalars['String']>>;
  isVerifiedEmail?: Maybe<Array<Scalars['Boolean']>>;
  isVerifiedPhone?: Maybe<Array<Scalars['Boolean']>>;
  data?: Maybe<Array<ExtensionUpdateDataInput>>;
  createdAt?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt?: Maybe<Array<Scalars['DateTime']>>;
};

export type UsersConnection = {
  __typename?: 'UsersConnection';
  /** Общее количество элементов в запросе с учетом фильтров */
  total: Scalars['Int'];
  /** Проверка наличия элементов на следующей странице пагинации */
  hasMore: Scalars['Boolean'];
  /** Список элементов */
  edges: Array<Maybe<User>>;
};

export type WidgetSummaryGraph = {
  __typename?: 'WidgetSummaryGraph';
  name: Scalars['String'];
  dataset: Array<WidgetSummaryGraphDataset>;
};

export type WidgetSummaryGraphDataset = {
  __typename?: 'WidgetSummaryGraphDataset';
  label: Scalars['String'];
  data: Array<WidgetSummaryGraphDatasetData>;
};

export type WidgetSummaryGraphDatasetData = {
  __typename?: 'WidgetSummaryGraphDatasetData';
  x: Scalars['Date'];
  y: Scalars['Int'];
};

export type WidgetsResponse = {
  __typename?: 'WidgetsResponse';
  commerceWidget: CommerceWidget;
};

export type TournamentMatchProtocolFragment = { __typename?: 'TournamentMatchProtocol' } & Pick<
  TournamentMatchProtocol,
  'id' | 'matchId' | 'minute' | 'event' | 'teamMemberId' | 'teamId' | 'createdAt' | 'updatedAt'
>;

export type TournamentMatchProtocolDetailsFragment = { __typename?: 'TournamentMatchProtocol' } & Pick<
  TournamentMatchProtocol,
  'id' | 'matchId' | 'minute' | 'event' | 'teamMemberId' | 'teamId' | 'createdAt' | 'updatedAt'
> & {
    match: { __typename?: 'TournamentMatch' } & TournamentMatchFragment;
    player?: Maybe<{ __typename?: 'TournamentTeamMember' } & TournamentTeamMemberFragment>;
    team?: Maybe<{ __typename?: 'TournamentTeam' } & TournamentTeamFragment>;
  };

export type TournamentMatchProtocolConnectionFragment = { __typename?: 'TournamentMatchProtocolConnection' } & Pick<
  TournamentMatchProtocolConnection,
  'total' | 'limit' | 'cursor' | 'sortable'
> & { edges: Array<{ __typename?: 'TournamentMatchProtocol' } & TournamentMatchProtocolFragment> };

export type AclFragment = { __typename?: 'ACL' } & Pick<Acl, 'id' | 'parentId' | 'name' | 'createdAt' | 'updatedAt'>;

export type ArticleFragment = { __typename?: 'Article' } & Pick<
  Article,
  'id' | 'clientId' | 'categoryId' | 'state' | 'tags' | 'localeId' | 'createdAt' | 'publishedAt' | 'title'
> & {
    locale: { __typename?: 'SystemLocale' } & SystemLocaleFragment;
    authors?: Maybe<Array<{ __typename?: 'ArticleAuthor' } & ArticleAuthorFragment>>;
  };

export type ArticleFullFragment = { __typename?: 'Article' } & {
  category: { __typename?: 'ArticleCategory' } & ArticleCategoryFragment;
  blocks?: Maybe<Array<{ __typename?: 'ArticleBlock' } & ArticleBlockFragment>>;
  preview?: Maybe<Array<{ __typename?: 'ArticleBlock' } & ArticleBlockFragment>>;
  authors?: Maybe<Array<{ __typename?: 'ArticleAuthor' } & ArticleAuthorFragment>>;
  links?: Maybe<Array<{ __typename?: 'ArticleLink' } & ArticleLinkFragment>>;
  log?: Maybe<Array<{ __typename?: 'ArticleLog' } & ArticleLogFragment>>;
} & ArticleFragment;

export type ArticleAuthorFragment = { __typename?: 'ArticleAuthor' } & Pick<
  ArticleAuthor,
  'id' | 'articleId' | 'userId' | 'role' | 'createdAt' | 'updatedAt'
> & { user: { __typename?: 'SystemUser' } & SystemUserFragment };

export type ArticleAuthorFullFragment = { __typename?: 'ArticleAuthor' } & Pick<ArticleAuthor, 'userId' | 'role'> & {
    user: { __typename?: 'SystemUser' } & SystemUserFragment;
  };

export type ArticleAuthorConnectionFragment = { __typename?: 'ArticleAuthorConnection' } & Pick<
  ArticleAuthorConnection,
  'total' | 'limit' | 'cursor' | 'sortable'
> & { edges: Array<{ __typename?: 'ArticleAuthor' } & ArticleAuthorFragment> };

export type ArticleBlockFragment = { __typename?: 'ArticleBlock' } & Pick<
  ArticleBlock,
  'id' | 'articleId' | 'authorId' | 'name' | 'type' | 'data' | 'isPreview' | 'sort' | 'createdAt' | 'updatedAt'
>;

export type ArticleBlockAuthorFragment = { __typename?: 'ArticleBlockAuthor' } & Pick<ArticleBlockAuthor, 'blockId'> & {
    author: { __typename?: 'SystemUser' } & SystemUserFragment;
  };

export type ArticleBlockAuthorsConnectionFragment = { __typename?: 'ArticleBlockAuthorsConnection' } & Pick<
  ArticleBlockAuthorsConnection,
  'total' | 'limit' | 'cursor' | 'sortable'
> & { edges: Array<{ __typename?: 'ArticleBlockAuthor' } & ArticleBlockAuthorFragment> };

export type ArticleBlockConnectionFragment = { __typename?: 'ArticleBlockConnection' } & Pick<
  ArticleBlockConnection,
  'total' | 'limit' | 'cursor' | 'sortable'
> & { edges: Array<{ __typename?: 'ArticleBlock' } & ArticleBlockFragment> };

export type ArticleCategoryConnectionFragment = { __typename?: 'ArticleCategoryConnection' } & Pick<
  ArticleCategoryConnection,
  'total' | 'limit' | 'cursor' | 'sortable'
> & { edges: Array<{ __typename?: 'ArticleCategory' } & ArticleCategoryFragment> };

export type ArticleCategoryFragment = { __typename?: 'ArticleCategory' } & Pick<
  ArticleCategory,
  'id' | 'clientId' | 'name' | 'parentId' | 'createdAt'
>;

export type ArticlesConnectionFragment = { __typename?: 'ArticlesConnection' } & Pick<
  ArticlesConnection,
  'total' | 'limit' | 'cursor' | 'sortable'
> & { edges: Array<{ __typename?: 'Article' } & ArticleFragment> };

export type ArticleLinkFragment = { __typename?: 'ArticleLink' } & Pick<
  ArticleLink,
  'articleId' | 'entityId' | 'entityType'
>;

export type ArticleLinkFullFragment = { __typename?: 'ArticleLink' } & Pick<
  ArticleLink,
  'articleId' | 'entityId' | 'entityType'
>;

export type ArticleLinkConnectionFragment = { __typename?: 'ArticleLinkConnection' } & Pick<
  ArticleLinkConnection,
  'total' | 'limit' | 'cursor' | 'sortable'
> & { edges: Array<{ __typename?: 'ArticleLink' } & ArticleLinkFragment> };

type ArticleLinkEntityRecord_TournamentMatch_Fragment = { __typename?: 'TournamentMatch' } & TournamentMatchFragment;

type ArticleLinkEntityRecord_TournamentTour_Fragment = { __typename?: 'TournamentTour' } & Pick<TournamentTour, 'id'>;

type ArticleLinkEntityRecord_TournamentTeam_Fragment = { __typename?: 'TournamentTeam' } & Pick<TournamentTeam, 'id'>;

type ArticleLinkEntityRecord_TournamentMatchEvent_Fragment = { __typename?: 'TournamentMatchEvent' } & Pick<
  TournamentMatchEvent,
  'id'
>;

type ArticleLinkEntityRecord_TournamentTeamMember_Fragment = { __typename?: 'TournamentTeamMember' } & Pick<
  TournamentTeamMember,
  'id'
>;

export type ArticleLinkEntityRecordFragment =
  | ArticleLinkEntityRecord_TournamentMatch_Fragment
  | ArticleLinkEntityRecord_TournamentTour_Fragment
  | ArticleLinkEntityRecord_TournamentTeam_Fragment
  | ArticleLinkEntityRecord_TournamentMatchEvent_Fragment
  | ArticleLinkEntityRecord_TournamentTeamMember_Fragment;

export type ArticleLinkEntityTypeConnectionFragment = { __typename?: 'ArticleLinkEntityTypeConnection' } & Pick<
  ArticleLinkEntityTypeConnection,
  'total' | 'limit' | 'cursor' | 'sortable'
> & {
    edges: Array<
      | ({ __typename?: 'TournamentMatch' } & ArticleLinkEntityRecord_TournamentMatch_Fragment)
      | ({ __typename?: 'TournamentTour' } & ArticleLinkEntityRecord_TournamentTour_Fragment)
      | ({ __typename?: 'TournamentTeam' } & ArticleLinkEntityRecord_TournamentTeam_Fragment)
      | ({ __typename?: 'TournamentMatchEvent' } & ArticleLinkEntityRecord_TournamentMatchEvent_Fragment)
      | ({ __typename?: 'TournamentTeamMember' } & ArticleLinkEntityRecord_TournamentTeamMember_Fragment)
    >;
  };

export type ArticleLogFragment = { __typename?: 'ArticleLog' } & Pick<
  ArticleLog,
  'articleId' | 'authorId' | 'data' | 'state' | 'createdAt'
>;

export type ArticleTagFragment = { __typename?: 'ArticleTag' } & Pick<ArticleTag, 'id' | 'createdAt' | 'updatedAt'>;

export type ArticleTagConnectionFragment = { __typename?: 'ArticleTagConnection' } & Pick<
  ArticleTagConnection,
  'total' | 'limit' | 'cursor' | 'sortable'
> & { edges: Array<{ __typename?: 'ArticleTag' } & ArticleTagFragment> };

export type CatalogueFlatFragment = { __typename?: 'Catalogue' } & Pick<
  Catalogue,
  | 'id'
  | 'clientId'
  | 'state'
  | 'name'
  | 'description'
  | 'localeIdx'
  | 'preview'
  | 'currencyIdx'
  | 'createdAt'
  | 'updatedAt'
>;

export type CatalogueFragment = { __typename?: 'Catalogue' } & {
  locales?: Maybe<Array<{ __typename?: 'Locale' } & LocaleFragment>>;
  currencies?: Maybe<Array<{ __typename?: 'Currency' } & CurrencyFragment>>;
} & CatalogueFlatFragment;

export type CatalogueCategoryFragment = { __typename?: 'CatalogueCategory' } & Pick<
  CatalogueCategory,
  'id' | 'name' | 'catalogueId' | 'state' | 'createdAt' | 'updatedAt'
>;

export type CatalogueCategoryConnectionFragment = { __typename?: 'CatalogueCategoryConnection' } & Pick<
  CatalogueCategoryConnection,
  'total' | 'limit' | 'cursor' | 'sortable'
> & { edges: Array<{ __typename?: 'CatalogueCategory' } & CatalogueCategoryFragment> };

export type CatalogueConnectionFragment = { __typename?: 'CatalogueConnection' } & Pick<
  CatalogueConnection,
  'total' | 'limit' | 'cursor' | 'sortable'
> & { edges: Array<{ __typename?: 'Catalogue' } & CatalogueFragment> };

export type CatalogueItemFlatFragment = { __typename?: 'CatalogueItem' } & Pick<
  CatalogueItem,
  'id' | 'catalogueId' | 'name' | 'preview' | 'state' | 'createdAt' | 'updatedAt' | 'parentId'
>;

export type CatalogueItemInCollectionFragment = { __typename?: 'CatalogueItem' } & {
  prices?: Maybe<Array<{ __typename?: 'CatalogueItemPrice' } & CatalogueItemPriceFragment>>;
} & CatalogueItemFlatFragment;

export type CatalogueItemFragment = { __typename?: 'CatalogueItem' } & {
  data: Array<{ __typename?: 'ExtensionData' } & ExtensionDataFragment>;
} & CatalogueItemFlatFragment;

export type CatalogueItemConnectionFragment = { __typename?: 'CatalogueItemConnection' } & Pick<
  CatalogueItemConnection,
  'total' | 'limit' | 'cursor' | 'sortable'
> & { edges: Array<{ __typename?: 'CatalogueItem' } & CatalogueItemInCollectionFragment> };

export type CatalogueItemFieldsConnectionFragment = { __typename?: 'CatalogueItemFieldsConnection' } & Pick<
  CatalogueItemFieldsConnection,
  'total' | 'limit' | 'cursor' | 'sortable'
> & { edges: Array<{ __typename?: 'ExtensionField' } & ExtensionFieldFragment> };

export type CatalogueItemPriceFragment = { __typename?: 'CatalogueItemPrice' } & Pick<
  CatalogueItemPrice,
  'id' | 'currencyId' | 'value'
> & { currency: { __typename?: 'Currency' } & CurrencyFragment };

export type CatalogueItemPriceConnectionFragment = { __typename?: 'CatalogueItemPriceConnection' } & Pick<
  CatalogueItemPriceConnection,
  'total' | 'limit' | 'cursor' | 'sortable'
> & { edges: Array<{ __typename?: 'CatalogueItemPrice' } & CatalogueItemPriceFragment> };

export type CatalogueSummaryFragment = { __typename?: 'CatalogueSummary' } & Pick<CatalogueSummary, 'itemsCount'> & {
    topItems?: Maybe<Array<{ __typename?: 'CatalogueItem' } & CatalogueItemFragment>>;
  };

export type CatalogueSummaryWidgetFragment = { __typename?: 'CatalogueSummaryWidget' } & {
  catalogue: { __typename?: 'Catalogue' } & CatalogueFragment;
  summary: { __typename?: 'CatalogueSummary' } & CatalogueSummaryFragment;
};

export type CateringFlatFragment = { __typename?: 'Catering' } & Pick<
  Catering,
  | 'id'
  | 'clientId'
  | 'eventPlaceId'
  | 'commerceShopId'
  | 'state'
  | 'printReceiptTemplateId'
  | 'name'
  | 'sectors'
  | 'createdAt'
  | 'updatedAt'
>;

export type CateringFragment = { __typename?: 'Catering' } & {
  eventPlace?: Maybe<{ __typename?: 'EventPlace' } & EventPlaceFragment>;
  commerceShop?: Maybe<{ __typename?: 'CommerceShop' } & CommerceShopFlatFragment>;
} & CateringFlatFragment;

export type CateringConnectionFragment = { __typename?: 'CateringConnection' } & Pick<
  CateringConnection,
  'total' | 'limit' | 'cursor' | 'sortable'
> & { edges: Array<{ __typename?: 'Catering' } & CateringFragment> };

export type ClientFragment = { __typename?: 'Client' } & Pick<
  Client,
  'id' | 'title' | 'role' | 'type' | 'logo' | 'website' | 'colors' | 'domain' | 'createdAt' | 'updatedAt'
>;

export type ClientPublicProfileFragment = { __typename?: 'ClientPublicProfile' } & Pick<
  ClientPublicProfile,
  'id' | 'title'
> & { settings?: Maybe<{ __typename?: 'ClientPublicSettings' } & ClientPublicSettingsFragment> };

export type ClientPublicSettingsFragment = { __typename?: 'ClientPublicSettings' } & Pick<
  ClientPublicSettings,
  'name' | 'logo' | 'website' | 'colors' | 'defaultLocale' | 'domain'
>;

export type CommerceOrderUpdateFragment = { __typename?: 'CommerceOrder' } & Pick<
  CommerceOrder,
  | 'id'
  | 'shopId'
  | 'clientId'
  | 'state'
  | 'paymentState'
  | 'userId'
  | 'externalEntityId'
  | 'code'
  | 'total'
  | 'paidAmount'
  | 'createdAt'
  | 'updatedAt'
>;

export type CommerceOrderFragment = { __typename?: 'CommerceOrder' } & Pick<
  CommerceOrder,
  | 'id'
  | 'shopId'
  | 'clientId'
  | 'state'
  | 'paymentState'
  | 'userId'
  | 'externalEntityId'
  | 'code'
  | 'total'
  | 'paidAmount'
  | 'createdAt'
  | 'updatedAt'
> & {
    user: { __typename?: 'User' } & UserFlatFragment;
    data?: Maybe<Array<{ __typename?: 'CommerceOrderData' } & Pick<CommerceOrderData, 'key' | 'value'>>>;
    items?: Maybe<Array<{ __typename?: 'CommerceOrderItem' } & CommerceOrderItemFragment>>;
  };

export type CommerceOrderConnectionFragment = { __typename?: 'CommerceOrderConnection' } & Pick<
  CommerceOrderConnection,
  'total' | 'limit' | 'cursor' | 'sortable'
> & { edges: Array<{ __typename?: 'CommerceOrder' } & CommerceOrderFragment> };

export type CommerceOrderDataFragment = { __typename?: 'CommerceOrderData' } & Pick<
  CommerceOrderData,
  'id' | 'value' | 'orderFieldId' | 'key' | 'createdAt' | 'updatedAt'
> & { field: { __typename?: 'CommerceOrderField' } & CommerceOrderFieldFragment };

export type CommerceOrderDataConnectionFragment = { __typename?: 'CommerceOrderDataConnection' } & Pick<
  CommerceOrderDataConnection,
  'total' | 'limit' | 'cursor' | 'sortable'
> & { edges: Array<{ __typename?: 'CommerceOrderData' } & CommerceOrderDataFragment> };

export type CommerceOrderFieldFragment = { __typename?: 'CommerceOrderField' } & Pick<
  CommerceOrderField,
  'id' | 'shopId' | 'name' | 'dataType' | 'hasLocale' | 'sort' | 'createdAt' | 'updatedAt'
>;

export type CommerceOrderFieldConnectionFragment = { __typename?: 'CommerceOrderFieldConnection' } & Pick<
  CommerceOrderFieldConnection,
  'total' | 'limit' | 'cursor' | 'sortable'
> & { edges: Array<{ __typename?: 'CommerceOrderField' } & CommerceOrderFieldFragment> };

export type CommerceOrderFieldTranslationFragment = { __typename?: 'CommerceOrderFieldTranslation' } & Pick<
  CommerceOrderFieldTranslation,
  'id' | 'localeId' | 'value' | 'createdAt' | 'updatedAt'
>;

export type CommerceOrderItemFlatFragment = { __typename?: 'CommerceOrderItem' } & Pick<
  CommerceOrderItem,
  | 'id'
  | 'orderId'
  | 'state'
  | 'catalogueItemId'
  | 'discountId'
  | 'currencyId'
  | 'qty'
  | 'price'
  | 'discountedPrice'
  | 'total'
  | 'createdAt'
  | 'updatedAt'
>;

export type CommerceOrderItemFragment = { __typename?: 'CommerceOrderItem' } & {
  catalogueItem: { __typename?: 'CatalogueItem' } & CatalogueItemFlatFragment;
} & CommerceOrderItemFlatFragment;

export type CommerceOrderItemConnectionFragment = { __typename?: 'CommerceOrderItemConnection' } & Pick<
  CommerceOrderItemConnection,
  'total' | 'limit' | 'cursor' | 'sortable'
> & { edges: Array<{ __typename?: 'CommerceOrderItem' } & CommerceOrderItemFragment> };

export type CommerceOrderStatesWidgetDataFragment = { __typename?: 'CommerceOrderStatesWidgetData' } & Pick<
  CommerceOrderStatesWidgetData,
  'state' | 'count' | 'total'
>;

export type CommerceShopFlatFragment = { __typename?: 'CommerceShop' } & Pick<
  CommerceShop,
  | 'id'
  | 'clientId'
  | 'state'
  | 'name'
  | 'description'
  | 'codeMask'
  | 'catalogueId'
  | 'currencyId'
  | 'createdAt'
  | 'updatedAt'
  | 'settings'
>;

export type CommerceShopFragment = { __typename?: 'CommerceShop' } & {
  currency?: Maybe<{ __typename?: 'Currency' } & CurrencyFragment>;
  catalogue?: Maybe<{ __typename?: 'Catalogue' } & CatalogueFlatFragment>;
} & CommerceShopFlatFragment;

export type CommerceShopConnectionFragment = { __typename?: 'CommerceShopConnection' } & Pick<
  CommerceShopConnection,
  'total' | 'limit' | 'cursor' | 'sortable'
> & { edges: Array<{ __typename?: 'CommerceShop' } & CommerceShopFragment> };

export type CommerceShopGraphFragment = { __typename?: 'CommerceShopGraph' } & Pick<CommerceShopGraph, 'name'> & {
    data?: Maybe<
      Array<{ __typename?: 'CommerceShopGraphData' } & Pick<CommerceShopGraphData, 'date' | 'total' | 'paidAmount'>>
    >;
  };

export type CommerceShopSummaryFragment = { __typename?: 'CommerceShopSummary' } & Pick<
  CommerceShopSummary,
  'ordersCount' | 'itemsCount'
> & { topItems?: Maybe<Array<{ __typename?: 'CatalogueItem' } & CatalogueItemFragment>> };

export type CommerceSummaryWidgetFragment = { __typename?: 'CommerceSummaryWidget' } & {
  shop: { __typename?: 'CommerceShop' } & CommerceShopFragment;
  summary: { __typename?: 'CommerceShopSummary' } & CommerceShopSummaryFragment;
  graphs?: Maybe<Array<{ __typename?: 'CommerceShopGraph' } & CommerceShopGraphFragment>>;
};

export type CommerceTemplateFragment = { __typename?: 'CommerceTemplate' } & Pick<
  CommerceTemplate,
  'id' | 'clientId' | 'name' | 'preview' | 'body' | 'createdAt' | 'updatedAt'
>;

export type CommerceTemplateConnectionFragment = { __typename?: 'CommerceTemplateConnection' } & Pick<
  CommerceTemplateConnection,
  'total' | 'limit' | 'cursor' | 'sortable'
> & { edges: Array<{ __typename?: 'CommerceTemplate' } & CommerceTemplateFragment> };

export type CommonGraphFragment = { __typename?: 'CommonGraph' } & Pick<CommonGraph, 'name'> & {
    dataset: Array<{ __typename?: 'CommonGraphDataset' } & CommonGraphDatasetFragment>;
  };

export type CommonGraphDatasetFragment = { __typename?: 'CommonGraphDataset' } & Pick<CommonGraphDataset, 'label'> & {
    data: Array<{ __typename?: 'CommonGraphDatasetData' } & CommonGraphDatasetDataFragment>;
  };

export type CommonGraphDatasetDataFragment = { __typename?: 'CommonGraphDatasetData' } & Pick<
  CommonGraphDatasetData,
  'x' | 'y'
>;

export type ContentTranslationFragment = { __typename?: 'ContentTranslation' } & Pick<
  ContentTranslation,
  'id' | 'clientId' | 'channel' | 'localeId' | 'key' | 'value' | 'createdAt' | 'updatedAt'
>;

export type ContentTranslationConnectionFragment = { __typename?: 'ContentTranslationConnection' } & Pick<
  ContentTranslationConnection,
  'total' | 'limit' | 'cursor' | 'sortable'
> & { edges: Array<{ __typename?: 'ContentTranslation' } & ContentTranslationFragment> };

export type CurrencyFragment = { __typename?: 'Currency' } & Pick<
  Currency,
  'id' | 'iso3' | 'name' | 'sign' | 'isDefault' | 'isVirtual' | 'createdAt' | 'updatedAt'
>;

export type DisputeFlatFragment = { __typename?: 'Dispute' } & Pick<
  Dispute,
  | 'id'
  | 'state'
  | 'result'
  | 'confirmation'
  | 'userId'
  | 'matchId'
  | 'amount'
  | 'winAmount'
  | 'text'
  | 'createdAt'
  | 'updatedAt'
>;

export type DisputeFragment = { __typename?: 'Dispute' } & {
  user: { __typename?: 'User' } & UserFlatFragment;
  match: { __typename?: 'TournamentMatch' } & TournamentMatchFragment;
} & DisputeFlatFragment;

export type DisputeConnectionFragment = { __typename?: 'DisputeConnection' } & Pick<
  DisputeConnection,
  'total' | 'limit' | 'cursor' | 'sortable' | 'searchable'
> & { edges: Array<{ __typename?: 'Dispute' } & DisputeFragment> };

export type DisputeDisputeResponseFlatFragment = { __typename?: 'DisputeDisputeResponse' } & Pick<
  DisputeDisputeResponse,
  | 'id'
  | 'clientId'
  | 'disputeId'
  | 'state'
  | 'userId'
  | 'amount'
  | 'comment'
  | 'respondedAt'
  | 'createdAt'
  | 'updatedAt'
>;

export type DisputeDisputeResponseFragment = { __typename?: 'DisputeDisputeResponse' } & {
  user: { __typename?: 'User' } & UserFlatFragment;
} & DisputeDisputeResponseFlatFragment;

export type DisputeDisputeResponseConnectionFragment = { __typename?: 'DisputeDisputeResponseConnection' } & Pick<
  DisputeDisputeResponseConnection,
  'total' | 'limit' | 'cursor' | 'sortable' | 'searchable'
> & { edges: Array<{ __typename?: 'DisputeDisputeResponse' } & DisputeDisputeResponseFragment> };

export type DisputeSettingsFragment = { __typename?: 'DisputeSettings' } & Pick<
  DisputeSettings,
  'id' | 'clientId' | 'pushTemplates' | 'simulators' | 'transportId' | 'createdAt' | 'updatedAt'
>;

export type EventFlatFragment = { __typename?: 'Event' } & Pick<
  Event,
  | 'id'
  | 'image'
  | 'state'
  | 'title'
  | 'eventTypeId'
  | 'eventPlaceId'
  | 'description'
  | 'saleStartDate'
  | 'saleFinishDate'
  | 'startDate'
  | 'finishDate'
  | 'availableSlots'
  | 'saleLimit'
  | 'parkings'
  | 'isFanIdRequired'
>;

export type EventFullFragment = { __typename?: 'Event' } & {
  price?: Maybe<{ __typename?: 'EventPrice' } & EventPriceFragment>;
  eventType: { __typename?: 'EventType' } & EventTypeFragment;
  tournamentMatch?: Maybe<{ __typename?: 'TournamentMatch' } & TournamentMatchFragment>;
  loyaltyDiscount?: Maybe<{ __typename?: 'LoyaltyDiscount' } & LoyaltyDiscountFragment>;
  promo?: Maybe<{ __typename?: 'LoyaltyPromo' } & LoyaltyPromoFragment>;
} & EventFlatFragment;

export type EventWidgetFragment = { __typename?: 'Event' } & {
  eventType: { __typename?: 'EventType' } & EventTypeFragment;
  tournamentMatch?: Maybe<{ __typename?: 'TournamentMatch' } & TournamentMatchFragment>;
} & EventFlatFragment;

export type EventCalendarFragmentFragment = { __typename?: 'Event' } & Pick<
  Event,
  'id' | 'state' | 'isFanIdRequired' | 'saleStartDate' | 'saleFinishDate' | 'startDate'
>;

export type EventConnectionFragment = { __typename?: 'EventConnection' } & Pick<
  EventConnection,
  'total' | 'limit' | 'cursor' | 'sortable'
> & { edges: Array<{ __typename?: 'Event' } & EventFullFragment> };

export type EventEventAccessFragment = { __typename?: 'EventEventAccess' } & Pick<
  EventEventAccess,
  'id' | 'eventId' | 'isVisibleToPublic' | 'userGroupToSector' | 'createdAt' | 'updatedAt'
>;

export type EventEventAccessConnectionFragment = { __typename?: 'EventEventAccessConnection' } & Pick<
  EventEventAccessConnection,
  'total' | 'limit' | 'cursor' | 'sortable' | 'searchable'
> & { edges: Array<{ __typename?: 'EventEventAccess' } & EventEventAccessFragment> };

export type EventFieldsConnectionFragment = { __typename?: 'EventFieldsConnection' } & Pick<
  EventFieldsConnection,
  'total' | 'limit' | 'cursor' | 'sortable'
> & { edges: Array<{ __typename?: 'ExtensionField' } & ExtensionFieldFragment> };

export type EventOrderFlatFragment = { __typename?: 'EventOrder' } & Pick<
  EventOrder,
  | 'id'
  | 'state'
  | 'paymentState'
  | 'discountId'
  | 'userId'
  | 'code'
  | 'total'
  | 'paidAmount'
  | 'reservedTill'
  | 'isFiscal'
  | 'meta'
  | 'createdAt'
  | 'eventId'
>;

export type EventOrderFragment = { __typename?: 'EventOrder' } & {
  event: { __typename?: 'Event' } & EventFullFragment;
  tickets: Array<
    { __typename?: 'EventOrderTicket' } & Pick<
      EventOrderTicket,
      | 'id'
      | 'sector'
      | 'row'
      | 'seat'
      | 'eventOrderId'
      | 'eventId'
      | 'barcode'
      | 'price'
      | 'quantity'
      | 'pathToPDF'
      | 'fanCode'
    > & { data: Array<{ __typename?: 'ExtensionData' } & Pick<ExtensionData, 'id' | 'key' | 'value'>> }
  >;
} & EventOrderFlatFragment;

export type EventOrderMinFragment = { __typename?: 'EventOrder' } & Pick<EventOrder, 'id'> & {
    tickets: Array<{ __typename?: 'EventOrderTicket' } & Pick<EventOrderTicket, 'id'>>;
  };

export type EventOrderConnectionFragment = { __typename?: 'EventOrderConnection' } & Pick<
  EventOrderConnection,
  'total' | 'limit' | 'cursor' | 'sortable'
> & { edges: Array<{ __typename?: 'EventOrder' } & EventOrderFragment> };

export type EventOrderConnectionMinFragment = { __typename?: 'EventOrderConnection' } & Pick<
  EventOrderConnection,
  'total' | 'limit' | 'cursor' | 'sortable'
> & { edges: Array<{ __typename?: 'EventOrder' } & EventOrderMinFragment> };

export type EventOrderTicketFlatFragment = { __typename?: 'EventOrderTicket' } & Pick<
  EventOrderTicket,
  'id' | 'eventOrderId' | 'eventId' | 'barcode' | 'price' | 'quantity' | 'discountId' | 'createdAt' | 'updatedAt'
>;

export type EventOrderTicketWithDataFragment = { __typename?: 'EventOrderTicket' } & {
  data: Array<{ __typename?: 'ExtensionData' } & ExtensionDataFragment>;
} & EventOrderTicketFlatFragment;

export type EventOrderTicketFullFragment = { __typename?: 'EventOrderTicket' } & {
  data: Array<{ __typename?: 'ExtensionData' } & ExtensionDataFragment>;
  eventOrder: { __typename?: 'EventOrder' } & { user: { __typename?: 'User' } & UserFlatFragment } & EventOrderFragment;
} & EventOrderTicketFlatFragment;

export type EventOrderTicketConnectionFragment = { __typename?: 'EventOrderTicketConnection' } & Pick<
  EventOrderTicketConnection,
  'total' | 'limit' | 'cursor' | 'sortable'
> & { edges: Array<{ __typename?: 'EventOrderTicket' } & EventOrderTicketFullFragment> };

export type EventPlaceFragment = { __typename?: 'EventPlace' } & Pick<
  EventPlace,
  'id' | 'clientId' | 'name' | 'address' | 'createdAt' | 'updatedAt'
> & { locales?: Maybe<Array<{ __typename?: 'SystemLocale' } & SystemLocaleFragment>> };

export type EventPlaceConnectionFragment = { __typename?: 'EventPlaceConnection' } & Pick<
  EventPlaceConnection,
  'total' | 'limit' | 'cursor' | 'sortable'
> & { edges: Array<{ __typename?: 'EventPlace' } & EventPlaceFragment> };

export type EventPriceFragment = { __typename?: 'EventPrice' } & Pick<EventPrice, 'value' | 'discountValue'>;

export type EventReserveFragment = { __typename?: 'EventReserve' } & Pick<
  EventReserve,
  'id' | 'uuid' | 'eventId' | 'userId' | 'expiryDate' | 'meta' | 'createdAt'
> & { seats: Array<{ __typename?: 'EventReserveSeat' } & EventReserveSeatFragment> };

export type EventReserveConnectionFragment = { __typename?: 'EventReserveConnection' } & Pick<
  EventReserveConnection,
  'total' | 'limit' | 'cursor' | 'sortable'
> & { edges: Array<{ __typename?: 'EventReserve' } & EventReserveFragment> };

export type EventReserveSeatFragment = { __typename?: 'EventReserveSeat' } & Pick<
  EventReserveSeat,
  'seatId' | 'sector' | 'row' | 'seat' | 'price'
>;

export type EventSectorFragment = { __typename?: 'EventSector' } & Pick<
  EventSector,
  'key' | 'name' | 'quantity' | 'category' | 'isSectorSaleEnabled' | 'discountAvailableSlots' | 'sectorId' | 'rotation'
> & {
    discountSectorPrice?: Maybe<{ __typename?: 'EventSectorPrice' } & EventSectorPriceFragment>;
    eventSectorPrice?: Maybe<{ __typename?: 'EventSectorPrice' } & EventSectorPriceFragment>;
    loyaltyDiscount?: Maybe<{ __typename?: 'LoyaltyDiscount' } & LoyaltyDiscountFragment>;
  };

export type EventSectorPriceFragment = { __typename?: 'EventSectorPrice' } & Pick<
  EventSectorPrice,
  'priceCategoryId' | 'priceMin' | 'priceMax' | 'priceDiscount'
>;

export type EventSectorRowFragment = { __typename?: 'EventSectorRow' } & Pick<EventSectorRow, 'row'> & {
    seats: Array<{ __typename?: 'EventSectorSeat' } & EventSectorSeatFragment>;
  };

export type EventSectorSeatFragment = { __typename?: 'EventSectorSeat' } & Pick<
  EventSectorSeat,
  'seatId' | 'name' | 'row' | 'seat' | 'price' | 'isReserved'
>;

export type EventTypeFragment = { __typename?: 'EventType' } & Pick<
  EventType,
  'id' | 'name' | 'description' | 'image' | 'templates'
>;

export type EventTypeConnectionFragment = { __typename?: 'EventTypeConnection' } & Pick<
  EventTypeConnection,
  'total' | 'limit' | 'cursor' | 'sortable'
> & { edges: Array<{ __typename?: 'EventType' } & EventTypeFragment> };

export type EventTypesConfigFragment = { __typename?: 'EventTypesConfig' } & {
  abonement?: Maybe<{ __typename?: 'EventType' } & EventTypeFragment>;
  parking?: Maybe<{ __typename?: 'EventType' } & EventTypeFragment>;
  tour?: Maybe<{ __typename?: 'EventType' } & EventTypeFragment>;
  match?: Maybe<{ __typename?: 'EventType' } & EventTypeFragment>;
  concert?: Maybe<{ __typename?: 'EventType' } & EventTypeFragment>;
};

export type EventWidgetActiveEventsFragment = { __typename?: 'EventWidgetActiveEvents' } & Pick<
  EventWidgetActiveEvents,
  'activeCount' | 'todayCount'
>;

export type EventWidgetTicketSalesTotalsFragment = { __typename?: 'EventWidgetTicketSalesTotals' } & Pick<
  EventWidgetTicketSalesTotals,
  'totalCount' | 'soldCount' | 'availableCount' | 'soldAmount'
>;

export type ExtensionDataFragment = { __typename?: 'ExtensionData' } & Pick<
  ExtensionData,
  'id' | 'localeId' | 'value' | 'key' | 'createdAt' | 'updatedAt'
> & { field: { __typename?: 'ExtensionField' } & ExtensionFieldFragment };

export type ExtensionFieldFragment = { __typename?: 'ExtensionField' } & Pick<
  ExtensionField,
  'id' | 'relationId' | 'name' | 'dataType' | 'isReadOnly' | 'sort' | 'createdAt' | 'updatedAt'
> & { translations: Array<{ __typename?: 'ExtensionFieldTranslation' } & ExtensionFieldTranslationFragment> };

export type ExtensionFieldTranslationFragment = { __typename?: 'ExtensionFieldTranslation' } & Pick<
  ExtensionFieldTranslation,
  'id' | 'localeId' | 'fieldId' | 'value' | 'createdAt' | 'updatedAt'
>;

export type FileFragment = { __typename?: 'File' } & Pick<File, 'url' | 'file'>;

export type FilterItemFragment = { __typename: 'FilterItem' } & Pick<
  FilterItem,
  'field' | 'type' | 'dataType' | 'isRangeAvailable' | 'isExcludAvailable' | 'min' | 'max' | 'step'
> & { options?: Maybe<Array<Maybe<{ __typename: 'FilterItemOption' } & Pick<FilterItemOption, 'id' | 'name'>>>> };

export type FoodOrderItemFragment = { __typename?: 'FoodOrderItem' } & Pick<
  FoodOrderItem,
  | 'id'
  | 'state'
  | 'name'
  | 'preview'
  | 'eventId'
  | 'quantity'
  | 'price'
  | 'currencyId'
  | 'total'
  | 'description'
  | 'categoryId'
  | 'createdAt'
  | 'updatedAt'
> & { images: Array<{ __typename?: 'File' } & FileFragment> };

export type LocaleFragment = { __typename?: 'Locale' } & Pick<
  Locale,
  'id' | 'iso' | 'title' | 'createdAt' | 'updatedAt'
>;

export type LoyaltyAccountSummaryFragment = { __typename?: 'LoyaltyAccountSummary' } & Pick<
  LoyaltyAccountSummary,
  'applicationsCount' | 'accountsCount' | 'unprocessedCount'
>;

export type LoyaltyDiscountFragment = { __typename?: 'LoyaltyDiscount' } & Pick<LoyaltyDiscount, 'discountPercent'> & {
    order?: Maybe<{ __typename?: 'LoyaltyOrder' } & Pick<LoyaltyOrder, 'id'>>;
  };

export type LoyaltyItemFragment = { __typename?: 'LoyaltyItem' } & Pick<
  LoyaltyItem,
  'id' | 'state' | 'name' | 'preview' | 'eventId' | 'title' | 'description' | 'information' | 'popularity'
> & {
    price?: Maybe<{ __typename?: 'CatalogueItemPrice' } & CatalogueItemPriceFragment>;
    images: Array<{ __typename?: 'File' } & FileFragment>;
  };

export type LoyaltyItemConnectionFragment = { __typename?: 'LoyaltyItemConnection' } & Pick<
  LoyaltyItemConnection,
  'total' | 'limit' | 'cursor' | 'sortable'
> & { edges: Array<{ __typename?: 'LoyaltyItem' } & LoyaltyItemFragment> };

export type LoyaltyOrderFlatFragment = { __typename?: 'LoyaltyOrder' } & Pick<
  LoyaltyOrder,
  'id' | 'state' | 'paymentState' | 'code' | 'total' | 'paidAmount' | 'createdAt' | 'updatedAt'
>;

export type LoyaltyOrderFragment = { __typename?: 'LoyaltyOrder' } & {
  items?: Maybe<Array<{ __typename?: 'LoyaltyItem' } & LoyaltyItemFragment>>;
} & LoyaltyOrderFlatFragment;

export type LoyaltyOrderConnectionFragment = { __typename?: 'LoyaltyOrderConnection' } & Pick<
  LoyaltyOrderConnection,
  'total' | 'limit' | 'cursor' | 'sortable'
> & { edges: Array<{ __typename?: 'LoyaltyOrder' } & LoyaltyOrderFragment> };

export type LoyaltyPartnerFragment = { __typename?: 'LoyaltyPartner' } & Pick<
  LoyaltyPartner,
  | 'id'
  | 'clientId'
  | 'state'
  | 'name'
  | 'description'
  | 'logo'
  | 'website'
  | 'shopId'
  | 'providerId'
  | 'createdAt'
  | 'updatedAt'
>;

export type LoyaltyPartnerConnectionFragment = { __typename?: 'LoyaltyPartnerConnection' } & Pick<
  LoyaltyPartnerConnection,
  'total' | 'limit' | 'cursor' | 'sortable'
> & { edges: Array<{ __typename?: 'LoyaltyPartner' } & LoyaltyPartnerFragment> };

export type LoyaltyPartnerEntityConnectionFragment = { __typename?: 'LoyaltyPartnerEntityConnection' } & Pick<
  LoyaltyPartnerEntityConnection,
  'total' | 'limit' | 'cursor' | 'sortable' | 'edges'
>;

export type LoyaltyPromoFragment = { __typename?: 'LoyaltyPromo' } & Pick<
  LoyaltyPromo,
  | 'id'
  | 'clientId'
  | 'name'
  | 'description'
  | 'imageUri'
  | 'state'
  | 'amount'
  | 'currencyId'
  | 'code'
  | 'codeType'
  | 'codesCount'
  | 'startDate'
  | 'finishDate'
  | 'promoCodeStartDate'
  | 'promoCodeFinishDate'
  | 'discountMaxAmount'
  | 'discountPercent'
  | 'createdAt'
  | 'updatedAt'
>;

export type LoyaltyPromoCodeFragment = { __typename?: 'LoyaltyPromoCode' } & Pick<
  LoyaltyPromoCode,
  | 'id'
  | 'promoId'
  | 'userId'
  | 'state'
  | 'code'
  | 'amount'
  | 'amountUsed'
  | 'currencyId'
  | 'expiredAt'
  | 'appliedAt'
  | 'createdAt'
  | 'updatedAt'
>;

export type LoyaltyPromoCodeFullFragment = { __typename?: 'LoyaltyPromoCode' } & Pick<
  LoyaltyPromoCode,
  | 'id'
  | 'promoId'
  | 'userId'
  | 'state'
  | 'code'
  | 'amount'
  | 'amountUsed'
  | 'currencyId'
  | 'expiredAt'
  | 'appliedAt'
  | 'createdAt'
  | 'updatedAt'
> & { promo: { __typename?: 'LoyaltyPromo' } & LoyaltyPromoFragment };

export type LoyaltyPromoCodeConnectionFragment = { __typename?: 'LoyaltyPromoCodeConnection' } & Pick<
  LoyaltyPromoCodeConnection,
  'total' | 'limit' | 'cursor' | 'sortable'
> & { edges: Array<{ __typename?: 'LoyaltyPromoCode' } & LoyaltyPromoCodeFragment> };

export type LoyaltyPromoConnectionFragment = { __typename?: 'LoyaltyPromoConnection' } & Pick<
  LoyaltyPromoConnection,
  'total' | 'limit' | 'cursor' | 'sortable'
> & { edges: Array<{ __typename?: 'LoyaltyPromo' } & LoyaltyPromoFragment> };

export type LoyaltySummaryGraphFragment = { __typename?: 'LoyaltySummaryGraph' } & Pick<LoyaltySummaryGraph, 'name'> & {
    dataset: Array<{ __typename?: 'LoyaltySummaryGraphDataset' } & LoyaltySummaryGraphDatasetFragment>;
  };

export type LoyaltySummaryGraphDatasetFragment = { __typename?: 'LoyaltySummaryGraphDataset' } & Pick<
  LoyaltySummaryGraphDataset,
  'label'
> & { data: Array<{ __typename?: 'LoyaltySummaryGraphDatasetData' } & LoyaltySummaryGraphDatasetDataFragment> };

export type LoyaltySummaryGraphDatasetDataFragment = { __typename?: 'LoyaltySummaryGraphDatasetData' } & Pick<
  LoyaltySummaryGraphDatasetData,
  'x' | 'y'
>;

export type LoyaltySummaryWidgetFragment = { __typename?: 'LoyaltySummaryWidget' } & {
  partner: { __typename?: 'LoyaltyPartner' } & LoyaltyPartnerFragment;
  summary: { __typename?: 'LoyaltyAccountSummary' } & LoyaltyAccountSummaryFragment;
  graphs?: Maybe<Array<{ __typename?: 'LoyaltySummaryGraph' } & LoyaltySummaryGraphFragment>>;
};

export type MessengerMailingFragment = { __typename?: 'MessengerMailing' } & Pick<
  MessengerMailing,
  | 'id'
  | 'clientId'
  | 'name'
  | 'sendAfter'
  | 'sender'
  | 'channel'
  | 'preview'
  | 'transportId'
  | 'state'
  | 'subject'
  | 'richText'
  | 'metaDesign'
  | 'text'
  | 'templateId'
  | 'createdAt'
  | 'updatedAt'
> & {
    stats?: Maybe<Array<{ __typename?: 'MessengerQueueNotificationStat' } & MessengerQueueNotificationStatFragment>>;
    currentTask?: Maybe<{ __typename?: 'MessengerQueueTask' } & MessengerQueueTaskFragment>;
  };

export type MessengerMailingConnectionFragment = { __typename?: 'MessengerMailingConnection' } & Pick<
  MessengerMailingConnection,
  'total' | 'limit' | 'cursor' | 'sortable'
> & { edges: Array<{ __typename?: 'MessengerMailing' } & MessengerMailingFragment> };

export type MessengerQueueNotificationFragment = { __typename?: 'MessengerQueueNotification' } & Pick<
  MessengerQueueNotification,
  | 'id'
  | 'clientId'
  | 'mailingId'
  | 'userId'
  | 'state'
  | 'channel'
  | 'willSendAt'
  | 'sender'
  | 'recipient'
  | 'payload'
  | 'envelope'
  | 'createdAt'
  | 'updatedAt'
> & { mailing?: Maybe<{ __typename?: 'MessengerMailing' } & MessengerMailingFragment> };

export type MessengerQueueNotificationConnectionFragment = {
  __typename?: 'MessengerQueueNotificationConnection';
} & Pick<MessengerQueueNotificationConnection, 'total' | 'limit' | 'cursor' | 'sortable' | 'searchable'> & {
    edges: Array<{ __typename?: 'MessengerQueueNotification' } & MessengerQueueNotificationFragment>;
  };

export type MessengerQueueNotificationStatFragment = { __typename?: 'MessengerQueueNotificationStat' } & Pick<
  MessengerQueueNotificationStat,
  'state' | 'value'
>;

export type MessengerQueueTaskFragment = { __typename?: 'MessengerQueueTask' } & Pick<
  MessengerQueueTask,
  | 'id'
  | 'mailingId'
  | 'operation'
  | 'state'
  | 'itemsToProcess'
  | 'itemsProcessed'
  | 'itemsInvalid'
  | 'createdAt'
  | 'updatedAt'
>;

export type MessengerQueueTaskConnectionFragment = { __typename?: 'MessengerQueueTaskConnection' } & Pick<
  MessengerQueueTaskConnection,
  'total' | 'limit' | 'cursor' | 'sortable'
> & { edges: Array<{ __typename?: 'MessengerQueueTask' } & MessengerQueueTaskFragment> };

export type MessengerSettingFragment = { __typename?: 'MessengerSetting' } & Pick<
  MessengerSetting,
  | 'id'
  | 'clientId'
  | 'testers'
  | 'defaultSender'
  | 'createdAt'
  | 'updatedAt'
  | 'emailDefault'
  | 'defaultSubscription'
  | 'smsDefault'
  | 'pushDefault'
>;

export type MessengerTransportFragment = { __typename?: 'MessengerTransport' } & Pick<
  MessengerTransport,
  'id' | 'clientId' | 'name' | 'settings' | 'providerId' | 'state' | 'createdAt' | 'updatedAt' | 'channel'
>;

export type MessengerTransportConnectionFragment = { __typename?: 'MessengerTransportConnection' } & Pick<
  MessengerTransportConnection,
  'total' | 'limit' | 'cursor' | 'sortable'
> & { edges: Array<{ __typename?: 'MessengerTransport' } & MessengerTransportFragment> };

export type MobileTranslationFragment = { __typename?: 'MobileTranslation' } & Pick<
  MobileTranslation,
  'id' | 'locale' | 'key' | 'value' | 'updatedAt'
>;

export type NavigationFragment = { __typename?: 'Navigation' } & Pick<
  Navigation,
  'id' | 'parentId' | 'name' | 'url' | 'icon' | 'sort'
> & {
    children?: Maybe<
      Array<{ __typename?: 'Navigation' } & Pick<Navigation, 'id' | 'parentId' | 'name' | 'url' | 'icon' | 'sort'>>
    >;
  };

export type PaymentAccountFlatFragment = { __typename?: 'PaymentAccount' } & Pick<
  PaymentAccount,
  | 'id'
  | 'state'
  | 'userId'
  | 'currencyId'
  | 'balance'
  | 'spent'
  | 'providerId'
  | 'providerCode'
  | 'createdAt'
  | 'updatedAt'
>;

export type PaymentAccountFragment = { __typename?: 'PaymentAccount' } & {
  user: { __typename?: 'User' } & UserFlatFragment;
  currency: { __typename?: 'PaymentCurrency' } & PaymentCurrencyFragment;
} & PaymentAccountFlatFragment;

export type PaymentAccountConnectionFragment = { __typename?: 'PaymentAccountConnection' } & Pick<
  PaymentAccountConnection,
  'total' | 'limit' | 'cursor' | 'sortable' | 'searchable'
> & { edges: Array<{ __typename?: 'PaymentAccount' } & PaymentAccountFragment> };

export type PaymentCurrencyFragment = { __typename?: 'PaymentCurrency' } & Pick<
  PaymentCurrency,
  'id' | 'clientId' | 'iso3' | 'name' | 'sign' | 'isDefault' | 'isVirtual' | 'createdAt' | 'updatedAt'
>;

export type PaymentCurrencyConnectionFragment = { __typename?: 'PaymentCurrencyConnection' } & Pick<
  PaymentCurrencyConnection,
  'total' | 'limit' | 'cursor' | 'sortable'
> & { edges: Array<{ __typename?: 'PaymentCurrency' } & PaymentCurrencyFragment> };

export type PaymentProviderFragment = { __typename?: 'PaymentProvider' } & Pick<
  PaymentProvider,
  'id' | 'gatewayId' | 'clientId' | 'state' | 'name' | 'settings' | 'createdAt' | 'updatedAt'
>;

export type PaymentProviderConnectionFragment = { __typename?: 'PaymentProviderConnection' } & Pick<
  PaymentProviderConnection,
  'total' | 'limit' | 'cursor' | 'sortable'
> & { edges: Array<{ __typename?: 'PaymentProvider' } & PaymentProviderFragment> };

export type PaymentTransactionFragment = { __typename?: 'PaymentTransaction' } & Pick<
  PaymentTransaction,
  | 'id'
  | 'state'
  | 'side'
  | 'userId'
  | 'accountId'
  | 'currencyId'
  | 'providerId'
  | 'amount'
  | 'bankCardId'
  | 'orderId'
  | 'orderType'
  | 'meta'
  | 'createdAt'
  | 'updatedAt'
>;

export type PaymentTransactionConnectionFragment = { __typename?: 'PaymentTransactionConnection' } & Pick<
  PaymentTransactionConnection,
  'total' | 'limit' | 'cursor' | 'sortable'
> & { edges: Array<{ __typename?: 'PaymentTransaction' } & PaymentTransactionFragment> };

export type ReserveFragment = { __typename?: 'Reserve' } & Pick<
  Reserve,
  'seatName' | 'seatId' | 'price' | 'eventId' | 'sector' | 'sectorId' | 'row' | 'seat'
> & { discount: { __typename?: 'ReserveDiscount' } & ReserveDiscountFragment };

export type ReserveDiscountFragment = { __typename?: 'ReserveDiscount' } & Pick<
  ReserveDiscount,
  'percent' | 'price'
> & { category: { __typename?: 'ReserveDiscountCategory' } & Pick<ReserveDiscountCategory, 'id' | 'name'> };

export type TournamentSeasonFullFragment = { __typename?: 'TournamentSeason' } & Pick<
  TournamentSeason,
  | 'id'
  | 'title'
  | 'description'
  | 'tournamentId'
  | 'startDate'
  | 'finishDate'
  | 'state'
  | 'sort'
  | 'createdAt'
  | 'updatedAt'
> & { teams: Array<{ __typename?: 'TournamentTeam' } & TournamentTeamFragment> };

export type TournamentSeasonTitleFragment = { __typename?: 'TournamentSeason' } & Pick<
  TournamentSeason,
  'id' | 'title'
>;

export type TournamentSeasonFragment = { __typename?: 'TournamentSeason' } & Pick<
  TournamentSeason,
  | 'id'
  | 'title'
  | 'description'
  | 'tournamentId'
  | 'startDate'
  | 'finishDate'
  | 'state'
  | 'sort'
  | 'createdAt'
  | 'updatedAt'
>;

export type SeasonTeamStatsFragment = { __typename?: 'TournamentSeasonTeamStat' } & Pick<
  TournamentSeasonTeamStat,
  | 'id'
  | 'position'
  | 'points'
  | 'games'
  | 'win'
  | 'loss'
  | 'draw'
  | 'yellowCards'
  | 'redCards'
  | 'goals'
  | 'shutouts'
  | 'missedGoals'
>;

export type SystemAsyncTaskFragment = { __typename?: 'SystemAsyncTask' } & Pick<
  SystemAsyncTask,
  'id' | 'entity' | 'entityId' | 'operation' | 'state' | 'progress' | 'createdAt' | 'updatedAt'
>;

export type SystemAsyncTaskConnectionFragment = { __typename?: 'SystemAsyncTaskConnection' } & Pick<
  SystemAsyncTaskConnection,
  'total' | 'limit' | 'cursor' | 'sortable'
> & { edges: Array<{ __typename?: 'SystemAsyncTask' } & SystemAsyncTaskFragment> };

export type SystemLocaleFragment = { __typename?: 'SystemLocale' } & Pick<
  SystemLocale,
  'id' | 'iso' | 'title' | 'createdAt' | 'updatedAt'
>;

export type SystemLocaleConnectionFragment = { __typename?: 'SystemLocaleConnection' } & Pick<
  SystemLocaleConnection,
  'total' | 'limit' | 'cursor' | 'sortable'
> & { edges: Array<{ __typename?: 'SystemLocale' } & SystemLocaleFragment> };

export type SystemLoginFragment = { __typename?: 'SystemLogin' } & Pick<
  SystemLogin,
  'accessToken' | 'refreshToken' | 'clientId'
> & {
    currentUser: { __typename?: 'SystemUser' } & SystemUserFragment;
    clients?: Maybe<Array<{ __typename?: 'Client' } & ClientFragment>>;
  };

export type SystemPrinterFragment = { __typename?: 'SystemPrinter' } & Pick<
  SystemPrinter,
  'id' | 'clientId' | 'name' | 'title' | 'model' | 'createdAt' | 'updatedAt'
>;

export type SystemPrinterConnectionFragment = { __typename?: 'SystemPrinterConnection' } & Pick<
  SystemPrinterConnection,
  'total' | 'limit' | 'cursor' | 'sortable'
> & { edges: Array<{ __typename?: 'SystemPrinter' } & SystemPrinterFragment> };

export type SystemTranslationFragment = { __typename?: 'SystemTranslation' } & Pick<
  SystemTranslation,
  'id' | 'locale' | 'key' | 'value' | 'createdAt' | 'updatedAt'
>;

export type SystemTranslationConnectionFragment = { __typename?: 'SystemTranslationConnection' } & Pick<
  SystemTranslationConnection,
  'total' | 'limit' | 'cursor' | 'sortable'
> & { edges: Array<{ __typename?: 'SystemTranslation' } & SystemTranslationFragment> };

export type SystemUserFragment = { __typename?: 'SystemUser' } & Pick<
  SystemUser,
  | 'id'
  | 'state'
  | 'email'
  | 'username'
  | 'phone'
  | 'firstName'
  | 'lastName'
  | 'middleName'
  | 'photo'
  | 'createdAt'
  | 'updatedAt'
  | 'locale'
> & {
    clients?: Maybe<Array<{ __typename?: 'Client' } & ClientFragment>>;
    ACL?: Maybe<Array<{ __typename?: 'ACL' } & AclFragment>>;
  };

export type SystemUsersConnectionFragment = { __typename?: 'SystemUsersConnection' } & Pick<
  SystemUsersConnection,
  'total' | 'limit' | 'cursor' | 'sortable'
> & { edges: Array<{ __typename?: 'SystemUser' } & SystemUserFragment> };

export type SystemWebHookFragment = { __typename?: 'SystemWebHook' } & Pick<
  SystemWebHook,
  'id' | 'clientId' | 'title' | 'entity' | 'operation' | 'url' | 'headers' | 'payload' | 'createdAt' | 'updatedAt'
>;

export type SystemWebHookConnectionFragment = { __typename?: 'SystemWebHookConnection' } & Pick<
  SystemWebHookConnection,
  'total' | 'limit' | 'cursor' | 'sortable'
> & { edges: Array<{ __typename?: 'SystemWebHook' } & SystemWebHookFragment> };

export type TournamentTeamConnectionFragment = { __typename?: 'TournamentTeamConnection' } & Pick<
  TournamentTeamConnection,
  'total' | 'limit' | 'cursor' | 'sortable'
> & { edges: Array<{ __typename?: 'TournamentTeam' } & TournamentTeamFragment> };

export type TeamHistoryFragment = { __typename?: 'TournamentTeamHistory' } & Pick<
  TournamentTeamHistory,
  'id' | 'field' | 'value' | 'translationId' | 'createdAt' | 'updatedAt'
>;

export type TemplateApplePassFragment = { __typename?: 'TemplateApplePass' } & Pick<
  TemplateApplePass,
  'id' | 'clientId' | 'preview' | 'name' | 'pass' | 'files' | 'certificates' | 'createdAt' | 'updatedAt'
>;

export type TemplateApplePassConnectionFragment = { __typename?: 'TemplateApplePassConnection' } & Pick<
  TemplateApplePassConnection,
  'total' | 'limit' | 'cursor' | 'sortable'
> & { edges: Array<{ __typename?: 'TemplateApplePass' } & TemplateApplePassFragment> };

export type TemplateNotificationFragment = { __typename?: 'TemplateNotification' } & Pick<
  TemplateNotification,
  | 'id'
  | 'clientId'
  | 'state'
  | 'channel'
  | 'sender'
  | 'name'
  | 'title'
  | 'richText'
  | 'preview'
  | 'text'
  | 'createdAt'
  | 'updatedAt'
>;

export type TemplateNotificationConnectionFragment = { __typename?: 'TemplateNotificationConnection' } & Pick<
  TemplateNotificationConnection,
  'total' | 'limit' | 'cursor' | 'sortable'
> & { edges: Array<{ __typename?: 'TemplateNotification' } & TemplateNotificationFragment> };

export type TemplatePdfFragment = { __typename?: 'TemplatePDF' } & Pick<
  TemplatePdf,
  'id' | 'clientId' | 'name' | 'preview' | 'body' | 'createdAt' | 'updatedAt'
>;

export type TemplatePdfConnectionFragment = { __typename?: 'TemplatePDFConnection' } & Pick<
  TemplatePdfConnection,
  'total' | 'limit' | 'cursor' | 'sortable'
> & { edges: Array<{ __typename?: 'TemplatePDF' } & TemplatePdfFragment> };

export type TournamentFullFragment = { __typename?: 'Tournament' } & Pick<
  Tournament,
  'id' | 'clientId' | 'title' | 'description' | 'website' | 'state' | 'preview' | 'sort' | 'createdAt' | 'updatedAt'
> & { seasons: Array<{ __typename?: 'TournamentSeason' } & TournamentSeasonTitleFragment> };

export type TournamentFragment = { __typename?: 'Tournament' } & Pick<
  Tournament,
  'id' | 'clientId' | 'title' | 'description' | 'website' | 'state' | 'preview' | 'sort' | 'createdAt' | 'updatedAt'
>;

export type TournamentConnectionFragment = { __typename?: 'TournamentConnection' } & Pick<
  TournamentConnection,
  'total' | 'limit' | 'cursor' | 'sortable'
> & { edges: Array<{ __typename?: 'Tournament' } & TournamentFullFragment> };

export type TournamentDisputeFlatFragment = { __typename?: 'TournamentDispute' } & Pick<
  TournamentDispute,
  | 'id'
  | 'state'
  | 'result'
  | 'confirmation'
  | 'userId'
  | 'matchId'
  | 'amount'
  | 'winAmount'
  | 'text'
  | 'createdAt'
  | 'updatedAt'
>;

export type TournamentDisputeFragment = { __typename?: 'TournamentDispute' } & {
  user: { __typename?: 'User' } & UserFlatFragment;
  match: { __typename?: 'TournamentMatch' } & TournamentMatchFragment;
} & TournamentDisputeFlatFragment;

export type TournamentDisqualificationFragment = { __typename?: 'TournamentDisqualification' } & Pick<
  TournamentDisqualification,
  | 'id'
  | 'playerId'
  | 'reason'
  | 'tournamentId'
  | 'resolutionDate'
  | 'disqualificationValue'
  | 'leftMatches'
  | 'state'
  | 'createdAt'
  | 'updatedAt'
>;

export type TournamentDisqualificationFullFragment = { __typename?: 'TournamentDisqualification' } & Pick<
  TournamentDisqualification,
  | 'id'
  | 'playerId'
  | 'reason'
  | 'tournamentId'
  | 'resolutionDate'
  | 'disqualificationValue'
  | 'leftMatches'
  | 'state'
  | 'createdAt'
  | 'updatedAt'
> & {
    player: { __typename?: 'TournamentTeamMember' } & TournamentTeamMemberFullFragment;
    tournament: { __typename?: 'Tournament' } & TournamentFragment;
  };

export type TournamentDisqualificationConnectionFragment = {
  __typename?: 'TournamentDisqualificationConnection';
} & Pick<TournamentDisqualificationConnection, 'total' | 'limit' | 'cursor' | 'sortable'> & {
    edges: Array<{ __typename?: 'TournamentDisqualification' } & TournamentDisqualificationFullFragment>;
  };

export type TournamentMatchFlatFragment = { __typename?: 'TournamentMatch' } & Pick<
  TournamentMatch,
  | 'id'
  | 'team1Id'
  | 'team2Id'
  | 'result'
  | 'team1IdGoals'
  | 'team2IdGoals'
  | 'state'
  | 'stadiumName'
  | 'stadiumAddress'
  | 'startDate'
  | 'startTime'
  | 'finishDate'
  | 'startedAt'
  | 'finishedAt'
>;

export type TournamentMatchFragment = { __typename?: 'TournamentMatch' } & {
  team1: { __typename?: 'TournamentTeam' } & TournamentTeamSmallFragment;
  team2: { __typename?: 'TournamentTeam' } & TournamentTeamSmallFragment;
} & TournamentMatchFlatFragment;

export type TournamentMatchDetailsFragment = { __typename?: 'TournamentMatch' } & {
  team1: { __typename?: 'TournamentTeam' } & TournamentTeamFragment;
  team2: { __typename?: 'TournamentTeam' } & TournamentTeamFragment;
} & TournamentMatchFlatFragment;

export type TournamentMatchConnectionFragment = { __typename?: 'TournamentMatchConnection' } & Pick<
  TournamentMatchConnection,
  'total' | 'limit' | 'cursor' | 'sortable'
> & { edges: Array<{ __typename?: 'TournamentMatch' } & TournamentMatchFragment> };

export type TournamentMatchEventFragment = { __typename?: 'TournamentMatchEvent' } & Pick<
  TournamentMatchEvent,
  | 'id'
  | 'matchId'
  | 'datetime'
  | 'minute'
  | 'event'
  | 'teamMemberId'
  | 'teamId'
  | 'comment'
  | 'isProtocol'
  | 'linkedMatchEventId'
  | 'createdAt'
  | 'updatedAt'
> & {
    player?: Maybe<{ __typename?: 'TournamentTeamMember' } & TournamentTeamMemberSmallFragment>;
    team?: Maybe<{ __typename?: 'TournamentTeam' } & TournamentTeamSmallFragment>;
  };

export type TournamentMatchEventConnectionFragment = { __typename?: 'TournamentMatchEventConnection' } & Pick<
  TournamentMatchEventConnection,
  'total' | 'limit' | 'cursor' | 'sortable'
> & { edges: Array<{ __typename?: 'TournamentMatchEvent' } & TournamentMatchEventFragment> };

export type TournamentMatchLineUpFragment = { __typename?: 'TournamentMatchLineUp' } & Pick<
  TournamentMatchLineUp,
  | 'id'
  | 'matchId'
  | 'teamId'
  | 'teamMemberId'
  | 'refereeId'
  | 'type'
  | 'initialRole'
  | 'finalRole'
  | 'initialState'
  | 'finalState'
  | 'number'
  | 'isTeamCaptain'
  | 'createdAt'
  | 'updatedAt'
> & {
    referee?: Maybe<{ __typename?: 'TournamentReferee' } & TournamentRefereeFragment>;
    team?: Maybe<{ __typename?: 'TournamentTeam' } & Pick<TournamentTeam, 'id' | 'name'>>;
    teamMember?: Maybe<{ __typename?: 'TournamentTeamMember' } & TournamentTeamMemberSmallFragment>;
  };

export type TournamentPlayerStatFragment = { __typename?: 'TournamentPlayerStat' } & Pick<
  TournamentPlayerStat,
  | 'id'
  | 'playerId'
  | 'seasonId'
  | 'games'
  | 'goals'
  | 'penaltyKicks'
  | 'passes'
  | 'yellowCards'
  | 'redCards'
  | 'missedGoals'
  | 'shutouts'
  | 'bombardierValue'
  | 'assistantValue'
  | 'goalsAndPassesValue'
  | 'goalkeeperValue'
  | 'warningValue'
  | 'createdAt'
  | 'updatedAt'
>;

export type TournamentPlayerStatFullFragment = { __typename?: 'TournamentPlayerStat' } & Pick<
  TournamentPlayerStat,
  | 'id'
  | 'playerId'
  | 'seasonId'
  | 'games'
  | 'goals'
  | 'penaltyKicks'
  | 'passes'
  | 'yellowCards'
  | 'redCards'
  | 'missedGoals'
  | 'shutouts'
  | 'bombardierValue'
  | 'assistantValue'
  | 'goalsAndPassesValue'
  | 'goalkeeperValue'
  | 'warningValue'
  | 'createdAt'
  | 'updatedAt'
> & { player: { __typename?: 'TournamentTeamMember' } & TournamentTeamMemberFragment };

export type TournamentPlayerStatConnectionFragment = { __typename?: 'TournamentPlayerStatConnection' } & Pick<
  TournamentPlayerStatConnection,
  'total' | 'limit' | 'cursor' | 'sortable'
> & { edges: Array<{ __typename?: 'TournamentPlayerStat' } & TournamentPlayerStatFragment> };

export type TournamentRefereeFragment = { __typename?: 'TournamentReferee' } & Pick<
  TournamentReferee,
  | 'id'
  | 'firstName'
  | 'lastName'
  | 'middleName'
  | 'birthday'
  | 'preview'
  | 'photo'
  | 'category'
  | 'createdAt'
  | 'updatedAt'
>;

export type TournamentRefereeConnectionFragment = { __typename?: 'TournamentRefereeConnection' } & Pick<
  TournamentRefereeConnection,
  'total' | 'limit' | 'cursor' | 'sortable'
> & { edges: Array<{ __typename?: 'TournamentReferee' } & TournamentRefereeFragment> };

export type TournamentSeasonConnectionFragment = { __typename?: 'TournamentSeasonConnection' } & Pick<
  TournamentSeasonConnection,
  'total' | 'limit' | 'cursor' | 'sortable'
> & { edges: Array<{ __typename?: 'TournamentSeason' } & TournamentSeasonFragment> };

export type TournamentSeasonTeamFragment = { __typename?: 'TournamentSeasonTeam' } & Pick<
  TournamentSeasonTeam,
  'id' | 'seasonId' | 'teamId' | 'createdAt' | 'updatedAt'
>;

export type TournamentSeasonTeamConnectionFragment = { __typename?: 'TournamentSeasonTeamConnection' } & Pick<
  TournamentSeasonTeamConnection,
  'total' | 'limit' | 'cursor' | 'sortable'
> & { edges: Array<{ __typename?: 'TournamentSeasonTeam' } & TournamentSeasonTeamFragment> };

export type TournamentSeasonTeamStatFragment = { __typename?: 'TournamentSeasonTeamStat' } & Pick<
  TournamentSeasonTeamStat,
  | 'id'
  | 'seasonId'
  | 'position'
  | 'teamId'
  | 'points'
  | 'games'
  | 'win'
  | 'draw'
  | 'loss'
  | 'goals'
  | 'missedGoals'
  | 'yellowCards'
  | 'redCards'
  | 'shutouts'
  | 'sort'
  | 'createdAt'
  | 'updatedAt'
>;

export type TournamentSeasonTeamStatConnectionFragment = { __typename?: 'TournamentSeasonTeamStatConnection' } & Pick<
  TournamentSeasonTeamStatConnection,
  'total' | 'limit' | 'cursor' | 'sortable'
> & { edges: Array<{ __typename?: 'TournamentSeasonTeamStat' } & TournamentSeasonTeamStatFragment> };

export type TournamentTeamFragment = { __typename?: 'TournamentTeam' } & Pick<
  TournamentTeam,
  | 'id'
  | 'clientId'
  | 'name'
  | 'logo'
  | 'altLogo'
  | 'coverPhoto'
  | 'website'
  | 'description'
  | 'history'
  | 'foundingDate'
  | 'teamForm'
  | 'createdAt'
  | 'updatedAt'
>;

export type TournamentTeamSmallFragment = { __typename?: 'TournamentTeam' } & Pick<
  TournamentTeam,
  'id' | 'name' | 'logo' | 'website'
>;

export type TournamentTeamDetailsFragment = { __typename?: 'TournamentTeam' } & {
  teamMembers: Array<{ __typename?: 'TournamentTeamMember' } & TournamentTeamMemberFragment>;
  teamHistories: Array<{ __typename?: 'TournamentTeamHistory' } & TeamHistoryFragment>;
  teamStats: Array<{ __typename?: 'TournamentSeasonTeamStat' } & SeasonTeamStatsFragment>;
} & TournamentTeamFragment;

export type TournamentTeamMemberSmallFragment = { __typename?: 'TournamentTeamMember' } & Pick<
  TournamentTeamMember,
  'id' | 'teamId' | 'firstName' | 'lastName' | 'middleName' | 'number' | 'role' | 'photo' | 'createdAt' | 'updatedAt'
>;

export type TournamentTeamMemberFullFragment = { __typename?: 'TournamentTeamMember' } & Pick<
  TournamentTeamMember,
  | 'id'
  | 'type'
  | 'teamId'
  | 'firstName'
  | 'lastName'
  | 'middleName'
  | 'birthday'
  | 'weight'
  | 'height'
  | 'number'
  | 'role'
  | 'bio'
  | 'photo'
  | 'createdAt'
  | 'updatedAt'
> & { team: { __typename?: 'TournamentTeam' } & Pick<TournamentTeam, 'id' | 'name' | 'logo' | 'website'> };

export type TournamentTeamMemberFragment = { __typename?: 'TournamentTeamMember' } & Pick<
  TournamentTeamMember,
  | 'id'
  | 'type'
  | 'teamId'
  | 'firstName'
  | 'lastName'
  | 'middleName'
  | 'birthday'
  | 'weight'
  | 'height'
  | 'number'
  | 'role'
  | 'bio'
  | 'photo'
  | 'createdAt'
  | 'updatedAt'
>;

export type TournamentTeamMemberConnectionFragment = { __typename?: 'TournamentTeamMemberConnection' } & Pick<
  TournamentTeamMemberConnection,
  'total' | 'limit' | 'cursor' | 'sortable'
> & { edges: Array<{ __typename?: 'TournamentTeamMember' } & TournamentTeamMemberFragment> };

export type TournamentTourFragment = { __typename?: 'TournamentTour' } & Pick<
  TournamentTour,
  | 'id'
  | 'title'
  | 'description'
  | 'tournamentId'
  | 'seasonId'
  | 'number'
  | 'startDate'
  | 'finishDate'
  | 'state'
  | 'sort'
  | 'createdAt'
  | 'updatedAt'
>;

export type TournamentTourDeepFragment = { __typename?: 'TournamentTour' } & {
  matches: Array<{ __typename?: 'TournamentMatch' } & TournamentMatchFragment>;
} & TournamentTourFragment;

export type TournamentTourConnectionFragment = { __typename?: 'TournamentTourConnection' } & Pick<
  TournamentTourConnection,
  'total' | 'limit' | 'cursor' | 'sortable'
> & { edges: Array<{ __typename?: 'TournamentTour' } & TournamentTourFragment> };

export type TournamentTourConnectionDeepFragment = { __typename?: 'TournamentTourConnection' } & Pick<
  TournamentTourConnection,
  'total' | 'limit' | 'cursor' | 'sortable'
> & { edges: Array<{ __typename?: 'TournamentTour' } & TournamentTourDeepFragment> };

export type TranslationFragment = { __typename?: 'Translation' } & Pick<
  Translation,
  'id' | 'locale' | 'key' | 'value' | 'updatedAt'
>;

export type UserFlatFragment = { __typename?: 'User' } & Pick<
  User,
  | 'id'
  | 'uuid'
  | 'state'
  | 'username'
  | 'firstName'
  | 'lastName'
  | 'middleName'
  | 'birthday'
  | 'email'
  | 'phone'
  | 'gender'
  | 'isVerifiedEmail'
  | 'isVerifiedPhone'
  | 'source'
  | 'createdAt'
  | 'updatedAt'
  | 'loyaltyCatalogueAffordableItemsCount'
  | 'isProfileComplete'
>;

export type UserFragment = { __typename?: 'User' } & {
  loyaltyAccount?: Maybe<{ __typename?: 'PaymentAccount' } & PaymentAccountFlatFragment>;
  loyaltyAccounts: Array<{ __typename?: 'PaymentAccount' } & PaymentAccountFlatFragment>;
} & UserFlatFragment;

export type UserApplicationFragment = { __typename?: 'UserApplication' } & Pick<
  UserApplication,
  'id' | 'clientId' | 'userId' | 'typeId' | 'payload' | 'state' | 'comment' | 'createdAt' | 'updatedAt'
> & {
    type: { __typename?: 'UserApplicationType' } & Pick<UserApplicationType, 'id' | 'name'>;
    data: Array<
      { __typename?: 'ExtensionData' } & Pick<ExtensionData, 'id' | 'value' | 'key' | 'createdAt' | 'updatedAt'>
    >;
  };

export type UserAuthResponseFragment = { __typename?: 'UserAuthResponse' } & Pick<
  UserAuthResponse,
  'refreshToken' | 'accessToken'
> & { user: { __typename?: 'User' } & UserFragment };

export type UserConnectionFragment = { __typename?: 'UserConnection' } & Pick<
  UserConnection,
  'total' | 'limit' | 'cursor' | 'sortable' | 'searchable'
> & { edges: Array<{ __typename?: 'User' } & UserFragment> };

export type UserDataFragment = { __typename?: 'UserData' } & Pick<UserData, 'key' | 'title' | 'value'>;

export type UserDeviceFragment = { __typename?: 'UserDevice' } & Pick<
  UserDevice,
  | 'userId'
  | 'installId'
  | 'bundleId'
  | 'brand'
  | 'deviceLocale'
  | 'deviceName'
  | 'deviceModel'
  | 'installReferrer'
  | 'manufacturer'
  | 'systemName'
  | 'systemVersion'
  | 'appVersion'
  | 'userAgent'
  | 'pushToken'
  | 'expoPushToken'
  | 'createdAt'
  | 'updatedAt'
>;

export type UserDocumentFragment = { __typename?: 'UserDocument' } & Pick<
  UserDocument,
  'id' | 'userId' | 'type' | 'data' | 'state' | 'createdAt' | 'updatedAt'
>;

export type UserFieldsConnectionFragment = { __typename?: 'UserFieldsConnection' } & Pick<
  UserFieldsConnection,
  'total' | 'limit' | 'cursor' | 'sortable'
> & { edges: Array<{ __typename?: 'ExtensionField' } & ExtensionFieldFragment> };

export type UserGroupFragment = { __typename?: 'UserGroup' } & Pick<
  UserGroup,
  'id' | 'clientId' | 'name' | 'usersCount' | 'sort' | 'createdAt' | 'updatedAt'
>;

export type UserGroupMinFragment = { __typename?: 'UserGroup' } & Pick<UserGroup, 'id' | 'name'>;

export type UserGroupConnectionFragment = { __typename?: 'UserGroupConnection' } & Pick<
  UserGroupConnection,
  'total' | 'limit' | 'cursor' | 'sortable' | 'searchable'
> & { edges: Array<{ __typename?: 'UserGroup' } & UserGroupFragment> };

export type UserKidFragment = { __typename?: 'UserKid' } & Pick<
  UserKid,
  | 'id'
  | 'clientId'
  | 'userId'
  | 'state'
  | 'firstName'
  | 'lastName'
  | 'middleName'
  | 'birthday'
  | 'gender'
  | 'isSeasonTicket'
  | 'seasonTicket'
  | 'isSeasonTicketPast'
  | 'height'
  | 'createdAt'
  | 'updatedAt'
>;

export type UserSubscriberFragment = { __typename?: 'UserSubscriber' } & Pick<
  UserSubscriber,
  'id' | 'clientId' | 'userId' | 'subscriptionId' | 'modifierId' | 'createdAt' | 'updatedAt'
> & { user: { __typename?: 'User' } & UserFlatFragment };

export type UserSubscriberConnectionFragment = { __typename?: 'UserSubscriberConnection' } & Pick<
  UserSubscriberConnection,
  'total' | 'limit' | 'cursor' | 'sortable' | 'searchable'
> & { edges: Array<{ __typename?: 'UserSubscriber' } & UserSubscriberFragment> };

export type UserSubscribersLogFragment = { __typename?: 'UserSubscribersLog' } & Pick<
  UserSubscribersLog,
  'id' | 'userId' | 'subscriptionId' | 'state' | 'reason' | 'systemUserId' | 'createdAt' | 'updatedAt'
>;

export type UserSubscribersLogConnectionFragment = { __typename?: 'UserSubscribersLogConnection' } & Pick<
  UserSubscribersLogConnection,
  'total' | 'limit' | 'cursor' | 'sortable'
> & { edges: Array<{ __typename?: 'UserSubscribersLog' } & UserSubscribersLogFragment> };

export type UserSubscriptionFragment = { __typename?: 'UserSubscription' } & Pick<
  UserSubscription,
  | 'id'
  | 'clientId'
  | 'state'
  | 'isSubscriber'
  | 'name'
  | 'description'
  | 'subscribersCount'
  | 'access'
  | 'createdAt'
  | 'updatedAt'
>;

export type UserSubscriptionConnectionFragment = { __typename?: 'UserSubscriptionConnection' } & Pick<
  UserSubscriptionConnection,
  'total' | 'limit' | 'cursor' | 'sortable'
> & { edges: Array<{ __typename?: 'UserSubscription' } & UserSubscriptionFragment> };

export type AddToMessengerQueueMutationVariables = Exact<{
  mailingId: Scalars['Int'];
  userId?: Maybe<Array<Scalars['Int']> | Scalars['Int']>;
  query?: Maybe<DefaultQueryInput>;
}>;

export type AddToMessengerQueueMutation = { __typename?: 'Mutation' } & {
  addToMessengerQueue: { __typename?: 'MessengerQueueTask' } & MessengerQueueTaskFragment;
};

export type AddToUsersSubscriptionMutationVariables = Exact<{
  subscriptionId: Scalars['Int'];
  query?: Maybe<DefaultQueryInput>;
}>;

export type AddToUsersSubscriptionMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'addToUsersSubscription'>;

export type AddUsersToGroupMutationVariables = Exact<{
  groupId: Array<Scalars['Int']> | Scalars['Int'];
  query: DefaultQueryInput;
}>;

export type AddUsersToGroupMutation = { __typename?: 'Mutation' } & {
  addUsersToGroup: Array<{ __typename?: 'SystemAsyncTask' } & SystemAsyncTaskFragment>;
};

export type AddUsersToGroupFromTicketsMutationVariables = Exact<{
  groupId: Array<Scalars['Int']> | Scalars['Int'];
  query: DefaultQueryInput;
}>;

export type AddUsersToGroupFromTicketsMutation = { __typename?: 'Mutation' } & {
  addUsersToGroupFromTickets: Array<{ __typename?: 'SystemAsyncTask' } & SystemAsyncTaskFragment>;
};

export type CancelApplicationFamilySectorMutationVariables = Exact<{
  id: Scalars['Int'];
}>;

export type CancelApplicationFamilySectorMutation = { __typename?: 'Mutation' } & {
  cancelApplicationFamilySector: { __typename?: 'UserApplication' } & Pick<
    UserApplication,
    'id' | 'userId' | 'typeId' | 'state' | 'comment'
  > & { data: Array<{ __typename?: 'ExtensionData' } & Pick<ExtensionData, 'key' | 'value'>> };
};

export type CancelLoyaltyOrderMutationVariables = Exact<{
  id: Scalars['Int'];
}>;

export type CancelLoyaltyOrderMutation = { __typename?: 'Mutation' } & {
  cancelLoyaltyOrder: { __typename?: 'LoyaltyOrder' } & Pick<LoyaltyOrder, 'id'>;
};

export type CancelMyEventOrderMutationVariables = Exact<{
  orderId: Scalars['Int'];
}>;

export type CancelMyEventOrderMutation = { __typename?: 'Mutation' } & {
  cancelMyEventOrder: { __typename?: 'EventOrder' } & EventOrderFragment;
};

export type ChargeFromAccountMutationVariables = Exact<{
  data: PaymentAccountOperationInput;
}>;

export type ChargeFromAccountMutation = { __typename?: 'Mutation' } & {
  chargeFromAccount: { __typename?: 'ProviderResponse' } & Pick<ProviderResponse, 'amount'>;
};

export type CheckMatchAccessMutationVariables = Exact<{
  data?: Maybe<CheckMatchAccessPayload>;
}>;

export type CheckMatchAccessMutation = { __typename?: 'Mutation' } & {
  checkMatchAccess: { __typename?: 'CheckMatchAccessResponse' } & Pick<CheckMatchAccessResponse, 'result' | 'seatName'>;
};

export type ValidateOrderPaymentMutationVariables = Exact<{
  data: CheckOrderPaymentInput;
}>;

export type ValidateOrderPaymentMutation = { __typename?: 'Mutation' } & {
  validateOrderPayment: { __typename?: 'PaymentOrderResult' } & Pick<PaymentOrderResult, 'orderId' | 'success'> & {
      eventOrder?: Maybe<{ __typename?: 'EventOrder' } & EventOrderFlatFragment>;
    };
};

export type CheckSeasonTicketMutationVariables = Exact<{
  data: CheckSeasonTicketPayload;
}>;

export type CheckSeasonTicketMutation = { __typename?: 'Mutation' } & {
  checkSeasonTicket: { __typename?: 'CheckSeasonTicketResponse' } & Pick<CheckSeasonTicketResponse, 'result'>;
};

export type CommerceOrderPrintMutationVariables = Exact<{
  id: Scalars['Int'];
  printerId: Scalars['Int'];
  templateId: Scalars['Int'];
}>;

export type CommerceOrderPrintMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'commerceOrderPrint'>;

export type ConfirmDisputeDisputeModeratorMutationVariables = Exact<{
  id: Scalars['Int'];
  result: DisputeResult;
}>;

export type ConfirmDisputeDisputeModeratorMutation = { __typename?: 'Mutation' } & {
  confirmDisputeDisputeModerator: { __typename?: 'Dispute' } & DisputeFragment;
};

export type ConfirmUserEmailMutationVariables = Exact<{
  email: Scalars['String'];
  confirmationKey?: Maybe<Scalars['String']>;
}>;

export type ConfirmUserEmailMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'confirmUserEmail'>;

export type CreateApplicationFamilySectorMutationVariables = Exact<{
  data: UserApplicationFamilySectorCreateInput;
}>;

export type CreateApplicationFamilySectorMutation = { __typename?: 'Mutation' } & {
  createApplicationFamilySector: { __typename?: 'UserApplication' } & Pick<
    UserApplication,
    'id' | 'userId' | 'typeId' | 'state' | 'comment'
  > & { data: Array<{ __typename?: 'ExtensionData' } & Pick<ExtensionData, 'key' | 'value'>> };
};

export type CreateArticleMutationVariables = Exact<{
  data: ArticleCreateInput;
}>;

export type CreateArticleMutation = { __typename?: 'Mutation' } & {
  createArticle: { __typename?: 'Article' } & ArticleFragment;
};

export type CreateArticleAuthorMutationVariables = Exact<{
  data: ArticleAuthorCreateInput;
}>;

export type CreateArticleAuthorMutation = { __typename?: 'Mutation' } & {
  createArticleAuthor: { __typename?: 'ArticleAuthor' } & ArticleAuthorFragment;
};

export type CreateArticleBlockMutationVariables = Exact<{
  data: ArticleBlockCreateInput;
}>;

export type CreateArticleBlockMutation = { __typename?: 'Mutation' } & {
  createArticleBlock: { __typename?: 'ArticleBlock' } & ArticleBlockFragment;
};

export type CreateArticleCategoryMutationVariables = Exact<{
  data: ArticleCategoryCreateInput;
}>;

export type CreateArticleCategoryMutation = { __typename?: 'Mutation' } & {
  createArticleCategory: { __typename?: 'ArticleCategory' } & ArticleCategoryFragment;
};

export type CreateArticleLinkMutationVariables = Exact<{
  data: ArticleLinkCreateInput;
}>;

export type CreateArticleLinkMutation = { __typename?: 'Mutation' } & {
  createArticleLink: { __typename?: 'ArticleLink' } & ArticleLinkFragment;
};

export type CreateArticleTagMutationVariables = Exact<{
  data: ArticleTagCreateInput;
}>;

export type CreateArticleTagMutation = { __typename?: 'Mutation' } & {
  createArticleTag: { __typename?: 'ArticleTag' } & ArticleTagFragment;
};

export type CreateCatalogueMutationVariables = Exact<{
  data: CatalogueCreateInput;
}>;

export type CreateCatalogueMutation = { __typename?: 'Mutation' } & {
  createCatalogue: { __typename?: 'Catalogue' } & CatalogueFlatFragment;
};

export type CreateCatalogueCategoryMutationVariables = Exact<{
  data: CatalogueCategoryCreateInput;
}>;

export type CreateCatalogueCategoryMutation = { __typename?: 'Mutation' } & {
  createCatalogueCategory: { __typename?: 'CatalogueCategory' } & CatalogueCategoryFragment;
};

export type CreateCatalogueItemMutationVariables = Exact<{
  data: CatalogueItemCreateInput;
}>;

export type CreateCatalogueItemMutation = { __typename?: 'Mutation' } & {
  createCatalogueItem: { __typename?: 'CatalogueItem' } & CatalogueItemFragment;
};

export type CreateCatalogueItemPriceMutationVariables = Exact<{
  data: CatalogueItemPriceCreateInput;
}>;

export type CreateCatalogueItemPriceMutation = { __typename?: 'Mutation' } & {
  createCatalogueItemPrice: { __typename?: 'CatalogueItemPrice' } & CatalogueItemPriceFragment;
};

export type CreateCateringMutationVariables = Exact<{
  data: CateringCreateInput;
}>;

export type CreateCateringMutation = { __typename?: 'Mutation' } & {
  createCatering: { __typename?: 'Catering' } & CateringFragment;
};

export type CreateCommerceOrderMutationVariables = Exact<{
  data: CommerceOrderCreateInput;
}>;

export type CreateCommerceOrderMutation = { __typename?: 'Mutation' } & {
  createCommerceOrder: { __typename?: 'CommerceOrder' } & CommerceOrderFragment;
};

export type CreateCommerceOrderDataMutationVariables = Exact<{
  data: CommerceOrderDataCreateInput;
}>;

export type CreateCommerceOrderDataMutation = { __typename?: 'Mutation' } & {
  createCommerceOrderData: { __typename?: 'CommerceOrderData' } & CommerceOrderDataFragment;
};

export type CreateCommerceOrderFieldMutationVariables = Exact<{
  data: CommerceOrderFieldCreateInput;
}>;

export type CreateCommerceOrderFieldMutation = { __typename?: 'Mutation' } & {
  createCommerceOrderField: { __typename?: 'CommerceOrderField' } & CommerceOrderFieldFragment;
};

export type CreateCommerceOrderItemMutationVariables = Exact<{
  data: CommerceOrderItemCreateInput;
}>;

export type CreateCommerceOrderItemMutation = { __typename?: 'Mutation' } & {
  createCommerceOrderItem: { __typename?: 'CommerceOrderItem' } & CommerceOrderItemFragment;
};

export type CreateCommerceShopMutationVariables = Exact<{
  data: CommerceShopCreateInput;
}>;

export type CreateCommerceShopMutation = { __typename?: 'Mutation' } & {
  createCommerceShop: { __typename?: 'CommerceShop' } & CommerceShopFragment;
};

export type CreateContentTranslationMutationVariables = Exact<{
  data: ContentTranslationCreateInput;
}>;

export type CreateContentTranslationMutation = { __typename?: 'Mutation' } & {
  createContentTranslation: { __typename?: 'ContentTranslation' } & ContentTranslationFragment;
};

export type CreateDisputeMutationVariables = Exact<{
  data: DisputeCreateInput;
}>;

export type CreateDisputeMutation = { __typename?: 'Mutation' } & {
  createDispute: { __typename?: 'Dispute' } & DisputeFragment;
};

export type CreateDisputeDisputeResponseMutationVariables = Exact<{
  data: DisputeResponseCreateInput;
}>;

export type CreateDisputeDisputeResponseMutation = { __typename?: 'Mutation' } & {
  createDisputeDisputeResponse: { __typename?: 'DisputeDisputeResponse' } & DisputeDisputeResponseFragment;
};

export type CreateEventMutationVariables = Exact<{
  data: EventCreateInput;
}>;

export type CreateEventMutation = { __typename?: 'Mutation' } & {
  createEvent: { __typename?: 'Event' } & EventFullFragment;
};

export type CreateEventEventAccessMutationVariables = Exact<{
  data: EventEventAccessCreateInput;
}>;

export type CreateEventEventAccessMutation = { __typename?: 'Mutation' } & {
  createEventEventAccess: { __typename?: 'EventEventAccess' } & EventEventAccessFragment;
};

export type CreateEventPlaceMutationVariables = Exact<{
  data: EventPlaceCreateInput;
}>;

export type CreateEventPlaceMutation = { __typename?: 'Mutation' } & {
  createEventPlace: { __typename?: 'EventPlace' } & EventPlaceFragment;
};

export type CreateEventReserveMutationVariables = Exact<{
  data: EventReserveCreateInput;
}>;

export type CreateEventReserveMutation = { __typename?: 'Mutation' } & {
  createEventReserve: { __typename?: 'EventReserve' } & EventReserveFragment;
};

export type CreateEventTypeMutationVariables = Exact<{
  data: EventTypeCreateInput;
}>;

export type CreateEventTypeMutation = { __typename?: 'Mutation' } & {
  createEventType: { __typename?: 'EventType' } & EventTypeFragment;
};

export type CreateFoodOrderMutationVariables = Exact<{
  data: FoodOrderCreateInput;
}>;

export type CreateFoodOrderMutation = { __typename?: 'Mutation' } & {
  createFoodOrder: { __typename?: 'FoodOrder' } & Pick<
    FoodOrder,
    'id' | 'shopId' | 'clientId' | 'state' | 'paidAmount' | 'paymentState' | 'userId' | 'code' | 'total'
  > & { items?: Maybe<Array<{ __typename?: 'FoodOrderItem' } & FoodOrderItemFragment>> };
};

export type CreateLoyaltyOrderMutationVariables = Exact<{
  data: LoyaltyOrderCreateInput;
}>;

export type CreateLoyaltyOrderMutation = { __typename?: 'Mutation' } & {
  createLoyaltyOrder: { __typename?: 'LoyaltyOrder' } & LoyaltyOrderFragment;
};

export type CreateLoyaltyPartnerMutationVariables = Exact<{
  data: LoyaltyPartnerCreateInput;
}>;

export type CreateLoyaltyPartnerMutation = { __typename?: 'Mutation' } & {
  createLoyaltyPartner: { __typename?: 'LoyaltyPartner' } & LoyaltyPartnerFragment;
};

export type CreateLoyaltyPromoMutationVariables = Exact<{
  data: LoyaltyPromoCreateInput;
}>;

export type CreateLoyaltyPromoMutation = { __typename?: 'Mutation' } & {
  createLoyaltyPromo: { __typename?: 'LoyaltyPromo' } & LoyaltyPromoFragment;
};

export type CreateLoyaltyPromoCodeMutationVariables = Exact<{
  data: LoyaltyPromoCodeCreateInput;
}>;

export type CreateLoyaltyPromoCodeMutation = { __typename?: 'Mutation' } & {
  createLoyaltyPromoCode: { __typename?: 'LoyaltyPromoCode' } & LoyaltyPromoCodeFragment;
};

export type CreateMessengerMailingMutationVariables = Exact<{
  data: MessengerMailingCreateInput;
}>;

export type CreateMessengerMailingMutation = { __typename?: 'Mutation' } & {
  createMessengerMailing: { __typename?: 'MessengerMailing' } & MessengerMailingFragment;
};

export type CreateMessengerQueueNotificationMutationVariables = Exact<{
  data: MessengerQueueNotificationCreateInput;
}>;

export type CreateMessengerQueueNotificationMutation = { __typename?: 'Mutation' } & {
  createMessengerQueueNotification: { __typename?: 'MessengerQueueNotification' } & MessengerQueueNotificationFragment;
};

export type CreateMessengerQueueTaskMutationVariables = Exact<{
  data: MessengerQueueTaskCreateInput;
}>;

export type CreateMessengerQueueTaskMutation = { __typename?: 'Mutation' } & {
  createMessengerQueueTask: { __typename?: 'MessengerQueueTask' } & MessengerQueueTaskFragment;
};

export type CreateMessengerTransportMutationVariables = Exact<{
  data: MessengerTransportCreateInput;
}>;

export type CreateMessengerTransportMutation = { __typename?: 'Mutation' } & {
  createMessengerTransport: { __typename?: 'MessengerTransport' } & MessengerTransportFragment;
};

export type CreateMyEventOrderMutationVariables = Exact<{
  eventId: Scalars['Int'];
  payload: EventOrderPayloadInput;
}>;

export type CreateMyEventOrderMutation = { __typename?: 'Mutation' } & {
  createMyEventOrder: { __typename?: 'EventOrder' } & EventOrderFragment;
};

export type CreateMyEventReserveMutationVariables = Exact<{
  eventId: Scalars['Int'];
  sectorId: Scalars['Int'];
  seatId?: Maybe<Scalars['Int']>;
  priceCategoryId?: Maybe<Scalars['String']>;
  fanCode?: Maybe<Scalars['String']>;
  fanGUID?: Maybe<Scalars['String']>;
}>;

export type CreateMyEventReserveMutation = { __typename?: 'Mutation' } & {
  createMyEventReserve: Array<{ __typename?: 'Reserve' } & ReserveFragment>;
};

export type CreatePaymentAccountMutationVariables = Exact<{
  data: PaymentAccountCreateInput;
}>;

export type CreatePaymentAccountMutation = { __typename?: 'Mutation' } & {
  createPaymentAccount: { __typename?: 'PaymentAccount' } & PaymentAccountFragment;
};

export type CreatePaymentAccountByApplicationCodeMutationVariables = Exact<{
  userId: Scalars['Int'];
  partnerId: Scalars['Int'];
  providerCode: Scalars['String'];
}>;

export type CreatePaymentAccountByApplicationCodeMutation = { __typename?: 'Mutation' } & {
  createPaymentAccountByApplicationCode?: Maybe<{ __typename?: 'PaymentAccount' } & PaymentAccountFragment>;
};

export type CreatePaymentCurrencyMutationVariables = Exact<{
  data: PaymentCurrencyCreateInput;
}>;

export type CreatePaymentCurrencyMutation = { __typename?: 'Mutation' } & {
  createPaymentCurrency: { __typename?: 'PaymentCurrency' } & PaymentCurrencyFragment;
};

export type CreatePaymentProviderMutationVariables = Exact<{
  data: PaymentProviderCreateInput;
}>;

export type CreatePaymentProviderMutation = { __typename?: 'Mutation' } & {
  createPaymentProvider: { __typename?: 'PaymentProvider' } & PaymentProviderFragment;
};

export type CreatePaymentTransactionMutationVariables = Exact<{
  data: PaymentTransactionCreateInput;
}>;

export type CreatePaymentTransactionMutation = { __typename?: 'Mutation' } & {
  createPaymentTransaction: { __typename?: 'PaymentTransaction' } & PaymentTransactionFragment;
};

export type CreateSystemPrinterMutationVariables = Exact<{
  data: SystemPrinterCreateInput;
}>;

export type CreateSystemPrinterMutation = { __typename?: 'Mutation' } & {
  createSystemPrinter: { __typename?: 'SystemPrinter' } & SystemPrinterFragment;
};

export type CreateSystemTranslationMutationVariables = Exact<{
  data: SystemTranslationCreateInput;
}>;

export type CreateSystemTranslationMutation = { __typename?: 'Mutation' } & {
  createSystemTranslation: { __typename?: 'SystemTranslation' } & SystemTranslationFragment;
};

export type CreateSystemUserMutationVariables = Exact<{
  data: CreateSystemUserInput;
}>;

export type CreateSystemUserMutation = { __typename?: 'Mutation' } & {
  createSystemUser: { __typename?: 'SystemUser' } & SystemUserFragment;
};

export type CreateSystemWebHookMutationVariables = Exact<{
  data: SystemWebHookCreateInput;
}>;

export type CreateSystemWebHookMutation = { __typename?: 'Mutation' } & {
  createSystemWebHook: { __typename?: 'SystemWebHook' } & SystemWebHookFragment;
};

export type CreateTemplateApplePassMutationVariables = Exact<{
  data: TemplateApplePassCreateInput;
}>;

export type CreateTemplateApplePassMutation = { __typename?: 'Mutation' } & {
  createTemplateApplePass: { __typename?: 'TemplateApplePass' } & TemplateApplePassFragment;
};

export type CreateTemplateNotificationMutationVariables = Exact<{
  data: TemplateNotificationCreateInput;
}>;

export type CreateTemplateNotificationMutation = { __typename?: 'Mutation' } & {
  createTemplateNotification: { __typename?: 'TemplateNotification' } & TemplateNotificationFragment;
};

export type CreateTemplatePdfMutationVariables = Exact<{
  data: TemplatePdfCreateInput;
}>;

export type CreateTemplatePdfMutation = { __typename?: 'Mutation' } & {
  createTemplatePDF: { __typename?: 'TemplatePDF' } & TemplatePdfFragment;
};

export type CreateTournamentMutationVariables = Exact<{
  data: TournamentCreateInput;
}>;

export type CreateTournamentMutation = { __typename?: 'Mutation' } & {
  createTournament: { __typename?: 'Tournament' } & TournamentFullFragment;
};

export type CreateTournamentDisqualificationMutationVariables = Exact<{
  data: TournamentDisqualificationCreateInput;
}>;

export type CreateTournamentDisqualificationMutation = { __typename?: 'Mutation' } & {
  createTournamentDisqualification: { __typename?: 'TournamentDisqualification' } & TournamentDisqualificationFragment;
};

export type CreateTournamentMatchMutationVariables = Exact<{
  data: TournamentMatchCreateInput;
}>;

export type CreateTournamentMatchMutation = { __typename?: 'Mutation' } & {
  createTournamentMatch: { __typename?: 'TournamentMatch' } & TournamentMatchFragment;
};

export type CreateTournamentMatchEventMutationVariables = Exact<{
  data: TournamentMatchEventCreateInput;
}>;

export type CreateTournamentMatchEventMutation = { __typename?: 'Mutation' } & {
  createTournamentMatchEvent: { __typename?: 'TournamentMatchEvent' } & TournamentMatchEventFragment;
};

export type CreateTournamentMatchLineUpMutationVariables = Exact<{
  data: TournamentMatchLineUpCreateInput;
}>;

export type CreateTournamentMatchLineUpMutation = { __typename?: 'Mutation' } & {
  createTournamentMatchLineUp: { __typename?: 'TournamentMatchLineUp' } & TournamentMatchLineUpFragment;
};

export type CreateTournamentMatchProtocolMutationVariables = Exact<{
  data: TournamentMatchProtocolCreateInput;
}>;

export type CreateTournamentMatchProtocolMutation = { __typename?: 'Mutation' } & {
  createTournamentMatchProtocol: { __typename?: 'TournamentMatchProtocol' } & TournamentMatchProtocolFragment;
};

export type CreateTournamentPlayerStatMutationVariables = Exact<{
  data: TournamentPlayerStatCreateInput;
}>;

export type CreateTournamentPlayerStatMutation = { __typename?: 'Mutation' } & {
  createTournamentPlayerStat: { __typename?: 'TournamentPlayerStat' } & TournamentPlayerStatFragment;
};

export type CreateTournamentRefereeMutationVariables = Exact<{
  data: TournamentRefereeCreateInput;
}>;

export type CreateTournamentRefereeMutation = { __typename?: 'Mutation' } & {
  createTournamentReferee: { __typename?: 'TournamentReferee' } & TournamentRefereeFragment;
};

export type CreateTournamentSeasonMutationVariables = Exact<{
  data: TournamentSeasonCreateInput;
}>;

export type CreateTournamentSeasonMutation = { __typename?: 'Mutation' } & {
  createTournamentSeason: { __typename?: 'TournamentSeason' } & TournamentSeasonFragment;
};

export type CreateTournamentSeasonTeamMutationVariables = Exact<{
  data: TournamentSeasonTeamCreateInput;
}>;

export type CreateTournamentSeasonTeamMutation = { __typename?: 'Mutation' } & {
  createTournamentSeasonTeam: { __typename?: 'TournamentSeasonTeam' } & TournamentSeasonTeamFragment;
};

export type CreateTournamentSeasonTeamStatMutationVariables = Exact<{
  data: TournamentSeasonTeamStatCreateInput;
}>;

export type CreateTournamentSeasonTeamStatMutation = { __typename?: 'Mutation' } & {
  createTournamentSeasonTeamStat: { __typename?: 'TournamentSeasonTeamStat' } & TournamentSeasonTeamStatFragment;
};

export type CreateTournamentTeamMutationVariables = Exact<{
  data: TournamentTeamCreateInput;
}>;

export type CreateTournamentTeamMutation = { __typename?: 'Mutation' } & {
  createTournamentTeam: { __typename?: 'TournamentTeam' } & TournamentTeamFragment;
};

export type CreateTournamentTeamMemberMutationVariables = Exact<{
  data: TournamentTeamMemberCreateInput;
}>;

export type CreateTournamentTeamMemberMutation = { __typename?: 'Mutation' } & {
  createTournamentTeamMember: { __typename?: 'TournamentTeamMember' } & TournamentTeamMemberFragment;
};

export type CreateTournamentTourMutationVariables = Exact<{
  data: TournamentTourCreateInput;
}>;

export type CreateTournamentTourMutation = { __typename?: 'Mutation' } & {
  createTournamentTour: { __typename?: 'TournamentTour' } & TournamentTourFragment;
};

export type CreateUserGroupMutationVariables = Exact<{
  data: UserGroupCreateInput;
}>;

export type CreateUserGroupMutation = { __typename?: 'Mutation' } & {
  createUserGroup: { __typename?: 'UserGroup' } & UserGroupFragment;
};

export type CreateUserSubscriberMutationVariables = Exact<{
  data: UserSubscriberCreateInput;
}>;

export type CreateUserSubscriberMutation = { __typename?: 'Mutation' } & {
  createUserSubscriber: { __typename?: 'UserSubscriber' } & UserSubscriberFragment;
};

export type CreateUserSubscribersMutationVariables = Exact<{
  subscriptionId: Scalars['Int'];
  userId: Array<Scalars['Int']> | Scalars['Int'];
  modifierId?: Maybe<Scalars['Int']>;
}>;

export type CreateUserSubscribersMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'createUserSubscribers'>;

export type CreateUserSubscriptionMutationVariables = Exact<{
  data: UserSubscriptionCreateInput;
}>;

export type CreateUserSubscriptionMutation = { __typename?: 'Mutation' } & {
  createUserSubscription: { __typename?: 'UserSubscription' } & UserSubscriptionFragment;
};

export type DeleteArticleMutationVariables = Exact<{
  id: Scalars['Int'];
}>;

export type DeleteArticleMutation = { __typename?: 'Mutation' } & {
  deleteArticle: { __typename?: 'Article' } & ArticleFragment;
};

export type DeleteArticleAuthorMutationVariables = Exact<{
  id: Scalars['Int'];
}>;

export type DeleteArticleAuthorMutation = { __typename?: 'Mutation' } & {
  deleteArticleAuthor: { __typename?: 'ArticleAuthor' } & ArticleAuthorFragment;
};

export type DeleteArticleBlockMutationVariables = Exact<{
  id: Scalars['Int'];
}>;

export type DeleteArticleBlockMutation = { __typename?: 'Mutation' } & {
  deleteArticleBlock: { __typename?: 'ArticleBlock' } & ArticleBlockFragment;
};

export type DeleteArticleCategoryMutationVariables = Exact<{
  id: Scalars['Int'];
}>;

export type DeleteArticleCategoryMutation = { __typename?: 'Mutation' } & {
  deleteArticleCategory: { __typename?: 'ArticleCategory' } & ArticleCategoryFragment;
};

export type DeleteArticleLinkMutationVariables = Exact<{
  id: Scalars['Int'];
}>;

export type DeleteArticleLinkMutation = { __typename?: 'Mutation' } & {
  deleteArticleLink: { __typename?: 'ArticleLink' } & ArticleLinkFragment;
};

export type DeleteArticleTagMutationVariables = Exact<{
  id: Scalars['Int'];
}>;

export type DeleteArticleTagMutation = { __typename?: 'Mutation' } & {
  deleteArticleTag: { __typename?: 'ArticleTag' } & ArticleTagFragment;
};

export type DeleteCatalogueMutationVariables = Exact<{
  id: Scalars['Int'];
}>;

export type DeleteCatalogueMutation = { __typename?: 'Mutation' } & {
  deleteCatalogue: { __typename?: 'Catalogue' } & CatalogueFlatFragment;
};

export type DeleteCatalogueCategoryMutationVariables = Exact<{
  id: Scalars['Int'];
}>;

export type DeleteCatalogueCategoryMutation = { __typename?: 'Mutation' } & {
  deleteCatalogueCategory: { __typename?: 'CatalogueCategory' } & CatalogueCategoryFragment;
};

export type DeleteCatalogueItemMutationVariables = Exact<{
  id: Scalars['Int'];
}>;

export type DeleteCatalogueItemMutation = { __typename?: 'Mutation' } & {
  deleteCatalogueItem: { __typename?: 'CatalogueItem' } & CatalogueItemFragment;
};

export type DeleteCatalogueItemPriceMutationVariables = Exact<{
  id: Scalars['Int'];
}>;

export type DeleteCatalogueItemPriceMutation = { __typename?: 'Mutation' } & {
  deleteCatalogueItemPrice: { __typename?: 'CatalogueItemPrice' } & CatalogueItemPriceFragment;
};

export type DeleteCateringMutationVariables = Exact<{
  id: Scalars['Int'];
}>;

export type DeleteCateringMutation = { __typename?: 'Mutation' } & {
  deleteCatering: { __typename?: 'Catering' } & CateringFragment;
};

export type DeleteCommerceOrderMutationVariables = Exact<{
  id: Scalars['Int'];
}>;

export type DeleteCommerceOrderMutation = { __typename?: 'Mutation' } & {
  deleteCommerceOrder: { __typename?: 'CommerceOrder' } & CommerceOrderFragment;
};

export type DeleteCommerceOrderDataMutationVariables = Exact<{
  id: Scalars['Int'];
}>;

export type DeleteCommerceOrderDataMutation = { __typename?: 'Mutation' } & {
  deleteCommerceOrderData: { __typename?: 'CommerceOrderData' } & CommerceOrderDataFragment;
};

export type DeleteCommerceOrderFieldMutationVariables = Exact<{
  id: Scalars['Int'];
}>;

export type DeleteCommerceOrderFieldMutation = { __typename?: 'Mutation' } & {
  deleteCommerceOrderField: { __typename?: 'CommerceOrderField' } & CommerceOrderFieldFragment;
};

export type DeleteCommerceOrderItemMutationVariables = Exact<{
  id: Scalars['Int'];
}>;

export type DeleteCommerceOrderItemMutation = { __typename?: 'Mutation' } & {
  deleteCommerceOrderItem: { __typename?: 'CommerceOrderItem' } & CommerceOrderItemFragment;
};

export type DeleteCommerceShopMutationVariables = Exact<{
  id: Scalars['Int'];
}>;

export type DeleteCommerceShopMutation = { __typename?: 'Mutation' } & {
  deleteCommerceShop: { __typename?: 'CommerceShop' } & CommerceShopFragment;
};

export type DeleteContentTranslationMutationVariables = Exact<{
  id: Scalars['Int'];
}>;

export type DeleteContentTranslationMutation = { __typename?: 'Mutation' } & {
  deleteContentTranslation: { __typename?: 'ContentTranslation' } & ContentTranslationFragment;
};

export type DeleteEventMutationVariables = Exact<{
  id: Scalars['Int'];
}>;

export type DeleteEventMutation = { __typename?: 'Mutation' } & {
  deleteEvent: { __typename?: 'Event' } & EventFullFragment;
};

export type DeleteEventEventAccessMutationVariables = Exact<{
  id: Scalars['Int'];
}>;

export type DeleteEventEventAccessMutation = { __typename?: 'Mutation' } & {
  deleteEventEventAccess: { __typename?: 'EventEventAccess' } & EventEventAccessFragment;
};

export type DeleteEventPlaceMutationVariables = Exact<{
  id: Scalars['Int'];
}>;

export type DeleteEventPlaceMutation = { __typename?: 'Mutation' } & {
  deleteEventPlace: { __typename?: 'EventPlace' } & EventPlaceFragment;
};

export type DeleteEventReserveMutationVariables = Exact<{
  id: Scalars['Int'];
}>;

export type DeleteEventReserveMutation = { __typename?: 'Mutation' } & {
  deleteEventReserve: { __typename?: 'EventReserve' } & EventReserveFragment;
};

export type DeleteEventTypeMutationVariables = Exact<{
  id: Scalars['Int'];
}>;

export type DeleteEventTypeMutation = { __typename?: 'Mutation' } & {
  deleteEventType: { __typename?: 'EventType' } & EventTypeFragment;
};

export type DeleteFromMessengerQueueMutationVariables = Exact<{
  mailingId: Scalars['Int'];
  userId?: Maybe<Array<Scalars['Int']> | Scalars['Int']>;
}>;

export type DeleteFromMessengerQueueMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'deleteFromMessengerQueue'>;

export type DeleteFromUsersSubscriptionMutationVariables = Exact<{
  subscriptionId: Scalars['Int'];
  userId?: Maybe<Array<Scalars['Int']> | Scalars['Int']>;
}>;

export type DeleteFromUsersSubscriptionMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'deleteFromUsersSubscription'
>;

export type DeleteLoyaltyPartnerMutationVariables = Exact<{
  id: Scalars['Int'];
}>;

export type DeleteLoyaltyPartnerMutation = { __typename?: 'Mutation' } & {
  deleteLoyaltyPartner: { __typename?: 'LoyaltyPartner' } & LoyaltyPartnerFragment;
};

export type DeleteLoyaltyPromoMutationVariables = Exact<{
  id: Scalars['Int'];
}>;

export type DeleteLoyaltyPromoMutation = { __typename?: 'Mutation' } & {
  deleteLoyaltyPromo: { __typename?: 'LoyaltyPromo' } & LoyaltyPromoFragment;
};

export type DeleteLoyaltyPromoCodeMutationVariables = Exact<{
  id: Scalars['Int'];
}>;

export type DeleteLoyaltyPromoCodeMutation = { __typename?: 'Mutation' } & {
  deleteLoyaltyPromoCode: { __typename?: 'LoyaltyPromoCode' } & LoyaltyPromoCodeFragment;
};

export type DeleteMessengerMailingMutationVariables = Exact<{
  id: Scalars['Int'];
}>;

export type DeleteMessengerMailingMutation = { __typename?: 'Mutation' } & {
  deleteMessengerMailing: { __typename?: 'MessengerMailing' } & MessengerMailingFragment;
};

export type DeleteMessengerQueueNotificationMutationVariables = Exact<{
  id: Scalars['String'];
}>;

export type DeleteMessengerQueueNotificationMutation = { __typename?: 'Mutation' } & {
  deleteMessengerQueueNotification: { __typename?: 'MessengerQueueNotification' } & MessengerQueueNotificationFragment;
};

export type DeleteMessengerQueueTaskMutationVariables = Exact<{
  id: Scalars['Int'];
}>;

export type DeleteMessengerQueueTaskMutation = { __typename?: 'Mutation' } & {
  deleteMessengerQueueTask: { __typename?: 'MessengerQueueTask' } & MessengerQueueTaskFragment;
};

export type DeleteMessengerTransportMutationVariables = Exact<{
  id: Scalars['Int'];
}>;

export type DeleteMessengerTransportMutation = { __typename?: 'Mutation' } & {
  deleteMessengerTransport: { __typename?: 'MessengerTransport' } & MessengerTransportFragment;
};

export type DeleteMobileTranslationMutationVariables = Exact<{
  id: Scalars['Int'];
}>;

export type DeleteMobileTranslationMutation = { __typename?: 'Mutation' } & {
  deleteMobileTranslation: { __typename?: 'MobileTranslation' } & MobileTranslationFragment;
};

export type DeleteMyEventReserveMutationVariables = Exact<{
  eventId: Scalars['Int'];
  sectorId: Scalars['Int'];
  seatId?: Maybe<Scalars['Int']>;
}>;

export type DeleteMyEventReserveMutation = { __typename?: 'Mutation' } & {
  deleteMyEventReserve: Array<{ __typename?: 'Reserve' } & ReserveFragment>;
};

export type DeletePaymentAccountMutationVariables = Exact<{
  id: Scalars['Int'];
}>;

export type DeletePaymentAccountMutation = { __typename?: 'Mutation' } & {
  deletePaymentAccount: { __typename?: 'PaymentAccount' } & PaymentAccountFragment;
};

export type DeletePaymentCurrencyMutationVariables = Exact<{
  id: Scalars['Int'];
}>;

export type DeletePaymentCurrencyMutation = { __typename?: 'Mutation' } & {
  deletePaymentCurrency: { __typename?: 'PaymentCurrency' } & PaymentCurrencyFragment;
};

export type DeletePaymentProviderMutationVariables = Exact<{
  id: Scalars['Int'];
}>;

export type DeletePaymentProviderMutation = { __typename?: 'Mutation' } & {
  deletePaymentProvider: { __typename?: 'PaymentProvider' } & PaymentProviderFragment;
};

export type DeletePaymentTransactionMutationVariables = Exact<{
  id: Scalars['Int'];
}>;

export type DeletePaymentTransactionMutation = { __typename?: 'Mutation' } & {
  deletePaymentTransaction: { __typename?: 'PaymentTransaction' } & PaymentTransactionFragment;
};

export type DeleteSystemPrinterMutationVariables = Exact<{
  id: Scalars['Int'];
}>;

export type DeleteSystemPrinterMutation = { __typename?: 'Mutation' } & {
  deleteSystemPrinter: { __typename?: 'SystemPrinter' } & SystemPrinterFragment;
};

export type DeleteSystemTranslationMutationVariables = Exact<{
  id: Scalars['Int'];
}>;

export type DeleteSystemTranslationMutation = { __typename?: 'Mutation' } & {
  deleteSystemTranslation: { __typename?: 'SystemTranslation' } & SystemTranslationFragment;
};

export type DeleteSystemWebHookMutationVariables = Exact<{
  id: Scalars['Int'];
}>;

export type DeleteSystemWebHookMutation = { __typename?: 'Mutation' } & {
  deleteSystemWebHook: { __typename?: 'SystemWebHook' } & SystemWebHookFragment;
};

export type DeleteTemplateApplePassMutationVariables = Exact<{
  id: Scalars['Int'];
}>;

export type DeleteTemplateApplePassMutation = { __typename?: 'Mutation' } & {
  deleteTemplateApplePass: { __typename?: 'TemplateApplePass' } & TemplateApplePassFragment;
};

export type DeleteTemplateNotificationMutationVariables = Exact<{
  id: Scalars['Int'];
}>;

export type DeleteTemplateNotificationMutation = { __typename?: 'Mutation' } & {
  deleteTemplateNotification: { __typename?: 'TemplateNotification' } & TemplateNotificationFragment;
};

export type DeleteTemplatePdfMutationVariables = Exact<{
  id: Scalars['Int'];
}>;

export type DeleteTemplatePdfMutation = { __typename?: 'Mutation' } & {
  deleteTemplatePDF: { __typename?: 'TemplatePDF' } & TemplatePdfFragment;
};

export type DeleteTournamentMutationVariables = Exact<{
  id: Scalars['Int'];
}>;

export type DeleteTournamentMutation = { __typename?: 'Mutation' } & {
  deleteTournament: { __typename?: 'Tournament' } & TournamentFullFragment;
};

export type DeleteTournamentDisqualificationMutationVariables = Exact<{
  id: Scalars['Int'];
}>;

export type DeleteTournamentDisqualificationMutation = { __typename?: 'Mutation' } & {
  deleteTournamentDisqualification: { __typename?: 'TournamentDisqualification' } & TournamentDisqualificationFragment;
};

export type DeleteTournamentMatchMutationVariables = Exact<{
  id: Scalars['Int'];
}>;

export type DeleteTournamentMatchMutation = { __typename?: 'Mutation' } & {
  deleteTournamentMatch: { __typename?: 'TournamentMatch' } & TournamentMatchFragment;
};

export type DeleteTournamentMatchEventMutationVariables = Exact<{
  id: Scalars['Int'];
}>;

export type DeleteTournamentMatchEventMutation = { __typename?: 'Mutation' } & {
  deleteTournamentMatchEvent: { __typename?: 'TournamentMatchEvent' } & TournamentMatchEventFragment;
};

export type DeleteTournamentMatchLineUpMutationVariables = Exact<{
  id: Scalars['Int'];
}>;

export type DeleteTournamentMatchLineUpMutation = { __typename?: 'Mutation' } & {
  deleteTournamentMatchLineUp: { __typename?: 'TournamentMatchLineUp' } & TournamentMatchLineUpFragment;
};

export type DeleteTournamentMatchProtocolMutationVariables = Exact<{
  id: Scalars['Int'];
}>;

export type DeleteTournamentMatchProtocolMutation = { __typename?: 'Mutation' } & {
  deleteTournamentMatchProtocol: { __typename?: 'TournamentMatchProtocol' } & TournamentMatchProtocolFragment;
};

export type DeleteTournamentPlayerStatMutationVariables = Exact<{
  id: Scalars['Int'];
}>;

export type DeleteTournamentPlayerStatMutation = { __typename?: 'Mutation' } & {
  deleteTournamentPlayerStat: { __typename?: 'TournamentPlayerStat' } & TournamentPlayerStatFragment;
};

export type DeleteTournamentRefereeMutationVariables = Exact<{
  id: Scalars['Int'];
}>;

export type DeleteTournamentRefereeMutation = { __typename?: 'Mutation' } & {
  deleteTournamentReferee: { __typename?: 'TournamentReferee' } & TournamentRefereeFragment;
};

export type DeleteTournamentSeasonMutationVariables = Exact<{
  id: Scalars['Int'];
}>;

export type DeleteTournamentSeasonMutation = { __typename?: 'Mutation' } & {
  deleteTournamentSeason: { __typename?: 'TournamentSeason' } & TournamentSeasonFragment;
};

export type DeleteTournamentSeasonTeamMutationVariables = Exact<{
  id: Scalars['Int'];
}>;

export type DeleteTournamentSeasonTeamMutation = { __typename?: 'Mutation' } & {
  deleteTournamentSeasonTeam: { __typename?: 'TournamentSeasonTeam' } & TournamentSeasonTeamFragment;
};

export type DeleteTournamentSeasonTeamStatMutationVariables = Exact<{
  id: Scalars['Int'];
}>;

export type DeleteTournamentSeasonTeamStatMutation = { __typename?: 'Mutation' } & {
  deleteTournamentSeasonTeamStat: { __typename?: 'TournamentSeasonTeamStat' } & TournamentSeasonTeamStatFragment;
};

export type DeleteTournamentTeamMutationVariables = Exact<{
  id: Scalars['Int'];
}>;

export type DeleteTournamentTeamMutation = { __typename?: 'Mutation' } & {
  deleteTournamentTeam: { __typename?: 'TournamentTeam' } & TournamentTeamFragment;
};

export type DeleteTournamentTeamMemberMutationVariables = Exact<{
  id: Scalars['Int'];
}>;

export type DeleteTournamentTeamMemberMutation = { __typename?: 'Mutation' } & {
  deleteTournamentTeamMember: { __typename?: 'TournamentTeamMember' } & TournamentTeamMemberFragment;
};

export type DeleteTournamentTourMutationVariables = Exact<{
  id: Scalars['Int'];
}>;

export type DeleteTournamentTourMutation = { __typename?: 'Mutation' } & {
  deleteTournamentTour: { __typename?: 'TournamentTour' } & TournamentTourFragment;
};

export type DeleteUserMutationVariables = Exact<{ [key: string]: never }>;

export type DeleteUserMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'deleteUser'>;

export type DeleteUserFromGroupMutationVariables = Exact<{
  groupId: Array<Scalars['Int']> | Scalars['Int'];
  query: DefaultQueryInput;
}>;

export type DeleteUserFromGroupMutation = { __typename?: 'Mutation' } & {
  deleteUserFromGroup: Array<{ __typename?: 'SystemAsyncTask' } & SystemAsyncTaskFragment>;
};

export type DeleteUserGroupMutationVariables = Exact<{
  id: Scalars['Int'];
}>;

export type DeleteUserGroupMutation = { __typename?: 'Mutation' } & {
  deleteUserGroup: { __typename?: 'UserGroup' } & UserGroupFragment;
};

export type DeleteUserSubscriberMutationVariables = Exact<{
  id: Scalars['String'];
}>;

export type DeleteUserSubscriberMutation = { __typename?: 'Mutation' } & {
  deleteUserSubscriber: { __typename?: 'UserSubscriber' } & UserSubscriberFragment;
};

export type DeleteUserSubscribersMutationVariables = Exact<{
  id: Array<Scalars['String']> | Scalars['String'];
}>;

export type DeleteUserSubscribersMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'deleteUserSubscribers'>;

export type DeleteUserSubscriptionMutationVariables = Exact<{
  id: Scalars['Int'];
}>;

export type DeleteUserSubscriptionMutation = { __typename?: 'Mutation' } & {
  deleteUserSubscription: { __typename?: 'UserSubscription' } & UserSubscriptionFragment;
};

export type DepositToAccountMutationVariables = Exact<{
  data: PaymentAccountOperationInput;
}>;

export type DepositToAccountMutation = { __typename?: 'Mutation' } & {
  depositToAccount: { __typename?: 'ProviderResponse' } & Pick<ProviderResponse, 'amount'>;
};

export type ExportPromocodesMutationVariables = Exact<{
  promoId: Scalars['Int'];
  format: ExportFormat;
}>;

export type ExportPromocodesMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'exportPromocodes'>;

export type GenerateDocsByEventOrderMutationVariables = Exact<{
  userId: Scalars['Int'];
  notify: Scalars['Boolean'];
  orderId?: Maybe<Scalars['Int']>;
  ticketId?: Maybe<Scalars['Int']>;
}>;

export type GenerateDocsByEventOrderMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'generateDocsByEventOrder'>;

export type GenerateLoyaltyPromoCodesMutationVariables = Exact<{
  promoId: Scalars['Int'];
  count: Scalars['Int'];
  length: Scalars['Int'];
}>;

export type GenerateLoyaltyPromoCodesMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'generateLoyaltyPromoCodes'
>;

export type GrantAccessMutationVariables = Exact<{
  userId: Scalars['ID'];
  aclId: Scalars['ID'];
}>;

export type GrantAccessMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'grantAccess'>;

export type ImportPromocodesMutationVariables = Exact<{
  promoId: Scalars['Int'];
  file: Scalars['Upload'];
}>;

export type ImportPromocodesMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'importPromocodes'>;

export type ImportRecipientsToQueueNotificationMutationVariables = Exact<{
  mailingId: Scalars['Int'];
  file: Scalars['Upload'];
}>;

export type ImportRecipientsToQueueNotificationMutation = { __typename?: 'Mutation' } & {
  importRecipientsToQueueNotification: { __typename?: 'MessengerQueueTask' } & MessengerQueueTaskFragment;
};

export type ImportTemplateNotificationsMutationVariables = Exact<{
  providerId?: Maybe<Scalars['Int']>;
  templateId?: Maybe<Scalars['Int']>;
}>;

export type ImportTemplateNotificationsMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'importTemplateNotifications'
>;

export type ImportUserSubscribersMutationVariables = Exact<{
  subscriptionId: Scalars['Int'];
  file: Scalars['Upload'];
}>;

export type ImportUserSubscribersMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'importUserSubscribers'>;

export type InitMyEventSessionMutationVariables = Exact<{ [key: string]: never }>;

export type InitMyEventSessionMutation = { __typename?: 'Mutation' } & {
  initMyEventSession: { __typename?: 'EventSession' } & Pick<EventSession, 'id'>;
};

export type InitPaymentGatewayMutationVariables = Exact<{
  data: InitPaymentGatewayInput;
}>;

export type InitPaymentGatewayMutation = { __typename?: 'Mutation' } & {
  initPaymentGateway: { __typename?: 'InitPaymentGatewayResponse' } & Pick<
    InitPaymentGatewayResponse,
    'orderId' | 'orderType' | 'code' | 'provider' | 'settings'
  >;
};

export type LoginUserMutationVariables = Exact<{
  username: Scalars['String'];
  password: Scalars['String'];
}>;

export type LoginUserMutation = { __typename?: 'Mutation' } & {
  loginUser: { __typename?: 'UserAuthResponse' } & UserAuthResponseFragment;
};

export type LoginUserSocialMutationVariables = Exact<{
  type: UserSocialAccountSocialNetwork;
  data: SocialAuthData;
}>;

export type LoginUserSocialMutation = { __typename?: 'Mutation' } & {
  loginUserSocial: { __typename?: 'UserSocialAuthResponse' } & Pick<
    UserSocialAuthResponse,
    'accessToken' | 'refreshToken'
  > & { user: { __typename?: 'User' } & UserFragment };
};

export type RefundOrderMutationVariables = Exact<{
  data: RefundOrderInput;
}>;

export type RefundOrderMutation = { __typename?: 'Mutation' } & {
  refundOrder: Array<{ __typename?: 'PaymentTransaction' } & PaymentTransactionFragment>;
};

export type RegisterUserMutationVariables = Exact<{
  data: UserCreateInput;
}>;

export type RegisterUserMutation = { __typename?: 'Mutation' } & {
  registerUser: { __typename?: 'UserAuthResponse' } & Pick<UserAuthResponse, 'refreshToken' | 'accessToken'> & {
      user: { __typename?: 'User' } & UserFragment;
    };
};

export type ResetLoyaltyPromoCodesMutationVariables = Exact<{
  promoId: Scalars['Int'];
}>;

export type ResetLoyaltyPromoCodesMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'resetLoyaltyPromoCodes'>;

export type ResetUserPasswordMutationVariables = Exact<{
  email?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  confirmationKey?: Maybe<Scalars['String']>;
}>;

export type ResetUserPasswordMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'resetUserPassword'>;

export type RevokeAccessMutationVariables = Exact<{
  userId: Scalars['ID'];
  aclId: Scalars['ID'];
}>;

export type RevokeAccessMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'revokeAccess'>;

export type SaveDisputeSettingsMutationVariables = Exact<{
  data: DisputeSettingsUpdateInput;
}>;

export type SaveDisputeSettingsMutation = { __typename?: 'Mutation' } & {
  saveDisputeSettings: { __typename?: 'DisputeSettings' } & DisputeSettingsFragment;
};

export type SaveMobileTranslationsMutationVariables = Exact<{
  data?: Maybe<Array<MobileTranslationSaveInput> | MobileTranslationSaveInput>;
}>;

export type SaveMobileTranslationsMutation = { __typename?: 'Mutation' } & {
  saveMobileTranslations?: Maybe<Array<{ __typename?: 'MobileTranslation' } & MobileTranslationFragment>>;
};

export type SaveSystemTranslationMutationVariables = Exact<{
  data: SystemTranslationSaveInput;
}>;

export type SaveSystemTranslationMutation = { __typename?: 'Mutation' } & {
  saveSystemTranslation: { __typename?: 'SystemTranslation' } & SystemTranslationFragment;
};

export type SetTicketFanIdMutationVariables = Exact<{
  fanCode: Scalars['String'];
  fanGUID: Scalars['String'];
  orderId: Scalars['Int'];
  barcode?: Maybe<Scalars['String']>;
  ticketId?: Maybe<Scalars['Int']>;
}>;

export type SetTicketFanIdMutation = { __typename?: 'Mutation' } & {
  setTicketFanId: { __typename?: 'EventOrder' } & EventOrderFragment;
};

export type SyncMyLoyaltyAccountsMutationVariables = Exact<{ [key: string]: never }>;

export type SyncMyLoyaltyAccountsMutation = { __typename?: 'Mutation' } & {
  syncMyLoyaltyAccounts: Array<{ __typename?: 'PaymentAccount' } & PaymentAccountFlatFragment>;
};

export type SyncPaymentAccountMutationVariables = Exact<{
  id: Scalars['Int'];
}>;

export type SyncPaymentAccountMutation = { __typename?: 'Mutation' } & {
  syncPaymentAccount: { __typename?: 'PaymentAccount' } & Pick<PaymentAccount, 'id' | 'currencyId' | 'balance'>;
};

export type SystemLoginMutationVariables = Exact<{
  username: Scalars['String'];
  password: Scalars['String'];
  clientId?: Maybe<Scalars['Int']>;
}>;

export type SystemLoginMutation = { __typename?: 'Mutation' } & {
  systemLogin: { __typename?: 'SystemLogin' } & SystemLoginFragment;
};

export type TestMessengerMailingMutationVariables = Exact<{
  mailingId: Scalars['Int'];
}>;

export type TestMessengerMailingMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'testMessengerMailing'>;

export type TriggerSystemWebHookMutationVariables = Exact<{
  id: Scalars['Int'];
  entityId?: Maybe<Scalars['Int']>;
  payload?: Maybe<SystemWebHookPayloadInput>;
}>;

export type TriggerSystemWebHookMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'triggerSystemWebHook'>;

export type UpdateApplicationFamilySectorMutationVariables = Exact<{
  id: Scalars['Int'];
  data: UserApplicationFamilySectorUpdateInput;
}>;

export type UpdateApplicationFamilySectorMutation = { __typename?: 'Mutation' } & {
  updateApplicationFamilySector: { __typename?: 'UserApplication' } & Pick<
    UserApplication,
    'id' | 'userId' | 'typeId' | 'state' | 'comment'
  > & { data: Array<{ __typename?: 'ExtensionData' } & Pick<ExtensionData, 'key' | 'value'>> };
};

export type UpdateArticleMutationVariables = Exact<{
  id: Scalars['Int'];
  data: ArticleUpdateInput;
}>;

export type UpdateArticleMutation = { __typename?: 'Mutation' } & {
  updateArticle: { __typename?: 'Article' } & ArticleFragment;
};

export type UpdateArticleAuthorMutationVariables = Exact<{
  id: Scalars['Int'];
  data: ArticleAuthorUpdateInput;
}>;

export type UpdateArticleAuthorMutation = { __typename?: 'Mutation' } & {
  updateArticleAuthor: { __typename?: 'ArticleAuthor' } & ArticleAuthorFragment;
};

export type UpdateArticleBlockMutationVariables = Exact<{
  id: Scalars['Int'];
  data: ArticleBlockUpdateInput;
}>;

export type UpdateArticleBlockMutation = { __typename?: 'Mutation' } & {
  updateArticleBlock: { __typename?: 'ArticleBlock' } & ArticleBlockFragment;
};

export type UpdateArticleBlocksMutationVariables = Exact<{
  data: Array<ArticleBlocksUpdateInput> | ArticleBlocksUpdateInput;
}>;

export type UpdateArticleBlocksMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'updateArticleBlocks'>;

export type UpdateArticleCategoryMutationVariables = Exact<{
  id: Scalars['Int'];
  data: ArticleCategoryUpdateInput;
}>;

export type UpdateArticleCategoryMutation = { __typename?: 'Mutation' } & {
  updateArticleCategory: { __typename?: 'ArticleCategory' } & ArticleCategoryFragment;
};

export type UpdateArticleLinkMutationVariables = Exact<{
  id: Scalars['Int'];
  data: ArticleLinkUpdateInput;
}>;

export type UpdateArticleLinkMutation = { __typename?: 'Mutation' } & {
  updateArticleLink: { __typename?: 'ArticleLink' } & ArticleLinkFragment;
};

export type UpdateArticleTagMutationVariables = Exact<{
  id: Scalars['Int'];
  data: ArticleTagUpdateInput;
}>;

export type UpdateArticleTagMutation = { __typename?: 'Mutation' } & {
  updateArticleTag: { __typename?: 'ArticleTag' } & ArticleTagFragment;
};

export type UpdateCatalogueMutationVariables = Exact<{
  id: Scalars['Int'];
  data: CatalogueUpdateInput;
}>;

export type UpdateCatalogueMutation = { __typename?: 'Mutation' } & {
  updateCatalogue: { __typename?: 'Catalogue' } & CatalogueFlatFragment;
};

export type UpdateCatalogueCategoryMutationVariables = Exact<{
  id: Scalars['Int'];
  data: CatalogueCategoryUpdateInput;
}>;

export type UpdateCatalogueCategoryMutation = { __typename?: 'Mutation' } & {
  updateCatalogueCategory: { __typename?: 'CatalogueCategory' } & CatalogueCategoryFragment;
};

export type UpdateCatalogueCategoryBatchMutationVariables = Exact<{
  data: Array<CatalogueCategoryBatchUpdateInput> | CatalogueCategoryBatchUpdateInput;
}>;

export type UpdateCatalogueCategoryBatchMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'updateCatalogueCategoryBatch'
>;

export type UpdateCatalogueItemMutationVariables = Exact<{
  id: Scalars['Int'];
  data: CatalogueItemUpdateInput;
}>;

export type UpdateCatalogueItemMutation = { __typename?: 'Mutation' } & {
  updateCatalogueItem: { __typename?: 'CatalogueItem' } & CatalogueItemFragment;
};

export type UpdateCatalogueItemBatchMutationVariables = Exact<{
  data: Array<CatalogueItemBatchUpdateInput> | CatalogueItemBatchUpdateInput;
}>;

export type UpdateCatalogueItemBatchMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'updateCatalogueItemBatch'>;

export type UpdateCatalogueItemPriceMutationVariables = Exact<{
  id: Scalars['Int'];
  data: CatalogueItemPriceUpdateInput;
}>;

export type UpdateCatalogueItemPriceMutation = { __typename?: 'Mutation' } & {
  updateCatalogueItemPrice: { __typename?: 'CatalogueItemPrice' } & CatalogueItemPriceFragment;
};

export type UpdateCateringMutationVariables = Exact<{
  id: Scalars['Int'];
  data: CateringUpdateInput;
}>;

export type UpdateCateringMutation = { __typename?: 'Mutation' } & {
  updateCatering: { __typename?: 'Catering' } & CateringFragment;
};

export type UpdateCommerceOrderMutationVariables = Exact<{
  id: Scalars['Int'];
  data: CommerceOrderUpdateInput;
}>;

export type UpdateCommerceOrderMutation = { __typename?: 'Mutation' } & {
  updateCommerceOrder: { __typename?: 'CommerceOrder' } & CommerceOrderUpdateFragment;
};

export type UpdateCommerceOrderDataMutationVariables = Exact<{
  id: Scalars['Int'];
  data: CommerceOrderDataUpdateInput;
}>;

export type UpdateCommerceOrderDataMutation = { __typename?: 'Mutation' } & {
  updateCommerceOrderData: { __typename?: 'CommerceOrderData' } & CommerceOrderDataFragment;
};

export type UpdateCommerceOrderDatasMutationVariables = Exact<{
  data: Array<CommerceOrderDatasUpdateInput> | CommerceOrderDatasUpdateInput;
}>;

export type UpdateCommerceOrderDatasMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'updateCommerceOrderDatas'>;

export type UpdateCommerceOrderFieldMutationVariables = Exact<{
  id: Scalars['Int'];
  data: CommerceOrderFieldUpdateInput;
}>;

export type UpdateCommerceOrderFieldMutation = { __typename?: 'Mutation' } & {
  updateCommerceOrderField: { __typename?: 'CommerceOrderField' } & CommerceOrderFieldFragment;
};

export type UpdateCommerceOrderItemMutationVariables = Exact<{
  id: Scalars['Int'];
  data: CommerceOrderItemUpdateInput;
}>;

export type UpdateCommerceOrderItemMutation = { __typename?: 'Mutation' } & {
  updateCommerceOrderItem: { __typename?: 'CommerceOrderItem' } & CommerceOrderItemFragment;
};

export type UpdateCommerceShopMutationVariables = Exact<{
  id: Scalars['Int'];
  data: CommerceShopUpdateInput;
}>;

export type UpdateCommerceShopMutation = { __typename?: 'Mutation' } & {
  updateCommerceShop: { __typename?: 'CommerceShop' } & CommerceShopFragment;
};

export type UpdateContentTranslationMutationVariables = Exact<{
  id: Scalars['Int'];
  data: ContentTranslationUpdateInput;
}>;

export type UpdateContentTranslationMutation = { __typename?: 'Mutation' } & {
  updateContentTranslation: { __typename?: 'ContentTranslation' } & ContentTranslationFragment;
};

export type UpdateDisputeMutationVariables = Exact<{
  id: Scalars['Int'];
  data: DisputeUpdateInput;
}>;

export type UpdateDisputeMutation = { __typename?: 'Mutation' } & {
  updateDispute: { __typename?: 'Dispute' } & DisputeFragment;
};

export type UpdateDisputeDisputeResponseMutationVariables = Exact<{
  id: Scalars['Int'];
  data: DisputeResponseUpdateInput;
}>;

export type UpdateDisputeDisputeResponseMutation = { __typename?: 'Mutation' } & {
  updateDisputeDisputeResponse: { __typename?: 'DisputeDisputeResponse' } & DisputeDisputeResponseFragment;
};

export type UpdateEventMutationVariables = Exact<{
  id: Scalars['Int'];
  data: EventUpdateInput;
}>;

export type UpdateEventMutation = { __typename?: 'Mutation' } & {
  updateEvent: { __typename?: 'Event' } & EventFullFragment;
};

export type UpdateEventEventAccessMutationVariables = Exact<{
  id: Scalars['Int'];
  data: EventEventAccessUpdateInput;
}>;

export type UpdateEventEventAccessMutation = { __typename?: 'Mutation' } & {
  updateEventEventAccess: { __typename?: 'EventEventAccess' } & EventEventAccessFragment;
};

export type UpdateEventPlaceMutationVariables = Exact<{
  id: Scalars['Int'];
  data: EventPlaceUpdateInput;
}>;

export type UpdateEventPlaceMutation = { __typename?: 'Mutation' } & {
  updateEventPlace: { __typename?: 'EventPlace' } & EventPlaceFragment;
};

export type UpdateEventReserveMutationVariables = Exact<{
  id: Scalars['Int'];
  data: EventReserveUpdateInput;
}>;

export type UpdateEventReserveMutation = { __typename?: 'Mutation' } & {
  updateEventReserve: { __typename?: 'EventReserve' } & EventReserveFragment;
};

export type UpdateEventTypeMutationVariables = Exact<{
  id: Scalars['Int'];
  data: EventTypeUpdateInput;
}>;

export type UpdateEventTypeMutation = { __typename?: 'Mutation' } & {
  updateEventType: { __typename?: 'EventType' } & EventTypeFragment;
};

export type UpdateLoyaltyPartnerMutationVariables = Exact<{
  id: Scalars['Int'];
  data: LoyaltyPartnerUpdateInput;
}>;

export type UpdateLoyaltyPartnerMutation = { __typename?: 'Mutation' } & {
  updateLoyaltyPartner: { __typename?: 'LoyaltyPartner' } & LoyaltyPartnerFragment;
};

export type UpdateLoyaltyPromoMutationVariables = Exact<{
  id: Scalars['Int'];
  data: LoyaltyPromoUpdateInput;
}>;

export type UpdateLoyaltyPromoMutation = { __typename?: 'Mutation' } & {
  updateLoyaltyPromo: { __typename?: 'LoyaltyPromo' } & LoyaltyPromoFragment;
};

export type UpdateLoyaltyPromoCodeMutationVariables = Exact<{
  id: Scalars['Int'];
  data: LoyaltyPromoCodeUpdateInput;
}>;

export type UpdateLoyaltyPromoCodeMutation = { __typename?: 'Mutation' } & {
  updateLoyaltyPromoCode: { __typename?: 'LoyaltyPromoCode' } & LoyaltyPromoCodeFragment;
};

export type UpdateMessengerMailingMutationVariables = Exact<{
  id: Scalars['Int'];
  data: MessengerMailingUpdateInput;
}>;

export type UpdateMessengerMailingMutation = { __typename?: 'Mutation' } & {
  updateMessengerMailing: { __typename?: 'MessengerMailing' } & MessengerMailingFragment;
};

export type UpdateMessengerQueueNotificationMutationVariables = Exact<{
  id: Scalars['String'];
  data: MessengerQueueNotificationUpdateInput;
}>;

export type UpdateMessengerQueueNotificationMutation = { __typename?: 'Mutation' } & {
  updateMessengerQueueNotification: { __typename?: 'MessengerQueueNotification' } & MessengerQueueNotificationFragment;
};

export type UpdateMessengerQueueTaskMutationVariables = Exact<{
  id: Scalars['Int'];
  data: MessengerQueueTaskUpdateInput;
}>;

export type UpdateMessengerQueueTaskMutation = { __typename?: 'Mutation' } & {
  updateMessengerQueueTask: { __typename?: 'MessengerQueueTask' } & MessengerQueueTaskFragment;
};

export type UpdateMessengerSettingMutationVariables = Exact<{
  data: MessengerSettingUpdateInput;
}>;

export type UpdateMessengerSettingMutation = { __typename?: 'Mutation' } & {
  updateMessengerSetting: { __typename?: 'MessengerSetting' } & MessengerSettingFragment;
};

export type UpdateMessengerTransportMutationVariables = Exact<{
  id: Scalars['Int'];
  data: MessengerTransportUpdateInput;
}>;

export type UpdateMessengerTransportMutation = { __typename?: 'Mutation' } & {
  updateMessengerTransport: { __typename?: 'MessengerTransport' } & MessengerTransportFragment;
};

export type UpdateMySubscriptionsMutationVariables = Exact<{
  subscriptionId: Array<Scalars['Int']> | Scalars['Int'];
}>;

export type UpdateMySubscriptionsMutation = { __typename?: 'Mutation' } & {
  updateMySubscriptions: { __typename?: 'UserSubscriptionConnection' } & UserSubscriptionConnectionFragment;
};

export type UpdatePaymentAccountMutationVariables = Exact<{
  id: Scalars['Int'];
  data: PaymentAccountUpdateInput;
}>;

export type UpdatePaymentAccountMutation = { __typename?: 'Mutation' } & {
  updatePaymentAccount: { __typename?: 'PaymentAccount' } & PaymentAccountFragment;
};

export type UpdatePaymentCurrencyMutationVariables = Exact<{
  id: Scalars['Int'];
  data: PaymentCurrencyUpdateInput;
}>;

export type UpdatePaymentCurrencyMutation = { __typename?: 'Mutation' } & {
  updatePaymentCurrency: { __typename?: 'PaymentCurrency' } & PaymentCurrencyFragment;
};

export type UpdatePaymentProviderMutationVariables = Exact<{
  id: Scalars['Int'];
  data: PaymentProviderUpdateInput;
}>;

export type UpdatePaymentProviderMutation = { __typename?: 'Mutation' } & {
  updatePaymentProvider: { __typename?: 'PaymentProvider' } & PaymentProviderFragment;
};

export type UpdatePaymentTransactionMutationVariables = Exact<{
  id: Scalars['Int'];
  data: PaymentTransactionUpdateInput;
}>;

export type UpdatePaymentTransactionMutation = { __typename?: 'Mutation' } & {
  updatePaymentTransaction: { __typename?: 'PaymentTransaction' } & PaymentTransactionFragment;
};

export type UpdateSystemPrinterMutationVariables = Exact<{
  id: Scalars['Int'];
  data: SystemPrinterUpdateInput;
}>;

export type UpdateSystemPrinterMutation = { __typename?: 'Mutation' } & {
  updateSystemPrinter: { __typename?: 'SystemPrinter' } & SystemPrinterFragment;
};

export type UpdateSystemTranslationMutationVariables = Exact<{
  id: Scalars['Int'];
  data: SystemTranslationUpdateInput;
}>;

export type UpdateSystemTranslationMutation = { __typename?: 'Mutation' } & {
  updateSystemTranslation: { __typename?: 'SystemTranslation' } & SystemTranslationFragment;
};

export type UpdateSystemTranslationsMutationVariables = Exact<{
  data: Array<SystemTranslationUpdateInput> | SystemTranslationUpdateInput;
}>;

export type UpdateSystemTranslationsMutation = { __typename?: 'Mutation' } & {
  updateSystemTranslations?: Maybe<Array<{ __typename?: 'SystemTranslation' } & SystemTranslationFragment>>;
};

export type UpdateSystemUserMutationVariables = Exact<{
  id: Scalars['ID'];
  data: UpdateSystemUserInput;
}>;

export type UpdateSystemUserMutation = { __typename?: 'Mutation' } & {
  updateSystemUser: { __typename?: 'SystemUser' } & SystemUserFragment;
};

export type UpdateSystemWebHookMutationVariables = Exact<{
  id: Scalars['Int'];
  data: SystemWebHookUpdateInput;
}>;

export type UpdateSystemWebHookMutation = { __typename?: 'Mutation' } & {
  updateSystemWebHook: { __typename?: 'SystemWebHook' } & SystemWebHookFragment;
};

export type UpdateTemplateApplePassMutationVariables = Exact<{
  id: Scalars['Int'];
  data: TemplateApplePassUpdateInput;
}>;

export type UpdateTemplateApplePassMutation = { __typename?: 'Mutation' } & {
  updateTemplateApplePass: { __typename?: 'TemplateApplePass' } & TemplateApplePassFragment;
};

export type UpdateTemplateNotificationMutationVariables = Exact<{
  id: Scalars['Int'];
  data: TemplateNotificationUpdateInput;
}>;

export type UpdateTemplateNotificationMutation = { __typename?: 'Mutation' } & {
  updateTemplateNotification: { __typename?: 'TemplateNotification' } & TemplateNotificationFragment;
};

export type UpdateTemplatePdfMutationVariables = Exact<{
  id: Scalars['Int'];
  data: TemplatePdfUpdateInput;
}>;

export type UpdateTemplatePdfMutation = { __typename?: 'Mutation' } & {
  updateTemplatePDF: { __typename?: 'TemplatePDF' } & TemplatePdfFragment;
};

export type UpdateTournamentMutationVariables = Exact<{
  id: Scalars['Int'];
  data: TournamentUpdateInput;
}>;

export type UpdateTournamentMutation = { __typename?: 'Mutation' } & {
  updateTournament: { __typename?: 'Tournament' } & TournamentFullFragment;
};

export type UpdateTournamentDisqualificationMutationVariables = Exact<{
  id: Scalars['Int'];
  data: TournamentDisqualificationUpdateInput;
}>;

export type UpdateTournamentDisqualificationMutation = { __typename?: 'Mutation' } & {
  updateTournamentDisqualification: { __typename?: 'TournamentDisqualification' } & TournamentDisqualificationFragment;
};

export type UpdateTournamentMatchMutationVariables = Exact<{
  id: Scalars['Int'];
  data: TournamentMatchUpdateInput;
}>;

export type UpdateTournamentMatchMutation = { __typename?: 'Mutation' } & {
  updateTournamentMatch: { __typename?: 'TournamentMatch' } & TournamentMatchFragment;
};

export type UpdateTournamentMatchEventMutationVariables = Exact<{
  id: Scalars['Int'];
  data: TournamentMatchEventUpdateInput;
}>;

export type UpdateTournamentMatchEventMutation = { __typename?: 'Mutation' } & {
  updateTournamentMatchEvent: { __typename?: 'TournamentMatchEvent' } & TournamentMatchEventFragment;
};

export type UpdateTournamentMatchLineUpMutationVariables = Exact<{
  id: Scalars['Int'];
  data: TournamentMatchLineUpUpdateInput;
}>;

export type UpdateTournamentMatchLineUpMutation = { __typename?: 'Mutation' } & {
  updateTournamentMatchLineUp: { __typename?: 'TournamentMatchLineUp' } & TournamentMatchLineUpFragment;
};

export type UpdateTournamentMatchProtocolMutationVariables = Exact<{
  id: Scalars['Int'];
  data: TournamentMatchProtocolUpdateInput;
}>;

export type UpdateTournamentMatchProtocolMutation = { __typename?: 'Mutation' } & {
  updateTournamentMatchProtocol: { __typename?: 'TournamentMatchProtocol' } & TournamentMatchProtocolFragment;
};

export type UpdateTournamentPlayerStatMutationVariables = Exact<{
  id: Scalars['Int'];
  data: TournamentPlayerStatUpdateInput;
}>;

export type UpdateTournamentPlayerStatMutation = { __typename?: 'Mutation' } & {
  updateTournamentPlayerStat: { __typename?: 'TournamentPlayerStat' } & TournamentPlayerStatFragment;
};

export type UpdateTournamentRefereeMutationVariables = Exact<{
  id: Scalars['Int'];
  data: TournamentRefereeUpdateInput;
}>;

export type UpdateTournamentRefereeMutation = { __typename?: 'Mutation' } & {
  updateTournamentReferee: { __typename?: 'TournamentReferee' } & TournamentRefereeFragment;
};

export type UpdateTournamentSeasonMutationVariables = Exact<{
  id: Scalars['Int'];
  data: TournamentSeasonUpdateInput;
}>;

export type UpdateTournamentSeasonMutation = { __typename?: 'Mutation' } & {
  updateTournamentSeason: { __typename?: 'TournamentSeason' } & TournamentSeasonFragment;
};

export type UpdateTournamentSeasonTeamMutationVariables = Exact<{
  id: Scalars['Int'];
  data: TournamentSeasonTeamUpdateInput;
}>;

export type UpdateTournamentSeasonTeamMutation = { __typename?: 'Mutation' } & {
  updateTournamentSeasonTeam: { __typename?: 'TournamentSeasonTeam' } & TournamentSeasonTeamFragment;
};

export type UpdateTournamentSeasonTeamStatMutationVariables = Exact<{
  id: Scalars['Int'];
  data: TournamentSeasonTeamStatUpdateInput;
}>;

export type UpdateTournamentSeasonTeamStatMutation = { __typename?: 'Mutation' } & {
  updateTournamentSeasonTeamStat: { __typename?: 'TournamentSeasonTeamStat' } & TournamentSeasonTeamStatFragment;
};

export type UpdateTournamentTeamMutationVariables = Exact<{
  id: Scalars['Int'];
  data: TournamentTeamUpdateInput;
}>;

export type UpdateTournamentTeamMutation = { __typename?: 'Mutation' } & {
  updateTournamentTeam: { __typename?: 'TournamentTeam' } & TournamentTeamFragment;
};

export type UpdateTournamentTeamMemberMutationVariables = Exact<{
  id: Scalars['Int'];
  data: TournamentTeamMemberUpdateInput;
}>;

export type UpdateTournamentTeamMemberMutation = { __typename?: 'Mutation' } & {
  updateTournamentTeamMember: { __typename?: 'TournamentTeamMember' } & TournamentTeamMemberFragment;
};

export type UpdateTournamentTourMutationVariables = Exact<{
  id: Scalars['Int'];
  data: TournamentTourUpdateInput;
}>;

export type UpdateTournamentTourMutation = { __typename?: 'Mutation' } & {
  updateTournamentTour: { __typename?: 'TournamentTour' } & TournamentTourFragment;
};

export type UpdateUserMutationVariables = Exact<{
  id: Scalars['Int'];
  data: UserUpdateInput;
}>;

export type UpdateUserMutation = { __typename?: 'Mutation' } & { updateUser: { __typename?: 'User' } & UserFragment };

export type UpdateUserGroupMutationVariables = Exact<{
  id: Scalars['Int'];
  data: UserGroupUpdateInput;
}>;

export type UpdateUserGroupMutation = { __typename?: 'Mutation' } & {
  updateUserGroup: { __typename?: 'UserGroup' } & UserGroupFragment;
};

export type UpdateUserSubscriberMutationVariables = Exact<{
  id: Scalars['String'];
  data: UserSubscriberUpdateInput;
}>;

export type UpdateUserSubscriberMutation = { __typename?: 'Mutation' } & {
  updateUserSubscriber: { __typename?: 'UserSubscriber' } & UserSubscriberFragment;
};

export type UpdateUserSubscriptionMutationVariables = Exact<{
  id: Scalars['Int'];
  data: UserSubscriptionUpdateInput;
}>;

export type UpdateUserSubscriptionMutation = { __typename?: 'Mutation' } & {
  updateUserSubscription: { __typename?: 'UserSubscription' } & UserSubscriptionFragment;
};

export type ValidatePhoneMutationVariables = Exact<{
  phone: Scalars['String'];
  code?: Maybe<Scalars['String']>;
}>;

export type ValidatePhoneMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'validatePhone'>;

export type AclQueryVariables = Exact<{ [key: string]: never }>;

export type AclQuery = { __typename?: 'Query' } & { acl: Array<{ __typename?: 'ACL' } & AclFragment> };

export type AllArticleAuthorsQueryVariables = Exact<{
  query?: Maybe<DefaultQueryInput>;
}>;

export type AllArticleAuthorsQuery = { __typename?: 'Query' } & {
  allArticleAuthors: { __typename?: 'ArticleAuthorConnection' } & ArticleAuthorConnectionFragment;
};

export type AllArticleBlockAuthorsQueryVariables = Exact<{
  query?: Maybe<DefaultQueryInput>;
}>;

export type AllArticleBlockAuthorsQuery = { __typename?: 'Query' } & {
  allArticleBlockAuthors?: Maybe<
    { __typename?: 'ArticleBlockAuthorsConnection' } & ArticleBlockAuthorsConnectionFragment
  >;
};

export type AllArticleBlocksQueryVariables = Exact<{
  query?: Maybe<DefaultQueryInput>;
}>;

export type AllArticleBlocksQuery = { __typename?: 'Query' } & {
  allArticleBlocks: { __typename?: 'ArticleBlockConnection' } & ArticleBlockConnectionFragment;
};

export type AllArticleCategoriesQueryVariables = Exact<{
  query?: Maybe<DefaultQueryInput>;
}>;

export type AllArticleCategoriesQuery = { __typename?: 'Query' } & {
  allArticleCategories: { __typename?: 'ArticleCategoryConnection' } & ArticleCategoryConnectionFragment;
};

export type AllEntityRecordsByArticleLinkTypeQueryVariables = Exact<{
  query?: Maybe<DefaultQueryInput>;
}>;

export type AllEntityRecordsByArticleLinkTypeQuery = { __typename?: 'Query' } & {
  allEntityRecordsByArticleLinkType: {
    __typename?: 'ArticleLinkEntityTypeConnection';
  } & ArticleLinkEntityTypeConnectionFragment;
};

export type AllArticleLinksQueryVariables = Exact<{
  query?: Maybe<DefaultQueryInput>;
}>;

export type AllArticleLinksQuery = { __typename?: 'Query' } & {
  allArticleLinks: { __typename?: 'ArticleLinkConnection' } & ArticleLinkConnectionFragment;
};

export type AllArticleTagsQueryVariables = Exact<{
  query?: Maybe<DefaultQueryInput>;
}>;

export type AllArticleTagsQuery = { __typename?: 'Query' } & {
  allArticleTags: { __typename?: 'ArticleTagConnection' } & ArticleTagConnectionFragment;
};

export type AllArticlesQueryVariables = Exact<{
  query?: Maybe<DefaultQueryInput>;
}>;

export type AllArticlesQuery = { __typename?: 'Query' } & {
  allArticles: { __typename?: 'ArticlesConnection' } & ArticlesConnectionFragment;
};

export type AllCatalogueCategoriesQueryVariables = Exact<{
  query?: Maybe<DefaultQueryInput>;
}>;

export type AllCatalogueCategoriesQuery = { __typename?: 'Query' } & {
  allCatalogueCategories: { __typename?: 'CatalogueCategoryConnection' } & CatalogueCategoryConnectionFragment;
};

export type AllCatalogueItemFieldsQueryVariables = Exact<{
  query?: Maybe<DefaultQueryInput>;
}>;

export type AllCatalogueItemFieldsQuery = { __typename?: 'Query' } & {
  allCatalogueItemFields: { __typename?: 'CatalogueItemFieldsConnection' } & CatalogueItemFieldsConnectionFragment;
};

export type AllCatalogueItemPricesQueryVariables = Exact<{
  query?: Maybe<DefaultQueryInput>;
}>;

export type AllCatalogueItemPricesQuery = { __typename?: 'Query' } & {
  allCatalogueItemPrices: { __typename?: 'CatalogueItemPriceConnection' } & CatalogueItemPriceConnectionFragment;
};

export type AllCatalogueItemsQueryVariables = Exact<{
  query?: Maybe<DefaultQueryInput>;
}>;

export type AllCatalogueItemsQuery = { __typename?: 'Query' } & {
  allCatalogueItems: { __typename?: 'CatalogueItemConnection' } & CatalogueItemConnectionFragment;
};

export type AllCatalogueItemsAndCategoriesQueryVariables = Exact<{
  categoriesQuery?: Maybe<DefaultQueryInput>;
  itemsQuery?: Maybe<DefaultQueryInput>;
}>;

export type AllCatalogueItemsAndCategoriesQuery = { __typename?: 'Query' } & {
  allCatalogueCategories: { __typename?: 'CatalogueCategoryConnection' } & CatalogueCategoryConnectionFragment;
  allCatalogueItems: { __typename?: 'CatalogueItemConnection' } & CatalogueItemConnectionFragment;
};

export type AllCataloguesQueryVariables = Exact<{
  query?: Maybe<DefaultQueryInput>;
}>;

export type AllCataloguesQuery = { __typename?: 'Query' } & {
  allCatalogues: { __typename?: 'CatalogueConnection' } & CatalogueConnectionFragment;
};

export type AllCateringsQueryVariables = Exact<{
  query?: Maybe<DefaultQueryInput>;
}>;

export type AllCateringsQuery = { __typename?: 'Query' } & {
  allCaterings: { __typename?: 'CateringConnection' } & CateringConnectionFragment;
};

export type AllCommerceOrderDatasQueryVariables = Exact<{
  query?: Maybe<DefaultQueryInput>;
}>;

export type AllCommerceOrderDatasQuery = { __typename?: 'Query' } & {
  allCommerceOrderDatas: { __typename?: 'CommerceOrderDataConnection' } & CommerceOrderDataConnectionFragment;
};

export type AllCommerceOrderFieldsQueryVariables = Exact<{
  query?: Maybe<DefaultQueryInput>;
}>;

export type AllCommerceOrderFieldsQuery = { __typename?: 'Query' } & {
  allCommerceOrderFields: { __typename?: 'CommerceOrderFieldConnection' } & CommerceOrderFieldConnectionFragment;
};

export type AllCommerceOrderItemsQueryVariables = Exact<{
  query?: Maybe<DefaultQueryInput>;
}>;

export type AllCommerceOrderItemsQuery = { __typename?: 'Query' } & {
  allCommerceOrderItems: { __typename?: 'CommerceOrderItemConnection' } & CommerceOrderItemConnectionFragment;
};

export type AllCommerceOrdersQueryVariables = Exact<{
  query?: Maybe<DefaultQueryInput>;
}>;

export type AllCommerceOrdersQuery = { __typename?: 'Query' } & {
  allCommerceOrders: { __typename?: 'CommerceOrderConnection' } & CommerceOrderConnectionFragment;
};

export type AllCommerceShopsQueryVariables = Exact<{
  query?: Maybe<DefaultQueryInput>;
}>;

export type AllCommerceShopsQuery = { __typename?: 'Query' } & {
  allCommerceShops: { __typename?: 'CommerceShopConnection' } & CommerceShopConnectionFragment;
};

export type AllCommerceTemplatesQueryVariables = Exact<{
  query?: Maybe<DefaultQueryInput>;
}>;

export type AllCommerceTemplatesQuery = { __typename?: 'Query' } & {
  allCommerceTemplates: { __typename?: 'CommerceTemplateConnection' } & CommerceTemplateConnectionFragment;
};

export type AllContentTranslationsQueryVariables = Exact<{
  query?: Maybe<DefaultQueryInput>;
}>;

export type AllContentTranslationsQuery = { __typename?: 'Query' } & {
  allContentTranslations: { __typename?: 'ContentTranslationConnection' } & ContentTranslationConnectionFragment;
};

export type AllDisputeDisputeResponsesQueryVariables = Exact<{
  query?: Maybe<DefaultQueryInput>;
}>;

export type AllDisputeDisputeResponsesQuery = { __typename?: 'Query' } & {
  allDisputeDisputeResponses: {
    __typename?: 'DisputeDisputeResponseConnection';
  } & DisputeDisputeResponseConnectionFragment;
};

export type AllDisputesQueryVariables = Exact<{
  query?: Maybe<DefaultQueryInput>;
}>;

export type AllDisputesQuery = { __typename?: 'Query' } & {
  allDisputes: { __typename?: 'DisputeConnection' } & DisputeConnectionFragment;
};

export type AllEventEventAccessesQueryVariables = Exact<{
  query?: Maybe<DefaultQueryInput>;
}>;

export type AllEventEventAccessesQuery = { __typename?: 'Query' } & {
  allEventEventAccesses: { __typename?: 'EventEventAccessConnection' } & EventEventAccessConnectionFragment;
};

export type AllEventFieldsQueryVariables = Exact<{
  query?: Maybe<DefaultQueryInput>;
}>;

export type AllEventFieldsQuery = { __typename?: 'Query' } & {
  allEventFields: { __typename?: 'EventFieldsConnection' } & EventFieldsConnectionFragment;
};

export type AllEventOrderTicketsQueryVariables = Exact<{
  query?: Maybe<DefaultQueryInput>;
}>;

export type AllEventOrderTicketsQuery = { __typename?: 'Query' } & {
  allEventOrderTickets: { __typename?: 'EventOrderTicketConnection' } & EventOrderTicketConnectionFragment;
};

export type AllEventOrdersQueryVariables = Exact<{
  query?: Maybe<DefaultQueryInput>;
}>;

export type AllEventOrdersQuery = { __typename?: 'Query' } & {
  allEventOrders: { __typename?: 'EventOrderConnection' } & EventOrderConnectionFragment;
};

export type AllEventPlacesQueryVariables = Exact<{
  query?: Maybe<DefaultQueryInput>;
}>;

export type AllEventPlacesQuery = { __typename?: 'Query' } & {
  allEventPlaces: { __typename?: 'EventPlaceConnection' } & EventPlaceConnectionFragment;
};

export type AllEventReservesQueryVariables = Exact<{
  query?: Maybe<DefaultQueryInput>;
}>;

export type AllEventReservesQuery = { __typename?: 'Query' } & {
  allEventReserves: { __typename?: 'EventReserveConnection' } & EventReserveConnectionFragment;
};

export type AllEventTypesQueryVariables = Exact<{
  query?: Maybe<DefaultQueryInput>;
}>;

export type AllEventTypesQuery = { __typename?: 'Query' } & {
  allEventTypes: { __typename?: 'EventTypeConnection' } & EventTypeConnectionFragment;
};

export type AllEventsQueryVariables = Exact<{
  query?: Maybe<DefaultQueryInput>;
}>;

export type AllEventsQuery = { __typename?: 'Query' } & {
  allEvents: { __typename?: 'EventConnection' } & EventConnectionFragment;
};

export type AllEventsCalendarQueryVariables = Exact<{
  query?: Maybe<DefaultQueryInput>;
}>;

export type AllEventsCalendarQuery = { __typename?: 'Query' } & {
  allEvents: { __typename?: 'EventConnection' } & Pick<EventConnection, 'total' | 'limit' | 'cursor' | 'sortable'> & {
      edges: Array<{ __typename?: 'Event' } & EventCalendarFragmentFragment>;
    };
};

export type AllLoyaltyItemsQueryVariables = Exact<{
  query?: Maybe<DefaultQueryInput>;
}>;

export type AllLoyaltyItemsQuery = { __typename?: 'Query' } & {
  allLoyaltyItems: { __typename?: 'LoyaltyItemConnection' } & LoyaltyItemConnectionFragment;
};

export type AllLoyaltyPartnerEntitiesQueryVariables = Exact<{
  query?: Maybe<DefaultQueryInput>;
}>;

export type AllLoyaltyPartnerEntitiesQuery = { __typename?: 'Query' } & {
  allLoyaltyPartnerEntities: { __typename?: 'LoyaltyPartnerEntityConnection' } & LoyaltyPartnerEntityConnectionFragment;
};

export type AllLoyaltyPartnersQueryVariables = Exact<{
  query?: Maybe<DefaultQueryInput>;
}>;

export type AllLoyaltyPartnersQuery = { __typename?: 'Query' } & {
  allLoyaltyPartners: { __typename?: 'LoyaltyPartnerConnection' } & LoyaltyPartnerConnectionFragment;
};

export type AllLoyaltyPromoCodesQueryVariables = Exact<{
  query?: Maybe<DefaultQueryInput>;
}>;

export type AllLoyaltyPromoCodesQuery = { __typename?: 'Query' } & {
  allLoyaltyPromoCodes: { __typename?: 'LoyaltyPromoCodeConnection' } & LoyaltyPromoCodeConnectionFragment;
};

export type AllLoyaltyPromosQueryVariables = Exact<{
  query?: Maybe<DefaultQueryInput>;
}>;

export type AllLoyaltyPromosQuery = { __typename?: 'Query' } & {
  allLoyaltyPromos: { __typename?: 'LoyaltyPromoConnection' } & LoyaltyPromoConnectionFragment;
};

export type AllMessengerMailingsQueryVariables = Exact<{
  query?: Maybe<DefaultQueryInput>;
}>;

export type AllMessengerMailingsQuery = { __typename?: 'Query' } & {
  allMessengerMailings: { __typename?: 'MessengerMailingConnection' } & MessengerMailingConnectionFragment;
};

export type AllMessengerQueueNotificationsQueryVariables = Exact<{
  query?: Maybe<DefaultQueryInput>;
}>;

export type AllMessengerQueueNotificationsQuery = { __typename?: 'Query' } & {
  allMessengerQueueNotifications: {
    __typename?: 'MessengerQueueNotificationConnection';
  } & MessengerQueueNotificationConnectionFragment;
};

export type AllMessengerQueueTasksQueryVariables = Exact<{
  query?: Maybe<DefaultQueryInput>;
}>;

export type AllMessengerQueueTasksQuery = { __typename?: 'Query' } & {
  allMessengerQueueTasks: { __typename?: 'MessengerQueueTaskConnection' } & MessengerQueueTaskConnectionFragment;
};

export type AllMessengerTransportsQueryVariables = Exact<{
  query?: Maybe<DefaultQueryInput>;
}>;

export type AllMessengerTransportsQuery = { __typename?: 'Query' } & {
  allMessengerTransports: { __typename?: 'MessengerTransportConnection' } & MessengerTransportConnectionFragment;
};

export type AllMobileTranslationsQueryVariables = Exact<{
  locale: Scalars['String'];
}>;

export type AllMobileTranslationsQuery = { __typename?: 'Query' } & {
  allMobileTranslations?: Maybe<Array<{ __typename?: 'MobileTranslation' } & MobileTranslationFragment>>;
};

export type AllMyEventOrdersQueryVariables = Exact<{
  query?: Maybe<DefaultQueryInput>;
}>;

export type AllMyEventOrdersQuery = { __typename?: 'Query' } & {
  allMyEventOrders: { __typename?: 'EventOrderConnection' } & EventOrderConnectionFragment;
};

export type AllMyEventOrdersLightQueryVariables = Exact<{
  query?: Maybe<DefaultQueryInput>;
}>;

export type AllMyEventOrdersLightQuery = { __typename?: 'Query' } & {
  allMyEventOrders: { __typename?: 'EventOrderConnection' } & EventOrderConnectionMinFragment;
};

export type AllMyLoyaltyOrdersQueryVariables = Exact<{
  query?: Maybe<DefaultQueryInput>;
}>;

export type AllMyLoyaltyOrdersQuery = { __typename?: 'Query' } & {
  allMyLoyaltyOrders: { __typename?: 'LoyaltyOrderConnection' } & LoyaltyOrderConnectionFragment;
};

export type AllMySubscriptionsQueryVariables = Exact<{
  query?: Maybe<DefaultQueryInput>;
}>;

export type AllMySubscriptionsQuery = { __typename?: 'Query' } & {
  allMySubscriptions: { __typename?: 'UserSubscriptionConnection' } & UserSubscriptionConnectionFragment;
};

export type AllPaymentAccountsQueryVariables = Exact<{
  query?: Maybe<DefaultQueryInput>;
}>;

export type AllPaymentAccountsQuery = { __typename?: 'Query' } & {
  allPaymentAccounts: { __typename?: 'PaymentAccountConnection' } & PaymentAccountConnectionFragment;
};

export type AllPaymentCurrenciesQueryVariables = Exact<{
  query?: Maybe<DefaultQueryInput>;
}>;

export type AllPaymentCurrenciesQuery = { __typename?: 'Query' } & {
  allPaymentCurrencies: { __typename?: 'PaymentCurrencyConnection' } & PaymentCurrencyConnectionFragment;
};

export type AllPaymentProvidersQueryVariables = Exact<{
  query?: Maybe<DefaultQueryInput>;
}>;

export type AllPaymentProvidersQuery = { __typename?: 'Query' } & {
  allPaymentProviders: { __typename?: 'PaymentProviderConnection' } & PaymentProviderConnectionFragment;
};

export type AllPaymentTransactionsQueryVariables = Exact<{
  query?: Maybe<DefaultQueryInput>;
}>;

export type AllPaymentTransactionsQuery = { __typename?: 'Query' } & {
  allPaymentTransactions: { __typename?: 'PaymentTransactionConnection' } & PaymentTransactionConnectionFragment;
};

export type AllSystemAsyncTasksQueryVariables = Exact<{
  query?: Maybe<DefaultQueryInput>;
}>;

export type AllSystemAsyncTasksQuery = { __typename?: 'Query' } & {
  allSystemAsyncTasks: { __typename?: 'SystemAsyncTaskConnection' } & SystemAsyncTaskConnectionFragment;
};

export type AllSystemLocalesQueryVariables = Exact<{
  query?: Maybe<DefaultQueryInput>;
}>;

export type AllSystemLocalesQuery = { __typename?: 'Query' } & {
  allSystemLocales: { __typename?: 'SystemLocaleConnection' } & SystemLocaleConnectionFragment;
};

export type AllSystemPrintersQueryVariables = Exact<{
  query?: Maybe<DefaultQueryInput>;
}>;

export type AllSystemPrintersQuery = { __typename?: 'Query' } & {
  allSystemPrinters: { __typename?: 'SystemPrinterConnection' } & SystemPrinterConnectionFragment;
};

export type AllSystemTranslationsQueryVariables = Exact<{
  query?: Maybe<DefaultQueryInput>;
}>;

export type AllSystemTranslationsQuery = { __typename?: 'Query' } & {
  allSystemTranslations: { __typename?: 'SystemTranslationConnection' } & SystemTranslationConnectionFragment;
};

export type AllSystemUsersQueryVariables = Exact<{
  include?: Maybe<SystemUsersWhereInput>;
  sort?: Maybe<Array<DefaultSortInput> | DefaultSortInput>;
  search?: Maybe<Scalars['String']>;
  cursor?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
}>;

export type AllSystemUsersQuery = { __typename?: 'Query' } & {
  allSystemUsers: { __typename?: 'SystemUsersConnection' } & SystemUsersConnectionFragment;
};

export type AllSystemWebHooksQueryVariables = Exact<{
  query?: Maybe<DefaultQueryInput>;
}>;

export type AllSystemWebHooksQuery = { __typename?: 'Query' } & {
  allSystemWebHooks: { __typename?: 'SystemWebHookConnection' } & SystemWebHookConnectionFragment;
};

export type AllTemplateApplePassesQueryVariables = Exact<{
  query?: Maybe<DefaultQueryInput>;
}>;

export type AllTemplateApplePassesQuery = { __typename?: 'Query' } & {
  allTemplateApplePasses: { __typename?: 'TemplateApplePassConnection' } & TemplateApplePassConnectionFragment;
};

export type AllTemplateNotificationsQueryVariables = Exact<{
  query?: Maybe<DefaultQueryInput>;
}>;

export type AllTemplateNotificationsQuery = { __typename?: 'Query' } & {
  allTemplateNotifications: { __typename?: 'TemplateNotificationConnection' } & TemplateNotificationConnectionFragment;
};

export type AllTemplatePdFsQueryVariables = Exact<{
  query?: Maybe<DefaultQueryInput>;
}>;

export type AllTemplatePdFsQuery = { __typename?: 'Query' } & {
  allTemplatePDFs: { __typename?: 'TemplatePDFConnection' } & TemplatePdfConnectionFragment;
};

export type AllTournamentDisqualificationsQueryVariables = Exact<{
  query?: Maybe<DefaultQueryInput>;
}>;

export type AllTournamentDisqualificationsQuery = { __typename?: 'Query' } & {
  allTournamentDisqualifications: {
    __typename?: 'TournamentDisqualificationConnection';
  } & TournamentDisqualificationConnectionFragment;
};

export type AllTournamentMatchEventsQueryVariables = Exact<{
  query?: Maybe<DefaultQueryInput>;
}>;

export type AllTournamentMatchEventsQuery = { __typename?: 'Query' } & {
  allTournamentMatchEvents: { __typename?: 'TournamentMatchEventConnection' } & TournamentMatchEventConnectionFragment;
};

export type AllTournamentMatchProtocolsQueryVariables = Exact<{
  query?: Maybe<DefaultQueryInput>;
}>;

export type AllTournamentMatchProtocolsQuery = { __typename?: 'Query' } & {
  allTournamentMatchProtocols: {
    __typename?: 'TournamentMatchProtocolConnection';
  } & TournamentMatchProtocolConnectionFragment;
};

export type AllTournamentMatchesQueryVariables = Exact<{
  query?: Maybe<DefaultQueryInput>;
}>;

export type AllTournamentMatchesQuery = { __typename?: 'Query' } & {
  allTournamentMatches: { __typename?: 'TournamentMatchConnection' } & TournamentMatchConnectionFragment;
};

export type AllTournamentPlayerStatsQueryVariables = Exact<{
  query?: Maybe<DefaultQueryInput>;
}>;

export type AllTournamentPlayerStatsQuery = { __typename?: 'Query' } & {
  allTournamentPlayerStats: { __typename?: 'TournamentPlayerStatConnection' } & TournamentPlayerStatConnectionFragment;
};

export type AllTournamentRefereesQueryVariables = Exact<{
  query?: Maybe<DefaultQueryInput>;
}>;

export type AllTournamentRefereesQuery = { __typename?: 'Query' } & {
  allTournamentReferees: { __typename?: 'TournamentRefereeConnection' } & TournamentRefereeConnectionFragment;
};

export type AllTournamentSeasonTeamStatsQueryVariables = Exact<{
  query?: Maybe<DefaultQueryInput>;
}>;

export type AllTournamentSeasonTeamStatsQuery = { __typename?: 'Query' } & {
  allTournamentSeasonTeamStats: {
    __typename?: 'TournamentSeasonTeamStatConnection';
  } & TournamentSeasonTeamStatConnectionFragment;
};

export type AllTournamentSeasonTeamsQueryVariables = Exact<{
  query?: Maybe<DefaultQueryInput>;
}>;

export type AllTournamentSeasonTeamsQuery = { __typename?: 'Query' } & {
  allTournamentSeasonTeams: { __typename?: 'TournamentSeasonTeamConnection' } & TournamentSeasonTeamConnectionFragment;
};

export type AllTournamentSeasonsQueryVariables = Exact<{
  query?: Maybe<DefaultQueryInput>;
}>;

export type AllTournamentSeasonsQuery = { __typename?: 'Query' } & {
  allTournamentSeasons: { __typename?: 'TournamentSeasonConnection' } & TournamentSeasonConnectionFragment;
};

export type AllTournamentTeamMembersQueryVariables = Exact<{
  query?: Maybe<DefaultQueryInput>;
}>;

export type AllTournamentTeamMembersQuery = { __typename?: 'Query' } & {
  allTournamentTeamMembers: { __typename?: 'TournamentTeamMemberConnection' } & TournamentTeamMemberConnectionFragment;
};

export type AllTournamentTeamsQueryVariables = Exact<{
  query?: Maybe<DefaultQueryInput>;
}>;

export type AllTournamentTeamsQuery = { __typename?: 'Query' } & {
  allTournamentTeams: { __typename?: 'TournamentTeamConnection' } & TournamentTeamConnectionFragment;
};

export type AllTournamentToursQueryVariables = Exact<{
  query?: Maybe<DefaultQueryInput>;
}>;

export type AllTournamentToursQuery = { __typename?: 'Query' } & {
  allTournamentTours: { __typename?: 'TournamentTourConnection' } & TournamentTourConnectionFragment;
};

export type AllTournamentToursDeepQueryVariables = Exact<{
  query?: Maybe<DefaultQueryInput>;
}>;

export type AllTournamentToursDeepQuery = { __typename?: 'Query' } & {
  allTournamentTours: { __typename?: 'TournamentTourConnection' } & TournamentTourConnectionDeepFragment;
};

export type AllTournamentsQueryVariables = Exact<{
  query?: Maybe<DefaultQueryInput>;
}>;

export type AllTournamentsQuery = { __typename?: 'Query' } & {
  allTournaments: { __typename?: 'TournamentConnection' } & TournamentConnectionFragment;
};

export type AllUserApplicationsQueryVariables = Exact<{
  query?: Maybe<DefaultQueryInput>;
}>;

export type AllUserApplicationsQuery = { __typename?: 'Query' } & {
  allUserApplications: { __typename?: 'UserApplicationConnection' } & Pick<UserApplicationConnection, 'total'> & {
      edges: Array<{ __typename?: 'UserApplication' } & UserApplicationFragment>;
    };
};

export type AllUserFieldsQueryVariables = Exact<{
  query?: Maybe<DefaultQueryInput>;
}>;

export type AllUserFieldsQuery = { __typename?: 'Query' } & {
  allUserFields: { __typename?: 'UserFieldsConnection' } & UserFieldsConnectionFragment;
};

export type AllUserGroupsQueryVariables = Exact<{
  query?: Maybe<DefaultQueryInput>;
}>;

export type AllUserGroupsQuery = { __typename?: 'Query' } & {
  allUserGroups: { __typename?: 'UserGroupConnection' } & UserGroupConnectionFragment;
};

export type AllUserKidsQueryVariables = Exact<{
  query?: Maybe<DefaultQueryInput>;
}>;

export type AllUserKidsQuery = { __typename?: 'Query' } & {
  allUserKids: { __typename?: 'UserKidConnection' } & Pick<UserKidConnection, 'total'> & {
      edges: Array<{ __typename?: 'UserKid' } & UserKidFragment>;
    };
};

export type AllUserSubscribersQueryVariables = Exact<{
  query?: Maybe<DefaultQueryInput>;
}>;

export type AllUserSubscribersQuery = { __typename?: 'Query' } & {
  allUserSubscribers: { __typename?: 'UserSubscriberConnection' } & UserSubscriberConnectionFragment;
};

export type AllUserSubscribersLogsQueryVariables = Exact<{
  query?: Maybe<DefaultQueryInput>;
}>;

export type AllUserSubscribersLogsQuery = { __typename?: 'Query' } & {
  allUserSubscribersLogs: { __typename?: 'UserSubscribersLogConnection' } & UserSubscribersLogConnectionFragment;
};

export type AllUserSubscriptionsQueryVariables = Exact<{
  query?: Maybe<DefaultQueryInput>;
}>;

export type AllUserSubscriptionsQuery = { __typename?: 'Query' } & {
  allUserSubscriptions: { __typename?: 'UserSubscriptionConnection' } & UserSubscriptionConnectionFragment;
};

export type AllUsersQueryVariables = Exact<{
  query?: Maybe<DefaultQueryInput>;
}>;

export type AllUsersQuery = { __typename?: 'Query' } & {
  allUsers?: Maybe<{ __typename?: 'UserConnection' } & UserConnectionFragment>;
};

export type AllWebTranslationsQueryVariables = Exact<{
  locale: Scalars['String'];
}>;

export type AllWebTranslationsQuery = { __typename?: 'Query' } & {
  allWebTranslations: Array<{ __typename?: 'Translation' } & TranslationFragment>;
};

export type ApplicationFiltersQueryVariables = Exact<{
  partnerId: Scalars['Int'];
}>;

export type ApplicationFiltersQuery = { __typename?: 'Query' } & {
  applicationFilters?: Maybe<Array<{ __typename?: 'FilterItem' } & FilterItemFragment>>;
};

export type ArticleQueryVariables = Exact<{
  id: Scalars['Int'];
}>;

export type ArticleQuery = { __typename?: 'Query' } & {
  article?: Maybe<{ __typename?: 'Article' } & ArticleFullFragment>;
};

export type ArticleAuthorQueryVariables = Exact<{
  id: Scalars['Int'];
}>;

export type ArticleAuthorQuery = { __typename?: 'Query' } & {
  articleAuthor: { __typename?: 'ArticleAuthor' } & ArticleAuthorFragment;
};

export type ArticleBlockQueryVariables = Exact<{
  id: Scalars['Int'];
}>;

export type ArticleBlockQuery = { __typename?: 'Query' } & {
  articleBlock: { __typename?: 'ArticleBlock' } & ArticleBlockFragment;
};

export type ArticleCategoryQueryVariables = Exact<{
  id: Scalars['Int'];
}>;

export type ArticleCategoryQuery = { __typename?: 'Query' } & {
  articleCategory: { __typename?: 'ArticleCategory' } & ArticleCategoryFragment;
};

export type ArticleLinkQueryVariables = Exact<{
  id: Scalars['Int'];
}>;

export type ArticleLinkQuery = { __typename?: 'Query' } & {
  articleLink: { __typename?: 'ArticleLink' } & ArticleLinkFragment;
};

export type ArticleTagQueryVariables = Exact<{
  id: Scalars['Int'];
}>;

export type ArticleTagQuery = { __typename?: 'Query' } & {
  articleTag: { __typename?: 'ArticleTag' } & ArticleTagFragment;
};

export type CatalogueQueryVariables = Exact<{
  id: Scalars['Int'];
}>;

export type CatalogueQuery = { __typename?: 'Query' } & { catalogue: { __typename?: 'Catalogue' } & CatalogueFragment };

export type CatalogueCategoryQueryVariables = Exact<{
  id: Scalars['Int'];
}>;

export type CatalogueCategoryQuery = { __typename?: 'Query' } & {
  catalogueCategory: { __typename?: 'CatalogueCategory' } & CatalogueCategoryFragment;
};

export type CatalogueItemQueryVariables = Exact<{
  id: Scalars['Int'];
}>;

export type CatalogueItemQuery = { __typename?: 'Query' } & {
  catalogueItem: { __typename?: 'CatalogueItem' } & CatalogueItemFragment;
};

export type CatalogueItemPriceQueryVariables = Exact<{
  id: Scalars['Int'];
}>;

export type CatalogueItemPriceQuery = { __typename?: 'Query' } & {
  catalogueItemPrice: { __typename?: 'CatalogueItemPrice' } & CatalogueItemPriceFragment;
};

export type CatalogueItemsByEventQueryVariables = Exact<{
  eventId: Scalars['Int'];
}>;

export type CatalogueItemsByEventQuery = { __typename?: 'Query' } & {
  catalogueItemsByEvent: Array<{ __typename?: 'CatalogueItem' } & CatalogueItemFragment>;
};

export type CatalogueSummaryWidgetQueryVariables = Exact<{ [key: string]: never }>;

export type CatalogueSummaryWidgetQuery = { __typename?: 'Query' } & {
  catalogueSummaryWidget?: Maybe<Array<{ __typename?: 'CatalogueSummaryWidget' } & CatalogueSummaryWidgetFragment>>;
};

export type CateringQueryVariables = Exact<{
  id: Scalars['Int'];
}>;

export type CateringQuery = { __typename?: 'Query' } & { catering: { __typename?: 'Catering' } & CateringFragment };

export type CheckFanIdQueryVariables = Exact<{
  fanId: Array<Scalars['String']> | Scalars['String'];
}>;

export type CheckFanIdQuery = { __typename?: 'Query' } & {
  checkFanId: Array<
    { __typename?: 'CheckFanIdResult' } & Pick<CheckFanIdResult, 'fanCode' | 'fanGUID' | 'isSuccess' | 'errorMessage'>
  >;
};

export type CheckPromocodeQueryVariables = Exact<{
  promoId: Scalars['Int'];
  code: Scalars['String'];
}>;

export type CheckPromocodeQuery = { __typename?: 'Query' } & {
  checkPromocode?: Maybe<
    { __typename?: 'LoyaltyPromoCode' } & Pick<LoyaltyPromoCode, 'id' | 'code'> & {
        promo: { __typename?: 'LoyaltyPromo' } & Pick<LoyaltyPromo, 'id' | 'discountPercent'>;
      }
  >;
};

export type ClientPublicProfileQueryVariables = Exact<{
  name?: Maybe<Scalars['String']>;
  domain?: Maybe<Scalars['String']>;
}>;

export type ClientPublicProfileQuery = { __typename?: 'Query' } & {
  clientPublicProfile?: Maybe<{ __typename?: 'ClientPublicProfile' } & ClientPublicProfileFragment>;
};

export type CommerceOrderQueryVariables = Exact<{
  id: Scalars['Int'];
}>;

export type CommerceOrderQuery = { __typename?: 'Query' } & {
  commerceOrder: { __typename?: 'CommerceOrder' } & CommerceOrderFragment;
};

export type CommerceOrderDataQueryVariables = Exact<{
  id: Scalars['Int'];
}>;

export type CommerceOrderDataQuery = { __typename?: 'Query' } & {
  commerceOrderData: { __typename?: 'CommerceOrderData' } & CommerceOrderDataFragment;
};

export type CommerceOrderFieldQueryVariables = Exact<{
  id: Scalars['Int'];
}>;

export type CommerceOrderFieldQuery = { __typename?: 'Query' } & {
  commerceOrderField: { __typename?: 'CommerceOrderField' } & CommerceOrderFieldFragment;
};

export type CommerceOrderFiltersQueryVariables = Exact<{
  shopId: Scalars['Int'];
}>;

export type CommerceOrderFiltersQuery = { __typename?: 'Query' } & {
  commerceOrderFilters?: Maybe<Array<{ __typename?: 'FilterItem' } & FilterItemFragment>>;
};

export type CommerceOrderItemQueryVariables = Exact<{
  id: Scalars['Int'];
}>;

export type CommerceOrderItemQuery = { __typename?: 'Query' } & {
  commerceOrderItem: { __typename?: 'CommerceOrderItem' } & CommerceOrderItemFragment;
};

export type CommerceOrderStatesWidgetQueryVariables = Exact<{
  shopId: Scalars['Int'];
}>;

export type CommerceOrderStatesWidgetQuery = { __typename?: 'Query' } & {
  commerceOrderStatesWidget?: Maybe<
    Array<{ __typename?: 'CommerceOrderStatesWidgetData' } & CommerceOrderStatesWidgetDataFragment>
  >;
};

export type CommerceShopQueryVariables = Exact<{
  id: Scalars['Int'];
}>;

export type CommerceShopQuery = { __typename?: 'Query' } & {
  commerceShop: { __typename?: 'CommerceShop' } & CommerceShopFragment;
};

export type CommerceSummaryWidgetQueryVariables = Exact<{
  query?: Maybe<DefaultQueryInput>;
}>;

export type CommerceSummaryWidgetQuery = { __typename?: 'Query' } & {
  commerceSummaryWidget?: Maybe<Array<{ __typename?: 'CommerceSummaryWidget' } & CommerceSummaryWidgetFragment>>;
};

export type ContentTranslationQueryVariables = Exact<{
  id: Scalars['Int'];
}>;

export type ContentTranslationQuery = { __typename?: 'Query' } & {
  contentTranslation: { __typename?: 'ContentTranslation' } & ContentTranslationFragment;
};

export type ContentTranslationsQueryVariables = Exact<{
  query?: Maybe<DefaultQueryInput>;
}>;

export type ContentTranslationsQuery = { __typename?: 'Query' } & {
  contentTranslations: Array<{ __typename?: 'ContentTranslation' } & ContentTranslationFragment>;
};

export type DisputeQueryVariables = Exact<{
  id: Scalars['Int'];
}>;

export type DisputeQuery = { __typename?: 'Query' } & { dispute: { __typename?: 'Dispute' } & DisputeFragment };

export type DisputeDisputeResponseQueryVariables = Exact<{
  id: Scalars['Int'];
}>;

export type DisputeDisputeResponseQuery = { __typename?: 'Query' } & {
  disputeDisputeResponse: { __typename?: 'DisputeDisputeResponse' } & DisputeDisputeResponseFragment;
};

export type DisputeSettingsQueryVariables = Exact<{ [key: string]: never }>;

export type DisputeSettingsQuery = { __typename?: 'Query' } & {
  disputeSettings: { __typename?: 'DisputeSettings' } & DisputeSettingsFragment;
};

export type EventQueryVariables = Exact<{
  id: Scalars['Int'];
}>;

export type EventQuery = { __typename?: 'Query' } & { event: { __typename?: 'Event' } & EventFullFragment };

export type EventEventAccessQueryVariables = Exact<{
  id: Scalars['Int'];
}>;

export type EventEventAccessQuery = { __typename?: 'Query' } & {
  eventEventAccess: { __typename?: 'EventEventAccess' } & EventEventAccessFragment;
};

export type EventOrderQueryVariables = Exact<{
  id: Scalars['Int'];
}>;

export type EventOrderQuery = { __typename?: 'Query' } & {
  eventOrder: { __typename?: 'EventOrder' } & EventOrderFragment;
};

export type EventOrderTicketQueryVariables = Exact<{
  id: Scalars['Int'];
}>;

export type EventOrderTicketQuery = { __typename?: 'Query' } & {
  eventOrderTicket: { __typename?: 'EventOrderTicket' } & EventOrderTicketFullFragment;
};

export type EventPlaceQueryVariables = Exact<{
  id: Scalars['Int'];
}>;

export type EventPlaceQuery = { __typename?: 'Query' } & {
  eventPlace: { __typename?: 'EventPlace' } & EventPlaceFragment;
};

export type EventReserveQueryVariables = Exact<{
  id: Scalars['Int'];
}>;

export type EventReserveQuery = { __typename?: 'Query' } & {
  eventReserve: { __typename?: 'EventReserve' } & EventReserveFragment;
};

export type EventSectorQueryVariables = Exact<{
  eventId: Scalars['Int'];
  sectorId: Scalars['Int'];
}>;

export type EventSectorQuery = { __typename?: 'Query' } & {
  eventSector: { __typename?: 'EventSector' } & EventSectorFragment;
};

export type EventSectorSeatsQueryVariables = Exact<{
  eventId: Scalars['Int'];
  sectorId: Scalars['Int'];
  sectorKey?: Maybe<Scalars['String']>;
}>;

export type EventSectorSeatsQuery = { __typename?: 'Query' } & {
  eventSectorSeats: Array<{ __typename?: 'EventSectorRow' } & EventSectorRowFragment>;
};

export type EventSectorsQueryVariables = Exact<{
  eventId: Scalars['Int'];
  sectorId?: Maybe<Array<Scalars['Int']> | Scalars['Int']>;
}>;

export type EventSectorsQuery = { __typename?: 'Query' } & {
  eventSectors: Array<{ __typename?: 'EventSector' } & EventSectorFragment>;
};

export type EventTypesConfigQueryVariables = Exact<{ [key: string]: never }>;

export type EventTypesConfigQuery = { __typename?: 'Query' } & {
  eventTypesConfig: { __typename?: 'EventTypesConfig' } & EventTypesConfigFragment;
};

export type EventWidgetActiveEventsQueryVariables = Exact<{ [key: string]: never }>;

export type EventWidgetActiveEventsQuery = { __typename?: 'Query' } & {
  eventWidgetActiveEvents: { __typename?: 'EventWidgetActiveEvents' } & EventWidgetActiveEventsFragment;
};

export type EventWidgetNextEventQueryVariables = Exact<{ [key: string]: never }>;

export type EventWidgetNextEventQuery = { __typename?: 'Query' } & {
  eventWidgetNextEvent?: Maybe<{ __typename?: 'Event' } & EventWidgetFragment>;
};

export type EventWidgetTicketSalesQueryVariables = Exact<{
  query?: Maybe<EventWidgetTicketSalesInput>;
}>;

export type EventWidgetTicketSalesQuery = { __typename?: 'Query' } & {
  eventWidgetTicketSales?: Maybe<
    { __typename?: 'EventWidgetTicketSales' } & {
      summary: { __typename?: 'EventWidgetTicketSalesTotals' } & EventWidgetTicketSalesTotalsFragment;
      graphs: Array<{ __typename?: 'CommonGraph' } & CommonGraphFragment>;
    }
  >;
};

export type FiltersQueryVariables = Exact<{
  queryName: Scalars['String'];
}>;

export type FiltersQuery = { __typename?: 'Query' } & {
  filters?: Maybe<Array<{ __typename?: 'FilterItem' } & FilterItemFragment>>;
};

export type LoyaltyItemQueryVariables = Exact<{
  id: Scalars['Int'];
}>;

export type LoyaltyItemQuery = { __typename?: 'Query' } & {
  loyaltyItem: { __typename?: 'LoyaltyItem' } & LoyaltyItemFragment;
};

export type LoyaltyPartnerQueryVariables = Exact<{
  id: Scalars['Int'];
}>;

export type LoyaltyPartnerQuery = { __typename?: 'Query' } & {
  loyaltyPartner: { __typename?: 'LoyaltyPartner' } & LoyaltyPartnerFragment;
};

export type LoyaltyPartnerEntityByProviderCodeQueryVariables = Exact<{
  providerId?: Maybe<Scalars['Int']>;
  providerCode?: Maybe<Scalars['String']>;
}>;

export type LoyaltyPartnerEntityByProviderCodeQuery = { __typename?: 'Query' } & Pick<
  Query,
  'loyaltyPartnerEntityByProviderCode'
>;

export type LoyaltyPromoQueryVariables = Exact<{
  id: Scalars['Int'];
}>;

export type LoyaltyPromoQuery = { __typename?: 'Query' } & {
  loyaltyPromo: { __typename?: 'LoyaltyPromo' } & LoyaltyPromoFragment;
};

export type LoyaltyPromoCodeQueryVariables = Exact<{
  id: Scalars['Int'];
}>;

export type LoyaltyPromoCodeQuery = { __typename?: 'Query' } & {
  loyaltyPromoCode: { __typename?: 'LoyaltyPromoCode' } & LoyaltyPromoCodeFragment;
};

export type LoyaltySummaryWidgetQueryVariables = Exact<{
  where?: Maybe<LoyaltySummaryWidgetInput>;
}>;

export type LoyaltySummaryWidgetQuery = { __typename?: 'Query' } & {
  loyaltySummaryWidget?: Maybe<Array<{ __typename?: 'LoyaltySummaryWidget' } & LoyaltySummaryWidgetFragment>>;
};

export type MessengerMailingQueryVariables = Exact<{
  id: Scalars['Int'];
}>;

export type MessengerMailingQuery = { __typename?: 'Query' } & {
  messengerMailing: { __typename?: 'MessengerMailing' } & MessengerMailingFragment;
};

export type MessengerMailingChannelsQueryVariables = Exact<{ [key: string]: never }>;

export type MessengerMailingChannelsQuery = { __typename?: 'Query' } & {
  messengerMailingChannels: Array<
    { __typename?: 'MessengerMailingChannelStat' } & Pick<MessengerMailingChannelStat, 'channel' | 'value'>
  >;
};

export type MessengerMailingStatQueryVariables = Exact<{
  mailingId?: Maybe<Scalars['Int']>;
}>;

export type MessengerMailingStatQuery = { __typename?: 'Query' } & {
  messengerMailingStat: Array<
    { __typename?: 'MessengerQueueNotificationStat' } & Pick<MessengerQueueNotificationStat, 'state' | 'value'>
  >;
};

export type MessengerQueueNotificationQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type MessengerQueueNotificationQuery = { __typename?: 'Query' } & {
  messengerQueueNotification: { __typename?: 'MessengerQueueNotification' } & MessengerQueueNotificationFragment;
};

export type MessengerQueueTaskQueryVariables = Exact<{
  id: Scalars['Int'];
}>;

export type MessengerQueueTaskQuery = { __typename?: 'Query' } & {
  messengerQueueTask: { __typename?: 'MessengerQueueTask' } & MessengerQueueTaskFragment;
};

export type MessengerSettingQueryVariables = Exact<{ [key: string]: never }>;

export type MessengerSettingQuery = { __typename?: 'Query' } & {
  messengerSetting: { __typename?: 'MessengerSetting' } & MessengerSettingFragment;
};

export type MessengerTransportQueryVariables = Exact<{
  id: Scalars['Int'];
}>;

export type MessengerTransportQuery = { __typename?: 'Query' } & {
  messengerTransport: { __typename?: 'MessengerTransport' } & MessengerTransportFragment;
};

export type MobileTranslationQueryVariables = Exact<{
  id: Scalars['Int'];
}>;

export type MobileTranslationQuery = { __typename?: 'Query' } & {
  mobileTranslation: { __typename?: 'MobileTranslation' } & MobileTranslationFragment;
};

export type MyApplicationsFamilySectorQueryVariables = Exact<{ [key: string]: never }>;

export type MyApplicationsFamilySectorQuery = { __typename?: 'Query' } & {
  myApplicationsFamilySector: Array<{ __typename?: 'UserApplication' } & UserApplicationFragment>;
};

export type MyEventOrderQueryVariables = Exact<{
  id: Scalars['Int'];
}>;

export type MyEventOrderQuery = { __typename?: 'Query' } & {
  myEventOrder: { __typename?: 'EventOrder' } & EventOrderFragment;
};

export type MyEventReservesQueryVariables = Exact<{ [key: string]: never }>;

export type MyEventReservesQuery = { __typename?: 'Query' } & {
  myEventReserves: Array<{ __typename?: 'Reserve' } & ReserveFragment>;
};

export type NavigationQueryVariables = Exact<{ [key: string]: never }>;

export type NavigationQuery = { __typename?: 'Query' } & {
  navigation: Array<{ __typename?: 'Navigation' } & NavigationFragment>;
};

export type PaymentAccountQueryVariables = Exact<{
  id: Scalars['Int'];
}>;

export type PaymentAccountQuery = { __typename?: 'Query' } & {
  paymentAccount: { __typename?: 'PaymentAccount' } & PaymentAccountFragment;
};

export type PaymentCurrencyQueryVariables = Exact<{
  id: Scalars['Int'];
}>;

export type PaymentCurrencyQuery = { __typename?: 'Query' } & {
  paymentCurrency: { __typename?: 'PaymentCurrency' } & PaymentCurrencyFragment;
};

export type PaymentProviderQueryVariables = Exact<{
  id: Scalars['Int'];
}>;

export type PaymentProviderQuery = { __typename?: 'Query' } & {
  paymentProvider: { __typename?: 'PaymentProvider' } & PaymentProviderFragment;
};

export type PaymentTransactionQueryVariables = Exact<{
  id: Scalars['Int'];
}>;

export type PaymentTransactionQuery = { __typename?: 'Query' } & {
  paymentTransaction: { __typename?: 'PaymentTransaction' } & PaymentTransactionFragment;
};

export type RedirectAnalyticsQueryVariables = Exact<{
  data: RedirectAnalyticsInput;
}>;

export type RedirectAnalyticsQuery = { __typename?: 'Query' } & {
  redirectAnalytics: Array<
    { __typename?: 'MessengerMailingRedirectAnalytics' } & Pick<
      MessengerMailingRedirectAnalytics,
      'utmCampaign' | 'redirectsCount'
    >
  >;
};

export type SystemLocaleQueryVariables = Exact<{
  id: Scalars['Int'];
}>;

export type SystemLocaleQuery = { __typename?: 'Query' } & {
  systemLocale: { __typename?: 'SystemLocale' } & SystemLocaleFragment;
};

export type SystemPrinterQueryVariables = Exact<{
  id: Scalars['Int'];
}>;

export type SystemPrinterQuery = { __typename?: 'Query' } & {
  systemPrinter: { __typename?: 'SystemPrinter' } & SystemPrinterFragment;
};

export type SystemTranslationQueryVariables = Exact<{
  id: Scalars['Int'];
}>;

export type SystemTranslationQuery = { __typename?: 'Query' } & {
  systemTranslation: { __typename?: 'SystemTranslation' } & SystemTranslationFragment;
};

export type SystemTranslationsQueryVariables = Exact<{
  query?: Maybe<DefaultQueryInput>;
}>;

export type SystemTranslationsQuery = { __typename?: 'Query' } & {
  systemTranslations: Array<{ __typename?: 'SystemTranslation' } & SystemTranslationFragment>;
};

export type SystemUserQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type SystemUserQuery = { __typename?: 'Query' } & {
  systemUser: { __typename?: 'SystemUser' } & SystemUserFragment;
};

export type SystemUserAclQueryVariables = Exact<{
  userId: Scalars['ID'];
  clientId: Scalars['ID'];
}>;

export type SystemUserAclQuery = { __typename?: 'Query' } & {
  systemUserACL: Array<{ __typename?: 'ACL' } & AclFragment>;
};

export type SystemUserProfileQueryVariables = Exact<{ [key: string]: never }>;

export type SystemUserProfileQuery = { __typename?: 'Query' } & {
  systemUserProfile: { __typename?: 'SystemUser' } & SystemUserFragment;
};

export type SystemWebHookQueryVariables = Exact<{
  id: Scalars['Int'];
}>;

export type SystemWebHookQuery = { __typename?: 'Query' } & {
  systemWebHook: { __typename?: 'SystemWebHook' } & SystemWebHookFragment;
};

export type TemplateApplePassQueryVariables = Exact<{
  id: Scalars['Int'];
}>;

export type TemplateApplePassQuery = { __typename?: 'Query' } & {
  templateApplePass: { __typename?: 'TemplateApplePass' } & TemplateApplePassFragment;
};

export type TemplateNotificationQueryVariables = Exact<{
  id: Scalars['Int'];
}>;

export type TemplateNotificationQuery = { __typename?: 'Query' } & {
  templateNotification: { __typename?: 'TemplateNotification' } & TemplateNotificationFragment;
};

export type TemplatePdfQueryVariables = Exact<{
  id: Scalars['Int'];
}>;

export type TemplatePdfQuery = { __typename?: 'Query' } & {
  templatePDF: { __typename?: 'TemplatePDF' } & TemplatePdfFragment;
};

export type TournamentQueryVariables = Exact<{
  id: Scalars['Int'];
}>;

export type TournamentQuery = { __typename?: 'Query' } & {
  tournament: { __typename?: 'Tournament' } & TournamentFragment;
};

export type TournamentDisputeQueryVariables = Exact<{
  id: Scalars['Int'];
}>;

export type TournamentDisputeQuery = { __typename?: 'Query' } & {
  tournamentDispute: { __typename?: 'TournamentDispute' } & TournamentDisputeFragment;
};

export type TournamentDisqualificationQueryVariables = Exact<{
  id: Scalars['Int'];
}>;

export type TournamentDisqualificationQuery = { __typename?: 'Query' } & {
  tournamentDisqualification: { __typename?: 'TournamentDisqualification' } & TournamentDisqualificationFullFragment;
};

export type TournamentMatchQueryVariables = Exact<{
  id: Scalars['Int'];
}>;

export type TournamentMatchQuery = { __typename?: 'Query' } & {
  tournamentMatch: { __typename?: 'TournamentMatch' } & TournamentMatchDetailsFragment;
};

export type TournamentMatchEventQueryVariables = Exact<{
  id: Scalars['Int'];
}>;

export type TournamentMatchEventQuery = { __typename?: 'Query' } & {
  tournamentMatchEvent: { __typename?: 'TournamentMatchEvent' } & TournamentMatchEventFragment;
};

export type TournamentMatchLineUpsQueryVariables = Exact<{
  query: DefaultQueryInput;
}>;

export type TournamentMatchLineUpsQuery = { __typename?: 'Query' } & {
  tournamentMatchLineUps: Array<{ __typename?: 'TournamentMatchLineUp' } & TournamentMatchLineUpFragment>;
};

export type TournamentMatchProtocolQueryVariables = Exact<{
  id: Scalars['Int'];
}>;

export type TournamentMatchProtocolQuery = { __typename?: 'Query' } & {
  tournamentMatchProtocol: { __typename?: 'TournamentMatchProtocol' } & TournamentMatchProtocolFragment;
};

export type TournamentPlayerStatQueryVariables = Exact<{
  id: Scalars['Int'];
}>;

export type TournamentPlayerStatQuery = { __typename?: 'Query' } & {
  tournamentPlayerStat: { __typename?: 'TournamentPlayerStat' } & TournamentPlayerStatFullFragment;
};

export type TournamentSeasonQueryVariables = Exact<{
  id: Scalars['Int'];
}>;

export type TournamentSeasonQuery = { __typename?: 'Query' } & {
  tournamentSeason: { __typename?: 'TournamentSeason' } & TournamentSeasonFullFragment;
};

export type TournamentSeasonTeamQueryVariables = Exact<{
  id: Scalars['Int'];
}>;

export type TournamentSeasonTeamQuery = { __typename?: 'Query' } & {
  tournamentSeasonTeam: { __typename?: 'TournamentSeasonTeam' } & TournamentSeasonTeamFragment;
};

export type TournamentSeasonTeamStatQueryVariables = Exact<{
  id: Scalars['Int'];
}>;

export type TournamentSeasonTeamStatQuery = { __typename?: 'Query' } & {
  tournamentSeasonTeamStat: { __typename?: 'TournamentSeasonTeamStat' } & TournamentSeasonTeamStatFragment;
};

export type TournamentTeamQueryVariables = Exact<{
  id: Scalars['Int'];
}>;

export type TournamentTeamQuery = { __typename?: 'Query' } & {
  tournamentTeam: { __typename?: 'TournamentTeam' } & TournamentTeamDetailsFragment;
};

export type TournamentTeamMemberQueryVariables = Exact<{
  id: Scalars['Int'];
}>;

export type TournamentTeamMemberQuery = { __typename?: 'Query' } & {
  tournamentTeamMember: { __typename?: 'TournamentTeamMember' } & TournamentTeamMemberFragment;
};

export type TournamentTourQueryVariables = Exact<{
  id: Scalars['Int'];
}>;

export type TournamentTourQuery = { __typename?: 'Query' } & {
  tournamentTour: { __typename?: 'TournamentTour' } & TournamentTourFragment;
};

export type UserQueryVariables = Exact<{
  id: Scalars['Int'];
}>;

export type UserQuery = { __typename?: 'Query' } & { user: { __typename?: 'User' } & UserFragment };

export type UserGroupQueryVariables = Exact<{
  id: Scalars['Int'];
}>;

export type UserGroupQuery = { __typename?: 'Query' } & { userGroup: { __typename?: 'UserGroup' } & UserGroupFragment };

export type UserProfileQueryVariables = Exact<{ [key: string]: never }>;

export type UserProfileQuery = { __typename?: 'Query' } & { userProfile: { __typename?: 'User' } & UserFragment };

export type UserProfileGroupQueryVariables = Exact<{ [key: string]: never }>;

export type UserProfileGroupQuery = { __typename?: 'Query' } & {
  userProfile: { __typename?: 'User' } & { groups?: Maybe<Array<{ __typename?: 'UserGroup' } & UserGroupMinFragment>> };
};

export type UserSubscriberQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type UserSubscriberQuery = { __typename?: 'Query' } & {
  userSubscriber: { __typename?: 'UserSubscriber' } & UserSubscriberFragment;
};

export type UserSubscriptionQueryVariables = Exact<{
  id: Scalars['Int'];
}>;

export type UserSubscriptionQuery = { __typename?: 'Query' } & {
  userSubscription: { __typename?: 'UserSubscription' } & UserSubscriptionFragment;
};

export type WebTranslationsUpdatedAtQueryVariables = Exact<{
  locale: Scalars['String'];
}>;

export type WebTranslationsUpdatedAtQuery = { __typename?: 'Query' } & Pick<Query, 'webTranslationsUpdatedAt'>;

export type WidgetsQueryVariables = Exact<{ [key: string]: never }>;

export type WidgetsQuery = { __typename?: 'Query' } & {
  widgets?: Maybe<
    { __typename?: 'WidgetsResponse' } & {
      commerceWidget: { __typename?: 'CommerceWidget' } & {
        shops: Array<
          { __typename?: 'CommerceShopInfo' } & Pick<
            CommerceShopInfo,
            | 'id'
            | 'clientId'
            | 'state'
            | 'name'
            | 'description'
            | 'codeMask'
            | 'catalogueId'
            | 'currencyId'
            | 'saleEnabled'
            | 'createdAt'
            | 'updatedAt'
            | 'items'
            | 'orders'
          >
        >;
      };
    }
  >;
};

export const TournamentMatchFlatFragmentDoc = gql`
  fragment tournamentMatchFlat on TournamentMatch {
    id
    team1Id
    team2Id
    result
    team1IdGoals
    team2IdGoals
    state
    stadiumName
    stadiumAddress
    startDate
    startTime
    finishDate
    startedAt
    finishedAt
  }
`;
export const TournamentTeamSmallFragmentDoc = gql`
  fragment tournamentTeamSmall on TournamentTeam {
    id
    name
    logo
    website
  }
`;
export const TournamentMatchFragmentDoc = gql`
  fragment tournamentMatch on TournamentMatch {
    ...tournamentMatchFlat
    team1 {
      ...tournamentTeamSmall
    }
    team2 {
      ...tournamentTeamSmall
    }
  }
  ${TournamentMatchFlatFragmentDoc}
  ${TournamentTeamSmallFragmentDoc}
`;
export const TournamentTeamMemberFragmentDoc = gql`
  fragment tournamentTeamMember on TournamentTeamMember {
    id
    type
    teamId
    firstName
    lastName
    middleName
    birthday
    weight
    height
    number
    role
    bio
    photo
    createdAt
    updatedAt
  }
`;
export const TournamentTeamFragmentDoc = gql`
  fragment tournamentTeam on TournamentTeam {
    id
    clientId
    name
    logo
    altLogo
    coverPhoto
    website
    description
    history
    foundingDate
    teamForm
    createdAt
    updatedAt
  }
`;
export const TournamentMatchProtocolDetailsFragmentDoc = gql`
  fragment tournamentMatchProtocolDetails on TournamentMatchProtocol {
    id
    matchId
    minute
    event
    teamMemberId
    teamId
    match {
      ...tournamentMatch
    }
    player {
      ...tournamentTeamMember
    }
    team {
      ...tournamentTeam
    }
    createdAt
    updatedAt
  }
  ${TournamentMatchFragmentDoc}
  ${TournamentTeamMemberFragmentDoc}
  ${TournamentTeamFragmentDoc}
`;
export const TournamentMatchProtocolFragmentDoc = gql`
  fragment tournamentMatchProtocol on TournamentMatchProtocol {
    id
    matchId
    minute
    event
    teamMemberId
    teamId
    createdAt
    updatedAt
  }
`;
export const TournamentMatchProtocolConnectionFragmentDoc = gql`
  fragment tournamentMatchProtocolConnection on TournamentMatchProtocolConnection {
    total
    limit
    cursor
    sortable
    edges {
      ...tournamentMatchProtocol
    }
  }
  ${TournamentMatchProtocolFragmentDoc}
`;
export const SystemLocaleFragmentDoc = gql`
  fragment systemLocale on SystemLocale {
    id
    iso
    title
    createdAt
    updatedAt
  }
`;
export const ClientFragmentDoc = gql`
  fragment client on Client {
    id
    title
    role
    type
    logo
    website
    colors
    domain
    createdAt
    updatedAt
  }
`;
export const AclFragmentDoc = gql`
  fragment acl on ACL {
    id
    parentId
    name
    createdAt
    updatedAt
  }
`;
export const SystemUserFragmentDoc = gql`
  fragment systemUser on SystemUser {
    id
    state
    email
    username
    phone
    firstName
    lastName
    middleName
    photo
    createdAt
    updatedAt
    locale
    clients {
      ...client
    }
    ACL {
      ...acl
    }
  }
  ${ClientFragmentDoc}
  ${AclFragmentDoc}
`;
export const ArticleAuthorFragmentDoc = gql`
  fragment articleAuthor on ArticleAuthor {
    id
    articleId
    userId
    user {
      ...systemUser
    }
    role
    createdAt
    updatedAt
  }
  ${SystemUserFragmentDoc}
`;
export const ArticleFragmentDoc = gql`
  fragment article on Article {
    id
    clientId
    categoryId
    state
    tags
    localeId
    locale {
      ...systemLocale
    }
    createdAt
    publishedAt
    title
    authors {
      ...articleAuthor
    }
  }
  ${SystemLocaleFragmentDoc}
  ${ArticleAuthorFragmentDoc}
`;
export const ArticleCategoryFragmentDoc = gql`
  fragment articleCategory on ArticleCategory {
    id
    clientId
    name
    parentId
    createdAt
  }
`;
export const ArticleBlockFragmentDoc = gql`
  fragment articleBlock on ArticleBlock {
    id
    articleId
    authorId
    name
    type
    data
    isPreview
    sort
    createdAt
    updatedAt
  }
`;
export const ArticleLinkFragmentDoc = gql`
  fragment articleLink on ArticleLink {
    articleId
    entityId
    entityType
  }
`;
export const ArticleLogFragmentDoc = gql`
  fragment articleLog on ArticleLog {
    articleId
    authorId
    data
    state
    createdAt
  }
`;
export const ArticleFullFragmentDoc = gql`
  fragment articleFull on Article {
    ...article
    category {
      ...articleCategory
    }
    blocks {
      ...articleBlock
    }
    preview {
      ...articleBlock
    }
    authors {
      ...articleAuthor
    }
    links {
      ...articleLink
    }
    log {
      ...articleLog
    }
  }
  ${ArticleFragmentDoc}
  ${ArticleCategoryFragmentDoc}
  ${ArticleBlockFragmentDoc}
  ${ArticleAuthorFragmentDoc}
  ${ArticleLinkFragmentDoc}
  ${ArticleLogFragmentDoc}
`;
export const ArticleAuthorFullFragmentDoc = gql`
  fragment articleAuthorFull on ArticleAuthor {
    userId
    role
    user {
      ...systemUser
    }
  }
  ${SystemUserFragmentDoc}
`;
export const ArticleAuthorConnectionFragmentDoc = gql`
  fragment articleAuthorConnection on ArticleAuthorConnection {
    total
    limit
    cursor
    sortable
    edges {
      ...articleAuthor
    }
  }
  ${ArticleAuthorFragmentDoc}
`;
export const ArticleBlockAuthorFragmentDoc = gql`
  fragment articleBlockAuthor on ArticleBlockAuthor {
    blockId
    author {
      ...systemUser
    }
  }
  ${SystemUserFragmentDoc}
`;
export const ArticleBlockAuthorsConnectionFragmentDoc = gql`
  fragment articleBlockAuthorsConnection on ArticleBlockAuthorsConnection {
    total
    limit
    cursor
    sortable
    edges {
      ...articleBlockAuthor
    }
  }
  ${ArticleBlockAuthorFragmentDoc}
`;
export const ArticleBlockConnectionFragmentDoc = gql`
  fragment articleBlockConnection on ArticleBlockConnection {
    total
    limit
    cursor
    sortable
    edges {
      ...articleBlock
    }
  }
  ${ArticleBlockFragmentDoc}
`;
export const ArticleCategoryConnectionFragmentDoc = gql`
  fragment articleCategoryConnection on ArticleCategoryConnection {
    total
    limit
    cursor
    sortable
    edges {
      ...articleCategory
    }
  }
  ${ArticleCategoryFragmentDoc}
`;
export const ArticlesConnectionFragmentDoc = gql`
  fragment articlesConnection on ArticlesConnection {
    total
    limit
    cursor
    sortable
    edges {
      ...article
    }
  }
  ${ArticleFragmentDoc}
`;
export const ArticleLinkFullFragmentDoc = gql`
  fragment articleLinkFull on ArticleLink {
    articleId
    entityId
    entityType
  }
`;
export const ArticleLinkConnectionFragmentDoc = gql`
  fragment articleLinkConnection on ArticleLinkConnection {
    total
    limit
    cursor
    sortable
    edges {
      ...articleLink
    }
  }
  ${ArticleLinkFragmentDoc}
`;
export const ArticleLinkEntityRecordFragmentDoc = gql`
  fragment articleLinkEntityRecord on ArticleLinkEntityRecord {
    ... on TournamentMatch {
      ...tournamentMatch
    }
    ... on TournamentTour {
      id
    }
    ... on TournamentTeam {
      id
    }
    ... on TournamentMatchEvent {
      id
    }
    ... on TournamentTeamMember {
      id
    }
  }
  ${TournamentMatchFragmentDoc}
`;
export const ArticleLinkEntityTypeConnectionFragmentDoc = gql`
  fragment articleLinkEntityTypeConnection on ArticleLinkEntityTypeConnection {
    total
    limit
    cursor
    sortable
    edges {
      ...articleLinkEntityRecord
    }
  }
  ${ArticleLinkEntityRecordFragmentDoc}
`;
export const ArticleTagFragmentDoc = gql`
  fragment articleTag on ArticleTag {
    id
    createdAt
    updatedAt
  }
`;
export const ArticleTagConnectionFragmentDoc = gql`
  fragment articleTagConnection on ArticleTagConnection {
    total
    limit
    cursor
    sortable
    edges {
      ...articleTag
    }
  }
  ${ArticleTagFragmentDoc}
`;
export const CatalogueCategoryFragmentDoc = gql`
  fragment catalogueCategory on CatalogueCategory {
    id
    name
    catalogueId
    state
    createdAt
    updatedAt
  }
`;
export const CatalogueCategoryConnectionFragmentDoc = gql`
  fragment catalogueCategoryConnection on CatalogueCategoryConnection {
    total
    limit
    cursor
    sortable
    edges {
      ...catalogueCategory
    }
  }
  ${CatalogueCategoryFragmentDoc}
`;
export const CatalogueFlatFragmentDoc = gql`
  fragment catalogueFlat on Catalogue {
    id
    clientId
    state
    name
    description
    localeIdx
    preview
    currencyIdx
    createdAt
    updatedAt
  }
`;
export const LocaleFragmentDoc = gql`
  fragment locale on Locale {
    id
    iso
    title
    createdAt
    updatedAt
  }
`;
export const CurrencyFragmentDoc = gql`
  fragment currency on Currency {
    id
    iso3
    name
    sign
    isDefault
    isVirtual
    createdAt
    updatedAt
  }
`;
export const CatalogueFragmentDoc = gql`
  fragment catalogue on Catalogue {
    ...catalogueFlat
    locales {
      ...locale
    }
    currencies {
      ...currency
    }
  }
  ${CatalogueFlatFragmentDoc}
  ${LocaleFragmentDoc}
  ${CurrencyFragmentDoc}
`;
export const CatalogueConnectionFragmentDoc = gql`
  fragment catalogueConnection on CatalogueConnection {
    total
    limit
    cursor
    sortable
    edges {
      ...catalogue
    }
  }
  ${CatalogueFragmentDoc}
`;
export const CatalogueItemFlatFragmentDoc = gql`
  fragment catalogueItemFlat on CatalogueItem {
    id
    catalogueId
    name
    preview
    state
    createdAt
    updatedAt
    parentId
  }
`;
export const CatalogueItemPriceFragmentDoc = gql`
  fragment catalogueItemPrice on CatalogueItemPrice {
    id
    currencyId
    value
    currency {
      ...currency
    }
  }
  ${CurrencyFragmentDoc}
`;
export const CatalogueItemInCollectionFragmentDoc = gql`
  fragment catalogueItemInCollection on CatalogueItem {
    ...catalogueItemFlat
    prices {
      ...catalogueItemPrice
    }
  }
  ${CatalogueItemFlatFragmentDoc}
  ${CatalogueItemPriceFragmentDoc}
`;
export const CatalogueItemConnectionFragmentDoc = gql`
  fragment catalogueItemConnection on CatalogueItemConnection {
    total
    limit
    cursor
    sortable
    edges {
      ...catalogueItemInCollection
    }
  }
  ${CatalogueItemInCollectionFragmentDoc}
`;
export const ExtensionFieldTranslationFragmentDoc = gql`
  fragment extensionFieldTranslation on ExtensionFieldTranslation {
    id
    localeId
    fieldId
    value
    createdAt
    updatedAt
  }
`;
export const ExtensionFieldFragmentDoc = gql`
  fragment extensionField on ExtensionField {
    id
    relationId
    name
    dataType
    isReadOnly
    sort
    translations {
      ...extensionFieldTranslation
    }
    createdAt
    updatedAt
  }
  ${ExtensionFieldTranslationFragmentDoc}
`;
export const CatalogueItemFieldsConnectionFragmentDoc = gql`
  fragment catalogueItemFieldsConnection on CatalogueItemFieldsConnection {
    total
    limit
    cursor
    sortable
    edges {
      ...extensionField
    }
  }
  ${ExtensionFieldFragmentDoc}
`;
export const CatalogueItemPriceConnectionFragmentDoc = gql`
  fragment catalogueItemPriceConnection on CatalogueItemPriceConnection {
    total
    limit
    cursor
    sortable
    edges {
      ...catalogueItemPrice
    }
  }
  ${CatalogueItemPriceFragmentDoc}
`;
export const ExtensionDataFragmentDoc = gql`
  fragment extensionData on ExtensionData {
    id
    localeId
    value
    key
    field {
      ...extensionField
    }
    createdAt
    updatedAt
  }
  ${ExtensionFieldFragmentDoc}
`;
export const CatalogueItemFragmentDoc = gql`
  fragment catalogueItem on CatalogueItem {
    ...catalogueItemFlat
    data {
      ...extensionData
    }
  }
  ${CatalogueItemFlatFragmentDoc}
  ${ExtensionDataFragmentDoc}
`;
export const CatalogueSummaryFragmentDoc = gql`
  fragment catalogueSummary on CatalogueSummary {
    itemsCount
    topItems {
      ...catalogueItem
    }
  }
  ${CatalogueItemFragmentDoc}
`;
export const CatalogueSummaryWidgetFragmentDoc = gql`
  fragment catalogueSummaryWidget on CatalogueSummaryWidget {
    catalogue {
      ...catalogue
    }
    summary {
      ...catalogueSummary
    }
  }
  ${CatalogueFragmentDoc}
  ${CatalogueSummaryFragmentDoc}
`;
export const CateringFlatFragmentDoc = gql`
  fragment cateringFlat on Catering {
    id
    clientId
    eventPlaceId
    commerceShopId
    state
    printReceiptTemplateId
    name
    sectors
    createdAt
    updatedAt
  }
`;
export const EventPlaceFragmentDoc = gql`
  fragment eventPlace on EventPlace {
    id
    clientId
    name
    address
    createdAt
    updatedAt
    locales {
      ...systemLocale
    }
  }
  ${SystemLocaleFragmentDoc}
`;
export const CommerceShopFlatFragmentDoc = gql`
  fragment commerceShopFlat on CommerceShop {
    id
    clientId
    state
    name
    description
    codeMask
    catalogueId
    currencyId
    createdAt
    updatedAt
    settings
  }
`;
export const CateringFragmentDoc = gql`
  fragment catering on Catering {
    ...cateringFlat
    eventPlace {
      ...eventPlace
    }
    commerceShop {
      ...commerceShopFlat
    }
  }
  ${CateringFlatFragmentDoc}
  ${EventPlaceFragmentDoc}
  ${CommerceShopFlatFragmentDoc}
`;
export const CateringConnectionFragmentDoc = gql`
  fragment cateringConnection on CateringConnection {
    total
    limit
    cursor
    sortable
    edges {
      ...catering
    }
  }
  ${CateringFragmentDoc}
`;
export const ClientPublicSettingsFragmentDoc = gql`
  fragment clientPublicSettings on ClientPublicSettings {
    name
    logo
    website
    colors
    defaultLocale
    domain
  }
`;
export const ClientPublicProfileFragmentDoc = gql`
  fragment clientPublicProfile on ClientPublicProfile {
    id
    title
    settings {
      ...clientPublicSettings
    }
  }
  ${ClientPublicSettingsFragmentDoc}
`;
export const CommerceOrderUpdateFragmentDoc = gql`
  fragment commerceOrderUpdate on CommerceOrder {
    id
    shopId
    clientId
    state
    paymentState
    userId
    externalEntityId
    code
    total
    paidAmount
    createdAt
    updatedAt
  }
`;
export const UserFlatFragmentDoc = gql`
  fragment userFlat on User {
    id
    uuid
    state
    username
    firstName
    lastName
    middleName
    birthday
    email
    phone
    gender
    isVerifiedEmail
    isVerifiedPhone
    source
    createdAt
    updatedAt
    loyaltyCatalogueAffordableItemsCount
    isProfileComplete
  }
`;
export const CommerceOrderItemFlatFragmentDoc = gql`
  fragment commerceOrderItemFlat on CommerceOrderItem {
    id
    orderId
    state
    catalogueItemId
    discountId
    currencyId
    qty
    price
    discountedPrice
    total
    createdAt
    updatedAt
  }
`;
export const CommerceOrderItemFragmentDoc = gql`
  fragment commerceOrderItem on CommerceOrderItem {
    ...commerceOrderItemFlat
    catalogueItem {
      ...catalogueItemFlat
    }
  }
  ${CommerceOrderItemFlatFragmentDoc}
  ${CatalogueItemFlatFragmentDoc}
`;
export const CommerceOrderFragmentDoc = gql`
  fragment commerceOrder on CommerceOrder {
    id
    shopId
    clientId
    state
    paymentState
    user {
      ...userFlat
    }
    data {
      key
      value
    }
    items {
      ...commerceOrderItem
    }
    userId
    externalEntityId
    code
    total
    paidAmount
    createdAt
    updatedAt
  }
  ${UserFlatFragmentDoc}
  ${CommerceOrderItemFragmentDoc}
`;
export const CommerceOrderConnectionFragmentDoc = gql`
  fragment commerceOrderConnection on CommerceOrderConnection {
    total
    limit
    cursor
    sortable
    edges {
      ...commerceOrder
    }
  }
  ${CommerceOrderFragmentDoc}
`;
export const CommerceOrderFieldFragmentDoc = gql`
  fragment commerceOrderField on CommerceOrderField {
    id
    shopId
    name
    dataType
    hasLocale
    sort
    createdAt
    updatedAt
  }
`;
export const CommerceOrderDataFragmentDoc = gql`
  fragment commerceOrderData on CommerceOrderData {
    id
    value
    orderFieldId
    field {
      ...commerceOrderField
    }
    key
    createdAt
    updatedAt
  }
  ${CommerceOrderFieldFragmentDoc}
`;
export const CommerceOrderDataConnectionFragmentDoc = gql`
  fragment commerceOrderDataConnection on CommerceOrderDataConnection {
    total
    limit
    cursor
    sortable
    edges {
      ...commerceOrderData
    }
  }
  ${CommerceOrderDataFragmentDoc}
`;
export const CommerceOrderFieldConnectionFragmentDoc = gql`
  fragment commerceOrderFieldConnection on CommerceOrderFieldConnection {
    total
    limit
    cursor
    sortable
    edges {
      ...commerceOrderField
    }
  }
  ${CommerceOrderFieldFragmentDoc}
`;
export const CommerceOrderFieldTranslationFragmentDoc = gql`
  fragment commerceOrderFieldTranslation on CommerceOrderFieldTranslation {
    id
    localeId
    value
    createdAt
    updatedAt
  }
`;
export const CommerceOrderItemConnectionFragmentDoc = gql`
  fragment commerceOrderItemConnection on CommerceOrderItemConnection {
    total
    limit
    cursor
    sortable
    edges {
      ...commerceOrderItem
    }
  }
  ${CommerceOrderItemFragmentDoc}
`;
export const CommerceOrderStatesWidgetDataFragmentDoc = gql`
  fragment commerceOrderStatesWidgetData on CommerceOrderStatesWidgetData {
    state
    count
    total
  }
`;
export const CommerceShopFragmentDoc = gql`
  fragment commerceShop on CommerceShop {
    ...commerceShopFlat
    currency {
      ...currency
    }
    catalogue {
      ...catalogueFlat
    }
  }
  ${CommerceShopFlatFragmentDoc}
  ${CurrencyFragmentDoc}
  ${CatalogueFlatFragmentDoc}
`;
export const CommerceShopConnectionFragmentDoc = gql`
  fragment commerceShopConnection on CommerceShopConnection {
    total
    limit
    cursor
    sortable
    edges {
      ...commerceShop
    }
  }
  ${CommerceShopFragmentDoc}
`;
export const CommerceShopSummaryFragmentDoc = gql`
  fragment commerceShopSummary on CommerceShopSummary {
    ordersCount
    itemsCount
    topItems {
      ...catalogueItem
    }
  }
  ${CatalogueItemFragmentDoc}
`;
export const CommerceShopGraphFragmentDoc = gql`
  fragment commerceShopGraph on CommerceShopGraph {
    name
    data {
      date
      total
      paidAmount
    }
  }
`;
export const CommerceSummaryWidgetFragmentDoc = gql`
  fragment commerceSummaryWidget on CommerceSummaryWidget {
    shop {
      ...commerceShop
    }
    summary {
      ...commerceShopSummary
    }
    graphs {
      ...commerceShopGraph
    }
  }
  ${CommerceShopFragmentDoc}
  ${CommerceShopSummaryFragmentDoc}
  ${CommerceShopGraphFragmentDoc}
`;
export const CommerceTemplateFragmentDoc = gql`
  fragment CommerceTemplate on CommerceTemplate {
    id
    clientId
    name
    preview
    body
    createdAt
    updatedAt
  }
`;
export const CommerceTemplateConnectionFragmentDoc = gql`
  fragment CommerceTemplateConnection on CommerceTemplateConnection {
    total
    limit
    cursor
    sortable
    edges {
      ...CommerceTemplate
    }
  }
  ${CommerceTemplateFragmentDoc}
`;
export const CommonGraphDatasetDataFragmentDoc = gql`
  fragment commonGraphDatasetData on CommonGraphDatasetData {
    x
    y
  }
`;
export const CommonGraphDatasetFragmentDoc = gql`
  fragment commonGraphDataset on CommonGraphDataset {
    label
    data {
      ...commonGraphDatasetData
    }
  }
  ${CommonGraphDatasetDataFragmentDoc}
`;
export const CommonGraphFragmentDoc = gql`
  fragment commonGraph on CommonGraph {
    name
    dataset {
      ...commonGraphDataset
    }
  }
  ${CommonGraphDatasetFragmentDoc}
`;
export const ContentTranslationFragmentDoc = gql`
  fragment contentTranslation on ContentTranslation {
    id
    clientId
    channel
    localeId
    key
    value
    createdAt
    updatedAt
  }
`;
export const ContentTranslationConnectionFragmentDoc = gql`
  fragment contentTranslationConnection on ContentTranslationConnection {
    total
    limit
    cursor
    sortable
    edges {
      ...contentTranslation
    }
  }
  ${ContentTranslationFragmentDoc}
`;
export const DisputeFlatFragmentDoc = gql`
  fragment disputeFlat on Dispute {
    id
    state
    result
    confirmation
    userId
    matchId
    amount
    winAmount
    text
    createdAt
    updatedAt
  }
`;
export const DisputeFragmentDoc = gql`
  fragment dispute on Dispute {
    ...disputeFlat
    user {
      ...userFlat
    }
    match {
      ...tournamentMatch
    }
  }
  ${DisputeFlatFragmentDoc}
  ${UserFlatFragmentDoc}
  ${TournamentMatchFragmentDoc}
`;
export const DisputeConnectionFragmentDoc = gql`
  fragment disputeConnection on DisputeConnection {
    total
    limit
    cursor
    sortable
    searchable
    edges {
      ...dispute
    }
  }
  ${DisputeFragmentDoc}
`;
export const DisputeDisputeResponseFlatFragmentDoc = gql`
  fragment disputeDisputeResponseFlat on DisputeDisputeResponse {
    id
    clientId
    disputeId
    state
    userId
    amount
    comment
    respondedAt
    createdAt
    updatedAt
  }
`;
export const DisputeDisputeResponseFragmentDoc = gql`
  fragment disputeDisputeResponse on DisputeDisputeResponse {
    ...disputeDisputeResponseFlat
    user {
      ...userFlat
    }
  }
  ${DisputeDisputeResponseFlatFragmentDoc}
  ${UserFlatFragmentDoc}
`;
export const DisputeDisputeResponseConnectionFragmentDoc = gql`
  fragment disputeDisputeResponseConnection on DisputeDisputeResponseConnection {
    total
    limit
    cursor
    sortable
    searchable
    edges {
      ...disputeDisputeResponse
    }
  }
  ${DisputeDisputeResponseFragmentDoc}
`;
export const DisputeSettingsFragmentDoc = gql`
  fragment disputeSettings on DisputeSettings {
    id
    clientId
    pushTemplates
    simulators
    transportId
    createdAt
    updatedAt
  }
`;
export const EventFlatFragmentDoc = gql`
  fragment eventFlat on Event {
    id
    image
    state
    title
    eventTypeId
    eventPlaceId
    description
    saleStartDate
    saleFinishDate
    startDate
    finishDate
    availableSlots
    saleLimit
    parkings
    isFanIdRequired
  }
`;
export const EventTypeFragmentDoc = gql`
  fragment eventType on EventType {
    id
    name
    description
    image
    templates
  }
`;
export const EventWidgetFragmentDoc = gql`
  fragment eventWidget on Event {
    ...eventFlat
    eventType {
      ...eventType
    }
    tournamentMatch {
      ...tournamentMatch
    }
  }
  ${EventFlatFragmentDoc}
  ${EventTypeFragmentDoc}
  ${TournamentMatchFragmentDoc}
`;
export const EventCalendarFragmentFragmentDoc = gql`
  fragment eventCalendarFragment on Event {
    id
    state
    isFanIdRequired
    saleStartDate
    saleFinishDate
    startDate
  }
`;
export const EventPriceFragmentDoc = gql`
  fragment eventPrice on EventPrice {
    value
    discountValue
  }
`;
export const LoyaltyDiscountFragmentDoc = gql`
  fragment loyaltyDiscount on LoyaltyDiscount {
    order {
      id
    }
    discountPercent
  }
`;
export const LoyaltyPromoFragmentDoc = gql`
  fragment loyaltyPromo on LoyaltyPromo {
    id
    clientId
    name
    description
    imageUri
    state
    amount
    currencyId
    code
    codeType
    codesCount
    startDate
    finishDate
    promoCodeStartDate
    promoCodeFinishDate
    discountMaxAmount
    discountPercent
    createdAt
    updatedAt
  }
`;
export const EventFullFragmentDoc = gql`
  fragment eventFull on Event {
    ...eventFlat
    price {
      ...eventPrice
    }
    eventType {
      ...eventType
    }
    tournamentMatch {
      ...tournamentMatch
    }
    loyaltyDiscount {
      ...loyaltyDiscount
    }
    promo {
      ...loyaltyPromo
    }
  }
  ${EventFlatFragmentDoc}
  ${EventPriceFragmentDoc}
  ${EventTypeFragmentDoc}
  ${TournamentMatchFragmentDoc}
  ${LoyaltyDiscountFragmentDoc}
  ${LoyaltyPromoFragmentDoc}
`;
export const EventConnectionFragmentDoc = gql`
  fragment eventConnection on EventConnection {
    total
    limit
    cursor
    sortable
    edges {
      ...eventFull
    }
  }
  ${EventFullFragmentDoc}
`;
export const EventEventAccessFragmentDoc = gql`
  fragment eventEventAccess on EventEventAccess {
    id
    eventId
    isVisibleToPublic
    userGroupToSector
    createdAt
    updatedAt
  }
`;
export const EventEventAccessConnectionFragmentDoc = gql`
  fragment eventEventAccessConnection on EventEventAccessConnection {
    total
    limit
    cursor
    sortable
    searchable
    edges {
      ...eventEventAccess
    }
  }
  ${EventEventAccessFragmentDoc}
`;
export const EventFieldsConnectionFragmentDoc = gql`
  fragment eventFieldsConnection on EventFieldsConnection {
    total
    limit
    cursor
    sortable
    edges {
      ...extensionField
    }
  }
  ${ExtensionFieldFragmentDoc}
`;
export const EventOrderFlatFragmentDoc = gql`
  fragment eventOrderFlat on EventOrder {
    id
    state
    paymentState
    discountId
    userId
    code
    total
    paidAmount
    reservedTill
    isFiscal
    meta
    createdAt
    eventId
  }
`;
export const EventOrderFragmentDoc = gql`
  fragment eventOrder on EventOrder {
    ...eventOrderFlat
    event {
      ...eventFull
    }
    tickets {
      id
      sector
      row
      seat
      eventOrderId
      eventId
      barcode
      price
      quantity
      pathToPDF
      fanCode
      data {
        id
        key
        value
      }
    }
  }
  ${EventOrderFlatFragmentDoc}
  ${EventFullFragmentDoc}
`;
export const EventOrderConnectionFragmentDoc = gql`
  fragment eventOrderConnection on EventOrderConnection {
    total
    limit
    cursor
    sortable
    edges {
      ...eventOrder
    }
  }
  ${EventOrderFragmentDoc}
`;
export const EventOrderMinFragmentDoc = gql`
  fragment eventOrderMin on EventOrder {
    id
    tickets {
      id
    }
  }
`;
export const EventOrderConnectionMinFragmentDoc = gql`
  fragment eventOrderConnectionMin on EventOrderConnection {
    total
    limit
    cursor
    sortable
    edges {
      ...eventOrderMin
    }
  }
  ${EventOrderMinFragmentDoc}
`;
export const EventOrderTicketFlatFragmentDoc = gql`
  fragment eventOrderTicketFlat on EventOrderTicket {
    id
    eventOrderId
    eventId
    barcode
    price
    quantity
    discountId
    createdAt
    updatedAt
  }
`;
export const EventOrderTicketWithDataFragmentDoc = gql`
  fragment eventOrderTicketWithData on EventOrderTicket {
    ...eventOrderTicketFlat
    data {
      ...extensionData
    }
  }
  ${EventOrderTicketFlatFragmentDoc}
  ${ExtensionDataFragmentDoc}
`;
export const EventOrderTicketFullFragmentDoc = gql`
  fragment eventOrderTicketFull on EventOrderTicket {
    ...eventOrderTicketFlat
    data {
      ...extensionData
    }
    eventOrder {
      ...eventOrder
      user {
        ...userFlat
      }
    }
  }
  ${EventOrderTicketFlatFragmentDoc}
  ${ExtensionDataFragmentDoc}
  ${EventOrderFragmentDoc}
  ${UserFlatFragmentDoc}
`;
export const EventOrderTicketConnectionFragmentDoc = gql`
  fragment eventOrderTicketConnection on EventOrderTicketConnection {
    total
    limit
    cursor
    sortable
    edges {
      ...eventOrderTicketFull
    }
  }
  ${EventOrderTicketFullFragmentDoc}
`;
export const EventPlaceConnectionFragmentDoc = gql`
  fragment eventPlaceConnection on EventPlaceConnection {
    total
    limit
    cursor
    sortable
    edges {
      ...eventPlace
    }
  }
  ${EventPlaceFragmentDoc}
`;
export const EventReserveSeatFragmentDoc = gql`
  fragment eventReserveSeat on EventReserveSeat {
    seatId
    sector
    row
    seat
    price
  }
`;
export const EventReserveFragmentDoc = gql`
  fragment eventReserve on EventReserve {
    id
    uuid
    eventId
    userId
    expiryDate
    meta
    createdAt
    seats {
      ...eventReserveSeat
    }
  }
  ${EventReserveSeatFragmentDoc}
`;
export const EventReserveConnectionFragmentDoc = gql`
  fragment eventReserveConnection on EventReserveConnection {
    total
    limit
    cursor
    sortable
    edges {
      ...eventReserve
    }
  }
  ${EventReserveFragmentDoc}
`;
export const EventSectorPriceFragmentDoc = gql`
  fragment eventSectorPrice on EventSectorPrice {
    priceCategoryId
    priceMin
    priceMax
    priceDiscount
  }
`;
export const EventSectorFragmentDoc = gql`
  fragment eventSector on EventSector {
    key
    name
    quantity
    category
    isSectorSaleEnabled
    discountAvailableSlots
    discountSectorPrice {
      ...eventSectorPrice
    }
    eventSectorPrice {
      ...eventSectorPrice
    }
    loyaltyDiscount {
      ...loyaltyDiscount
    }
    sectorId
    rotation
  }
  ${EventSectorPriceFragmentDoc}
  ${LoyaltyDiscountFragmentDoc}
`;
export const EventSectorSeatFragmentDoc = gql`
  fragment eventSectorSeat on EventSectorSeat {
    seatId
    name
    row
    seat
    price
    isReserved
  }
`;
export const EventSectorRowFragmentDoc = gql`
  fragment eventSectorRow on EventSectorRow {
    row
    seats {
      ...eventSectorSeat
    }
  }
  ${EventSectorSeatFragmentDoc}
`;
export const EventTypeConnectionFragmentDoc = gql`
  fragment eventTypeConnection on EventTypeConnection {
    total
    limit
    cursor
    sortable
    edges {
      ...eventType
    }
  }
  ${EventTypeFragmentDoc}
`;
export const EventTypesConfigFragmentDoc = gql`
  fragment eventTypesConfig on EventTypesConfig {
    abonement {
      ...eventType
    }
    parking {
      ...eventType
    }
    tour {
      ...eventType
    }
    match {
      ...eventType
    }
    concert {
      ...eventType
    }
  }
  ${EventTypeFragmentDoc}
`;
export const EventWidgetActiveEventsFragmentDoc = gql`
  fragment eventWidgetActiveEvents on EventWidgetActiveEvents {
    activeCount
    todayCount
  }
`;
export const EventWidgetTicketSalesTotalsFragmentDoc = gql`
  fragment eventWidgetTicketSalesTotals on EventWidgetTicketSalesTotals {
    totalCount
    soldCount
    availableCount
    soldAmount
  }
`;
export const FilterItemFragmentDoc = gql`
  fragment filterItem on FilterItem {
    __typename @skip(if: true)
    field
    type
    dataType
    options {
      __typename @skip(if: true)
      id
      name
    }
    isRangeAvailable
    isExcludAvailable
    min
    max
    step
  }
`;
export const FileFragmentDoc = gql`
  fragment file on File {
    url
    file
  }
`;
export const FoodOrderItemFragmentDoc = gql`
  fragment foodOrderItem on FoodOrderItem {
    id
    state
    name
    preview
    eventId
    quantity
    price
    currencyId
    total
    description
    categoryId
    images {
      ...file
    }
    createdAt
    updatedAt
  }
  ${FileFragmentDoc}
`;
export const LoyaltyItemFragmentDoc = gql`
  fragment loyaltyItem on LoyaltyItem {
    id
    state
    name
    preview
    eventId
    price {
      ...catalogueItemPrice
    }
    title
    description
    information
    popularity
    images {
      ...file
    }
  }
  ${CatalogueItemPriceFragmentDoc}
  ${FileFragmentDoc}
`;
export const LoyaltyItemConnectionFragmentDoc = gql`
  fragment loyaltyItemConnection on LoyaltyItemConnection {
    total
    limit
    cursor
    sortable
    edges {
      ...loyaltyItem
    }
  }
  ${LoyaltyItemFragmentDoc}
`;
export const LoyaltyOrderFlatFragmentDoc = gql`
  fragment loyaltyOrderFlat on LoyaltyOrder {
    id
    state
    paymentState
    code
    total
    paidAmount
    createdAt
    updatedAt
  }
`;
export const LoyaltyOrderFragmentDoc = gql`
  fragment loyaltyOrder on LoyaltyOrder {
    ...loyaltyOrderFlat
    items {
      ...loyaltyItem
    }
  }
  ${LoyaltyOrderFlatFragmentDoc}
  ${LoyaltyItemFragmentDoc}
`;
export const LoyaltyOrderConnectionFragmentDoc = gql`
  fragment loyaltyOrderConnection on LoyaltyOrderConnection {
    total
    limit
    cursor
    sortable
    edges {
      ...loyaltyOrder
    }
  }
  ${LoyaltyOrderFragmentDoc}
`;
export const LoyaltyPartnerFragmentDoc = gql`
  fragment loyaltyPartner on LoyaltyPartner {
    id
    clientId
    state
    name
    description
    logo
    website
    shopId
    providerId
    createdAt
    updatedAt
  }
`;
export const LoyaltyPartnerConnectionFragmentDoc = gql`
  fragment loyaltyPartnerConnection on LoyaltyPartnerConnection {
    total
    limit
    cursor
    sortable
    edges {
      ...loyaltyPartner
    }
  }
  ${LoyaltyPartnerFragmentDoc}
`;
export const LoyaltyPartnerEntityConnectionFragmentDoc = gql`
  fragment loyaltyPartnerEntityConnection on LoyaltyPartnerEntityConnection {
    total
    limit
    cursor
    sortable
    edges
  }
`;
export const LoyaltyPromoCodeFullFragmentDoc = gql`
  fragment loyaltyPromoCodeFull on LoyaltyPromoCode {
    id
    promoId
    userId
    state
    code
    amount
    amountUsed
    currencyId
    expiredAt
    appliedAt
    promo {
      ...loyaltyPromo
    }
    createdAt
    updatedAt
  }
  ${LoyaltyPromoFragmentDoc}
`;
export const LoyaltyPromoCodeFragmentDoc = gql`
  fragment loyaltyPromoCode on LoyaltyPromoCode {
    id
    promoId
    userId
    state
    code
    amount
    amountUsed
    currencyId
    expiredAt
    appliedAt
    createdAt
    updatedAt
  }
`;
export const LoyaltyPromoCodeConnectionFragmentDoc = gql`
  fragment loyaltyPromoCodeConnection on LoyaltyPromoCodeConnection {
    total
    limit
    cursor
    sortable
    edges {
      ...loyaltyPromoCode
    }
  }
  ${LoyaltyPromoCodeFragmentDoc}
`;
export const LoyaltyPromoConnectionFragmentDoc = gql`
  fragment loyaltyPromoConnection on LoyaltyPromoConnection {
    total
    limit
    cursor
    sortable
    edges {
      ...loyaltyPromo
    }
  }
  ${LoyaltyPromoFragmentDoc}
`;
export const LoyaltyAccountSummaryFragmentDoc = gql`
  fragment loyaltyAccountSummary on LoyaltyAccountSummary {
    applicationsCount
    accountsCount
    unprocessedCount
  }
`;
export const LoyaltySummaryGraphDatasetDataFragmentDoc = gql`
  fragment loyaltySummaryGraphDatasetData on LoyaltySummaryGraphDatasetData {
    x
    y
  }
`;
export const LoyaltySummaryGraphDatasetFragmentDoc = gql`
  fragment loyaltySummaryGraphDataset on LoyaltySummaryGraphDataset {
    label
    data {
      ...loyaltySummaryGraphDatasetData
    }
  }
  ${LoyaltySummaryGraphDatasetDataFragmentDoc}
`;
export const LoyaltySummaryGraphFragmentDoc = gql`
  fragment loyaltySummaryGraph on LoyaltySummaryGraph {
    name
    dataset {
      ...loyaltySummaryGraphDataset
    }
  }
  ${LoyaltySummaryGraphDatasetFragmentDoc}
`;
export const LoyaltySummaryWidgetFragmentDoc = gql`
  fragment loyaltySummaryWidget on LoyaltySummaryWidget {
    partner {
      ...loyaltyPartner
    }
    summary {
      ...loyaltyAccountSummary
    }
    graphs {
      ...loyaltySummaryGraph
    }
  }
  ${LoyaltyPartnerFragmentDoc}
  ${LoyaltyAccountSummaryFragmentDoc}
  ${LoyaltySummaryGraphFragmentDoc}
`;
export const MessengerQueueNotificationStatFragmentDoc = gql`
  fragment messengerQueueNotificationStat on MessengerQueueNotificationStat {
    state
    value
  }
`;
export const MessengerQueueTaskFragmentDoc = gql`
  fragment messengerQueueTask on MessengerQueueTask {
    id
    mailingId
    operation
    state
    itemsToProcess
    itemsProcessed
    itemsInvalid
    createdAt
    updatedAt
  }
`;
export const MessengerMailingFragmentDoc = gql`
  fragment messengerMailing on MessengerMailing {
    id
    clientId
    name
    sendAfter
    sender
    channel
    preview
    transportId
    stats {
      ...messengerQueueNotificationStat
    }
    currentTask {
      ...messengerQueueTask
    }
    state
    subject
    richText
    metaDesign
    text
    templateId
    createdAt
    updatedAt
  }
  ${MessengerQueueNotificationStatFragmentDoc}
  ${MessengerQueueTaskFragmentDoc}
`;
export const MessengerMailingConnectionFragmentDoc = gql`
  fragment messengerMailingConnection on MessengerMailingConnection {
    total
    limit
    cursor
    sortable
    edges {
      ...messengerMailing
    }
  }
  ${MessengerMailingFragmentDoc}
`;
export const MessengerQueueNotificationFragmentDoc = gql`
  fragment messengerQueueNotification on MessengerQueueNotification {
    id
    clientId
    mailingId
    userId
    state
    channel
    willSendAt
    sender
    recipient
    payload
    envelope
    createdAt
    updatedAt
    mailing {
      ...messengerMailing
    }
  }
  ${MessengerMailingFragmentDoc}
`;
export const MessengerQueueNotificationConnectionFragmentDoc = gql`
  fragment messengerQueueNotificationConnection on MessengerQueueNotificationConnection {
    total
    limit
    cursor
    sortable
    searchable
    edges {
      ...messengerQueueNotification
    }
  }
  ${MessengerQueueNotificationFragmentDoc}
`;
export const MessengerQueueTaskConnectionFragmentDoc = gql`
  fragment messengerQueueTaskConnection on MessengerQueueTaskConnection {
    total
    limit
    cursor
    sortable
    edges {
      ...messengerQueueTask
    }
  }
  ${MessengerQueueTaskFragmentDoc}
`;
export const MessengerSettingFragmentDoc = gql`
  fragment messengerSetting on MessengerSetting {
    id
    clientId
    testers
    defaultSender
    createdAt
    updatedAt
    emailDefault
    defaultSubscription
    smsDefault
    pushDefault
  }
`;
export const MessengerTransportFragmentDoc = gql`
  fragment messengerTransport on MessengerTransport {
    id
    clientId
    name
    settings
    providerId
    state
    createdAt
    updatedAt
    channel
  }
`;
export const MessengerTransportConnectionFragmentDoc = gql`
  fragment messengerTransportConnection on MessengerTransportConnection {
    total
    limit
    cursor
    sortable
    edges {
      ...messengerTransport
    }
  }
  ${MessengerTransportFragmentDoc}
`;
export const MobileTranslationFragmentDoc = gql`
  fragment mobileTranslation on MobileTranslation {
    id
    locale
    key
    value
    updatedAt
  }
`;
export const NavigationFragmentDoc = gql`
  fragment navigation on Navigation {
    id
    parentId
    name
    url
    icon
    sort
    children {
      id
      parentId
      name
      url
      icon
      sort
    }
  }
`;
export const PaymentAccountFlatFragmentDoc = gql`
  fragment paymentAccountFlat on PaymentAccount {
    id
    state
    userId
    currencyId
    balance
    spent
    providerId
    providerCode
    createdAt
    updatedAt
  }
`;
export const PaymentCurrencyFragmentDoc = gql`
  fragment paymentCurrency on PaymentCurrency {
    id
    clientId
    iso3
    name
    sign
    isDefault
    isVirtual
    createdAt
    updatedAt
  }
`;
export const PaymentAccountFragmentDoc = gql`
  fragment paymentAccount on PaymentAccount {
    ...paymentAccountFlat
    user {
      ...userFlat
    }
    currency {
      ...paymentCurrency
    }
  }
  ${PaymentAccountFlatFragmentDoc}
  ${UserFlatFragmentDoc}
  ${PaymentCurrencyFragmentDoc}
`;
export const PaymentAccountConnectionFragmentDoc = gql`
  fragment paymentAccountConnection on PaymentAccountConnection {
    total
    limit
    cursor
    sortable
    searchable
    edges {
      ...paymentAccount
    }
  }
  ${PaymentAccountFragmentDoc}
`;
export const PaymentCurrencyConnectionFragmentDoc = gql`
  fragment paymentCurrencyConnection on PaymentCurrencyConnection {
    total
    limit
    cursor
    sortable
    edges {
      ...paymentCurrency
    }
  }
  ${PaymentCurrencyFragmentDoc}
`;
export const PaymentProviderFragmentDoc = gql`
  fragment paymentProvider on PaymentProvider {
    id
    gatewayId
    clientId
    state
    name
    settings
    createdAt
    updatedAt
  }
`;
export const PaymentProviderConnectionFragmentDoc = gql`
  fragment paymentProviderConnection on PaymentProviderConnection {
    total
    limit
    cursor
    sortable
    edges {
      ...paymentProvider
    }
  }
  ${PaymentProviderFragmentDoc}
`;
export const PaymentTransactionFragmentDoc = gql`
  fragment paymentTransaction on PaymentTransaction {
    id
    state
    side
    userId
    accountId
    currencyId
    providerId
    amount
    bankCardId
    orderId
    orderType
    meta
    createdAt
    updatedAt
  }
`;
export const PaymentTransactionConnectionFragmentDoc = gql`
  fragment paymentTransactionConnection on PaymentTransactionConnection {
    total
    limit
    cursor
    sortable
    edges {
      ...paymentTransaction
    }
  }
  ${PaymentTransactionFragmentDoc}
`;
export const ReserveDiscountFragmentDoc = gql`
  fragment reserveDiscount on ReserveDiscount {
    percent
    price
    category {
      id
      name
    }
  }
`;
export const ReserveFragmentDoc = gql`
  fragment reserve on Reserve {
    seatName
    seatId
    price
    eventId
    sector
    sectorId
    row
    seat
    discount {
      ...reserveDiscount
    }
  }
  ${ReserveDiscountFragmentDoc}
`;
export const TournamentSeasonFullFragmentDoc = gql`
  fragment tournamentSeasonFull on TournamentSeason {
    id
    title
    description
    tournamentId
    startDate
    finishDate
    state
    sort
    teams {
      ...tournamentTeam
    }
    createdAt
    updatedAt
  }
  ${TournamentTeamFragmentDoc}
`;
export const SystemAsyncTaskFragmentDoc = gql`
  fragment systemAsyncTask on SystemAsyncTask {
    id
    entity
    entityId
    operation
    state
    progress
    createdAt
    updatedAt
  }
`;
export const SystemAsyncTaskConnectionFragmentDoc = gql`
  fragment systemAsyncTaskConnection on SystemAsyncTaskConnection {
    total
    limit
    cursor
    sortable
    edges {
      ...systemAsyncTask
    }
  }
  ${SystemAsyncTaskFragmentDoc}
`;
export const SystemLocaleConnectionFragmentDoc = gql`
  fragment systemLocaleConnection on SystemLocaleConnection {
    total
    limit
    cursor
    sortable
    edges {
      ...systemLocale
    }
  }
  ${SystemLocaleFragmentDoc}
`;
export const SystemLoginFragmentDoc = gql`
  fragment systemLogin on SystemLogin {
    currentUser {
      ...systemUser
    }
    accessToken
    refreshToken
    clients {
      ...client
    }
    clientId
  }
  ${SystemUserFragmentDoc}
  ${ClientFragmentDoc}
`;
export const SystemPrinterFragmentDoc = gql`
  fragment systemPrinter on SystemPrinter {
    id
    clientId
    name
    title
    model
    createdAt
    updatedAt
  }
`;
export const SystemPrinterConnectionFragmentDoc = gql`
  fragment systemPrinterConnection on SystemPrinterConnection {
    total
    limit
    cursor
    sortable
    edges {
      ...systemPrinter
    }
  }
  ${SystemPrinterFragmentDoc}
`;
export const SystemTranslationFragmentDoc = gql`
  fragment systemTranslation on SystemTranslation {
    id
    locale
    key
    value
    createdAt
    updatedAt
  }
`;
export const SystemTranslationConnectionFragmentDoc = gql`
  fragment systemTranslationConnection on SystemTranslationConnection {
    total
    limit
    cursor
    sortable
    edges {
      ...systemTranslation
    }
  }
  ${SystemTranslationFragmentDoc}
`;
export const SystemUsersConnectionFragmentDoc = gql`
  fragment systemUsersConnection on SystemUsersConnection {
    total
    limit
    cursor
    sortable
    edges {
      ...systemUser
    }
  }
  ${SystemUserFragmentDoc}
`;
export const SystemWebHookFragmentDoc = gql`
  fragment systemWebHook on SystemWebHook {
    id
    clientId
    title
    entity
    operation
    url
    headers
    payload
    createdAt
    updatedAt
  }
`;
export const SystemWebHookConnectionFragmentDoc = gql`
  fragment systemWebHookConnection on SystemWebHookConnection {
    total
    limit
    cursor
    sortable
    edges {
      ...systemWebHook
    }
  }
  ${SystemWebHookFragmentDoc}
`;
export const TournamentTeamConnectionFragmentDoc = gql`
  fragment tournamentTeamConnection on TournamentTeamConnection {
    total
    limit
    cursor
    sortable
    edges {
      ...tournamentTeam
    }
  }
  ${TournamentTeamFragmentDoc}
`;
export const TemplateApplePassFragmentDoc = gql`
  fragment templateApplePass on TemplateApplePass {
    id
    clientId
    preview
    name
    pass
    files
    certificates
    createdAt
    updatedAt
  }
`;
export const TemplateApplePassConnectionFragmentDoc = gql`
  fragment templateApplePassConnection on TemplateApplePassConnection {
    total
    limit
    cursor
    sortable
    edges {
      ...templateApplePass
    }
  }
  ${TemplateApplePassFragmentDoc}
`;
export const TemplateNotificationFragmentDoc = gql`
  fragment templateNotification on TemplateNotification {
    id
    clientId
    state
    channel
    sender
    name
    title
    richText
    preview
    text
    createdAt
    updatedAt
  }
`;
export const TemplateNotificationConnectionFragmentDoc = gql`
  fragment templateNotificationConnection on TemplateNotificationConnection {
    total
    limit
    cursor
    sortable
    edges {
      ...templateNotification
    }
  }
  ${TemplateNotificationFragmentDoc}
`;
export const TemplatePdfFragmentDoc = gql`
  fragment templatePDF on TemplatePDF {
    id
    clientId
    name
    preview
    body
    createdAt
    updatedAt
  }
`;
export const TemplatePdfConnectionFragmentDoc = gql`
  fragment templatePDFConnection on TemplatePDFConnection {
    total
    limit
    cursor
    sortable
    edges {
      ...templatePDF
    }
  }
  ${TemplatePdfFragmentDoc}
`;
export const TournamentSeasonTitleFragmentDoc = gql`
  fragment tournamentSeasonTitle on TournamentSeason {
    id
    title
  }
`;
export const TournamentFullFragmentDoc = gql`
  fragment tournamentFull on Tournament {
    id
    clientId
    title
    description
    website
    state
    preview
    sort
    seasons {
      ...tournamentSeasonTitle
    }
    createdAt
    updatedAt
  }
  ${TournamentSeasonTitleFragmentDoc}
`;
export const TournamentConnectionFragmentDoc = gql`
  fragment tournamentConnection on TournamentConnection {
    total
    limit
    cursor
    sortable
    edges {
      ...tournamentFull
    }
  }
  ${TournamentFullFragmentDoc}
`;
export const TournamentDisputeFlatFragmentDoc = gql`
  fragment tournamentDisputeFlat on TournamentDispute {
    id
    state
    result
    confirmation
    userId
    matchId
    amount
    winAmount
    text
    createdAt
    updatedAt
  }
`;
export const TournamentDisputeFragmentDoc = gql`
  fragment tournamentDispute on TournamentDispute {
    ...tournamentDisputeFlat
    user {
      ...userFlat
    }
    match {
      ...tournamentMatch
    }
  }
  ${TournamentDisputeFlatFragmentDoc}
  ${UserFlatFragmentDoc}
  ${TournamentMatchFragmentDoc}
`;
export const TournamentDisqualificationFragmentDoc = gql`
  fragment tournamentDisqualification on TournamentDisqualification {
    id
    playerId
    reason
    tournamentId
    resolutionDate
    disqualificationValue
    leftMatches
    state
    createdAt
    updatedAt
  }
`;
export const TournamentTeamMemberFullFragmentDoc = gql`
  fragment tournamentTeamMemberFull on TournamentTeamMember {
    id
    type
    teamId
    firstName
    lastName
    middleName
    birthday
    weight
    height
    number
    role
    bio
    photo
    team {
      id
      name
      logo
      website
    }
    createdAt
    updatedAt
  }
`;
export const TournamentFragmentDoc = gql`
  fragment tournament on Tournament {
    id
    clientId
    title
    description
    website
    state
    preview
    sort
    createdAt
    updatedAt
  }
`;
export const TournamentDisqualificationFullFragmentDoc = gql`
  fragment tournamentDisqualificationFull on TournamentDisqualification {
    id
    playerId
    reason
    tournamentId
    resolutionDate
    disqualificationValue
    leftMatches
    state
    player {
      ...tournamentTeamMemberFull
    }
    tournament {
      ...tournament
    }
    createdAt
    updatedAt
  }
  ${TournamentTeamMemberFullFragmentDoc}
  ${TournamentFragmentDoc}
`;
export const TournamentDisqualificationConnectionFragmentDoc = gql`
  fragment tournamentDisqualificationConnection on TournamentDisqualificationConnection {
    total
    limit
    cursor
    sortable
    edges {
      ...tournamentDisqualificationFull
    }
  }
  ${TournamentDisqualificationFullFragmentDoc}
`;
export const TournamentMatchDetailsFragmentDoc = gql`
  fragment tournamentMatchDetails on TournamentMatch {
    ...tournamentMatchFlat
    team1 {
      ...tournamentTeam
    }
    team2 {
      ...tournamentTeam
    }
  }
  ${TournamentMatchFlatFragmentDoc}
  ${TournamentTeamFragmentDoc}
`;
export const TournamentMatchConnectionFragmentDoc = gql`
  fragment tournamentMatchConnection on TournamentMatchConnection {
    total
    limit
    cursor
    sortable
    edges {
      ...tournamentMatch
    }
  }
  ${TournamentMatchFragmentDoc}
`;
export const TournamentTeamMemberSmallFragmentDoc = gql`
  fragment tournamentTeamMemberSmall on TournamentTeamMember {
    id
    teamId
    firstName
    lastName
    middleName
    number
    role
    photo
    createdAt
    updatedAt
  }
`;
export const TournamentMatchEventFragmentDoc = gql`
  fragment tournamentMatchEvent on TournamentMatchEvent {
    id
    matchId
    datetime
    minute
    event
    teamMemberId
    teamId
    comment
    isProtocol
    linkedMatchEventId
    player {
      ...tournamentTeamMemberSmall
    }
    team {
      ...tournamentTeamSmall
    }
    createdAt
    updatedAt
  }
  ${TournamentTeamMemberSmallFragmentDoc}
  ${TournamentTeamSmallFragmentDoc}
`;
export const TournamentMatchEventConnectionFragmentDoc = gql`
  fragment tournamentMatchEventConnection on TournamentMatchEventConnection {
    total
    limit
    cursor
    sortable
    edges {
      ...tournamentMatchEvent
    }
  }
  ${TournamentMatchEventFragmentDoc}
`;
export const TournamentRefereeFragmentDoc = gql`
  fragment tournamentReferee on TournamentReferee {
    id
    firstName
    lastName
    middleName
    birthday
    preview
    photo
    category
    createdAt
    updatedAt
  }
`;
export const TournamentMatchLineUpFragmentDoc = gql`
  fragment tournamentMatchLineUp on TournamentMatchLineUp {
    id
    matchId
    teamId
    teamMemberId
    refereeId
    type
    initialRole
    finalRole
    initialState
    finalState
    number
    isTeamCaptain
    createdAt
    updatedAt
    referee {
      ...tournamentReferee
    }
    team {
      id
      name
    }
    teamMember {
      ...tournamentTeamMemberSmall
    }
  }
  ${TournamentRefereeFragmentDoc}
  ${TournamentTeamMemberSmallFragmentDoc}
`;
export const TournamentPlayerStatFullFragmentDoc = gql`
  fragment tournamentPlayerStatFull on TournamentPlayerStat {
    id
    playerId
    seasonId
    games
    goals
    penaltyKicks
    passes
    yellowCards
    redCards
    missedGoals
    shutouts
    bombardierValue
    assistantValue
    goalsAndPassesValue
    goalkeeperValue
    warningValue
    player {
      ...tournamentTeamMember
    }
    createdAt
    updatedAt
  }
  ${TournamentTeamMemberFragmentDoc}
`;
export const TournamentPlayerStatFragmentDoc = gql`
  fragment tournamentPlayerStat on TournamentPlayerStat {
    id
    playerId
    seasonId
    games
    goals
    penaltyKicks
    passes
    yellowCards
    redCards
    missedGoals
    shutouts
    bombardierValue
    assistantValue
    goalsAndPassesValue
    goalkeeperValue
    warningValue
    createdAt
    updatedAt
  }
`;
export const TournamentPlayerStatConnectionFragmentDoc = gql`
  fragment tournamentPlayerStatConnection on TournamentPlayerStatConnection {
    total
    limit
    cursor
    sortable
    edges {
      ...tournamentPlayerStat
    }
  }
  ${TournamentPlayerStatFragmentDoc}
`;
export const TournamentRefereeConnectionFragmentDoc = gql`
  fragment tournamentRefereeConnection on TournamentRefereeConnection {
    total
    limit
    cursor
    sortable
    edges {
      ...tournamentReferee
    }
  }
  ${TournamentRefereeFragmentDoc}
`;
export const TournamentSeasonFragmentDoc = gql`
  fragment tournamentSeason on TournamentSeason {
    id
    title
    description
    tournamentId
    startDate
    finishDate
    state
    sort
    createdAt
    updatedAt
  }
`;
export const TournamentSeasonConnectionFragmentDoc = gql`
  fragment tournamentSeasonConnection on TournamentSeasonConnection {
    total
    limit
    cursor
    sortable
    edges {
      ...tournamentSeason
    }
  }
  ${TournamentSeasonFragmentDoc}
`;
export const TournamentSeasonTeamFragmentDoc = gql`
  fragment tournamentSeasonTeam on TournamentSeasonTeam {
    id
    seasonId
    teamId
    createdAt
    updatedAt
  }
`;
export const TournamentSeasonTeamConnectionFragmentDoc = gql`
  fragment tournamentSeasonTeamConnection on TournamentSeasonTeamConnection {
    total
    limit
    cursor
    sortable
    edges {
      ...tournamentSeasonTeam
    }
  }
  ${TournamentSeasonTeamFragmentDoc}
`;
export const TournamentSeasonTeamStatFragmentDoc = gql`
  fragment tournamentSeasonTeamStat on TournamentSeasonTeamStat {
    id
    seasonId
    position
    teamId
    points
    games
    win
    draw
    loss
    goals
    missedGoals
    yellowCards
    redCards
    shutouts
    sort
    createdAt
    updatedAt
  }
`;
export const TournamentSeasonTeamStatConnectionFragmentDoc = gql`
  fragment tournamentSeasonTeamStatConnection on TournamentSeasonTeamStatConnection {
    total
    limit
    cursor
    sortable
    edges {
      ...tournamentSeasonTeamStat
    }
  }
  ${TournamentSeasonTeamStatFragmentDoc}
`;
export const TeamHistoryFragmentDoc = gql`
  fragment teamHistory on TournamentTeamHistory {
    id
    field
    value
    translationId
    createdAt
    updatedAt
  }
`;
export const SeasonTeamStatsFragmentDoc = gql`
  fragment seasonTeamStats on TournamentSeasonTeamStat {
    id
    position
    points
    games
    win
    loss
    draw
    yellowCards
    redCards
    goals
    shutouts
    missedGoals
  }
`;
export const TournamentTeamDetailsFragmentDoc = gql`
  fragment tournamentTeamDetails on TournamentTeam {
    ...tournamentTeam
    teamMembers {
      ...tournamentTeamMember
    }
    teamHistories {
      ...teamHistory
    }
    teamStats {
      ...seasonTeamStats
    }
  }
  ${TournamentTeamFragmentDoc}
  ${TournamentTeamMemberFragmentDoc}
  ${TeamHistoryFragmentDoc}
  ${SeasonTeamStatsFragmentDoc}
`;
export const TournamentTeamMemberConnectionFragmentDoc = gql`
  fragment tournamentTeamMemberConnection on TournamentTeamMemberConnection {
    total
    limit
    cursor
    sortable
    edges {
      ...tournamentTeamMember
    }
  }
  ${TournamentTeamMemberFragmentDoc}
`;
export const TournamentTourFragmentDoc = gql`
  fragment tournamentTour on TournamentTour {
    id
    title
    description
    tournamentId
    seasonId
    number
    startDate
    finishDate
    state
    sort
    createdAt
    updatedAt
  }
`;
export const TournamentTourConnectionFragmentDoc = gql`
  fragment tournamentTourConnection on TournamentTourConnection {
    total
    limit
    cursor
    sortable
    edges {
      ...tournamentTour
    }
  }
  ${TournamentTourFragmentDoc}
`;
export const TournamentTourDeepFragmentDoc = gql`
  fragment tournamentTourDeep on TournamentTour {
    ...tournamentTour
    matches {
      ...tournamentMatch
    }
  }
  ${TournamentTourFragmentDoc}
  ${TournamentMatchFragmentDoc}
`;
export const TournamentTourConnectionDeepFragmentDoc = gql`
  fragment tournamentTourConnectionDeep on TournamentTourConnection {
    total
    limit
    cursor
    sortable
    edges {
      ...tournamentTourDeep
    }
  }
  ${TournamentTourDeepFragmentDoc}
`;
export const TranslationFragmentDoc = gql`
  fragment translation on Translation {
    id
    locale
    key
    value
    updatedAt
  }
`;
export const UserApplicationFragmentDoc = gql`
  fragment userApplication on UserApplication {
    id
    clientId
    userId
    typeId
    type {
      id
      name
    }
    payload
    state
    comment
    data {
      id
      value
      key
      createdAt
      updatedAt
    }
    createdAt
    updatedAt
  }
`;
export const UserFragmentDoc = gql`
  fragment user on User {
    ...userFlat
    loyaltyAccount {
      ...paymentAccountFlat
    }
    loyaltyAccounts {
      ...paymentAccountFlat
    }
  }
  ${UserFlatFragmentDoc}
  ${PaymentAccountFlatFragmentDoc}
`;
export const UserAuthResponseFragmentDoc = gql`
  fragment userAuthResponse on UserAuthResponse {
    user {
      ...user
    }
    refreshToken
    accessToken
  }
  ${UserFragmentDoc}
`;
export const UserConnectionFragmentDoc = gql`
  fragment userConnection on UserConnection {
    total
    limit
    cursor
    sortable
    searchable
    edges {
      ...user
    }
  }
  ${UserFragmentDoc}
`;
export const UserDataFragmentDoc = gql`
  fragment userData on UserData {
    key
    title
    value
  }
`;
export const UserDeviceFragmentDoc = gql`
  fragment userDevice on UserDevice {
    userId
    installId
    bundleId
    brand
    deviceLocale
    deviceName
    deviceModel
    installReferrer
    manufacturer
    systemName
    systemVersion
    appVersion
    userAgent
    pushToken
    expoPushToken
    createdAt
    updatedAt
  }
`;
export const UserDocumentFragmentDoc = gql`
  fragment userDocument on UserDocument {
    id
    userId
    type
    data
    state
    createdAt
    updatedAt
  }
`;
export const UserFieldsConnectionFragmentDoc = gql`
  fragment userFieldsConnection on UserFieldsConnection {
    total
    limit
    cursor
    sortable
    edges {
      ...extensionField
    }
  }
  ${ExtensionFieldFragmentDoc}
`;
export const UserGroupMinFragmentDoc = gql`
  fragment userGroupMin on UserGroup {
    id
    name
  }
`;
export const UserGroupFragmentDoc = gql`
  fragment userGroup on UserGroup {
    id
    clientId
    name
    usersCount
    sort
    createdAt
    updatedAt
  }
`;
export const UserGroupConnectionFragmentDoc = gql`
  fragment userGroupConnection on UserGroupConnection {
    total
    limit
    cursor
    sortable
    searchable
    edges {
      ...userGroup
    }
  }
  ${UserGroupFragmentDoc}
`;
export const UserKidFragmentDoc = gql`
  fragment userKid on UserKid {
    id
    clientId
    userId
    state
    firstName
    lastName
    middleName
    birthday
    gender
    isSeasonTicket
    seasonTicket
    isSeasonTicketPast
    height
    createdAt
    updatedAt
  }
`;
export const UserSubscriberFragmentDoc = gql`
  fragment userSubscriber on UserSubscriber {
    id
    clientId
    userId
    subscriptionId
    modifierId
    user {
      ...userFlat
    }
    createdAt
    updatedAt
  }
  ${UserFlatFragmentDoc}
`;
export const UserSubscriberConnectionFragmentDoc = gql`
  fragment userSubscriberConnection on UserSubscriberConnection {
    total
    limit
    cursor
    sortable
    searchable
    edges {
      ...userSubscriber
    }
  }
  ${UserSubscriberFragmentDoc}
`;
export const UserSubscribersLogFragmentDoc = gql`
  fragment userSubscribersLog on UserSubscribersLog {
    id
    userId
    subscriptionId
    state
    reason
    systemUserId
    createdAt
    updatedAt
  }
`;
export const UserSubscribersLogConnectionFragmentDoc = gql`
  fragment userSubscribersLogConnection on UserSubscribersLogConnection {
    total
    limit
    cursor
    sortable
    edges {
      ...userSubscribersLog
    }
  }
  ${UserSubscribersLogFragmentDoc}
`;
export const UserSubscriptionFragmentDoc = gql`
  fragment userSubscription on UserSubscription {
    id
    clientId
    state
    isSubscriber
    name
    description
    subscribersCount
    access
    createdAt
    updatedAt
  }
`;
export const UserSubscriptionConnectionFragmentDoc = gql`
  fragment userSubscriptionConnection on UserSubscriptionConnection {
    total
    limit
    cursor
    sortable
    edges {
      ...userSubscription
    }
  }
  ${UserSubscriptionFragmentDoc}
`;
export const AddToMessengerQueueDocument = gql`
  mutation addToMessengerQueue($mailingId: Int!, $userId: [Int!], $query: DefaultQueryInput) {
    addToMessengerQueue(mailingId: $mailingId, userId: $userId, query: $query) {
      ...messengerQueueTask
    }
  }
  ${MessengerQueueTaskFragmentDoc}
`;
export type AddToMessengerQueueMutationFn = Apollo.MutationFunction<
  AddToMessengerQueueMutation,
  AddToMessengerQueueMutationVariables
>;

/**
 * __useAddToMessengerQueueMutation__
 *
 * To run a mutation, you first call `useAddToMessengerQueueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddToMessengerQueueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addToMessengerQueueMutation, { data, loading, error }] = useAddToMessengerQueueMutation({
 *   variables: {
 *      mailingId: // value for 'mailingId'
 *      userId: // value for 'userId'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAddToMessengerQueueMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<AddToMessengerQueueMutation, AddToMessengerQueueMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<AddToMessengerQueueMutation, AddToMessengerQueueMutationVariables>(
    AddToMessengerQueueDocument,
    options
  );
}
export type AddToMessengerQueueMutationHookResult = ReturnType<typeof useAddToMessengerQueueMutation>;
export type AddToMessengerQueueMutationResult = Apollo.MutationResult<AddToMessengerQueueMutation>;
export type AddToMessengerQueueMutationOptions = Apollo.BaseMutationOptions<
  AddToMessengerQueueMutation,
  AddToMessengerQueueMutationVariables
>;
export const AddToUsersSubscriptionDocument = gql`
  mutation addToUsersSubscription($subscriptionId: Int!, $query: DefaultQueryInput) {
    addToUsersSubscription(subscriptionId: $subscriptionId, query: $query)
  }
`;
export type AddToUsersSubscriptionMutationFn = Apollo.MutationFunction<
  AddToUsersSubscriptionMutation,
  AddToUsersSubscriptionMutationVariables
>;

/**
 * __useAddToUsersSubscriptionMutation__
 *
 * To run a mutation, you first call `useAddToUsersSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddToUsersSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addToUsersSubscriptionMutation, { data, loading, error }] = useAddToUsersSubscriptionMutation({
 *   variables: {
 *      subscriptionId: // value for 'subscriptionId'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAddToUsersSubscriptionMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AddToUsersSubscriptionMutation,
    AddToUsersSubscriptionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<AddToUsersSubscriptionMutation, AddToUsersSubscriptionMutationVariables>(
    AddToUsersSubscriptionDocument,
    options
  );
}
export type AddToUsersSubscriptionMutationHookResult = ReturnType<typeof useAddToUsersSubscriptionMutation>;
export type AddToUsersSubscriptionMutationResult = Apollo.MutationResult<AddToUsersSubscriptionMutation>;
export type AddToUsersSubscriptionMutationOptions = Apollo.BaseMutationOptions<
  AddToUsersSubscriptionMutation,
  AddToUsersSubscriptionMutationVariables
>;
export const AddUsersToGroupDocument = gql`
  mutation addUsersToGroup($groupId: [Int!]!, $query: DefaultQueryInput!) {
    addUsersToGroup(groupId: $groupId, query: $query) {
      ...systemAsyncTask
    }
  }
  ${SystemAsyncTaskFragmentDoc}
`;
export type AddUsersToGroupMutationFn = Apollo.MutationFunction<
  AddUsersToGroupMutation,
  AddUsersToGroupMutationVariables
>;

/**
 * __useAddUsersToGroupMutation__
 *
 * To run a mutation, you first call `useAddUsersToGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUsersToGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUsersToGroupMutation, { data, loading, error }] = useAddUsersToGroupMutation({
 *   variables: {
 *      groupId: // value for 'groupId'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAddUsersToGroupMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<AddUsersToGroupMutation, AddUsersToGroupMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<AddUsersToGroupMutation, AddUsersToGroupMutationVariables>(
    AddUsersToGroupDocument,
    options
  );
}
export type AddUsersToGroupMutationHookResult = ReturnType<typeof useAddUsersToGroupMutation>;
export type AddUsersToGroupMutationResult = Apollo.MutationResult<AddUsersToGroupMutation>;
export type AddUsersToGroupMutationOptions = Apollo.BaseMutationOptions<
  AddUsersToGroupMutation,
  AddUsersToGroupMutationVariables
>;
export const AddUsersToGroupFromTicketsDocument = gql`
  mutation addUsersToGroupFromTickets($groupId: [Int!]!, $query: DefaultQueryInput!) {
    addUsersToGroupFromTickets(groupId: $groupId, query: $query) {
      ...systemAsyncTask
    }
  }
  ${SystemAsyncTaskFragmentDoc}
`;
export type AddUsersToGroupFromTicketsMutationFn = Apollo.MutationFunction<
  AddUsersToGroupFromTicketsMutation,
  AddUsersToGroupFromTicketsMutationVariables
>;

/**
 * __useAddUsersToGroupFromTicketsMutation__
 *
 * To run a mutation, you first call `useAddUsersToGroupFromTicketsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUsersToGroupFromTicketsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUsersToGroupFromTicketsMutation, { data, loading, error }] = useAddUsersToGroupFromTicketsMutation({
 *   variables: {
 *      groupId: // value for 'groupId'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAddUsersToGroupFromTicketsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AddUsersToGroupFromTicketsMutation,
    AddUsersToGroupFromTicketsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<AddUsersToGroupFromTicketsMutation, AddUsersToGroupFromTicketsMutationVariables>(
    AddUsersToGroupFromTicketsDocument,
    options
  );
}
export type AddUsersToGroupFromTicketsMutationHookResult = ReturnType<typeof useAddUsersToGroupFromTicketsMutation>;
export type AddUsersToGroupFromTicketsMutationResult = Apollo.MutationResult<AddUsersToGroupFromTicketsMutation>;
export type AddUsersToGroupFromTicketsMutationOptions = Apollo.BaseMutationOptions<
  AddUsersToGroupFromTicketsMutation,
  AddUsersToGroupFromTicketsMutationVariables
>;
export const CancelApplicationFamilySectorDocument = gql`
  mutation cancelApplicationFamilySector($id: Int!) {
    cancelApplicationFamilySector(id: $id) {
      id
      userId
      typeId
      state
      comment
      data {
        key
        value
      }
    }
  }
`;
export type CancelApplicationFamilySectorMutationFn = Apollo.MutationFunction<
  CancelApplicationFamilySectorMutation,
  CancelApplicationFamilySectorMutationVariables
>;

/**
 * __useCancelApplicationFamilySectorMutation__
 *
 * To run a mutation, you first call `useCancelApplicationFamilySectorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelApplicationFamilySectorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelApplicationFamilySectorMutation, { data, loading, error }] = useCancelApplicationFamilySectorMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCancelApplicationFamilySectorMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CancelApplicationFamilySectorMutation,
    CancelApplicationFamilySectorMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    CancelApplicationFamilySectorMutation,
    CancelApplicationFamilySectorMutationVariables
  >(CancelApplicationFamilySectorDocument, options);
}
export type CancelApplicationFamilySectorMutationHookResult = ReturnType<
  typeof useCancelApplicationFamilySectorMutation
>;
export type CancelApplicationFamilySectorMutationResult = Apollo.MutationResult<CancelApplicationFamilySectorMutation>;
export type CancelApplicationFamilySectorMutationOptions = Apollo.BaseMutationOptions<
  CancelApplicationFamilySectorMutation,
  CancelApplicationFamilySectorMutationVariables
>;
export const CancelLoyaltyOrderDocument = gql`
  mutation cancelLoyaltyOrder($id: Int!) {
    cancelLoyaltyOrder(id: $id) {
      id
    }
  }
`;
export type CancelLoyaltyOrderMutationFn = Apollo.MutationFunction<
  CancelLoyaltyOrderMutation,
  CancelLoyaltyOrderMutationVariables
>;

/**
 * __useCancelLoyaltyOrderMutation__
 *
 * To run a mutation, you first call `useCancelLoyaltyOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelLoyaltyOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelLoyaltyOrderMutation, { data, loading, error }] = useCancelLoyaltyOrderMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCancelLoyaltyOrderMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<CancelLoyaltyOrderMutation, CancelLoyaltyOrderMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<CancelLoyaltyOrderMutation, CancelLoyaltyOrderMutationVariables>(
    CancelLoyaltyOrderDocument,
    options
  );
}
export type CancelLoyaltyOrderMutationHookResult = ReturnType<typeof useCancelLoyaltyOrderMutation>;
export type CancelLoyaltyOrderMutationResult = Apollo.MutationResult<CancelLoyaltyOrderMutation>;
export type CancelLoyaltyOrderMutationOptions = Apollo.BaseMutationOptions<
  CancelLoyaltyOrderMutation,
  CancelLoyaltyOrderMutationVariables
>;
export const CancelMyEventOrderDocument = gql`
  mutation cancelMyEventOrder($orderId: Int!) {
    cancelMyEventOrder(orderId: $orderId) {
      ...eventOrder
    }
  }
  ${EventOrderFragmentDoc}
`;
export type CancelMyEventOrderMutationFn = Apollo.MutationFunction<
  CancelMyEventOrderMutation,
  CancelMyEventOrderMutationVariables
>;

/**
 * __useCancelMyEventOrderMutation__
 *
 * To run a mutation, you first call `useCancelMyEventOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelMyEventOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelMyEventOrderMutation, { data, loading, error }] = useCancelMyEventOrderMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useCancelMyEventOrderMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<CancelMyEventOrderMutation, CancelMyEventOrderMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<CancelMyEventOrderMutation, CancelMyEventOrderMutationVariables>(
    CancelMyEventOrderDocument,
    options
  );
}
export type CancelMyEventOrderMutationHookResult = ReturnType<typeof useCancelMyEventOrderMutation>;
export type CancelMyEventOrderMutationResult = Apollo.MutationResult<CancelMyEventOrderMutation>;
export type CancelMyEventOrderMutationOptions = Apollo.BaseMutationOptions<
  CancelMyEventOrderMutation,
  CancelMyEventOrderMutationVariables
>;
export const ChargeFromAccountDocument = gql`
  mutation chargeFromAccount($data: PaymentAccountOperationInput!) {
    chargeFromAccount(data: $data) {
      amount
    }
  }
`;
export type ChargeFromAccountMutationFn = Apollo.MutationFunction<
  ChargeFromAccountMutation,
  ChargeFromAccountMutationVariables
>;

/**
 * __useChargeFromAccountMutation__
 *
 * To run a mutation, you first call `useChargeFromAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChargeFromAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [chargeFromAccountMutation, { data, loading, error }] = useChargeFromAccountMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useChargeFromAccountMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<ChargeFromAccountMutation, ChargeFromAccountMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<ChargeFromAccountMutation, ChargeFromAccountMutationVariables>(
    ChargeFromAccountDocument,
    options
  );
}
export type ChargeFromAccountMutationHookResult = ReturnType<typeof useChargeFromAccountMutation>;
export type ChargeFromAccountMutationResult = Apollo.MutationResult<ChargeFromAccountMutation>;
export type ChargeFromAccountMutationOptions = Apollo.BaseMutationOptions<
  ChargeFromAccountMutation,
  ChargeFromAccountMutationVariables
>;
export const CheckMatchAccessDocument = gql`
  mutation checkMatchAccess($data: CheckMatchAccessPayload) {
    checkMatchAccess(data: $data) {
      result
      seatName
    }
  }
`;
export type CheckMatchAccessMutationFn = Apollo.MutationFunction<
  CheckMatchAccessMutation,
  CheckMatchAccessMutationVariables
>;

/**
 * __useCheckMatchAccessMutation__
 *
 * To run a mutation, you first call `useCheckMatchAccessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckMatchAccessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkMatchAccessMutation, { data, loading, error }] = useCheckMatchAccessMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCheckMatchAccessMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<CheckMatchAccessMutation, CheckMatchAccessMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<CheckMatchAccessMutation, CheckMatchAccessMutationVariables>(
    CheckMatchAccessDocument,
    options
  );
}
export type CheckMatchAccessMutationHookResult = ReturnType<typeof useCheckMatchAccessMutation>;
export type CheckMatchAccessMutationResult = Apollo.MutationResult<CheckMatchAccessMutation>;
export type CheckMatchAccessMutationOptions = Apollo.BaseMutationOptions<
  CheckMatchAccessMutation,
  CheckMatchAccessMutationVariables
>;
export const ValidateOrderPaymentDocument = gql`
  mutation validateOrderPayment($data: CheckOrderPaymentInput!) {
    validateOrderPayment(data: $data) {
      eventOrder {
        ...eventOrderFlat
      }
      orderId
      success
    }
  }
  ${EventOrderFlatFragmentDoc}
`;
export type ValidateOrderPaymentMutationFn = Apollo.MutationFunction<
  ValidateOrderPaymentMutation,
  ValidateOrderPaymentMutationVariables
>;

/**
 * __useValidateOrderPaymentMutation__
 *
 * To run a mutation, you first call `useValidateOrderPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useValidateOrderPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [validateOrderPaymentMutation, { data, loading, error }] = useValidateOrderPaymentMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useValidateOrderPaymentMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ValidateOrderPaymentMutation,
    ValidateOrderPaymentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<ValidateOrderPaymentMutation, ValidateOrderPaymentMutationVariables>(
    ValidateOrderPaymentDocument,
    options
  );
}
export type ValidateOrderPaymentMutationHookResult = ReturnType<typeof useValidateOrderPaymentMutation>;
export type ValidateOrderPaymentMutationResult = Apollo.MutationResult<ValidateOrderPaymentMutation>;
export type ValidateOrderPaymentMutationOptions = Apollo.BaseMutationOptions<
  ValidateOrderPaymentMutation,
  ValidateOrderPaymentMutationVariables
>;
export const CheckSeasonTicketDocument = gql`
  mutation checkSeasonTicket($data: CheckSeasonTicketPayload!) {
    checkSeasonTicket(data: $data) {
      result
    }
  }
`;
export type CheckSeasonTicketMutationFn = Apollo.MutationFunction<
  CheckSeasonTicketMutation,
  CheckSeasonTicketMutationVariables
>;

/**
 * __useCheckSeasonTicketMutation__
 *
 * To run a mutation, you first call `useCheckSeasonTicketMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckSeasonTicketMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkSeasonTicketMutation, { data, loading, error }] = useCheckSeasonTicketMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCheckSeasonTicketMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<CheckSeasonTicketMutation, CheckSeasonTicketMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<CheckSeasonTicketMutation, CheckSeasonTicketMutationVariables>(
    CheckSeasonTicketDocument,
    options
  );
}
export type CheckSeasonTicketMutationHookResult = ReturnType<typeof useCheckSeasonTicketMutation>;
export type CheckSeasonTicketMutationResult = Apollo.MutationResult<CheckSeasonTicketMutation>;
export type CheckSeasonTicketMutationOptions = Apollo.BaseMutationOptions<
  CheckSeasonTicketMutation,
  CheckSeasonTicketMutationVariables
>;
export const CommerceOrderPrintDocument = gql`
  mutation commerceOrderPrint($id: Int!, $printerId: Int!, $templateId: Int!) {
    commerceOrderPrint(id: $id, printerId: $printerId, templateId: $templateId)
  }
`;
export type CommerceOrderPrintMutationFn = Apollo.MutationFunction<
  CommerceOrderPrintMutation,
  CommerceOrderPrintMutationVariables
>;

/**
 * __useCommerceOrderPrintMutation__
 *
 * To run a mutation, you first call `useCommerceOrderPrintMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCommerceOrderPrintMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [commerceOrderPrintMutation, { data, loading, error }] = useCommerceOrderPrintMutation({
 *   variables: {
 *      id: // value for 'id'
 *      printerId: // value for 'printerId'
 *      templateId: // value for 'templateId'
 *   },
 * });
 */
export function useCommerceOrderPrintMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<CommerceOrderPrintMutation, CommerceOrderPrintMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<CommerceOrderPrintMutation, CommerceOrderPrintMutationVariables>(
    CommerceOrderPrintDocument,
    options
  );
}
export type CommerceOrderPrintMutationHookResult = ReturnType<typeof useCommerceOrderPrintMutation>;
export type CommerceOrderPrintMutationResult = Apollo.MutationResult<CommerceOrderPrintMutation>;
export type CommerceOrderPrintMutationOptions = Apollo.BaseMutationOptions<
  CommerceOrderPrintMutation,
  CommerceOrderPrintMutationVariables
>;
export const ConfirmDisputeDisputeModeratorDocument = gql`
  mutation confirmDisputeDisputeModerator($id: Int!, $result: DisputeResult!) {
    confirmDisputeDisputeModerator(id: $id, result: $result) {
      ...dispute
    }
  }
  ${DisputeFragmentDoc}
`;
export type ConfirmDisputeDisputeModeratorMutationFn = Apollo.MutationFunction<
  ConfirmDisputeDisputeModeratorMutation,
  ConfirmDisputeDisputeModeratorMutationVariables
>;

/**
 * __useConfirmDisputeDisputeModeratorMutation__
 *
 * To run a mutation, you first call `useConfirmDisputeDisputeModeratorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmDisputeDisputeModeratorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmDisputeDisputeModeratorMutation, { data, loading, error }] = useConfirmDisputeDisputeModeratorMutation({
 *   variables: {
 *      id: // value for 'id'
 *      result: // value for 'result'
 *   },
 * });
 */
export function useConfirmDisputeDisputeModeratorMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ConfirmDisputeDisputeModeratorMutation,
    ConfirmDisputeDisputeModeratorMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    ConfirmDisputeDisputeModeratorMutation,
    ConfirmDisputeDisputeModeratorMutationVariables
  >(ConfirmDisputeDisputeModeratorDocument, options);
}
export type ConfirmDisputeDisputeModeratorMutationHookResult = ReturnType<
  typeof useConfirmDisputeDisputeModeratorMutation
>;
export type ConfirmDisputeDisputeModeratorMutationResult = Apollo.MutationResult<ConfirmDisputeDisputeModeratorMutation>;
export type ConfirmDisputeDisputeModeratorMutationOptions = Apollo.BaseMutationOptions<
  ConfirmDisputeDisputeModeratorMutation,
  ConfirmDisputeDisputeModeratorMutationVariables
>;
export const ConfirmUserEmailDocument = gql`
  mutation confirmUserEmail($email: String!, $confirmationKey: String) {
    confirmUserEmail(email: $email, confirmationKey: $confirmationKey)
  }
`;
export type ConfirmUserEmailMutationFn = Apollo.MutationFunction<
  ConfirmUserEmailMutation,
  ConfirmUserEmailMutationVariables
>;

/**
 * __useConfirmUserEmailMutation__
 *
 * To run a mutation, you first call `useConfirmUserEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmUserEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmUserEmailMutation, { data, loading, error }] = useConfirmUserEmailMutation({
 *   variables: {
 *      email: // value for 'email'
 *      confirmationKey: // value for 'confirmationKey'
 *   },
 * });
 */
export function useConfirmUserEmailMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<ConfirmUserEmailMutation, ConfirmUserEmailMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<ConfirmUserEmailMutation, ConfirmUserEmailMutationVariables>(
    ConfirmUserEmailDocument,
    options
  );
}
export type ConfirmUserEmailMutationHookResult = ReturnType<typeof useConfirmUserEmailMutation>;
export type ConfirmUserEmailMutationResult = Apollo.MutationResult<ConfirmUserEmailMutation>;
export type ConfirmUserEmailMutationOptions = Apollo.BaseMutationOptions<
  ConfirmUserEmailMutation,
  ConfirmUserEmailMutationVariables
>;
export const CreateApplicationFamilySectorDocument = gql`
  mutation createApplicationFamilySector($data: UserApplicationFamilySectorCreateInput!) {
    createApplicationFamilySector(data: $data) {
      id
      userId
      typeId
      state
      comment
      data {
        key
        value
      }
    }
  }
`;
export type CreateApplicationFamilySectorMutationFn = Apollo.MutationFunction<
  CreateApplicationFamilySectorMutation,
  CreateApplicationFamilySectorMutationVariables
>;

/**
 * __useCreateApplicationFamilySectorMutation__
 *
 * To run a mutation, you first call `useCreateApplicationFamilySectorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateApplicationFamilySectorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createApplicationFamilySectorMutation, { data, loading, error }] = useCreateApplicationFamilySectorMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateApplicationFamilySectorMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateApplicationFamilySectorMutation,
    CreateApplicationFamilySectorMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    CreateApplicationFamilySectorMutation,
    CreateApplicationFamilySectorMutationVariables
  >(CreateApplicationFamilySectorDocument, options);
}
export type CreateApplicationFamilySectorMutationHookResult = ReturnType<
  typeof useCreateApplicationFamilySectorMutation
>;
export type CreateApplicationFamilySectorMutationResult = Apollo.MutationResult<CreateApplicationFamilySectorMutation>;
export type CreateApplicationFamilySectorMutationOptions = Apollo.BaseMutationOptions<
  CreateApplicationFamilySectorMutation,
  CreateApplicationFamilySectorMutationVariables
>;
export const CreateArticleDocument = gql`
  mutation createArticle($data: ArticleCreateInput!) {
    createArticle(data: $data) {
      ...article
    }
  }
  ${ArticleFragmentDoc}
`;
export type CreateArticleMutationFn = Apollo.MutationFunction<CreateArticleMutation, CreateArticleMutationVariables>;

/**
 * __useCreateArticleMutation__
 *
 * To run a mutation, you first call `useCreateArticleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateArticleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createArticleMutation, { data, loading, error }] = useCreateArticleMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateArticleMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<CreateArticleMutation, CreateArticleMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<CreateArticleMutation, CreateArticleMutationVariables>(
    CreateArticleDocument,
    options
  );
}
export type CreateArticleMutationHookResult = ReturnType<typeof useCreateArticleMutation>;
export type CreateArticleMutationResult = Apollo.MutationResult<CreateArticleMutation>;
export type CreateArticleMutationOptions = Apollo.BaseMutationOptions<
  CreateArticleMutation,
  CreateArticleMutationVariables
>;
export const CreateArticleAuthorDocument = gql`
  mutation createArticleAuthor($data: ArticleAuthorCreateInput!) {
    createArticleAuthor(data: $data) {
      ...articleAuthor
    }
  }
  ${ArticleAuthorFragmentDoc}
`;
export type CreateArticleAuthorMutationFn = Apollo.MutationFunction<
  CreateArticleAuthorMutation,
  CreateArticleAuthorMutationVariables
>;

/**
 * __useCreateArticleAuthorMutation__
 *
 * To run a mutation, you first call `useCreateArticleAuthorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateArticleAuthorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createArticleAuthorMutation, { data, loading, error }] = useCreateArticleAuthorMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateArticleAuthorMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<CreateArticleAuthorMutation, CreateArticleAuthorMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<CreateArticleAuthorMutation, CreateArticleAuthorMutationVariables>(
    CreateArticleAuthorDocument,
    options
  );
}
export type CreateArticleAuthorMutationHookResult = ReturnType<typeof useCreateArticleAuthorMutation>;
export type CreateArticleAuthorMutationResult = Apollo.MutationResult<CreateArticleAuthorMutation>;
export type CreateArticleAuthorMutationOptions = Apollo.BaseMutationOptions<
  CreateArticleAuthorMutation,
  CreateArticleAuthorMutationVariables
>;
export const CreateArticleBlockDocument = gql`
  mutation createArticleBlock($data: ArticleBlockCreateInput!) {
    createArticleBlock(data: $data) {
      ...articleBlock
    }
  }
  ${ArticleBlockFragmentDoc}
`;
export type CreateArticleBlockMutationFn = Apollo.MutationFunction<
  CreateArticleBlockMutation,
  CreateArticleBlockMutationVariables
>;

/**
 * __useCreateArticleBlockMutation__
 *
 * To run a mutation, you first call `useCreateArticleBlockMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateArticleBlockMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createArticleBlockMutation, { data, loading, error }] = useCreateArticleBlockMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateArticleBlockMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<CreateArticleBlockMutation, CreateArticleBlockMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<CreateArticleBlockMutation, CreateArticleBlockMutationVariables>(
    CreateArticleBlockDocument,
    options
  );
}
export type CreateArticleBlockMutationHookResult = ReturnType<typeof useCreateArticleBlockMutation>;
export type CreateArticleBlockMutationResult = Apollo.MutationResult<CreateArticleBlockMutation>;
export type CreateArticleBlockMutationOptions = Apollo.BaseMutationOptions<
  CreateArticleBlockMutation,
  CreateArticleBlockMutationVariables
>;
export const CreateArticleCategoryDocument = gql`
  mutation createArticleCategory($data: ArticleCategoryCreateInput!) {
    createArticleCategory(data: $data) {
      ...articleCategory
    }
  }
  ${ArticleCategoryFragmentDoc}
`;
export type CreateArticleCategoryMutationFn = Apollo.MutationFunction<
  CreateArticleCategoryMutation,
  CreateArticleCategoryMutationVariables
>;

/**
 * __useCreateArticleCategoryMutation__
 *
 * To run a mutation, you first call `useCreateArticleCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateArticleCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createArticleCategoryMutation, { data, loading, error }] = useCreateArticleCategoryMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateArticleCategoryMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateArticleCategoryMutation,
    CreateArticleCategoryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<CreateArticleCategoryMutation, CreateArticleCategoryMutationVariables>(
    CreateArticleCategoryDocument,
    options
  );
}
export type CreateArticleCategoryMutationHookResult = ReturnType<typeof useCreateArticleCategoryMutation>;
export type CreateArticleCategoryMutationResult = Apollo.MutationResult<CreateArticleCategoryMutation>;
export type CreateArticleCategoryMutationOptions = Apollo.BaseMutationOptions<
  CreateArticleCategoryMutation,
  CreateArticleCategoryMutationVariables
>;
export const CreateArticleLinkDocument = gql`
  mutation createArticleLink($data: ArticleLinkCreateInput!) {
    createArticleLink(data: $data) {
      ...articleLink
    }
  }
  ${ArticleLinkFragmentDoc}
`;
export type CreateArticleLinkMutationFn = Apollo.MutationFunction<
  CreateArticleLinkMutation,
  CreateArticleLinkMutationVariables
>;

/**
 * __useCreateArticleLinkMutation__
 *
 * To run a mutation, you first call `useCreateArticleLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateArticleLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createArticleLinkMutation, { data, loading, error }] = useCreateArticleLinkMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateArticleLinkMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<CreateArticleLinkMutation, CreateArticleLinkMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<CreateArticleLinkMutation, CreateArticleLinkMutationVariables>(
    CreateArticleLinkDocument,
    options
  );
}
export type CreateArticleLinkMutationHookResult = ReturnType<typeof useCreateArticleLinkMutation>;
export type CreateArticleLinkMutationResult = Apollo.MutationResult<CreateArticleLinkMutation>;
export type CreateArticleLinkMutationOptions = Apollo.BaseMutationOptions<
  CreateArticleLinkMutation,
  CreateArticleLinkMutationVariables
>;
export const CreateArticleTagDocument = gql`
  mutation createArticleTag($data: ArticleTagCreateInput!) {
    createArticleTag(data: $data) {
      ...articleTag
    }
  }
  ${ArticleTagFragmentDoc}
`;
export type CreateArticleTagMutationFn = Apollo.MutationFunction<
  CreateArticleTagMutation,
  CreateArticleTagMutationVariables
>;

/**
 * __useCreateArticleTagMutation__
 *
 * To run a mutation, you first call `useCreateArticleTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateArticleTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createArticleTagMutation, { data, loading, error }] = useCreateArticleTagMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateArticleTagMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<CreateArticleTagMutation, CreateArticleTagMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<CreateArticleTagMutation, CreateArticleTagMutationVariables>(
    CreateArticleTagDocument,
    options
  );
}
export type CreateArticleTagMutationHookResult = ReturnType<typeof useCreateArticleTagMutation>;
export type CreateArticleTagMutationResult = Apollo.MutationResult<CreateArticleTagMutation>;
export type CreateArticleTagMutationOptions = Apollo.BaseMutationOptions<
  CreateArticleTagMutation,
  CreateArticleTagMutationVariables
>;
export const CreateCatalogueDocument = gql`
  mutation createCatalogue($data: CatalogueCreateInput!) {
    createCatalogue(data: $data) {
      ...catalogueFlat
    }
  }
  ${CatalogueFlatFragmentDoc}
`;
export type CreateCatalogueMutationFn = Apollo.MutationFunction<
  CreateCatalogueMutation,
  CreateCatalogueMutationVariables
>;

/**
 * __useCreateCatalogueMutation__
 *
 * To run a mutation, you first call `useCreateCatalogueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCatalogueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCatalogueMutation, { data, loading, error }] = useCreateCatalogueMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateCatalogueMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCatalogueMutation, CreateCatalogueMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<CreateCatalogueMutation, CreateCatalogueMutationVariables>(
    CreateCatalogueDocument,
    options
  );
}
export type CreateCatalogueMutationHookResult = ReturnType<typeof useCreateCatalogueMutation>;
export type CreateCatalogueMutationResult = Apollo.MutationResult<CreateCatalogueMutation>;
export type CreateCatalogueMutationOptions = Apollo.BaseMutationOptions<
  CreateCatalogueMutation,
  CreateCatalogueMutationVariables
>;
export const CreateCatalogueCategoryDocument = gql`
  mutation createCatalogueCategory($data: CatalogueCategoryCreateInput!) {
    createCatalogueCategory(data: $data) {
      ...catalogueCategory
    }
  }
  ${CatalogueCategoryFragmentDoc}
`;
export type CreateCatalogueCategoryMutationFn = Apollo.MutationFunction<
  CreateCatalogueCategoryMutation,
  CreateCatalogueCategoryMutationVariables
>;

/**
 * __useCreateCatalogueCategoryMutation__
 *
 * To run a mutation, you first call `useCreateCatalogueCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCatalogueCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCatalogueCategoryMutation, { data, loading, error }] = useCreateCatalogueCategoryMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateCatalogueCategoryMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateCatalogueCategoryMutation,
    CreateCatalogueCategoryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<CreateCatalogueCategoryMutation, CreateCatalogueCategoryMutationVariables>(
    CreateCatalogueCategoryDocument,
    options
  );
}
export type CreateCatalogueCategoryMutationHookResult = ReturnType<typeof useCreateCatalogueCategoryMutation>;
export type CreateCatalogueCategoryMutationResult = Apollo.MutationResult<CreateCatalogueCategoryMutation>;
export type CreateCatalogueCategoryMutationOptions = Apollo.BaseMutationOptions<
  CreateCatalogueCategoryMutation,
  CreateCatalogueCategoryMutationVariables
>;
export const CreateCatalogueItemDocument = gql`
  mutation createCatalogueItem($data: CatalogueItemCreateInput!) {
    createCatalogueItem(data: $data) {
      ...catalogueItem
    }
  }
  ${CatalogueItemFragmentDoc}
`;
export type CreateCatalogueItemMutationFn = Apollo.MutationFunction<
  CreateCatalogueItemMutation,
  CreateCatalogueItemMutationVariables
>;

/**
 * __useCreateCatalogueItemMutation__
 *
 * To run a mutation, you first call `useCreateCatalogueItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCatalogueItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCatalogueItemMutation, { data, loading, error }] = useCreateCatalogueItemMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateCatalogueItemMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCatalogueItemMutation, CreateCatalogueItemMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<CreateCatalogueItemMutation, CreateCatalogueItemMutationVariables>(
    CreateCatalogueItemDocument,
    options
  );
}
export type CreateCatalogueItemMutationHookResult = ReturnType<typeof useCreateCatalogueItemMutation>;
export type CreateCatalogueItemMutationResult = Apollo.MutationResult<CreateCatalogueItemMutation>;
export type CreateCatalogueItemMutationOptions = Apollo.BaseMutationOptions<
  CreateCatalogueItemMutation,
  CreateCatalogueItemMutationVariables
>;
export const CreateCatalogueItemPriceDocument = gql`
  mutation createCatalogueItemPrice($data: CatalogueItemPriceCreateInput!) {
    createCatalogueItemPrice(data: $data) {
      ...catalogueItemPrice
    }
  }
  ${CatalogueItemPriceFragmentDoc}
`;
export type CreateCatalogueItemPriceMutationFn = Apollo.MutationFunction<
  CreateCatalogueItemPriceMutation,
  CreateCatalogueItemPriceMutationVariables
>;

/**
 * __useCreateCatalogueItemPriceMutation__
 *
 * To run a mutation, you first call `useCreateCatalogueItemPriceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCatalogueItemPriceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCatalogueItemPriceMutation, { data, loading, error }] = useCreateCatalogueItemPriceMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateCatalogueItemPriceMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateCatalogueItemPriceMutation,
    CreateCatalogueItemPriceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<CreateCatalogueItemPriceMutation, CreateCatalogueItemPriceMutationVariables>(
    CreateCatalogueItemPriceDocument,
    options
  );
}
export type CreateCatalogueItemPriceMutationHookResult = ReturnType<typeof useCreateCatalogueItemPriceMutation>;
export type CreateCatalogueItemPriceMutationResult = Apollo.MutationResult<CreateCatalogueItemPriceMutation>;
export type CreateCatalogueItemPriceMutationOptions = Apollo.BaseMutationOptions<
  CreateCatalogueItemPriceMutation,
  CreateCatalogueItemPriceMutationVariables
>;
export const CreateCateringDocument = gql`
  mutation createCatering($data: CateringCreateInput!) {
    createCatering(data: $data) {
      ...catering
    }
  }
  ${CateringFragmentDoc}
`;
export type CreateCateringMutationFn = Apollo.MutationFunction<CreateCateringMutation, CreateCateringMutationVariables>;

/**
 * __useCreateCateringMutation__
 *
 * To run a mutation, you first call `useCreateCateringMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCateringMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCateringMutation, { data, loading, error }] = useCreateCateringMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateCateringMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCateringMutation, CreateCateringMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<CreateCateringMutation, CreateCateringMutationVariables>(
    CreateCateringDocument,
    options
  );
}
export type CreateCateringMutationHookResult = ReturnType<typeof useCreateCateringMutation>;
export type CreateCateringMutationResult = Apollo.MutationResult<CreateCateringMutation>;
export type CreateCateringMutationOptions = Apollo.BaseMutationOptions<
  CreateCateringMutation,
  CreateCateringMutationVariables
>;
export const CreateCommerceOrderDocument = gql`
  mutation createCommerceOrder($data: CommerceOrderCreateInput!) {
    createCommerceOrder(data: $data) {
      ...commerceOrder
    }
  }
  ${CommerceOrderFragmentDoc}
`;
export type CreateCommerceOrderMutationFn = Apollo.MutationFunction<
  CreateCommerceOrderMutation,
  CreateCommerceOrderMutationVariables
>;

/**
 * __useCreateCommerceOrderMutation__
 *
 * To run a mutation, you first call `useCreateCommerceOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCommerceOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCommerceOrderMutation, { data, loading, error }] = useCreateCommerceOrderMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateCommerceOrderMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCommerceOrderMutation, CreateCommerceOrderMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<CreateCommerceOrderMutation, CreateCommerceOrderMutationVariables>(
    CreateCommerceOrderDocument,
    options
  );
}
export type CreateCommerceOrderMutationHookResult = ReturnType<typeof useCreateCommerceOrderMutation>;
export type CreateCommerceOrderMutationResult = Apollo.MutationResult<CreateCommerceOrderMutation>;
export type CreateCommerceOrderMutationOptions = Apollo.BaseMutationOptions<
  CreateCommerceOrderMutation,
  CreateCommerceOrderMutationVariables
>;
export const CreateCommerceOrderDataDocument = gql`
  mutation createCommerceOrderData($data: CommerceOrderDataCreateInput!) {
    createCommerceOrderData(data: $data) {
      ...commerceOrderData
    }
  }
  ${CommerceOrderDataFragmentDoc}
`;
export type CreateCommerceOrderDataMutationFn = Apollo.MutationFunction<
  CreateCommerceOrderDataMutation,
  CreateCommerceOrderDataMutationVariables
>;

/**
 * __useCreateCommerceOrderDataMutation__
 *
 * To run a mutation, you first call `useCreateCommerceOrderDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCommerceOrderDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCommerceOrderDataMutation, { data, loading, error }] = useCreateCommerceOrderDataMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateCommerceOrderDataMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateCommerceOrderDataMutation,
    CreateCommerceOrderDataMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<CreateCommerceOrderDataMutation, CreateCommerceOrderDataMutationVariables>(
    CreateCommerceOrderDataDocument,
    options
  );
}
export type CreateCommerceOrderDataMutationHookResult = ReturnType<typeof useCreateCommerceOrderDataMutation>;
export type CreateCommerceOrderDataMutationResult = Apollo.MutationResult<CreateCommerceOrderDataMutation>;
export type CreateCommerceOrderDataMutationOptions = Apollo.BaseMutationOptions<
  CreateCommerceOrderDataMutation,
  CreateCommerceOrderDataMutationVariables
>;
export const CreateCommerceOrderFieldDocument = gql`
  mutation createCommerceOrderField($data: CommerceOrderFieldCreateInput!) {
    createCommerceOrderField(data: $data) {
      ...commerceOrderField
    }
  }
  ${CommerceOrderFieldFragmentDoc}
`;
export type CreateCommerceOrderFieldMutationFn = Apollo.MutationFunction<
  CreateCommerceOrderFieldMutation,
  CreateCommerceOrderFieldMutationVariables
>;

/**
 * __useCreateCommerceOrderFieldMutation__
 *
 * To run a mutation, you first call `useCreateCommerceOrderFieldMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCommerceOrderFieldMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCommerceOrderFieldMutation, { data, loading, error }] = useCreateCommerceOrderFieldMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateCommerceOrderFieldMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateCommerceOrderFieldMutation,
    CreateCommerceOrderFieldMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<CreateCommerceOrderFieldMutation, CreateCommerceOrderFieldMutationVariables>(
    CreateCommerceOrderFieldDocument,
    options
  );
}
export type CreateCommerceOrderFieldMutationHookResult = ReturnType<typeof useCreateCommerceOrderFieldMutation>;
export type CreateCommerceOrderFieldMutationResult = Apollo.MutationResult<CreateCommerceOrderFieldMutation>;
export type CreateCommerceOrderFieldMutationOptions = Apollo.BaseMutationOptions<
  CreateCommerceOrderFieldMutation,
  CreateCommerceOrderFieldMutationVariables
>;
export const CreateCommerceOrderItemDocument = gql`
  mutation createCommerceOrderItem($data: CommerceOrderItemCreateInput!) {
    createCommerceOrderItem(data: $data) {
      ...commerceOrderItem
    }
  }
  ${CommerceOrderItemFragmentDoc}
`;
export type CreateCommerceOrderItemMutationFn = Apollo.MutationFunction<
  CreateCommerceOrderItemMutation,
  CreateCommerceOrderItemMutationVariables
>;

/**
 * __useCreateCommerceOrderItemMutation__
 *
 * To run a mutation, you first call `useCreateCommerceOrderItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCommerceOrderItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCommerceOrderItemMutation, { data, loading, error }] = useCreateCommerceOrderItemMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateCommerceOrderItemMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateCommerceOrderItemMutation,
    CreateCommerceOrderItemMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<CreateCommerceOrderItemMutation, CreateCommerceOrderItemMutationVariables>(
    CreateCommerceOrderItemDocument,
    options
  );
}
export type CreateCommerceOrderItemMutationHookResult = ReturnType<typeof useCreateCommerceOrderItemMutation>;
export type CreateCommerceOrderItemMutationResult = Apollo.MutationResult<CreateCommerceOrderItemMutation>;
export type CreateCommerceOrderItemMutationOptions = Apollo.BaseMutationOptions<
  CreateCommerceOrderItemMutation,
  CreateCommerceOrderItemMutationVariables
>;
export const CreateCommerceShopDocument = gql`
  mutation createCommerceShop($data: CommerceShopCreateInput!) {
    createCommerceShop(data: $data) {
      ...commerceShop
    }
  }
  ${CommerceShopFragmentDoc}
`;
export type CreateCommerceShopMutationFn = Apollo.MutationFunction<
  CreateCommerceShopMutation,
  CreateCommerceShopMutationVariables
>;

/**
 * __useCreateCommerceShopMutation__
 *
 * To run a mutation, you first call `useCreateCommerceShopMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCommerceShopMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCommerceShopMutation, { data, loading, error }] = useCreateCommerceShopMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateCommerceShopMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCommerceShopMutation, CreateCommerceShopMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<CreateCommerceShopMutation, CreateCommerceShopMutationVariables>(
    CreateCommerceShopDocument,
    options
  );
}
export type CreateCommerceShopMutationHookResult = ReturnType<typeof useCreateCommerceShopMutation>;
export type CreateCommerceShopMutationResult = Apollo.MutationResult<CreateCommerceShopMutation>;
export type CreateCommerceShopMutationOptions = Apollo.BaseMutationOptions<
  CreateCommerceShopMutation,
  CreateCommerceShopMutationVariables
>;
export const CreateContentTranslationDocument = gql`
  mutation createContentTranslation($data: ContentTranslationCreateInput!) {
    createContentTranslation(data: $data) {
      ...contentTranslation
    }
  }
  ${ContentTranslationFragmentDoc}
`;
export type CreateContentTranslationMutationFn = Apollo.MutationFunction<
  CreateContentTranslationMutation,
  CreateContentTranslationMutationVariables
>;

/**
 * __useCreateContentTranslationMutation__
 *
 * To run a mutation, you first call `useCreateContentTranslationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContentTranslationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContentTranslationMutation, { data, loading, error }] = useCreateContentTranslationMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateContentTranslationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateContentTranslationMutation,
    CreateContentTranslationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<CreateContentTranslationMutation, CreateContentTranslationMutationVariables>(
    CreateContentTranslationDocument,
    options
  );
}
export type CreateContentTranslationMutationHookResult = ReturnType<typeof useCreateContentTranslationMutation>;
export type CreateContentTranslationMutationResult = Apollo.MutationResult<CreateContentTranslationMutation>;
export type CreateContentTranslationMutationOptions = Apollo.BaseMutationOptions<
  CreateContentTranslationMutation,
  CreateContentTranslationMutationVariables
>;
export const CreateDisputeDocument = gql`
  mutation createDispute($data: DisputeCreateInput!) {
    createDispute(data: $data) {
      ...dispute
    }
  }
  ${DisputeFragmentDoc}
`;
export type CreateDisputeMutationFn = Apollo.MutationFunction<CreateDisputeMutation, CreateDisputeMutationVariables>;

/**
 * __useCreateDisputeMutation__
 *
 * To run a mutation, you first call `useCreateDisputeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDisputeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDisputeMutation, { data, loading, error }] = useCreateDisputeMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateDisputeMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<CreateDisputeMutation, CreateDisputeMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<CreateDisputeMutation, CreateDisputeMutationVariables>(
    CreateDisputeDocument,
    options
  );
}
export type CreateDisputeMutationHookResult = ReturnType<typeof useCreateDisputeMutation>;
export type CreateDisputeMutationResult = Apollo.MutationResult<CreateDisputeMutation>;
export type CreateDisputeMutationOptions = Apollo.BaseMutationOptions<
  CreateDisputeMutation,
  CreateDisputeMutationVariables
>;
export const CreateDisputeDisputeResponseDocument = gql`
  mutation createDisputeDisputeResponse($data: DisputeResponseCreateInput!) {
    createDisputeDisputeResponse(data: $data) {
      ...disputeDisputeResponse
    }
  }
  ${DisputeDisputeResponseFragmentDoc}
`;
export type CreateDisputeDisputeResponseMutationFn = Apollo.MutationFunction<
  CreateDisputeDisputeResponseMutation,
  CreateDisputeDisputeResponseMutationVariables
>;

/**
 * __useCreateDisputeDisputeResponseMutation__
 *
 * To run a mutation, you first call `useCreateDisputeDisputeResponseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDisputeDisputeResponseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDisputeDisputeResponseMutation, { data, loading, error }] = useCreateDisputeDisputeResponseMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateDisputeDisputeResponseMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateDisputeDisputeResponseMutation,
    CreateDisputeDisputeResponseMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    CreateDisputeDisputeResponseMutation,
    CreateDisputeDisputeResponseMutationVariables
  >(CreateDisputeDisputeResponseDocument, options);
}
export type CreateDisputeDisputeResponseMutationHookResult = ReturnType<typeof useCreateDisputeDisputeResponseMutation>;
export type CreateDisputeDisputeResponseMutationResult = Apollo.MutationResult<CreateDisputeDisputeResponseMutation>;
export type CreateDisputeDisputeResponseMutationOptions = Apollo.BaseMutationOptions<
  CreateDisputeDisputeResponseMutation,
  CreateDisputeDisputeResponseMutationVariables
>;
export const CreateEventDocument = gql`
  mutation createEvent($data: EventCreateInput!) {
    createEvent(data: $data) {
      ...eventFull
    }
  }
  ${EventFullFragmentDoc}
`;
export type CreateEventMutationFn = Apollo.MutationFunction<CreateEventMutation, CreateEventMutationVariables>;

/**
 * __useCreateEventMutation__
 *
 * To run a mutation, you first call `useCreateEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEventMutation, { data, loading, error }] = useCreateEventMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateEventMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<CreateEventMutation, CreateEventMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<CreateEventMutation, CreateEventMutationVariables>(CreateEventDocument, options);
}
export type CreateEventMutationHookResult = ReturnType<typeof useCreateEventMutation>;
export type CreateEventMutationResult = Apollo.MutationResult<CreateEventMutation>;
export type CreateEventMutationOptions = Apollo.BaseMutationOptions<CreateEventMutation, CreateEventMutationVariables>;
export const CreateEventEventAccessDocument = gql`
  mutation createEventEventAccess($data: EventEventAccessCreateInput!) {
    createEventEventAccess(data: $data) {
      ...eventEventAccess
    }
  }
  ${EventEventAccessFragmentDoc}
`;
export type CreateEventEventAccessMutationFn = Apollo.MutationFunction<
  CreateEventEventAccessMutation,
  CreateEventEventAccessMutationVariables
>;

/**
 * __useCreateEventEventAccessMutation__
 *
 * To run a mutation, you first call `useCreateEventEventAccessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEventEventAccessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEventEventAccessMutation, { data, loading, error }] = useCreateEventEventAccessMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateEventEventAccessMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateEventEventAccessMutation,
    CreateEventEventAccessMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<CreateEventEventAccessMutation, CreateEventEventAccessMutationVariables>(
    CreateEventEventAccessDocument,
    options
  );
}
export type CreateEventEventAccessMutationHookResult = ReturnType<typeof useCreateEventEventAccessMutation>;
export type CreateEventEventAccessMutationResult = Apollo.MutationResult<CreateEventEventAccessMutation>;
export type CreateEventEventAccessMutationOptions = Apollo.BaseMutationOptions<
  CreateEventEventAccessMutation,
  CreateEventEventAccessMutationVariables
>;
export const CreateEventPlaceDocument = gql`
  mutation createEventPlace($data: EventPlaceCreateInput!) {
    createEventPlace(data: $data) {
      ...eventPlace
    }
  }
  ${EventPlaceFragmentDoc}
`;
export type CreateEventPlaceMutationFn = Apollo.MutationFunction<
  CreateEventPlaceMutation,
  CreateEventPlaceMutationVariables
>;

/**
 * __useCreateEventPlaceMutation__
 *
 * To run a mutation, you first call `useCreateEventPlaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEventPlaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEventPlaceMutation, { data, loading, error }] = useCreateEventPlaceMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateEventPlaceMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<CreateEventPlaceMutation, CreateEventPlaceMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<CreateEventPlaceMutation, CreateEventPlaceMutationVariables>(
    CreateEventPlaceDocument,
    options
  );
}
export type CreateEventPlaceMutationHookResult = ReturnType<typeof useCreateEventPlaceMutation>;
export type CreateEventPlaceMutationResult = Apollo.MutationResult<CreateEventPlaceMutation>;
export type CreateEventPlaceMutationOptions = Apollo.BaseMutationOptions<
  CreateEventPlaceMutation,
  CreateEventPlaceMutationVariables
>;
export const CreateEventReserveDocument = gql`
  mutation createEventReserve($data: EventReserveCreateInput!) {
    createEventReserve(data: $data) {
      ...eventReserve
    }
  }
  ${EventReserveFragmentDoc}
`;
export type CreateEventReserveMutationFn = Apollo.MutationFunction<
  CreateEventReserveMutation,
  CreateEventReserveMutationVariables
>;

/**
 * __useCreateEventReserveMutation__
 *
 * To run a mutation, you first call `useCreateEventReserveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEventReserveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEventReserveMutation, { data, loading, error }] = useCreateEventReserveMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateEventReserveMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<CreateEventReserveMutation, CreateEventReserveMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<CreateEventReserveMutation, CreateEventReserveMutationVariables>(
    CreateEventReserveDocument,
    options
  );
}
export type CreateEventReserveMutationHookResult = ReturnType<typeof useCreateEventReserveMutation>;
export type CreateEventReserveMutationResult = Apollo.MutationResult<CreateEventReserveMutation>;
export type CreateEventReserveMutationOptions = Apollo.BaseMutationOptions<
  CreateEventReserveMutation,
  CreateEventReserveMutationVariables
>;
export const CreateEventTypeDocument = gql`
  mutation createEventType($data: EventTypeCreateInput!) {
    createEventType(data: $data) {
      ...eventType
    }
  }
  ${EventTypeFragmentDoc}
`;
export type CreateEventTypeMutationFn = Apollo.MutationFunction<
  CreateEventTypeMutation,
  CreateEventTypeMutationVariables
>;

/**
 * __useCreateEventTypeMutation__
 *
 * To run a mutation, you first call `useCreateEventTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEventTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEventTypeMutation, { data, loading, error }] = useCreateEventTypeMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateEventTypeMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<CreateEventTypeMutation, CreateEventTypeMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<CreateEventTypeMutation, CreateEventTypeMutationVariables>(
    CreateEventTypeDocument,
    options
  );
}
export type CreateEventTypeMutationHookResult = ReturnType<typeof useCreateEventTypeMutation>;
export type CreateEventTypeMutationResult = Apollo.MutationResult<CreateEventTypeMutation>;
export type CreateEventTypeMutationOptions = Apollo.BaseMutationOptions<
  CreateEventTypeMutation,
  CreateEventTypeMutationVariables
>;
export const CreateFoodOrderDocument = gql`
  mutation createFoodOrder($data: FoodOrderCreateInput!) {
    createFoodOrder(data: $data) {
      id
      shopId
      clientId
      state
      paidAmount
      paymentState
      userId
      items {
        ...foodOrderItem
      }
      code
      total
    }
  }
  ${FoodOrderItemFragmentDoc}
`;
export type CreateFoodOrderMutationFn = Apollo.MutationFunction<
  CreateFoodOrderMutation,
  CreateFoodOrderMutationVariables
>;

/**
 * __useCreateFoodOrderMutation__
 *
 * To run a mutation, you first call `useCreateFoodOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFoodOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFoodOrderMutation, { data, loading, error }] = useCreateFoodOrderMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateFoodOrderMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<CreateFoodOrderMutation, CreateFoodOrderMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<CreateFoodOrderMutation, CreateFoodOrderMutationVariables>(
    CreateFoodOrderDocument,
    options
  );
}
export type CreateFoodOrderMutationHookResult = ReturnType<typeof useCreateFoodOrderMutation>;
export type CreateFoodOrderMutationResult = Apollo.MutationResult<CreateFoodOrderMutation>;
export type CreateFoodOrderMutationOptions = Apollo.BaseMutationOptions<
  CreateFoodOrderMutation,
  CreateFoodOrderMutationVariables
>;
export const CreateLoyaltyOrderDocument = gql`
  mutation createLoyaltyOrder($data: LoyaltyOrderCreateInput!) {
    createLoyaltyOrder(data: $data) {
      ...loyaltyOrder
    }
  }
  ${LoyaltyOrderFragmentDoc}
`;
export type CreateLoyaltyOrderMutationFn = Apollo.MutationFunction<
  CreateLoyaltyOrderMutation,
  CreateLoyaltyOrderMutationVariables
>;

/**
 * __useCreateLoyaltyOrderMutation__
 *
 * To run a mutation, you first call `useCreateLoyaltyOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLoyaltyOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLoyaltyOrderMutation, { data, loading, error }] = useCreateLoyaltyOrderMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateLoyaltyOrderMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<CreateLoyaltyOrderMutation, CreateLoyaltyOrderMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<CreateLoyaltyOrderMutation, CreateLoyaltyOrderMutationVariables>(
    CreateLoyaltyOrderDocument,
    options
  );
}
export type CreateLoyaltyOrderMutationHookResult = ReturnType<typeof useCreateLoyaltyOrderMutation>;
export type CreateLoyaltyOrderMutationResult = Apollo.MutationResult<CreateLoyaltyOrderMutation>;
export type CreateLoyaltyOrderMutationOptions = Apollo.BaseMutationOptions<
  CreateLoyaltyOrderMutation,
  CreateLoyaltyOrderMutationVariables
>;
export const CreateLoyaltyPartnerDocument = gql`
  mutation createLoyaltyPartner($data: LoyaltyPartnerCreateInput!) {
    createLoyaltyPartner(data: $data) {
      ...loyaltyPartner
    }
  }
  ${LoyaltyPartnerFragmentDoc}
`;
export type CreateLoyaltyPartnerMutationFn = Apollo.MutationFunction<
  CreateLoyaltyPartnerMutation,
  CreateLoyaltyPartnerMutationVariables
>;

/**
 * __useCreateLoyaltyPartnerMutation__
 *
 * To run a mutation, you first call `useCreateLoyaltyPartnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLoyaltyPartnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLoyaltyPartnerMutation, { data, loading, error }] = useCreateLoyaltyPartnerMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateLoyaltyPartnerMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateLoyaltyPartnerMutation,
    CreateLoyaltyPartnerMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<CreateLoyaltyPartnerMutation, CreateLoyaltyPartnerMutationVariables>(
    CreateLoyaltyPartnerDocument,
    options
  );
}
export type CreateLoyaltyPartnerMutationHookResult = ReturnType<typeof useCreateLoyaltyPartnerMutation>;
export type CreateLoyaltyPartnerMutationResult = Apollo.MutationResult<CreateLoyaltyPartnerMutation>;
export type CreateLoyaltyPartnerMutationOptions = Apollo.BaseMutationOptions<
  CreateLoyaltyPartnerMutation,
  CreateLoyaltyPartnerMutationVariables
>;
export const CreateLoyaltyPromoDocument = gql`
  mutation createLoyaltyPromo($data: LoyaltyPromoCreateInput!) {
    createLoyaltyPromo(data: $data) {
      ...loyaltyPromo
    }
  }
  ${LoyaltyPromoFragmentDoc}
`;
export type CreateLoyaltyPromoMutationFn = Apollo.MutationFunction<
  CreateLoyaltyPromoMutation,
  CreateLoyaltyPromoMutationVariables
>;

/**
 * __useCreateLoyaltyPromoMutation__
 *
 * To run a mutation, you first call `useCreateLoyaltyPromoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLoyaltyPromoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLoyaltyPromoMutation, { data, loading, error }] = useCreateLoyaltyPromoMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateLoyaltyPromoMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<CreateLoyaltyPromoMutation, CreateLoyaltyPromoMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<CreateLoyaltyPromoMutation, CreateLoyaltyPromoMutationVariables>(
    CreateLoyaltyPromoDocument,
    options
  );
}
export type CreateLoyaltyPromoMutationHookResult = ReturnType<typeof useCreateLoyaltyPromoMutation>;
export type CreateLoyaltyPromoMutationResult = Apollo.MutationResult<CreateLoyaltyPromoMutation>;
export type CreateLoyaltyPromoMutationOptions = Apollo.BaseMutationOptions<
  CreateLoyaltyPromoMutation,
  CreateLoyaltyPromoMutationVariables
>;
export const CreateLoyaltyPromoCodeDocument = gql`
  mutation createLoyaltyPromoCode($data: LoyaltyPromoCodeCreateInput!) {
    createLoyaltyPromoCode(data: $data) {
      ...loyaltyPromoCode
    }
  }
  ${LoyaltyPromoCodeFragmentDoc}
`;
export type CreateLoyaltyPromoCodeMutationFn = Apollo.MutationFunction<
  CreateLoyaltyPromoCodeMutation,
  CreateLoyaltyPromoCodeMutationVariables
>;

/**
 * __useCreateLoyaltyPromoCodeMutation__
 *
 * To run a mutation, you first call `useCreateLoyaltyPromoCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLoyaltyPromoCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLoyaltyPromoCodeMutation, { data, loading, error }] = useCreateLoyaltyPromoCodeMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateLoyaltyPromoCodeMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateLoyaltyPromoCodeMutation,
    CreateLoyaltyPromoCodeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<CreateLoyaltyPromoCodeMutation, CreateLoyaltyPromoCodeMutationVariables>(
    CreateLoyaltyPromoCodeDocument,
    options
  );
}
export type CreateLoyaltyPromoCodeMutationHookResult = ReturnType<typeof useCreateLoyaltyPromoCodeMutation>;
export type CreateLoyaltyPromoCodeMutationResult = Apollo.MutationResult<CreateLoyaltyPromoCodeMutation>;
export type CreateLoyaltyPromoCodeMutationOptions = Apollo.BaseMutationOptions<
  CreateLoyaltyPromoCodeMutation,
  CreateLoyaltyPromoCodeMutationVariables
>;
export const CreateMessengerMailingDocument = gql`
  mutation createMessengerMailing($data: MessengerMailingCreateInput!) {
    createMessengerMailing(data: $data) {
      ...messengerMailing
    }
  }
  ${MessengerMailingFragmentDoc}
`;
export type CreateMessengerMailingMutationFn = Apollo.MutationFunction<
  CreateMessengerMailingMutation,
  CreateMessengerMailingMutationVariables
>;

/**
 * __useCreateMessengerMailingMutation__
 *
 * To run a mutation, you first call `useCreateMessengerMailingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMessengerMailingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMessengerMailingMutation, { data, loading, error }] = useCreateMessengerMailingMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateMessengerMailingMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateMessengerMailingMutation,
    CreateMessengerMailingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<CreateMessengerMailingMutation, CreateMessengerMailingMutationVariables>(
    CreateMessengerMailingDocument,
    options
  );
}
export type CreateMessengerMailingMutationHookResult = ReturnType<typeof useCreateMessengerMailingMutation>;
export type CreateMessengerMailingMutationResult = Apollo.MutationResult<CreateMessengerMailingMutation>;
export type CreateMessengerMailingMutationOptions = Apollo.BaseMutationOptions<
  CreateMessengerMailingMutation,
  CreateMessengerMailingMutationVariables
>;
export const CreateMessengerQueueNotificationDocument = gql`
  mutation createMessengerQueueNotification($data: MessengerQueueNotificationCreateInput!) {
    createMessengerQueueNotification(data: $data) {
      ...messengerQueueNotification
    }
  }
  ${MessengerQueueNotificationFragmentDoc}
`;
export type CreateMessengerQueueNotificationMutationFn = Apollo.MutationFunction<
  CreateMessengerQueueNotificationMutation,
  CreateMessengerQueueNotificationMutationVariables
>;

/**
 * __useCreateMessengerQueueNotificationMutation__
 *
 * To run a mutation, you first call `useCreateMessengerQueueNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMessengerQueueNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMessengerQueueNotificationMutation, { data, loading, error }] = useCreateMessengerQueueNotificationMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateMessengerQueueNotificationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateMessengerQueueNotificationMutation,
    CreateMessengerQueueNotificationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    CreateMessengerQueueNotificationMutation,
    CreateMessengerQueueNotificationMutationVariables
  >(CreateMessengerQueueNotificationDocument, options);
}
export type CreateMessengerQueueNotificationMutationHookResult = ReturnType<
  typeof useCreateMessengerQueueNotificationMutation
>;
export type CreateMessengerQueueNotificationMutationResult = Apollo.MutationResult<CreateMessengerQueueNotificationMutation>;
export type CreateMessengerQueueNotificationMutationOptions = Apollo.BaseMutationOptions<
  CreateMessengerQueueNotificationMutation,
  CreateMessengerQueueNotificationMutationVariables
>;
export const CreateMessengerQueueTaskDocument = gql`
  mutation createMessengerQueueTask($data: MessengerQueueTaskCreateInput!) {
    createMessengerQueueTask(data: $data) {
      ...messengerQueueTask
    }
  }
  ${MessengerQueueTaskFragmentDoc}
`;
export type CreateMessengerQueueTaskMutationFn = Apollo.MutationFunction<
  CreateMessengerQueueTaskMutation,
  CreateMessengerQueueTaskMutationVariables
>;

/**
 * __useCreateMessengerQueueTaskMutation__
 *
 * To run a mutation, you first call `useCreateMessengerQueueTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMessengerQueueTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMessengerQueueTaskMutation, { data, loading, error }] = useCreateMessengerQueueTaskMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateMessengerQueueTaskMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateMessengerQueueTaskMutation,
    CreateMessengerQueueTaskMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<CreateMessengerQueueTaskMutation, CreateMessengerQueueTaskMutationVariables>(
    CreateMessengerQueueTaskDocument,
    options
  );
}
export type CreateMessengerQueueTaskMutationHookResult = ReturnType<typeof useCreateMessengerQueueTaskMutation>;
export type CreateMessengerQueueTaskMutationResult = Apollo.MutationResult<CreateMessengerQueueTaskMutation>;
export type CreateMessengerQueueTaskMutationOptions = Apollo.BaseMutationOptions<
  CreateMessengerQueueTaskMutation,
  CreateMessengerQueueTaskMutationVariables
>;
export const CreateMessengerTransportDocument = gql`
  mutation createMessengerTransport($data: MessengerTransportCreateInput!) {
    createMessengerTransport(data: $data) {
      ...messengerTransport
    }
  }
  ${MessengerTransportFragmentDoc}
`;
export type CreateMessengerTransportMutationFn = Apollo.MutationFunction<
  CreateMessengerTransportMutation,
  CreateMessengerTransportMutationVariables
>;

/**
 * __useCreateMessengerTransportMutation__
 *
 * To run a mutation, you first call `useCreateMessengerTransportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMessengerTransportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMessengerTransportMutation, { data, loading, error }] = useCreateMessengerTransportMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateMessengerTransportMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateMessengerTransportMutation,
    CreateMessengerTransportMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<CreateMessengerTransportMutation, CreateMessengerTransportMutationVariables>(
    CreateMessengerTransportDocument,
    options
  );
}
export type CreateMessengerTransportMutationHookResult = ReturnType<typeof useCreateMessengerTransportMutation>;
export type CreateMessengerTransportMutationResult = Apollo.MutationResult<CreateMessengerTransportMutation>;
export type CreateMessengerTransportMutationOptions = Apollo.BaseMutationOptions<
  CreateMessengerTransportMutation,
  CreateMessengerTransportMutationVariables
>;
export const CreateMyEventOrderDocument = gql`
  mutation createMyEventOrder($eventId: Int!, $payload: EventOrderPayloadInput!) {
    createMyEventOrder(eventId: $eventId, payload: $payload) {
      ...eventOrder
    }
  }
  ${EventOrderFragmentDoc}
`;
export type CreateMyEventOrderMutationFn = Apollo.MutationFunction<
  CreateMyEventOrderMutation,
  CreateMyEventOrderMutationVariables
>;

/**
 * __useCreateMyEventOrderMutation__
 *
 * To run a mutation, you first call `useCreateMyEventOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMyEventOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMyEventOrderMutation, { data, loading, error }] = useCreateMyEventOrderMutation({
 *   variables: {
 *      eventId: // value for 'eventId'
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useCreateMyEventOrderMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<CreateMyEventOrderMutation, CreateMyEventOrderMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<CreateMyEventOrderMutation, CreateMyEventOrderMutationVariables>(
    CreateMyEventOrderDocument,
    options
  );
}
export type CreateMyEventOrderMutationHookResult = ReturnType<typeof useCreateMyEventOrderMutation>;
export type CreateMyEventOrderMutationResult = Apollo.MutationResult<CreateMyEventOrderMutation>;
export type CreateMyEventOrderMutationOptions = Apollo.BaseMutationOptions<
  CreateMyEventOrderMutation,
  CreateMyEventOrderMutationVariables
>;
export const CreateMyEventReserveDocument = gql`
  mutation createMyEventReserve(
    $eventId: Int!
    $sectorId: Int!
    $seatId: Int
    $priceCategoryId: String
    $fanCode: String
    $fanGUID: String
  ) {
    createMyEventReserve(
      eventId: $eventId
      sectorId: $sectorId
      seatId: $seatId
      priceCategoryId: $priceCategoryId
      fanCode: $fanCode
      fanGUID: $fanGUID
    ) {
      ...reserve
    }
  }
  ${ReserveFragmentDoc}
`;
export type CreateMyEventReserveMutationFn = Apollo.MutationFunction<
  CreateMyEventReserveMutation,
  CreateMyEventReserveMutationVariables
>;

/**
 * __useCreateMyEventReserveMutation__
 *
 * To run a mutation, you first call `useCreateMyEventReserveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMyEventReserveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMyEventReserveMutation, { data, loading, error }] = useCreateMyEventReserveMutation({
 *   variables: {
 *      eventId: // value for 'eventId'
 *      sectorId: // value for 'sectorId'
 *      seatId: // value for 'seatId'
 *      priceCategoryId: // value for 'priceCategoryId'
 *      fanCode: // value for 'fanCode'
 *      fanGUID: // value for 'fanGUID'
 *   },
 * });
 */
export function useCreateMyEventReserveMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateMyEventReserveMutation,
    CreateMyEventReserveMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<CreateMyEventReserveMutation, CreateMyEventReserveMutationVariables>(
    CreateMyEventReserveDocument,
    options
  );
}
export type CreateMyEventReserveMutationHookResult = ReturnType<typeof useCreateMyEventReserveMutation>;
export type CreateMyEventReserveMutationResult = Apollo.MutationResult<CreateMyEventReserveMutation>;
export type CreateMyEventReserveMutationOptions = Apollo.BaseMutationOptions<
  CreateMyEventReserveMutation,
  CreateMyEventReserveMutationVariables
>;
export const CreatePaymentAccountDocument = gql`
  mutation createPaymentAccount($data: PaymentAccountCreateInput!) {
    createPaymentAccount(data: $data) {
      ...paymentAccount
    }
  }
  ${PaymentAccountFragmentDoc}
`;
export type CreatePaymentAccountMutationFn = Apollo.MutationFunction<
  CreatePaymentAccountMutation,
  CreatePaymentAccountMutationVariables
>;

/**
 * __useCreatePaymentAccountMutation__
 *
 * To run a mutation, you first call `useCreatePaymentAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePaymentAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPaymentAccountMutation, { data, loading, error }] = useCreatePaymentAccountMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreatePaymentAccountMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreatePaymentAccountMutation,
    CreatePaymentAccountMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<CreatePaymentAccountMutation, CreatePaymentAccountMutationVariables>(
    CreatePaymentAccountDocument,
    options
  );
}
export type CreatePaymentAccountMutationHookResult = ReturnType<typeof useCreatePaymentAccountMutation>;
export type CreatePaymentAccountMutationResult = Apollo.MutationResult<CreatePaymentAccountMutation>;
export type CreatePaymentAccountMutationOptions = Apollo.BaseMutationOptions<
  CreatePaymentAccountMutation,
  CreatePaymentAccountMutationVariables
>;
export const CreatePaymentAccountByApplicationCodeDocument = gql`
  mutation createPaymentAccountByApplicationCode($userId: Int!, $partnerId: Int!, $providerCode: String!) {
    createPaymentAccountByApplicationCode(userId: $userId, partnerId: $partnerId, providerCode: $providerCode) {
      ...paymentAccount
    }
  }
  ${PaymentAccountFragmentDoc}
`;
export type CreatePaymentAccountByApplicationCodeMutationFn = Apollo.MutationFunction<
  CreatePaymentAccountByApplicationCodeMutation,
  CreatePaymentAccountByApplicationCodeMutationVariables
>;

/**
 * __useCreatePaymentAccountByApplicationCodeMutation__
 *
 * To run a mutation, you first call `useCreatePaymentAccountByApplicationCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePaymentAccountByApplicationCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPaymentAccountByApplicationCodeMutation, { data, loading, error }] = useCreatePaymentAccountByApplicationCodeMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      partnerId: // value for 'partnerId'
 *      providerCode: // value for 'providerCode'
 *   },
 * });
 */
export function useCreatePaymentAccountByApplicationCodeMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreatePaymentAccountByApplicationCodeMutation,
    CreatePaymentAccountByApplicationCodeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    CreatePaymentAccountByApplicationCodeMutation,
    CreatePaymentAccountByApplicationCodeMutationVariables
  >(CreatePaymentAccountByApplicationCodeDocument, options);
}
export type CreatePaymentAccountByApplicationCodeMutationHookResult = ReturnType<
  typeof useCreatePaymentAccountByApplicationCodeMutation
>;
export type CreatePaymentAccountByApplicationCodeMutationResult = Apollo.MutationResult<CreatePaymentAccountByApplicationCodeMutation>;
export type CreatePaymentAccountByApplicationCodeMutationOptions = Apollo.BaseMutationOptions<
  CreatePaymentAccountByApplicationCodeMutation,
  CreatePaymentAccountByApplicationCodeMutationVariables
>;
export const CreatePaymentCurrencyDocument = gql`
  mutation createPaymentCurrency($data: PaymentCurrencyCreateInput!) {
    createPaymentCurrency(data: $data) {
      ...paymentCurrency
    }
  }
  ${PaymentCurrencyFragmentDoc}
`;
export type CreatePaymentCurrencyMutationFn = Apollo.MutationFunction<
  CreatePaymentCurrencyMutation,
  CreatePaymentCurrencyMutationVariables
>;

/**
 * __useCreatePaymentCurrencyMutation__
 *
 * To run a mutation, you first call `useCreatePaymentCurrencyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePaymentCurrencyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPaymentCurrencyMutation, { data, loading, error }] = useCreatePaymentCurrencyMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreatePaymentCurrencyMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreatePaymentCurrencyMutation,
    CreatePaymentCurrencyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<CreatePaymentCurrencyMutation, CreatePaymentCurrencyMutationVariables>(
    CreatePaymentCurrencyDocument,
    options
  );
}
export type CreatePaymentCurrencyMutationHookResult = ReturnType<typeof useCreatePaymentCurrencyMutation>;
export type CreatePaymentCurrencyMutationResult = Apollo.MutationResult<CreatePaymentCurrencyMutation>;
export type CreatePaymentCurrencyMutationOptions = Apollo.BaseMutationOptions<
  CreatePaymentCurrencyMutation,
  CreatePaymentCurrencyMutationVariables
>;
export const CreatePaymentProviderDocument = gql`
  mutation createPaymentProvider($data: PaymentProviderCreateInput!) {
    createPaymentProvider(data: $data) {
      ...paymentProvider
    }
  }
  ${PaymentProviderFragmentDoc}
`;
export type CreatePaymentProviderMutationFn = Apollo.MutationFunction<
  CreatePaymentProviderMutation,
  CreatePaymentProviderMutationVariables
>;

/**
 * __useCreatePaymentProviderMutation__
 *
 * To run a mutation, you first call `useCreatePaymentProviderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePaymentProviderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPaymentProviderMutation, { data, loading, error }] = useCreatePaymentProviderMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreatePaymentProviderMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreatePaymentProviderMutation,
    CreatePaymentProviderMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<CreatePaymentProviderMutation, CreatePaymentProviderMutationVariables>(
    CreatePaymentProviderDocument,
    options
  );
}
export type CreatePaymentProviderMutationHookResult = ReturnType<typeof useCreatePaymentProviderMutation>;
export type CreatePaymentProviderMutationResult = Apollo.MutationResult<CreatePaymentProviderMutation>;
export type CreatePaymentProviderMutationOptions = Apollo.BaseMutationOptions<
  CreatePaymentProviderMutation,
  CreatePaymentProviderMutationVariables
>;
export const CreatePaymentTransactionDocument = gql`
  mutation createPaymentTransaction($data: PaymentTransactionCreateInput!) {
    createPaymentTransaction(data: $data) {
      ...paymentTransaction
    }
  }
  ${PaymentTransactionFragmentDoc}
`;
export type CreatePaymentTransactionMutationFn = Apollo.MutationFunction<
  CreatePaymentTransactionMutation,
  CreatePaymentTransactionMutationVariables
>;

/**
 * __useCreatePaymentTransactionMutation__
 *
 * To run a mutation, you first call `useCreatePaymentTransactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePaymentTransactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPaymentTransactionMutation, { data, loading, error }] = useCreatePaymentTransactionMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreatePaymentTransactionMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreatePaymentTransactionMutation,
    CreatePaymentTransactionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<CreatePaymentTransactionMutation, CreatePaymentTransactionMutationVariables>(
    CreatePaymentTransactionDocument,
    options
  );
}
export type CreatePaymentTransactionMutationHookResult = ReturnType<typeof useCreatePaymentTransactionMutation>;
export type CreatePaymentTransactionMutationResult = Apollo.MutationResult<CreatePaymentTransactionMutation>;
export type CreatePaymentTransactionMutationOptions = Apollo.BaseMutationOptions<
  CreatePaymentTransactionMutation,
  CreatePaymentTransactionMutationVariables
>;
export const CreateSystemPrinterDocument = gql`
  mutation createSystemPrinter($data: SystemPrinterCreateInput!) {
    createSystemPrinter(data: $data) {
      ...systemPrinter
    }
  }
  ${SystemPrinterFragmentDoc}
`;
export type CreateSystemPrinterMutationFn = Apollo.MutationFunction<
  CreateSystemPrinterMutation,
  CreateSystemPrinterMutationVariables
>;

/**
 * __useCreateSystemPrinterMutation__
 *
 * To run a mutation, you first call `useCreateSystemPrinterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSystemPrinterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSystemPrinterMutation, { data, loading, error }] = useCreateSystemPrinterMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateSystemPrinterMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSystemPrinterMutation, CreateSystemPrinterMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<CreateSystemPrinterMutation, CreateSystemPrinterMutationVariables>(
    CreateSystemPrinterDocument,
    options
  );
}
export type CreateSystemPrinterMutationHookResult = ReturnType<typeof useCreateSystemPrinterMutation>;
export type CreateSystemPrinterMutationResult = Apollo.MutationResult<CreateSystemPrinterMutation>;
export type CreateSystemPrinterMutationOptions = Apollo.BaseMutationOptions<
  CreateSystemPrinterMutation,
  CreateSystemPrinterMutationVariables
>;
export const CreateSystemTranslationDocument = gql`
  mutation createSystemTranslation($data: SystemTranslationCreateInput!) {
    createSystemTranslation(data: $data) {
      ...systemTranslation
    }
  }
  ${SystemTranslationFragmentDoc}
`;
export type CreateSystemTranslationMutationFn = Apollo.MutationFunction<
  CreateSystemTranslationMutation,
  CreateSystemTranslationMutationVariables
>;

/**
 * __useCreateSystemTranslationMutation__
 *
 * To run a mutation, you first call `useCreateSystemTranslationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSystemTranslationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSystemTranslationMutation, { data, loading, error }] = useCreateSystemTranslationMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateSystemTranslationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateSystemTranslationMutation,
    CreateSystemTranslationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<CreateSystemTranslationMutation, CreateSystemTranslationMutationVariables>(
    CreateSystemTranslationDocument,
    options
  );
}
export type CreateSystemTranslationMutationHookResult = ReturnType<typeof useCreateSystemTranslationMutation>;
export type CreateSystemTranslationMutationResult = Apollo.MutationResult<CreateSystemTranslationMutation>;
export type CreateSystemTranslationMutationOptions = Apollo.BaseMutationOptions<
  CreateSystemTranslationMutation,
  CreateSystemTranslationMutationVariables
>;
export const CreateSystemUserDocument = gql`
  mutation createSystemUser($data: CreateSystemUserInput!) {
    createSystemUser(data: $data) {
      ...systemUser
    }
  }
  ${SystemUserFragmentDoc}
`;
export type CreateSystemUserMutationFn = Apollo.MutationFunction<
  CreateSystemUserMutation,
  CreateSystemUserMutationVariables
>;

/**
 * __useCreateSystemUserMutation__
 *
 * To run a mutation, you first call `useCreateSystemUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSystemUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSystemUserMutation, { data, loading, error }] = useCreateSystemUserMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateSystemUserMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSystemUserMutation, CreateSystemUserMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<CreateSystemUserMutation, CreateSystemUserMutationVariables>(
    CreateSystemUserDocument,
    options
  );
}
export type CreateSystemUserMutationHookResult = ReturnType<typeof useCreateSystemUserMutation>;
export type CreateSystemUserMutationResult = Apollo.MutationResult<CreateSystemUserMutation>;
export type CreateSystemUserMutationOptions = Apollo.BaseMutationOptions<
  CreateSystemUserMutation,
  CreateSystemUserMutationVariables
>;
export const CreateSystemWebHookDocument = gql`
  mutation createSystemWebHook($data: SystemWebHookCreateInput!) {
    createSystemWebHook(data: $data) {
      ...systemWebHook
    }
  }
  ${SystemWebHookFragmentDoc}
`;
export type CreateSystemWebHookMutationFn = Apollo.MutationFunction<
  CreateSystemWebHookMutation,
  CreateSystemWebHookMutationVariables
>;

/**
 * __useCreateSystemWebHookMutation__
 *
 * To run a mutation, you first call `useCreateSystemWebHookMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSystemWebHookMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSystemWebHookMutation, { data, loading, error }] = useCreateSystemWebHookMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateSystemWebHookMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSystemWebHookMutation, CreateSystemWebHookMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<CreateSystemWebHookMutation, CreateSystemWebHookMutationVariables>(
    CreateSystemWebHookDocument,
    options
  );
}
export type CreateSystemWebHookMutationHookResult = ReturnType<typeof useCreateSystemWebHookMutation>;
export type CreateSystemWebHookMutationResult = Apollo.MutationResult<CreateSystemWebHookMutation>;
export type CreateSystemWebHookMutationOptions = Apollo.BaseMutationOptions<
  CreateSystemWebHookMutation,
  CreateSystemWebHookMutationVariables
>;
export const CreateTemplateApplePassDocument = gql`
  mutation createTemplateApplePass($data: TemplateApplePassCreateInput!) {
    createTemplateApplePass(data: $data) {
      ...templateApplePass
    }
  }
  ${TemplateApplePassFragmentDoc}
`;
export type CreateTemplateApplePassMutationFn = Apollo.MutationFunction<
  CreateTemplateApplePassMutation,
  CreateTemplateApplePassMutationVariables
>;

/**
 * __useCreateTemplateApplePassMutation__
 *
 * To run a mutation, you first call `useCreateTemplateApplePassMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTemplateApplePassMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTemplateApplePassMutation, { data, loading, error }] = useCreateTemplateApplePassMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateTemplateApplePassMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateTemplateApplePassMutation,
    CreateTemplateApplePassMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<CreateTemplateApplePassMutation, CreateTemplateApplePassMutationVariables>(
    CreateTemplateApplePassDocument,
    options
  );
}
export type CreateTemplateApplePassMutationHookResult = ReturnType<typeof useCreateTemplateApplePassMutation>;
export type CreateTemplateApplePassMutationResult = Apollo.MutationResult<CreateTemplateApplePassMutation>;
export type CreateTemplateApplePassMutationOptions = Apollo.BaseMutationOptions<
  CreateTemplateApplePassMutation,
  CreateTemplateApplePassMutationVariables
>;
export const CreateTemplateNotificationDocument = gql`
  mutation createTemplateNotification($data: TemplateNotificationCreateInput!) {
    createTemplateNotification(data: $data) {
      ...templateNotification
    }
  }
  ${TemplateNotificationFragmentDoc}
`;
export type CreateTemplateNotificationMutationFn = Apollo.MutationFunction<
  CreateTemplateNotificationMutation,
  CreateTemplateNotificationMutationVariables
>;

/**
 * __useCreateTemplateNotificationMutation__
 *
 * To run a mutation, you first call `useCreateTemplateNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTemplateNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTemplateNotificationMutation, { data, loading, error }] = useCreateTemplateNotificationMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateTemplateNotificationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateTemplateNotificationMutation,
    CreateTemplateNotificationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<CreateTemplateNotificationMutation, CreateTemplateNotificationMutationVariables>(
    CreateTemplateNotificationDocument,
    options
  );
}
export type CreateTemplateNotificationMutationHookResult = ReturnType<typeof useCreateTemplateNotificationMutation>;
export type CreateTemplateNotificationMutationResult = Apollo.MutationResult<CreateTemplateNotificationMutation>;
export type CreateTemplateNotificationMutationOptions = Apollo.BaseMutationOptions<
  CreateTemplateNotificationMutation,
  CreateTemplateNotificationMutationVariables
>;
export const CreateTemplatePdfDocument = gql`
  mutation createTemplatePDF($data: TemplatePDFCreateInput!) {
    createTemplatePDF(data: $data) {
      ...templatePDF
    }
  }
  ${TemplatePdfFragmentDoc}
`;
export type CreateTemplatePdfMutationFn = Apollo.MutationFunction<
  CreateTemplatePdfMutation,
  CreateTemplatePdfMutationVariables
>;

/**
 * __useCreateTemplatePdfMutation__
 *
 * To run a mutation, you first call `useCreateTemplatePdfMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTemplatePdfMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTemplatePdfMutation, { data, loading, error }] = useCreateTemplatePdfMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateTemplatePdfMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<CreateTemplatePdfMutation, CreateTemplatePdfMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<CreateTemplatePdfMutation, CreateTemplatePdfMutationVariables>(
    CreateTemplatePdfDocument,
    options
  );
}
export type CreateTemplatePdfMutationHookResult = ReturnType<typeof useCreateTemplatePdfMutation>;
export type CreateTemplatePdfMutationResult = Apollo.MutationResult<CreateTemplatePdfMutation>;
export type CreateTemplatePdfMutationOptions = Apollo.BaseMutationOptions<
  CreateTemplatePdfMutation,
  CreateTemplatePdfMutationVariables
>;
export const CreateTournamentDocument = gql`
  mutation createTournament($data: TournamentCreateInput!) {
    createTournament(data: $data) {
      ...tournamentFull
    }
  }
  ${TournamentFullFragmentDoc}
`;
export type CreateTournamentMutationFn = Apollo.MutationFunction<
  CreateTournamentMutation,
  CreateTournamentMutationVariables
>;

/**
 * __useCreateTournamentMutation__
 *
 * To run a mutation, you first call `useCreateTournamentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTournamentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTournamentMutation, { data, loading, error }] = useCreateTournamentMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateTournamentMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<CreateTournamentMutation, CreateTournamentMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<CreateTournamentMutation, CreateTournamentMutationVariables>(
    CreateTournamentDocument,
    options
  );
}
export type CreateTournamentMutationHookResult = ReturnType<typeof useCreateTournamentMutation>;
export type CreateTournamentMutationResult = Apollo.MutationResult<CreateTournamentMutation>;
export type CreateTournamentMutationOptions = Apollo.BaseMutationOptions<
  CreateTournamentMutation,
  CreateTournamentMutationVariables
>;
export const CreateTournamentDisqualificationDocument = gql`
  mutation createTournamentDisqualification($data: TournamentDisqualificationCreateInput!) {
    createTournamentDisqualification(data: $data) {
      ...tournamentDisqualification
    }
  }
  ${TournamentDisqualificationFragmentDoc}
`;
export type CreateTournamentDisqualificationMutationFn = Apollo.MutationFunction<
  CreateTournamentDisqualificationMutation,
  CreateTournamentDisqualificationMutationVariables
>;

/**
 * __useCreateTournamentDisqualificationMutation__
 *
 * To run a mutation, you first call `useCreateTournamentDisqualificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTournamentDisqualificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTournamentDisqualificationMutation, { data, loading, error }] = useCreateTournamentDisqualificationMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateTournamentDisqualificationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateTournamentDisqualificationMutation,
    CreateTournamentDisqualificationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    CreateTournamentDisqualificationMutation,
    CreateTournamentDisqualificationMutationVariables
  >(CreateTournamentDisqualificationDocument, options);
}
export type CreateTournamentDisqualificationMutationHookResult = ReturnType<
  typeof useCreateTournamentDisqualificationMutation
>;
export type CreateTournamentDisqualificationMutationResult = Apollo.MutationResult<CreateTournamentDisqualificationMutation>;
export type CreateTournamentDisqualificationMutationOptions = Apollo.BaseMutationOptions<
  CreateTournamentDisqualificationMutation,
  CreateTournamentDisqualificationMutationVariables
>;
export const CreateTournamentMatchDocument = gql`
  mutation createTournamentMatch($data: TournamentMatchCreateInput!) {
    createTournamentMatch(data: $data) {
      ...tournamentMatch
    }
  }
  ${TournamentMatchFragmentDoc}
`;
export type CreateTournamentMatchMutationFn = Apollo.MutationFunction<
  CreateTournamentMatchMutation,
  CreateTournamentMatchMutationVariables
>;

/**
 * __useCreateTournamentMatchMutation__
 *
 * To run a mutation, you first call `useCreateTournamentMatchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTournamentMatchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTournamentMatchMutation, { data, loading, error }] = useCreateTournamentMatchMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateTournamentMatchMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateTournamentMatchMutation,
    CreateTournamentMatchMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<CreateTournamentMatchMutation, CreateTournamentMatchMutationVariables>(
    CreateTournamentMatchDocument,
    options
  );
}
export type CreateTournamentMatchMutationHookResult = ReturnType<typeof useCreateTournamentMatchMutation>;
export type CreateTournamentMatchMutationResult = Apollo.MutationResult<CreateTournamentMatchMutation>;
export type CreateTournamentMatchMutationOptions = Apollo.BaseMutationOptions<
  CreateTournamentMatchMutation,
  CreateTournamentMatchMutationVariables
>;
export const CreateTournamentMatchEventDocument = gql`
  mutation createTournamentMatchEvent($data: TournamentMatchEventCreateInput!) {
    createTournamentMatchEvent(data: $data) {
      ...tournamentMatchEvent
    }
  }
  ${TournamentMatchEventFragmentDoc}
`;
export type CreateTournamentMatchEventMutationFn = Apollo.MutationFunction<
  CreateTournamentMatchEventMutation,
  CreateTournamentMatchEventMutationVariables
>;

/**
 * __useCreateTournamentMatchEventMutation__
 *
 * To run a mutation, you first call `useCreateTournamentMatchEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTournamentMatchEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTournamentMatchEventMutation, { data, loading, error }] = useCreateTournamentMatchEventMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateTournamentMatchEventMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateTournamentMatchEventMutation,
    CreateTournamentMatchEventMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<CreateTournamentMatchEventMutation, CreateTournamentMatchEventMutationVariables>(
    CreateTournamentMatchEventDocument,
    options
  );
}
export type CreateTournamentMatchEventMutationHookResult = ReturnType<typeof useCreateTournamentMatchEventMutation>;
export type CreateTournamentMatchEventMutationResult = Apollo.MutationResult<CreateTournamentMatchEventMutation>;
export type CreateTournamentMatchEventMutationOptions = Apollo.BaseMutationOptions<
  CreateTournamentMatchEventMutation,
  CreateTournamentMatchEventMutationVariables
>;
export const CreateTournamentMatchLineUpDocument = gql`
  mutation createTournamentMatchLineUp($data: TournamentMatchLineUpCreateInput!) {
    createTournamentMatchLineUp(data: $data) {
      ...tournamentMatchLineUp
    }
  }
  ${TournamentMatchLineUpFragmentDoc}
`;
export type CreateTournamentMatchLineUpMutationFn = Apollo.MutationFunction<
  CreateTournamentMatchLineUpMutation,
  CreateTournamentMatchLineUpMutationVariables
>;

/**
 * __useCreateTournamentMatchLineUpMutation__
 *
 * To run a mutation, you first call `useCreateTournamentMatchLineUpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTournamentMatchLineUpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTournamentMatchLineUpMutation, { data, loading, error }] = useCreateTournamentMatchLineUpMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateTournamentMatchLineUpMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateTournamentMatchLineUpMutation,
    CreateTournamentMatchLineUpMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    CreateTournamentMatchLineUpMutation,
    CreateTournamentMatchLineUpMutationVariables
  >(CreateTournamentMatchLineUpDocument, options);
}
export type CreateTournamentMatchLineUpMutationHookResult = ReturnType<typeof useCreateTournamentMatchLineUpMutation>;
export type CreateTournamentMatchLineUpMutationResult = Apollo.MutationResult<CreateTournamentMatchLineUpMutation>;
export type CreateTournamentMatchLineUpMutationOptions = Apollo.BaseMutationOptions<
  CreateTournamentMatchLineUpMutation,
  CreateTournamentMatchLineUpMutationVariables
>;
export const CreateTournamentMatchProtocolDocument = gql`
  mutation createTournamentMatchProtocol($data: TournamentMatchProtocolCreateInput!) {
    createTournamentMatchProtocol(data: $data) {
      ...tournamentMatchProtocol
    }
  }
  ${TournamentMatchProtocolFragmentDoc}
`;
export type CreateTournamentMatchProtocolMutationFn = Apollo.MutationFunction<
  CreateTournamentMatchProtocolMutation,
  CreateTournamentMatchProtocolMutationVariables
>;

/**
 * __useCreateTournamentMatchProtocolMutation__
 *
 * To run a mutation, you first call `useCreateTournamentMatchProtocolMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTournamentMatchProtocolMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTournamentMatchProtocolMutation, { data, loading, error }] = useCreateTournamentMatchProtocolMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateTournamentMatchProtocolMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateTournamentMatchProtocolMutation,
    CreateTournamentMatchProtocolMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    CreateTournamentMatchProtocolMutation,
    CreateTournamentMatchProtocolMutationVariables
  >(CreateTournamentMatchProtocolDocument, options);
}
export type CreateTournamentMatchProtocolMutationHookResult = ReturnType<
  typeof useCreateTournamentMatchProtocolMutation
>;
export type CreateTournamentMatchProtocolMutationResult = Apollo.MutationResult<CreateTournamentMatchProtocolMutation>;
export type CreateTournamentMatchProtocolMutationOptions = Apollo.BaseMutationOptions<
  CreateTournamentMatchProtocolMutation,
  CreateTournamentMatchProtocolMutationVariables
>;
export const CreateTournamentPlayerStatDocument = gql`
  mutation createTournamentPlayerStat($data: TournamentPlayerStatCreateInput!) {
    createTournamentPlayerStat(data: $data) {
      ...tournamentPlayerStat
    }
  }
  ${TournamentPlayerStatFragmentDoc}
`;
export type CreateTournamentPlayerStatMutationFn = Apollo.MutationFunction<
  CreateTournamentPlayerStatMutation,
  CreateTournamentPlayerStatMutationVariables
>;

/**
 * __useCreateTournamentPlayerStatMutation__
 *
 * To run a mutation, you first call `useCreateTournamentPlayerStatMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTournamentPlayerStatMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTournamentPlayerStatMutation, { data, loading, error }] = useCreateTournamentPlayerStatMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateTournamentPlayerStatMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateTournamentPlayerStatMutation,
    CreateTournamentPlayerStatMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<CreateTournamentPlayerStatMutation, CreateTournamentPlayerStatMutationVariables>(
    CreateTournamentPlayerStatDocument,
    options
  );
}
export type CreateTournamentPlayerStatMutationHookResult = ReturnType<typeof useCreateTournamentPlayerStatMutation>;
export type CreateTournamentPlayerStatMutationResult = Apollo.MutationResult<CreateTournamentPlayerStatMutation>;
export type CreateTournamentPlayerStatMutationOptions = Apollo.BaseMutationOptions<
  CreateTournamentPlayerStatMutation,
  CreateTournamentPlayerStatMutationVariables
>;
export const CreateTournamentRefereeDocument = gql`
  mutation createTournamentReferee($data: TournamentRefereeCreateInput!) {
    createTournamentReferee(data: $data) {
      ...tournamentReferee
    }
  }
  ${TournamentRefereeFragmentDoc}
`;
export type CreateTournamentRefereeMutationFn = Apollo.MutationFunction<
  CreateTournamentRefereeMutation,
  CreateTournamentRefereeMutationVariables
>;

/**
 * __useCreateTournamentRefereeMutation__
 *
 * To run a mutation, you first call `useCreateTournamentRefereeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTournamentRefereeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTournamentRefereeMutation, { data, loading, error }] = useCreateTournamentRefereeMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateTournamentRefereeMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateTournamentRefereeMutation,
    CreateTournamentRefereeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<CreateTournamentRefereeMutation, CreateTournamentRefereeMutationVariables>(
    CreateTournamentRefereeDocument,
    options
  );
}
export type CreateTournamentRefereeMutationHookResult = ReturnType<typeof useCreateTournamentRefereeMutation>;
export type CreateTournamentRefereeMutationResult = Apollo.MutationResult<CreateTournamentRefereeMutation>;
export type CreateTournamentRefereeMutationOptions = Apollo.BaseMutationOptions<
  CreateTournamentRefereeMutation,
  CreateTournamentRefereeMutationVariables
>;
export const CreateTournamentSeasonDocument = gql`
  mutation createTournamentSeason($data: TournamentSeasonCreateInput!) {
    createTournamentSeason(data: $data) {
      ...tournamentSeason
    }
  }
  ${TournamentSeasonFragmentDoc}
`;
export type CreateTournamentSeasonMutationFn = Apollo.MutationFunction<
  CreateTournamentSeasonMutation,
  CreateTournamentSeasonMutationVariables
>;

/**
 * __useCreateTournamentSeasonMutation__
 *
 * To run a mutation, you first call `useCreateTournamentSeasonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTournamentSeasonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTournamentSeasonMutation, { data, loading, error }] = useCreateTournamentSeasonMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateTournamentSeasonMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateTournamentSeasonMutation,
    CreateTournamentSeasonMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<CreateTournamentSeasonMutation, CreateTournamentSeasonMutationVariables>(
    CreateTournamentSeasonDocument,
    options
  );
}
export type CreateTournamentSeasonMutationHookResult = ReturnType<typeof useCreateTournamentSeasonMutation>;
export type CreateTournamentSeasonMutationResult = Apollo.MutationResult<CreateTournamentSeasonMutation>;
export type CreateTournamentSeasonMutationOptions = Apollo.BaseMutationOptions<
  CreateTournamentSeasonMutation,
  CreateTournamentSeasonMutationVariables
>;
export const CreateTournamentSeasonTeamDocument = gql`
  mutation createTournamentSeasonTeam($data: TournamentSeasonTeamCreateInput!) {
    createTournamentSeasonTeam(data: $data) {
      ...tournamentSeasonTeam
    }
  }
  ${TournamentSeasonTeamFragmentDoc}
`;
export type CreateTournamentSeasonTeamMutationFn = Apollo.MutationFunction<
  CreateTournamentSeasonTeamMutation,
  CreateTournamentSeasonTeamMutationVariables
>;

/**
 * __useCreateTournamentSeasonTeamMutation__
 *
 * To run a mutation, you first call `useCreateTournamentSeasonTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTournamentSeasonTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTournamentSeasonTeamMutation, { data, loading, error }] = useCreateTournamentSeasonTeamMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateTournamentSeasonTeamMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateTournamentSeasonTeamMutation,
    CreateTournamentSeasonTeamMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<CreateTournamentSeasonTeamMutation, CreateTournamentSeasonTeamMutationVariables>(
    CreateTournamentSeasonTeamDocument,
    options
  );
}
export type CreateTournamentSeasonTeamMutationHookResult = ReturnType<typeof useCreateTournamentSeasonTeamMutation>;
export type CreateTournamentSeasonTeamMutationResult = Apollo.MutationResult<CreateTournamentSeasonTeamMutation>;
export type CreateTournamentSeasonTeamMutationOptions = Apollo.BaseMutationOptions<
  CreateTournamentSeasonTeamMutation,
  CreateTournamentSeasonTeamMutationVariables
>;
export const CreateTournamentSeasonTeamStatDocument = gql`
  mutation createTournamentSeasonTeamStat($data: TournamentSeasonTeamStatCreateInput!) {
    createTournamentSeasonTeamStat(data: $data) {
      ...tournamentSeasonTeamStat
    }
  }
  ${TournamentSeasonTeamStatFragmentDoc}
`;
export type CreateTournamentSeasonTeamStatMutationFn = Apollo.MutationFunction<
  CreateTournamentSeasonTeamStatMutation,
  CreateTournamentSeasonTeamStatMutationVariables
>;

/**
 * __useCreateTournamentSeasonTeamStatMutation__
 *
 * To run a mutation, you first call `useCreateTournamentSeasonTeamStatMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTournamentSeasonTeamStatMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTournamentSeasonTeamStatMutation, { data, loading, error }] = useCreateTournamentSeasonTeamStatMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateTournamentSeasonTeamStatMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateTournamentSeasonTeamStatMutation,
    CreateTournamentSeasonTeamStatMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    CreateTournamentSeasonTeamStatMutation,
    CreateTournamentSeasonTeamStatMutationVariables
  >(CreateTournamentSeasonTeamStatDocument, options);
}
export type CreateTournamentSeasonTeamStatMutationHookResult = ReturnType<
  typeof useCreateTournamentSeasonTeamStatMutation
>;
export type CreateTournamentSeasonTeamStatMutationResult = Apollo.MutationResult<CreateTournamentSeasonTeamStatMutation>;
export type CreateTournamentSeasonTeamStatMutationOptions = Apollo.BaseMutationOptions<
  CreateTournamentSeasonTeamStatMutation,
  CreateTournamentSeasonTeamStatMutationVariables
>;
export const CreateTournamentTeamDocument = gql`
  mutation createTournamentTeam($data: TournamentTeamCreateInput!) {
    createTournamentTeam(data: $data) {
      ...tournamentTeam
    }
  }
  ${TournamentTeamFragmentDoc}
`;
export type CreateTournamentTeamMutationFn = Apollo.MutationFunction<
  CreateTournamentTeamMutation,
  CreateTournamentTeamMutationVariables
>;

/**
 * __useCreateTournamentTeamMutation__
 *
 * To run a mutation, you first call `useCreateTournamentTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTournamentTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTournamentTeamMutation, { data, loading, error }] = useCreateTournamentTeamMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateTournamentTeamMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateTournamentTeamMutation,
    CreateTournamentTeamMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<CreateTournamentTeamMutation, CreateTournamentTeamMutationVariables>(
    CreateTournamentTeamDocument,
    options
  );
}
export type CreateTournamentTeamMutationHookResult = ReturnType<typeof useCreateTournamentTeamMutation>;
export type CreateTournamentTeamMutationResult = Apollo.MutationResult<CreateTournamentTeamMutation>;
export type CreateTournamentTeamMutationOptions = Apollo.BaseMutationOptions<
  CreateTournamentTeamMutation,
  CreateTournamentTeamMutationVariables
>;
export const CreateTournamentTeamMemberDocument = gql`
  mutation createTournamentTeamMember($data: TournamentTeamMemberCreateInput!) {
    createTournamentTeamMember(data: $data) {
      ...tournamentTeamMember
    }
  }
  ${TournamentTeamMemberFragmentDoc}
`;
export type CreateTournamentTeamMemberMutationFn = Apollo.MutationFunction<
  CreateTournamentTeamMemberMutation,
  CreateTournamentTeamMemberMutationVariables
>;

/**
 * __useCreateTournamentTeamMemberMutation__
 *
 * To run a mutation, you first call `useCreateTournamentTeamMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTournamentTeamMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTournamentTeamMemberMutation, { data, loading, error }] = useCreateTournamentTeamMemberMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateTournamentTeamMemberMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateTournamentTeamMemberMutation,
    CreateTournamentTeamMemberMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<CreateTournamentTeamMemberMutation, CreateTournamentTeamMemberMutationVariables>(
    CreateTournamentTeamMemberDocument,
    options
  );
}
export type CreateTournamentTeamMemberMutationHookResult = ReturnType<typeof useCreateTournamentTeamMemberMutation>;
export type CreateTournamentTeamMemberMutationResult = Apollo.MutationResult<CreateTournamentTeamMemberMutation>;
export type CreateTournamentTeamMemberMutationOptions = Apollo.BaseMutationOptions<
  CreateTournamentTeamMemberMutation,
  CreateTournamentTeamMemberMutationVariables
>;
export const CreateTournamentTourDocument = gql`
  mutation createTournamentTour($data: TournamentTourCreateInput!) {
    createTournamentTour(data: $data) {
      ...tournamentTour
    }
  }
  ${TournamentTourFragmentDoc}
`;
export type CreateTournamentTourMutationFn = Apollo.MutationFunction<
  CreateTournamentTourMutation,
  CreateTournamentTourMutationVariables
>;

/**
 * __useCreateTournamentTourMutation__
 *
 * To run a mutation, you first call `useCreateTournamentTourMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTournamentTourMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTournamentTourMutation, { data, loading, error }] = useCreateTournamentTourMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateTournamentTourMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateTournamentTourMutation,
    CreateTournamentTourMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<CreateTournamentTourMutation, CreateTournamentTourMutationVariables>(
    CreateTournamentTourDocument,
    options
  );
}
export type CreateTournamentTourMutationHookResult = ReturnType<typeof useCreateTournamentTourMutation>;
export type CreateTournamentTourMutationResult = Apollo.MutationResult<CreateTournamentTourMutation>;
export type CreateTournamentTourMutationOptions = Apollo.BaseMutationOptions<
  CreateTournamentTourMutation,
  CreateTournamentTourMutationVariables
>;
export const CreateUserGroupDocument = gql`
  mutation createUserGroup($data: UserGroupCreateInput!) {
    createUserGroup(data: $data) {
      ...userGroup
    }
  }
  ${UserGroupFragmentDoc}
`;
export type CreateUserGroupMutationFn = Apollo.MutationFunction<
  CreateUserGroupMutation,
  CreateUserGroupMutationVariables
>;

/**
 * __useCreateUserGroupMutation__
 *
 * To run a mutation, you first call `useCreateUserGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserGroupMutation, { data, loading, error }] = useCreateUserGroupMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateUserGroupMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<CreateUserGroupMutation, CreateUserGroupMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<CreateUserGroupMutation, CreateUserGroupMutationVariables>(
    CreateUserGroupDocument,
    options
  );
}
export type CreateUserGroupMutationHookResult = ReturnType<typeof useCreateUserGroupMutation>;
export type CreateUserGroupMutationResult = Apollo.MutationResult<CreateUserGroupMutation>;
export type CreateUserGroupMutationOptions = Apollo.BaseMutationOptions<
  CreateUserGroupMutation,
  CreateUserGroupMutationVariables
>;
export const CreateUserSubscriberDocument = gql`
  mutation createUserSubscriber($data: UserSubscriberCreateInput!) {
    createUserSubscriber(data: $data) {
      ...userSubscriber
    }
  }
  ${UserSubscriberFragmentDoc}
`;
export type CreateUserSubscriberMutationFn = Apollo.MutationFunction<
  CreateUserSubscriberMutation,
  CreateUserSubscriberMutationVariables
>;

/**
 * __useCreateUserSubscriberMutation__
 *
 * To run a mutation, you first call `useCreateUserSubscriberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserSubscriberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserSubscriberMutation, { data, loading, error }] = useCreateUserSubscriberMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateUserSubscriberMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateUserSubscriberMutation,
    CreateUserSubscriberMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<CreateUserSubscriberMutation, CreateUserSubscriberMutationVariables>(
    CreateUserSubscriberDocument,
    options
  );
}
export type CreateUserSubscriberMutationHookResult = ReturnType<typeof useCreateUserSubscriberMutation>;
export type CreateUserSubscriberMutationResult = Apollo.MutationResult<CreateUserSubscriberMutation>;
export type CreateUserSubscriberMutationOptions = Apollo.BaseMutationOptions<
  CreateUserSubscriberMutation,
  CreateUserSubscriberMutationVariables
>;
export const CreateUserSubscribersDocument = gql`
  mutation createUserSubscribers($subscriptionId: Int!, $userId: [Int!]!, $modifierId: Int) {
    createUserSubscribers(subscriptionId: $subscriptionId, userId: $userId, modifierId: $modifierId)
  }
`;
export type CreateUserSubscribersMutationFn = Apollo.MutationFunction<
  CreateUserSubscribersMutation,
  CreateUserSubscribersMutationVariables
>;

/**
 * __useCreateUserSubscribersMutation__
 *
 * To run a mutation, you first call `useCreateUserSubscribersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserSubscribersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserSubscribersMutation, { data, loading, error }] = useCreateUserSubscribersMutation({
 *   variables: {
 *      subscriptionId: // value for 'subscriptionId'
 *      userId: // value for 'userId'
 *      modifierId: // value for 'modifierId'
 *   },
 * });
 */
export function useCreateUserSubscribersMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateUserSubscribersMutation,
    CreateUserSubscribersMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<CreateUserSubscribersMutation, CreateUserSubscribersMutationVariables>(
    CreateUserSubscribersDocument,
    options
  );
}
export type CreateUserSubscribersMutationHookResult = ReturnType<typeof useCreateUserSubscribersMutation>;
export type CreateUserSubscribersMutationResult = Apollo.MutationResult<CreateUserSubscribersMutation>;
export type CreateUserSubscribersMutationOptions = Apollo.BaseMutationOptions<
  CreateUserSubscribersMutation,
  CreateUserSubscribersMutationVariables
>;
export const CreateUserSubscriptionDocument = gql`
  mutation createUserSubscription($data: UserSubscriptionCreateInput!) {
    createUserSubscription(data: $data) {
      ...userSubscription
    }
  }
  ${UserSubscriptionFragmentDoc}
`;
export type CreateUserSubscriptionMutationFn = Apollo.MutationFunction<
  CreateUserSubscriptionMutation,
  CreateUserSubscriptionMutationVariables
>;

/**
 * __useCreateUserSubscriptionMutation__
 *
 * To run a mutation, you first call `useCreateUserSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserSubscriptionMutation, { data, loading, error }] = useCreateUserSubscriptionMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateUserSubscriptionMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateUserSubscriptionMutation,
    CreateUserSubscriptionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<CreateUserSubscriptionMutation, CreateUserSubscriptionMutationVariables>(
    CreateUserSubscriptionDocument,
    options
  );
}
export type CreateUserSubscriptionMutationHookResult = ReturnType<typeof useCreateUserSubscriptionMutation>;
export type CreateUserSubscriptionMutationResult = Apollo.MutationResult<CreateUserSubscriptionMutation>;
export type CreateUserSubscriptionMutationOptions = Apollo.BaseMutationOptions<
  CreateUserSubscriptionMutation,
  CreateUserSubscriptionMutationVariables
>;
export const DeleteArticleDocument = gql`
  mutation deleteArticle($id: Int!) {
    deleteArticle(id: $id) {
      ...article
    }
  }
  ${ArticleFragmentDoc}
`;
export type DeleteArticleMutationFn = Apollo.MutationFunction<DeleteArticleMutation, DeleteArticleMutationVariables>;

/**
 * __useDeleteArticleMutation__
 *
 * To run a mutation, you first call `useDeleteArticleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteArticleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteArticleMutation, { data, loading, error }] = useDeleteArticleMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteArticleMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteArticleMutation, DeleteArticleMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<DeleteArticleMutation, DeleteArticleMutationVariables>(
    DeleteArticleDocument,
    options
  );
}
export type DeleteArticleMutationHookResult = ReturnType<typeof useDeleteArticleMutation>;
export type DeleteArticleMutationResult = Apollo.MutationResult<DeleteArticleMutation>;
export type DeleteArticleMutationOptions = Apollo.BaseMutationOptions<
  DeleteArticleMutation,
  DeleteArticleMutationVariables
>;
export const DeleteArticleAuthorDocument = gql`
  mutation deleteArticleAuthor($id: Int!) {
    deleteArticleAuthor(id: $id) {
      ...articleAuthor
    }
  }
  ${ArticleAuthorFragmentDoc}
`;
export type DeleteArticleAuthorMutationFn = Apollo.MutationFunction<
  DeleteArticleAuthorMutation,
  DeleteArticleAuthorMutationVariables
>;

/**
 * __useDeleteArticleAuthorMutation__
 *
 * To run a mutation, you first call `useDeleteArticleAuthorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteArticleAuthorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteArticleAuthorMutation, { data, loading, error }] = useDeleteArticleAuthorMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteArticleAuthorMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteArticleAuthorMutation, DeleteArticleAuthorMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<DeleteArticleAuthorMutation, DeleteArticleAuthorMutationVariables>(
    DeleteArticleAuthorDocument,
    options
  );
}
export type DeleteArticleAuthorMutationHookResult = ReturnType<typeof useDeleteArticleAuthorMutation>;
export type DeleteArticleAuthorMutationResult = Apollo.MutationResult<DeleteArticleAuthorMutation>;
export type DeleteArticleAuthorMutationOptions = Apollo.BaseMutationOptions<
  DeleteArticleAuthorMutation,
  DeleteArticleAuthorMutationVariables
>;
export const DeleteArticleBlockDocument = gql`
  mutation deleteArticleBlock($id: Int!) {
    deleteArticleBlock(id: $id) {
      ...articleBlock
    }
  }
  ${ArticleBlockFragmentDoc}
`;
export type DeleteArticleBlockMutationFn = Apollo.MutationFunction<
  DeleteArticleBlockMutation,
  DeleteArticleBlockMutationVariables
>;

/**
 * __useDeleteArticleBlockMutation__
 *
 * To run a mutation, you first call `useDeleteArticleBlockMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteArticleBlockMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteArticleBlockMutation, { data, loading, error }] = useDeleteArticleBlockMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteArticleBlockMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteArticleBlockMutation, DeleteArticleBlockMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<DeleteArticleBlockMutation, DeleteArticleBlockMutationVariables>(
    DeleteArticleBlockDocument,
    options
  );
}
export type DeleteArticleBlockMutationHookResult = ReturnType<typeof useDeleteArticleBlockMutation>;
export type DeleteArticleBlockMutationResult = Apollo.MutationResult<DeleteArticleBlockMutation>;
export type DeleteArticleBlockMutationOptions = Apollo.BaseMutationOptions<
  DeleteArticleBlockMutation,
  DeleteArticleBlockMutationVariables
>;
export const DeleteArticleCategoryDocument = gql`
  mutation deleteArticleCategory($id: Int!) {
    deleteArticleCategory(id: $id) {
      ...articleCategory
    }
  }
  ${ArticleCategoryFragmentDoc}
`;
export type DeleteArticleCategoryMutationFn = Apollo.MutationFunction<
  DeleteArticleCategoryMutation,
  DeleteArticleCategoryMutationVariables
>;

/**
 * __useDeleteArticleCategoryMutation__
 *
 * To run a mutation, you first call `useDeleteArticleCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteArticleCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteArticleCategoryMutation, { data, loading, error }] = useDeleteArticleCategoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteArticleCategoryMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteArticleCategoryMutation,
    DeleteArticleCategoryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<DeleteArticleCategoryMutation, DeleteArticleCategoryMutationVariables>(
    DeleteArticleCategoryDocument,
    options
  );
}
export type DeleteArticleCategoryMutationHookResult = ReturnType<typeof useDeleteArticleCategoryMutation>;
export type DeleteArticleCategoryMutationResult = Apollo.MutationResult<DeleteArticleCategoryMutation>;
export type DeleteArticleCategoryMutationOptions = Apollo.BaseMutationOptions<
  DeleteArticleCategoryMutation,
  DeleteArticleCategoryMutationVariables
>;
export const DeleteArticleLinkDocument = gql`
  mutation deleteArticleLink($id: Int!) {
    deleteArticleLink(id: $id) {
      ...articleLink
    }
  }
  ${ArticleLinkFragmentDoc}
`;
export type DeleteArticleLinkMutationFn = Apollo.MutationFunction<
  DeleteArticleLinkMutation,
  DeleteArticleLinkMutationVariables
>;

/**
 * __useDeleteArticleLinkMutation__
 *
 * To run a mutation, you first call `useDeleteArticleLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteArticleLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteArticleLinkMutation, { data, loading, error }] = useDeleteArticleLinkMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteArticleLinkMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteArticleLinkMutation, DeleteArticleLinkMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<DeleteArticleLinkMutation, DeleteArticleLinkMutationVariables>(
    DeleteArticleLinkDocument,
    options
  );
}
export type DeleteArticleLinkMutationHookResult = ReturnType<typeof useDeleteArticleLinkMutation>;
export type DeleteArticleLinkMutationResult = Apollo.MutationResult<DeleteArticleLinkMutation>;
export type DeleteArticleLinkMutationOptions = Apollo.BaseMutationOptions<
  DeleteArticleLinkMutation,
  DeleteArticleLinkMutationVariables
>;
export const DeleteArticleTagDocument = gql`
  mutation deleteArticleTag($id: Int!) {
    deleteArticleTag(id: $id) {
      ...articleTag
    }
  }
  ${ArticleTagFragmentDoc}
`;
export type DeleteArticleTagMutationFn = Apollo.MutationFunction<
  DeleteArticleTagMutation,
  DeleteArticleTagMutationVariables
>;

/**
 * __useDeleteArticleTagMutation__
 *
 * To run a mutation, you first call `useDeleteArticleTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteArticleTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteArticleTagMutation, { data, loading, error }] = useDeleteArticleTagMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteArticleTagMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteArticleTagMutation, DeleteArticleTagMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<DeleteArticleTagMutation, DeleteArticleTagMutationVariables>(
    DeleteArticleTagDocument,
    options
  );
}
export type DeleteArticleTagMutationHookResult = ReturnType<typeof useDeleteArticleTagMutation>;
export type DeleteArticleTagMutationResult = Apollo.MutationResult<DeleteArticleTagMutation>;
export type DeleteArticleTagMutationOptions = Apollo.BaseMutationOptions<
  DeleteArticleTagMutation,
  DeleteArticleTagMutationVariables
>;
export const DeleteCatalogueDocument = gql`
  mutation deleteCatalogue($id: Int!) {
    deleteCatalogue(id: $id) {
      ...catalogueFlat
    }
  }
  ${CatalogueFlatFragmentDoc}
`;
export type DeleteCatalogueMutationFn = Apollo.MutationFunction<
  DeleteCatalogueMutation,
  DeleteCatalogueMutationVariables
>;

/**
 * __useDeleteCatalogueMutation__
 *
 * To run a mutation, you first call `useDeleteCatalogueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCatalogueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCatalogueMutation, { data, loading, error }] = useDeleteCatalogueMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCatalogueMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteCatalogueMutation, DeleteCatalogueMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<DeleteCatalogueMutation, DeleteCatalogueMutationVariables>(
    DeleteCatalogueDocument,
    options
  );
}
export type DeleteCatalogueMutationHookResult = ReturnType<typeof useDeleteCatalogueMutation>;
export type DeleteCatalogueMutationResult = Apollo.MutationResult<DeleteCatalogueMutation>;
export type DeleteCatalogueMutationOptions = Apollo.BaseMutationOptions<
  DeleteCatalogueMutation,
  DeleteCatalogueMutationVariables
>;
export const DeleteCatalogueCategoryDocument = gql`
  mutation deleteCatalogueCategory($id: Int!) {
    deleteCatalogueCategory(id: $id) {
      ...catalogueCategory
    }
  }
  ${CatalogueCategoryFragmentDoc}
`;
export type DeleteCatalogueCategoryMutationFn = Apollo.MutationFunction<
  DeleteCatalogueCategoryMutation,
  DeleteCatalogueCategoryMutationVariables
>;

/**
 * __useDeleteCatalogueCategoryMutation__
 *
 * To run a mutation, you first call `useDeleteCatalogueCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCatalogueCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCatalogueCategoryMutation, { data, loading, error }] = useDeleteCatalogueCategoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCatalogueCategoryMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteCatalogueCategoryMutation,
    DeleteCatalogueCategoryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<DeleteCatalogueCategoryMutation, DeleteCatalogueCategoryMutationVariables>(
    DeleteCatalogueCategoryDocument,
    options
  );
}
export type DeleteCatalogueCategoryMutationHookResult = ReturnType<typeof useDeleteCatalogueCategoryMutation>;
export type DeleteCatalogueCategoryMutationResult = Apollo.MutationResult<DeleteCatalogueCategoryMutation>;
export type DeleteCatalogueCategoryMutationOptions = Apollo.BaseMutationOptions<
  DeleteCatalogueCategoryMutation,
  DeleteCatalogueCategoryMutationVariables
>;
export const DeleteCatalogueItemDocument = gql`
  mutation deleteCatalogueItem($id: Int!) {
    deleteCatalogueItem(id: $id) {
      ...catalogueItem
    }
  }
  ${CatalogueItemFragmentDoc}
`;
export type DeleteCatalogueItemMutationFn = Apollo.MutationFunction<
  DeleteCatalogueItemMutation,
  DeleteCatalogueItemMutationVariables
>;

/**
 * __useDeleteCatalogueItemMutation__
 *
 * To run a mutation, you first call `useDeleteCatalogueItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCatalogueItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCatalogueItemMutation, { data, loading, error }] = useDeleteCatalogueItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCatalogueItemMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteCatalogueItemMutation, DeleteCatalogueItemMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<DeleteCatalogueItemMutation, DeleteCatalogueItemMutationVariables>(
    DeleteCatalogueItemDocument,
    options
  );
}
export type DeleteCatalogueItemMutationHookResult = ReturnType<typeof useDeleteCatalogueItemMutation>;
export type DeleteCatalogueItemMutationResult = Apollo.MutationResult<DeleteCatalogueItemMutation>;
export type DeleteCatalogueItemMutationOptions = Apollo.BaseMutationOptions<
  DeleteCatalogueItemMutation,
  DeleteCatalogueItemMutationVariables
>;
export const DeleteCatalogueItemPriceDocument = gql`
  mutation deleteCatalogueItemPrice($id: Int!) {
    deleteCatalogueItemPrice(id: $id) {
      ...catalogueItemPrice
    }
  }
  ${CatalogueItemPriceFragmentDoc}
`;
export type DeleteCatalogueItemPriceMutationFn = Apollo.MutationFunction<
  DeleteCatalogueItemPriceMutation,
  DeleteCatalogueItemPriceMutationVariables
>;

/**
 * __useDeleteCatalogueItemPriceMutation__
 *
 * To run a mutation, you first call `useDeleteCatalogueItemPriceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCatalogueItemPriceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCatalogueItemPriceMutation, { data, loading, error }] = useDeleteCatalogueItemPriceMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCatalogueItemPriceMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteCatalogueItemPriceMutation,
    DeleteCatalogueItemPriceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<DeleteCatalogueItemPriceMutation, DeleteCatalogueItemPriceMutationVariables>(
    DeleteCatalogueItemPriceDocument,
    options
  );
}
export type DeleteCatalogueItemPriceMutationHookResult = ReturnType<typeof useDeleteCatalogueItemPriceMutation>;
export type DeleteCatalogueItemPriceMutationResult = Apollo.MutationResult<DeleteCatalogueItemPriceMutation>;
export type DeleteCatalogueItemPriceMutationOptions = Apollo.BaseMutationOptions<
  DeleteCatalogueItemPriceMutation,
  DeleteCatalogueItemPriceMutationVariables
>;
export const DeleteCateringDocument = gql`
  mutation deleteCatering($id: Int!) {
    deleteCatering(id: $id) {
      ...catering
    }
  }
  ${CateringFragmentDoc}
`;
export type DeleteCateringMutationFn = Apollo.MutationFunction<DeleteCateringMutation, DeleteCateringMutationVariables>;

/**
 * __useDeleteCateringMutation__
 *
 * To run a mutation, you first call `useDeleteCateringMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCateringMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCateringMutation, { data, loading, error }] = useDeleteCateringMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCateringMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteCateringMutation, DeleteCateringMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<DeleteCateringMutation, DeleteCateringMutationVariables>(
    DeleteCateringDocument,
    options
  );
}
export type DeleteCateringMutationHookResult = ReturnType<typeof useDeleteCateringMutation>;
export type DeleteCateringMutationResult = Apollo.MutationResult<DeleteCateringMutation>;
export type DeleteCateringMutationOptions = Apollo.BaseMutationOptions<
  DeleteCateringMutation,
  DeleteCateringMutationVariables
>;
export const DeleteCommerceOrderDocument = gql`
  mutation deleteCommerceOrder($id: Int!) {
    deleteCommerceOrder(id: $id) {
      ...commerceOrder
    }
  }
  ${CommerceOrderFragmentDoc}
`;
export type DeleteCommerceOrderMutationFn = Apollo.MutationFunction<
  DeleteCommerceOrderMutation,
  DeleteCommerceOrderMutationVariables
>;

/**
 * __useDeleteCommerceOrderMutation__
 *
 * To run a mutation, you first call `useDeleteCommerceOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCommerceOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCommerceOrderMutation, { data, loading, error }] = useDeleteCommerceOrderMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCommerceOrderMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteCommerceOrderMutation, DeleteCommerceOrderMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<DeleteCommerceOrderMutation, DeleteCommerceOrderMutationVariables>(
    DeleteCommerceOrderDocument,
    options
  );
}
export type DeleteCommerceOrderMutationHookResult = ReturnType<typeof useDeleteCommerceOrderMutation>;
export type DeleteCommerceOrderMutationResult = Apollo.MutationResult<DeleteCommerceOrderMutation>;
export type DeleteCommerceOrderMutationOptions = Apollo.BaseMutationOptions<
  DeleteCommerceOrderMutation,
  DeleteCommerceOrderMutationVariables
>;
export const DeleteCommerceOrderDataDocument = gql`
  mutation deleteCommerceOrderData($id: Int!) {
    deleteCommerceOrderData(id: $id) {
      ...commerceOrderData
    }
  }
  ${CommerceOrderDataFragmentDoc}
`;
export type DeleteCommerceOrderDataMutationFn = Apollo.MutationFunction<
  DeleteCommerceOrderDataMutation,
  DeleteCommerceOrderDataMutationVariables
>;

/**
 * __useDeleteCommerceOrderDataMutation__
 *
 * To run a mutation, you first call `useDeleteCommerceOrderDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCommerceOrderDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCommerceOrderDataMutation, { data, loading, error }] = useDeleteCommerceOrderDataMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCommerceOrderDataMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteCommerceOrderDataMutation,
    DeleteCommerceOrderDataMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<DeleteCommerceOrderDataMutation, DeleteCommerceOrderDataMutationVariables>(
    DeleteCommerceOrderDataDocument,
    options
  );
}
export type DeleteCommerceOrderDataMutationHookResult = ReturnType<typeof useDeleteCommerceOrderDataMutation>;
export type DeleteCommerceOrderDataMutationResult = Apollo.MutationResult<DeleteCommerceOrderDataMutation>;
export type DeleteCommerceOrderDataMutationOptions = Apollo.BaseMutationOptions<
  DeleteCommerceOrderDataMutation,
  DeleteCommerceOrderDataMutationVariables
>;
export const DeleteCommerceOrderFieldDocument = gql`
  mutation deleteCommerceOrderField($id: Int!) {
    deleteCommerceOrderField(id: $id) {
      ...commerceOrderField
    }
  }
  ${CommerceOrderFieldFragmentDoc}
`;
export type DeleteCommerceOrderFieldMutationFn = Apollo.MutationFunction<
  DeleteCommerceOrderFieldMutation,
  DeleteCommerceOrderFieldMutationVariables
>;

/**
 * __useDeleteCommerceOrderFieldMutation__
 *
 * To run a mutation, you first call `useDeleteCommerceOrderFieldMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCommerceOrderFieldMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCommerceOrderFieldMutation, { data, loading, error }] = useDeleteCommerceOrderFieldMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCommerceOrderFieldMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteCommerceOrderFieldMutation,
    DeleteCommerceOrderFieldMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<DeleteCommerceOrderFieldMutation, DeleteCommerceOrderFieldMutationVariables>(
    DeleteCommerceOrderFieldDocument,
    options
  );
}
export type DeleteCommerceOrderFieldMutationHookResult = ReturnType<typeof useDeleteCommerceOrderFieldMutation>;
export type DeleteCommerceOrderFieldMutationResult = Apollo.MutationResult<DeleteCommerceOrderFieldMutation>;
export type DeleteCommerceOrderFieldMutationOptions = Apollo.BaseMutationOptions<
  DeleteCommerceOrderFieldMutation,
  DeleteCommerceOrderFieldMutationVariables
>;
export const DeleteCommerceOrderItemDocument = gql`
  mutation deleteCommerceOrderItem($id: Int!) {
    deleteCommerceOrderItem(id: $id) {
      ...commerceOrderItem
    }
  }
  ${CommerceOrderItemFragmentDoc}
`;
export type DeleteCommerceOrderItemMutationFn = Apollo.MutationFunction<
  DeleteCommerceOrderItemMutation,
  DeleteCommerceOrderItemMutationVariables
>;

/**
 * __useDeleteCommerceOrderItemMutation__
 *
 * To run a mutation, you first call `useDeleteCommerceOrderItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCommerceOrderItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCommerceOrderItemMutation, { data, loading, error }] = useDeleteCommerceOrderItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCommerceOrderItemMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteCommerceOrderItemMutation,
    DeleteCommerceOrderItemMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<DeleteCommerceOrderItemMutation, DeleteCommerceOrderItemMutationVariables>(
    DeleteCommerceOrderItemDocument,
    options
  );
}
export type DeleteCommerceOrderItemMutationHookResult = ReturnType<typeof useDeleteCommerceOrderItemMutation>;
export type DeleteCommerceOrderItemMutationResult = Apollo.MutationResult<DeleteCommerceOrderItemMutation>;
export type DeleteCommerceOrderItemMutationOptions = Apollo.BaseMutationOptions<
  DeleteCommerceOrderItemMutation,
  DeleteCommerceOrderItemMutationVariables
>;
export const DeleteCommerceShopDocument = gql`
  mutation deleteCommerceShop($id: Int!) {
    deleteCommerceShop(id: $id) {
      ...commerceShop
    }
  }
  ${CommerceShopFragmentDoc}
`;
export type DeleteCommerceShopMutationFn = Apollo.MutationFunction<
  DeleteCommerceShopMutation,
  DeleteCommerceShopMutationVariables
>;

/**
 * __useDeleteCommerceShopMutation__
 *
 * To run a mutation, you first call `useDeleteCommerceShopMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCommerceShopMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCommerceShopMutation, { data, loading, error }] = useDeleteCommerceShopMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCommerceShopMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteCommerceShopMutation, DeleteCommerceShopMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<DeleteCommerceShopMutation, DeleteCommerceShopMutationVariables>(
    DeleteCommerceShopDocument,
    options
  );
}
export type DeleteCommerceShopMutationHookResult = ReturnType<typeof useDeleteCommerceShopMutation>;
export type DeleteCommerceShopMutationResult = Apollo.MutationResult<DeleteCommerceShopMutation>;
export type DeleteCommerceShopMutationOptions = Apollo.BaseMutationOptions<
  DeleteCommerceShopMutation,
  DeleteCommerceShopMutationVariables
>;
export const DeleteContentTranslationDocument = gql`
  mutation deleteContentTranslation($id: Int!) {
    deleteContentTranslation(id: $id) {
      ...contentTranslation
    }
  }
  ${ContentTranslationFragmentDoc}
`;
export type DeleteContentTranslationMutationFn = Apollo.MutationFunction<
  DeleteContentTranslationMutation,
  DeleteContentTranslationMutationVariables
>;

/**
 * __useDeleteContentTranslationMutation__
 *
 * To run a mutation, you first call `useDeleteContentTranslationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteContentTranslationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteContentTranslationMutation, { data, loading, error }] = useDeleteContentTranslationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteContentTranslationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteContentTranslationMutation,
    DeleteContentTranslationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<DeleteContentTranslationMutation, DeleteContentTranslationMutationVariables>(
    DeleteContentTranslationDocument,
    options
  );
}
export type DeleteContentTranslationMutationHookResult = ReturnType<typeof useDeleteContentTranslationMutation>;
export type DeleteContentTranslationMutationResult = Apollo.MutationResult<DeleteContentTranslationMutation>;
export type DeleteContentTranslationMutationOptions = Apollo.BaseMutationOptions<
  DeleteContentTranslationMutation,
  DeleteContentTranslationMutationVariables
>;
export const DeleteEventDocument = gql`
  mutation deleteEvent($id: Int!) {
    deleteEvent(id: $id) {
      ...eventFull
    }
  }
  ${EventFullFragmentDoc}
`;
export type DeleteEventMutationFn = Apollo.MutationFunction<DeleteEventMutation, DeleteEventMutationVariables>;

/**
 * __useDeleteEventMutation__
 *
 * To run a mutation, you first call `useDeleteEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEventMutation, { data, loading, error }] = useDeleteEventMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteEventMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteEventMutation, DeleteEventMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<DeleteEventMutation, DeleteEventMutationVariables>(DeleteEventDocument, options);
}
export type DeleteEventMutationHookResult = ReturnType<typeof useDeleteEventMutation>;
export type DeleteEventMutationResult = Apollo.MutationResult<DeleteEventMutation>;
export type DeleteEventMutationOptions = Apollo.BaseMutationOptions<DeleteEventMutation, DeleteEventMutationVariables>;
export const DeleteEventEventAccessDocument = gql`
  mutation deleteEventEventAccess($id: Int!) {
    deleteEventEventAccess(id: $id) {
      ...eventEventAccess
    }
  }
  ${EventEventAccessFragmentDoc}
`;
export type DeleteEventEventAccessMutationFn = Apollo.MutationFunction<
  DeleteEventEventAccessMutation,
  DeleteEventEventAccessMutationVariables
>;

/**
 * __useDeleteEventEventAccessMutation__
 *
 * To run a mutation, you first call `useDeleteEventEventAccessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEventEventAccessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEventEventAccessMutation, { data, loading, error }] = useDeleteEventEventAccessMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteEventEventAccessMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteEventEventAccessMutation,
    DeleteEventEventAccessMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<DeleteEventEventAccessMutation, DeleteEventEventAccessMutationVariables>(
    DeleteEventEventAccessDocument,
    options
  );
}
export type DeleteEventEventAccessMutationHookResult = ReturnType<typeof useDeleteEventEventAccessMutation>;
export type DeleteEventEventAccessMutationResult = Apollo.MutationResult<DeleteEventEventAccessMutation>;
export type DeleteEventEventAccessMutationOptions = Apollo.BaseMutationOptions<
  DeleteEventEventAccessMutation,
  DeleteEventEventAccessMutationVariables
>;
export const DeleteEventPlaceDocument = gql`
  mutation deleteEventPlace($id: Int!) {
    deleteEventPlace(id: $id) {
      ...eventPlace
    }
  }
  ${EventPlaceFragmentDoc}
`;
export type DeleteEventPlaceMutationFn = Apollo.MutationFunction<
  DeleteEventPlaceMutation,
  DeleteEventPlaceMutationVariables
>;

/**
 * __useDeleteEventPlaceMutation__
 *
 * To run a mutation, you first call `useDeleteEventPlaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEventPlaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEventPlaceMutation, { data, loading, error }] = useDeleteEventPlaceMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteEventPlaceMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteEventPlaceMutation, DeleteEventPlaceMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<DeleteEventPlaceMutation, DeleteEventPlaceMutationVariables>(
    DeleteEventPlaceDocument,
    options
  );
}
export type DeleteEventPlaceMutationHookResult = ReturnType<typeof useDeleteEventPlaceMutation>;
export type DeleteEventPlaceMutationResult = Apollo.MutationResult<DeleteEventPlaceMutation>;
export type DeleteEventPlaceMutationOptions = Apollo.BaseMutationOptions<
  DeleteEventPlaceMutation,
  DeleteEventPlaceMutationVariables
>;
export const DeleteEventReserveDocument = gql`
  mutation deleteEventReserve($id: Int!) {
    deleteEventReserve(id: $id) {
      ...eventReserve
    }
  }
  ${EventReserveFragmentDoc}
`;
export type DeleteEventReserveMutationFn = Apollo.MutationFunction<
  DeleteEventReserveMutation,
  DeleteEventReserveMutationVariables
>;

/**
 * __useDeleteEventReserveMutation__
 *
 * To run a mutation, you first call `useDeleteEventReserveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEventReserveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEventReserveMutation, { data, loading, error }] = useDeleteEventReserveMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteEventReserveMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteEventReserveMutation, DeleteEventReserveMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<DeleteEventReserveMutation, DeleteEventReserveMutationVariables>(
    DeleteEventReserveDocument,
    options
  );
}
export type DeleteEventReserveMutationHookResult = ReturnType<typeof useDeleteEventReserveMutation>;
export type DeleteEventReserveMutationResult = Apollo.MutationResult<DeleteEventReserveMutation>;
export type DeleteEventReserveMutationOptions = Apollo.BaseMutationOptions<
  DeleteEventReserveMutation,
  DeleteEventReserveMutationVariables
>;
export const DeleteEventTypeDocument = gql`
  mutation deleteEventType($id: Int!) {
    deleteEventType(id: $id) {
      ...eventType
    }
  }
  ${EventTypeFragmentDoc}
`;
export type DeleteEventTypeMutationFn = Apollo.MutationFunction<
  DeleteEventTypeMutation,
  DeleteEventTypeMutationVariables
>;

/**
 * __useDeleteEventTypeMutation__
 *
 * To run a mutation, you first call `useDeleteEventTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEventTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEventTypeMutation, { data, loading, error }] = useDeleteEventTypeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteEventTypeMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteEventTypeMutation, DeleteEventTypeMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<DeleteEventTypeMutation, DeleteEventTypeMutationVariables>(
    DeleteEventTypeDocument,
    options
  );
}
export type DeleteEventTypeMutationHookResult = ReturnType<typeof useDeleteEventTypeMutation>;
export type DeleteEventTypeMutationResult = Apollo.MutationResult<DeleteEventTypeMutation>;
export type DeleteEventTypeMutationOptions = Apollo.BaseMutationOptions<
  DeleteEventTypeMutation,
  DeleteEventTypeMutationVariables
>;
export const DeleteFromMessengerQueueDocument = gql`
  mutation deleteFromMessengerQueue($mailingId: Int!, $userId: [Int!]) {
    deleteFromMessengerQueue(mailingId: $mailingId, userId: $userId)
  }
`;
export type DeleteFromMessengerQueueMutationFn = Apollo.MutationFunction<
  DeleteFromMessengerQueueMutation,
  DeleteFromMessengerQueueMutationVariables
>;

/**
 * __useDeleteFromMessengerQueueMutation__
 *
 * To run a mutation, you first call `useDeleteFromMessengerQueueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFromMessengerQueueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFromMessengerQueueMutation, { data, loading, error }] = useDeleteFromMessengerQueueMutation({
 *   variables: {
 *      mailingId: // value for 'mailingId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useDeleteFromMessengerQueueMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteFromMessengerQueueMutation,
    DeleteFromMessengerQueueMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<DeleteFromMessengerQueueMutation, DeleteFromMessengerQueueMutationVariables>(
    DeleteFromMessengerQueueDocument,
    options
  );
}
export type DeleteFromMessengerQueueMutationHookResult = ReturnType<typeof useDeleteFromMessengerQueueMutation>;
export type DeleteFromMessengerQueueMutationResult = Apollo.MutationResult<DeleteFromMessengerQueueMutation>;
export type DeleteFromMessengerQueueMutationOptions = Apollo.BaseMutationOptions<
  DeleteFromMessengerQueueMutation,
  DeleteFromMessengerQueueMutationVariables
>;
export const DeleteFromUsersSubscriptionDocument = gql`
  mutation deleteFromUsersSubscription($subscriptionId: Int!, $userId: [Int!]) {
    deleteFromUsersSubscription(subscriptionId: $subscriptionId, userId: $userId)
  }
`;
export type DeleteFromUsersSubscriptionMutationFn = Apollo.MutationFunction<
  DeleteFromUsersSubscriptionMutation,
  DeleteFromUsersSubscriptionMutationVariables
>;

/**
 * __useDeleteFromUsersSubscriptionMutation__
 *
 * To run a mutation, you first call `useDeleteFromUsersSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFromUsersSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFromUsersSubscriptionMutation, { data, loading, error }] = useDeleteFromUsersSubscriptionMutation({
 *   variables: {
 *      subscriptionId: // value for 'subscriptionId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useDeleteFromUsersSubscriptionMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteFromUsersSubscriptionMutation,
    DeleteFromUsersSubscriptionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    DeleteFromUsersSubscriptionMutation,
    DeleteFromUsersSubscriptionMutationVariables
  >(DeleteFromUsersSubscriptionDocument, options);
}
export type DeleteFromUsersSubscriptionMutationHookResult = ReturnType<typeof useDeleteFromUsersSubscriptionMutation>;
export type DeleteFromUsersSubscriptionMutationResult = Apollo.MutationResult<DeleteFromUsersSubscriptionMutation>;
export type DeleteFromUsersSubscriptionMutationOptions = Apollo.BaseMutationOptions<
  DeleteFromUsersSubscriptionMutation,
  DeleteFromUsersSubscriptionMutationVariables
>;
export const DeleteLoyaltyPartnerDocument = gql`
  mutation deleteLoyaltyPartner($id: Int!) {
    deleteLoyaltyPartner(id: $id) {
      ...loyaltyPartner
    }
  }
  ${LoyaltyPartnerFragmentDoc}
`;
export type DeleteLoyaltyPartnerMutationFn = Apollo.MutationFunction<
  DeleteLoyaltyPartnerMutation,
  DeleteLoyaltyPartnerMutationVariables
>;

/**
 * __useDeleteLoyaltyPartnerMutation__
 *
 * To run a mutation, you first call `useDeleteLoyaltyPartnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLoyaltyPartnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLoyaltyPartnerMutation, { data, loading, error }] = useDeleteLoyaltyPartnerMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteLoyaltyPartnerMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteLoyaltyPartnerMutation,
    DeleteLoyaltyPartnerMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<DeleteLoyaltyPartnerMutation, DeleteLoyaltyPartnerMutationVariables>(
    DeleteLoyaltyPartnerDocument,
    options
  );
}
export type DeleteLoyaltyPartnerMutationHookResult = ReturnType<typeof useDeleteLoyaltyPartnerMutation>;
export type DeleteLoyaltyPartnerMutationResult = Apollo.MutationResult<DeleteLoyaltyPartnerMutation>;
export type DeleteLoyaltyPartnerMutationOptions = Apollo.BaseMutationOptions<
  DeleteLoyaltyPartnerMutation,
  DeleteLoyaltyPartnerMutationVariables
>;
export const DeleteLoyaltyPromoDocument = gql`
  mutation deleteLoyaltyPromo($id: Int!) {
    deleteLoyaltyPromo(id: $id) {
      ...loyaltyPromo
    }
  }
  ${LoyaltyPromoFragmentDoc}
`;
export type DeleteLoyaltyPromoMutationFn = Apollo.MutationFunction<
  DeleteLoyaltyPromoMutation,
  DeleteLoyaltyPromoMutationVariables
>;

/**
 * __useDeleteLoyaltyPromoMutation__
 *
 * To run a mutation, you first call `useDeleteLoyaltyPromoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLoyaltyPromoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLoyaltyPromoMutation, { data, loading, error }] = useDeleteLoyaltyPromoMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteLoyaltyPromoMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteLoyaltyPromoMutation, DeleteLoyaltyPromoMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<DeleteLoyaltyPromoMutation, DeleteLoyaltyPromoMutationVariables>(
    DeleteLoyaltyPromoDocument,
    options
  );
}
export type DeleteLoyaltyPromoMutationHookResult = ReturnType<typeof useDeleteLoyaltyPromoMutation>;
export type DeleteLoyaltyPromoMutationResult = Apollo.MutationResult<DeleteLoyaltyPromoMutation>;
export type DeleteLoyaltyPromoMutationOptions = Apollo.BaseMutationOptions<
  DeleteLoyaltyPromoMutation,
  DeleteLoyaltyPromoMutationVariables
>;
export const DeleteLoyaltyPromoCodeDocument = gql`
  mutation deleteLoyaltyPromoCode($id: Int!) {
    deleteLoyaltyPromoCode(id: $id) {
      ...loyaltyPromoCode
    }
  }
  ${LoyaltyPromoCodeFragmentDoc}
`;
export type DeleteLoyaltyPromoCodeMutationFn = Apollo.MutationFunction<
  DeleteLoyaltyPromoCodeMutation,
  DeleteLoyaltyPromoCodeMutationVariables
>;

/**
 * __useDeleteLoyaltyPromoCodeMutation__
 *
 * To run a mutation, you first call `useDeleteLoyaltyPromoCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLoyaltyPromoCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLoyaltyPromoCodeMutation, { data, loading, error }] = useDeleteLoyaltyPromoCodeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteLoyaltyPromoCodeMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteLoyaltyPromoCodeMutation,
    DeleteLoyaltyPromoCodeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<DeleteLoyaltyPromoCodeMutation, DeleteLoyaltyPromoCodeMutationVariables>(
    DeleteLoyaltyPromoCodeDocument,
    options
  );
}
export type DeleteLoyaltyPromoCodeMutationHookResult = ReturnType<typeof useDeleteLoyaltyPromoCodeMutation>;
export type DeleteLoyaltyPromoCodeMutationResult = Apollo.MutationResult<DeleteLoyaltyPromoCodeMutation>;
export type DeleteLoyaltyPromoCodeMutationOptions = Apollo.BaseMutationOptions<
  DeleteLoyaltyPromoCodeMutation,
  DeleteLoyaltyPromoCodeMutationVariables
>;
export const DeleteMessengerMailingDocument = gql`
  mutation deleteMessengerMailing($id: Int!) {
    deleteMessengerMailing(id: $id) {
      ...messengerMailing
    }
  }
  ${MessengerMailingFragmentDoc}
`;
export type DeleteMessengerMailingMutationFn = Apollo.MutationFunction<
  DeleteMessengerMailingMutation,
  DeleteMessengerMailingMutationVariables
>;

/**
 * __useDeleteMessengerMailingMutation__
 *
 * To run a mutation, you first call `useDeleteMessengerMailingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMessengerMailingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMessengerMailingMutation, { data, loading, error }] = useDeleteMessengerMailingMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteMessengerMailingMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteMessengerMailingMutation,
    DeleteMessengerMailingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<DeleteMessengerMailingMutation, DeleteMessengerMailingMutationVariables>(
    DeleteMessengerMailingDocument,
    options
  );
}
export type DeleteMessengerMailingMutationHookResult = ReturnType<typeof useDeleteMessengerMailingMutation>;
export type DeleteMessengerMailingMutationResult = Apollo.MutationResult<DeleteMessengerMailingMutation>;
export type DeleteMessengerMailingMutationOptions = Apollo.BaseMutationOptions<
  DeleteMessengerMailingMutation,
  DeleteMessengerMailingMutationVariables
>;
export const DeleteMessengerQueueNotificationDocument = gql`
  mutation deleteMessengerQueueNotification($id: String!) {
    deleteMessengerQueueNotification(id: $id) {
      ...messengerQueueNotification
    }
  }
  ${MessengerQueueNotificationFragmentDoc}
`;
export type DeleteMessengerQueueNotificationMutationFn = Apollo.MutationFunction<
  DeleteMessengerQueueNotificationMutation,
  DeleteMessengerQueueNotificationMutationVariables
>;

/**
 * __useDeleteMessengerQueueNotificationMutation__
 *
 * To run a mutation, you first call `useDeleteMessengerQueueNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMessengerQueueNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMessengerQueueNotificationMutation, { data, loading, error }] = useDeleteMessengerQueueNotificationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteMessengerQueueNotificationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteMessengerQueueNotificationMutation,
    DeleteMessengerQueueNotificationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    DeleteMessengerQueueNotificationMutation,
    DeleteMessengerQueueNotificationMutationVariables
  >(DeleteMessengerQueueNotificationDocument, options);
}
export type DeleteMessengerQueueNotificationMutationHookResult = ReturnType<
  typeof useDeleteMessengerQueueNotificationMutation
>;
export type DeleteMessengerQueueNotificationMutationResult = Apollo.MutationResult<DeleteMessengerQueueNotificationMutation>;
export type DeleteMessengerQueueNotificationMutationOptions = Apollo.BaseMutationOptions<
  DeleteMessengerQueueNotificationMutation,
  DeleteMessengerQueueNotificationMutationVariables
>;
export const DeleteMessengerQueueTaskDocument = gql`
  mutation deleteMessengerQueueTask($id: Int!) {
    deleteMessengerQueueTask(id: $id) {
      ...messengerQueueTask
    }
  }
  ${MessengerQueueTaskFragmentDoc}
`;
export type DeleteMessengerQueueTaskMutationFn = Apollo.MutationFunction<
  DeleteMessengerQueueTaskMutation,
  DeleteMessengerQueueTaskMutationVariables
>;

/**
 * __useDeleteMessengerQueueTaskMutation__
 *
 * To run a mutation, you first call `useDeleteMessengerQueueTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMessengerQueueTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMessengerQueueTaskMutation, { data, loading, error }] = useDeleteMessengerQueueTaskMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteMessengerQueueTaskMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteMessengerQueueTaskMutation,
    DeleteMessengerQueueTaskMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<DeleteMessengerQueueTaskMutation, DeleteMessengerQueueTaskMutationVariables>(
    DeleteMessengerQueueTaskDocument,
    options
  );
}
export type DeleteMessengerQueueTaskMutationHookResult = ReturnType<typeof useDeleteMessengerQueueTaskMutation>;
export type DeleteMessengerQueueTaskMutationResult = Apollo.MutationResult<DeleteMessengerQueueTaskMutation>;
export type DeleteMessengerQueueTaskMutationOptions = Apollo.BaseMutationOptions<
  DeleteMessengerQueueTaskMutation,
  DeleteMessengerQueueTaskMutationVariables
>;
export const DeleteMessengerTransportDocument = gql`
  mutation deleteMessengerTransport($id: Int!) {
    deleteMessengerTransport(id: $id) {
      ...messengerTransport
    }
  }
  ${MessengerTransportFragmentDoc}
`;
export type DeleteMessengerTransportMutationFn = Apollo.MutationFunction<
  DeleteMessengerTransportMutation,
  DeleteMessengerTransportMutationVariables
>;

/**
 * __useDeleteMessengerTransportMutation__
 *
 * To run a mutation, you first call `useDeleteMessengerTransportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMessengerTransportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMessengerTransportMutation, { data, loading, error }] = useDeleteMessengerTransportMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteMessengerTransportMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteMessengerTransportMutation,
    DeleteMessengerTransportMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<DeleteMessengerTransportMutation, DeleteMessengerTransportMutationVariables>(
    DeleteMessengerTransportDocument,
    options
  );
}
export type DeleteMessengerTransportMutationHookResult = ReturnType<typeof useDeleteMessengerTransportMutation>;
export type DeleteMessengerTransportMutationResult = Apollo.MutationResult<DeleteMessengerTransportMutation>;
export type DeleteMessengerTransportMutationOptions = Apollo.BaseMutationOptions<
  DeleteMessengerTransportMutation,
  DeleteMessengerTransportMutationVariables
>;
export const DeleteMobileTranslationDocument = gql`
  mutation deleteMobileTranslation($id: Int!) {
    deleteMobileTranslation(id: $id) {
      ...mobileTranslation
    }
  }
  ${MobileTranslationFragmentDoc}
`;
export type DeleteMobileTranslationMutationFn = Apollo.MutationFunction<
  DeleteMobileTranslationMutation,
  DeleteMobileTranslationMutationVariables
>;

/**
 * __useDeleteMobileTranslationMutation__
 *
 * To run a mutation, you first call `useDeleteMobileTranslationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMobileTranslationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMobileTranslationMutation, { data, loading, error }] = useDeleteMobileTranslationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteMobileTranslationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteMobileTranslationMutation,
    DeleteMobileTranslationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<DeleteMobileTranslationMutation, DeleteMobileTranslationMutationVariables>(
    DeleteMobileTranslationDocument,
    options
  );
}
export type DeleteMobileTranslationMutationHookResult = ReturnType<typeof useDeleteMobileTranslationMutation>;
export type DeleteMobileTranslationMutationResult = Apollo.MutationResult<DeleteMobileTranslationMutation>;
export type DeleteMobileTranslationMutationOptions = Apollo.BaseMutationOptions<
  DeleteMobileTranslationMutation,
  DeleteMobileTranslationMutationVariables
>;
export const DeleteMyEventReserveDocument = gql`
  mutation deleteMyEventReserve($eventId: Int!, $sectorId: Int!, $seatId: Int) {
    deleteMyEventReserve(eventId: $eventId, sectorId: $sectorId, seatId: $seatId) {
      ...reserve
    }
  }
  ${ReserveFragmentDoc}
`;
export type DeleteMyEventReserveMutationFn = Apollo.MutationFunction<
  DeleteMyEventReserveMutation,
  DeleteMyEventReserveMutationVariables
>;

/**
 * __useDeleteMyEventReserveMutation__
 *
 * To run a mutation, you first call `useDeleteMyEventReserveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMyEventReserveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMyEventReserveMutation, { data, loading, error }] = useDeleteMyEventReserveMutation({
 *   variables: {
 *      eventId: // value for 'eventId'
 *      sectorId: // value for 'sectorId'
 *      seatId: // value for 'seatId'
 *   },
 * });
 */
export function useDeleteMyEventReserveMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteMyEventReserveMutation,
    DeleteMyEventReserveMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<DeleteMyEventReserveMutation, DeleteMyEventReserveMutationVariables>(
    DeleteMyEventReserveDocument,
    options
  );
}
export type DeleteMyEventReserveMutationHookResult = ReturnType<typeof useDeleteMyEventReserveMutation>;
export type DeleteMyEventReserveMutationResult = Apollo.MutationResult<DeleteMyEventReserveMutation>;
export type DeleteMyEventReserveMutationOptions = Apollo.BaseMutationOptions<
  DeleteMyEventReserveMutation,
  DeleteMyEventReserveMutationVariables
>;
export const DeletePaymentAccountDocument = gql`
  mutation deletePaymentAccount($id: Int!) {
    deletePaymentAccount(id: $id) {
      ...paymentAccount
    }
  }
  ${PaymentAccountFragmentDoc}
`;
export type DeletePaymentAccountMutationFn = Apollo.MutationFunction<
  DeletePaymentAccountMutation,
  DeletePaymentAccountMutationVariables
>;

/**
 * __useDeletePaymentAccountMutation__
 *
 * To run a mutation, you first call `useDeletePaymentAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePaymentAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePaymentAccountMutation, { data, loading, error }] = useDeletePaymentAccountMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePaymentAccountMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeletePaymentAccountMutation,
    DeletePaymentAccountMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<DeletePaymentAccountMutation, DeletePaymentAccountMutationVariables>(
    DeletePaymentAccountDocument,
    options
  );
}
export type DeletePaymentAccountMutationHookResult = ReturnType<typeof useDeletePaymentAccountMutation>;
export type DeletePaymentAccountMutationResult = Apollo.MutationResult<DeletePaymentAccountMutation>;
export type DeletePaymentAccountMutationOptions = Apollo.BaseMutationOptions<
  DeletePaymentAccountMutation,
  DeletePaymentAccountMutationVariables
>;
export const DeletePaymentCurrencyDocument = gql`
  mutation deletePaymentCurrency($id: Int!) {
    deletePaymentCurrency(id: $id) {
      ...paymentCurrency
    }
  }
  ${PaymentCurrencyFragmentDoc}
`;
export type DeletePaymentCurrencyMutationFn = Apollo.MutationFunction<
  DeletePaymentCurrencyMutation,
  DeletePaymentCurrencyMutationVariables
>;

/**
 * __useDeletePaymentCurrencyMutation__
 *
 * To run a mutation, you first call `useDeletePaymentCurrencyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePaymentCurrencyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePaymentCurrencyMutation, { data, loading, error }] = useDeletePaymentCurrencyMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePaymentCurrencyMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeletePaymentCurrencyMutation,
    DeletePaymentCurrencyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<DeletePaymentCurrencyMutation, DeletePaymentCurrencyMutationVariables>(
    DeletePaymentCurrencyDocument,
    options
  );
}
export type DeletePaymentCurrencyMutationHookResult = ReturnType<typeof useDeletePaymentCurrencyMutation>;
export type DeletePaymentCurrencyMutationResult = Apollo.MutationResult<DeletePaymentCurrencyMutation>;
export type DeletePaymentCurrencyMutationOptions = Apollo.BaseMutationOptions<
  DeletePaymentCurrencyMutation,
  DeletePaymentCurrencyMutationVariables
>;
export const DeletePaymentProviderDocument = gql`
  mutation deletePaymentProvider($id: Int!) {
    deletePaymentProvider(id: $id) {
      ...paymentProvider
    }
  }
  ${PaymentProviderFragmentDoc}
`;
export type DeletePaymentProviderMutationFn = Apollo.MutationFunction<
  DeletePaymentProviderMutation,
  DeletePaymentProviderMutationVariables
>;

/**
 * __useDeletePaymentProviderMutation__
 *
 * To run a mutation, you first call `useDeletePaymentProviderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePaymentProviderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePaymentProviderMutation, { data, loading, error }] = useDeletePaymentProviderMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePaymentProviderMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeletePaymentProviderMutation,
    DeletePaymentProviderMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<DeletePaymentProviderMutation, DeletePaymentProviderMutationVariables>(
    DeletePaymentProviderDocument,
    options
  );
}
export type DeletePaymentProviderMutationHookResult = ReturnType<typeof useDeletePaymentProviderMutation>;
export type DeletePaymentProviderMutationResult = Apollo.MutationResult<DeletePaymentProviderMutation>;
export type DeletePaymentProviderMutationOptions = Apollo.BaseMutationOptions<
  DeletePaymentProviderMutation,
  DeletePaymentProviderMutationVariables
>;
export const DeletePaymentTransactionDocument = gql`
  mutation deletePaymentTransaction($id: Int!) {
    deletePaymentTransaction(id: $id) {
      ...paymentTransaction
    }
  }
  ${PaymentTransactionFragmentDoc}
`;
export type DeletePaymentTransactionMutationFn = Apollo.MutationFunction<
  DeletePaymentTransactionMutation,
  DeletePaymentTransactionMutationVariables
>;

/**
 * __useDeletePaymentTransactionMutation__
 *
 * To run a mutation, you first call `useDeletePaymentTransactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePaymentTransactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePaymentTransactionMutation, { data, loading, error }] = useDeletePaymentTransactionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePaymentTransactionMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeletePaymentTransactionMutation,
    DeletePaymentTransactionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<DeletePaymentTransactionMutation, DeletePaymentTransactionMutationVariables>(
    DeletePaymentTransactionDocument,
    options
  );
}
export type DeletePaymentTransactionMutationHookResult = ReturnType<typeof useDeletePaymentTransactionMutation>;
export type DeletePaymentTransactionMutationResult = Apollo.MutationResult<DeletePaymentTransactionMutation>;
export type DeletePaymentTransactionMutationOptions = Apollo.BaseMutationOptions<
  DeletePaymentTransactionMutation,
  DeletePaymentTransactionMutationVariables
>;
export const DeleteSystemPrinterDocument = gql`
  mutation deleteSystemPrinter($id: Int!) {
    deleteSystemPrinter(id: $id) {
      ...systemPrinter
    }
  }
  ${SystemPrinterFragmentDoc}
`;
export type DeleteSystemPrinterMutationFn = Apollo.MutationFunction<
  DeleteSystemPrinterMutation,
  DeleteSystemPrinterMutationVariables
>;

/**
 * __useDeleteSystemPrinterMutation__
 *
 * To run a mutation, you first call `useDeleteSystemPrinterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSystemPrinterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSystemPrinterMutation, { data, loading, error }] = useDeleteSystemPrinterMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSystemPrinterMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteSystemPrinterMutation, DeleteSystemPrinterMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<DeleteSystemPrinterMutation, DeleteSystemPrinterMutationVariables>(
    DeleteSystemPrinterDocument,
    options
  );
}
export type DeleteSystemPrinterMutationHookResult = ReturnType<typeof useDeleteSystemPrinterMutation>;
export type DeleteSystemPrinterMutationResult = Apollo.MutationResult<DeleteSystemPrinterMutation>;
export type DeleteSystemPrinterMutationOptions = Apollo.BaseMutationOptions<
  DeleteSystemPrinterMutation,
  DeleteSystemPrinterMutationVariables
>;
export const DeleteSystemTranslationDocument = gql`
  mutation deleteSystemTranslation($id: Int!) {
    deleteSystemTranslation(id: $id) {
      ...systemTranslation
    }
  }
  ${SystemTranslationFragmentDoc}
`;
export type DeleteSystemTranslationMutationFn = Apollo.MutationFunction<
  DeleteSystemTranslationMutation,
  DeleteSystemTranslationMutationVariables
>;

/**
 * __useDeleteSystemTranslationMutation__
 *
 * To run a mutation, you first call `useDeleteSystemTranslationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSystemTranslationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSystemTranslationMutation, { data, loading, error }] = useDeleteSystemTranslationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSystemTranslationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteSystemTranslationMutation,
    DeleteSystemTranslationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<DeleteSystemTranslationMutation, DeleteSystemTranslationMutationVariables>(
    DeleteSystemTranslationDocument,
    options
  );
}
export type DeleteSystemTranslationMutationHookResult = ReturnType<typeof useDeleteSystemTranslationMutation>;
export type DeleteSystemTranslationMutationResult = Apollo.MutationResult<DeleteSystemTranslationMutation>;
export type DeleteSystemTranslationMutationOptions = Apollo.BaseMutationOptions<
  DeleteSystemTranslationMutation,
  DeleteSystemTranslationMutationVariables
>;
export const DeleteSystemWebHookDocument = gql`
  mutation deleteSystemWebHook($id: Int!) {
    deleteSystemWebHook(id: $id) {
      ...systemWebHook
    }
  }
  ${SystemWebHookFragmentDoc}
`;
export type DeleteSystemWebHookMutationFn = Apollo.MutationFunction<
  DeleteSystemWebHookMutation,
  DeleteSystemWebHookMutationVariables
>;

/**
 * __useDeleteSystemWebHookMutation__
 *
 * To run a mutation, you first call `useDeleteSystemWebHookMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSystemWebHookMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSystemWebHookMutation, { data, loading, error }] = useDeleteSystemWebHookMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSystemWebHookMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteSystemWebHookMutation, DeleteSystemWebHookMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<DeleteSystemWebHookMutation, DeleteSystemWebHookMutationVariables>(
    DeleteSystemWebHookDocument,
    options
  );
}
export type DeleteSystemWebHookMutationHookResult = ReturnType<typeof useDeleteSystemWebHookMutation>;
export type DeleteSystemWebHookMutationResult = Apollo.MutationResult<DeleteSystemWebHookMutation>;
export type DeleteSystemWebHookMutationOptions = Apollo.BaseMutationOptions<
  DeleteSystemWebHookMutation,
  DeleteSystemWebHookMutationVariables
>;
export const DeleteTemplateApplePassDocument = gql`
  mutation deleteTemplateApplePass($id: Int!) {
    deleteTemplateApplePass(id: $id) {
      ...templateApplePass
    }
  }
  ${TemplateApplePassFragmentDoc}
`;
export type DeleteTemplateApplePassMutationFn = Apollo.MutationFunction<
  DeleteTemplateApplePassMutation,
  DeleteTemplateApplePassMutationVariables
>;

/**
 * __useDeleteTemplateApplePassMutation__
 *
 * To run a mutation, you first call `useDeleteTemplateApplePassMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTemplateApplePassMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTemplateApplePassMutation, { data, loading, error }] = useDeleteTemplateApplePassMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTemplateApplePassMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteTemplateApplePassMutation,
    DeleteTemplateApplePassMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<DeleteTemplateApplePassMutation, DeleteTemplateApplePassMutationVariables>(
    DeleteTemplateApplePassDocument,
    options
  );
}
export type DeleteTemplateApplePassMutationHookResult = ReturnType<typeof useDeleteTemplateApplePassMutation>;
export type DeleteTemplateApplePassMutationResult = Apollo.MutationResult<DeleteTemplateApplePassMutation>;
export type DeleteTemplateApplePassMutationOptions = Apollo.BaseMutationOptions<
  DeleteTemplateApplePassMutation,
  DeleteTemplateApplePassMutationVariables
>;
export const DeleteTemplateNotificationDocument = gql`
  mutation deleteTemplateNotification($id: Int!) {
    deleteTemplateNotification(id: $id) {
      ...templateNotification
    }
  }
  ${TemplateNotificationFragmentDoc}
`;
export type DeleteTemplateNotificationMutationFn = Apollo.MutationFunction<
  DeleteTemplateNotificationMutation,
  DeleteTemplateNotificationMutationVariables
>;

/**
 * __useDeleteTemplateNotificationMutation__
 *
 * To run a mutation, you first call `useDeleteTemplateNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTemplateNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTemplateNotificationMutation, { data, loading, error }] = useDeleteTemplateNotificationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTemplateNotificationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteTemplateNotificationMutation,
    DeleteTemplateNotificationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<DeleteTemplateNotificationMutation, DeleteTemplateNotificationMutationVariables>(
    DeleteTemplateNotificationDocument,
    options
  );
}
export type DeleteTemplateNotificationMutationHookResult = ReturnType<typeof useDeleteTemplateNotificationMutation>;
export type DeleteTemplateNotificationMutationResult = Apollo.MutationResult<DeleteTemplateNotificationMutation>;
export type DeleteTemplateNotificationMutationOptions = Apollo.BaseMutationOptions<
  DeleteTemplateNotificationMutation,
  DeleteTemplateNotificationMutationVariables
>;
export const DeleteTemplatePdfDocument = gql`
  mutation deleteTemplatePDF($id: Int!) {
    deleteTemplatePDF(id: $id) {
      ...templatePDF
    }
  }
  ${TemplatePdfFragmentDoc}
`;
export type DeleteTemplatePdfMutationFn = Apollo.MutationFunction<
  DeleteTemplatePdfMutation,
  DeleteTemplatePdfMutationVariables
>;

/**
 * __useDeleteTemplatePdfMutation__
 *
 * To run a mutation, you first call `useDeleteTemplatePdfMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTemplatePdfMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTemplatePdfMutation, { data, loading, error }] = useDeleteTemplatePdfMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTemplatePdfMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteTemplatePdfMutation, DeleteTemplatePdfMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<DeleteTemplatePdfMutation, DeleteTemplatePdfMutationVariables>(
    DeleteTemplatePdfDocument,
    options
  );
}
export type DeleteTemplatePdfMutationHookResult = ReturnType<typeof useDeleteTemplatePdfMutation>;
export type DeleteTemplatePdfMutationResult = Apollo.MutationResult<DeleteTemplatePdfMutation>;
export type DeleteTemplatePdfMutationOptions = Apollo.BaseMutationOptions<
  DeleteTemplatePdfMutation,
  DeleteTemplatePdfMutationVariables
>;
export const DeleteTournamentDocument = gql`
  mutation deleteTournament($id: Int!) {
    deleteTournament(id: $id) {
      ...tournamentFull
    }
  }
  ${TournamentFullFragmentDoc}
`;
export type DeleteTournamentMutationFn = Apollo.MutationFunction<
  DeleteTournamentMutation,
  DeleteTournamentMutationVariables
>;

/**
 * __useDeleteTournamentMutation__
 *
 * To run a mutation, you first call `useDeleteTournamentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTournamentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTournamentMutation, { data, loading, error }] = useDeleteTournamentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTournamentMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteTournamentMutation, DeleteTournamentMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<DeleteTournamentMutation, DeleteTournamentMutationVariables>(
    DeleteTournamentDocument,
    options
  );
}
export type DeleteTournamentMutationHookResult = ReturnType<typeof useDeleteTournamentMutation>;
export type DeleteTournamentMutationResult = Apollo.MutationResult<DeleteTournamentMutation>;
export type DeleteTournamentMutationOptions = Apollo.BaseMutationOptions<
  DeleteTournamentMutation,
  DeleteTournamentMutationVariables
>;
export const DeleteTournamentDisqualificationDocument = gql`
  mutation deleteTournamentDisqualification($id: Int!) {
    deleteTournamentDisqualification(id: $id) {
      ...tournamentDisqualification
    }
  }
  ${TournamentDisqualificationFragmentDoc}
`;
export type DeleteTournamentDisqualificationMutationFn = Apollo.MutationFunction<
  DeleteTournamentDisqualificationMutation,
  DeleteTournamentDisqualificationMutationVariables
>;

/**
 * __useDeleteTournamentDisqualificationMutation__
 *
 * To run a mutation, you first call `useDeleteTournamentDisqualificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTournamentDisqualificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTournamentDisqualificationMutation, { data, loading, error }] = useDeleteTournamentDisqualificationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTournamentDisqualificationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteTournamentDisqualificationMutation,
    DeleteTournamentDisqualificationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    DeleteTournamentDisqualificationMutation,
    DeleteTournamentDisqualificationMutationVariables
  >(DeleteTournamentDisqualificationDocument, options);
}
export type DeleteTournamentDisqualificationMutationHookResult = ReturnType<
  typeof useDeleteTournamentDisqualificationMutation
>;
export type DeleteTournamentDisqualificationMutationResult = Apollo.MutationResult<DeleteTournamentDisqualificationMutation>;
export type DeleteTournamentDisqualificationMutationOptions = Apollo.BaseMutationOptions<
  DeleteTournamentDisqualificationMutation,
  DeleteTournamentDisqualificationMutationVariables
>;
export const DeleteTournamentMatchDocument = gql`
  mutation deleteTournamentMatch($id: Int!) {
    deleteTournamentMatch(id: $id) {
      ...tournamentMatch
    }
  }
  ${TournamentMatchFragmentDoc}
`;
export type DeleteTournamentMatchMutationFn = Apollo.MutationFunction<
  DeleteTournamentMatchMutation,
  DeleteTournamentMatchMutationVariables
>;

/**
 * __useDeleteTournamentMatchMutation__
 *
 * To run a mutation, you first call `useDeleteTournamentMatchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTournamentMatchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTournamentMatchMutation, { data, loading, error }] = useDeleteTournamentMatchMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTournamentMatchMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteTournamentMatchMutation,
    DeleteTournamentMatchMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<DeleteTournamentMatchMutation, DeleteTournamentMatchMutationVariables>(
    DeleteTournamentMatchDocument,
    options
  );
}
export type DeleteTournamentMatchMutationHookResult = ReturnType<typeof useDeleteTournamentMatchMutation>;
export type DeleteTournamentMatchMutationResult = Apollo.MutationResult<DeleteTournamentMatchMutation>;
export type DeleteTournamentMatchMutationOptions = Apollo.BaseMutationOptions<
  DeleteTournamentMatchMutation,
  DeleteTournamentMatchMutationVariables
>;
export const DeleteTournamentMatchEventDocument = gql`
  mutation deleteTournamentMatchEvent($id: Int!) {
    deleteTournamentMatchEvent(id: $id) {
      ...tournamentMatchEvent
    }
  }
  ${TournamentMatchEventFragmentDoc}
`;
export type DeleteTournamentMatchEventMutationFn = Apollo.MutationFunction<
  DeleteTournamentMatchEventMutation,
  DeleteTournamentMatchEventMutationVariables
>;

/**
 * __useDeleteTournamentMatchEventMutation__
 *
 * To run a mutation, you first call `useDeleteTournamentMatchEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTournamentMatchEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTournamentMatchEventMutation, { data, loading, error }] = useDeleteTournamentMatchEventMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTournamentMatchEventMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteTournamentMatchEventMutation,
    DeleteTournamentMatchEventMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<DeleteTournamentMatchEventMutation, DeleteTournamentMatchEventMutationVariables>(
    DeleteTournamentMatchEventDocument,
    options
  );
}
export type DeleteTournamentMatchEventMutationHookResult = ReturnType<typeof useDeleteTournamentMatchEventMutation>;
export type DeleteTournamentMatchEventMutationResult = Apollo.MutationResult<DeleteTournamentMatchEventMutation>;
export type DeleteTournamentMatchEventMutationOptions = Apollo.BaseMutationOptions<
  DeleteTournamentMatchEventMutation,
  DeleteTournamentMatchEventMutationVariables
>;
export const DeleteTournamentMatchLineUpDocument = gql`
  mutation deleteTournamentMatchLineUp($id: Int!) {
    deleteTournamentMatchLineUp(id: $id) {
      ...tournamentMatchLineUp
    }
  }
  ${TournamentMatchLineUpFragmentDoc}
`;
export type DeleteTournamentMatchLineUpMutationFn = Apollo.MutationFunction<
  DeleteTournamentMatchLineUpMutation,
  DeleteTournamentMatchLineUpMutationVariables
>;

/**
 * __useDeleteTournamentMatchLineUpMutation__
 *
 * To run a mutation, you first call `useDeleteTournamentMatchLineUpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTournamentMatchLineUpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTournamentMatchLineUpMutation, { data, loading, error }] = useDeleteTournamentMatchLineUpMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTournamentMatchLineUpMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteTournamentMatchLineUpMutation,
    DeleteTournamentMatchLineUpMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    DeleteTournamentMatchLineUpMutation,
    DeleteTournamentMatchLineUpMutationVariables
  >(DeleteTournamentMatchLineUpDocument, options);
}
export type DeleteTournamentMatchLineUpMutationHookResult = ReturnType<typeof useDeleteTournamentMatchLineUpMutation>;
export type DeleteTournamentMatchLineUpMutationResult = Apollo.MutationResult<DeleteTournamentMatchLineUpMutation>;
export type DeleteTournamentMatchLineUpMutationOptions = Apollo.BaseMutationOptions<
  DeleteTournamentMatchLineUpMutation,
  DeleteTournamentMatchLineUpMutationVariables
>;
export const DeleteTournamentMatchProtocolDocument = gql`
  mutation deleteTournamentMatchProtocol($id: Int!) {
    deleteTournamentMatchProtocol(id: $id) {
      ...tournamentMatchProtocol
    }
  }
  ${TournamentMatchProtocolFragmentDoc}
`;
export type DeleteTournamentMatchProtocolMutationFn = Apollo.MutationFunction<
  DeleteTournamentMatchProtocolMutation,
  DeleteTournamentMatchProtocolMutationVariables
>;

/**
 * __useDeleteTournamentMatchProtocolMutation__
 *
 * To run a mutation, you first call `useDeleteTournamentMatchProtocolMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTournamentMatchProtocolMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTournamentMatchProtocolMutation, { data, loading, error }] = useDeleteTournamentMatchProtocolMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTournamentMatchProtocolMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteTournamentMatchProtocolMutation,
    DeleteTournamentMatchProtocolMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    DeleteTournamentMatchProtocolMutation,
    DeleteTournamentMatchProtocolMutationVariables
  >(DeleteTournamentMatchProtocolDocument, options);
}
export type DeleteTournamentMatchProtocolMutationHookResult = ReturnType<
  typeof useDeleteTournamentMatchProtocolMutation
>;
export type DeleteTournamentMatchProtocolMutationResult = Apollo.MutationResult<DeleteTournamentMatchProtocolMutation>;
export type DeleteTournamentMatchProtocolMutationOptions = Apollo.BaseMutationOptions<
  DeleteTournamentMatchProtocolMutation,
  DeleteTournamentMatchProtocolMutationVariables
>;
export const DeleteTournamentPlayerStatDocument = gql`
  mutation deleteTournamentPlayerStat($id: Int!) {
    deleteTournamentPlayerStat(id: $id) {
      ...tournamentPlayerStat
    }
  }
  ${TournamentPlayerStatFragmentDoc}
`;
export type DeleteTournamentPlayerStatMutationFn = Apollo.MutationFunction<
  DeleteTournamentPlayerStatMutation,
  DeleteTournamentPlayerStatMutationVariables
>;

/**
 * __useDeleteTournamentPlayerStatMutation__
 *
 * To run a mutation, you first call `useDeleteTournamentPlayerStatMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTournamentPlayerStatMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTournamentPlayerStatMutation, { data, loading, error }] = useDeleteTournamentPlayerStatMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTournamentPlayerStatMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteTournamentPlayerStatMutation,
    DeleteTournamentPlayerStatMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<DeleteTournamentPlayerStatMutation, DeleteTournamentPlayerStatMutationVariables>(
    DeleteTournamentPlayerStatDocument,
    options
  );
}
export type DeleteTournamentPlayerStatMutationHookResult = ReturnType<typeof useDeleteTournamentPlayerStatMutation>;
export type DeleteTournamentPlayerStatMutationResult = Apollo.MutationResult<DeleteTournamentPlayerStatMutation>;
export type DeleteTournamentPlayerStatMutationOptions = Apollo.BaseMutationOptions<
  DeleteTournamentPlayerStatMutation,
  DeleteTournamentPlayerStatMutationVariables
>;
export const DeleteTournamentRefereeDocument = gql`
  mutation deleteTournamentReferee($id: Int!) {
    deleteTournamentReferee(id: $id) {
      ...tournamentReferee
    }
  }
  ${TournamentRefereeFragmentDoc}
`;
export type DeleteTournamentRefereeMutationFn = Apollo.MutationFunction<
  DeleteTournamentRefereeMutation,
  DeleteTournamentRefereeMutationVariables
>;

/**
 * __useDeleteTournamentRefereeMutation__
 *
 * To run a mutation, you first call `useDeleteTournamentRefereeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTournamentRefereeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTournamentRefereeMutation, { data, loading, error }] = useDeleteTournamentRefereeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTournamentRefereeMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteTournamentRefereeMutation,
    DeleteTournamentRefereeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<DeleteTournamentRefereeMutation, DeleteTournamentRefereeMutationVariables>(
    DeleteTournamentRefereeDocument,
    options
  );
}
export type DeleteTournamentRefereeMutationHookResult = ReturnType<typeof useDeleteTournamentRefereeMutation>;
export type DeleteTournamentRefereeMutationResult = Apollo.MutationResult<DeleteTournamentRefereeMutation>;
export type DeleteTournamentRefereeMutationOptions = Apollo.BaseMutationOptions<
  DeleteTournamentRefereeMutation,
  DeleteTournamentRefereeMutationVariables
>;
export const DeleteTournamentSeasonDocument = gql`
  mutation deleteTournamentSeason($id: Int!) {
    deleteTournamentSeason(id: $id) {
      ...tournamentSeason
    }
  }
  ${TournamentSeasonFragmentDoc}
`;
export type DeleteTournamentSeasonMutationFn = Apollo.MutationFunction<
  DeleteTournamentSeasonMutation,
  DeleteTournamentSeasonMutationVariables
>;

/**
 * __useDeleteTournamentSeasonMutation__
 *
 * To run a mutation, you first call `useDeleteTournamentSeasonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTournamentSeasonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTournamentSeasonMutation, { data, loading, error }] = useDeleteTournamentSeasonMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTournamentSeasonMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteTournamentSeasonMutation,
    DeleteTournamentSeasonMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<DeleteTournamentSeasonMutation, DeleteTournamentSeasonMutationVariables>(
    DeleteTournamentSeasonDocument,
    options
  );
}
export type DeleteTournamentSeasonMutationHookResult = ReturnType<typeof useDeleteTournamentSeasonMutation>;
export type DeleteTournamentSeasonMutationResult = Apollo.MutationResult<DeleteTournamentSeasonMutation>;
export type DeleteTournamentSeasonMutationOptions = Apollo.BaseMutationOptions<
  DeleteTournamentSeasonMutation,
  DeleteTournamentSeasonMutationVariables
>;
export const DeleteTournamentSeasonTeamDocument = gql`
  mutation deleteTournamentSeasonTeam($id: Int!) {
    deleteTournamentSeasonTeam(id: $id) {
      ...tournamentSeasonTeam
    }
  }
  ${TournamentSeasonTeamFragmentDoc}
`;
export type DeleteTournamentSeasonTeamMutationFn = Apollo.MutationFunction<
  DeleteTournamentSeasonTeamMutation,
  DeleteTournamentSeasonTeamMutationVariables
>;

/**
 * __useDeleteTournamentSeasonTeamMutation__
 *
 * To run a mutation, you first call `useDeleteTournamentSeasonTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTournamentSeasonTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTournamentSeasonTeamMutation, { data, loading, error }] = useDeleteTournamentSeasonTeamMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTournamentSeasonTeamMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteTournamentSeasonTeamMutation,
    DeleteTournamentSeasonTeamMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<DeleteTournamentSeasonTeamMutation, DeleteTournamentSeasonTeamMutationVariables>(
    DeleteTournamentSeasonTeamDocument,
    options
  );
}
export type DeleteTournamentSeasonTeamMutationHookResult = ReturnType<typeof useDeleteTournamentSeasonTeamMutation>;
export type DeleteTournamentSeasonTeamMutationResult = Apollo.MutationResult<DeleteTournamentSeasonTeamMutation>;
export type DeleteTournamentSeasonTeamMutationOptions = Apollo.BaseMutationOptions<
  DeleteTournamentSeasonTeamMutation,
  DeleteTournamentSeasonTeamMutationVariables
>;
export const DeleteTournamentSeasonTeamStatDocument = gql`
  mutation deleteTournamentSeasonTeamStat($id: Int!) {
    deleteTournamentSeasonTeamStat(id: $id) {
      ...tournamentSeasonTeamStat
    }
  }
  ${TournamentSeasonTeamStatFragmentDoc}
`;
export type DeleteTournamentSeasonTeamStatMutationFn = Apollo.MutationFunction<
  DeleteTournamentSeasonTeamStatMutation,
  DeleteTournamentSeasonTeamStatMutationVariables
>;

/**
 * __useDeleteTournamentSeasonTeamStatMutation__
 *
 * To run a mutation, you first call `useDeleteTournamentSeasonTeamStatMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTournamentSeasonTeamStatMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTournamentSeasonTeamStatMutation, { data, loading, error }] = useDeleteTournamentSeasonTeamStatMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTournamentSeasonTeamStatMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteTournamentSeasonTeamStatMutation,
    DeleteTournamentSeasonTeamStatMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    DeleteTournamentSeasonTeamStatMutation,
    DeleteTournamentSeasonTeamStatMutationVariables
  >(DeleteTournamentSeasonTeamStatDocument, options);
}
export type DeleteTournamentSeasonTeamStatMutationHookResult = ReturnType<
  typeof useDeleteTournamentSeasonTeamStatMutation
>;
export type DeleteTournamentSeasonTeamStatMutationResult = Apollo.MutationResult<DeleteTournamentSeasonTeamStatMutation>;
export type DeleteTournamentSeasonTeamStatMutationOptions = Apollo.BaseMutationOptions<
  DeleteTournamentSeasonTeamStatMutation,
  DeleteTournamentSeasonTeamStatMutationVariables
>;
export const DeleteTournamentTeamDocument = gql`
  mutation deleteTournamentTeam($id: Int!) {
    deleteTournamentTeam(id: $id) {
      ...tournamentTeam
    }
  }
  ${TournamentTeamFragmentDoc}
`;
export type DeleteTournamentTeamMutationFn = Apollo.MutationFunction<
  DeleteTournamentTeamMutation,
  DeleteTournamentTeamMutationVariables
>;

/**
 * __useDeleteTournamentTeamMutation__
 *
 * To run a mutation, you first call `useDeleteTournamentTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTournamentTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTournamentTeamMutation, { data, loading, error }] = useDeleteTournamentTeamMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTournamentTeamMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteTournamentTeamMutation,
    DeleteTournamentTeamMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<DeleteTournamentTeamMutation, DeleteTournamentTeamMutationVariables>(
    DeleteTournamentTeamDocument,
    options
  );
}
export type DeleteTournamentTeamMutationHookResult = ReturnType<typeof useDeleteTournamentTeamMutation>;
export type DeleteTournamentTeamMutationResult = Apollo.MutationResult<DeleteTournamentTeamMutation>;
export type DeleteTournamentTeamMutationOptions = Apollo.BaseMutationOptions<
  DeleteTournamentTeamMutation,
  DeleteTournamentTeamMutationVariables
>;
export const DeleteTournamentTeamMemberDocument = gql`
  mutation deleteTournamentTeamMember($id: Int!) {
    deleteTournamentTeamMember(id: $id) {
      ...tournamentTeamMember
    }
  }
  ${TournamentTeamMemberFragmentDoc}
`;
export type DeleteTournamentTeamMemberMutationFn = Apollo.MutationFunction<
  DeleteTournamentTeamMemberMutation,
  DeleteTournamentTeamMemberMutationVariables
>;

/**
 * __useDeleteTournamentTeamMemberMutation__
 *
 * To run a mutation, you first call `useDeleteTournamentTeamMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTournamentTeamMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTournamentTeamMemberMutation, { data, loading, error }] = useDeleteTournamentTeamMemberMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTournamentTeamMemberMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteTournamentTeamMemberMutation,
    DeleteTournamentTeamMemberMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<DeleteTournamentTeamMemberMutation, DeleteTournamentTeamMemberMutationVariables>(
    DeleteTournamentTeamMemberDocument,
    options
  );
}
export type DeleteTournamentTeamMemberMutationHookResult = ReturnType<typeof useDeleteTournamentTeamMemberMutation>;
export type DeleteTournamentTeamMemberMutationResult = Apollo.MutationResult<DeleteTournamentTeamMemberMutation>;
export type DeleteTournamentTeamMemberMutationOptions = Apollo.BaseMutationOptions<
  DeleteTournamentTeamMemberMutation,
  DeleteTournamentTeamMemberMutationVariables
>;
export const DeleteTournamentTourDocument = gql`
  mutation deleteTournamentTour($id: Int!) {
    deleteTournamentTour(id: $id) {
      ...tournamentTour
    }
  }
  ${TournamentTourFragmentDoc}
`;
export type DeleteTournamentTourMutationFn = Apollo.MutationFunction<
  DeleteTournamentTourMutation,
  DeleteTournamentTourMutationVariables
>;

/**
 * __useDeleteTournamentTourMutation__
 *
 * To run a mutation, you first call `useDeleteTournamentTourMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTournamentTourMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTournamentTourMutation, { data, loading, error }] = useDeleteTournamentTourMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTournamentTourMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteTournamentTourMutation,
    DeleteTournamentTourMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<DeleteTournamentTourMutation, DeleteTournamentTourMutationVariables>(
    DeleteTournamentTourDocument,
    options
  );
}
export type DeleteTournamentTourMutationHookResult = ReturnType<typeof useDeleteTournamentTourMutation>;
export type DeleteTournamentTourMutationResult = Apollo.MutationResult<DeleteTournamentTourMutation>;
export type DeleteTournamentTourMutationOptions = Apollo.BaseMutationOptions<
  DeleteTournamentTourMutation,
  DeleteTournamentTourMutationVariables
>;
export const DeleteUserDocument = gql`
  mutation deleteUser {
    deleteUser
  }
`;
export type DeleteUserMutationFn = Apollo.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *   },
 * });
 */
export function useDeleteUserMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, options);
}
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>;
export const DeleteUserFromGroupDocument = gql`
  mutation deleteUserFromGroup($groupId: [Int!]!, $query: DefaultQueryInput!) {
    deleteUserFromGroup(groupId: $groupId, query: $query) {
      ...systemAsyncTask
    }
  }
  ${SystemAsyncTaskFragmentDoc}
`;
export type DeleteUserFromGroupMutationFn = Apollo.MutationFunction<
  DeleteUserFromGroupMutation,
  DeleteUserFromGroupMutationVariables
>;

/**
 * __useDeleteUserFromGroupMutation__
 *
 * To run a mutation, you first call `useDeleteUserFromGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserFromGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserFromGroupMutation, { data, loading, error }] = useDeleteUserFromGroupMutation({
 *   variables: {
 *      groupId: // value for 'groupId'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useDeleteUserFromGroupMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteUserFromGroupMutation, DeleteUserFromGroupMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<DeleteUserFromGroupMutation, DeleteUserFromGroupMutationVariables>(
    DeleteUserFromGroupDocument,
    options
  );
}
export type DeleteUserFromGroupMutationHookResult = ReturnType<typeof useDeleteUserFromGroupMutation>;
export type DeleteUserFromGroupMutationResult = Apollo.MutationResult<DeleteUserFromGroupMutation>;
export type DeleteUserFromGroupMutationOptions = Apollo.BaseMutationOptions<
  DeleteUserFromGroupMutation,
  DeleteUserFromGroupMutationVariables
>;
export const DeleteUserGroupDocument = gql`
  mutation deleteUserGroup($id: Int!) {
    deleteUserGroup(id: $id) {
      ...userGroup
    }
  }
  ${UserGroupFragmentDoc}
`;
export type DeleteUserGroupMutationFn = Apollo.MutationFunction<
  DeleteUserGroupMutation,
  DeleteUserGroupMutationVariables
>;

/**
 * __useDeleteUserGroupMutation__
 *
 * To run a mutation, you first call `useDeleteUserGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserGroupMutation, { data, loading, error }] = useDeleteUserGroupMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUserGroupMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteUserGroupMutation, DeleteUserGroupMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<DeleteUserGroupMutation, DeleteUserGroupMutationVariables>(
    DeleteUserGroupDocument,
    options
  );
}
export type DeleteUserGroupMutationHookResult = ReturnType<typeof useDeleteUserGroupMutation>;
export type DeleteUserGroupMutationResult = Apollo.MutationResult<DeleteUserGroupMutation>;
export type DeleteUserGroupMutationOptions = Apollo.BaseMutationOptions<
  DeleteUserGroupMutation,
  DeleteUserGroupMutationVariables
>;
export const DeleteUserSubscriberDocument = gql`
  mutation deleteUserSubscriber($id: String!) {
    deleteUserSubscriber(id: $id) {
      ...userSubscriber
    }
  }
  ${UserSubscriberFragmentDoc}
`;
export type DeleteUserSubscriberMutationFn = Apollo.MutationFunction<
  DeleteUserSubscriberMutation,
  DeleteUserSubscriberMutationVariables
>;

/**
 * __useDeleteUserSubscriberMutation__
 *
 * To run a mutation, you first call `useDeleteUserSubscriberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserSubscriberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserSubscriberMutation, { data, loading, error }] = useDeleteUserSubscriberMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUserSubscriberMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteUserSubscriberMutation,
    DeleteUserSubscriberMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<DeleteUserSubscriberMutation, DeleteUserSubscriberMutationVariables>(
    DeleteUserSubscriberDocument,
    options
  );
}
export type DeleteUserSubscriberMutationHookResult = ReturnType<typeof useDeleteUserSubscriberMutation>;
export type DeleteUserSubscriberMutationResult = Apollo.MutationResult<DeleteUserSubscriberMutation>;
export type DeleteUserSubscriberMutationOptions = Apollo.BaseMutationOptions<
  DeleteUserSubscriberMutation,
  DeleteUserSubscriberMutationVariables
>;
export const DeleteUserSubscribersDocument = gql`
  mutation deleteUserSubscribers($id: [String!]!) {
    deleteUserSubscribers(id: $id)
  }
`;
export type DeleteUserSubscribersMutationFn = Apollo.MutationFunction<
  DeleteUserSubscribersMutation,
  DeleteUserSubscribersMutationVariables
>;

/**
 * __useDeleteUserSubscribersMutation__
 *
 * To run a mutation, you first call `useDeleteUserSubscribersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserSubscribersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserSubscribersMutation, { data, loading, error }] = useDeleteUserSubscribersMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUserSubscribersMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteUserSubscribersMutation,
    DeleteUserSubscribersMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<DeleteUserSubscribersMutation, DeleteUserSubscribersMutationVariables>(
    DeleteUserSubscribersDocument,
    options
  );
}
export type DeleteUserSubscribersMutationHookResult = ReturnType<typeof useDeleteUserSubscribersMutation>;
export type DeleteUserSubscribersMutationResult = Apollo.MutationResult<DeleteUserSubscribersMutation>;
export type DeleteUserSubscribersMutationOptions = Apollo.BaseMutationOptions<
  DeleteUserSubscribersMutation,
  DeleteUserSubscribersMutationVariables
>;
export const DeleteUserSubscriptionDocument = gql`
  mutation deleteUserSubscription($id: Int!) {
    deleteUserSubscription(id: $id) {
      ...userSubscription
    }
  }
  ${UserSubscriptionFragmentDoc}
`;
export type DeleteUserSubscriptionMutationFn = Apollo.MutationFunction<
  DeleteUserSubscriptionMutation,
  DeleteUserSubscriptionMutationVariables
>;

/**
 * __useDeleteUserSubscriptionMutation__
 *
 * To run a mutation, you first call `useDeleteUserSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserSubscriptionMutation, { data, loading, error }] = useDeleteUserSubscriptionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUserSubscriptionMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteUserSubscriptionMutation,
    DeleteUserSubscriptionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<DeleteUserSubscriptionMutation, DeleteUserSubscriptionMutationVariables>(
    DeleteUserSubscriptionDocument,
    options
  );
}
export type DeleteUserSubscriptionMutationHookResult = ReturnType<typeof useDeleteUserSubscriptionMutation>;
export type DeleteUserSubscriptionMutationResult = Apollo.MutationResult<DeleteUserSubscriptionMutation>;
export type DeleteUserSubscriptionMutationOptions = Apollo.BaseMutationOptions<
  DeleteUserSubscriptionMutation,
  DeleteUserSubscriptionMutationVariables
>;
export const DepositToAccountDocument = gql`
  mutation depositToAccount($data: PaymentAccountOperationInput!) {
    depositToAccount(data: $data) {
      amount
    }
  }
`;
export type DepositToAccountMutationFn = Apollo.MutationFunction<
  DepositToAccountMutation,
  DepositToAccountMutationVariables
>;

/**
 * __useDepositToAccountMutation__
 *
 * To run a mutation, you first call `useDepositToAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDepositToAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [depositToAccountMutation, { data, loading, error }] = useDepositToAccountMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useDepositToAccountMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<DepositToAccountMutation, DepositToAccountMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<DepositToAccountMutation, DepositToAccountMutationVariables>(
    DepositToAccountDocument,
    options
  );
}
export type DepositToAccountMutationHookResult = ReturnType<typeof useDepositToAccountMutation>;
export type DepositToAccountMutationResult = Apollo.MutationResult<DepositToAccountMutation>;
export type DepositToAccountMutationOptions = Apollo.BaseMutationOptions<
  DepositToAccountMutation,
  DepositToAccountMutationVariables
>;
export const ExportPromocodesDocument = gql`
  mutation exportPromocodes($promoId: Int!, $format: ExportFormat!) {
    exportPromocodes(promoId: $promoId, format: $format)
  }
`;
export type ExportPromocodesMutationFn = Apollo.MutationFunction<
  ExportPromocodesMutation,
  ExportPromocodesMutationVariables
>;

/**
 * __useExportPromocodesMutation__
 *
 * To run a mutation, you first call `useExportPromocodesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportPromocodesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportPromocodesMutation, { data, loading, error }] = useExportPromocodesMutation({
 *   variables: {
 *      promoId: // value for 'promoId'
 *      format: // value for 'format'
 *   },
 * });
 */
export function useExportPromocodesMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<ExportPromocodesMutation, ExportPromocodesMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<ExportPromocodesMutation, ExportPromocodesMutationVariables>(
    ExportPromocodesDocument,
    options
  );
}
export type ExportPromocodesMutationHookResult = ReturnType<typeof useExportPromocodesMutation>;
export type ExportPromocodesMutationResult = Apollo.MutationResult<ExportPromocodesMutation>;
export type ExportPromocodesMutationOptions = Apollo.BaseMutationOptions<
  ExportPromocodesMutation,
  ExportPromocodesMutationVariables
>;
export const GenerateDocsByEventOrderDocument = gql`
  mutation generateDocsByEventOrder($userId: Int!, $notify: Boolean!, $orderId: Int, $ticketId: Int) {
    generateDocsByEventOrder(userId: $userId, notify: $notify, orderId: $orderId, ticketId: $ticketId)
  }
`;
export type GenerateDocsByEventOrderMutationFn = Apollo.MutationFunction<
  GenerateDocsByEventOrderMutation,
  GenerateDocsByEventOrderMutationVariables
>;

/**
 * __useGenerateDocsByEventOrderMutation__
 *
 * To run a mutation, you first call `useGenerateDocsByEventOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateDocsByEventOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateDocsByEventOrderMutation, { data, loading, error }] = useGenerateDocsByEventOrderMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      notify: // value for 'notify'
 *      orderId: // value for 'orderId'
 *      ticketId: // value for 'ticketId'
 *   },
 * });
 */
export function useGenerateDocsByEventOrderMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    GenerateDocsByEventOrderMutation,
    GenerateDocsByEventOrderMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<GenerateDocsByEventOrderMutation, GenerateDocsByEventOrderMutationVariables>(
    GenerateDocsByEventOrderDocument,
    options
  );
}
export type GenerateDocsByEventOrderMutationHookResult = ReturnType<typeof useGenerateDocsByEventOrderMutation>;
export type GenerateDocsByEventOrderMutationResult = Apollo.MutationResult<GenerateDocsByEventOrderMutation>;
export type GenerateDocsByEventOrderMutationOptions = Apollo.BaseMutationOptions<
  GenerateDocsByEventOrderMutation,
  GenerateDocsByEventOrderMutationVariables
>;
export const GenerateLoyaltyPromoCodesDocument = gql`
  mutation generateLoyaltyPromoCodes($promoId: Int!, $count: Int!, $length: Int!) {
    generateLoyaltyPromoCodes(promoId: $promoId, count: $count, length: $length)
  }
`;
export type GenerateLoyaltyPromoCodesMutationFn = Apollo.MutationFunction<
  GenerateLoyaltyPromoCodesMutation,
  GenerateLoyaltyPromoCodesMutationVariables
>;

/**
 * __useGenerateLoyaltyPromoCodesMutation__
 *
 * To run a mutation, you first call `useGenerateLoyaltyPromoCodesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateLoyaltyPromoCodesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateLoyaltyPromoCodesMutation, { data, loading, error }] = useGenerateLoyaltyPromoCodesMutation({
 *   variables: {
 *      promoId: // value for 'promoId'
 *      count: // value for 'count'
 *      length: // value for 'length'
 *   },
 * });
 */
export function useGenerateLoyaltyPromoCodesMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    GenerateLoyaltyPromoCodesMutation,
    GenerateLoyaltyPromoCodesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<GenerateLoyaltyPromoCodesMutation, GenerateLoyaltyPromoCodesMutationVariables>(
    GenerateLoyaltyPromoCodesDocument,
    options
  );
}
export type GenerateLoyaltyPromoCodesMutationHookResult = ReturnType<typeof useGenerateLoyaltyPromoCodesMutation>;
export type GenerateLoyaltyPromoCodesMutationResult = Apollo.MutationResult<GenerateLoyaltyPromoCodesMutation>;
export type GenerateLoyaltyPromoCodesMutationOptions = Apollo.BaseMutationOptions<
  GenerateLoyaltyPromoCodesMutation,
  GenerateLoyaltyPromoCodesMutationVariables
>;
export const GrantAccessDocument = gql`
  mutation grantAccess($userId: ID!, $aclId: ID!) {
    grantAccess(userId: $userId, aclId: $aclId)
  }
`;
export type GrantAccessMutationFn = Apollo.MutationFunction<GrantAccessMutation, GrantAccessMutationVariables>;

/**
 * __useGrantAccessMutation__
 *
 * To run a mutation, you first call `useGrantAccessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGrantAccessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [grantAccessMutation, { data, loading, error }] = useGrantAccessMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      aclId: // value for 'aclId'
 *   },
 * });
 */
export function useGrantAccessMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<GrantAccessMutation, GrantAccessMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<GrantAccessMutation, GrantAccessMutationVariables>(GrantAccessDocument, options);
}
export type GrantAccessMutationHookResult = ReturnType<typeof useGrantAccessMutation>;
export type GrantAccessMutationResult = Apollo.MutationResult<GrantAccessMutation>;
export type GrantAccessMutationOptions = Apollo.BaseMutationOptions<GrantAccessMutation, GrantAccessMutationVariables>;
export const ImportPromocodesDocument = gql`
  mutation importPromocodes($promoId: Int!, $file: Upload!) {
    importPromocodes(promoId: $promoId, file: $file)
  }
`;
export type ImportPromocodesMutationFn = Apollo.MutationFunction<
  ImportPromocodesMutation,
  ImportPromocodesMutationVariables
>;

/**
 * __useImportPromocodesMutation__
 *
 * To run a mutation, you first call `useImportPromocodesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportPromocodesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importPromocodesMutation, { data, loading, error }] = useImportPromocodesMutation({
 *   variables: {
 *      promoId: // value for 'promoId'
 *      file: // value for 'file'
 *   },
 * });
 */
export function useImportPromocodesMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<ImportPromocodesMutation, ImportPromocodesMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<ImportPromocodesMutation, ImportPromocodesMutationVariables>(
    ImportPromocodesDocument,
    options
  );
}
export type ImportPromocodesMutationHookResult = ReturnType<typeof useImportPromocodesMutation>;
export type ImportPromocodesMutationResult = Apollo.MutationResult<ImportPromocodesMutation>;
export type ImportPromocodesMutationOptions = Apollo.BaseMutationOptions<
  ImportPromocodesMutation,
  ImportPromocodesMutationVariables
>;
export const ImportRecipientsToQueueNotificationDocument = gql`
  mutation importRecipientsToQueueNotification($mailingId: Int!, $file: Upload!) {
    importRecipientsToQueueNotification(mailingId: $mailingId, file: $file) {
      ...messengerQueueTask
    }
  }
  ${MessengerQueueTaskFragmentDoc}
`;
export type ImportRecipientsToQueueNotificationMutationFn = Apollo.MutationFunction<
  ImportRecipientsToQueueNotificationMutation,
  ImportRecipientsToQueueNotificationMutationVariables
>;

/**
 * __useImportRecipientsToQueueNotificationMutation__
 *
 * To run a mutation, you first call `useImportRecipientsToQueueNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportRecipientsToQueueNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importRecipientsToQueueNotificationMutation, { data, loading, error }] = useImportRecipientsToQueueNotificationMutation({
 *   variables: {
 *      mailingId: // value for 'mailingId'
 *      file: // value for 'file'
 *   },
 * });
 */
export function useImportRecipientsToQueueNotificationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ImportRecipientsToQueueNotificationMutation,
    ImportRecipientsToQueueNotificationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    ImportRecipientsToQueueNotificationMutation,
    ImportRecipientsToQueueNotificationMutationVariables
  >(ImportRecipientsToQueueNotificationDocument, options);
}
export type ImportRecipientsToQueueNotificationMutationHookResult = ReturnType<
  typeof useImportRecipientsToQueueNotificationMutation
>;
export type ImportRecipientsToQueueNotificationMutationResult = Apollo.MutationResult<ImportRecipientsToQueueNotificationMutation>;
export type ImportRecipientsToQueueNotificationMutationOptions = Apollo.BaseMutationOptions<
  ImportRecipientsToQueueNotificationMutation,
  ImportRecipientsToQueueNotificationMutationVariables
>;
export const ImportTemplateNotificationsDocument = gql`
  mutation importTemplateNotifications($providerId: Int, $templateId: Int) {
    importTemplateNotifications(providerId: $providerId, templateId: $templateId)
  }
`;
export type ImportTemplateNotificationsMutationFn = Apollo.MutationFunction<
  ImportTemplateNotificationsMutation,
  ImportTemplateNotificationsMutationVariables
>;

/**
 * __useImportTemplateNotificationsMutation__
 *
 * To run a mutation, you first call `useImportTemplateNotificationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportTemplateNotificationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importTemplateNotificationsMutation, { data, loading, error }] = useImportTemplateNotificationsMutation({
 *   variables: {
 *      providerId: // value for 'providerId'
 *      templateId: // value for 'templateId'
 *   },
 * });
 */
export function useImportTemplateNotificationsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ImportTemplateNotificationsMutation,
    ImportTemplateNotificationsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    ImportTemplateNotificationsMutation,
    ImportTemplateNotificationsMutationVariables
  >(ImportTemplateNotificationsDocument, options);
}
export type ImportTemplateNotificationsMutationHookResult = ReturnType<typeof useImportTemplateNotificationsMutation>;
export type ImportTemplateNotificationsMutationResult = Apollo.MutationResult<ImportTemplateNotificationsMutation>;
export type ImportTemplateNotificationsMutationOptions = Apollo.BaseMutationOptions<
  ImportTemplateNotificationsMutation,
  ImportTemplateNotificationsMutationVariables
>;
export const ImportUserSubscribersDocument = gql`
  mutation importUserSubscribers($subscriptionId: Int!, $file: Upload!) {
    importUserSubscribers(subscriptionId: $subscriptionId, file: $file)
  }
`;
export type ImportUserSubscribersMutationFn = Apollo.MutationFunction<
  ImportUserSubscribersMutation,
  ImportUserSubscribersMutationVariables
>;

/**
 * __useImportUserSubscribersMutation__
 *
 * To run a mutation, you first call `useImportUserSubscribersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportUserSubscribersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importUserSubscribersMutation, { data, loading, error }] = useImportUserSubscribersMutation({
 *   variables: {
 *      subscriptionId: // value for 'subscriptionId'
 *      file: // value for 'file'
 *   },
 * });
 */
export function useImportUserSubscribersMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ImportUserSubscribersMutation,
    ImportUserSubscribersMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<ImportUserSubscribersMutation, ImportUserSubscribersMutationVariables>(
    ImportUserSubscribersDocument,
    options
  );
}
export type ImportUserSubscribersMutationHookResult = ReturnType<typeof useImportUserSubscribersMutation>;
export type ImportUserSubscribersMutationResult = Apollo.MutationResult<ImportUserSubscribersMutation>;
export type ImportUserSubscribersMutationOptions = Apollo.BaseMutationOptions<
  ImportUserSubscribersMutation,
  ImportUserSubscribersMutationVariables
>;
export const InitMyEventSessionDocument = gql`
  mutation initMyEventSession {
    initMyEventSession {
      id
    }
  }
`;
export type InitMyEventSessionMutationFn = Apollo.MutationFunction<
  InitMyEventSessionMutation,
  InitMyEventSessionMutationVariables
>;

/**
 * __useInitMyEventSessionMutation__
 *
 * To run a mutation, you first call `useInitMyEventSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInitMyEventSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [initMyEventSessionMutation, { data, loading, error }] = useInitMyEventSessionMutation({
 *   variables: {
 *   },
 * });
 */
export function useInitMyEventSessionMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<InitMyEventSessionMutation, InitMyEventSessionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<InitMyEventSessionMutation, InitMyEventSessionMutationVariables>(
    InitMyEventSessionDocument,
    options
  );
}
export type InitMyEventSessionMutationHookResult = ReturnType<typeof useInitMyEventSessionMutation>;
export type InitMyEventSessionMutationResult = Apollo.MutationResult<InitMyEventSessionMutation>;
export type InitMyEventSessionMutationOptions = Apollo.BaseMutationOptions<
  InitMyEventSessionMutation,
  InitMyEventSessionMutationVariables
>;
export const InitPaymentGatewayDocument = gql`
  mutation initPaymentGateway($data: InitPaymentGatewayInput!) {
    initPaymentGateway(data: $data) {
      orderId
      orderType
      code
      provider
      settings
    }
  }
`;
export type InitPaymentGatewayMutationFn = Apollo.MutationFunction<
  InitPaymentGatewayMutation,
  InitPaymentGatewayMutationVariables
>;

/**
 * __useInitPaymentGatewayMutation__
 *
 * To run a mutation, you first call `useInitPaymentGatewayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInitPaymentGatewayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [initPaymentGatewayMutation, { data, loading, error }] = useInitPaymentGatewayMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useInitPaymentGatewayMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<InitPaymentGatewayMutation, InitPaymentGatewayMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<InitPaymentGatewayMutation, InitPaymentGatewayMutationVariables>(
    InitPaymentGatewayDocument,
    options
  );
}
export type InitPaymentGatewayMutationHookResult = ReturnType<typeof useInitPaymentGatewayMutation>;
export type InitPaymentGatewayMutationResult = Apollo.MutationResult<InitPaymentGatewayMutation>;
export type InitPaymentGatewayMutationOptions = Apollo.BaseMutationOptions<
  InitPaymentGatewayMutation,
  InitPaymentGatewayMutationVariables
>;
export const LoginUserDocument = gql`
  mutation loginUser($username: String!, $password: String!) {
    loginUser(username: $username, password: $password) {
      ...userAuthResponse
    }
  }
  ${UserAuthResponseFragmentDoc}
`;
export type LoginUserMutationFn = Apollo.MutationFunction<LoginUserMutation, LoginUserMutationVariables>;

/**
 * __useLoginUserMutation__
 *
 * To run a mutation, you first call `useLoginUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginUserMutation, { data, loading, error }] = useLoginUserMutation({
 *   variables: {
 *      username: // value for 'username'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginUserMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<LoginUserMutation, LoginUserMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<LoginUserMutation, LoginUserMutationVariables>(LoginUserDocument, options);
}
export type LoginUserMutationHookResult = ReturnType<typeof useLoginUserMutation>;
export type LoginUserMutationResult = Apollo.MutationResult<LoginUserMutation>;
export type LoginUserMutationOptions = Apollo.BaseMutationOptions<LoginUserMutation, LoginUserMutationVariables>;
export const LoginUserSocialDocument = gql`
  mutation loginUserSocial($type: UserSocialAccountSocialNetwork!, $data: SocialAuthData!) {
    loginUserSocial(type: $type, data: $data) {
      accessToken
      refreshToken
      user {
        ...user
      }
    }
  }
  ${UserFragmentDoc}
`;
export type LoginUserSocialMutationFn = Apollo.MutationFunction<
  LoginUserSocialMutation,
  LoginUserSocialMutationVariables
>;

/**
 * __useLoginUserSocialMutation__
 *
 * To run a mutation, you first call `useLoginUserSocialMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginUserSocialMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginUserSocialMutation, { data, loading, error }] = useLoginUserSocialMutation({
 *   variables: {
 *      type: // value for 'type'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useLoginUserSocialMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<LoginUserSocialMutation, LoginUserSocialMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<LoginUserSocialMutation, LoginUserSocialMutationVariables>(
    LoginUserSocialDocument,
    options
  );
}
export type LoginUserSocialMutationHookResult = ReturnType<typeof useLoginUserSocialMutation>;
export type LoginUserSocialMutationResult = Apollo.MutationResult<LoginUserSocialMutation>;
export type LoginUserSocialMutationOptions = Apollo.BaseMutationOptions<
  LoginUserSocialMutation,
  LoginUserSocialMutationVariables
>;
export const RefundOrderDocument = gql`
  mutation refundOrder($data: RefundOrderInput!) {
    refundOrder(data: $data) {
      ...paymentTransaction
    }
  }
  ${PaymentTransactionFragmentDoc}
`;
export type RefundOrderMutationFn = Apollo.MutationFunction<RefundOrderMutation, RefundOrderMutationVariables>;

/**
 * __useRefundOrderMutation__
 *
 * To run a mutation, you first call `useRefundOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefundOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refundOrderMutation, { data, loading, error }] = useRefundOrderMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useRefundOrderMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<RefundOrderMutation, RefundOrderMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<RefundOrderMutation, RefundOrderMutationVariables>(RefundOrderDocument, options);
}
export type RefundOrderMutationHookResult = ReturnType<typeof useRefundOrderMutation>;
export type RefundOrderMutationResult = Apollo.MutationResult<RefundOrderMutation>;
export type RefundOrderMutationOptions = Apollo.BaseMutationOptions<RefundOrderMutation, RefundOrderMutationVariables>;
export const RegisterUserDocument = gql`
  mutation registerUser($data: UserCreateInput!) {
    registerUser(data: $data) {
      user {
        ...user
      }
      refreshToken
      accessToken
    }
  }
  ${UserFragmentDoc}
`;
export type RegisterUserMutationFn = Apollo.MutationFunction<RegisterUserMutation, RegisterUserMutationVariables>;

/**
 * __useRegisterUserMutation__
 *
 * To run a mutation, you first call `useRegisterUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerUserMutation, { data, loading, error }] = useRegisterUserMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useRegisterUserMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<RegisterUserMutation, RegisterUserMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<RegisterUserMutation, RegisterUserMutationVariables>(
    RegisterUserDocument,
    options
  );
}
export type RegisterUserMutationHookResult = ReturnType<typeof useRegisterUserMutation>;
export type RegisterUserMutationResult = Apollo.MutationResult<RegisterUserMutation>;
export type RegisterUserMutationOptions = Apollo.BaseMutationOptions<
  RegisterUserMutation,
  RegisterUserMutationVariables
>;
export const ResetLoyaltyPromoCodesDocument = gql`
  mutation resetLoyaltyPromoCodes($promoId: Int!) {
    resetLoyaltyPromoCodes(promoId: $promoId)
  }
`;
export type ResetLoyaltyPromoCodesMutationFn = Apollo.MutationFunction<
  ResetLoyaltyPromoCodesMutation,
  ResetLoyaltyPromoCodesMutationVariables
>;

/**
 * __useResetLoyaltyPromoCodesMutation__
 *
 * To run a mutation, you first call `useResetLoyaltyPromoCodesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetLoyaltyPromoCodesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetLoyaltyPromoCodesMutation, { data, loading, error }] = useResetLoyaltyPromoCodesMutation({
 *   variables: {
 *      promoId: // value for 'promoId'
 *   },
 * });
 */
export function useResetLoyaltyPromoCodesMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ResetLoyaltyPromoCodesMutation,
    ResetLoyaltyPromoCodesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<ResetLoyaltyPromoCodesMutation, ResetLoyaltyPromoCodesMutationVariables>(
    ResetLoyaltyPromoCodesDocument,
    options
  );
}
export type ResetLoyaltyPromoCodesMutationHookResult = ReturnType<typeof useResetLoyaltyPromoCodesMutation>;
export type ResetLoyaltyPromoCodesMutationResult = Apollo.MutationResult<ResetLoyaltyPromoCodesMutation>;
export type ResetLoyaltyPromoCodesMutationOptions = Apollo.BaseMutationOptions<
  ResetLoyaltyPromoCodesMutation,
  ResetLoyaltyPromoCodesMutationVariables
>;
export const ResetUserPasswordDocument = gql`
  mutation resetUserPassword($email: String, $password: String, $confirmationKey: String) {
    resetUserPassword(email: $email, password: $password, confirmationKey: $confirmationKey)
  }
`;
export type ResetUserPasswordMutationFn = Apollo.MutationFunction<
  ResetUserPasswordMutation,
  ResetUserPasswordMutationVariables
>;

/**
 * __useResetUserPasswordMutation__
 *
 * To run a mutation, you first call `useResetUserPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetUserPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetUserPasswordMutation, { data, loading, error }] = useResetUserPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      confirmationKey: // value for 'confirmationKey'
 *   },
 * });
 */
export function useResetUserPasswordMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<ResetUserPasswordMutation, ResetUserPasswordMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<ResetUserPasswordMutation, ResetUserPasswordMutationVariables>(
    ResetUserPasswordDocument,
    options
  );
}
export type ResetUserPasswordMutationHookResult = ReturnType<typeof useResetUserPasswordMutation>;
export type ResetUserPasswordMutationResult = Apollo.MutationResult<ResetUserPasswordMutation>;
export type ResetUserPasswordMutationOptions = Apollo.BaseMutationOptions<
  ResetUserPasswordMutation,
  ResetUserPasswordMutationVariables
>;
export const RevokeAccessDocument = gql`
  mutation revokeAccess($userId: ID!, $aclId: ID!) {
    revokeAccess(userId: $userId, aclId: $aclId)
  }
`;
export type RevokeAccessMutationFn = Apollo.MutationFunction<RevokeAccessMutation, RevokeAccessMutationVariables>;

/**
 * __useRevokeAccessMutation__
 *
 * To run a mutation, you first call `useRevokeAccessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevokeAccessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revokeAccessMutation, { data, loading, error }] = useRevokeAccessMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      aclId: // value for 'aclId'
 *   },
 * });
 */
export function useRevokeAccessMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<RevokeAccessMutation, RevokeAccessMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<RevokeAccessMutation, RevokeAccessMutationVariables>(
    RevokeAccessDocument,
    options
  );
}
export type RevokeAccessMutationHookResult = ReturnType<typeof useRevokeAccessMutation>;
export type RevokeAccessMutationResult = Apollo.MutationResult<RevokeAccessMutation>;
export type RevokeAccessMutationOptions = Apollo.BaseMutationOptions<
  RevokeAccessMutation,
  RevokeAccessMutationVariables
>;
export const SaveDisputeSettingsDocument = gql`
  mutation saveDisputeSettings($data: DisputeSettingsUpdateInput!) {
    saveDisputeSettings(data: $data) {
      ...disputeSettings
    }
  }
  ${DisputeSettingsFragmentDoc}
`;
export type SaveDisputeSettingsMutationFn = Apollo.MutationFunction<
  SaveDisputeSettingsMutation,
  SaveDisputeSettingsMutationVariables
>;

/**
 * __useSaveDisputeSettingsMutation__
 *
 * To run a mutation, you first call `useSaveDisputeSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveDisputeSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveDisputeSettingsMutation, { data, loading, error }] = useSaveDisputeSettingsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSaveDisputeSettingsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<SaveDisputeSettingsMutation, SaveDisputeSettingsMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<SaveDisputeSettingsMutation, SaveDisputeSettingsMutationVariables>(
    SaveDisputeSettingsDocument,
    options
  );
}
export type SaveDisputeSettingsMutationHookResult = ReturnType<typeof useSaveDisputeSettingsMutation>;
export type SaveDisputeSettingsMutationResult = Apollo.MutationResult<SaveDisputeSettingsMutation>;
export type SaveDisputeSettingsMutationOptions = Apollo.BaseMutationOptions<
  SaveDisputeSettingsMutation,
  SaveDisputeSettingsMutationVariables
>;
export const SaveMobileTranslationsDocument = gql`
  mutation saveMobileTranslations($data: [MobileTranslationSaveInput!]) {
    saveMobileTranslations(data: $data) {
      ...mobileTranslation
    }
  }
  ${MobileTranslationFragmentDoc}
`;
export type SaveMobileTranslationsMutationFn = Apollo.MutationFunction<
  SaveMobileTranslationsMutation,
  SaveMobileTranslationsMutationVariables
>;

/**
 * __useSaveMobileTranslationsMutation__
 *
 * To run a mutation, you first call `useSaveMobileTranslationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveMobileTranslationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveMobileTranslationsMutation, { data, loading, error }] = useSaveMobileTranslationsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSaveMobileTranslationsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SaveMobileTranslationsMutation,
    SaveMobileTranslationsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<SaveMobileTranslationsMutation, SaveMobileTranslationsMutationVariables>(
    SaveMobileTranslationsDocument,
    options
  );
}
export type SaveMobileTranslationsMutationHookResult = ReturnType<typeof useSaveMobileTranslationsMutation>;
export type SaveMobileTranslationsMutationResult = Apollo.MutationResult<SaveMobileTranslationsMutation>;
export type SaveMobileTranslationsMutationOptions = Apollo.BaseMutationOptions<
  SaveMobileTranslationsMutation,
  SaveMobileTranslationsMutationVariables
>;
export const SaveSystemTranslationDocument = gql`
  mutation saveSystemTranslation($data: SystemTranslationSaveInput!) {
    saveSystemTranslation(data: $data) {
      ...systemTranslation
    }
  }
  ${SystemTranslationFragmentDoc}
`;
export type SaveSystemTranslationMutationFn = Apollo.MutationFunction<
  SaveSystemTranslationMutation,
  SaveSystemTranslationMutationVariables
>;

/**
 * __useSaveSystemTranslationMutation__
 *
 * To run a mutation, you first call `useSaveSystemTranslationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveSystemTranslationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveSystemTranslationMutation, { data, loading, error }] = useSaveSystemTranslationMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSaveSystemTranslationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SaveSystemTranslationMutation,
    SaveSystemTranslationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<SaveSystemTranslationMutation, SaveSystemTranslationMutationVariables>(
    SaveSystemTranslationDocument,
    options
  );
}
export type SaveSystemTranslationMutationHookResult = ReturnType<typeof useSaveSystemTranslationMutation>;
export type SaveSystemTranslationMutationResult = Apollo.MutationResult<SaveSystemTranslationMutation>;
export type SaveSystemTranslationMutationOptions = Apollo.BaseMutationOptions<
  SaveSystemTranslationMutation,
  SaveSystemTranslationMutationVariables
>;
export const SetTicketFanIdDocument = gql`
  mutation setTicketFanId($fanCode: String!, $fanGUID: String!, $orderId: Int!, $barcode: String, $ticketId: Int) {
    setTicketFanId(fanCode: $fanCode, fanGUID: $fanGUID, orderId: $orderId, barcode: $barcode, ticketId: $ticketId) {
      ...eventOrder
    }
  }
  ${EventOrderFragmentDoc}
`;
export type SetTicketFanIdMutationFn = Apollo.MutationFunction<SetTicketFanIdMutation, SetTicketFanIdMutationVariables>;

/**
 * __useSetTicketFanIdMutation__
 *
 * To run a mutation, you first call `useSetTicketFanIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetTicketFanIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setTicketFanIdMutation, { data, loading, error }] = useSetTicketFanIdMutation({
 *   variables: {
 *      fanCode: // value for 'fanCode'
 *      fanGUID: // value for 'fanGUID'
 *      orderId: // value for 'orderId'
 *      barcode: // value for 'barcode'
 *      ticketId: // value for 'ticketId'
 *   },
 * });
 */
export function useSetTicketFanIdMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<SetTicketFanIdMutation, SetTicketFanIdMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<SetTicketFanIdMutation, SetTicketFanIdMutationVariables>(
    SetTicketFanIdDocument,
    options
  );
}
export type SetTicketFanIdMutationHookResult = ReturnType<typeof useSetTicketFanIdMutation>;
export type SetTicketFanIdMutationResult = Apollo.MutationResult<SetTicketFanIdMutation>;
export type SetTicketFanIdMutationOptions = Apollo.BaseMutationOptions<
  SetTicketFanIdMutation,
  SetTicketFanIdMutationVariables
>;
export const SyncMyLoyaltyAccountsDocument = gql`
  mutation syncMyLoyaltyAccounts {
    syncMyLoyaltyAccounts {
      ...paymentAccountFlat
    }
  }
  ${PaymentAccountFlatFragmentDoc}
`;
export type SyncMyLoyaltyAccountsMutationFn = Apollo.MutationFunction<
  SyncMyLoyaltyAccountsMutation,
  SyncMyLoyaltyAccountsMutationVariables
>;

/**
 * __useSyncMyLoyaltyAccountsMutation__
 *
 * To run a mutation, you first call `useSyncMyLoyaltyAccountsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyncMyLoyaltyAccountsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syncMyLoyaltyAccountsMutation, { data, loading, error }] = useSyncMyLoyaltyAccountsMutation({
 *   variables: {
 *   },
 * });
 */
export function useSyncMyLoyaltyAccountsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SyncMyLoyaltyAccountsMutation,
    SyncMyLoyaltyAccountsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<SyncMyLoyaltyAccountsMutation, SyncMyLoyaltyAccountsMutationVariables>(
    SyncMyLoyaltyAccountsDocument,
    options
  );
}
export type SyncMyLoyaltyAccountsMutationHookResult = ReturnType<typeof useSyncMyLoyaltyAccountsMutation>;
export type SyncMyLoyaltyAccountsMutationResult = Apollo.MutationResult<SyncMyLoyaltyAccountsMutation>;
export type SyncMyLoyaltyAccountsMutationOptions = Apollo.BaseMutationOptions<
  SyncMyLoyaltyAccountsMutation,
  SyncMyLoyaltyAccountsMutationVariables
>;
export const SyncPaymentAccountDocument = gql`
  mutation syncPaymentAccount($id: Int!) {
    syncPaymentAccount(id: $id) {
      id
      currencyId
      balance
    }
  }
`;
export type SyncPaymentAccountMutationFn = Apollo.MutationFunction<
  SyncPaymentAccountMutation,
  SyncPaymentAccountMutationVariables
>;

/**
 * __useSyncPaymentAccountMutation__
 *
 * To run a mutation, you first call `useSyncPaymentAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyncPaymentAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syncPaymentAccountMutation, { data, loading, error }] = useSyncPaymentAccountMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSyncPaymentAccountMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<SyncPaymentAccountMutation, SyncPaymentAccountMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<SyncPaymentAccountMutation, SyncPaymentAccountMutationVariables>(
    SyncPaymentAccountDocument,
    options
  );
}
export type SyncPaymentAccountMutationHookResult = ReturnType<typeof useSyncPaymentAccountMutation>;
export type SyncPaymentAccountMutationResult = Apollo.MutationResult<SyncPaymentAccountMutation>;
export type SyncPaymentAccountMutationOptions = Apollo.BaseMutationOptions<
  SyncPaymentAccountMutation,
  SyncPaymentAccountMutationVariables
>;
export const SystemLoginDocument = gql`
  mutation systemLogin($username: String!, $password: String!, $clientId: Int) {
    systemLogin(username: $username, password: $password, clientId: $clientId) {
      ...systemLogin
    }
  }
  ${SystemLoginFragmentDoc}
`;
export type SystemLoginMutationFn = Apollo.MutationFunction<SystemLoginMutation, SystemLoginMutationVariables>;

/**
 * __useSystemLoginMutation__
 *
 * To run a mutation, you first call `useSystemLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSystemLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [systemLoginMutation, { data, loading, error }] = useSystemLoginMutation({
 *   variables: {
 *      username: // value for 'username'
 *      password: // value for 'password'
 *      clientId: // value for 'clientId'
 *   },
 * });
 */
export function useSystemLoginMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<SystemLoginMutation, SystemLoginMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<SystemLoginMutation, SystemLoginMutationVariables>(SystemLoginDocument, options);
}
export type SystemLoginMutationHookResult = ReturnType<typeof useSystemLoginMutation>;
export type SystemLoginMutationResult = Apollo.MutationResult<SystemLoginMutation>;
export type SystemLoginMutationOptions = Apollo.BaseMutationOptions<SystemLoginMutation, SystemLoginMutationVariables>;
export const TestMessengerMailingDocument = gql`
  mutation testMessengerMailing($mailingId: Int!) {
    testMessengerMailing(mailingId: $mailingId)
  }
`;
export type TestMessengerMailingMutationFn = Apollo.MutationFunction<
  TestMessengerMailingMutation,
  TestMessengerMailingMutationVariables
>;

/**
 * __useTestMessengerMailingMutation__
 *
 * To run a mutation, you first call `useTestMessengerMailingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTestMessengerMailingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [testMessengerMailingMutation, { data, loading, error }] = useTestMessengerMailingMutation({
 *   variables: {
 *      mailingId: // value for 'mailingId'
 *   },
 * });
 */
export function useTestMessengerMailingMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    TestMessengerMailingMutation,
    TestMessengerMailingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<TestMessengerMailingMutation, TestMessengerMailingMutationVariables>(
    TestMessengerMailingDocument,
    options
  );
}
export type TestMessengerMailingMutationHookResult = ReturnType<typeof useTestMessengerMailingMutation>;
export type TestMessengerMailingMutationResult = Apollo.MutationResult<TestMessengerMailingMutation>;
export type TestMessengerMailingMutationOptions = Apollo.BaseMutationOptions<
  TestMessengerMailingMutation,
  TestMessengerMailingMutationVariables
>;
export const TriggerSystemWebHookDocument = gql`
  mutation triggerSystemWebHook($id: Int!, $entityId: Int, $payload: SystemWebHookPayloadInput) {
    triggerSystemWebHook(id: $id, entityId: $entityId, payload: $payload)
  }
`;
export type TriggerSystemWebHookMutationFn = Apollo.MutationFunction<
  TriggerSystemWebHookMutation,
  TriggerSystemWebHookMutationVariables
>;

/**
 * __useTriggerSystemWebHookMutation__
 *
 * To run a mutation, you first call `useTriggerSystemWebHookMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTriggerSystemWebHookMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [triggerSystemWebHookMutation, { data, loading, error }] = useTriggerSystemWebHookMutation({
 *   variables: {
 *      id: // value for 'id'
 *      entityId: // value for 'entityId'
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useTriggerSystemWebHookMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    TriggerSystemWebHookMutation,
    TriggerSystemWebHookMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<TriggerSystemWebHookMutation, TriggerSystemWebHookMutationVariables>(
    TriggerSystemWebHookDocument,
    options
  );
}
export type TriggerSystemWebHookMutationHookResult = ReturnType<typeof useTriggerSystemWebHookMutation>;
export type TriggerSystemWebHookMutationResult = Apollo.MutationResult<TriggerSystemWebHookMutation>;
export type TriggerSystemWebHookMutationOptions = Apollo.BaseMutationOptions<
  TriggerSystemWebHookMutation,
  TriggerSystemWebHookMutationVariables
>;
export const UpdateApplicationFamilySectorDocument = gql`
  mutation updateApplicationFamilySector($id: Int!, $data: UserApplicationFamilySectorUpdateInput!) {
    updateApplicationFamilySector(id: $id, data: $data) {
      id
      userId
      typeId
      state
      comment
      data {
        key
        value
      }
    }
  }
`;
export type UpdateApplicationFamilySectorMutationFn = Apollo.MutationFunction<
  UpdateApplicationFamilySectorMutation,
  UpdateApplicationFamilySectorMutationVariables
>;

/**
 * __useUpdateApplicationFamilySectorMutation__
 *
 * To run a mutation, you first call `useUpdateApplicationFamilySectorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateApplicationFamilySectorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateApplicationFamilySectorMutation, { data, loading, error }] = useUpdateApplicationFamilySectorMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateApplicationFamilySectorMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateApplicationFamilySectorMutation,
    UpdateApplicationFamilySectorMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UpdateApplicationFamilySectorMutation,
    UpdateApplicationFamilySectorMutationVariables
  >(UpdateApplicationFamilySectorDocument, options);
}
export type UpdateApplicationFamilySectorMutationHookResult = ReturnType<
  typeof useUpdateApplicationFamilySectorMutation
>;
export type UpdateApplicationFamilySectorMutationResult = Apollo.MutationResult<UpdateApplicationFamilySectorMutation>;
export type UpdateApplicationFamilySectorMutationOptions = Apollo.BaseMutationOptions<
  UpdateApplicationFamilySectorMutation,
  UpdateApplicationFamilySectorMutationVariables
>;
export const UpdateArticleDocument = gql`
  mutation updateArticle($id: Int!, $data: ArticleUpdateInput!) {
    updateArticle(id: $id, data: $data) {
      ...article
    }
  }
  ${ArticleFragmentDoc}
`;
export type UpdateArticleMutationFn = Apollo.MutationFunction<UpdateArticleMutation, UpdateArticleMutationVariables>;

/**
 * __useUpdateArticleMutation__
 *
 * To run a mutation, you first call `useUpdateArticleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateArticleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateArticleMutation, { data, loading, error }] = useUpdateArticleMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateArticleMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateArticleMutation, UpdateArticleMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<UpdateArticleMutation, UpdateArticleMutationVariables>(
    UpdateArticleDocument,
    options
  );
}
export type UpdateArticleMutationHookResult = ReturnType<typeof useUpdateArticleMutation>;
export type UpdateArticleMutationResult = Apollo.MutationResult<UpdateArticleMutation>;
export type UpdateArticleMutationOptions = Apollo.BaseMutationOptions<
  UpdateArticleMutation,
  UpdateArticleMutationVariables
>;
export const UpdateArticleAuthorDocument = gql`
  mutation updateArticleAuthor($id: Int!, $data: ArticleAuthorUpdateInput!) {
    updateArticleAuthor(id: $id, data: $data) {
      ...articleAuthor
    }
  }
  ${ArticleAuthorFragmentDoc}
`;
export type UpdateArticleAuthorMutationFn = Apollo.MutationFunction<
  UpdateArticleAuthorMutation,
  UpdateArticleAuthorMutationVariables
>;

/**
 * __useUpdateArticleAuthorMutation__
 *
 * To run a mutation, you first call `useUpdateArticleAuthorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateArticleAuthorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateArticleAuthorMutation, { data, loading, error }] = useUpdateArticleAuthorMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateArticleAuthorMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateArticleAuthorMutation, UpdateArticleAuthorMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<UpdateArticleAuthorMutation, UpdateArticleAuthorMutationVariables>(
    UpdateArticleAuthorDocument,
    options
  );
}
export type UpdateArticleAuthorMutationHookResult = ReturnType<typeof useUpdateArticleAuthorMutation>;
export type UpdateArticleAuthorMutationResult = Apollo.MutationResult<UpdateArticleAuthorMutation>;
export type UpdateArticleAuthorMutationOptions = Apollo.BaseMutationOptions<
  UpdateArticleAuthorMutation,
  UpdateArticleAuthorMutationVariables
>;
export const UpdateArticleBlockDocument = gql`
  mutation updateArticleBlock($id: Int!, $data: ArticleBlockUpdateInput!) {
    updateArticleBlock(id: $id, data: $data) {
      ...articleBlock
    }
  }
  ${ArticleBlockFragmentDoc}
`;
export type UpdateArticleBlockMutationFn = Apollo.MutationFunction<
  UpdateArticleBlockMutation,
  UpdateArticleBlockMutationVariables
>;

/**
 * __useUpdateArticleBlockMutation__
 *
 * To run a mutation, you first call `useUpdateArticleBlockMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateArticleBlockMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateArticleBlockMutation, { data, loading, error }] = useUpdateArticleBlockMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateArticleBlockMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateArticleBlockMutation, UpdateArticleBlockMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<UpdateArticleBlockMutation, UpdateArticleBlockMutationVariables>(
    UpdateArticleBlockDocument,
    options
  );
}
export type UpdateArticleBlockMutationHookResult = ReturnType<typeof useUpdateArticleBlockMutation>;
export type UpdateArticleBlockMutationResult = Apollo.MutationResult<UpdateArticleBlockMutation>;
export type UpdateArticleBlockMutationOptions = Apollo.BaseMutationOptions<
  UpdateArticleBlockMutation,
  UpdateArticleBlockMutationVariables
>;
export const UpdateArticleBlocksDocument = gql`
  mutation updateArticleBlocks($data: [ArticleBlocksUpdateInput!]!) {
    updateArticleBlocks(data: $data)
  }
`;
export type UpdateArticleBlocksMutationFn = Apollo.MutationFunction<
  UpdateArticleBlocksMutation,
  UpdateArticleBlocksMutationVariables
>;

/**
 * __useUpdateArticleBlocksMutation__
 *
 * To run a mutation, you first call `useUpdateArticleBlocksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateArticleBlocksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateArticleBlocksMutation, { data, loading, error }] = useUpdateArticleBlocksMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateArticleBlocksMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateArticleBlocksMutation, UpdateArticleBlocksMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<UpdateArticleBlocksMutation, UpdateArticleBlocksMutationVariables>(
    UpdateArticleBlocksDocument,
    options
  );
}
export type UpdateArticleBlocksMutationHookResult = ReturnType<typeof useUpdateArticleBlocksMutation>;
export type UpdateArticleBlocksMutationResult = Apollo.MutationResult<UpdateArticleBlocksMutation>;
export type UpdateArticleBlocksMutationOptions = Apollo.BaseMutationOptions<
  UpdateArticleBlocksMutation,
  UpdateArticleBlocksMutationVariables
>;
export const UpdateArticleCategoryDocument = gql`
  mutation updateArticleCategory($id: Int!, $data: ArticleCategoryUpdateInput!) {
    updateArticleCategory(id: $id, data: $data) {
      ...articleCategory
    }
  }
  ${ArticleCategoryFragmentDoc}
`;
export type UpdateArticleCategoryMutationFn = Apollo.MutationFunction<
  UpdateArticleCategoryMutation,
  UpdateArticleCategoryMutationVariables
>;

/**
 * __useUpdateArticleCategoryMutation__
 *
 * To run a mutation, you first call `useUpdateArticleCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateArticleCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateArticleCategoryMutation, { data, loading, error }] = useUpdateArticleCategoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateArticleCategoryMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateArticleCategoryMutation,
    UpdateArticleCategoryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<UpdateArticleCategoryMutation, UpdateArticleCategoryMutationVariables>(
    UpdateArticleCategoryDocument,
    options
  );
}
export type UpdateArticleCategoryMutationHookResult = ReturnType<typeof useUpdateArticleCategoryMutation>;
export type UpdateArticleCategoryMutationResult = Apollo.MutationResult<UpdateArticleCategoryMutation>;
export type UpdateArticleCategoryMutationOptions = Apollo.BaseMutationOptions<
  UpdateArticleCategoryMutation,
  UpdateArticleCategoryMutationVariables
>;
export const UpdateArticleLinkDocument = gql`
  mutation updateArticleLink($id: Int!, $data: ArticleLinkUpdateInput!) {
    updateArticleLink(id: $id, data: $data) {
      ...articleLink
    }
  }
  ${ArticleLinkFragmentDoc}
`;
export type UpdateArticleLinkMutationFn = Apollo.MutationFunction<
  UpdateArticleLinkMutation,
  UpdateArticleLinkMutationVariables
>;

/**
 * __useUpdateArticleLinkMutation__
 *
 * To run a mutation, you first call `useUpdateArticleLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateArticleLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateArticleLinkMutation, { data, loading, error }] = useUpdateArticleLinkMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateArticleLinkMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateArticleLinkMutation, UpdateArticleLinkMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<UpdateArticleLinkMutation, UpdateArticleLinkMutationVariables>(
    UpdateArticleLinkDocument,
    options
  );
}
export type UpdateArticleLinkMutationHookResult = ReturnType<typeof useUpdateArticleLinkMutation>;
export type UpdateArticleLinkMutationResult = Apollo.MutationResult<UpdateArticleLinkMutation>;
export type UpdateArticleLinkMutationOptions = Apollo.BaseMutationOptions<
  UpdateArticleLinkMutation,
  UpdateArticleLinkMutationVariables
>;
export const UpdateArticleTagDocument = gql`
  mutation updateArticleTag($id: Int!, $data: ArticleTagUpdateInput!) {
    updateArticleTag(id: $id, data: $data) {
      ...articleTag
    }
  }
  ${ArticleTagFragmentDoc}
`;
export type UpdateArticleTagMutationFn = Apollo.MutationFunction<
  UpdateArticleTagMutation,
  UpdateArticleTagMutationVariables
>;

/**
 * __useUpdateArticleTagMutation__
 *
 * To run a mutation, you first call `useUpdateArticleTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateArticleTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateArticleTagMutation, { data, loading, error }] = useUpdateArticleTagMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateArticleTagMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateArticleTagMutation, UpdateArticleTagMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<UpdateArticleTagMutation, UpdateArticleTagMutationVariables>(
    UpdateArticleTagDocument,
    options
  );
}
export type UpdateArticleTagMutationHookResult = ReturnType<typeof useUpdateArticleTagMutation>;
export type UpdateArticleTagMutationResult = Apollo.MutationResult<UpdateArticleTagMutation>;
export type UpdateArticleTagMutationOptions = Apollo.BaseMutationOptions<
  UpdateArticleTagMutation,
  UpdateArticleTagMutationVariables
>;
export const UpdateCatalogueDocument = gql`
  mutation updateCatalogue($id: Int!, $data: CatalogueUpdateInput!) {
    updateCatalogue(id: $id, data: $data) {
      ...catalogueFlat
    }
  }
  ${CatalogueFlatFragmentDoc}
`;
export type UpdateCatalogueMutationFn = Apollo.MutationFunction<
  UpdateCatalogueMutation,
  UpdateCatalogueMutationVariables
>;

/**
 * __useUpdateCatalogueMutation__
 *
 * To run a mutation, you first call `useUpdateCatalogueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCatalogueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCatalogueMutation, { data, loading, error }] = useUpdateCatalogueMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateCatalogueMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCatalogueMutation, UpdateCatalogueMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<UpdateCatalogueMutation, UpdateCatalogueMutationVariables>(
    UpdateCatalogueDocument,
    options
  );
}
export type UpdateCatalogueMutationHookResult = ReturnType<typeof useUpdateCatalogueMutation>;
export type UpdateCatalogueMutationResult = Apollo.MutationResult<UpdateCatalogueMutation>;
export type UpdateCatalogueMutationOptions = Apollo.BaseMutationOptions<
  UpdateCatalogueMutation,
  UpdateCatalogueMutationVariables
>;
export const UpdateCatalogueCategoryDocument = gql`
  mutation updateCatalogueCategory($id: Int!, $data: CatalogueCategoryUpdateInput!) {
    updateCatalogueCategory(id: $id, data: $data) {
      ...catalogueCategory
    }
  }
  ${CatalogueCategoryFragmentDoc}
`;
export type UpdateCatalogueCategoryMutationFn = Apollo.MutationFunction<
  UpdateCatalogueCategoryMutation,
  UpdateCatalogueCategoryMutationVariables
>;

/**
 * __useUpdateCatalogueCategoryMutation__
 *
 * To run a mutation, you first call `useUpdateCatalogueCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCatalogueCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCatalogueCategoryMutation, { data, loading, error }] = useUpdateCatalogueCategoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateCatalogueCategoryMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateCatalogueCategoryMutation,
    UpdateCatalogueCategoryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<UpdateCatalogueCategoryMutation, UpdateCatalogueCategoryMutationVariables>(
    UpdateCatalogueCategoryDocument,
    options
  );
}
export type UpdateCatalogueCategoryMutationHookResult = ReturnType<typeof useUpdateCatalogueCategoryMutation>;
export type UpdateCatalogueCategoryMutationResult = Apollo.MutationResult<UpdateCatalogueCategoryMutation>;
export type UpdateCatalogueCategoryMutationOptions = Apollo.BaseMutationOptions<
  UpdateCatalogueCategoryMutation,
  UpdateCatalogueCategoryMutationVariables
>;
export const UpdateCatalogueCategoryBatchDocument = gql`
  mutation updateCatalogueCategoryBatch($data: [CatalogueCategoryBatchUpdateInput!]!) {
    updateCatalogueCategoryBatch(data: $data)
  }
`;
export type UpdateCatalogueCategoryBatchMutationFn = Apollo.MutationFunction<
  UpdateCatalogueCategoryBatchMutation,
  UpdateCatalogueCategoryBatchMutationVariables
>;

/**
 * __useUpdateCatalogueCategoryBatchMutation__
 *
 * To run a mutation, you first call `useUpdateCatalogueCategoryBatchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCatalogueCategoryBatchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCatalogueCategoryBatchMutation, { data, loading, error }] = useUpdateCatalogueCategoryBatchMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateCatalogueCategoryBatchMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateCatalogueCategoryBatchMutation,
    UpdateCatalogueCategoryBatchMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UpdateCatalogueCategoryBatchMutation,
    UpdateCatalogueCategoryBatchMutationVariables
  >(UpdateCatalogueCategoryBatchDocument, options);
}
export type UpdateCatalogueCategoryBatchMutationHookResult = ReturnType<typeof useUpdateCatalogueCategoryBatchMutation>;
export type UpdateCatalogueCategoryBatchMutationResult = Apollo.MutationResult<UpdateCatalogueCategoryBatchMutation>;
export type UpdateCatalogueCategoryBatchMutationOptions = Apollo.BaseMutationOptions<
  UpdateCatalogueCategoryBatchMutation,
  UpdateCatalogueCategoryBatchMutationVariables
>;
export const UpdateCatalogueItemDocument = gql`
  mutation updateCatalogueItem($id: Int!, $data: CatalogueItemUpdateInput!) {
    updateCatalogueItem(id: $id, data: $data) {
      ...catalogueItem
    }
  }
  ${CatalogueItemFragmentDoc}
`;
export type UpdateCatalogueItemMutationFn = Apollo.MutationFunction<
  UpdateCatalogueItemMutation,
  UpdateCatalogueItemMutationVariables
>;

/**
 * __useUpdateCatalogueItemMutation__
 *
 * To run a mutation, you first call `useUpdateCatalogueItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCatalogueItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCatalogueItemMutation, { data, loading, error }] = useUpdateCatalogueItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateCatalogueItemMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCatalogueItemMutation, UpdateCatalogueItemMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<UpdateCatalogueItemMutation, UpdateCatalogueItemMutationVariables>(
    UpdateCatalogueItemDocument,
    options
  );
}
export type UpdateCatalogueItemMutationHookResult = ReturnType<typeof useUpdateCatalogueItemMutation>;
export type UpdateCatalogueItemMutationResult = Apollo.MutationResult<UpdateCatalogueItemMutation>;
export type UpdateCatalogueItemMutationOptions = Apollo.BaseMutationOptions<
  UpdateCatalogueItemMutation,
  UpdateCatalogueItemMutationVariables
>;
export const UpdateCatalogueItemBatchDocument = gql`
  mutation updateCatalogueItemBatch($data: [CatalogueItemBatchUpdateInput!]!) {
    updateCatalogueItemBatch(data: $data)
  }
`;
export type UpdateCatalogueItemBatchMutationFn = Apollo.MutationFunction<
  UpdateCatalogueItemBatchMutation,
  UpdateCatalogueItemBatchMutationVariables
>;

/**
 * __useUpdateCatalogueItemBatchMutation__
 *
 * To run a mutation, you first call `useUpdateCatalogueItemBatchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCatalogueItemBatchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCatalogueItemBatchMutation, { data, loading, error }] = useUpdateCatalogueItemBatchMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateCatalogueItemBatchMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateCatalogueItemBatchMutation,
    UpdateCatalogueItemBatchMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<UpdateCatalogueItemBatchMutation, UpdateCatalogueItemBatchMutationVariables>(
    UpdateCatalogueItemBatchDocument,
    options
  );
}
export type UpdateCatalogueItemBatchMutationHookResult = ReturnType<typeof useUpdateCatalogueItemBatchMutation>;
export type UpdateCatalogueItemBatchMutationResult = Apollo.MutationResult<UpdateCatalogueItemBatchMutation>;
export type UpdateCatalogueItemBatchMutationOptions = Apollo.BaseMutationOptions<
  UpdateCatalogueItemBatchMutation,
  UpdateCatalogueItemBatchMutationVariables
>;
export const UpdateCatalogueItemPriceDocument = gql`
  mutation updateCatalogueItemPrice($id: Int!, $data: CatalogueItemPriceUpdateInput!) {
    updateCatalogueItemPrice(id: $id, data: $data) {
      ...catalogueItemPrice
    }
  }
  ${CatalogueItemPriceFragmentDoc}
`;
export type UpdateCatalogueItemPriceMutationFn = Apollo.MutationFunction<
  UpdateCatalogueItemPriceMutation,
  UpdateCatalogueItemPriceMutationVariables
>;

/**
 * __useUpdateCatalogueItemPriceMutation__
 *
 * To run a mutation, you first call `useUpdateCatalogueItemPriceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCatalogueItemPriceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCatalogueItemPriceMutation, { data, loading, error }] = useUpdateCatalogueItemPriceMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateCatalogueItemPriceMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateCatalogueItemPriceMutation,
    UpdateCatalogueItemPriceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<UpdateCatalogueItemPriceMutation, UpdateCatalogueItemPriceMutationVariables>(
    UpdateCatalogueItemPriceDocument,
    options
  );
}
export type UpdateCatalogueItemPriceMutationHookResult = ReturnType<typeof useUpdateCatalogueItemPriceMutation>;
export type UpdateCatalogueItemPriceMutationResult = Apollo.MutationResult<UpdateCatalogueItemPriceMutation>;
export type UpdateCatalogueItemPriceMutationOptions = Apollo.BaseMutationOptions<
  UpdateCatalogueItemPriceMutation,
  UpdateCatalogueItemPriceMutationVariables
>;
export const UpdateCateringDocument = gql`
  mutation updateCatering($id: Int!, $data: CateringUpdateInput!) {
    updateCatering(id: $id, data: $data) {
      ...catering
    }
  }
  ${CateringFragmentDoc}
`;
export type UpdateCateringMutationFn = Apollo.MutationFunction<UpdateCateringMutation, UpdateCateringMutationVariables>;

/**
 * __useUpdateCateringMutation__
 *
 * To run a mutation, you first call `useUpdateCateringMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCateringMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCateringMutation, { data, loading, error }] = useUpdateCateringMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateCateringMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCateringMutation, UpdateCateringMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<UpdateCateringMutation, UpdateCateringMutationVariables>(
    UpdateCateringDocument,
    options
  );
}
export type UpdateCateringMutationHookResult = ReturnType<typeof useUpdateCateringMutation>;
export type UpdateCateringMutationResult = Apollo.MutationResult<UpdateCateringMutation>;
export type UpdateCateringMutationOptions = Apollo.BaseMutationOptions<
  UpdateCateringMutation,
  UpdateCateringMutationVariables
>;
export const UpdateCommerceOrderDocument = gql`
  mutation updateCommerceOrder($id: Int!, $data: CommerceOrderUpdateInput!) {
    updateCommerceOrder(id: $id, data: $data) {
      ...commerceOrderUpdate
    }
  }
  ${CommerceOrderUpdateFragmentDoc}
`;
export type UpdateCommerceOrderMutationFn = Apollo.MutationFunction<
  UpdateCommerceOrderMutation,
  UpdateCommerceOrderMutationVariables
>;

/**
 * __useUpdateCommerceOrderMutation__
 *
 * To run a mutation, you first call `useUpdateCommerceOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCommerceOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCommerceOrderMutation, { data, loading, error }] = useUpdateCommerceOrderMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateCommerceOrderMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCommerceOrderMutation, UpdateCommerceOrderMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<UpdateCommerceOrderMutation, UpdateCommerceOrderMutationVariables>(
    UpdateCommerceOrderDocument,
    options
  );
}
export type UpdateCommerceOrderMutationHookResult = ReturnType<typeof useUpdateCommerceOrderMutation>;
export type UpdateCommerceOrderMutationResult = Apollo.MutationResult<UpdateCommerceOrderMutation>;
export type UpdateCommerceOrderMutationOptions = Apollo.BaseMutationOptions<
  UpdateCommerceOrderMutation,
  UpdateCommerceOrderMutationVariables
>;
export const UpdateCommerceOrderDataDocument = gql`
  mutation updateCommerceOrderData($id: Int!, $data: CommerceOrderDataUpdateInput!) {
    updateCommerceOrderData(id: $id, data: $data) {
      ...commerceOrderData
    }
  }
  ${CommerceOrderDataFragmentDoc}
`;
export type UpdateCommerceOrderDataMutationFn = Apollo.MutationFunction<
  UpdateCommerceOrderDataMutation,
  UpdateCommerceOrderDataMutationVariables
>;

/**
 * __useUpdateCommerceOrderDataMutation__
 *
 * To run a mutation, you first call `useUpdateCommerceOrderDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCommerceOrderDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCommerceOrderDataMutation, { data, loading, error }] = useUpdateCommerceOrderDataMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateCommerceOrderDataMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateCommerceOrderDataMutation,
    UpdateCommerceOrderDataMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<UpdateCommerceOrderDataMutation, UpdateCommerceOrderDataMutationVariables>(
    UpdateCommerceOrderDataDocument,
    options
  );
}
export type UpdateCommerceOrderDataMutationHookResult = ReturnType<typeof useUpdateCommerceOrderDataMutation>;
export type UpdateCommerceOrderDataMutationResult = Apollo.MutationResult<UpdateCommerceOrderDataMutation>;
export type UpdateCommerceOrderDataMutationOptions = Apollo.BaseMutationOptions<
  UpdateCommerceOrderDataMutation,
  UpdateCommerceOrderDataMutationVariables
>;
export const UpdateCommerceOrderDatasDocument = gql`
  mutation updateCommerceOrderDatas($data: [CommerceOrderDatasUpdateInput!]!) {
    updateCommerceOrderDatas(data: $data)
  }
`;
export type UpdateCommerceOrderDatasMutationFn = Apollo.MutationFunction<
  UpdateCommerceOrderDatasMutation,
  UpdateCommerceOrderDatasMutationVariables
>;

/**
 * __useUpdateCommerceOrderDatasMutation__
 *
 * To run a mutation, you first call `useUpdateCommerceOrderDatasMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCommerceOrderDatasMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCommerceOrderDatasMutation, { data, loading, error }] = useUpdateCommerceOrderDatasMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateCommerceOrderDatasMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateCommerceOrderDatasMutation,
    UpdateCommerceOrderDatasMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<UpdateCommerceOrderDatasMutation, UpdateCommerceOrderDatasMutationVariables>(
    UpdateCommerceOrderDatasDocument,
    options
  );
}
export type UpdateCommerceOrderDatasMutationHookResult = ReturnType<typeof useUpdateCommerceOrderDatasMutation>;
export type UpdateCommerceOrderDatasMutationResult = Apollo.MutationResult<UpdateCommerceOrderDatasMutation>;
export type UpdateCommerceOrderDatasMutationOptions = Apollo.BaseMutationOptions<
  UpdateCommerceOrderDatasMutation,
  UpdateCommerceOrderDatasMutationVariables
>;
export const UpdateCommerceOrderFieldDocument = gql`
  mutation updateCommerceOrderField($id: Int!, $data: CommerceOrderFieldUpdateInput!) {
    updateCommerceOrderField(id: $id, data: $data) {
      ...commerceOrderField
    }
  }
  ${CommerceOrderFieldFragmentDoc}
`;
export type UpdateCommerceOrderFieldMutationFn = Apollo.MutationFunction<
  UpdateCommerceOrderFieldMutation,
  UpdateCommerceOrderFieldMutationVariables
>;

/**
 * __useUpdateCommerceOrderFieldMutation__
 *
 * To run a mutation, you first call `useUpdateCommerceOrderFieldMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCommerceOrderFieldMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCommerceOrderFieldMutation, { data, loading, error }] = useUpdateCommerceOrderFieldMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateCommerceOrderFieldMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateCommerceOrderFieldMutation,
    UpdateCommerceOrderFieldMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<UpdateCommerceOrderFieldMutation, UpdateCommerceOrderFieldMutationVariables>(
    UpdateCommerceOrderFieldDocument,
    options
  );
}
export type UpdateCommerceOrderFieldMutationHookResult = ReturnType<typeof useUpdateCommerceOrderFieldMutation>;
export type UpdateCommerceOrderFieldMutationResult = Apollo.MutationResult<UpdateCommerceOrderFieldMutation>;
export type UpdateCommerceOrderFieldMutationOptions = Apollo.BaseMutationOptions<
  UpdateCommerceOrderFieldMutation,
  UpdateCommerceOrderFieldMutationVariables
>;
export const UpdateCommerceOrderItemDocument = gql`
  mutation updateCommerceOrderItem($id: Int!, $data: CommerceOrderItemUpdateInput!) {
    updateCommerceOrderItem(id: $id, data: $data) {
      ...commerceOrderItem
    }
  }
  ${CommerceOrderItemFragmentDoc}
`;
export type UpdateCommerceOrderItemMutationFn = Apollo.MutationFunction<
  UpdateCommerceOrderItemMutation,
  UpdateCommerceOrderItemMutationVariables
>;

/**
 * __useUpdateCommerceOrderItemMutation__
 *
 * To run a mutation, you first call `useUpdateCommerceOrderItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCommerceOrderItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCommerceOrderItemMutation, { data, loading, error }] = useUpdateCommerceOrderItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateCommerceOrderItemMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateCommerceOrderItemMutation,
    UpdateCommerceOrderItemMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<UpdateCommerceOrderItemMutation, UpdateCommerceOrderItemMutationVariables>(
    UpdateCommerceOrderItemDocument,
    options
  );
}
export type UpdateCommerceOrderItemMutationHookResult = ReturnType<typeof useUpdateCommerceOrderItemMutation>;
export type UpdateCommerceOrderItemMutationResult = Apollo.MutationResult<UpdateCommerceOrderItemMutation>;
export type UpdateCommerceOrderItemMutationOptions = Apollo.BaseMutationOptions<
  UpdateCommerceOrderItemMutation,
  UpdateCommerceOrderItemMutationVariables
>;
export const UpdateCommerceShopDocument = gql`
  mutation updateCommerceShop($id: Int!, $data: CommerceShopUpdateInput!) {
    updateCommerceShop(id: $id, data: $data) {
      ...commerceShop
    }
  }
  ${CommerceShopFragmentDoc}
`;
export type UpdateCommerceShopMutationFn = Apollo.MutationFunction<
  UpdateCommerceShopMutation,
  UpdateCommerceShopMutationVariables
>;

/**
 * __useUpdateCommerceShopMutation__
 *
 * To run a mutation, you first call `useUpdateCommerceShopMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCommerceShopMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCommerceShopMutation, { data, loading, error }] = useUpdateCommerceShopMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateCommerceShopMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCommerceShopMutation, UpdateCommerceShopMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<UpdateCommerceShopMutation, UpdateCommerceShopMutationVariables>(
    UpdateCommerceShopDocument,
    options
  );
}
export type UpdateCommerceShopMutationHookResult = ReturnType<typeof useUpdateCommerceShopMutation>;
export type UpdateCommerceShopMutationResult = Apollo.MutationResult<UpdateCommerceShopMutation>;
export type UpdateCommerceShopMutationOptions = Apollo.BaseMutationOptions<
  UpdateCommerceShopMutation,
  UpdateCommerceShopMutationVariables
>;
export const UpdateContentTranslationDocument = gql`
  mutation updateContentTranslation($id: Int!, $data: ContentTranslationUpdateInput!) {
    updateContentTranslation(id: $id, data: $data) {
      ...contentTranslation
    }
  }
  ${ContentTranslationFragmentDoc}
`;
export type UpdateContentTranslationMutationFn = Apollo.MutationFunction<
  UpdateContentTranslationMutation,
  UpdateContentTranslationMutationVariables
>;

/**
 * __useUpdateContentTranslationMutation__
 *
 * To run a mutation, you first call `useUpdateContentTranslationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContentTranslationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContentTranslationMutation, { data, loading, error }] = useUpdateContentTranslationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateContentTranslationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateContentTranslationMutation,
    UpdateContentTranslationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<UpdateContentTranslationMutation, UpdateContentTranslationMutationVariables>(
    UpdateContentTranslationDocument,
    options
  );
}
export type UpdateContentTranslationMutationHookResult = ReturnType<typeof useUpdateContentTranslationMutation>;
export type UpdateContentTranslationMutationResult = Apollo.MutationResult<UpdateContentTranslationMutation>;
export type UpdateContentTranslationMutationOptions = Apollo.BaseMutationOptions<
  UpdateContentTranslationMutation,
  UpdateContentTranslationMutationVariables
>;
export const UpdateDisputeDocument = gql`
  mutation updateDispute($id: Int!, $data: DisputeUpdateInput!) {
    updateDispute(id: $id, data: $data) {
      ...dispute
    }
  }
  ${DisputeFragmentDoc}
`;
export type UpdateDisputeMutationFn = Apollo.MutationFunction<UpdateDisputeMutation, UpdateDisputeMutationVariables>;

/**
 * __useUpdateDisputeMutation__
 *
 * To run a mutation, you first call `useUpdateDisputeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDisputeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDisputeMutation, { data, loading, error }] = useUpdateDisputeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateDisputeMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateDisputeMutation, UpdateDisputeMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<UpdateDisputeMutation, UpdateDisputeMutationVariables>(
    UpdateDisputeDocument,
    options
  );
}
export type UpdateDisputeMutationHookResult = ReturnType<typeof useUpdateDisputeMutation>;
export type UpdateDisputeMutationResult = Apollo.MutationResult<UpdateDisputeMutation>;
export type UpdateDisputeMutationOptions = Apollo.BaseMutationOptions<
  UpdateDisputeMutation,
  UpdateDisputeMutationVariables
>;
export const UpdateDisputeDisputeResponseDocument = gql`
  mutation updateDisputeDisputeResponse($id: Int!, $data: DisputeResponseUpdateInput!) {
    updateDisputeDisputeResponse(id: $id, data: $data) {
      ...disputeDisputeResponse
    }
  }
  ${DisputeDisputeResponseFragmentDoc}
`;
export type UpdateDisputeDisputeResponseMutationFn = Apollo.MutationFunction<
  UpdateDisputeDisputeResponseMutation,
  UpdateDisputeDisputeResponseMutationVariables
>;

/**
 * __useUpdateDisputeDisputeResponseMutation__
 *
 * To run a mutation, you first call `useUpdateDisputeDisputeResponseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDisputeDisputeResponseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDisputeDisputeResponseMutation, { data, loading, error }] = useUpdateDisputeDisputeResponseMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateDisputeDisputeResponseMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateDisputeDisputeResponseMutation,
    UpdateDisputeDisputeResponseMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UpdateDisputeDisputeResponseMutation,
    UpdateDisputeDisputeResponseMutationVariables
  >(UpdateDisputeDisputeResponseDocument, options);
}
export type UpdateDisputeDisputeResponseMutationHookResult = ReturnType<typeof useUpdateDisputeDisputeResponseMutation>;
export type UpdateDisputeDisputeResponseMutationResult = Apollo.MutationResult<UpdateDisputeDisputeResponseMutation>;
export type UpdateDisputeDisputeResponseMutationOptions = Apollo.BaseMutationOptions<
  UpdateDisputeDisputeResponseMutation,
  UpdateDisputeDisputeResponseMutationVariables
>;
export const UpdateEventDocument = gql`
  mutation updateEvent($id: Int!, $data: EventUpdateInput!) {
    updateEvent(id: $id, data: $data) {
      ...eventFull
    }
  }
  ${EventFullFragmentDoc}
`;
export type UpdateEventMutationFn = Apollo.MutationFunction<UpdateEventMutation, UpdateEventMutationVariables>;

/**
 * __useUpdateEventMutation__
 *
 * To run a mutation, you first call `useUpdateEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEventMutation, { data, loading, error }] = useUpdateEventMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateEventMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateEventMutation, UpdateEventMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<UpdateEventMutation, UpdateEventMutationVariables>(UpdateEventDocument, options);
}
export type UpdateEventMutationHookResult = ReturnType<typeof useUpdateEventMutation>;
export type UpdateEventMutationResult = Apollo.MutationResult<UpdateEventMutation>;
export type UpdateEventMutationOptions = Apollo.BaseMutationOptions<UpdateEventMutation, UpdateEventMutationVariables>;
export const UpdateEventEventAccessDocument = gql`
  mutation updateEventEventAccess($id: Int!, $data: EventEventAccessUpdateInput!) {
    updateEventEventAccess(id: $id, data: $data) {
      ...eventEventAccess
    }
  }
  ${EventEventAccessFragmentDoc}
`;
export type UpdateEventEventAccessMutationFn = Apollo.MutationFunction<
  UpdateEventEventAccessMutation,
  UpdateEventEventAccessMutationVariables
>;

/**
 * __useUpdateEventEventAccessMutation__
 *
 * To run a mutation, you first call `useUpdateEventEventAccessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEventEventAccessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEventEventAccessMutation, { data, loading, error }] = useUpdateEventEventAccessMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateEventEventAccessMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateEventEventAccessMutation,
    UpdateEventEventAccessMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<UpdateEventEventAccessMutation, UpdateEventEventAccessMutationVariables>(
    UpdateEventEventAccessDocument,
    options
  );
}
export type UpdateEventEventAccessMutationHookResult = ReturnType<typeof useUpdateEventEventAccessMutation>;
export type UpdateEventEventAccessMutationResult = Apollo.MutationResult<UpdateEventEventAccessMutation>;
export type UpdateEventEventAccessMutationOptions = Apollo.BaseMutationOptions<
  UpdateEventEventAccessMutation,
  UpdateEventEventAccessMutationVariables
>;
export const UpdateEventPlaceDocument = gql`
  mutation updateEventPlace($id: Int!, $data: EventPlaceUpdateInput!) {
    updateEventPlace(id: $id, data: $data) {
      ...eventPlace
    }
  }
  ${EventPlaceFragmentDoc}
`;
export type UpdateEventPlaceMutationFn = Apollo.MutationFunction<
  UpdateEventPlaceMutation,
  UpdateEventPlaceMutationVariables
>;

/**
 * __useUpdateEventPlaceMutation__
 *
 * To run a mutation, you first call `useUpdateEventPlaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEventPlaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEventPlaceMutation, { data, loading, error }] = useUpdateEventPlaceMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateEventPlaceMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateEventPlaceMutation, UpdateEventPlaceMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<UpdateEventPlaceMutation, UpdateEventPlaceMutationVariables>(
    UpdateEventPlaceDocument,
    options
  );
}
export type UpdateEventPlaceMutationHookResult = ReturnType<typeof useUpdateEventPlaceMutation>;
export type UpdateEventPlaceMutationResult = Apollo.MutationResult<UpdateEventPlaceMutation>;
export type UpdateEventPlaceMutationOptions = Apollo.BaseMutationOptions<
  UpdateEventPlaceMutation,
  UpdateEventPlaceMutationVariables
>;
export const UpdateEventReserveDocument = gql`
  mutation updateEventReserve($id: Int!, $data: EventReserveUpdateInput!) {
    updateEventReserve(id: $id, data: $data) {
      ...eventReserve
    }
  }
  ${EventReserveFragmentDoc}
`;
export type UpdateEventReserveMutationFn = Apollo.MutationFunction<
  UpdateEventReserveMutation,
  UpdateEventReserveMutationVariables
>;

/**
 * __useUpdateEventReserveMutation__
 *
 * To run a mutation, you first call `useUpdateEventReserveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEventReserveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEventReserveMutation, { data, loading, error }] = useUpdateEventReserveMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateEventReserveMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateEventReserveMutation, UpdateEventReserveMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<UpdateEventReserveMutation, UpdateEventReserveMutationVariables>(
    UpdateEventReserveDocument,
    options
  );
}
export type UpdateEventReserveMutationHookResult = ReturnType<typeof useUpdateEventReserveMutation>;
export type UpdateEventReserveMutationResult = Apollo.MutationResult<UpdateEventReserveMutation>;
export type UpdateEventReserveMutationOptions = Apollo.BaseMutationOptions<
  UpdateEventReserveMutation,
  UpdateEventReserveMutationVariables
>;
export const UpdateEventTypeDocument = gql`
  mutation updateEventType($id: Int!, $data: EventTypeUpdateInput!) {
    updateEventType(id: $id, data: $data) {
      ...eventType
    }
  }
  ${EventTypeFragmentDoc}
`;
export type UpdateEventTypeMutationFn = Apollo.MutationFunction<
  UpdateEventTypeMutation,
  UpdateEventTypeMutationVariables
>;

/**
 * __useUpdateEventTypeMutation__
 *
 * To run a mutation, you first call `useUpdateEventTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEventTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEventTypeMutation, { data, loading, error }] = useUpdateEventTypeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateEventTypeMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateEventTypeMutation, UpdateEventTypeMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<UpdateEventTypeMutation, UpdateEventTypeMutationVariables>(
    UpdateEventTypeDocument,
    options
  );
}
export type UpdateEventTypeMutationHookResult = ReturnType<typeof useUpdateEventTypeMutation>;
export type UpdateEventTypeMutationResult = Apollo.MutationResult<UpdateEventTypeMutation>;
export type UpdateEventTypeMutationOptions = Apollo.BaseMutationOptions<
  UpdateEventTypeMutation,
  UpdateEventTypeMutationVariables
>;
export const UpdateLoyaltyPartnerDocument = gql`
  mutation updateLoyaltyPartner($id: Int!, $data: LoyaltyPartnerUpdateInput!) {
    updateLoyaltyPartner(id: $id, data: $data) {
      ...loyaltyPartner
    }
  }
  ${LoyaltyPartnerFragmentDoc}
`;
export type UpdateLoyaltyPartnerMutationFn = Apollo.MutationFunction<
  UpdateLoyaltyPartnerMutation,
  UpdateLoyaltyPartnerMutationVariables
>;

/**
 * __useUpdateLoyaltyPartnerMutation__
 *
 * To run a mutation, you first call `useUpdateLoyaltyPartnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLoyaltyPartnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLoyaltyPartnerMutation, { data, loading, error }] = useUpdateLoyaltyPartnerMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateLoyaltyPartnerMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateLoyaltyPartnerMutation,
    UpdateLoyaltyPartnerMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<UpdateLoyaltyPartnerMutation, UpdateLoyaltyPartnerMutationVariables>(
    UpdateLoyaltyPartnerDocument,
    options
  );
}
export type UpdateLoyaltyPartnerMutationHookResult = ReturnType<typeof useUpdateLoyaltyPartnerMutation>;
export type UpdateLoyaltyPartnerMutationResult = Apollo.MutationResult<UpdateLoyaltyPartnerMutation>;
export type UpdateLoyaltyPartnerMutationOptions = Apollo.BaseMutationOptions<
  UpdateLoyaltyPartnerMutation,
  UpdateLoyaltyPartnerMutationVariables
>;
export const UpdateLoyaltyPromoDocument = gql`
  mutation updateLoyaltyPromo($id: Int!, $data: LoyaltyPromoUpdateInput!) {
    updateLoyaltyPromo(id: $id, data: $data) {
      ...loyaltyPromo
    }
  }
  ${LoyaltyPromoFragmentDoc}
`;
export type UpdateLoyaltyPromoMutationFn = Apollo.MutationFunction<
  UpdateLoyaltyPromoMutation,
  UpdateLoyaltyPromoMutationVariables
>;

/**
 * __useUpdateLoyaltyPromoMutation__
 *
 * To run a mutation, you first call `useUpdateLoyaltyPromoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLoyaltyPromoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLoyaltyPromoMutation, { data, loading, error }] = useUpdateLoyaltyPromoMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateLoyaltyPromoMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateLoyaltyPromoMutation, UpdateLoyaltyPromoMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<UpdateLoyaltyPromoMutation, UpdateLoyaltyPromoMutationVariables>(
    UpdateLoyaltyPromoDocument,
    options
  );
}
export type UpdateLoyaltyPromoMutationHookResult = ReturnType<typeof useUpdateLoyaltyPromoMutation>;
export type UpdateLoyaltyPromoMutationResult = Apollo.MutationResult<UpdateLoyaltyPromoMutation>;
export type UpdateLoyaltyPromoMutationOptions = Apollo.BaseMutationOptions<
  UpdateLoyaltyPromoMutation,
  UpdateLoyaltyPromoMutationVariables
>;
export const UpdateLoyaltyPromoCodeDocument = gql`
  mutation updateLoyaltyPromoCode($id: Int!, $data: LoyaltyPromoCodeUpdateInput!) {
    updateLoyaltyPromoCode(id: $id, data: $data) {
      ...loyaltyPromoCode
    }
  }
  ${LoyaltyPromoCodeFragmentDoc}
`;
export type UpdateLoyaltyPromoCodeMutationFn = Apollo.MutationFunction<
  UpdateLoyaltyPromoCodeMutation,
  UpdateLoyaltyPromoCodeMutationVariables
>;

/**
 * __useUpdateLoyaltyPromoCodeMutation__
 *
 * To run a mutation, you first call `useUpdateLoyaltyPromoCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLoyaltyPromoCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLoyaltyPromoCodeMutation, { data, loading, error }] = useUpdateLoyaltyPromoCodeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateLoyaltyPromoCodeMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateLoyaltyPromoCodeMutation,
    UpdateLoyaltyPromoCodeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<UpdateLoyaltyPromoCodeMutation, UpdateLoyaltyPromoCodeMutationVariables>(
    UpdateLoyaltyPromoCodeDocument,
    options
  );
}
export type UpdateLoyaltyPromoCodeMutationHookResult = ReturnType<typeof useUpdateLoyaltyPromoCodeMutation>;
export type UpdateLoyaltyPromoCodeMutationResult = Apollo.MutationResult<UpdateLoyaltyPromoCodeMutation>;
export type UpdateLoyaltyPromoCodeMutationOptions = Apollo.BaseMutationOptions<
  UpdateLoyaltyPromoCodeMutation,
  UpdateLoyaltyPromoCodeMutationVariables
>;
export const UpdateMessengerMailingDocument = gql`
  mutation updateMessengerMailing($id: Int!, $data: MessengerMailingUpdateInput!) {
    updateMessengerMailing(id: $id, data: $data) {
      ...messengerMailing
    }
  }
  ${MessengerMailingFragmentDoc}
`;
export type UpdateMessengerMailingMutationFn = Apollo.MutationFunction<
  UpdateMessengerMailingMutation,
  UpdateMessengerMailingMutationVariables
>;

/**
 * __useUpdateMessengerMailingMutation__
 *
 * To run a mutation, you first call `useUpdateMessengerMailingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMessengerMailingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMessengerMailingMutation, { data, loading, error }] = useUpdateMessengerMailingMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateMessengerMailingMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateMessengerMailingMutation,
    UpdateMessengerMailingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<UpdateMessengerMailingMutation, UpdateMessengerMailingMutationVariables>(
    UpdateMessengerMailingDocument,
    options
  );
}
export type UpdateMessengerMailingMutationHookResult = ReturnType<typeof useUpdateMessengerMailingMutation>;
export type UpdateMessengerMailingMutationResult = Apollo.MutationResult<UpdateMessengerMailingMutation>;
export type UpdateMessengerMailingMutationOptions = Apollo.BaseMutationOptions<
  UpdateMessengerMailingMutation,
  UpdateMessengerMailingMutationVariables
>;
export const UpdateMessengerQueueNotificationDocument = gql`
  mutation updateMessengerQueueNotification($id: String!, $data: MessengerQueueNotificationUpdateInput!) {
    updateMessengerQueueNotification(id: $id, data: $data) {
      ...messengerQueueNotification
    }
  }
  ${MessengerQueueNotificationFragmentDoc}
`;
export type UpdateMessengerQueueNotificationMutationFn = Apollo.MutationFunction<
  UpdateMessengerQueueNotificationMutation,
  UpdateMessengerQueueNotificationMutationVariables
>;

/**
 * __useUpdateMessengerQueueNotificationMutation__
 *
 * To run a mutation, you first call `useUpdateMessengerQueueNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMessengerQueueNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMessengerQueueNotificationMutation, { data, loading, error }] = useUpdateMessengerQueueNotificationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateMessengerQueueNotificationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateMessengerQueueNotificationMutation,
    UpdateMessengerQueueNotificationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UpdateMessengerQueueNotificationMutation,
    UpdateMessengerQueueNotificationMutationVariables
  >(UpdateMessengerQueueNotificationDocument, options);
}
export type UpdateMessengerQueueNotificationMutationHookResult = ReturnType<
  typeof useUpdateMessengerQueueNotificationMutation
>;
export type UpdateMessengerQueueNotificationMutationResult = Apollo.MutationResult<UpdateMessengerQueueNotificationMutation>;
export type UpdateMessengerQueueNotificationMutationOptions = Apollo.BaseMutationOptions<
  UpdateMessengerQueueNotificationMutation,
  UpdateMessengerQueueNotificationMutationVariables
>;
export const UpdateMessengerQueueTaskDocument = gql`
  mutation updateMessengerQueueTask($id: Int!, $data: MessengerQueueTaskUpdateInput!) {
    updateMessengerQueueTask(id: $id, data: $data) {
      ...messengerQueueTask
    }
  }
  ${MessengerQueueTaskFragmentDoc}
`;
export type UpdateMessengerQueueTaskMutationFn = Apollo.MutationFunction<
  UpdateMessengerQueueTaskMutation,
  UpdateMessengerQueueTaskMutationVariables
>;

/**
 * __useUpdateMessengerQueueTaskMutation__
 *
 * To run a mutation, you first call `useUpdateMessengerQueueTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMessengerQueueTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMessengerQueueTaskMutation, { data, loading, error }] = useUpdateMessengerQueueTaskMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateMessengerQueueTaskMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateMessengerQueueTaskMutation,
    UpdateMessengerQueueTaskMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<UpdateMessengerQueueTaskMutation, UpdateMessengerQueueTaskMutationVariables>(
    UpdateMessengerQueueTaskDocument,
    options
  );
}
export type UpdateMessengerQueueTaskMutationHookResult = ReturnType<typeof useUpdateMessengerQueueTaskMutation>;
export type UpdateMessengerQueueTaskMutationResult = Apollo.MutationResult<UpdateMessengerQueueTaskMutation>;
export type UpdateMessengerQueueTaskMutationOptions = Apollo.BaseMutationOptions<
  UpdateMessengerQueueTaskMutation,
  UpdateMessengerQueueTaskMutationVariables
>;
export const UpdateMessengerSettingDocument = gql`
  mutation updateMessengerSetting($data: MessengerSettingUpdateInput!) {
    updateMessengerSetting(data: $data) {
      ...messengerSetting
    }
  }
  ${MessengerSettingFragmentDoc}
`;
export type UpdateMessengerSettingMutationFn = Apollo.MutationFunction<
  UpdateMessengerSettingMutation,
  UpdateMessengerSettingMutationVariables
>;

/**
 * __useUpdateMessengerSettingMutation__
 *
 * To run a mutation, you first call `useUpdateMessengerSettingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMessengerSettingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMessengerSettingMutation, { data, loading, error }] = useUpdateMessengerSettingMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateMessengerSettingMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateMessengerSettingMutation,
    UpdateMessengerSettingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<UpdateMessengerSettingMutation, UpdateMessengerSettingMutationVariables>(
    UpdateMessengerSettingDocument,
    options
  );
}
export type UpdateMessengerSettingMutationHookResult = ReturnType<typeof useUpdateMessengerSettingMutation>;
export type UpdateMessengerSettingMutationResult = Apollo.MutationResult<UpdateMessengerSettingMutation>;
export type UpdateMessengerSettingMutationOptions = Apollo.BaseMutationOptions<
  UpdateMessengerSettingMutation,
  UpdateMessengerSettingMutationVariables
>;
export const UpdateMessengerTransportDocument = gql`
  mutation updateMessengerTransport($id: Int!, $data: MessengerTransportUpdateInput!) {
    updateMessengerTransport(id: $id, data: $data) {
      ...messengerTransport
    }
  }
  ${MessengerTransportFragmentDoc}
`;
export type UpdateMessengerTransportMutationFn = Apollo.MutationFunction<
  UpdateMessengerTransportMutation,
  UpdateMessengerTransportMutationVariables
>;

/**
 * __useUpdateMessengerTransportMutation__
 *
 * To run a mutation, you first call `useUpdateMessengerTransportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMessengerTransportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMessengerTransportMutation, { data, loading, error }] = useUpdateMessengerTransportMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateMessengerTransportMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateMessengerTransportMutation,
    UpdateMessengerTransportMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<UpdateMessengerTransportMutation, UpdateMessengerTransportMutationVariables>(
    UpdateMessengerTransportDocument,
    options
  );
}
export type UpdateMessengerTransportMutationHookResult = ReturnType<typeof useUpdateMessengerTransportMutation>;
export type UpdateMessengerTransportMutationResult = Apollo.MutationResult<UpdateMessengerTransportMutation>;
export type UpdateMessengerTransportMutationOptions = Apollo.BaseMutationOptions<
  UpdateMessengerTransportMutation,
  UpdateMessengerTransportMutationVariables
>;
export const UpdateMySubscriptionsDocument = gql`
  mutation updateMySubscriptions($subscriptionId: [Int!]!) {
    updateMySubscriptions(subscriptionId: $subscriptionId) {
      ...userSubscriptionConnection
    }
  }
  ${UserSubscriptionConnectionFragmentDoc}
`;
export type UpdateMySubscriptionsMutationFn = Apollo.MutationFunction<
  UpdateMySubscriptionsMutation,
  UpdateMySubscriptionsMutationVariables
>;

/**
 * __useUpdateMySubscriptionsMutation__
 *
 * To run a mutation, you first call `useUpdateMySubscriptionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMySubscriptionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMySubscriptionsMutation, { data, loading, error }] = useUpdateMySubscriptionsMutation({
 *   variables: {
 *      subscriptionId: // value for 'subscriptionId'
 *   },
 * });
 */
export function useUpdateMySubscriptionsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateMySubscriptionsMutation,
    UpdateMySubscriptionsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<UpdateMySubscriptionsMutation, UpdateMySubscriptionsMutationVariables>(
    UpdateMySubscriptionsDocument,
    options
  );
}
export type UpdateMySubscriptionsMutationHookResult = ReturnType<typeof useUpdateMySubscriptionsMutation>;
export type UpdateMySubscriptionsMutationResult = Apollo.MutationResult<UpdateMySubscriptionsMutation>;
export type UpdateMySubscriptionsMutationOptions = Apollo.BaseMutationOptions<
  UpdateMySubscriptionsMutation,
  UpdateMySubscriptionsMutationVariables
>;
export const UpdatePaymentAccountDocument = gql`
  mutation updatePaymentAccount($id: Int!, $data: PaymentAccountUpdateInput!) {
    updatePaymentAccount(id: $id, data: $data) {
      ...paymentAccount
    }
  }
  ${PaymentAccountFragmentDoc}
`;
export type UpdatePaymentAccountMutationFn = Apollo.MutationFunction<
  UpdatePaymentAccountMutation,
  UpdatePaymentAccountMutationVariables
>;

/**
 * __useUpdatePaymentAccountMutation__
 *
 * To run a mutation, you first call `useUpdatePaymentAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePaymentAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePaymentAccountMutation, { data, loading, error }] = useUpdatePaymentAccountMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdatePaymentAccountMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdatePaymentAccountMutation,
    UpdatePaymentAccountMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<UpdatePaymentAccountMutation, UpdatePaymentAccountMutationVariables>(
    UpdatePaymentAccountDocument,
    options
  );
}
export type UpdatePaymentAccountMutationHookResult = ReturnType<typeof useUpdatePaymentAccountMutation>;
export type UpdatePaymentAccountMutationResult = Apollo.MutationResult<UpdatePaymentAccountMutation>;
export type UpdatePaymentAccountMutationOptions = Apollo.BaseMutationOptions<
  UpdatePaymentAccountMutation,
  UpdatePaymentAccountMutationVariables
>;
export const UpdatePaymentCurrencyDocument = gql`
  mutation updatePaymentCurrency($id: Int!, $data: PaymentCurrencyUpdateInput!) {
    updatePaymentCurrency(id: $id, data: $data) {
      ...paymentCurrency
    }
  }
  ${PaymentCurrencyFragmentDoc}
`;
export type UpdatePaymentCurrencyMutationFn = Apollo.MutationFunction<
  UpdatePaymentCurrencyMutation,
  UpdatePaymentCurrencyMutationVariables
>;

/**
 * __useUpdatePaymentCurrencyMutation__
 *
 * To run a mutation, you first call `useUpdatePaymentCurrencyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePaymentCurrencyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePaymentCurrencyMutation, { data, loading, error }] = useUpdatePaymentCurrencyMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdatePaymentCurrencyMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdatePaymentCurrencyMutation,
    UpdatePaymentCurrencyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<UpdatePaymentCurrencyMutation, UpdatePaymentCurrencyMutationVariables>(
    UpdatePaymentCurrencyDocument,
    options
  );
}
export type UpdatePaymentCurrencyMutationHookResult = ReturnType<typeof useUpdatePaymentCurrencyMutation>;
export type UpdatePaymentCurrencyMutationResult = Apollo.MutationResult<UpdatePaymentCurrencyMutation>;
export type UpdatePaymentCurrencyMutationOptions = Apollo.BaseMutationOptions<
  UpdatePaymentCurrencyMutation,
  UpdatePaymentCurrencyMutationVariables
>;
export const UpdatePaymentProviderDocument = gql`
  mutation updatePaymentProvider($id: Int!, $data: PaymentProviderUpdateInput!) {
    updatePaymentProvider(id: $id, data: $data) {
      ...paymentProvider
    }
  }
  ${PaymentProviderFragmentDoc}
`;
export type UpdatePaymentProviderMutationFn = Apollo.MutationFunction<
  UpdatePaymentProviderMutation,
  UpdatePaymentProviderMutationVariables
>;

/**
 * __useUpdatePaymentProviderMutation__
 *
 * To run a mutation, you first call `useUpdatePaymentProviderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePaymentProviderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePaymentProviderMutation, { data, loading, error }] = useUpdatePaymentProviderMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdatePaymentProviderMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdatePaymentProviderMutation,
    UpdatePaymentProviderMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<UpdatePaymentProviderMutation, UpdatePaymentProviderMutationVariables>(
    UpdatePaymentProviderDocument,
    options
  );
}
export type UpdatePaymentProviderMutationHookResult = ReturnType<typeof useUpdatePaymentProviderMutation>;
export type UpdatePaymentProviderMutationResult = Apollo.MutationResult<UpdatePaymentProviderMutation>;
export type UpdatePaymentProviderMutationOptions = Apollo.BaseMutationOptions<
  UpdatePaymentProviderMutation,
  UpdatePaymentProviderMutationVariables
>;
export const UpdatePaymentTransactionDocument = gql`
  mutation updatePaymentTransaction($id: Int!, $data: PaymentTransactionUpdateInput!) {
    updatePaymentTransaction(id: $id, data: $data) {
      ...paymentTransaction
    }
  }
  ${PaymentTransactionFragmentDoc}
`;
export type UpdatePaymentTransactionMutationFn = Apollo.MutationFunction<
  UpdatePaymentTransactionMutation,
  UpdatePaymentTransactionMutationVariables
>;

/**
 * __useUpdatePaymentTransactionMutation__
 *
 * To run a mutation, you first call `useUpdatePaymentTransactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePaymentTransactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePaymentTransactionMutation, { data, loading, error }] = useUpdatePaymentTransactionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdatePaymentTransactionMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdatePaymentTransactionMutation,
    UpdatePaymentTransactionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<UpdatePaymentTransactionMutation, UpdatePaymentTransactionMutationVariables>(
    UpdatePaymentTransactionDocument,
    options
  );
}
export type UpdatePaymentTransactionMutationHookResult = ReturnType<typeof useUpdatePaymentTransactionMutation>;
export type UpdatePaymentTransactionMutationResult = Apollo.MutationResult<UpdatePaymentTransactionMutation>;
export type UpdatePaymentTransactionMutationOptions = Apollo.BaseMutationOptions<
  UpdatePaymentTransactionMutation,
  UpdatePaymentTransactionMutationVariables
>;
export const UpdateSystemPrinterDocument = gql`
  mutation updateSystemPrinter($id: Int!, $data: SystemPrinterUpdateInput!) {
    updateSystemPrinter(id: $id, data: $data) {
      ...systemPrinter
    }
  }
  ${SystemPrinterFragmentDoc}
`;
export type UpdateSystemPrinterMutationFn = Apollo.MutationFunction<
  UpdateSystemPrinterMutation,
  UpdateSystemPrinterMutationVariables
>;

/**
 * __useUpdateSystemPrinterMutation__
 *
 * To run a mutation, you first call `useUpdateSystemPrinterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSystemPrinterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSystemPrinterMutation, { data, loading, error }] = useUpdateSystemPrinterMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateSystemPrinterMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateSystemPrinterMutation, UpdateSystemPrinterMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<UpdateSystemPrinterMutation, UpdateSystemPrinterMutationVariables>(
    UpdateSystemPrinterDocument,
    options
  );
}
export type UpdateSystemPrinterMutationHookResult = ReturnType<typeof useUpdateSystemPrinterMutation>;
export type UpdateSystemPrinterMutationResult = Apollo.MutationResult<UpdateSystemPrinterMutation>;
export type UpdateSystemPrinterMutationOptions = Apollo.BaseMutationOptions<
  UpdateSystemPrinterMutation,
  UpdateSystemPrinterMutationVariables
>;
export const UpdateSystemTranslationDocument = gql`
  mutation updateSystemTranslation($id: Int!, $data: SystemTranslationUpdateInput!) {
    updateSystemTranslation(id: $id, data: $data) {
      ...systemTranslation
    }
  }
  ${SystemTranslationFragmentDoc}
`;
export type UpdateSystemTranslationMutationFn = Apollo.MutationFunction<
  UpdateSystemTranslationMutation,
  UpdateSystemTranslationMutationVariables
>;

/**
 * __useUpdateSystemTranslationMutation__
 *
 * To run a mutation, you first call `useUpdateSystemTranslationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSystemTranslationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSystemTranslationMutation, { data, loading, error }] = useUpdateSystemTranslationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateSystemTranslationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateSystemTranslationMutation,
    UpdateSystemTranslationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<UpdateSystemTranslationMutation, UpdateSystemTranslationMutationVariables>(
    UpdateSystemTranslationDocument,
    options
  );
}
export type UpdateSystemTranslationMutationHookResult = ReturnType<typeof useUpdateSystemTranslationMutation>;
export type UpdateSystemTranslationMutationResult = Apollo.MutationResult<UpdateSystemTranslationMutation>;
export type UpdateSystemTranslationMutationOptions = Apollo.BaseMutationOptions<
  UpdateSystemTranslationMutation,
  UpdateSystemTranslationMutationVariables
>;
export const UpdateSystemTranslationsDocument = gql`
  mutation updateSystemTranslations($data: [SystemTranslationUpdateInput!]!) {
    updateSystemTranslations(data: $data) {
      ...systemTranslation
    }
  }
  ${SystemTranslationFragmentDoc}
`;
export type UpdateSystemTranslationsMutationFn = Apollo.MutationFunction<
  UpdateSystemTranslationsMutation,
  UpdateSystemTranslationsMutationVariables
>;

/**
 * __useUpdateSystemTranslationsMutation__
 *
 * To run a mutation, you first call `useUpdateSystemTranslationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSystemTranslationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSystemTranslationsMutation, { data, loading, error }] = useUpdateSystemTranslationsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateSystemTranslationsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateSystemTranslationsMutation,
    UpdateSystemTranslationsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<UpdateSystemTranslationsMutation, UpdateSystemTranslationsMutationVariables>(
    UpdateSystemTranslationsDocument,
    options
  );
}
export type UpdateSystemTranslationsMutationHookResult = ReturnType<typeof useUpdateSystemTranslationsMutation>;
export type UpdateSystemTranslationsMutationResult = Apollo.MutationResult<UpdateSystemTranslationsMutation>;
export type UpdateSystemTranslationsMutationOptions = Apollo.BaseMutationOptions<
  UpdateSystemTranslationsMutation,
  UpdateSystemTranslationsMutationVariables
>;
export const UpdateSystemUserDocument = gql`
  mutation updateSystemUser($id: ID!, $data: UpdateSystemUserInput!) {
    updateSystemUser(id: $id, data: $data) {
      ...systemUser
    }
  }
  ${SystemUserFragmentDoc}
`;
export type UpdateSystemUserMutationFn = Apollo.MutationFunction<
  UpdateSystemUserMutation,
  UpdateSystemUserMutationVariables
>;

/**
 * __useUpdateSystemUserMutation__
 *
 * To run a mutation, you first call `useUpdateSystemUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSystemUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSystemUserMutation, { data, loading, error }] = useUpdateSystemUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateSystemUserMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateSystemUserMutation, UpdateSystemUserMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<UpdateSystemUserMutation, UpdateSystemUserMutationVariables>(
    UpdateSystemUserDocument,
    options
  );
}
export type UpdateSystemUserMutationHookResult = ReturnType<typeof useUpdateSystemUserMutation>;
export type UpdateSystemUserMutationResult = Apollo.MutationResult<UpdateSystemUserMutation>;
export type UpdateSystemUserMutationOptions = Apollo.BaseMutationOptions<
  UpdateSystemUserMutation,
  UpdateSystemUserMutationVariables
>;
export const UpdateSystemWebHookDocument = gql`
  mutation updateSystemWebHook($id: Int!, $data: SystemWebHookUpdateInput!) {
    updateSystemWebHook(id: $id, data: $data) {
      ...systemWebHook
    }
  }
  ${SystemWebHookFragmentDoc}
`;
export type UpdateSystemWebHookMutationFn = Apollo.MutationFunction<
  UpdateSystemWebHookMutation,
  UpdateSystemWebHookMutationVariables
>;

/**
 * __useUpdateSystemWebHookMutation__
 *
 * To run a mutation, you first call `useUpdateSystemWebHookMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSystemWebHookMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSystemWebHookMutation, { data, loading, error }] = useUpdateSystemWebHookMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateSystemWebHookMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateSystemWebHookMutation, UpdateSystemWebHookMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<UpdateSystemWebHookMutation, UpdateSystemWebHookMutationVariables>(
    UpdateSystemWebHookDocument,
    options
  );
}
export type UpdateSystemWebHookMutationHookResult = ReturnType<typeof useUpdateSystemWebHookMutation>;
export type UpdateSystemWebHookMutationResult = Apollo.MutationResult<UpdateSystemWebHookMutation>;
export type UpdateSystemWebHookMutationOptions = Apollo.BaseMutationOptions<
  UpdateSystemWebHookMutation,
  UpdateSystemWebHookMutationVariables
>;
export const UpdateTemplateApplePassDocument = gql`
  mutation updateTemplateApplePass($id: Int!, $data: TemplateApplePassUpdateInput!) {
    updateTemplateApplePass(id: $id, data: $data) {
      ...templateApplePass
    }
  }
  ${TemplateApplePassFragmentDoc}
`;
export type UpdateTemplateApplePassMutationFn = Apollo.MutationFunction<
  UpdateTemplateApplePassMutation,
  UpdateTemplateApplePassMutationVariables
>;

/**
 * __useUpdateTemplateApplePassMutation__
 *
 * To run a mutation, you first call `useUpdateTemplateApplePassMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTemplateApplePassMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTemplateApplePassMutation, { data, loading, error }] = useUpdateTemplateApplePassMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateTemplateApplePassMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateTemplateApplePassMutation,
    UpdateTemplateApplePassMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<UpdateTemplateApplePassMutation, UpdateTemplateApplePassMutationVariables>(
    UpdateTemplateApplePassDocument,
    options
  );
}
export type UpdateTemplateApplePassMutationHookResult = ReturnType<typeof useUpdateTemplateApplePassMutation>;
export type UpdateTemplateApplePassMutationResult = Apollo.MutationResult<UpdateTemplateApplePassMutation>;
export type UpdateTemplateApplePassMutationOptions = Apollo.BaseMutationOptions<
  UpdateTemplateApplePassMutation,
  UpdateTemplateApplePassMutationVariables
>;
export const UpdateTemplateNotificationDocument = gql`
  mutation updateTemplateNotification($id: Int!, $data: TemplateNotificationUpdateInput!) {
    updateTemplateNotification(id: $id, data: $data) {
      ...templateNotification
    }
  }
  ${TemplateNotificationFragmentDoc}
`;
export type UpdateTemplateNotificationMutationFn = Apollo.MutationFunction<
  UpdateTemplateNotificationMutation,
  UpdateTemplateNotificationMutationVariables
>;

/**
 * __useUpdateTemplateNotificationMutation__
 *
 * To run a mutation, you first call `useUpdateTemplateNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTemplateNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTemplateNotificationMutation, { data, loading, error }] = useUpdateTemplateNotificationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateTemplateNotificationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateTemplateNotificationMutation,
    UpdateTemplateNotificationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<UpdateTemplateNotificationMutation, UpdateTemplateNotificationMutationVariables>(
    UpdateTemplateNotificationDocument,
    options
  );
}
export type UpdateTemplateNotificationMutationHookResult = ReturnType<typeof useUpdateTemplateNotificationMutation>;
export type UpdateTemplateNotificationMutationResult = Apollo.MutationResult<UpdateTemplateNotificationMutation>;
export type UpdateTemplateNotificationMutationOptions = Apollo.BaseMutationOptions<
  UpdateTemplateNotificationMutation,
  UpdateTemplateNotificationMutationVariables
>;
export const UpdateTemplatePdfDocument = gql`
  mutation updateTemplatePDF($id: Int!, $data: TemplatePDFUpdateInput!) {
    updateTemplatePDF(id: $id, data: $data) {
      ...templatePDF
    }
  }
  ${TemplatePdfFragmentDoc}
`;
export type UpdateTemplatePdfMutationFn = Apollo.MutationFunction<
  UpdateTemplatePdfMutation,
  UpdateTemplatePdfMutationVariables
>;

/**
 * __useUpdateTemplatePdfMutation__
 *
 * To run a mutation, you first call `useUpdateTemplatePdfMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTemplatePdfMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTemplatePdfMutation, { data, loading, error }] = useUpdateTemplatePdfMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateTemplatePdfMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateTemplatePdfMutation, UpdateTemplatePdfMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<UpdateTemplatePdfMutation, UpdateTemplatePdfMutationVariables>(
    UpdateTemplatePdfDocument,
    options
  );
}
export type UpdateTemplatePdfMutationHookResult = ReturnType<typeof useUpdateTemplatePdfMutation>;
export type UpdateTemplatePdfMutationResult = Apollo.MutationResult<UpdateTemplatePdfMutation>;
export type UpdateTemplatePdfMutationOptions = Apollo.BaseMutationOptions<
  UpdateTemplatePdfMutation,
  UpdateTemplatePdfMutationVariables
>;
export const UpdateTournamentDocument = gql`
  mutation updateTournament($id: Int!, $data: TournamentUpdateInput!) {
    updateTournament(id: $id, data: $data) {
      ...tournamentFull
    }
  }
  ${TournamentFullFragmentDoc}
`;
export type UpdateTournamentMutationFn = Apollo.MutationFunction<
  UpdateTournamentMutation,
  UpdateTournamentMutationVariables
>;

/**
 * __useUpdateTournamentMutation__
 *
 * To run a mutation, you first call `useUpdateTournamentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTournamentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTournamentMutation, { data, loading, error }] = useUpdateTournamentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateTournamentMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateTournamentMutation, UpdateTournamentMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<UpdateTournamentMutation, UpdateTournamentMutationVariables>(
    UpdateTournamentDocument,
    options
  );
}
export type UpdateTournamentMutationHookResult = ReturnType<typeof useUpdateTournamentMutation>;
export type UpdateTournamentMutationResult = Apollo.MutationResult<UpdateTournamentMutation>;
export type UpdateTournamentMutationOptions = Apollo.BaseMutationOptions<
  UpdateTournamentMutation,
  UpdateTournamentMutationVariables
>;
export const UpdateTournamentDisqualificationDocument = gql`
  mutation updateTournamentDisqualification($id: Int!, $data: TournamentDisqualificationUpdateInput!) {
    updateTournamentDisqualification(id: $id, data: $data) {
      ...tournamentDisqualification
    }
  }
  ${TournamentDisqualificationFragmentDoc}
`;
export type UpdateTournamentDisqualificationMutationFn = Apollo.MutationFunction<
  UpdateTournamentDisqualificationMutation,
  UpdateTournamentDisqualificationMutationVariables
>;

/**
 * __useUpdateTournamentDisqualificationMutation__
 *
 * To run a mutation, you first call `useUpdateTournamentDisqualificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTournamentDisqualificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTournamentDisqualificationMutation, { data, loading, error }] = useUpdateTournamentDisqualificationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateTournamentDisqualificationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateTournamentDisqualificationMutation,
    UpdateTournamentDisqualificationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UpdateTournamentDisqualificationMutation,
    UpdateTournamentDisqualificationMutationVariables
  >(UpdateTournamentDisqualificationDocument, options);
}
export type UpdateTournamentDisqualificationMutationHookResult = ReturnType<
  typeof useUpdateTournamentDisqualificationMutation
>;
export type UpdateTournamentDisqualificationMutationResult = Apollo.MutationResult<UpdateTournamentDisqualificationMutation>;
export type UpdateTournamentDisqualificationMutationOptions = Apollo.BaseMutationOptions<
  UpdateTournamentDisqualificationMutation,
  UpdateTournamentDisqualificationMutationVariables
>;
export const UpdateTournamentMatchDocument = gql`
  mutation updateTournamentMatch($id: Int!, $data: TournamentMatchUpdateInput!) {
    updateTournamentMatch(id: $id, data: $data) {
      ...tournamentMatch
    }
  }
  ${TournamentMatchFragmentDoc}
`;
export type UpdateTournamentMatchMutationFn = Apollo.MutationFunction<
  UpdateTournamentMatchMutation,
  UpdateTournamentMatchMutationVariables
>;

/**
 * __useUpdateTournamentMatchMutation__
 *
 * To run a mutation, you first call `useUpdateTournamentMatchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTournamentMatchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTournamentMatchMutation, { data, loading, error }] = useUpdateTournamentMatchMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateTournamentMatchMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateTournamentMatchMutation,
    UpdateTournamentMatchMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<UpdateTournamentMatchMutation, UpdateTournamentMatchMutationVariables>(
    UpdateTournamentMatchDocument,
    options
  );
}
export type UpdateTournamentMatchMutationHookResult = ReturnType<typeof useUpdateTournamentMatchMutation>;
export type UpdateTournamentMatchMutationResult = Apollo.MutationResult<UpdateTournamentMatchMutation>;
export type UpdateTournamentMatchMutationOptions = Apollo.BaseMutationOptions<
  UpdateTournamentMatchMutation,
  UpdateTournamentMatchMutationVariables
>;
export const UpdateTournamentMatchEventDocument = gql`
  mutation updateTournamentMatchEvent($id: Int!, $data: TournamentMatchEventUpdateInput!) {
    updateTournamentMatchEvent(id: $id, data: $data) {
      ...tournamentMatchEvent
    }
  }
  ${TournamentMatchEventFragmentDoc}
`;
export type UpdateTournamentMatchEventMutationFn = Apollo.MutationFunction<
  UpdateTournamentMatchEventMutation,
  UpdateTournamentMatchEventMutationVariables
>;

/**
 * __useUpdateTournamentMatchEventMutation__
 *
 * To run a mutation, you first call `useUpdateTournamentMatchEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTournamentMatchEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTournamentMatchEventMutation, { data, loading, error }] = useUpdateTournamentMatchEventMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateTournamentMatchEventMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateTournamentMatchEventMutation,
    UpdateTournamentMatchEventMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<UpdateTournamentMatchEventMutation, UpdateTournamentMatchEventMutationVariables>(
    UpdateTournamentMatchEventDocument,
    options
  );
}
export type UpdateTournamentMatchEventMutationHookResult = ReturnType<typeof useUpdateTournamentMatchEventMutation>;
export type UpdateTournamentMatchEventMutationResult = Apollo.MutationResult<UpdateTournamentMatchEventMutation>;
export type UpdateTournamentMatchEventMutationOptions = Apollo.BaseMutationOptions<
  UpdateTournamentMatchEventMutation,
  UpdateTournamentMatchEventMutationVariables
>;
export const UpdateTournamentMatchLineUpDocument = gql`
  mutation updateTournamentMatchLineUp($id: Int!, $data: TournamentMatchLineUpUpdateInput!) {
    updateTournamentMatchLineUp(id: $id, data: $data) {
      ...tournamentMatchLineUp
    }
  }
  ${TournamentMatchLineUpFragmentDoc}
`;
export type UpdateTournamentMatchLineUpMutationFn = Apollo.MutationFunction<
  UpdateTournamentMatchLineUpMutation,
  UpdateTournamentMatchLineUpMutationVariables
>;

/**
 * __useUpdateTournamentMatchLineUpMutation__
 *
 * To run a mutation, you first call `useUpdateTournamentMatchLineUpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTournamentMatchLineUpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTournamentMatchLineUpMutation, { data, loading, error }] = useUpdateTournamentMatchLineUpMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateTournamentMatchLineUpMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateTournamentMatchLineUpMutation,
    UpdateTournamentMatchLineUpMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UpdateTournamentMatchLineUpMutation,
    UpdateTournamentMatchLineUpMutationVariables
  >(UpdateTournamentMatchLineUpDocument, options);
}
export type UpdateTournamentMatchLineUpMutationHookResult = ReturnType<typeof useUpdateTournamentMatchLineUpMutation>;
export type UpdateTournamentMatchLineUpMutationResult = Apollo.MutationResult<UpdateTournamentMatchLineUpMutation>;
export type UpdateTournamentMatchLineUpMutationOptions = Apollo.BaseMutationOptions<
  UpdateTournamentMatchLineUpMutation,
  UpdateTournamentMatchLineUpMutationVariables
>;
export const UpdateTournamentMatchProtocolDocument = gql`
  mutation updateTournamentMatchProtocol($id: Int!, $data: TournamentMatchProtocolUpdateInput!) {
    updateTournamentMatchProtocol(id: $id, data: $data) {
      ...tournamentMatchProtocol
    }
  }
  ${TournamentMatchProtocolFragmentDoc}
`;
export type UpdateTournamentMatchProtocolMutationFn = Apollo.MutationFunction<
  UpdateTournamentMatchProtocolMutation,
  UpdateTournamentMatchProtocolMutationVariables
>;

/**
 * __useUpdateTournamentMatchProtocolMutation__
 *
 * To run a mutation, you first call `useUpdateTournamentMatchProtocolMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTournamentMatchProtocolMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTournamentMatchProtocolMutation, { data, loading, error }] = useUpdateTournamentMatchProtocolMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateTournamentMatchProtocolMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateTournamentMatchProtocolMutation,
    UpdateTournamentMatchProtocolMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UpdateTournamentMatchProtocolMutation,
    UpdateTournamentMatchProtocolMutationVariables
  >(UpdateTournamentMatchProtocolDocument, options);
}
export type UpdateTournamentMatchProtocolMutationHookResult = ReturnType<
  typeof useUpdateTournamentMatchProtocolMutation
>;
export type UpdateTournamentMatchProtocolMutationResult = Apollo.MutationResult<UpdateTournamentMatchProtocolMutation>;
export type UpdateTournamentMatchProtocolMutationOptions = Apollo.BaseMutationOptions<
  UpdateTournamentMatchProtocolMutation,
  UpdateTournamentMatchProtocolMutationVariables
>;
export const UpdateTournamentPlayerStatDocument = gql`
  mutation updateTournamentPlayerStat($id: Int!, $data: TournamentPlayerStatUpdateInput!) {
    updateTournamentPlayerStat(id: $id, data: $data) {
      ...tournamentPlayerStat
    }
  }
  ${TournamentPlayerStatFragmentDoc}
`;
export type UpdateTournamentPlayerStatMutationFn = Apollo.MutationFunction<
  UpdateTournamentPlayerStatMutation,
  UpdateTournamentPlayerStatMutationVariables
>;

/**
 * __useUpdateTournamentPlayerStatMutation__
 *
 * To run a mutation, you first call `useUpdateTournamentPlayerStatMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTournamentPlayerStatMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTournamentPlayerStatMutation, { data, loading, error }] = useUpdateTournamentPlayerStatMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateTournamentPlayerStatMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateTournamentPlayerStatMutation,
    UpdateTournamentPlayerStatMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<UpdateTournamentPlayerStatMutation, UpdateTournamentPlayerStatMutationVariables>(
    UpdateTournamentPlayerStatDocument,
    options
  );
}
export type UpdateTournamentPlayerStatMutationHookResult = ReturnType<typeof useUpdateTournamentPlayerStatMutation>;
export type UpdateTournamentPlayerStatMutationResult = Apollo.MutationResult<UpdateTournamentPlayerStatMutation>;
export type UpdateTournamentPlayerStatMutationOptions = Apollo.BaseMutationOptions<
  UpdateTournamentPlayerStatMutation,
  UpdateTournamentPlayerStatMutationVariables
>;
export const UpdateTournamentRefereeDocument = gql`
  mutation updateTournamentReferee($id: Int!, $data: TournamentRefereeUpdateInput!) {
    updateTournamentReferee(id: $id, data: $data) {
      ...tournamentReferee
    }
  }
  ${TournamentRefereeFragmentDoc}
`;
export type UpdateTournamentRefereeMutationFn = Apollo.MutationFunction<
  UpdateTournamentRefereeMutation,
  UpdateTournamentRefereeMutationVariables
>;

/**
 * __useUpdateTournamentRefereeMutation__
 *
 * To run a mutation, you first call `useUpdateTournamentRefereeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTournamentRefereeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTournamentRefereeMutation, { data, loading, error }] = useUpdateTournamentRefereeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateTournamentRefereeMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateTournamentRefereeMutation,
    UpdateTournamentRefereeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<UpdateTournamentRefereeMutation, UpdateTournamentRefereeMutationVariables>(
    UpdateTournamentRefereeDocument,
    options
  );
}
export type UpdateTournamentRefereeMutationHookResult = ReturnType<typeof useUpdateTournamentRefereeMutation>;
export type UpdateTournamentRefereeMutationResult = Apollo.MutationResult<UpdateTournamentRefereeMutation>;
export type UpdateTournamentRefereeMutationOptions = Apollo.BaseMutationOptions<
  UpdateTournamentRefereeMutation,
  UpdateTournamentRefereeMutationVariables
>;
export const UpdateTournamentSeasonDocument = gql`
  mutation updateTournamentSeason($id: Int!, $data: TournamentSeasonUpdateInput!) {
    updateTournamentSeason(id: $id, data: $data) {
      ...tournamentSeason
    }
  }
  ${TournamentSeasonFragmentDoc}
`;
export type UpdateTournamentSeasonMutationFn = Apollo.MutationFunction<
  UpdateTournamentSeasonMutation,
  UpdateTournamentSeasonMutationVariables
>;

/**
 * __useUpdateTournamentSeasonMutation__
 *
 * To run a mutation, you first call `useUpdateTournamentSeasonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTournamentSeasonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTournamentSeasonMutation, { data, loading, error }] = useUpdateTournamentSeasonMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateTournamentSeasonMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateTournamentSeasonMutation,
    UpdateTournamentSeasonMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<UpdateTournamentSeasonMutation, UpdateTournamentSeasonMutationVariables>(
    UpdateTournamentSeasonDocument,
    options
  );
}
export type UpdateTournamentSeasonMutationHookResult = ReturnType<typeof useUpdateTournamentSeasonMutation>;
export type UpdateTournamentSeasonMutationResult = Apollo.MutationResult<UpdateTournamentSeasonMutation>;
export type UpdateTournamentSeasonMutationOptions = Apollo.BaseMutationOptions<
  UpdateTournamentSeasonMutation,
  UpdateTournamentSeasonMutationVariables
>;
export const UpdateTournamentSeasonTeamDocument = gql`
  mutation updateTournamentSeasonTeam($id: Int!, $data: TournamentSeasonTeamUpdateInput!) {
    updateTournamentSeasonTeam(id: $id, data: $data) {
      ...tournamentSeasonTeam
    }
  }
  ${TournamentSeasonTeamFragmentDoc}
`;
export type UpdateTournamentSeasonTeamMutationFn = Apollo.MutationFunction<
  UpdateTournamentSeasonTeamMutation,
  UpdateTournamentSeasonTeamMutationVariables
>;

/**
 * __useUpdateTournamentSeasonTeamMutation__
 *
 * To run a mutation, you first call `useUpdateTournamentSeasonTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTournamentSeasonTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTournamentSeasonTeamMutation, { data, loading, error }] = useUpdateTournamentSeasonTeamMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateTournamentSeasonTeamMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateTournamentSeasonTeamMutation,
    UpdateTournamentSeasonTeamMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<UpdateTournamentSeasonTeamMutation, UpdateTournamentSeasonTeamMutationVariables>(
    UpdateTournamentSeasonTeamDocument,
    options
  );
}
export type UpdateTournamentSeasonTeamMutationHookResult = ReturnType<typeof useUpdateTournamentSeasonTeamMutation>;
export type UpdateTournamentSeasonTeamMutationResult = Apollo.MutationResult<UpdateTournamentSeasonTeamMutation>;
export type UpdateTournamentSeasonTeamMutationOptions = Apollo.BaseMutationOptions<
  UpdateTournamentSeasonTeamMutation,
  UpdateTournamentSeasonTeamMutationVariables
>;
export const UpdateTournamentSeasonTeamStatDocument = gql`
  mutation updateTournamentSeasonTeamStat($id: Int!, $data: TournamentSeasonTeamStatUpdateInput!) {
    updateTournamentSeasonTeamStat(id: $id, data: $data) {
      ...tournamentSeasonTeamStat
    }
  }
  ${TournamentSeasonTeamStatFragmentDoc}
`;
export type UpdateTournamentSeasonTeamStatMutationFn = Apollo.MutationFunction<
  UpdateTournamentSeasonTeamStatMutation,
  UpdateTournamentSeasonTeamStatMutationVariables
>;

/**
 * __useUpdateTournamentSeasonTeamStatMutation__
 *
 * To run a mutation, you first call `useUpdateTournamentSeasonTeamStatMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTournamentSeasonTeamStatMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTournamentSeasonTeamStatMutation, { data, loading, error }] = useUpdateTournamentSeasonTeamStatMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateTournamentSeasonTeamStatMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateTournamentSeasonTeamStatMutation,
    UpdateTournamentSeasonTeamStatMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UpdateTournamentSeasonTeamStatMutation,
    UpdateTournamentSeasonTeamStatMutationVariables
  >(UpdateTournamentSeasonTeamStatDocument, options);
}
export type UpdateTournamentSeasonTeamStatMutationHookResult = ReturnType<
  typeof useUpdateTournamentSeasonTeamStatMutation
>;
export type UpdateTournamentSeasonTeamStatMutationResult = Apollo.MutationResult<UpdateTournamentSeasonTeamStatMutation>;
export type UpdateTournamentSeasonTeamStatMutationOptions = Apollo.BaseMutationOptions<
  UpdateTournamentSeasonTeamStatMutation,
  UpdateTournamentSeasonTeamStatMutationVariables
>;
export const UpdateTournamentTeamDocument = gql`
  mutation updateTournamentTeam($id: Int!, $data: TournamentTeamUpdateInput!) {
    updateTournamentTeam(id: $id, data: $data) {
      ...tournamentTeam
    }
  }
  ${TournamentTeamFragmentDoc}
`;
export type UpdateTournamentTeamMutationFn = Apollo.MutationFunction<
  UpdateTournamentTeamMutation,
  UpdateTournamentTeamMutationVariables
>;

/**
 * __useUpdateTournamentTeamMutation__
 *
 * To run a mutation, you first call `useUpdateTournamentTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTournamentTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTournamentTeamMutation, { data, loading, error }] = useUpdateTournamentTeamMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateTournamentTeamMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateTournamentTeamMutation,
    UpdateTournamentTeamMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<UpdateTournamentTeamMutation, UpdateTournamentTeamMutationVariables>(
    UpdateTournamentTeamDocument,
    options
  );
}
export type UpdateTournamentTeamMutationHookResult = ReturnType<typeof useUpdateTournamentTeamMutation>;
export type UpdateTournamentTeamMutationResult = Apollo.MutationResult<UpdateTournamentTeamMutation>;
export type UpdateTournamentTeamMutationOptions = Apollo.BaseMutationOptions<
  UpdateTournamentTeamMutation,
  UpdateTournamentTeamMutationVariables
>;
export const UpdateTournamentTeamMemberDocument = gql`
  mutation updateTournamentTeamMember($id: Int!, $data: TournamentTeamMemberUpdateInput!) {
    updateTournamentTeamMember(id: $id, data: $data) {
      ...tournamentTeamMember
    }
  }
  ${TournamentTeamMemberFragmentDoc}
`;
export type UpdateTournamentTeamMemberMutationFn = Apollo.MutationFunction<
  UpdateTournamentTeamMemberMutation,
  UpdateTournamentTeamMemberMutationVariables
>;

/**
 * __useUpdateTournamentTeamMemberMutation__
 *
 * To run a mutation, you first call `useUpdateTournamentTeamMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTournamentTeamMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTournamentTeamMemberMutation, { data, loading, error }] = useUpdateTournamentTeamMemberMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateTournamentTeamMemberMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateTournamentTeamMemberMutation,
    UpdateTournamentTeamMemberMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<UpdateTournamentTeamMemberMutation, UpdateTournamentTeamMemberMutationVariables>(
    UpdateTournamentTeamMemberDocument,
    options
  );
}
export type UpdateTournamentTeamMemberMutationHookResult = ReturnType<typeof useUpdateTournamentTeamMemberMutation>;
export type UpdateTournamentTeamMemberMutationResult = Apollo.MutationResult<UpdateTournamentTeamMemberMutation>;
export type UpdateTournamentTeamMemberMutationOptions = Apollo.BaseMutationOptions<
  UpdateTournamentTeamMemberMutation,
  UpdateTournamentTeamMemberMutationVariables
>;
export const UpdateTournamentTourDocument = gql`
  mutation updateTournamentTour($id: Int!, $data: TournamentTourUpdateInput!) {
    updateTournamentTour(id: $id, data: $data) {
      ...tournamentTour
    }
  }
  ${TournamentTourFragmentDoc}
`;
export type UpdateTournamentTourMutationFn = Apollo.MutationFunction<
  UpdateTournamentTourMutation,
  UpdateTournamentTourMutationVariables
>;

/**
 * __useUpdateTournamentTourMutation__
 *
 * To run a mutation, you first call `useUpdateTournamentTourMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTournamentTourMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTournamentTourMutation, { data, loading, error }] = useUpdateTournamentTourMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateTournamentTourMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateTournamentTourMutation,
    UpdateTournamentTourMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<UpdateTournamentTourMutation, UpdateTournamentTourMutationVariables>(
    UpdateTournamentTourDocument,
    options
  );
}
export type UpdateTournamentTourMutationHookResult = ReturnType<typeof useUpdateTournamentTourMutation>;
export type UpdateTournamentTourMutationResult = Apollo.MutationResult<UpdateTournamentTourMutation>;
export type UpdateTournamentTourMutationOptions = Apollo.BaseMutationOptions<
  UpdateTournamentTourMutation,
  UpdateTournamentTourMutationVariables
>;
export const UpdateUserDocument = gql`
  mutation updateUser($id: Int!, $data: UserUpdateInput!) {
    updateUser(id: $id, data: $data) {
      ...user
    }
  }
  ${UserFragmentDoc}
`;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateUserMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
}
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const UpdateUserGroupDocument = gql`
  mutation updateUserGroup($id: Int!, $data: UserGroupUpdateInput!) {
    updateUserGroup(id: $id, data: $data) {
      ...userGroup
    }
  }
  ${UserGroupFragmentDoc}
`;
export type UpdateUserGroupMutationFn = Apollo.MutationFunction<
  UpdateUserGroupMutation,
  UpdateUserGroupMutationVariables
>;

/**
 * __useUpdateUserGroupMutation__
 *
 * To run a mutation, you first call `useUpdateUserGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserGroupMutation, { data, loading, error }] = useUpdateUserGroupMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateUserGroupMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateUserGroupMutation, UpdateUserGroupMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<UpdateUserGroupMutation, UpdateUserGroupMutationVariables>(
    UpdateUserGroupDocument,
    options
  );
}
export type UpdateUserGroupMutationHookResult = ReturnType<typeof useUpdateUserGroupMutation>;
export type UpdateUserGroupMutationResult = Apollo.MutationResult<UpdateUserGroupMutation>;
export type UpdateUserGroupMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserGroupMutation,
  UpdateUserGroupMutationVariables
>;
export const UpdateUserSubscriberDocument = gql`
  mutation updateUserSubscriber($id: String!, $data: UserSubscriberUpdateInput!) {
    updateUserSubscriber(id: $id, data: $data) {
      ...userSubscriber
    }
  }
  ${UserSubscriberFragmentDoc}
`;
export type UpdateUserSubscriberMutationFn = Apollo.MutationFunction<
  UpdateUserSubscriberMutation,
  UpdateUserSubscriberMutationVariables
>;

/**
 * __useUpdateUserSubscriberMutation__
 *
 * To run a mutation, you first call `useUpdateUserSubscriberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserSubscriberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserSubscriberMutation, { data, loading, error }] = useUpdateUserSubscriberMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateUserSubscriberMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateUserSubscriberMutation,
    UpdateUserSubscriberMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<UpdateUserSubscriberMutation, UpdateUserSubscriberMutationVariables>(
    UpdateUserSubscriberDocument,
    options
  );
}
export type UpdateUserSubscriberMutationHookResult = ReturnType<typeof useUpdateUserSubscriberMutation>;
export type UpdateUserSubscriberMutationResult = Apollo.MutationResult<UpdateUserSubscriberMutation>;
export type UpdateUserSubscriberMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserSubscriberMutation,
  UpdateUserSubscriberMutationVariables
>;
export const UpdateUserSubscriptionDocument = gql`
  mutation updateUserSubscription($id: Int!, $data: UserSubscriptionUpdateInput!) {
    updateUserSubscription(id: $id, data: $data) {
      ...userSubscription
    }
  }
  ${UserSubscriptionFragmentDoc}
`;
export type UpdateUserSubscriptionMutationFn = Apollo.MutationFunction<
  UpdateUserSubscriptionMutation,
  UpdateUserSubscriptionMutationVariables
>;

/**
 * __useUpdateUserSubscriptionMutation__
 *
 * To run a mutation, you first call `useUpdateUserSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserSubscriptionMutation, { data, loading, error }] = useUpdateUserSubscriptionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateUserSubscriptionMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateUserSubscriptionMutation,
    UpdateUserSubscriptionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<UpdateUserSubscriptionMutation, UpdateUserSubscriptionMutationVariables>(
    UpdateUserSubscriptionDocument,
    options
  );
}
export type UpdateUserSubscriptionMutationHookResult = ReturnType<typeof useUpdateUserSubscriptionMutation>;
export type UpdateUserSubscriptionMutationResult = Apollo.MutationResult<UpdateUserSubscriptionMutation>;
export type UpdateUserSubscriptionMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserSubscriptionMutation,
  UpdateUserSubscriptionMutationVariables
>;
export const ValidatePhoneDocument = gql`
  mutation validatePhone($phone: String!, $code: String) {
    validatePhone(phone: $phone, code: $code)
  }
`;
export type ValidatePhoneMutationFn = Apollo.MutationFunction<ValidatePhoneMutation, ValidatePhoneMutationVariables>;

/**
 * __useValidatePhoneMutation__
 *
 * To run a mutation, you first call `useValidatePhoneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useValidatePhoneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [validatePhoneMutation, { data, loading, error }] = useValidatePhoneMutation({
 *   variables: {
 *      phone: // value for 'phone'
 *      code: // value for 'code'
 *   },
 * });
 */
export function useValidatePhoneMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<ValidatePhoneMutation, ValidatePhoneMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<ValidatePhoneMutation, ValidatePhoneMutationVariables>(
    ValidatePhoneDocument,
    options
  );
}
export type ValidatePhoneMutationHookResult = ReturnType<typeof useValidatePhoneMutation>;
export type ValidatePhoneMutationResult = Apollo.MutationResult<ValidatePhoneMutation>;
export type ValidatePhoneMutationOptions = Apollo.BaseMutationOptions<
  ValidatePhoneMutation,
  ValidatePhoneMutationVariables
>;
export const AclDocument = gql`
  query acl {
    acl {
      ...acl
    }
  }
  ${AclFragmentDoc}
`;

/**
 * __useAclQuery__
 *
 * To run a query within a React component, call `useAclQuery` and pass it any options that fit your needs.
 * When your component renders, `useAclQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAclQuery({
 *   variables: {
 *   },
 * });
 */
export function useAclQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AclQuery, AclQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AclQuery, AclQueryVariables>(AclDocument, options);
}
export function useAclLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AclQuery, AclQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AclQuery, AclQueryVariables>(AclDocument, options);
}
export type AclQueryHookResult = ReturnType<typeof useAclQuery>;
export type AclLazyQueryHookResult = ReturnType<typeof useAclLazyQuery>;
export type AclQueryResult = Apollo.QueryResult<AclQuery, AclQueryVariables>;
export const AllArticleAuthorsDocument = gql`
  query allArticleAuthors($query: DefaultQueryInput) {
    allArticleAuthors(query: $query) {
      ...articleAuthorConnection
    }
  }
  ${ArticleAuthorConnectionFragmentDoc}
`;

/**
 * __useAllArticleAuthorsQuery__
 *
 * To run a query within a React component, call `useAllArticleAuthorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllArticleAuthorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllArticleAuthorsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllArticleAuthorsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllArticleAuthorsQuery, AllArticleAuthorsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AllArticleAuthorsQuery, AllArticleAuthorsQueryVariables>(
    AllArticleAuthorsDocument,
    options
  );
}
export function useAllArticleAuthorsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllArticleAuthorsQuery, AllArticleAuthorsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AllArticleAuthorsQuery, AllArticleAuthorsQueryVariables>(
    AllArticleAuthorsDocument,
    options
  );
}
export type AllArticleAuthorsQueryHookResult = ReturnType<typeof useAllArticleAuthorsQuery>;
export type AllArticleAuthorsLazyQueryHookResult = ReturnType<typeof useAllArticleAuthorsLazyQuery>;
export type AllArticleAuthorsQueryResult = Apollo.QueryResult<AllArticleAuthorsQuery, AllArticleAuthorsQueryVariables>;
export const AllArticleBlockAuthorsDocument = gql`
  query allArticleBlockAuthors($query: DefaultQueryInput) {
    allArticleBlockAuthors(query: $query) {
      ...articleBlockAuthorsConnection
    }
  }
  ${ArticleBlockAuthorsConnectionFragmentDoc}
`;

/**
 * __useAllArticleBlockAuthorsQuery__
 *
 * To run a query within a React component, call `useAllArticleBlockAuthorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllArticleBlockAuthorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllArticleBlockAuthorsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllArticleBlockAuthorsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllArticleBlockAuthorsQuery, AllArticleBlockAuthorsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AllArticleBlockAuthorsQuery, AllArticleBlockAuthorsQueryVariables>(
    AllArticleBlockAuthorsDocument,
    options
  );
}
export function useAllArticleBlockAuthorsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllArticleBlockAuthorsQuery, AllArticleBlockAuthorsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AllArticleBlockAuthorsQuery, AllArticleBlockAuthorsQueryVariables>(
    AllArticleBlockAuthorsDocument,
    options
  );
}
export type AllArticleBlockAuthorsQueryHookResult = ReturnType<typeof useAllArticleBlockAuthorsQuery>;
export type AllArticleBlockAuthorsLazyQueryHookResult = ReturnType<typeof useAllArticleBlockAuthorsLazyQuery>;
export type AllArticleBlockAuthorsQueryResult = Apollo.QueryResult<
  AllArticleBlockAuthorsQuery,
  AllArticleBlockAuthorsQueryVariables
>;
export const AllArticleBlocksDocument = gql`
  query allArticleBlocks($query: DefaultQueryInput) {
    allArticleBlocks(query: $query) {
      ...articleBlockConnection
    }
  }
  ${ArticleBlockConnectionFragmentDoc}
`;

/**
 * __useAllArticleBlocksQuery__
 *
 * To run a query within a React component, call `useAllArticleBlocksQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllArticleBlocksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllArticleBlocksQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllArticleBlocksQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllArticleBlocksQuery, AllArticleBlocksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AllArticleBlocksQuery, AllArticleBlocksQueryVariables>(
    AllArticleBlocksDocument,
    options
  );
}
export function useAllArticleBlocksLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllArticleBlocksQuery, AllArticleBlocksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AllArticleBlocksQuery, AllArticleBlocksQueryVariables>(
    AllArticleBlocksDocument,
    options
  );
}
export type AllArticleBlocksQueryHookResult = ReturnType<typeof useAllArticleBlocksQuery>;
export type AllArticleBlocksLazyQueryHookResult = ReturnType<typeof useAllArticleBlocksLazyQuery>;
export type AllArticleBlocksQueryResult = Apollo.QueryResult<AllArticleBlocksQuery, AllArticleBlocksQueryVariables>;
export const AllArticleCategoriesDocument = gql`
  query allArticleCategories($query: DefaultQueryInput) {
    allArticleCategories(query: $query) {
      ...articleCategoryConnection
    }
  }
  ${ArticleCategoryConnectionFragmentDoc}
`;

/**
 * __useAllArticleCategoriesQuery__
 *
 * To run a query within a React component, call `useAllArticleCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllArticleCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllArticleCategoriesQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllArticleCategoriesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllArticleCategoriesQuery, AllArticleCategoriesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AllArticleCategoriesQuery, AllArticleCategoriesQueryVariables>(
    AllArticleCategoriesDocument,
    options
  );
}
export function useAllArticleCategoriesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllArticleCategoriesQuery, AllArticleCategoriesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AllArticleCategoriesQuery, AllArticleCategoriesQueryVariables>(
    AllArticleCategoriesDocument,
    options
  );
}
export type AllArticleCategoriesQueryHookResult = ReturnType<typeof useAllArticleCategoriesQuery>;
export type AllArticleCategoriesLazyQueryHookResult = ReturnType<typeof useAllArticleCategoriesLazyQuery>;
export type AllArticleCategoriesQueryResult = Apollo.QueryResult<
  AllArticleCategoriesQuery,
  AllArticleCategoriesQueryVariables
>;
export const AllEntityRecordsByArticleLinkTypeDocument = gql`
  query allEntityRecordsByArticleLinkType($query: DefaultQueryInput) {
    allEntityRecordsByArticleLinkType(query: $query) {
      ...articleLinkEntityTypeConnection
    }
  }
  ${ArticleLinkEntityTypeConnectionFragmentDoc}
`;

/**
 * __useAllEntityRecordsByArticleLinkTypeQuery__
 *
 * To run a query within a React component, call `useAllEntityRecordsByArticleLinkTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllEntityRecordsByArticleLinkTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllEntityRecordsByArticleLinkTypeQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllEntityRecordsByArticleLinkTypeQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    AllEntityRecordsByArticleLinkTypeQuery,
    AllEntityRecordsByArticleLinkTypeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    AllEntityRecordsByArticleLinkTypeQuery,
    AllEntityRecordsByArticleLinkTypeQueryVariables
  >(AllEntityRecordsByArticleLinkTypeDocument, options);
}
export function useAllEntityRecordsByArticleLinkTypeLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AllEntityRecordsByArticleLinkTypeQuery,
    AllEntityRecordsByArticleLinkTypeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    AllEntityRecordsByArticleLinkTypeQuery,
    AllEntityRecordsByArticleLinkTypeQueryVariables
  >(AllEntityRecordsByArticleLinkTypeDocument, options);
}
export type AllEntityRecordsByArticleLinkTypeQueryHookResult = ReturnType<
  typeof useAllEntityRecordsByArticleLinkTypeQuery
>;
export type AllEntityRecordsByArticleLinkTypeLazyQueryHookResult = ReturnType<
  typeof useAllEntityRecordsByArticleLinkTypeLazyQuery
>;
export type AllEntityRecordsByArticleLinkTypeQueryResult = Apollo.QueryResult<
  AllEntityRecordsByArticleLinkTypeQuery,
  AllEntityRecordsByArticleLinkTypeQueryVariables
>;
export const AllArticleLinksDocument = gql`
  query allArticleLinks($query: DefaultQueryInput) {
    allArticleLinks(query: $query) {
      ...articleLinkConnection
    }
  }
  ${ArticleLinkConnectionFragmentDoc}
`;

/**
 * __useAllArticleLinksQuery__
 *
 * To run a query within a React component, call `useAllArticleLinksQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllArticleLinksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllArticleLinksQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllArticleLinksQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllArticleLinksQuery, AllArticleLinksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AllArticleLinksQuery, AllArticleLinksQueryVariables>(
    AllArticleLinksDocument,
    options
  );
}
export function useAllArticleLinksLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllArticleLinksQuery, AllArticleLinksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AllArticleLinksQuery, AllArticleLinksQueryVariables>(
    AllArticleLinksDocument,
    options
  );
}
export type AllArticleLinksQueryHookResult = ReturnType<typeof useAllArticleLinksQuery>;
export type AllArticleLinksLazyQueryHookResult = ReturnType<typeof useAllArticleLinksLazyQuery>;
export type AllArticleLinksQueryResult = Apollo.QueryResult<AllArticleLinksQuery, AllArticleLinksQueryVariables>;
export const AllArticleTagsDocument = gql`
  query allArticleTags($query: DefaultQueryInput) {
    allArticleTags(query: $query) {
      ...articleTagConnection
    }
  }
  ${ArticleTagConnectionFragmentDoc}
`;

/**
 * __useAllArticleTagsQuery__
 *
 * To run a query within a React component, call `useAllArticleTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllArticleTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllArticleTagsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllArticleTagsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllArticleTagsQuery, AllArticleTagsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AllArticleTagsQuery, AllArticleTagsQueryVariables>(AllArticleTagsDocument, options);
}
export function useAllArticleTagsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllArticleTagsQuery, AllArticleTagsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AllArticleTagsQuery, AllArticleTagsQueryVariables>(
    AllArticleTagsDocument,
    options
  );
}
export type AllArticleTagsQueryHookResult = ReturnType<typeof useAllArticleTagsQuery>;
export type AllArticleTagsLazyQueryHookResult = ReturnType<typeof useAllArticleTagsLazyQuery>;
export type AllArticleTagsQueryResult = Apollo.QueryResult<AllArticleTagsQuery, AllArticleTagsQueryVariables>;
export const AllArticlesDocument = gql`
  query allArticles($query: DefaultQueryInput) {
    allArticles(query: $query) {
      ...articlesConnection
    }
  }
  ${ArticlesConnectionFragmentDoc}
`;

/**
 * __useAllArticlesQuery__
 *
 * To run a query within a React component, call `useAllArticlesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllArticlesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllArticlesQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllArticlesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllArticlesQuery, AllArticlesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AllArticlesQuery, AllArticlesQueryVariables>(AllArticlesDocument, options);
}
export function useAllArticlesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllArticlesQuery, AllArticlesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AllArticlesQuery, AllArticlesQueryVariables>(AllArticlesDocument, options);
}
export type AllArticlesQueryHookResult = ReturnType<typeof useAllArticlesQuery>;
export type AllArticlesLazyQueryHookResult = ReturnType<typeof useAllArticlesLazyQuery>;
export type AllArticlesQueryResult = Apollo.QueryResult<AllArticlesQuery, AllArticlesQueryVariables>;
export const AllCatalogueCategoriesDocument = gql`
  query allCatalogueCategories($query: DefaultQueryInput) {
    allCatalogueCategories(query: $query) {
      ...catalogueCategoryConnection
    }
  }
  ${CatalogueCategoryConnectionFragmentDoc}
`;

/**
 * __useAllCatalogueCategoriesQuery__
 *
 * To run a query within a React component, call `useAllCatalogueCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllCatalogueCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllCatalogueCategoriesQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllCatalogueCategoriesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllCatalogueCategoriesQuery, AllCatalogueCategoriesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AllCatalogueCategoriesQuery, AllCatalogueCategoriesQueryVariables>(
    AllCatalogueCategoriesDocument,
    options
  );
}
export function useAllCatalogueCategoriesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllCatalogueCategoriesQuery, AllCatalogueCategoriesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AllCatalogueCategoriesQuery, AllCatalogueCategoriesQueryVariables>(
    AllCatalogueCategoriesDocument,
    options
  );
}
export type AllCatalogueCategoriesQueryHookResult = ReturnType<typeof useAllCatalogueCategoriesQuery>;
export type AllCatalogueCategoriesLazyQueryHookResult = ReturnType<typeof useAllCatalogueCategoriesLazyQuery>;
export type AllCatalogueCategoriesQueryResult = Apollo.QueryResult<
  AllCatalogueCategoriesQuery,
  AllCatalogueCategoriesQueryVariables
>;
export const AllCatalogueItemFieldsDocument = gql`
  query allCatalogueItemFields($query: DefaultQueryInput) {
    allCatalogueItemFields(query: $query) {
      ...catalogueItemFieldsConnection
    }
  }
  ${CatalogueItemFieldsConnectionFragmentDoc}
`;

/**
 * __useAllCatalogueItemFieldsQuery__
 *
 * To run a query within a React component, call `useAllCatalogueItemFieldsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllCatalogueItemFieldsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllCatalogueItemFieldsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllCatalogueItemFieldsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllCatalogueItemFieldsQuery, AllCatalogueItemFieldsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AllCatalogueItemFieldsQuery, AllCatalogueItemFieldsQueryVariables>(
    AllCatalogueItemFieldsDocument,
    options
  );
}
export function useAllCatalogueItemFieldsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllCatalogueItemFieldsQuery, AllCatalogueItemFieldsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AllCatalogueItemFieldsQuery, AllCatalogueItemFieldsQueryVariables>(
    AllCatalogueItemFieldsDocument,
    options
  );
}
export type AllCatalogueItemFieldsQueryHookResult = ReturnType<typeof useAllCatalogueItemFieldsQuery>;
export type AllCatalogueItemFieldsLazyQueryHookResult = ReturnType<typeof useAllCatalogueItemFieldsLazyQuery>;
export type AllCatalogueItemFieldsQueryResult = Apollo.QueryResult<
  AllCatalogueItemFieldsQuery,
  AllCatalogueItemFieldsQueryVariables
>;
export const AllCatalogueItemPricesDocument = gql`
  query allCatalogueItemPrices($query: DefaultQueryInput) {
    allCatalogueItemPrices(query: $query) {
      ...catalogueItemPriceConnection
    }
  }
  ${CatalogueItemPriceConnectionFragmentDoc}
`;

/**
 * __useAllCatalogueItemPricesQuery__
 *
 * To run a query within a React component, call `useAllCatalogueItemPricesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllCatalogueItemPricesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllCatalogueItemPricesQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllCatalogueItemPricesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllCatalogueItemPricesQuery, AllCatalogueItemPricesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AllCatalogueItemPricesQuery, AllCatalogueItemPricesQueryVariables>(
    AllCatalogueItemPricesDocument,
    options
  );
}
export function useAllCatalogueItemPricesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllCatalogueItemPricesQuery, AllCatalogueItemPricesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AllCatalogueItemPricesQuery, AllCatalogueItemPricesQueryVariables>(
    AllCatalogueItemPricesDocument,
    options
  );
}
export type AllCatalogueItemPricesQueryHookResult = ReturnType<typeof useAllCatalogueItemPricesQuery>;
export type AllCatalogueItemPricesLazyQueryHookResult = ReturnType<typeof useAllCatalogueItemPricesLazyQuery>;
export type AllCatalogueItemPricesQueryResult = Apollo.QueryResult<
  AllCatalogueItemPricesQuery,
  AllCatalogueItemPricesQueryVariables
>;
export const AllCatalogueItemsDocument = gql`
  query allCatalogueItems($query: DefaultQueryInput) {
    allCatalogueItems(query: $query) {
      ...catalogueItemConnection
    }
  }
  ${CatalogueItemConnectionFragmentDoc}
`;

/**
 * __useAllCatalogueItemsQuery__
 *
 * To run a query within a React component, call `useAllCatalogueItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllCatalogueItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllCatalogueItemsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllCatalogueItemsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllCatalogueItemsQuery, AllCatalogueItemsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AllCatalogueItemsQuery, AllCatalogueItemsQueryVariables>(
    AllCatalogueItemsDocument,
    options
  );
}
export function useAllCatalogueItemsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllCatalogueItemsQuery, AllCatalogueItemsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AllCatalogueItemsQuery, AllCatalogueItemsQueryVariables>(
    AllCatalogueItemsDocument,
    options
  );
}
export type AllCatalogueItemsQueryHookResult = ReturnType<typeof useAllCatalogueItemsQuery>;
export type AllCatalogueItemsLazyQueryHookResult = ReturnType<typeof useAllCatalogueItemsLazyQuery>;
export type AllCatalogueItemsQueryResult = Apollo.QueryResult<AllCatalogueItemsQuery, AllCatalogueItemsQueryVariables>;
export const AllCatalogueItemsAndCategoriesDocument = gql`
  query allCatalogueItemsAndCategories($categoriesQuery: DefaultQueryInput, $itemsQuery: DefaultQueryInput) {
    allCatalogueCategories(query: $categoriesQuery) {
      ...catalogueCategoryConnection
    }
    allCatalogueItems(query: $itemsQuery) {
      ...catalogueItemConnection
    }
  }
  ${CatalogueCategoryConnectionFragmentDoc}
  ${CatalogueItemConnectionFragmentDoc}
`;

/**
 * __useAllCatalogueItemsAndCategoriesQuery__
 *
 * To run a query within a React component, call `useAllCatalogueItemsAndCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllCatalogueItemsAndCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllCatalogueItemsAndCategoriesQuery({
 *   variables: {
 *      categoriesQuery: // value for 'categoriesQuery'
 *      itemsQuery: // value for 'itemsQuery'
 *   },
 * });
 */
export function useAllCatalogueItemsAndCategoriesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    AllCatalogueItemsAndCategoriesQuery,
    AllCatalogueItemsAndCategoriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AllCatalogueItemsAndCategoriesQuery, AllCatalogueItemsAndCategoriesQueryVariables>(
    AllCatalogueItemsAndCategoriesDocument,
    options
  );
}
export function useAllCatalogueItemsAndCategoriesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AllCatalogueItemsAndCategoriesQuery,
    AllCatalogueItemsAndCategoriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    AllCatalogueItemsAndCategoriesQuery,
    AllCatalogueItemsAndCategoriesQueryVariables
  >(AllCatalogueItemsAndCategoriesDocument, options);
}
export type AllCatalogueItemsAndCategoriesQueryHookResult = ReturnType<typeof useAllCatalogueItemsAndCategoriesQuery>;
export type AllCatalogueItemsAndCategoriesLazyQueryHookResult = ReturnType<
  typeof useAllCatalogueItemsAndCategoriesLazyQuery
>;
export type AllCatalogueItemsAndCategoriesQueryResult = Apollo.QueryResult<
  AllCatalogueItemsAndCategoriesQuery,
  AllCatalogueItemsAndCategoriesQueryVariables
>;
export const AllCataloguesDocument = gql`
  query allCatalogues($query: DefaultQueryInput) {
    allCatalogues(query: $query) {
      ...catalogueConnection
    }
  }
  ${CatalogueConnectionFragmentDoc}
`;

/**
 * __useAllCataloguesQuery__
 *
 * To run a query within a React component, call `useAllCataloguesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllCataloguesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllCataloguesQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllCataloguesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllCataloguesQuery, AllCataloguesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AllCataloguesQuery, AllCataloguesQueryVariables>(AllCataloguesDocument, options);
}
export function useAllCataloguesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllCataloguesQuery, AllCataloguesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AllCataloguesQuery, AllCataloguesQueryVariables>(AllCataloguesDocument, options);
}
export type AllCataloguesQueryHookResult = ReturnType<typeof useAllCataloguesQuery>;
export type AllCataloguesLazyQueryHookResult = ReturnType<typeof useAllCataloguesLazyQuery>;
export type AllCataloguesQueryResult = Apollo.QueryResult<AllCataloguesQuery, AllCataloguesQueryVariables>;
export const AllCateringsDocument = gql`
  query allCaterings($query: DefaultQueryInput) {
    allCaterings(query: $query) {
      ...cateringConnection
    }
  }
  ${CateringConnectionFragmentDoc}
`;

/**
 * __useAllCateringsQuery__
 *
 * To run a query within a React component, call `useAllCateringsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllCateringsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllCateringsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllCateringsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllCateringsQuery, AllCateringsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AllCateringsQuery, AllCateringsQueryVariables>(AllCateringsDocument, options);
}
export function useAllCateringsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllCateringsQuery, AllCateringsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AllCateringsQuery, AllCateringsQueryVariables>(AllCateringsDocument, options);
}
export type AllCateringsQueryHookResult = ReturnType<typeof useAllCateringsQuery>;
export type AllCateringsLazyQueryHookResult = ReturnType<typeof useAllCateringsLazyQuery>;
export type AllCateringsQueryResult = Apollo.QueryResult<AllCateringsQuery, AllCateringsQueryVariables>;
export const AllCommerceOrderDatasDocument = gql`
  query allCommerceOrderDatas($query: DefaultQueryInput) {
    allCommerceOrderDatas(query: $query) {
      ...commerceOrderDataConnection
    }
  }
  ${CommerceOrderDataConnectionFragmentDoc}
`;

/**
 * __useAllCommerceOrderDatasQuery__
 *
 * To run a query within a React component, call `useAllCommerceOrderDatasQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllCommerceOrderDatasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllCommerceOrderDatasQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllCommerceOrderDatasQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllCommerceOrderDatasQuery, AllCommerceOrderDatasQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AllCommerceOrderDatasQuery, AllCommerceOrderDatasQueryVariables>(
    AllCommerceOrderDatasDocument,
    options
  );
}
export function useAllCommerceOrderDatasLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllCommerceOrderDatasQuery, AllCommerceOrderDatasQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AllCommerceOrderDatasQuery, AllCommerceOrderDatasQueryVariables>(
    AllCommerceOrderDatasDocument,
    options
  );
}
export type AllCommerceOrderDatasQueryHookResult = ReturnType<typeof useAllCommerceOrderDatasQuery>;
export type AllCommerceOrderDatasLazyQueryHookResult = ReturnType<typeof useAllCommerceOrderDatasLazyQuery>;
export type AllCommerceOrderDatasQueryResult = Apollo.QueryResult<
  AllCommerceOrderDatasQuery,
  AllCommerceOrderDatasQueryVariables
>;
export const AllCommerceOrderFieldsDocument = gql`
  query allCommerceOrderFields($query: DefaultQueryInput) {
    allCommerceOrderFields(query: $query) {
      ...commerceOrderFieldConnection
    }
  }
  ${CommerceOrderFieldConnectionFragmentDoc}
`;

/**
 * __useAllCommerceOrderFieldsQuery__
 *
 * To run a query within a React component, call `useAllCommerceOrderFieldsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllCommerceOrderFieldsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllCommerceOrderFieldsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllCommerceOrderFieldsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllCommerceOrderFieldsQuery, AllCommerceOrderFieldsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AllCommerceOrderFieldsQuery, AllCommerceOrderFieldsQueryVariables>(
    AllCommerceOrderFieldsDocument,
    options
  );
}
export function useAllCommerceOrderFieldsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllCommerceOrderFieldsQuery, AllCommerceOrderFieldsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AllCommerceOrderFieldsQuery, AllCommerceOrderFieldsQueryVariables>(
    AllCommerceOrderFieldsDocument,
    options
  );
}
export type AllCommerceOrderFieldsQueryHookResult = ReturnType<typeof useAllCommerceOrderFieldsQuery>;
export type AllCommerceOrderFieldsLazyQueryHookResult = ReturnType<typeof useAllCommerceOrderFieldsLazyQuery>;
export type AllCommerceOrderFieldsQueryResult = Apollo.QueryResult<
  AllCommerceOrderFieldsQuery,
  AllCommerceOrderFieldsQueryVariables
>;
export const AllCommerceOrderItemsDocument = gql`
  query allCommerceOrderItems($query: DefaultQueryInput) {
    allCommerceOrderItems(query: $query) {
      ...commerceOrderItemConnection
    }
  }
  ${CommerceOrderItemConnectionFragmentDoc}
`;

/**
 * __useAllCommerceOrderItemsQuery__
 *
 * To run a query within a React component, call `useAllCommerceOrderItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllCommerceOrderItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllCommerceOrderItemsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllCommerceOrderItemsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllCommerceOrderItemsQuery, AllCommerceOrderItemsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AllCommerceOrderItemsQuery, AllCommerceOrderItemsQueryVariables>(
    AllCommerceOrderItemsDocument,
    options
  );
}
export function useAllCommerceOrderItemsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllCommerceOrderItemsQuery, AllCommerceOrderItemsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AllCommerceOrderItemsQuery, AllCommerceOrderItemsQueryVariables>(
    AllCommerceOrderItemsDocument,
    options
  );
}
export type AllCommerceOrderItemsQueryHookResult = ReturnType<typeof useAllCommerceOrderItemsQuery>;
export type AllCommerceOrderItemsLazyQueryHookResult = ReturnType<typeof useAllCommerceOrderItemsLazyQuery>;
export type AllCommerceOrderItemsQueryResult = Apollo.QueryResult<
  AllCommerceOrderItemsQuery,
  AllCommerceOrderItemsQueryVariables
>;
export const AllCommerceOrdersDocument = gql`
  query allCommerceOrders($query: DefaultQueryInput) {
    allCommerceOrders(query: $query) {
      ...commerceOrderConnection
    }
  }
  ${CommerceOrderConnectionFragmentDoc}
`;

/**
 * __useAllCommerceOrdersQuery__
 *
 * To run a query within a React component, call `useAllCommerceOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllCommerceOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllCommerceOrdersQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllCommerceOrdersQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllCommerceOrdersQuery, AllCommerceOrdersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AllCommerceOrdersQuery, AllCommerceOrdersQueryVariables>(
    AllCommerceOrdersDocument,
    options
  );
}
export function useAllCommerceOrdersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllCommerceOrdersQuery, AllCommerceOrdersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AllCommerceOrdersQuery, AllCommerceOrdersQueryVariables>(
    AllCommerceOrdersDocument,
    options
  );
}
export type AllCommerceOrdersQueryHookResult = ReturnType<typeof useAllCommerceOrdersQuery>;
export type AllCommerceOrdersLazyQueryHookResult = ReturnType<typeof useAllCommerceOrdersLazyQuery>;
export type AllCommerceOrdersQueryResult = Apollo.QueryResult<AllCommerceOrdersQuery, AllCommerceOrdersQueryVariables>;
export const AllCommerceShopsDocument = gql`
  query allCommerceShops($query: DefaultQueryInput) {
    allCommerceShops(query: $query) {
      ...commerceShopConnection
    }
  }
  ${CommerceShopConnectionFragmentDoc}
`;

/**
 * __useAllCommerceShopsQuery__
 *
 * To run a query within a React component, call `useAllCommerceShopsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllCommerceShopsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllCommerceShopsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllCommerceShopsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllCommerceShopsQuery, AllCommerceShopsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AllCommerceShopsQuery, AllCommerceShopsQueryVariables>(
    AllCommerceShopsDocument,
    options
  );
}
export function useAllCommerceShopsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllCommerceShopsQuery, AllCommerceShopsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AllCommerceShopsQuery, AllCommerceShopsQueryVariables>(
    AllCommerceShopsDocument,
    options
  );
}
export type AllCommerceShopsQueryHookResult = ReturnType<typeof useAllCommerceShopsQuery>;
export type AllCommerceShopsLazyQueryHookResult = ReturnType<typeof useAllCommerceShopsLazyQuery>;
export type AllCommerceShopsQueryResult = Apollo.QueryResult<AllCommerceShopsQuery, AllCommerceShopsQueryVariables>;
export const AllCommerceTemplatesDocument = gql`
  query allCommerceTemplates($query: DefaultQueryInput) {
    allCommerceTemplates(query: $query) {
      ...CommerceTemplateConnection
    }
  }
  ${CommerceTemplateConnectionFragmentDoc}
`;

/**
 * __useAllCommerceTemplatesQuery__
 *
 * To run a query within a React component, call `useAllCommerceTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllCommerceTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllCommerceTemplatesQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllCommerceTemplatesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllCommerceTemplatesQuery, AllCommerceTemplatesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AllCommerceTemplatesQuery, AllCommerceTemplatesQueryVariables>(
    AllCommerceTemplatesDocument,
    options
  );
}
export function useAllCommerceTemplatesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllCommerceTemplatesQuery, AllCommerceTemplatesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AllCommerceTemplatesQuery, AllCommerceTemplatesQueryVariables>(
    AllCommerceTemplatesDocument,
    options
  );
}
export type AllCommerceTemplatesQueryHookResult = ReturnType<typeof useAllCommerceTemplatesQuery>;
export type AllCommerceTemplatesLazyQueryHookResult = ReturnType<typeof useAllCommerceTemplatesLazyQuery>;
export type AllCommerceTemplatesQueryResult = Apollo.QueryResult<
  AllCommerceTemplatesQuery,
  AllCommerceTemplatesQueryVariables
>;
export const AllContentTranslationsDocument = gql`
  query allContentTranslations($query: DefaultQueryInput) {
    allContentTranslations(query: $query) {
      ...contentTranslationConnection
    }
  }
  ${ContentTranslationConnectionFragmentDoc}
`;

/**
 * __useAllContentTranslationsQuery__
 *
 * To run a query within a React component, call `useAllContentTranslationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllContentTranslationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllContentTranslationsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllContentTranslationsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllContentTranslationsQuery, AllContentTranslationsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AllContentTranslationsQuery, AllContentTranslationsQueryVariables>(
    AllContentTranslationsDocument,
    options
  );
}
export function useAllContentTranslationsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllContentTranslationsQuery, AllContentTranslationsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AllContentTranslationsQuery, AllContentTranslationsQueryVariables>(
    AllContentTranslationsDocument,
    options
  );
}
export type AllContentTranslationsQueryHookResult = ReturnType<typeof useAllContentTranslationsQuery>;
export type AllContentTranslationsLazyQueryHookResult = ReturnType<typeof useAllContentTranslationsLazyQuery>;
export type AllContentTranslationsQueryResult = Apollo.QueryResult<
  AllContentTranslationsQuery,
  AllContentTranslationsQueryVariables
>;
export const AllDisputeDisputeResponsesDocument = gql`
  query allDisputeDisputeResponses($query: DefaultQueryInput) {
    allDisputeDisputeResponses(query: $query) {
      ...disputeDisputeResponseConnection
    }
  }
  ${DisputeDisputeResponseConnectionFragmentDoc}
`;

/**
 * __useAllDisputeDisputeResponsesQuery__
 *
 * To run a query within a React component, call `useAllDisputeDisputeResponsesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllDisputeDisputeResponsesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllDisputeDisputeResponsesQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllDisputeDisputeResponsesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    AllDisputeDisputeResponsesQuery,
    AllDisputeDisputeResponsesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AllDisputeDisputeResponsesQuery, AllDisputeDisputeResponsesQueryVariables>(
    AllDisputeDisputeResponsesDocument,
    options
  );
}
export function useAllDisputeDisputeResponsesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AllDisputeDisputeResponsesQuery,
    AllDisputeDisputeResponsesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AllDisputeDisputeResponsesQuery, AllDisputeDisputeResponsesQueryVariables>(
    AllDisputeDisputeResponsesDocument,
    options
  );
}
export type AllDisputeDisputeResponsesQueryHookResult = ReturnType<typeof useAllDisputeDisputeResponsesQuery>;
export type AllDisputeDisputeResponsesLazyQueryHookResult = ReturnType<typeof useAllDisputeDisputeResponsesLazyQuery>;
export type AllDisputeDisputeResponsesQueryResult = Apollo.QueryResult<
  AllDisputeDisputeResponsesQuery,
  AllDisputeDisputeResponsesQueryVariables
>;
export const AllDisputesDocument = gql`
  query allDisputes($query: DefaultQueryInput) {
    allDisputes(query: $query) {
      ...disputeConnection
    }
  }
  ${DisputeConnectionFragmentDoc}
`;

/**
 * __useAllDisputesQuery__
 *
 * To run a query within a React component, call `useAllDisputesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllDisputesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllDisputesQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllDisputesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllDisputesQuery, AllDisputesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AllDisputesQuery, AllDisputesQueryVariables>(AllDisputesDocument, options);
}
export function useAllDisputesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllDisputesQuery, AllDisputesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AllDisputesQuery, AllDisputesQueryVariables>(AllDisputesDocument, options);
}
export type AllDisputesQueryHookResult = ReturnType<typeof useAllDisputesQuery>;
export type AllDisputesLazyQueryHookResult = ReturnType<typeof useAllDisputesLazyQuery>;
export type AllDisputesQueryResult = Apollo.QueryResult<AllDisputesQuery, AllDisputesQueryVariables>;
export const AllEventEventAccessesDocument = gql`
  query allEventEventAccesses($query: DefaultQueryInput) {
    allEventEventAccesses(query: $query) {
      ...eventEventAccessConnection
    }
  }
  ${EventEventAccessConnectionFragmentDoc}
`;

/**
 * __useAllEventEventAccessesQuery__
 *
 * To run a query within a React component, call `useAllEventEventAccessesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllEventEventAccessesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllEventEventAccessesQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllEventEventAccessesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllEventEventAccessesQuery, AllEventEventAccessesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AllEventEventAccessesQuery, AllEventEventAccessesQueryVariables>(
    AllEventEventAccessesDocument,
    options
  );
}
export function useAllEventEventAccessesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllEventEventAccessesQuery, AllEventEventAccessesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AllEventEventAccessesQuery, AllEventEventAccessesQueryVariables>(
    AllEventEventAccessesDocument,
    options
  );
}
export type AllEventEventAccessesQueryHookResult = ReturnType<typeof useAllEventEventAccessesQuery>;
export type AllEventEventAccessesLazyQueryHookResult = ReturnType<typeof useAllEventEventAccessesLazyQuery>;
export type AllEventEventAccessesQueryResult = Apollo.QueryResult<
  AllEventEventAccessesQuery,
  AllEventEventAccessesQueryVariables
>;
export const AllEventFieldsDocument = gql`
  query allEventFields($query: DefaultQueryInput) {
    allEventFields(query: $query) {
      ...eventFieldsConnection
    }
  }
  ${EventFieldsConnectionFragmentDoc}
`;

/**
 * __useAllEventFieldsQuery__
 *
 * To run a query within a React component, call `useAllEventFieldsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllEventFieldsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllEventFieldsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllEventFieldsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllEventFieldsQuery, AllEventFieldsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AllEventFieldsQuery, AllEventFieldsQueryVariables>(AllEventFieldsDocument, options);
}
export function useAllEventFieldsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllEventFieldsQuery, AllEventFieldsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AllEventFieldsQuery, AllEventFieldsQueryVariables>(
    AllEventFieldsDocument,
    options
  );
}
export type AllEventFieldsQueryHookResult = ReturnType<typeof useAllEventFieldsQuery>;
export type AllEventFieldsLazyQueryHookResult = ReturnType<typeof useAllEventFieldsLazyQuery>;
export type AllEventFieldsQueryResult = Apollo.QueryResult<AllEventFieldsQuery, AllEventFieldsQueryVariables>;
export const AllEventOrderTicketsDocument = gql`
  query allEventOrderTickets($query: DefaultQueryInput) {
    allEventOrderTickets(query: $query) {
      ...eventOrderTicketConnection
    }
  }
  ${EventOrderTicketConnectionFragmentDoc}
`;

/**
 * __useAllEventOrderTicketsQuery__
 *
 * To run a query within a React component, call `useAllEventOrderTicketsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllEventOrderTicketsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllEventOrderTicketsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllEventOrderTicketsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllEventOrderTicketsQuery, AllEventOrderTicketsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AllEventOrderTicketsQuery, AllEventOrderTicketsQueryVariables>(
    AllEventOrderTicketsDocument,
    options
  );
}
export function useAllEventOrderTicketsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllEventOrderTicketsQuery, AllEventOrderTicketsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AllEventOrderTicketsQuery, AllEventOrderTicketsQueryVariables>(
    AllEventOrderTicketsDocument,
    options
  );
}
export type AllEventOrderTicketsQueryHookResult = ReturnType<typeof useAllEventOrderTicketsQuery>;
export type AllEventOrderTicketsLazyQueryHookResult = ReturnType<typeof useAllEventOrderTicketsLazyQuery>;
export type AllEventOrderTicketsQueryResult = Apollo.QueryResult<
  AllEventOrderTicketsQuery,
  AllEventOrderTicketsQueryVariables
>;
export const AllEventOrdersDocument = gql`
  query allEventOrders($query: DefaultQueryInput) {
    allEventOrders(query: $query) {
      ...eventOrderConnection
    }
  }
  ${EventOrderConnectionFragmentDoc}
`;

/**
 * __useAllEventOrdersQuery__
 *
 * To run a query within a React component, call `useAllEventOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllEventOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllEventOrdersQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllEventOrdersQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllEventOrdersQuery, AllEventOrdersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AllEventOrdersQuery, AllEventOrdersQueryVariables>(AllEventOrdersDocument, options);
}
export function useAllEventOrdersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllEventOrdersQuery, AllEventOrdersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AllEventOrdersQuery, AllEventOrdersQueryVariables>(
    AllEventOrdersDocument,
    options
  );
}
export type AllEventOrdersQueryHookResult = ReturnType<typeof useAllEventOrdersQuery>;
export type AllEventOrdersLazyQueryHookResult = ReturnType<typeof useAllEventOrdersLazyQuery>;
export type AllEventOrdersQueryResult = Apollo.QueryResult<AllEventOrdersQuery, AllEventOrdersQueryVariables>;
export const AllEventPlacesDocument = gql`
  query allEventPlaces($query: DefaultQueryInput) {
    allEventPlaces(query: $query) {
      ...eventPlaceConnection
    }
  }
  ${EventPlaceConnectionFragmentDoc}
`;

/**
 * __useAllEventPlacesQuery__
 *
 * To run a query within a React component, call `useAllEventPlacesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllEventPlacesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllEventPlacesQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllEventPlacesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllEventPlacesQuery, AllEventPlacesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AllEventPlacesQuery, AllEventPlacesQueryVariables>(AllEventPlacesDocument, options);
}
export function useAllEventPlacesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllEventPlacesQuery, AllEventPlacesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AllEventPlacesQuery, AllEventPlacesQueryVariables>(
    AllEventPlacesDocument,
    options
  );
}
export type AllEventPlacesQueryHookResult = ReturnType<typeof useAllEventPlacesQuery>;
export type AllEventPlacesLazyQueryHookResult = ReturnType<typeof useAllEventPlacesLazyQuery>;
export type AllEventPlacesQueryResult = Apollo.QueryResult<AllEventPlacesQuery, AllEventPlacesQueryVariables>;
export const AllEventReservesDocument = gql`
  query allEventReserves($query: DefaultQueryInput) {
    allEventReserves(query: $query) {
      ...eventReserveConnection
    }
  }
  ${EventReserveConnectionFragmentDoc}
`;

/**
 * __useAllEventReservesQuery__
 *
 * To run a query within a React component, call `useAllEventReservesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllEventReservesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllEventReservesQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllEventReservesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllEventReservesQuery, AllEventReservesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AllEventReservesQuery, AllEventReservesQueryVariables>(
    AllEventReservesDocument,
    options
  );
}
export function useAllEventReservesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllEventReservesQuery, AllEventReservesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AllEventReservesQuery, AllEventReservesQueryVariables>(
    AllEventReservesDocument,
    options
  );
}
export type AllEventReservesQueryHookResult = ReturnType<typeof useAllEventReservesQuery>;
export type AllEventReservesLazyQueryHookResult = ReturnType<typeof useAllEventReservesLazyQuery>;
export type AllEventReservesQueryResult = Apollo.QueryResult<AllEventReservesQuery, AllEventReservesQueryVariables>;
export const AllEventTypesDocument = gql`
  query allEventTypes($query: DefaultQueryInput) {
    allEventTypes(query: $query) {
      ...eventTypeConnection
    }
  }
  ${EventTypeConnectionFragmentDoc}
`;

/**
 * __useAllEventTypesQuery__
 *
 * To run a query within a React component, call `useAllEventTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllEventTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllEventTypesQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllEventTypesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllEventTypesQuery, AllEventTypesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AllEventTypesQuery, AllEventTypesQueryVariables>(AllEventTypesDocument, options);
}
export function useAllEventTypesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllEventTypesQuery, AllEventTypesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AllEventTypesQuery, AllEventTypesQueryVariables>(AllEventTypesDocument, options);
}
export type AllEventTypesQueryHookResult = ReturnType<typeof useAllEventTypesQuery>;
export type AllEventTypesLazyQueryHookResult = ReturnType<typeof useAllEventTypesLazyQuery>;
export type AllEventTypesQueryResult = Apollo.QueryResult<AllEventTypesQuery, AllEventTypesQueryVariables>;
export const AllEventsDocument = gql`
  query allEvents($query: DefaultQueryInput) {
    allEvents(query: $query) {
      ...eventConnection
    }
  }
  ${EventConnectionFragmentDoc}
`;

/**
 * __useAllEventsQuery__
 *
 * To run a query within a React component, call `useAllEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllEventsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllEventsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllEventsQuery, AllEventsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AllEventsQuery, AllEventsQueryVariables>(AllEventsDocument, options);
}
export function useAllEventsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllEventsQuery, AllEventsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AllEventsQuery, AllEventsQueryVariables>(AllEventsDocument, options);
}
export type AllEventsQueryHookResult = ReturnType<typeof useAllEventsQuery>;
export type AllEventsLazyQueryHookResult = ReturnType<typeof useAllEventsLazyQuery>;
export type AllEventsQueryResult = Apollo.QueryResult<AllEventsQuery, AllEventsQueryVariables>;
export const AllEventsCalendarDocument = gql`
  query allEventsCalendar($query: DefaultQueryInput) {
    allEvents(query: $query) {
      total
      limit
      cursor
      sortable
      edges {
        ...eventCalendarFragment
      }
    }
  }
  ${EventCalendarFragmentFragmentDoc}
`;

/**
 * __useAllEventsCalendarQuery__
 *
 * To run a query within a React component, call `useAllEventsCalendarQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllEventsCalendarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllEventsCalendarQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllEventsCalendarQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllEventsCalendarQuery, AllEventsCalendarQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AllEventsCalendarQuery, AllEventsCalendarQueryVariables>(
    AllEventsCalendarDocument,
    options
  );
}
export function useAllEventsCalendarLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllEventsCalendarQuery, AllEventsCalendarQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AllEventsCalendarQuery, AllEventsCalendarQueryVariables>(
    AllEventsCalendarDocument,
    options
  );
}
export type AllEventsCalendarQueryHookResult = ReturnType<typeof useAllEventsCalendarQuery>;
export type AllEventsCalendarLazyQueryHookResult = ReturnType<typeof useAllEventsCalendarLazyQuery>;
export type AllEventsCalendarQueryResult = Apollo.QueryResult<AllEventsCalendarQuery, AllEventsCalendarQueryVariables>;
export const AllLoyaltyItemsDocument = gql`
  query allLoyaltyItems($query: DefaultQueryInput) {
    allLoyaltyItems(query: $query) {
      ...loyaltyItemConnection
    }
  }
  ${LoyaltyItemConnectionFragmentDoc}
`;

/**
 * __useAllLoyaltyItemsQuery__
 *
 * To run a query within a React component, call `useAllLoyaltyItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllLoyaltyItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllLoyaltyItemsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllLoyaltyItemsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllLoyaltyItemsQuery, AllLoyaltyItemsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AllLoyaltyItemsQuery, AllLoyaltyItemsQueryVariables>(
    AllLoyaltyItemsDocument,
    options
  );
}
export function useAllLoyaltyItemsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllLoyaltyItemsQuery, AllLoyaltyItemsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AllLoyaltyItemsQuery, AllLoyaltyItemsQueryVariables>(
    AllLoyaltyItemsDocument,
    options
  );
}
export type AllLoyaltyItemsQueryHookResult = ReturnType<typeof useAllLoyaltyItemsQuery>;
export type AllLoyaltyItemsLazyQueryHookResult = ReturnType<typeof useAllLoyaltyItemsLazyQuery>;
export type AllLoyaltyItemsQueryResult = Apollo.QueryResult<AllLoyaltyItemsQuery, AllLoyaltyItemsQueryVariables>;
export const AllLoyaltyPartnerEntitiesDocument = gql`
  query allLoyaltyPartnerEntities($query: DefaultQueryInput) {
    allLoyaltyPartnerEntities(query: $query) {
      ...loyaltyPartnerEntityConnection
    }
  }
  ${LoyaltyPartnerEntityConnectionFragmentDoc}
`;

/**
 * __useAllLoyaltyPartnerEntitiesQuery__
 *
 * To run a query within a React component, call `useAllLoyaltyPartnerEntitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllLoyaltyPartnerEntitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllLoyaltyPartnerEntitiesQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllLoyaltyPartnerEntitiesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    AllLoyaltyPartnerEntitiesQuery,
    AllLoyaltyPartnerEntitiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AllLoyaltyPartnerEntitiesQuery, AllLoyaltyPartnerEntitiesQueryVariables>(
    AllLoyaltyPartnerEntitiesDocument,
    options
  );
}
export function useAllLoyaltyPartnerEntitiesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AllLoyaltyPartnerEntitiesQuery,
    AllLoyaltyPartnerEntitiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AllLoyaltyPartnerEntitiesQuery, AllLoyaltyPartnerEntitiesQueryVariables>(
    AllLoyaltyPartnerEntitiesDocument,
    options
  );
}
export type AllLoyaltyPartnerEntitiesQueryHookResult = ReturnType<typeof useAllLoyaltyPartnerEntitiesQuery>;
export type AllLoyaltyPartnerEntitiesLazyQueryHookResult = ReturnType<typeof useAllLoyaltyPartnerEntitiesLazyQuery>;
export type AllLoyaltyPartnerEntitiesQueryResult = Apollo.QueryResult<
  AllLoyaltyPartnerEntitiesQuery,
  AllLoyaltyPartnerEntitiesQueryVariables
>;
export const AllLoyaltyPartnersDocument = gql`
  query allLoyaltyPartners($query: DefaultQueryInput) {
    allLoyaltyPartners(query: $query) {
      ...loyaltyPartnerConnection
    }
  }
  ${LoyaltyPartnerConnectionFragmentDoc}
`;

/**
 * __useAllLoyaltyPartnersQuery__
 *
 * To run a query within a React component, call `useAllLoyaltyPartnersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllLoyaltyPartnersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllLoyaltyPartnersQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllLoyaltyPartnersQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllLoyaltyPartnersQuery, AllLoyaltyPartnersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AllLoyaltyPartnersQuery, AllLoyaltyPartnersQueryVariables>(
    AllLoyaltyPartnersDocument,
    options
  );
}
export function useAllLoyaltyPartnersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllLoyaltyPartnersQuery, AllLoyaltyPartnersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AllLoyaltyPartnersQuery, AllLoyaltyPartnersQueryVariables>(
    AllLoyaltyPartnersDocument,
    options
  );
}
export type AllLoyaltyPartnersQueryHookResult = ReturnType<typeof useAllLoyaltyPartnersQuery>;
export type AllLoyaltyPartnersLazyQueryHookResult = ReturnType<typeof useAllLoyaltyPartnersLazyQuery>;
export type AllLoyaltyPartnersQueryResult = Apollo.QueryResult<
  AllLoyaltyPartnersQuery,
  AllLoyaltyPartnersQueryVariables
>;
export const AllLoyaltyPromoCodesDocument = gql`
  query allLoyaltyPromoCodes($query: DefaultQueryInput) {
    allLoyaltyPromoCodes(query: $query) {
      ...loyaltyPromoCodeConnection
    }
  }
  ${LoyaltyPromoCodeConnectionFragmentDoc}
`;

/**
 * __useAllLoyaltyPromoCodesQuery__
 *
 * To run a query within a React component, call `useAllLoyaltyPromoCodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllLoyaltyPromoCodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllLoyaltyPromoCodesQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllLoyaltyPromoCodesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllLoyaltyPromoCodesQuery, AllLoyaltyPromoCodesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AllLoyaltyPromoCodesQuery, AllLoyaltyPromoCodesQueryVariables>(
    AllLoyaltyPromoCodesDocument,
    options
  );
}
export function useAllLoyaltyPromoCodesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllLoyaltyPromoCodesQuery, AllLoyaltyPromoCodesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AllLoyaltyPromoCodesQuery, AllLoyaltyPromoCodesQueryVariables>(
    AllLoyaltyPromoCodesDocument,
    options
  );
}
export type AllLoyaltyPromoCodesQueryHookResult = ReturnType<typeof useAllLoyaltyPromoCodesQuery>;
export type AllLoyaltyPromoCodesLazyQueryHookResult = ReturnType<typeof useAllLoyaltyPromoCodesLazyQuery>;
export type AllLoyaltyPromoCodesQueryResult = Apollo.QueryResult<
  AllLoyaltyPromoCodesQuery,
  AllLoyaltyPromoCodesQueryVariables
>;
export const AllLoyaltyPromosDocument = gql`
  query allLoyaltyPromos($query: DefaultQueryInput) {
    allLoyaltyPromos(query: $query) {
      ...loyaltyPromoConnection
    }
  }
  ${LoyaltyPromoConnectionFragmentDoc}
`;

/**
 * __useAllLoyaltyPromosQuery__
 *
 * To run a query within a React component, call `useAllLoyaltyPromosQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllLoyaltyPromosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllLoyaltyPromosQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllLoyaltyPromosQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllLoyaltyPromosQuery, AllLoyaltyPromosQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AllLoyaltyPromosQuery, AllLoyaltyPromosQueryVariables>(
    AllLoyaltyPromosDocument,
    options
  );
}
export function useAllLoyaltyPromosLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllLoyaltyPromosQuery, AllLoyaltyPromosQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AllLoyaltyPromosQuery, AllLoyaltyPromosQueryVariables>(
    AllLoyaltyPromosDocument,
    options
  );
}
export type AllLoyaltyPromosQueryHookResult = ReturnType<typeof useAllLoyaltyPromosQuery>;
export type AllLoyaltyPromosLazyQueryHookResult = ReturnType<typeof useAllLoyaltyPromosLazyQuery>;
export type AllLoyaltyPromosQueryResult = Apollo.QueryResult<AllLoyaltyPromosQuery, AllLoyaltyPromosQueryVariables>;
export const AllMessengerMailingsDocument = gql`
  query allMessengerMailings($query: DefaultQueryInput) {
    allMessengerMailings(query: $query) {
      ...messengerMailingConnection
    }
  }
  ${MessengerMailingConnectionFragmentDoc}
`;

/**
 * __useAllMessengerMailingsQuery__
 *
 * To run a query within a React component, call `useAllMessengerMailingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllMessengerMailingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllMessengerMailingsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllMessengerMailingsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllMessengerMailingsQuery, AllMessengerMailingsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AllMessengerMailingsQuery, AllMessengerMailingsQueryVariables>(
    AllMessengerMailingsDocument,
    options
  );
}
export function useAllMessengerMailingsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllMessengerMailingsQuery, AllMessengerMailingsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AllMessengerMailingsQuery, AllMessengerMailingsQueryVariables>(
    AllMessengerMailingsDocument,
    options
  );
}
export type AllMessengerMailingsQueryHookResult = ReturnType<typeof useAllMessengerMailingsQuery>;
export type AllMessengerMailingsLazyQueryHookResult = ReturnType<typeof useAllMessengerMailingsLazyQuery>;
export type AllMessengerMailingsQueryResult = Apollo.QueryResult<
  AllMessengerMailingsQuery,
  AllMessengerMailingsQueryVariables
>;
export const AllMessengerQueueNotificationsDocument = gql`
  query allMessengerQueueNotifications($query: DefaultQueryInput) {
    allMessengerQueueNotifications(query: $query) {
      ...messengerQueueNotificationConnection
    }
  }
  ${MessengerQueueNotificationConnectionFragmentDoc}
`;

/**
 * __useAllMessengerQueueNotificationsQuery__
 *
 * To run a query within a React component, call `useAllMessengerQueueNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllMessengerQueueNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllMessengerQueueNotificationsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllMessengerQueueNotificationsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    AllMessengerQueueNotificationsQuery,
    AllMessengerQueueNotificationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AllMessengerQueueNotificationsQuery, AllMessengerQueueNotificationsQueryVariables>(
    AllMessengerQueueNotificationsDocument,
    options
  );
}
export function useAllMessengerQueueNotificationsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AllMessengerQueueNotificationsQuery,
    AllMessengerQueueNotificationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    AllMessengerQueueNotificationsQuery,
    AllMessengerQueueNotificationsQueryVariables
  >(AllMessengerQueueNotificationsDocument, options);
}
export type AllMessengerQueueNotificationsQueryHookResult = ReturnType<typeof useAllMessengerQueueNotificationsQuery>;
export type AllMessengerQueueNotificationsLazyQueryHookResult = ReturnType<
  typeof useAllMessengerQueueNotificationsLazyQuery
>;
export type AllMessengerQueueNotificationsQueryResult = Apollo.QueryResult<
  AllMessengerQueueNotificationsQuery,
  AllMessengerQueueNotificationsQueryVariables
>;
export const AllMessengerQueueTasksDocument = gql`
  query allMessengerQueueTasks($query: DefaultQueryInput) {
    allMessengerQueueTasks(query: $query) {
      ...messengerQueueTaskConnection
    }
  }
  ${MessengerQueueTaskConnectionFragmentDoc}
`;

/**
 * __useAllMessengerQueueTasksQuery__
 *
 * To run a query within a React component, call `useAllMessengerQueueTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllMessengerQueueTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllMessengerQueueTasksQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllMessengerQueueTasksQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllMessengerQueueTasksQuery, AllMessengerQueueTasksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AllMessengerQueueTasksQuery, AllMessengerQueueTasksQueryVariables>(
    AllMessengerQueueTasksDocument,
    options
  );
}
export function useAllMessengerQueueTasksLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllMessengerQueueTasksQuery, AllMessengerQueueTasksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AllMessengerQueueTasksQuery, AllMessengerQueueTasksQueryVariables>(
    AllMessengerQueueTasksDocument,
    options
  );
}
export type AllMessengerQueueTasksQueryHookResult = ReturnType<typeof useAllMessengerQueueTasksQuery>;
export type AllMessengerQueueTasksLazyQueryHookResult = ReturnType<typeof useAllMessengerQueueTasksLazyQuery>;
export type AllMessengerQueueTasksQueryResult = Apollo.QueryResult<
  AllMessengerQueueTasksQuery,
  AllMessengerQueueTasksQueryVariables
>;
export const AllMessengerTransportsDocument = gql`
  query allMessengerTransports($query: DefaultQueryInput) {
    allMessengerTransports(query: $query) {
      ...messengerTransportConnection
    }
  }
  ${MessengerTransportConnectionFragmentDoc}
`;

/**
 * __useAllMessengerTransportsQuery__
 *
 * To run a query within a React component, call `useAllMessengerTransportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllMessengerTransportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllMessengerTransportsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllMessengerTransportsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllMessengerTransportsQuery, AllMessengerTransportsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AllMessengerTransportsQuery, AllMessengerTransportsQueryVariables>(
    AllMessengerTransportsDocument,
    options
  );
}
export function useAllMessengerTransportsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllMessengerTransportsQuery, AllMessengerTransportsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AllMessengerTransportsQuery, AllMessengerTransportsQueryVariables>(
    AllMessengerTransportsDocument,
    options
  );
}
export type AllMessengerTransportsQueryHookResult = ReturnType<typeof useAllMessengerTransportsQuery>;
export type AllMessengerTransportsLazyQueryHookResult = ReturnType<typeof useAllMessengerTransportsLazyQuery>;
export type AllMessengerTransportsQueryResult = Apollo.QueryResult<
  AllMessengerTransportsQuery,
  AllMessengerTransportsQueryVariables
>;
export const AllMobileTranslationsDocument = gql`
  query allMobileTranslations($locale: String!) {
    allMobileTranslations(locale: $locale) {
      ...mobileTranslation
    }
  }
  ${MobileTranslationFragmentDoc}
`;

/**
 * __useAllMobileTranslationsQuery__
 *
 * To run a query within a React component, call `useAllMobileTranslationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllMobileTranslationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllMobileTranslationsQuery({
 *   variables: {
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useAllMobileTranslationsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<AllMobileTranslationsQuery, AllMobileTranslationsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AllMobileTranslationsQuery, AllMobileTranslationsQueryVariables>(
    AllMobileTranslationsDocument,
    options
  );
}
export function useAllMobileTranslationsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllMobileTranslationsQuery, AllMobileTranslationsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AllMobileTranslationsQuery, AllMobileTranslationsQueryVariables>(
    AllMobileTranslationsDocument,
    options
  );
}
export type AllMobileTranslationsQueryHookResult = ReturnType<typeof useAllMobileTranslationsQuery>;
export type AllMobileTranslationsLazyQueryHookResult = ReturnType<typeof useAllMobileTranslationsLazyQuery>;
export type AllMobileTranslationsQueryResult = Apollo.QueryResult<
  AllMobileTranslationsQuery,
  AllMobileTranslationsQueryVariables
>;
export const AllMyEventOrdersDocument = gql`
  query allMyEventOrders($query: DefaultQueryInput) {
    allMyEventOrders(query: $query) {
      ...eventOrderConnection
    }
  }
  ${EventOrderConnectionFragmentDoc}
`;

/**
 * __useAllMyEventOrdersQuery__
 *
 * To run a query within a React component, call `useAllMyEventOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllMyEventOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllMyEventOrdersQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllMyEventOrdersQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllMyEventOrdersQuery, AllMyEventOrdersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AllMyEventOrdersQuery, AllMyEventOrdersQueryVariables>(
    AllMyEventOrdersDocument,
    options
  );
}
export function useAllMyEventOrdersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllMyEventOrdersQuery, AllMyEventOrdersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AllMyEventOrdersQuery, AllMyEventOrdersQueryVariables>(
    AllMyEventOrdersDocument,
    options
  );
}
export type AllMyEventOrdersQueryHookResult = ReturnType<typeof useAllMyEventOrdersQuery>;
export type AllMyEventOrdersLazyQueryHookResult = ReturnType<typeof useAllMyEventOrdersLazyQuery>;
export type AllMyEventOrdersQueryResult = Apollo.QueryResult<AllMyEventOrdersQuery, AllMyEventOrdersQueryVariables>;
export const AllMyEventOrdersLightDocument = gql`
  query allMyEventOrdersLight($query: DefaultQueryInput) {
    allMyEventOrders(query: $query) {
      ...eventOrderConnectionMin
    }
  }
  ${EventOrderConnectionMinFragmentDoc}
`;

/**
 * __useAllMyEventOrdersLightQuery__
 *
 * To run a query within a React component, call `useAllMyEventOrdersLightQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllMyEventOrdersLightQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllMyEventOrdersLightQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllMyEventOrdersLightQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllMyEventOrdersLightQuery, AllMyEventOrdersLightQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AllMyEventOrdersLightQuery, AllMyEventOrdersLightQueryVariables>(
    AllMyEventOrdersLightDocument,
    options
  );
}
export function useAllMyEventOrdersLightLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllMyEventOrdersLightQuery, AllMyEventOrdersLightQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AllMyEventOrdersLightQuery, AllMyEventOrdersLightQueryVariables>(
    AllMyEventOrdersLightDocument,
    options
  );
}
export type AllMyEventOrdersLightQueryHookResult = ReturnType<typeof useAllMyEventOrdersLightQuery>;
export type AllMyEventOrdersLightLazyQueryHookResult = ReturnType<typeof useAllMyEventOrdersLightLazyQuery>;
export type AllMyEventOrdersLightQueryResult = Apollo.QueryResult<
  AllMyEventOrdersLightQuery,
  AllMyEventOrdersLightQueryVariables
>;
export const AllMyLoyaltyOrdersDocument = gql`
  query allMyLoyaltyOrders($query: DefaultQueryInput) {
    allMyLoyaltyOrders(query: $query) {
      ...loyaltyOrderConnection
    }
  }
  ${LoyaltyOrderConnectionFragmentDoc}
`;

/**
 * __useAllMyLoyaltyOrdersQuery__
 *
 * To run a query within a React component, call `useAllMyLoyaltyOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllMyLoyaltyOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllMyLoyaltyOrdersQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllMyLoyaltyOrdersQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllMyLoyaltyOrdersQuery, AllMyLoyaltyOrdersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AllMyLoyaltyOrdersQuery, AllMyLoyaltyOrdersQueryVariables>(
    AllMyLoyaltyOrdersDocument,
    options
  );
}
export function useAllMyLoyaltyOrdersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllMyLoyaltyOrdersQuery, AllMyLoyaltyOrdersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AllMyLoyaltyOrdersQuery, AllMyLoyaltyOrdersQueryVariables>(
    AllMyLoyaltyOrdersDocument,
    options
  );
}
export type AllMyLoyaltyOrdersQueryHookResult = ReturnType<typeof useAllMyLoyaltyOrdersQuery>;
export type AllMyLoyaltyOrdersLazyQueryHookResult = ReturnType<typeof useAllMyLoyaltyOrdersLazyQuery>;
export type AllMyLoyaltyOrdersQueryResult = Apollo.QueryResult<
  AllMyLoyaltyOrdersQuery,
  AllMyLoyaltyOrdersQueryVariables
>;
export const AllMySubscriptionsDocument = gql`
  query allMySubscriptions($query: DefaultQueryInput) {
    allMySubscriptions(query: $query) {
      ...userSubscriptionConnection
    }
  }
  ${UserSubscriptionConnectionFragmentDoc}
`;

/**
 * __useAllMySubscriptionsQuery__
 *
 * To run a query within a React component, call `useAllMySubscriptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllMySubscriptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllMySubscriptionsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllMySubscriptionsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllMySubscriptionsQuery, AllMySubscriptionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AllMySubscriptionsQuery, AllMySubscriptionsQueryVariables>(
    AllMySubscriptionsDocument,
    options
  );
}
export function useAllMySubscriptionsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllMySubscriptionsQuery, AllMySubscriptionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AllMySubscriptionsQuery, AllMySubscriptionsQueryVariables>(
    AllMySubscriptionsDocument,
    options
  );
}
export type AllMySubscriptionsQueryHookResult = ReturnType<typeof useAllMySubscriptionsQuery>;
export type AllMySubscriptionsLazyQueryHookResult = ReturnType<typeof useAllMySubscriptionsLazyQuery>;
export type AllMySubscriptionsQueryResult = Apollo.QueryResult<
  AllMySubscriptionsQuery,
  AllMySubscriptionsQueryVariables
>;
export const AllPaymentAccountsDocument = gql`
  query allPaymentAccounts($query: DefaultQueryInput) {
    allPaymentAccounts(query: $query) {
      ...paymentAccountConnection
    }
  }
  ${PaymentAccountConnectionFragmentDoc}
`;

/**
 * __useAllPaymentAccountsQuery__
 *
 * To run a query within a React component, call `useAllPaymentAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllPaymentAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllPaymentAccountsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllPaymentAccountsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllPaymentAccountsQuery, AllPaymentAccountsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AllPaymentAccountsQuery, AllPaymentAccountsQueryVariables>(
    AllPaymentAccountsDocument,
    options
  );
}
export function useAllPaymentAccountsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllPaymentAccountsQuery, AllPaymentAccountsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AllPaymentAccountsQuery, AllPaymentAccountsQueryVariables>(
    AllPaymentAccountsDocument,
    options
  );
}
export type AllPaymentAccountsQueryHookResult = ReturnType<typeof useAllPaymentAccountsQuery>;
export type AllPaymentAccountsLazyQueryHookResult = ReturnType<typeof useAllPaymentAccountsLazyQuery>;
export type AllPaymentAccountsQueryResult = Apollo.QueryResult<
  AllPaymentAccountsQuery,
  AllPaymentAccountsQueryVariables
>;
export const AllPaymentCurrenciesDocument = gql`
  query allPaymentCurrencies($query: DefaultQueryInput) {
    allPaymentCurrencies(query: $query) {
      ...paymentCurrencyConnection
    }
  }
  ${PaymentCurrencyConnectionFragmentDoc}
`;

/**
 * __useAllPaymentCurrenciesQuery__
 *
 * To run a query within a React component, call `useAllPaymentCurrenciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllPaymentCurrenciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllPaymentCurrenciesQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllPaymentCurrenciesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllPaymentCurrenciesQuery, AllPaymentCurrenciesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AllPaymentCurrenciesQuery, AllPaymentCurrenciesQueryVariables>(
    AllPaymentCurrenciesDocument,
    options
  );
}
export function useAllPaymentCurrenciesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllPaymentCurrenciesQuery, AllPaymentCurrenciesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AllPaymentCurrenciesQuery, AllPaymentCurrenciesQueryVariables>(
    AllPaymentCurrenciesDocument,
    options
  );
}
export type AllPaymentCurrenciesQueryHookResult = ReturnType<typeof useAllPaymentCurrenciesQuery>;
export type AllPaymentCurrenciesLazyQueryHookResult = ReturnType<typeof useAllPaymentCurrenciesLazyQuery>;
export type AllPaymentCurrenciesQueryResult = Apollo.QueryResult<
  AllPaymentCurrenciesQuery,
  AllPaymentCurrenciesQueryVariables
>;
export const AllPaymentProvidersDocument = gql`
  query allPaymentProviders($query: DefaultQueryInput) {
    allPaymentProviders(query: $query) {
      ...paymentProviderConnection
    }
  }
  ${PaymentProviderConnectionFragmentDoc}
`;

/**
 * __useAllPaymentProvidersQuery__
 *
 * To run a query within a React component, call `useAllPaymentProvidersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllPaymentProvidersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllPaymentProvidersQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllPaymentProvidersQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllPaymentProvidersQuery, AllPaymentProvidersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AllPaymentProvidersQuery, AllPaymentProvidersQueryVariables>(
    AllPaymentProvidersDocument,
    options
  );
}
export function useAllPaymentProvidersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllPaymentProvidersQuery, AllPaymentProvidersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AllPaymentProvidersQuery, AllPaymentProvidersQueryVariables>(
    AllPaymentProvidersDocument,
    options
  );
}
export type AllPaymentProvidersQueryHookResult = ReturnType<typeof useAllPaymentProvidersQuery>;
export type AllPaymentProvidersLazyQueryHookResult = ReturnType<typeof useAllPaymentProvidersLazyQuery>;
export type AllPaymentProvidersQueryResult = Apollo.QueryResult<
  AllPaymentProvidersQuery,
  AllPaymentProvidersQueryVariables
>;
export const AllPaymentTransactionsDocument = gql`
  query allPaymentTransactions($query: DefaultQueryInput) {
    allPaymentTransactions(query: $query) {
      ...paymentTransactionConnection
    }
  }
  ${PaymentTransactionConnectionFragmentDoc}
`;

/**
 * __useAllPaymentTransactionsQuery__
 *
 * To run a query within a React component, call `useAllPaymentTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllPaymentTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllPaymentTransactionsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllPaymentTransactionsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllPaymentTransactionsQuery, AllPaymentTransactionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AllPaymentTransactionsQuery, AllPaymentTransactionsQueryVariables>(
    AllPaymentTransactionsDocument,
    options
  );
}
export function useAllPaymentTransactionsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllPaymentTransactionsQuery, AllPaymentTransactionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AllPaymentTransactionsQuery, AllPaymentTransactionsQueryVariables>(
    AllPaymentTransactionsDocument,
    options
  );
}
export type AllPaymentTransactionsQueryHookResult = ReturnType<typeof useAllPaymentTransactionsQuery>;
export type AllPaymentTransactionsLazyQueryHookResult = ReturnType<typeof useAllPaymentTransactionsLazyQuery>;
export type AllPaymentTransactionsQueryResult = Apollo.QueryResult<
  AllPaymentTransactionsQuery,
  AllPaymentTransactionsQueryVariables
>;
export const AllSystemAsyncTasksDocument = gql`
  query allSystemAsyncTasks($query: DefaultQueryInput) {
    allSystemAsyncTasks(query: $query) {
      ...systemAsyncTaskConnection
    }
  }
  ${SystemAsyncTaskConnectionFragmentDoc}
`;

/**
 * __useAllSystemAsyncTasksQuery__
 *
 * To run a query within a React component, call `useAllSystemAsyncTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllSystemAsyncTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllSystemAsyncTasksQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllSystemAsyncTasksQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllSystemAsyncTasksQuery, AllSystemAsyncTasksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AllSystemAsyncTasksQuery, AllSystemAsyncTasksQueryVariables>(
    AllSystemAsyncTasksDocument,
    options
  );
}
export function useAllSystemAsyncTasksLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllSystemAsyncTasksQuery, AllSystemAsyncTasksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AllSystemAsyncTasksQuery, AllSystemAsyncTasksQueryVariables>(
    AllSystemAsyncTasksDocument,
    options
  );
}
export type AllSystemAsyncTasksQueryHookResult = ReturnType<typeof useAllSystemAsyncTasksQuery>;
export type AllSystemAsyncTasksLazyQueryHookResult = ReturnType<typeof useAllSystemAsyncTasksLazyQuery>;
export type AllSystemAsyncTasksQueryResult = Apollo.QueryResult<
  AllSystemAsyncTasksQuery,
  AllSystemAsyncTasksQueryVariables
>;
export const AllSystemLocalesDocument = gql`
  query allSystemLocales($query: DefaultQueryInput) {
    allSystemLocales(query: $query) {
      ...systemLocaleConnection
    }
  }
  ${SystemLocaleConnectionFragmentDoc}
`;

/**
 * __useAllSystemLocalesQuery__
 *
 * To run a query within a React component, call `useAllSystemLocalesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllSystemLocalesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllSystemLocalesQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllSystemLocalesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllSystemLocalesQuery, AllSystemLocalesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AllSystemLocalesQuery, AllSystemLocalesQueryVariables>(
    AllSystemLocalesDocument,
    options
  );
}
export function useAllSystemLocalesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllSystemLocalesQuery, AllSystemLocalesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AllSystemLocalesQuery, AllSystemLocalesQueryVariables>(
    AllSystemLocalesDocument,
    options
  );
}
export type AllSystemLocalesQueryHookResult = ReturnType<typeof useAllSystemLocalesQuery>;
export type AllSystemLocalesLazyQueryHookResult = ReturnType<typeof useAllSystemLocalesLazyQuery>;
export type AllSystemLocalesQueryResult = Apollo.QueryResult<AllSystemLocalesQuery, AllSystemLocalesQueryVariables>;
export const AllSystemPrintersDocument = gql`
  query allSystemPrinters($query: DefaultQueryInput) {
    allSystemPrinters(query: $query) {
      ...systemPrinterConnection
    }
  }
  ${SystemPrinterConnectionFragmentDoc}
`;

/**
 * __useAllSystemPrintersQuery__
 *
 * To run a query within a React component, call `useAllSystemPrintersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllSystemPrintersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllSystemPrintersQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllSystemPrintersQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllSystemPrintersQuery, AllSystemPrintersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AllSystemPrintersQuery, AllSystemPrintersQueryVariables>(
    AllSystemPrintersDocument,
    options
  );
}
export function useAllSystemPrintersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllSystemPrintersQuery, AllSystemPrintersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AllSystemPrintersQuery, AllSystemPrintersQueryVariables>(
    AllSystemPrintersDocument,
    options
  );
}
export type AllSystemPrintersQueryHookResult = ReturnType<typeof useAllSystemPrintersQuery>;
export type AllSystemPrintersLazyQueryHookResult = ReturnType<typeof useAllSystemPrintersLazyQuery>;
export type AllSystemPrintersQueryResult = Apollo.QueryResult<AllSystemPrintersQuery, AllSystemPrintersQueryVariables>;
export const AllSystemTranslationsDocument = gql`
  query allSystemTranslations($query: DefaultQueryInput) {
    allSystemTranslations(query: $query) {
      ...systemTranslationConnection
    }
  }
  ${SystemTranslationConnectionFragmentDoc}
`;

/**
 * __useAllSystemTranslationsQuery__
 *
 * To run a query within a React component, call `useAllSystemTranslationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllSystemTranslationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllSystemTranslationsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllSystemTranslationsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllSystemTranslationsQuery, AllSystemTranslationsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AllSystemTranslationsQuery, AllSystemTranslationsQueryVariables>(
    AllSystemTranslationsDocument,
    options
  );
}
export function useAllSystemTranslationsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllSystemTranslationsQuery, AllSystemTranslationsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AllSystemTranslationsQuery, AllSystemTranslationsQueryVariables>(
    AllSystemTranslationsDocument,
    options
  );
}
export type AllSystemTranslationsQueryHookResult = ReturnType<typeof useAllSystemTranslationsQuery>;
export type AllSystemTranslationsLazyQueryHookResult = ReturnType<typeof useAllSystemTranslationsLazyQuery>;
export type AllSystemTranslationsQueryResult = Apollo.QueryResult<
  AllSystemTranslationsQuery,
  AllSystemTranslationsQueryVariables
>;
export const AllSystemUsersDocument = gql`
  query allSystemUsers(
    $include: SystemUsersWhereInput
    $sort: [DefaultSortInput!]
    $search: String
    $cursor: Int
    $limit: Int
  ) {
    allSystemUsers(include: $include, sort: $sort, search: $search, cursor: $cursor, limit: $limit) {
      ...systemUsersConnection
    }
  }
  ${SystemUsersConnectionFragmentDoc}
`;

/**
 * __useAllSystemUsersQuery__
 *
 * To run a query within a React component, call `useAllSystemUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllSystemUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllSystemUsersQuery({
 *   variables: {
 *      include: // value for 'include'
 *      sort: // value for 'sort'
 *      search: // value for 'search'
 *      cursor: // value for 'cursor'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useAllSystemUsersQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllSystemUsersQuery, AllSystemUsersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AllSystemUsersQuery, AllSystemUsersQueryVariables>(AllSystemUsersDocument, options);
}
export function useAllSystemUsersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllSystemUsersQuery, AllSystemUsersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AllSystemUsersQuery, AllSystemUsersQueryVariables>(
    AllSystemUsersDocument,
    options
  );
}
export type AllSystemUsersQueryHookResult = ReturnType<typeof useAllSystemUsersQuery>;
export type AllSystemUsersLazyQueryHookResult = ReturnType<typeof useAllSystemUsersLazyQuery>;
export type AllSystemUsersQueryResult = Apollo.QueryResult<AllSystemUsersQuery, AllSystemUsersQueryVariables>;
export const AllSystemWebHooksDocument = gql`
  query allSystemWebHooks($query: DefaultQueryInput) {
    allSystemWebHooks(query: $query) {
      ...systemWebHookConnection
    }
  }
  ${SystemWebHookConnectionFragmentDoc}
`;

/**
 * __useAllSystemWebHooksQuery__
 *
 * To run a query within a React component, call `useAllSystemWebHooksQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllSystemWebHooksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllSystemWebHooksQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllSystemWebHooksQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllSystemWebHooksQuery, AllSystemWebHooksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AllSystemWebHooksQuery, AllSystemWebHooksQueryVariables>(
    AllSystemWebHooksDocument,
    options
  );
}
export function useAllSystemWebHooksLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllSystemWebHooksQuery, AllSystemWebHooksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AllSystemWebHooksQuery, AllSystemWebHooksQueryVariables>(
    AllSystemWebHooksDocument,
    options
  );
}
export type AllSystemWebHooksQueryHookResult = ReturnType<typeof useAllSystemWebHooksQuery>;
export type AllSystemWebHooksLazyQueryHookResult = ReturnType<typeof useAllSystemWebHooksLazyQuery>;
export type AllSystemWebHooksQueryResult = Apollo.QueryResult<AllSystemWebHooksQuery, AllSystemWebHooksQueryVariables>;
export const AllTemplateApplePassesDocument = gql`
  query allTemplateApplePasses($query: DefaultQueryInput) {
    allTemplateApplePasses(query: $query) {
      ...templateApplePassConnection
    }
  }
  ${TemplateApplePassConnectionFragmentDoc}
`;

/**
 * __useAllTemplateApplePassesQuery__
 *
 * To run a query within a React component, call `useAllTemplateApplePassesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllTemplateApplePassesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllTemplateApplePassesQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllTemplateApplePassesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllTemplateApplePassesQuery, AllTemplateApplePassesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AllTemplateApplePassesQuery, AllTemplateApplePassesQueryVariables>(
    AllTemplateApplePassesDocument,
    options
  );
}
export function useAllTemplateApplePassesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllTemplateApplePassesQuery, AllTemplateApplePassesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AllTemplateApplePassesQuery, AllTemplateApplePassesQueryVariables>(
    AllTemplateApplePassesDocument,
    options
  );
}
export type AllTemplateApplePassesQueryHookResult = ReturnType<typeof useAllTemplateApplePassesQuery>;
export type AllTemplateApplePassesLazyQueryHookResult = ReturnType<typeof useAllTemplateApplePassesLazyQuery>;
export type AllTemplateApplePassesQueryResult = Apollo.QueryResult<
  AllTemplateApplePassesQuery,
  AllTemplateApplePassesQueryVariables
>;
export const AllTemplateNotificationsDocument = gql`
  query allTemplateNotifications($query: DefaultQueryInput) {
    allTemplateNotifications(query: $query) {
      ...templateNotificationConnection
    }
  }
  ${TemplateNotificationConnectionFragmentDoc}
`;

/**
 * __useAllTemplateNotificationsQuery__
 *
 * To run a query within a React component, call `useAllTemplateNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllTemplateNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllTemplateNotificationsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllTemplateNotificationsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllTemplateNotificationsQuery, AllTemplateNotificationsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AllTemplateNotificationsQuery, AllTemplateNotificationsQueryVariables>(
    AllTemplateNotificationsDocument,
    options
  );
}
export function useAllTemplateNotificationsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AllTemplateNotificationsQuery,
    AllTemplateNotificationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AllTemplateNotificationsQuery, AllTemplateNotificationsQueryVariables>(
    AllTemplateNotificationsDocument,
    options
  );
}
export type AllTemplateNotificationsQueryHookResult = ReturnType<typeof useAllTemplateNotificationsQuery>;
export type AllTemplateNotificationsLazyQueryHookResult = ReturnType<typeof useAllTemplateNotificationsLazyQuery>;
export type AllTemplateNotificationsQueryResult = Apollo.QueryResult<
  AllTemplateNotificationsQuery,
  AllTemplateNotificationsQueryVariables
>;
export const AllTemplatePdFsDocument = gql`
  query allTemplatePDFs($query: DefaultQueryInput) {
    allTemplatePDFs(query: $query) {
      ...templatePDFConnection
    }
  }
  ${TemplatePdfConnectionFragmentDoc}
`;

/**
 * __useAllTemplatePdFsQuery__
 *
 * To run a query within a React component, call `useAllTemplatePdFsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllTemplatePdFsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllTemplatePdFsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllTemplatePdFsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllTemplatePdFsQuery, AllTemplatePdFsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AllTemplatePdFsQuery, AllTemplatePdFsQueryVariables>(
    AllTemplatePdFsDocument,
    options
  );
}
export function useAllTemplatePdFsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllTemplatePdFsQuery, AllTemplatePdFsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AllTemplatePdFsQuery, AllTemplatePdFsQueryVariables>(
    AllTemplatePdFsDocument,
    options
  );
}
export type AllTemplatePdFsQueryHookResult = ReturnType<typeof useAllTemplatePdFsQuery>;
export type AllTemplatePdFsLazyQueryHookResult = ReturnType<typeof useAllTemplatePdFsLazyQuery>;
export type AllTemplatePdFsQueryResult = Apollo.QueryResult<AllTemplatePdFsQuery, AllTemplatePdFsQueryVariables>;
export const AllTournamentDisqualificationsDocument = gql`
  query allTournamentDisqualifications($query: DefaultQueryInput) {
    allTournamentDisqualifications(query: $query) {
      ...tournamentDisqualificationConnection
    }
  }
  ${TournamentDisqualificationConnectionFragmentDoc}
`;

/**
 * __useAllTournamentDisqualificationsQuery__
 *
 * To run a query within a React component, call `useAllTournamentDisqualificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllTournamentDisqualificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllTournamentDisqualificationsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllTournamentDisqualificationsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    AllTournamentDisqualificationsQuery,
    AllTournamentDisqualificationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AllTournamentDisqualificationsQuery, AllTournamentDisqualificationsQueryVariables>(
    AllTournamentDisqualificationsDocument,
    options
  );
}
export function useAllTournamentDisqualificationsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AllTournamentDisqualificationsQuery,
    AllTournamentDisqualificationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    AllTournamentDisqualificationsQuery,
    AllTournamentDisqualificationsQueryVariables
  >(AllTournamentDisqualificationsDocument, options);
}
export type AllTournamentDisqualificationsQueryHookResult = ReturnType<typeof useAllTournamentDisqualificationsQuery>;
export type AllTournamentDisqualificationsLazyQueryHookResult = ReturnType<
  typeof useAllTournamentDisqualificationsLazyQuery
>;
export type AllTournamentDisqualificationsQueryResult = Apollo.QueryResult<
  AllTournamentDisqualificationsQuery,
  AllTournamentDisqualificationsQueryVariables
>;
export const AllTournamentMatchEventsDocument = gql`
  query allTournamentMatchEvents($query: DefaultQueryInput) {
    allTournamentMatchEvents(query: $query) {
      ...tournamentMatchEventConnection
    }
  }
  ${TournamentMatchEventConnectionFragmentDoc}
`;

/**
 * __useAllTournamentMatchEventsQuery__
 *
 * To run a query within a React component, call `useAllTournamentMatchEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllTournamentMatchEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllTournamentMatchEventsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllTournamentMatchEventsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllTournamentMatchEventsQuery, AllTournamentMatchEventsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AllTournamentMatchEventsQuery, AllTournamentMatchEventsQueryVariables>(
    AllTournamentMatchEventsDocument,
    options
  );
}
export function useAllTournamentMatchEventsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AllTournamentMatchEventsQuery,
    AllTournamentMatchEventsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AllTournamentMatchEventsQuery, AllTournamentMatchEventsQueryVariables>(
    AllTournamentMatchEventsDocument,
    options
  );
}
export type AllTournamentMatchEventsQueryHookResult = ReturnType<typeof useAllTournamentMatchEventsQuery>;
export type AllTournamentMatchEventsLazyQueryHookResult = ReturnType<typeof useAllTournamentMatchEventsLazyQuery>;
export type AllTournamentMatchEventsQueryResult = Apollo.QueryResult<
  AllTournamentMatchEventsQuery,
  AllTournamentMatchEventsQueryVariables
>;
export const AllTournamentMatchProtocolsDocument = gql`
  query allTournamentMatchProtocols($query: DefaultQueryInput) {
    allTournamentMatchProtocols(query: $query) {
      ...tournamentMatchProtocolConnection
    }
  }
  ${TournamentMatchProtocolConnectionFragmentDoc}
`;

/**
 * __useAllTournamentMatchProtocolsQuery__
 *
 * To run a query within a React component, call `useAllTournamentMatchProtocolsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllTournamentMatchProtocolsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllTournamentMatchProtocolsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllTournamentMatchProtocolsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    AllTournamentMatchProtocolsQuery,
    AllTournamentMatchProtocolsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AllTournamentMatchProtocolsQuery, AllTournamentMatchProtocolsQueryVariables>(
    AllTournamentMatchProtocolsDocument,
    options
  );
}
export function useAllTournamentMatchProtocolsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AllTournamentMatchProtocolsQuery,
    AllTournamentMatchProtocolsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AllTournamentMatchProtocolsQuery, AllTournamentMatchProtocolsQueryVariables>(
    AllTournamentMatchProtocolsDocument,
    options
  );
}
export type AllTournamentMatchProtocolsQueryHookResult = ReturnType<typeof useAllTournamentMatchProtocolsQuery>;
export type AllTournamentMatchProtocolsLazyQueryHookResult = ReturnType<typeof useAllTournamentMatchProtocolsLazyQuery>;
export type AllTournamentMatchProtocolsQueryResult = Apollo.QueryResult<
  AllTournamentMatchProtocolsQuery,
  AllTournamentMatchProtocolsQueryVariables
>;
export const AllTournamentMatchesDocument = gql`
  query allTournamentMatches($query: DefaultQueryInput) {
    allTournamentMatches(query: $query) {
      ...tournamentMatchConnection
    }
  }
  ${TournamentMatchConnectionFragmentDoc}
`;

/**
 * __useAllTournamentMatchesQuery__
 *
 * To run a query within a React component, call `useAllTournamentMatchesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllTournamentMatchesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllTournamentMatchesQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllTournamentMatchesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllTournamentMatchesQuery, AllTournamentMatchesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AllTournamentMatchesQuery, AllTournamentMatchesQueryVariables>(
    AllTournamentMatchesDocument,
    options
  );
}
export function useAllTournamentMatchesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllTournamentMatchesQuery, AllTournamentMatchesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AllTournamentMatchesQuery, AllTournamentMatchesQueryVariables>(
    AllTournamentMatchesDocument,
    options
  );
}
export type AllTournamentMatchesQueryHookResult = ReturnType<typeof useAllTournamentMatchesQuery>;
export type AllTournamentMatchesLazyQueryHookResult = ReturnType<typeof useAllTournamentMatchesLazyQuery>;
export type AllTournamentMatchesQueryResult = Apollo.QueryResult<
  AllTournamentMatchesQuery,
  AllTournamentMatchesQueryVariables
>;
export const AllTournamentPlayerStatsDocument = gql`
  query allTournamentPlayerStats($query: DefaultQueryInput) {
    allTournamentPlayerStats(query: $query) {
      ...tournamentPlayerStatConnection
    }
  }
  ${TournamentPlayerStatConnectionFragmentDoc}
`;

/**
 * __useAllTournamentPlayerStatsQuery__
 *
 * To run a query within a React component, call `useAllTournamentPlayerStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllTournamentPlayerStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllTournamentPlayerStatsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllTournamentPlayerStatsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllTournamentPlayerStatsQuery, AllTournamentPlayerStatsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AllTournamentPlayerStatsQuery, AllTournamentPlayerStatsQueryVariables>(
    AllTournamentPlayerStatsDocument,
    options
  );
}
export function useAllTournamentPlayerStatsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AllTournamentPlayerStatsQuery,
    AllTournamentPlayerStatsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AllTournamentPlayerStatsQuery, AllTournamentPlayerStatsQueryVariables>(
    AllTournamentPlayerStatsDocument,
    options
  );
}
export type AllTournamentPlayerStatsQueryHookResult = ReturnType<typeof useAllTournamentPlayerStatsQuery>;
export type AllTournamentPlayerStatsLazyQueryHookResult = ReturnType<typeof useAllTournamentPlayerStatsLazyQuery>;
export type AllTournamentPlayerStatsQueryResult = Apollo.QueryResult<
  AllTournamentPlayerStatsQuery,
  AllTournamentPlayerStatsQueryVariables
>;
export const AllTournamentRefereesDocument = gql`
  query allTournamentReferees($query: DefaultQueryInput) {
    allTournamentReferees(query: $query) {
      ...tournamentRefereeConnection
    }
  }
  ${TournamentRefereeConnectionFragmentDoc}
`;

/**
 * __useAllTournamentRefereesQuery__
 *
 * To run a query within a React component, call `useAllTournamentRefereesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllTournamentRefereesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllTournamentRefereesQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllTournamentRefereesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllTournamentRefereesQuery, AllTournamentRefereesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AllTournamentRefereesQuery, AllTournamentRefereesQueryVariables>(
    AllTournamentRefereesDocument,
    options
  );
}
export function useAllTournamentRefereesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllTournamentRefereesQuery, AllTournamentRefereesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AllTournamentRefereesQuery, AllTournamentRefereesQueryVariables>(
    AllTournamentRefereesDocument,
    options
  );
}
export type AllTournamentRefereesQueryHookResult = ReturnType<typeof useAllTournamentRefereesQuery>;
export type AllTournamentRefereesLazyQueryHookResult = ReturnType<typeof useAllTournamentRefereesLazyQuery>;
export type AllTournamentRefereesQueryResult = Apollo.QueryResult<
  AllTournamentRefereesQuery,
  AllTournamentRefereesQueryVariables
>;
export const AllTournamentSeasonTeamStatsDocument = gql`
  query allTournamentSeasonTeamStats($query: DefaultQueryInput) {
    allTournamentSeasonTeamStats(query: $query) {
      ...tournamentSeasonTeamStatConnection
    }
  }
  ${TournamentSeasonTeamStatConnectionFragmentDoc}
`;

/**
 * __useAllTournamentSeasonTeamStatsQuery__
 *
 * To run a query within a React component, call `useAllTournamentSeasonTeamStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllTournamentSeasonTeamStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllTournamentSeasonTeamStatsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllTournamentSeasonTeamStatsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    AllTournamentSeasonTeamStatsQuery,
    AllTournamentSeasonTeamStatsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AllTournamentSeasonTeamStatsQuery, AllTournamentSeasonTeamStatsQueryVariables>(
    AllTournamentSeasonTeamStatsDocument,
    options
  );
}
export function useAllTournamentSeasonTeamStatsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AllTournamentSeasonTeamStatsQuery,
    AllTournamentSeasonTeamStatsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AllTournamentSeasonTeamStatsQuery, AllTournamentSeasonTeamStatsQueryVariables>(
    AllTournamentSeasonTeamStatsDocument,
    options
  );
}
export type AllTournamentSeasonTeamStatsQueryHookResult = ReturnType<typeof useAllTournamentSeasonTeamStatsQuery>;
export type AllTournamentSeasonTeamStatsLazyQueryHookResult = ReturnType<
  typeof useAllTournamentSeasonTeamStatsLazyQuery
>;
export type AllTournamentSeasonTeamStatsQueryResult = Apollo.QueryResult<
  AllTournamentSeasonTeamStatsQuery,
  AllTournamentSeasonTeamStatsQueryVariables
>;
export const AllTournamentSeasonTeamsDocument = gql`
  query allTournamentSeasonTeams($query: DefaultQueryInput) {
    allTournamentSeasonTeams(query: $query) {
      ...tournamentSeasonTeamConnection
    }
  }
  ${TournamentSeasonTeamConnectionFragmentDoc}
`;

/**
 * __useAllTournamentSeasonTeamsQuery__
 *
 * To run a query within a React component, call `useAllTournamentSeasonTeamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllTournamentSeasonTeamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllTournamentSeasonTeamsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllTournamentSeasonTeamsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllTournamentSeasonTeamsQuery, AllTournamentSeasonTeamsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AllTournamentSeasonTeamsQuery, AllTournamentSeasonTeamsQueryVariables>(
    AllTournamentSeasonTeamsDocument,
    options
  );
}
export function useAllTournamentSeasonTeamsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AllTournamentSeasonTeamsQuery,
    AllTournamentSeasonTeamsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AllTournamentSeasonTeamsQuery, AllTournamentSeasonTeamsQueryVariables>(
    AllTournamentSeasonTeamsDocument,
    options
  );
}
export type AllTournamentSeasonTeamsQueryHookResult = ReturnType<typeof useAllTournamentSeasonTeamsQuery>;
export type AllTournamentSeasonTeamsLazyQueryHookResult = ReturnType<typeof useAllTournamentSeasonTeamsLazyQuery>;
export type AllTournamentSeasonTeamsQueryResult = Apollo.QueryResult<
  AllTournamentSeasonTeamsQuery,
  AllTournamentSeasonTeamsQueryVariables
>;
export const AllTournamentSeasonsDocument = gql`
  query allTournamentSeasons($query: DefaultQueryInput) {
    allTournamentSeasons(query: $query) {
      ...tournamentSeasonConnection
    }
  }
  ${TournamentSeasonConnectionFragmentDoc}
`;

/**
 * __useAllTournamentSeasonsQuery__
 *
 * To run a query within a React component, call `useAllTournamentSeasonsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllTournamentSeasonsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllTournamentSeasonsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllTournamentSeasonsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllTournamentSeasonsQuery, AllTournamentSeasonsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AllTournamentSeasonsQuery, AllTournamentSeasonsQueryVariables>(
    AllTournamentSeasonsDocument,
    options
  );
}
export function useAllTournamentSeasonsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllTournamentSeasonsQuery, AllTournamentSeasonsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AllTournamentSeasonsQuery, AllTournamentSeasonsQueryVariables>(
    AllTournamentSeasonsDocument,
    options
  );
}
export type AllTournamentSeasonsQueryHookResult = ReturnType<typeof useAllTournamentSeasonsQuery>;
export type AllTournamentSeasonsLazyQueryHookResult = ReturnType<typeof useAllTournamentSeasonsLazyQuery>;
export type AllTournamentSeasonsQueryResult = Apollo.QueryResult<
  AllTournamentSeasonsQuery,
  AllTournamentSeasonsQueryVariables
>;
export const AllTournamentTeamMembersDocument = gql`
  query allTournamentTeamMembers($query: DefaultQueryInput) {
    allTournamentTeamMembers(query: $query) {
      ...tournamentTeamMemberConnection
    }
  }
  ${TournamentTeamMemberConnectionFragmentDoc}
`;

/**
 * __useAllTournamentTeamMembersQuery__
 *
 * To run a query within a React component, call `useAllTournamentTeamMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllTournamentTeamMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllTournamentTeamMembersQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllTournamentTeamMembersQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllTournamentTeamMembersQuery, AllTournamentTeamMembersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AllTournamentTeamMembersQuery, AllTournamentTeamMembersQueryVariables>(
    AllTournamentTeamMembersDocument,
    options
  );
}
export function useAllTournamentTeamMembersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AllTournamentTeamMembersQuery,
    AllTournamentTeamMembersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AllTournamentTeamMembersQuery, AllTournamentTeamMembersQueryVariables>(
    AllTournamentTeamMembersDocument,
    options
  );
}
export type AllTournamentTeamMembersQueryHookResult = ReturnType<typeof useAllTournamentTeamMembersQuery>;
export type AllTournamentTeamMembersLazyQueryHookResult = ReturnType<typeof useAllTournamentTeamMembersLazyQuery>;
export type AllTournamentTeamMembersQueryResult = Apollo.QueryResult<
  AllTournamentTeamMembersQuery,
  AllTournamentTeamMembersQueryVariables
>;
export const AllTournamentTeamsDocument = gql`
  query allTournamentTeams($query: DefaultQueryInput) {
    allTournamentTeams(query: $query) {
      ...tournamentTeamConnection
    }
  }
  ${TournamentTeamConnectionFragmentDoc}
`;

/**
 * __useAllTournamentTeamsQuery__
 *
 * To run a query within a React component, call `useAllTournamentTeamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllTournamentTeamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllTournamentTeamsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllTournamentTeamsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllTournamentTeamsQuery, AllTournamentTeamsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AllTournamentTeamsQuery, AllTournamentTeamsQueryVariables>(
    AllTournamentTeamsDocument,
    options
  );
}
export function useAllTournamentTeamsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllTournamentTeamsQuery, AllTournamentTeamsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AllTournamentTeamsQuery, AllTournamentTeamsQueryVariables>(
    AllTournamentTeamsDocument,
    options
  );
}
export type AllTournamentTeamsQueryHookResult = ReturnType<typeof useAllTournamentTeamsQuery>;
export type AllTournamentTeamsLazyQueryHookResult = ReturnType<typeof useAllTournamentTeamsLazyQuery>;
export type AllTournamentTeamsQueryResult = Apollo.QueryResult<
  AllTournamentTeamsQuery,
  AllTournamentTeamsQueryVariables
>;
export const AllTournamentToursDocument = gql`
  query allTournamentTours($query: DefaultQueryInput) {
    allTournamentTours(query: $query) {
      ...tournamentTourConnection
    }
  }
  ${TournamentTourConnectionFragmentDoc}
`;

/**
 * __useAllTournamentToursQuery__
 *
 * To run a query within a React component, call `useAllTournamentToursQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllTournamentToursQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllTournamentToursQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllTournamentToursQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllTournamentToursQuery, AllTournamentToursQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AllTournamentToursQuery, AllTournamentToursQueryVariables>(
    AllTournamentToursDocument,
    options
  );
}
export function useAllTournamentToursLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllTournamentToursQuery, AllTournamentToursQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AllTournamentToursQuery, AllTournamentToursQueryVariables>(
    AllTournamentToursDocument,
    options
  );
}
export type AllTournamentToursQueryHookResult = ReturnType<typeof useAllTournamentToursQuery>;
export type AllTournamentToursLazyQueryHookResult = ReturnType<typeof useAllTournamentToursLazyQuery>;
export type AllTournamentToursQueryResult = Apollo.QueryResult<
  AllTournamentToursQuery,
  AllTournamentToursQueryVariables
>;
export const AllTournamentToursDeepDocument = gql`
  query allTournamentToursDeep($query: DefaultQueryInput) {
    allTournamentTours(query: $query) {
      ...tournamentTourConnectionDeep
    }
  }
  ${TournamentTourConnectionDeepFragmentDoc}
`;

/**
 * __useAllTournamentToursDeepQuery__
 *
 * To run a query within a React component, call `useAllTournamentToursDeepQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllTournamentToursDeepQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllTournamentToursDeepQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllTournamentToursDeepQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllTournamentToursDeepQuery, AllTournamentToursDeepQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AllTournamentToursDeepQuery, AllTournamentToursDeepQueryVariables>(
    AllTournamentToursDeepDocument,
    options
  );
}
export function useAllTournamentToursDeepLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllTournamentToursDeepQuery, AllTournamentToursDeepQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AllTournamentToursDeepQuery, AllTournamentToursDeepQueryVariables>(
    AllTournamentToursDeepDocument,
    options
  );
}
export type AllTournamentToursDeepQueryHookResult = ReturnType<typeof useAllTournamentToursDeepQuery>;
export type AllTournamentToursDeepLazyQueryHookResult = ReturnType<typeof useAllTournamentToursDeepLazyQuery>;
export type AllTournamentToursDeepQueryResult = Apollo.QueryResult<
  AllTournamentToursDeepQuery,
  AllTournamentToursDeepQueryVariables
>;
export const AllTournamentsDocument = gql`
  query allTournaments($query: DefaultQueryInput) {
    allTournaments(query: $query) {
      ...tournamentConnection
    }
  }
  ${TournamentConnectionFragmentDoc}
`;

/**
 * __useAllTournamentsQuery__
 *
 * To run a query within a React component, call `useAllTournamentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllTournamentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllTournamentsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllTournamentsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllTournamentsQuery, AllTournamentsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AllTournamentsQuery, AllTournamentsQueryVariables>(AllTournamentsDocument, options);
}
export function useAllTournamentsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllTournamentsQuery, AllTournamentsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AllTournamentsQuery, AllTournamentsQueryVariables>(
    AllTournamentsDocument,
    options
  );
}
export type AllTournamentsQueryHookResult = ReturnType<typeof useAllTournamentsQuery>;
export type AllTournamentsLazyQueryHookResult = ReturnType<typeof useAllTournamentsLazyQuery>;
export type AllTournamentsQueryResult = Apollo.QueryResult<AllTournamentsQuery, AllTournamentsQueryVariables>;
export const AllUserApplicationsDocument = gql`
  query allUserApplications($query: DefaultQueryInput) {
    allUserApplications(query: $query) {
      total
      edges {
        ...userApplication
      }
    }
  }
  ${UserApplicationFragmentDoc}
`;

/**
 * __useAllUserApplicationsQuery__
 *
 * To run a query within a React component, call `useAllUserApplicationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllUserApplicationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllUserApplicationsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllUserApplicationsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllUserApplicationsQuery, AllUserApplicationsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AllUserApplicationsQuery, AllUserApplicationsQueryVariables>(
    AllUserApplicationsDocument,
    options
  );
}
export function useAllUserApplicationsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllUserApplicationsQuery, AllUserApplicationsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AllUserApplicationsQuery, AllUserApplicationsQueryVariables>(
    AllUserApplicationsDocument,
    options
  );
}
export type AllUserApplicationsQueryHookResult = ReturnType<typeof useAllUserApplicationsQuery>;
export type AllUserApplicationsLazyQueryHookResult = ReturnType<typeof useAllUserApplicationsLazyQuery>;
export type AllUserApplicationsQueryResult = Apollo.QueryResult<
  AllUserApplicationsQuery,
  AllUserApplicationsQueryVariables
>;
export const AllUserFieldsDocument = gql`
  query allUserFields($query: DefaultQueryInput) {
    allUserFields(query: $query) {
      ...userFieldsConnection
    }
  }
  ${UserFieldsConnectionFragmentDoc}
`;

/**
 * __useAllUserFieldsQuery__
 *
 * To run a query within a React component, call `useAllUserFieldsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllUserFieldsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllUserFieldsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllUserFieldsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllUserFieldsQuery, AllUserFieldsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AllUserFieldsQuery, AllUserFieldsQueryVariables>(AllUserFieldsDocument, options);
}
export function useAllUserFieldsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllUserFieldsQuery, AllUserFieldsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AllUserFieldsQuery, AllUserFieldsQueryVariables>(AllUserFieldsDocument, options);
}
export type AllUserFieldsQueryHookResult = ReturnType<typeof useAllUserFieldsQuery>;
export type AllUserFieldsLazyQueryHookResult = ReturnType<typeof useAllUserFieldsLazyQuery>;
export type AllUserFieldsQueryResult = Apollo.QueryResult<AllUserFieldsQuery, AllUserFieldsQueryVariables>;
export const AllUserGroupsDocument = gql`
  query allUserGroups($query: DefaultQueryInput) {
    allUserGroups(query: $query) {
      ...userGroupConnection
    }
  }
  ${UserGroupConnectionFragmentDoc}
`;

/**
 * __useAllUserGroupsQuery__
 *
 * To run a query within a React component, call `useAllUserGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllUserGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllUserGroupsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllUserGroupsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllUserGroupsQuery, AllUserGroupsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AllUserGroupsQuery, AllUserGroupsQueryVariables>(AllUserGroupsDocument, options);
}
export function useAllUserGroupsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllUserGroupsQuery, AllUserGroupsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AllUserGroupsQuery, AllUserGroupsQueryVariables>(AllUserGroupsDocument, options);
}
export type AllUserGroupsQueryHookResult = ReturnType<typeof useAllUserGroupsQuery>;
export type AllUserGroupsLazyQueryHookResult = ReturnType<typeof useAllUserGroupsLazyQuery>;
export type AllUserGroupsQueryResult = Apollo.QueryResult<AllUserGroupsQuery, AllUserGroupsQueryVariables>;
export const AllUserKidsDocument = gql`
  query allUserKids($query: DefaultQueryInput) {
    allUserKids(query: $query) {
      total
      edges {
        ...userKid
      }
    }
  }
  ${UserKidFragmentDoc}
`;

/**
 * __useAllUserKidsQuery__
 *
 * To run a query within a React component, call `useAllUserKidsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllUserKidsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllUserKidsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllUserKidsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllUserKidsQuery, AllUserKidsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AllUserKidsQuery, AllUserKidsQueryVariables>(AllUserKidsDocument, options);
}
export function useAllUserKidsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllUserKidsQuery, AllUserKidsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AllUserKidsQuery, AllUserKidsQueryVariables>(AllUserKidsDocument, options);
}
export type AllUserKidsQueryHookResult = ReturnType<typeof useAllUserKidsQuery>;
export type AllUserKidsLazyQueryHookResult = ReturnType<typeof useAllUserKidsLazyQuery>;
export type AllUserKidsQueryResult = Apollo.QueryResult<AllUserKidsQuery, AllUserKidsQueryVariables>;
export const AllUserSubscribersDocument = gql`
  query allUserSubscribers($query: DefaultQueryInput) {
    allUserSubscribers(query: $query) {
      ...userSubscriberConnection
    }
  }
  ${UserSubscriberConnectionFragmentDoc}
`;

/**
 * __useAllUserSubscribersQuery__
 *
 * To run a query within a React component, call `useAllUserSubscribersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllUserSubscribersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllUserSubscribersQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllUserSubscribersQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllUserSubscribersQuery, AllUserSubscribersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AllUserSubscribersQuery, AllUserSubscribersQueryVariables>(
    AllUserSubscribersDocument,
    options
  );
}
export function useAllUserSubscribersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllUserSubscribersQuery, AllUserSubscribersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AllUserSubscribersQuery, AllUserSubscribersQueryVariables>(
    AllUserSubscribersDocument,
    options
  );
}
export type AllUserSubscribersQueryHookResult = ReturnType<typeof useAllUserSubscribersQuery>;
export type AllUserSubscribersLazyQueryHookResult = ReturnType<typeof useAllUserSubscribersLazyQuery>;
export type AllUserSubscribersQueryResult = Apollo.QueryResult<
  AllUserSubscribersQuery,
  AllUserSubscribersQueryVariables
>;
export const AllUserSubscribersLogsDocument = gql`
  query allUserSubscribersLogs($query: DefaultQueryInput) {
    allUserSubscribersLogs(query: $query) {
      ...userSubscribersLogConnection
    }
  }
  ${UserSubscribersLogConnectionFragmentDoc}
`;

/**
 * __useAllUserSubscribersLogsQuery__
 *
 * To run a query within a React component, call `useAllUserSubscribersLogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllUserSubscribersLogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllUserSubscribersLogsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllUserSubscribersLogsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllUserSubscribersLogsQuery, AllUserSubscribersLogsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AllUserSubscribersLogsQuery, AllUserSubscribersLogsQueryVariables>(
    AllUserSubscribersLogsDocument,
    options
  );
}
export function useAllUserSubscribersLogsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllUserSubscribersLogsQuery, AllUserSubscribersLogsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AllUserSubscribersLogsQuery, AllUserSubscribersLogsQueryVariables>(
    AllUserSubscribersLogsDocument,
    options
  );
}
export type AllUserSubscribersLogsQueryHookResult = ReturnType<typeof useAllUserSubscribersLogsQuery>;
export type AllUserSubscribersLogsLazyQueryHookResult = ReturnType<typeof useAllUserSubscribersLogsLazyQuery>;
export type AllUserSubscribersLogsQueryResult = Apollo.QueryResult<
  AllUserSubscribersLogsQuery,
  AllUserSubscribersLogsQueryVariables
>;
export const AllUserSubscriptionsDocument = gql`
  query allUserSubscriptions($query: DefaultQueryInput) {
    allUserSubscriptions(query: $query) {
      ...userSubscriptionConnection
    }
  }
  ${UserSubscriptionConnectionFragmentDoc}
`;

/**
 * __useAllUserSubscriptionsQuery__
 *
 * To run a query within a React component, call `useAllUserSubscriptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllUserSubscriptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllUserSubscriptionsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllUserSubscriptionsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllUserSubscriptionsQuery, AllUserSubscriptionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AllUserSubscriptionsQuery, AllUserSubscriptionsQueryVariables>(
    AllUserSubscriptionsDocument,
    options
  );
}
export function useAllUserSubscriptionsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllUserSubscriptionsQuery, AllUserSubscriptionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AllUserSubscriptionsQuery, AllUserSubscriptionsQueryVariables>(
    AllUserSubscriptionsDocument,
    options
  );
}
export type AllUserSubscriptionsQueryHookResult = ReturnType<typeof useAllUserSubscriptionsQuery>;
export type AllUserSubscriptionsLazyQueryHookResult = ReturnType<typeof useAllUserSubscriptionsLazyQuery>;
export type AllUserSubscriptionsQueryResult = Apollo.QueryResult<
  AllUserSubscriptionsQuery,
  AllUserSubscriptionsQueryVariables
>;
export const AllUsersDocument = gql`
  query allUsers($query: DefaultQueryInput) {
    allUsers(query: $query) {
      ...userConnection
    }
  }
  ${UserConnectionFragmentDoc}
`;

/**
 * __useAllUsersQuery__
 *
 * To run a query within a React component, call `useAllUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllUsersQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAllUsersQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AllUsersQuery, AllUsersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AllUsersQuery, AllUsersQueryVariables>(AllUsersDocument, options);
}
export function useAllUsersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllUsersQuery, AllUsersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AllUsersQuery, AllUsersQueryVariables>(AllUsersDocument, options);
}
export type AllUsersQueryHookResult = ReturnType<typeof useAllUsersQuery>;
export type AllUsersLazyQueryHookResult = ReturnType<typeof useAllUsersLazyQuery>;
export type AllUsersQueryResult = Apollo.QueryResult<AllUsersQuery, AllUsersQueryVariables>;
export const AllWebTranslationsDocument = gql`
  query allWebTranslations($locale: String!) {
    allWebTranslations(locale: $locale) {
      ...translation
    }
  }
  ${TranslationFragmentDoc}
`;

/**
 * __useAllWebTranslationsQuery__
 *
 * To run a query within a React component, call `useAllWebTranslationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllWebTranslationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllWebTranslationsQuery({
 *   variables: {
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useAllWebTranslationsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<AllWebTranslationsQuery, AllWebTranslationsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AllWebTranslationsQuery, AllWebTranslationsQueryVariables>(
    AllWebTranslationsDocument,
    options
  );
}
export function useAllWebTranslationsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllWebTranslationsQuery, AllWebTranslationsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AllWebTranslationsQuery, AllWebTranslationsQueryVariables>(
    AllWebTranslationsDocument,
    options
  );
}
export type AllWebTranslationsQueryHookResult = ReturnType<typeof useAllWebTranslationsQuery>;
export type AllWebTranslationsLazyQueryHookResult = ReturnType<typeof useAllWebTranslationsLazyQuery>;
export type AllWebTranslationsQueryResult = Apollo.QueryResult<
  AllWebTranslationsQuery,
  AllWebTranslationsQueryVariables
>;
export const ApplicationFiltersDocument = gql`
  query applicationFilters($partnerId: Int!) {
    applicationFilters(partnerId: $partnerId) {
      ...filterItem
    }
  }
  ${FilterItemFragmentDoc}
`;

/**
 * __useApplicationFiltersQuery__
 *
 * To run a query within a React component, call `useApplicationFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useApplicationFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApplicationFiltersQuery({
 *   variables: {
 *      partnerId: // value for 'partnerId'
 *   },
 * });
 */
export function useApplicationFiltersQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<ApplicationFiltersQuery, ApplicationFiltersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<ApplicationFiltersQuery, ApplicationFiltersQueryVariables>(
    ApplicationFiltersDocument,
    options
  );
}
export function useApplicationFiltersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ApplicationFiltersQuery, ApplicationFiltersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<ApplicationFiltersQuery, ApplicationFiltersQueryVariables>(
    ApplicationFiltersDocument,
    options
  );
}
export type ApplicationFiltersQueryHookResult = ReturnType<typeof useApplicationFiltersQuery>;
export type ApplicationFiltersLazyQueryHookResult = ReturnType<typeof useApplicationFiltersLazyQuery>;
export type ApplicationFiltersQueryResult = Apollo.QueryResult<
  ApplicationFiltersQuery,
  ApplicationFiltersQueryVariables
>;
export const ArticleDocument = gql`
  query article($id: Int!) {
    article(id: $id) {
      ...articleFull
    }
  }
  ${ArticleFullFragmentDoc}
`;

/**
 * __useArticleQuery__
 *
 * To run a query within a React component, call `useArticleQuery` and pass it any options that fit your needs.
 * When your component renders, `useArticleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArticleQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useArticleQuery(baseOptions: ApolloReactHooks.QueryHookOptions<ArticleQuery, ArticleQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<ArticleQuery, ArticleQueryVariables>(ArticleDocument, options);
}
export function useArticleLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ArticleQuery, ArticleQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<ArticleQuery, ArticleQueryVariables>(ArticleDocument, options);
}
export type ArticleQueryHookResult = ReturnType<typeof useArticleQuery>;
export type ArticleLazyQueryHookResult = ReturnType<typeof useArticleLazyQuery>;
export type ArticleQueryResult = Apollo.QueryResult<ArticleQuery, ArticleQueryVariables>;
export const ArticleAuthorDocument = gql`
  query articleAuthor($id: Int!) {
    articleAuthor(id: $id) {
      ...articleAuthor
    }
  }
  ${ArticleAuthorFragmentDoc}
`;

/**
 * __useArticleAuthorQuery__
 *
 * To run a query within a React component, call `useArticleAuthorQuery` and pass it any options that fit your needs.
 * When your component renders, `useArticleAuthorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArticleAuthorQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useArticleAuthorQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<ArticleAuthorQuery, ArticleAuthorQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<ArticleAuthorQuery, ArticleAuthorQueryVariables>(ArticleAuthorDocument, options);
}
export function useArticleAuthorLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ArticleAuthorQuery, ArticleAuthorQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<ArticleAuthorQuery, ArticleAuthorQueryVariables>(ArticleAuthorDocument, options);
}
export type ArticleAuthorQueryHookResult = ReturnType<typeof useArticleAuthorQuery>;
export type ArticleAuthorLazyQueryHookResult = ReturnType<typeof useArticleAuthorLazyQuery>;
export type ArticleAuthorQueryResult = Apollo.QueryResult<ArticleAuthorQuery, ArticleAuthorQueryVariables>;
export const ArticleBlockDocument = gql`
  query articleBlock($id: Int!) {
    articleBlock(id: $id) {
      ...articleBlock
    }
  }
  ${ArticleBlockFragmentDoc}
`;

/**
 * __useArticleBlockQuery__
 *
 * To run a query within a React component, call `useArticleBlockQuery` and pass it any options that fit your needs.
 * When your component renders, `useArticleBlockQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArticleBlockQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useArticleBlockQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<ArticleBlockQuery, ArticleBlockQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<ArticleBlockQuery, ArticleBlockQueryVariables>(ArticleBlockDocument, options);
}
export function useArticleBlockLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ArticleBlockQuery, ArticleBlockQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<ArticleBlockQuery, ArticleBlockQueryVariables>(ArticleBlockDocument, options);
}
export type ArticleBlockQueryHookResult = ReturnType<typeof useArticleBlockQuery>;
export type ArticleBlockLazyQueryHookResult = ReturnType<typeof useArticleBlockLazyQuery>;
export type ArticleBlockQueryResult = Apollo.QueryResult<ArticleBlockQuery, ArticleBlockQueryVariables>;
export const ArticleCategoryDocument = gql`
  query articleCategory($id: Int!) {
    articleCategory(id: $id) {
      ...articleCategory
    }
  }
  ${ArticleCategoryFragmentDoc}
`;

/**
 * __useArticleCategoryQuery__
 *
 * To run a query within a React component, call `useArticleCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useArticleCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArticleCategoryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useArticleCategoryQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<ArticleCategoryQuery, ArticleCategoryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<ArticleCategoryQuery, ArticleCategoryQueryVariables>(
    ArticleCategoryDocument,
    options
  );
}
export function useArticleCategoryLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ArticleCategoryQuery, ArticleCategoryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<ArticleCategoryQuery, ArticleCategoryQueryVariables>(
    ArticleCategoryDocument,
    options
  );
}
export type ArticleCategoryQueryHookResult = ReturnType<typeof useArticleCategoryQuery>;
export type ArticleCategoryLazyQueryHookResult = ReturnType<typeof useArticleCategoryLazyQuery>;
export type ArticleCategoryQueryResult = Apollo.QueryResult<ArticleCategoryQuery, ArticleCategoryQueryVariables>;
export const ArticleLinkDocument = gql`
  query articleLink($id: Int!) {
    articleLink(id: $id) {
      ...articleLink
    }
  }
  ${ArticleLinkFragmentDoc}
`;

/**
 * __useArticleLinkQuery__
 *
 * To run a query within a React component, call `useArticleLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useArticleLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArticleLinkQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useArticleLinkQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<ArticleLinkQuery, ArticleLinkQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<ArticleLinkQuery, ArticleLinkQueryVariables>(ArticleLinkDocument, options);
}
export function useArticleLinkLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ArticleLinkQuery, ArticleLinkQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<ArticleLinkQuery, ArticleLinkQueryVariables>(ArticleLinkDocument, options);
}
export type ArticleLinkQueryHookResult = ReturnType<typeof useArticleLinkQuery>;
export type ArticleLinkLazyQueryHookResult = ReturnType<typeof useArticleLinkLazyQuery>;
export type ArticleLinkQueryResult = Apollo.QueryResult<ArticleLinkQuery, ArticleLinkQueryVariables>;
export const ArticleTagDocument = gql`
  query articleTag($id: Int!) {
    articleTag(id: $id) {
      ...articleTag
    }
  }
  ${ArticleTagFragmentDoc}
`;

/**
 * __useArticleTagQuery__
 *
 * To run a query within a React component, call `useArticleTagQuery` and pass it any options that fit your needs.
 * When your component renders, `useArticleTagQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArticleTagQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useArticleTagQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<ArticleTagQuery, ArticleTagQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<ArticleTagQuery, ArticleTagQueryVariables>(ArticleTagDocument, options);
}
export function useArticleTagLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ArticleTagQuery, ArticleTagQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<ArticleTagQuery, ArticleTagQueryVariables>(ArticleTagDocument, options);
}
export type ArticleTagQueryHookResult = ReturnType<typeof useArticleTagQuery>;
export type ArticleTagLazyQueryHookResult = ReturnType<typeof useArticleTagLazyQuery>;
export type ArticleTagQueryResult = Apollo.QueryResult<ArticleTagQuery, ArticleTagQueryVariables>;
export const CatalogueDocument = gql`
  query catalogue($id: Int!) {
    catalogue(id: $id) {
      ...catalogue
    }
  }
  ${CatalogueFragmentDoc}
`;

/**
 * __useCatalogueQuery__
 *
 * To run a query within a React component, call `useCatalogueQuery` and pass it any options that fit your needs.
 * When your component renders, `useCatalogueQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCatalogueQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCatalogueQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<CatalogueQuery, CatalogueQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<CatalogueQuery, CatalogueQueryVariables>(CatalogueDocument, options);
}
export function useCatalogueLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CatalogueQuery, CatalogueQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<CatalogueQuery, CatalogueQueryVariables>(CatalogueDocument, options);
}
export type CatalogueQueryHookResult = ReturnType<typeof useCatalogueQuery>;
export type CatalogueLazyQueryHookResult = ReturnType<typeof useCatalogueLazyQuery>;
export type CatalogueQueryResult = Apollo.QueryResult<CatalogueQuery, CatalogueQueryVariables>;
export const CatalogueCategoryDocument = gql`
  query catalogueCategory($id: Int!) {
    catalogueCategory(id: $id) {
      ...catalogueCategory
    }
  }
  ${CatalogueCategoryFragmentDoc}
`;

/**
 * __useCatalogueCategoryQuery__
 *
 * To run a query within a React component, call `useCatalogueCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useCatalogueCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCatalogueCategoryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCatalogueCategoryQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<CatalogueCategoryQuery, CatalogueCategoryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<CatalogueCategoryQuery, CatalogueCategoryQueryVariables>(
    CatalogueCategoryDocument,
    options
  );
}
export function useCatalogueCategoryLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CatalogueCategoryQuery, CatalogueCategoryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<CatalogueCategoryQuery, CatalogueCategoryQueryVariables>(
    CatalogueCategoryDocument,
    options
  );
}
export type CatalogueCategoryQueryHookResult = ReturnType<typeof useCatalogueCategoryQuery>;
export type CatalogueCategoryLazyQueryHookResult = ReturnType<typeof useCatalogueCategoryLazyQuery>;
export type CatalogueCategoryQueryResult = Apollo.QueryResult<CatalogueCategoryQuery, CatalogueCategoryQueryVariables>;
export const CatalogueItemDocument = gql`
  query catalogueItem($id: Int!) {
    catalogueItem(id: $id) {
      ...catalogueItem
    }
  }
  ${CatalogueItemFragmentDoc}
`;

/**
 * __useCatalogueItemQuery__
 *
 * To run a query within a React component, call `useCatalogueItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useCatalogueItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCatalogueItemQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCatalogueItemQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<CatalogueItemQuery, CatalogueItemQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<CatalogueItemQuery, CatalogueItemQueryVariables>(CatalogueItemDocument, options);
}
export function useCatalogueItemLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CatalogueItemQuery, CatalogueItemQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<CatalogueItemQuery, CatalogueItemQueryVariables>(CatalogueItemDocument, options);
}
export type CatalogueItemQueryHookResult = ReturnType<typeof useCatalogueItemQuery>;
export type CatalogueItemLazyQueryHookResult = ReturnType<typeof useCatalogueItemLazyQuery>;
export type CatalogueItemQueryResult = Apollo.QueryResult<CatalogueItemQuery, CatalogueItemQueryVariables>;
export const CatalogueItemPriceDocument = gql`
  query catalogueItemPrice($id: Int!) {
    catalogueItemPrice(id: $id) {
      ...catalogueItemPrice
    }
  }
  ${CatalogueItemPriceFragmentDoc}
`;

/**
 * __useCatalogueItemPriceQuery__
 *
 * To run a query within a React component, call `useCatalogueItemPriceQuery` and pass it any options that fit your needs.
 * When your component renders, `useCatalogueItemPriceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCatalogueItemPriceQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCatalogueItemPriceQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<CatalogueItemPriceQuery, CatalogueItemPriceQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<CatalogueItemPriceQuery, CatalogueItemPriceQueryVariables>(
    CatalogueItemPriceDocument,
    options
  );
}
export function useCatalogueItemPriceLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CatalogueItemPriceQuery, CatalogueItemPriceQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<CatalogueItemPriceQuery, CatalogueItemPriceQueryVariables>(
    CatalogueItemPriceDocument,
    options
  );
}
export type CatalogueItemPriceQueryHookResult = ReturnType<typeof useCatalogueItemPriceQuery>;
export type CatalogueItemPriceLazyQueryHookResult = ReturnType<typeof useCatalogueItemPriceLazyQuery>;
export type CatalogueItemPriceQueryResult = Apollo.QueryResult<
  CatalogueItemPriceQuery,
  CatalogueItemPriceQueryVariables
>;
export const CatalogueItemsByEventDocument = gql`
  query catalogueItemsByEvent($eventId: Int!) {
    catalogueItemsByEvent(eventId: $eventId) {
      ...catalogueItem
    }
  }
  ${CatalogueItemFragmentDoc}
`;

/**
 * __useCatalogueItemsByEventQuery__
 *
 * To run a query within a React component, call `useCatalogueItemsByEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useCatalogueItemsByEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCatalogueItemsByEventQuery({
 *   variables: {
 *      eventId: // value for 'eventId'
 *   },
 * });
 */
export function useCatalogueItemsByEventQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<CatalogueItemsByEventQuery, CatalogueItemsByEventQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<CatalogueItemsByEventQuery, CatalogueItemsByEventQueryVariables>(
    CatalogueItemsByEventDocument,
    options
  );
}
export function useCatalogueItemsByEventLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CatalogueItemsByEventQuery, CatalogueItemsByEventQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<CatalogueItemsByEventQuery, CatalogueItemsByEventQueryVariables>(
    CatalogueItemsByEventDocument,
    options
  );
}
export type CatalogueItemsByEventQueryHookResult = ReturnType<typeof useCatalogueItemsByEventQuery>;
export type CatalogueItemsByEventLazyQueryHookResult = ReturnType<typeof useCatalogueItemsByEventLazyQuery>;
export type CatalogueItemsByEventQueryResult = Apollo.QueryResult<
  CatalogueItemsByEventQuery,
  CatalogueItemsByEventQueryVariables
>;
export const CatalogueSummaryWidgetDocument = gql`
  query catalogueSummaryWidget {
    catalogueSummaryWidget {
      ...catalogueSummaryWidget
    }
  }
  ${CatalogueSummaryWidgetFragmentDoc}
`;

/**
 * __useCatalogueSummaryWidgetQuery__
 *
 * To run a query within a React component, call `useCatalogueSummaryWidgetQuery` and pass it any options that fit your needs.
 * When your component renders, `useCatalogueSummaryWidgetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCatalogueSummaryWidgetQuery({
 *   variables: {
 *   },
 * });
 */
export function useCatalogueSummaryWidgetQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<CatalogueSummaryWidgetQuery, CatalogueSummaryWidgetQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<CatalogueSummaryWidgetQuery, CatalogueSummaryWidgetQueryVariables>(
    CatalogueSummaryWidgetDocument,
    options
  );
}
export function useCatalogueSummaryWidgetLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CatalogueSummaryWidgetQuery, CatalogueSummaryWidgetQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<CatalogueSummaryWidgetQuery, CatalogueSummaryWidgetQueryVariables>(
    CatalogueSummaryWidgetDocument,
    options
  );
}
export type CatalogueSummaryWidgetQueryHookResult = ReturnType<typeof useCatalogueSummaryWidgetQuery>;
export type CatalogueSummaryWidgetLazyQueryHookResult = ReturnType<typeof useCatalogueSummaryWidgetLazyQuery>;
export type CatalogueSummaryWidgetQueryResult = Apollo.QueryResult<
  CatalogueSummaryWidgetQuery,
  CatalogueSummaryWidgetQueryVariables
>;
export const CateringDocument = gql`
  query catering($id: Int!) {
    catering(id: $id) {
      ...catering
    }
  }
  ${CateringFragmentDoc}
`;

/**
 * __useCateringQuery__
 *
 * To run a query within a React component, call `useCateringQuery` and pass it any options that fit your needs.
 * When your component renders, `useCateringQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCateringQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCateringQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<CateringQuery, CateringQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<CateringQuery, CateringQueryVariables>(CateringDocument, options);
}
export function useCateringLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CateringQuery, CateringQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<CateringQuery, CateringQueryVariables>(CateringDocument, options);
}
export type CateringQueryHookResult = ReturnType<typeof useCateringQuery>;
export type CateringLazyQueryHookResult = ReturnType<typeof useCateringLazyQuery>;
export type CateringQueryResult = Apollo.QueryResult<CateringQuery, CateringQueryVariables>;
export const CheckFanIdDocument = gql`
  query checkFanId($fanId: [String!]!) {
    checkFanId(fanId: $fanId) {
      fanCode
      fanGUID
      isSuccess
      errorMessage
    }
  }
`;

/**
 * __useCheckFanIdQuery__
 *
 * To run a query within a React component, call `useCheckFanIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckFanIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckFanIdQuery({
 *   variables: {
 *      fanId: // value for 'fanId'
 *   },
 * });
 */
export function useCheckFanIdQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<CheckFanIdQuery, CheckFanIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<CheckFanIdQuery, CheckFanIdQueryVariables>(CheckFanIdDocument, options);
}
export function useCheckFanIdLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CheckFanIdQuery, CheckFanIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<CheckFanIdQuery, CheckFanIdQueryVariables>(CheckFanIdDocument, options);
}
export type CheckFanIdQueryHookResult = ReturnType<typeof useCheckFanIdQuery>;
export type CheckFanIdLazyQueryHookResult = ReturnType<typeof useCheckFanIdLazyQuery>;
export type CheckFanIdQueryResult = Apollo.QueryResult<CheckFanIdQuery, CheckFanIdQueryVariables>;
export const CheckPromocodeDocument = gql`
  query checkPromocode($promoId: Int!, $code: String!) {
    checkPromocode(promoId: $promoId, code: $code) {
      id
      code
      promo {
        id
        discountPercent
      }
    }
  }
`;

/**
 * __useCheckPromocodeQuery__
 *
 * To run a query within a React component, call `useCheckPromocodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckPromocodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckPromocodeQuery({
 *   variables: {
 *      promoId: // value for 'promoId'
 *      code: // value for 'code'
 *   },
 * });
 */
export function useCheckPromocodeQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<CheckPromocodeQuery, CheckPromocodeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<CheckPromocodeQuery, CheckPromocodeQueryVariables>(CheckPromocodeDocument, options);
}
export function useCheckPromocodeLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CheckPromocodeQuery, CheckPromocodeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<CheckPromocodeQuery, CheckPromocodeQueryVariables>(
    CheckPromocodeDocument,
    options
  );
}
export type CheckPromocodeQueryHookResult = ReturnType<typeof useCheckPromocodeQuery>;
export type CheckPromocodeLazyQueryHookResult = ReturnType<typeof useCheckPromocodeLazyQuery>;
export type CheckPromocodeQueryResult = Apollo.QueryResult<CheckPromocodeQuery, CheckPromocodeQueryVariables>;
export const ClientPublicProfileDocument = gql`
  query clientPublicProfile($name: String, $domain: String) {
    clientPublicProfile(name: $name, domain: $domain) {
      ...clientPublicProfile
    }
  }
  ${ClientPublicProfileFragmentDoc}
`;

/**
 * __useClientPublicProfileQuery__
 *
 * To run a query within a React component, call `useClientPublicProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientPublicProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientPublicProfileQuery({
 *   variables: {
 *      name: // value for 'name'
 *      domain: // value for 'domain'
 *   },
 * });
 */
export function useClientPublicProfileQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<ClientPublicProfileQuery, ClientPublicProfileQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<ClientPublicProfileQuery, ClientPublicProfileQueryVariables>(
    ClientPublicProfileDocument,
    options
  );
}
export function useClientPublicProfileLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ClientPublicProfileQuery, ClientPublicProfileQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<ClientPublicProfileQuery, ClientPublicProfileQueryVariables>(
    ClientPublicProfileDocument,
    options
  );
}
export type ClientPublicProfileQueryHookResult = ReturnType<typeof useClientPublicProfileQuery>;
export type ClientPublicProfileLazyQueryHookResult = ReturnType<typeof useClientPublicProfileLazyQuery>;
export type ClientPublicProfileQueryResult = Apollo.QueryResult<
  ClientPublicProfileQuery,
  ClientPublicProfileQueryVariables
>;
export const CommerceOrderDocument = gql`
  query commerceOrder($id: Int!) {
    commerceOrder(id: $id) {
      ...commerceOrder
    }
  }
  ${CommerceOrderFragmentDoc}
`;

/**
 * __useCommerceOrderQuery__
 *
 * To run a query within a React component, call `useCommerceOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommerceOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommerceOrderQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCommerceOrderQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<CommerceOrderQuery, CommerceOrderQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<CommerceOrderQuery, CommerceOrderQueryVariables>(CommerceOrderDocument, options);
}
export function useCommerceOrderLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CommerceOrderQuery, CommerceOrderQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<CommerceOrderQuery, CommerceOrderQueryVariables>(CommerceOrderDocument, options);
}
export type CommerceOrderQueryHookResult = ReturnType<typeof useCommerceOrderQuery>;
export type CommerceOrderLazyQueryHookResult = ReturnType<typeof useCommerceOrderLazyQuery>;
export type CommerceOrderQueryResult = Apollo.QueryResult<CommerceOrderQuery, CommerceOrderQueryVariables>;
export const CommerceOrderDataDocument = gql`
  query commerceOrderData($id: Int!) {
    commerceOrderData(id: $id) {
      ...commerceOrderData
    }
  }
  ${CommerceOrderDataFragmentDoc}
`;

/**
 * __useCommerceOrderDataQuery__
 *
 * To run a query within a React component, call `useCommerceOrderDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommerceOrderDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommerceOrderDataQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCommerceOrderDataQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<CommerceOrderDataQuery, CommerceOrderDataQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<CommerceOrderDataQuery, CommerceOrderDataQueryVariables>(
    CommerceOrderDataDocument,
    options
  );
}
export function useCommerceOrderDataLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CommerceOrderDataQuery, CommerceOrderDataQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<CommerceOrderDataQuery, CommerceOrderDataQueryVariables>(
    CommerceOrderDataDocument,
    options
  );
}
export type CommerceOrderDataQueryHookResult = ReturnType<typeof useCommerceOrderDataQuery>;
export type CommerceOrderDataLazyQueryHookResult = ReturnType<typeof useCommerceOrderDataLazyQuery>;
export type CommerceOrderDataQueryResult = Apollo.QueryResult<CommerceOrderDataQuery, CommerceOrderDataQueryVariables>;
export const CommerceOrderFieldDocument = gql`
  query commerceOrderField($id: Int!) {
    commerceOrderField(id: $id) {
      ...commerceOrderField
    }
  }
  ${CommerceOrderFieldFragmentDoc}
`;

/**
 * __useCommerceOrderFieldQuery__
 *
 * To run a query within a React component, call `useCommerceOrderFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommerceOrderFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommerceOrderFieldQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCommerceOrderFieldQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<CommerceOrderFieldQuery, CommerceOrderFieldQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<CommerceOrderFieldQuery, CommerceOrderFieldQueryVariables>(
    CommerceOrderFieldDocument,
    options
  );
}
export function useCommerceOrderFieldLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CommerceOrderFieldQuery, CommerceOrderFieldQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<CommerceOrderFieldQuery, CommerceOrderFieldQueryVariables>(
    CommerceOrderFieldDocument,
    options
  );
}
export type CommerceOrderFieldQueryHookResult = ReturnType<typeof useCommerceOrderFieldQuery>;
export type CommerceOrderFieldLazyQueryHookResult = ReturnType<typeof useCommerceOrderFieldLazyQuery>;
export type CommerceOrderFieldQueryResult = Apollo.QueryResult<
  CommerceOrderFieldQuery,
  CommerceOrderFieldQueryVariables
>;
export const CommerceOrderFiltersDocument = gql`
  query commerceOrderFilters($shopId: Int!) {
    commerceOrderFilters(shopId: $shopId) {
      ...filterItem
    }
  }
  ${FilterItemFragmentDoc}
`;

/**
 * __useCommerceOrderFiltersQuery__
 *
 * To run a query within a React component, call `useCommerceOrderFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommerceOrderFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommerceOrderFiltersQuery({
 *   variables: {
 *      shopId: // value for 'shopId'
 *   },
 * });
 */
export function useCommerceOrderFiltersQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<CommerceOrderFiltersQuery, CommerceOrderFiltersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<CommerceOrderFiltersQuery, CommerceOrderFiltersQueryVariables>(
    CommerceOrderFiltersDocument,
    options
  );
}
export function useCommerceOrderFiltersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CommerceOrderFiltersQuery, CommerceOrderFiltersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<CommerceOrderFiltersQuery, CommerceOrderFiltersQueryVariables>(
    CommerceOrderFiltersDocument,
    options
  );
}
export type CommerceOrderFiltersQueryHookResult = ReturnType<typeof useCommerceOrderFiltersQuery>;
export type CommerceOrderFiltersLazyQueryHookResult = ReturnType<typeof useCommerceOrderFiltersLazyQuery>;
export type CommerceOrderFiltersQueryResult = Apollo.QueryResult<
  CommerceOrderFiltersQuery,
  CommerceOrderFiltersQueryVariables
>;
export const CommerceOrderItemDocument = gql`
  query commerceOrderItem($id: Int!) {
    commerceOrderItem(id: $id) {
      ...commerceOrderItem
    }
  }
  ${CommerceOrderItemFragmentDoc}
`;

/**
 * __useCommerceOrderItemQuery__
 *
 * To run a query within a React component, call `useCommerceOrderItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommerceOrderItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommerceOrderItemQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCommerceOrderItemQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<CommerceOrderItemQuery, CommerceOrderItemQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<CommerceOrderItemQuery, CommerceOrderItemQueryVariables>(
    CommerceOrderItemDocument,
    options
  );
}
export function useCommerceOrderItemLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CommerceOrderItemQuery, CommerceOrderItemQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<CommerceOrderItemQuery, CommerceOrderItemQueryVariables>(
    CommerceOrderItemDocument,
    options
  );
}
export type CommerceOrderItemQueryHookResult = ReturnType<typeof useCommerceOrderItemQuery>;
export type CommerceOrderItemLazyQueryHookResult = ReturnType<typeof useCommerceOrderItemLazyQuery>;
export type CommerceOrderItemQueryResult = Apollo.QueryResult<CommerceOrderItemQuery, CommerceOrderItemQueryVariables>;
export const CommerceOrderStatesWidgetDocument = gql`
  query commerceOrderStatesWidget($shopId: Int!) {
    commerceOrderStatesWidget(shopId: $shopId) {
      ...commerceOrderStatesWidgetData
    }
  }
  ${CommerceOrderStatesWidgetDataFragmentDoc}
`;

/**
 * __useCommerceOrderStatesWidgetQuery__
 *
 * To run a query within a React component, call `useCommerceOrderStatesWidgetQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommerceOrderStatesWidgetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommerceOrderStatesWidgetQuery({
 *   variables: {
 *      shopId: // value for 'shopId'
 *   },
 * });
 */
export function useCommerceOrderStatesWidgetQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    CommerceOrderStatesWidgetQuery,
    CommerceOrderStatesWidgetQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<CommerceOrderStatesWidgetQuery, CommerceOrderStatesWidgetQueryVariables>(
    CommerceOrderStatesWidgetDocument,
    options
  );
}
export function useCommerceOrderStatesWidgetLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CommerceOrderStatesWidgetQuery,
    CommerceOrderStatesWidgetQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<CommerceOrderStatesWidgetQuery, CommerceOrderStatesWidgetQueryVariables>(
    CommerceOrderStatesWidgetDocument,
    options
  );
}
export type CommerceOrderStatesWidgetQueryHookResult = ReturnType<typeof useCommerceOrderStatesWidgetQuery>;
export type CommerceOrderStatesWidgetLazyQueryHookResult = ReturnType<typeof useCommerceOrderStatesWidgetLazyQuery>;
export type CommerceOrderStatesWidgetQueryResult = Apollo.QueryResult<
  CommerceOrderStatesWidgetQuery,
  CommerceOrderStatesWidgetQueryVariables
>;
export const CommerceShopDocument = gql`
  query commerceShop($id: Int!) {
    commerceShop(id: $id) {
      ...commerceShop
    }
  }
  ${CommerceShopFragmentDoc}
`;

/**
 * __useCommerceShopQuery__
 *
 * To run a query within a React component, call `useCommerceShopQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommerceShopQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommerceShopQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCommerceShopQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<CommerceShopQuery, CommerceShopQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<CommerceShopQuery, CommerceShopQueryVariables>(CommerceShopDocument, options);
}
export function useCommerceShopLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CommerceShopQuery, CommerceShopQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<CommerceShopQuery, CommerceShopQueryVariables>(CommerceShopDocument, options);
}
export type CommerceShopQueryHookResult = ReturnType<typeof useCommerceShopQuery>;
export type CommerceShopLazyQueryHookResult = ReturnType<typeof useCommerceShopLazyQuery>;
export type CommerceShopQueryResult = Apollo.QueryResult<CommerceShopQuery, CommerceShopQueryVariables>;
export const CommerceSummaryWidgetDocument = gql`
  query commerceSummaryWidget($query: DefaultQueryInput) {
    commerceSummaryWidget(query: $query) {
      ...commerceSummaryWidget
    }
  }
  ${CommerceSummaryWidgetFragmentDoc}
`;

/**
 * __useCommerceSummaryWidgetQuery__
 *
 * To run a query within a React component, call `useCommerceSummaryWidgetQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommerceSummaryWidgetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommerceSummaryWidgetQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useCommerceSummaryWidgetQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<CommerceSummaryWidgetQuery, CommerceSummaryWidgetQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<CommerceSummaryWidgetQuery, CommerceSummaryWidgetQueryVariables>(
    CommerceSummaryWidgetDocument,
    options
  );
}
export function useCommerceSummaryWidgetLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CommerceSummaryWidgetQuery, CommerceSummaryWidgetQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<CommerceSummaryWidgetQuery, CommerceSummaryWidgetQueryVariables>(
    CommerceSummaryWidgetDocument,
    options
  );
}
export type CommerceSummaryWidgetQueryHookResult = ReturnType<typeof useCommerceSummaryWidgetQuery>;
export type CommerceSummaryWidgetLazyQueryHookResult = ReturnType<typeof useCommerceSummaryWidgetLazyQuery>;
export type CommerceSummaryWidgetQueryResult = Apollo.QueryResult<
  CommerceSummaryWidgetQuery,
  CommerceSummaryWidgetQueryVariables
>;
export const ContentTranslationDocument = gql`
  query contentTranslation($id: Int!) {
    contentTranslation(id: $id) {
      ...contentTranslation
    }
  }
  ${ContentTranslationFragmentDoc}
`;

/**
 * __useContentTranslationQuery__
 *
 * To run a query within a React component, call `useContentTranslationQuery` and pass it any options that fit your needs.
 * When your component renders, `useContentTranslationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContentTranslationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useContentTranslationQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<ContentTranslationQuery, ContentTranslationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<ContentTranslationQuery, ContentTranslationQueryVariables>(
    ContentTranslationDocument,
    options
  );
}
export function useContentTranslationLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ContentTranslationQuery, ContentTranslationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<ContentTranslationQuery, ContentTranslationQueryVariables>(
    ContentTranslationDocument,
    options
  );
}
export type ContentTranslationQueryHookResult = ReturnType<typeof useContentTranslationQuery>;
export type ContentTranslationLazyQueryHookResult = ReturnType<typeof useContentTranslationLazyQuery>;
export type ContentTranslationQueryResult = Apollo.QueryResult<
  ContentTranslationQuery,
  ContentTranslationQueryVariables
>;
export const ContentTranslationsDocument = gql`
  query contentTranslations($query: DefaultQueryInput) {
    contentTranslations(query: $query) {
      ...contentTranslation
    }
  }
  ${ContentTranslationFragmentDoc}
`;

/**
 * __useContentTranslationsQuery__
 *
 * To run a query within a React component, call `useContentTranslationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useContentTranslationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContentTranslationsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useContentTranslationsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<ContentTranslationsQuery, ContentTranslationsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<ContentTranslationsQuery, ContentTranslationsQueryVariables>(
    ContentTranslationsDocument,
    options
  );
}
export function useContentTranslationsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ContentTranslationsQuery, ContentTranslationsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<ContentTranslationsQuery, ContentTranslationsQueryVariables>(
    ContentTranslationsDocument,
    options
  );
}
export type ContentTranslationsQueryHookResult = ReturnType<typeof useContentTranslationsQuery>;
export type ContentTranslationsLazyQueryHookResult = ReturnType<typeof useContentTranslationsLazyQuery>;
export type ContentTranslationsQueryResult = Apollo.QueryResult<
  ContentTranslationsQuery,
  ContentTranslationsQueryVariables
>;
export const DisputeDocument = gql`
  query dispute($id: Int!) {
    dispute(id: $id) {
      ...dispute
    }
  }
  ${DisputeFragmentDoc}
`;

/**
 * __useDisputeQuery__
 *
 * To run a query within a React component, call `useDisputeQuery` and pass it any options that fit your needs.
 * When your component renders, `useDisputeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDisputeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDisputeQuery(baseOptions: ApolloReactHooks.QueryHookOptions<DisputeQuery, DisputeQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<DisputeQuery, DisputeQueryVariables>(DisputeDocument, options);
}
export function useDisputeLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DisputeQuery, DisputeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<DisputeQuery, DisputeQueryVariables>(DisputeDocument, options);
}
export type DisputeQueryHookResult = ReturnType<typeof useDisputeQuery>;
export type DisputeLazyQueryHookResult = ReturnType<typeof useDisputeLazyQuery>;
export type DisputeQueryResult = Apollo.QueryResult<DisputeQuery, DisputeQueryVariables>;
export const DisputeDisputeResponseDocument = gql`
  query disputeDisputeResponse($id: Int!) {
    disputeDisputeResponse(id: $id) {
      ...disputeDisputeResponse
    }
  }
  ${DisputeDisputeResponseFragmentDoc}
`;

/**
 * __useDisputeDisputeResponseQuery__
 *
 * To run a query within a React component, call `useDisputeDisputeResponseQuery` and pass it any options that fit your needs.
 * When your component renders, `useDisputeDisputeResponseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDisputeDisputeResponseQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDisputeDisputeResponseQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<DisputeDisputeResponseQuery, DisputeDisputeResponseQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<DisputeDisputeResponseQuery, DisputeDisputeResponseQueryVariables>(
    DisputeDisputeResponseDocument,
    options
  );
}
export function useDisputeDisputeResponseLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DisputeDisputeResponseQuery, DisputeDisputeResponseQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<DisputeDisputeResponseQuery, DisputeDisputeResponseQueryVariables>(
    DisputeDisputeResponseDocument,
    options
  );
}
export type DisputeDisputeResponseQueryHookResult = ReturnType<typeof useDisputeDisputeResponseQuery>;
export type DisputeDisputeResponseLazyQueryHookResult = ReturnType<typeof useDisputeDisputeResponseLazyQuery>;
export type DisputeDisputeResponseQueryResult = Apollo.QueryResult<
  DisputeDisputeResponseQuery,
  DisputeDisputeResponseQueryVariables
>;
export const DisputeSettingsDocument = gql`
  query disputeSettings {
    disputeSettings {
      ...disputeSettings
    }
  }
  ${DisputeSettingsFragmentDoc}
`;

/**
 * __useDisputeSettingsQuery__
 *
 * To run a query within a React component, call `useDisputeSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDisputeSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDisputeSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useDisputeSettingsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<DisputeSettingsQuery, DisputeSettingsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<DisputeSettingsQuery, DisputeSettingsQueryVariables>(
    DisputeSettingsDocument,
    options
  );
}
export function useDisputeSettingsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DisputeSettingsQuery, DisputeSettingsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<DisputeSettingsQuery, DisputeSettingsQueryVariables>(
    DisputeSettingsDocument,
    options
  );
}
export type DisputeSettingsQueryHookResult = ReturnType<typeof useDisputeSettingsQuery>;
export type DisputeSettingsLazyQueryHookResult = ReturnType<typeof useDisputeSettingsLazyQuery>;
export type DisputeSettingsQueryResult = Apollo.QueryResult<DisputeSettingsQuery, DisputeSettingsQueryVariables>;
export const EventDocument = gql`
  query event($id: Int!) {
    event(id: $id) {
      ...eventFull
    }
  }
  ${EventFullFragmentDoc}
`;

/**
 * __useEventQuery__
 *
 * To run a query within a React component, call `useEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEventQuery(baseOptions: ApolloReactHooks.QueryHookOptions<EventQuery, EventQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<EventQuery, EventQueryVariables>(EventDocument, options);
}
export function useEventLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EventQuery, EventQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<EventQuery, EventQueryVariables>(EventDocument, options);
}
export type EventQueryHookResult = ReturnType<typeof useEventQuery>;
export type EventLazyQueryHookResult = ReturnType<typeof useEventLazyQuery>;
export type EventQueryResult = Apollo.QueryResult<EventQuery, EventQueryVariables>;
export const EventEventAccessDocument = gql`
  query eventEventAccess($id: Int!) {
    eventEventAccess(id: $id) {
      ...eventEventAccess
    }
  }
  ${EventEventAccessFragmentDoc}
`;

/**
 * __useEventEventAccessQuery__
 *
 * To run a query within a React component, call `useEventEventAccessQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventEventAccessQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventEventAccessQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEventEventAccessQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<EventEventAccessQuery, EventEventAccessQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<EventEventAccessQuery, EventEventAccessQueryVariables>(
    EventEventAccessDocument,
    options
  );
}
export function useEventEventAccessLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EventEventAccessQuery, EventEventAccessQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<EventEventAccessQuery, EventEventAccessQueryVariables>(
    EventEventAccessDocument,
    options
  );
}
export type EventEventAccessQueryHookResult = ReturnType<typeof useEventEventAccessQuery>;
export type EventEventAccessLazyQueryHookResult = ReturnType<typeof useEventEventAccessLazyQuery>;
export type EventEventAccessQueryResult = Apollo.QueryResult<EventEventAccessQuery, EventEventAccessQueryVariables>;
export const EventOrderDocument = gql`
  query eventOrder($id: Int!) {
    eventOrder(id: $id) {
      ...eventOrder
    }
  }
  ${EventOrderFragmentDoc}
`;

/**
 * __useEventOrderQuery__
 *
 * To run a query within a React component, call `useEventOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventOrderQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEventOrderQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<EventOrderQuery, EventOrderQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<EventOrderQuery, EventOrderQueryVariables>(EventOrderDocument, options);
}
export function useEventOrderLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EventOrderQuery, EventOrderQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<EventOrderQuery, EventOrderQueryVariables>(EventOrderDocument, options);
}
export type EventOrderQueryHookResult = ReturnType<typeof useEventOrderQuery>;
export type EventOrderLazyQueryHookResult = ReturnType<typeof useEventOrderLazyQuery>;
export type EventOrderQueryResult = Apollo.QueryResult<EventOrderQuery, EventOrderQueryVariables>;
export const EventOrderTicketDocument = gql`
  query eventOrderTicket($id: Int!) {
    eventOrderTicket(id: $id) {
      ...eventOrderTicketFull
    }
  }
  ${EventOrderTicketFullFragmentDoc}
`;

/**
 * __useEventOrderTicketQuery__
 *
 * To run a query within a React component, call `useEventOrderTicketQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventOrderTicketQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventOrderTicketQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEventOrderTicketQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<EventOrderTicketQuery, EventOrderTicketQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<EventOrderTicketQuery, EventOrderTicketQueryVariables>(
    EventOrderTicketDocument,
    options
  );
}
export function useEventOrderTicketLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EventOrderTicketQuery, EventOrderTicketQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<EventOrderTicketQuery, EventOrderTicketQueryVariables>(
    EventOrderTicketDocument,
    options
  );
}
export type EventOrderTicketQueryHookResult = ReturnType<typeof useEventOrderTicketQuery>;
export type EventOrderTicketLazyQueryHookResult = ReturnType<typeof useEventOrderTicketLazyQuery>;
export type EventOrderTicketQueryResult = Apollo.QueryResult<EventOrderTicketQuery, EventOrderTicketQueryVariables>;
export const EventPlaceDocument = gql`
  query eventPlace($id: Int!) {
    eventPlace(id: $id) {
      ...eventPlace
    }
  }
  ${EventPlaceFragmentDoc}
`;

/**
 * __useEventPlaceQuery__
 *
 * To run a query within a React component, call `useEventPlaceQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventPlaceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventPlaceQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEventPlaceQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<EventPlaceQuery, EventPlaceQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<EventPlaceQuery, EventPlaceQueryVariables>(EventPlaceDocument, options);
}
export function useEventPlaceLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EventPlaceQuery, EventPlaceQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<EventPlaceQuery, EventPlaceQueryVariables>(EventPlaceDocument, options);
}
export type EventPlaceQueryHookResult = ReturnType<typeof useEventPlaceQuery>;
export type EventPlaceLazyQueryHookResult = ReturnType<typeof useEventPlaceLazyQuery>;
export type EventPlaceQueryResult = Apollo.QueryResult<EventPlaceQuery, EventPlaceQueryVariables>;
export const EventReserveDocument = gql`
  query eventReserve($id: Int!) {
    eventReserve(id: $id) {
      ...eventReserve
    }
  }
  ${EventReserveFragmentDoc}
`;

/**
 * __useEventReserveQuery__
 *
 * To run a query within a React component, call `useEventReserveQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventReserveQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventReserveQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEventReserveQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<EventReserveQuery, EventReserveQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<EventReserveQuery, EventReserveQueryVariables>(EventReserveDocument, options);
}
export function useEventReserveLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EventReserveQuery, EventReserveQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<EventReserveQuery, EventReserveQueryVariables>(EventReserveDocument, options);
}
export type EventReserveQueryHookResult = ReturnType<typeof useEventReserveQuery>;
export type EventReserveLazyQueryHookResult = ReturnType<typeof useEventReserveLazyQuery>;
export type EventReserveQueryResult = Apollo.QueryResult<EventReserveQuery, EventReserveQueryVariables>;
export const EventSectorDocument = gql`
  query eventSector($eventId: Int!, $sectorId: Int!) {
    eventSector(eventId: $eventId, sectorId: $sectorId) {
      ...eventSector
    }
  }
  ${EventSectorFragmentDoc}
`;

/**
 * __useEventSectorQuery__
 *
 * To run a query within a React component, call `useEventSectorQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventSectorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventSectorQuery({
 *   variables: {
 *      eventId: // value for 'eventId'
 *      sectorId: // value for 'sectorId'
 *   },
 * });
 */
export function useEventSectorQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<EventSectorQuery, EventSectorQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<EventSectorQuery, EventSectorQueryVariables>(EventSectorDocument, options);
}
export function useEventSectorLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EventSectorQuery, EventSectorQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<EventSectorQuery, EventSectorQueryVariables>(EventSectorDocument, options);
}
export type EventSectorQueryHookResult = ReturnType<typeof useEventSectorQuery>;
export type EventSectorLazyQueryHookResult = ReturnType<typeof useEventSectorLazyQuery>;
export type EventSectorQueryResult = Apollo.QueryResult<EventSectorQuery, EventSectorQueryVariables>;
export const EventSectorSeatsDocument = gql`
  query eventSectorSeats($eventId: Int!, $sectorId: Int!, $sectorKey: String) {
    eventSectorSeats(eventId: $eventId, sectorId: $sectorId, sectorKey: $sectorKey) {
      ...eventSectorRow
    }
  }
  ${EventSectorRowFragmentDoc}
`;

/**
 * __useEventSectorSeatsQuery__
 *
 * To run a query within a React component, call `useEventSectorSeatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventSectorSeatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventSectorSeatsQuery({
 *   variables: {
 *      eventId: // value for 'eventId'
 *      sectorId: // value for 'sectorId'
 *      sectorKey: // value for 'sectorKey'
 *   },
 * });
 */
export function useEventSectorSeatsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<EventSectorSeatsQuery, EventSectorSeatsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<EventSectorSeatsQuery, EventSectorSeatsQueryVariables>(
    EventSectorSeatsDocument,
    options
  );
}
export function useEventSectorSeatsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EventSectorSeatsQuery, EventSectorSeatsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<EventSectorSeatsQuery, EventSectorSeatsQueryVariables>(
    EventSectorSeatsDocument,
    options
  );
}
export type EventSectorSeatsQueryHookResult = ReturnType<typeof useEventSectorSeatsQuery>;
export type EventSectorSeatsLazyQueryHookResult = ReturnType<typeof useEventSectorSeatsLazyQuery>;
export type EventSectorSeatsQueryResult = Apollo.QueryResult<EventSectorSeatsQuery, EventSectorSeatsQueryVariables>;
export const EventSectorsDocument = gql`
  query eventSectors($eventId: Int!, $sectorId: [Int!]) {
    eventSectors(eventId: $eventId, sectorId: $sectorId) {
      ...eventSector
    }
  }
  ${EventSectorFragmentDoc}
`;

/**
 * __useEventSectorsQuery__
 *
 * To run a query within a React component, call `useEventSectorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventSectorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventSectorsQuery({
 *   variables: {
 *      eventId: // value for 'eventId'
 *      sectorId: // value for 'sectorId'
 *   },
 * });
 */
export function useEventSectorsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<EventSectorsQuery, EventSectorsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<EventSectorsQuery, EventSectorsQueryVariables>(EventSectorsDocument, options);
}
export function useEventSectorsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EventSectorsQuery, EventSectorsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<EventSectorsQuery, EventSectorsQueryVariables>(EventSectorsDocument, options);
}
export type EventSectorsQueryHookResult = ReturnType<typeof useEventSectorsQuery>;
export type EventSectorsLazyQueryHookResult = ReturnType<typeof useEventSectorsLazyQuery>;
export type EventSectorsQueryResult = Apollo.QueryResult<EventSectorsQuery, EventSectorsQueryVariables>;
export const EventTypesConfigDocument = gql`
  query eventTypesConfig {
    eventTypesConfig {
      ...eventTypesConfig
    }
  }
  ${EventTypesConfigFragmentDoc}
`;

/**
 * __useEventTypesConfigQuery__
 *
 * To run a query within a React component, call `useEventTypesConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventTypesConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventTypesConfigQuery({
 *   variables: {
 *   },
 * });
 */
export function useEventTypesConfigQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<EventTypesConfigQuery, EventTypesConfigQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<EventTypesConfigQuery, EventTypesConfigQueryVariables>(
    EventTypesConfigDocument,
    options
  );
}
export function useEventTypesConfigLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EventTypesConfigQuery, EventTypesConfigQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<EventTypesConfigQuery, EventTypesConfigQueryVariables>(
    EventTypesConfigDocument,
    options
  );
}
export type EventTypesConfigQueryHookResult = ReturnType<typeof useEventTypesConfigQuery>;
export type EventTypesConfigLazyQueryHookResult = ReturnType<typeof useEventTypesConfigLazyQuery>;
export type EventTypesConfigQueryResult = Apollo.QueryResult<EventTypesConfigQuery, EventTypesConfigQueryVariables>;
export const EventWidgetActiveEventsDocument = gql`
  query eventWidgetActiveEvents {
    eventWidgetActiveEvents {
      ...eventWidgetActiveEvents
    }
  }
  ${EventWidgetActiveEventsFragmentDoc}
`;

/**
 * __useEventWidgetActiveEventsQuery__
 *
 * To run a query within a React component, call `useEventWidgetActiveEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventWidgetActiveEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventWidgetActiveEventsQuery({
 *   variables: {
 *   },
 * });
 */
export function useEventWidgetActiveEventsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<EventWidgetActiveEventsQuery, EventWidgetActiveEventsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<EventWidgetActiveEventsQuery, EventWidgetActiveEventsQueryVariables>(
    EventWidgetActiveEventsDocument,
    options
  );
}
export function useEventWidgetActiveEventsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    EventWidgetActiveEventsQuery,
    EventWidgetActiveEventsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<EventWidgetActiveEventsQuery, EventWidgetActiveEventsQueryVariables>(
    EventWidgetActiveEventsDocument,
    options
  );
}
export type EventWidgetActiveEventsQueryHookResult = ReturnType<typeof useEventWidgetActiveEventsQuery>;
export type EventWidgetActiveEventsLazyQueryHookResult = ReturnType<typeof useEventWidgetActiveEventsLazyQuery>;
export type EventWidgetActiveEventsQueryResult = Apollo.QueryResult<
  EventWidgetActiveEventsQuery,
  EventWidgetActiveEventsQueryVariables
>;
export const EventWidgetNextEventDocument = gql`
  query eventWidgetNextEvent {
    eventWidgetNextEvent {
      ...eventWidget
    }
  }
  ${EventWidgetFragmentDoc}
`;

/**
 * __useEventWidgetNextEventQuery__
 *
 * To run a query within a React component, call `useEventWidgetNextEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventWidgetNextEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventWidgetNextEventQuery({
 *   variables: {
 *   },
 * });
 */
export function useEventWidgetNextEventQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<EventWidgetNextEventQuery, EventWidgetNextEventQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<EventWidgetNextEventQuery, EventWidgetNextEventQueryVariables>(
    EventWidgetNextEventDocument,
    options
  );
}
export function useEventWidgetNextEventLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EventWidgetNextEventQuery, EventWidgetNextEventQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<EventWidgetNextEventQuery, EventWidgetNextEventQueryVariables>(
    EventWidgetNextEventDocument,
    options
  );
}
export type EventWidgetNextEventQueryHookResult = ReturnType<typeof useEventWidgetNextEventQuery>;
export type EventWidgetNextEventLazyQueryHookResult = ReturnType<typeof useEventWidgetNextEventLazyQuery>;
export type EventWidgetNextEventQueryResult = Apollo.QueryResult<
  EventWidgetNextEventQuery,
  EventWidgetNextEventQueryVariables
>;
export const EventWidgetTicketSalesDocument = gql`
  query eventWidgetTicketSales($query: EventWidgetTicketSalesInput) {
    eventWidgetTicketSales(query: $query) {
      summary {
        ...eventWidgetTicketSalesTotals
      }
      graphs {
        ...commonGraph
      }
    }
  }
  ${EventWidgetTicketSalesTotalsFragmentDoc}
  ${CommonGraphFragmentDoc}
`;

/**
 * __useEventWidgetTicketSalesQuery__
 *
 * To run a query within a React component, call `useEventWidgetTicketSalesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventWidgetTicketSalesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventWidgetTicketSalesQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useEventWidgetTicketSalesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<EventWidgetTicketSalesQuery, EventWidgetTicketSalesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<EventWidgetTicketSalesQuery, EventWidgetTicketSalesQueryVariables>(
    EventWidgetTicketSalesDocument,
    options
  );
}
export function useEventWidgetTicketSalesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EventWidgetTicketSalesQuery, EventWidgetTicketSalesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<EventWidgetTicketSalesQuery, EventWidgetTicketSalesQueryVariables>(
    EventWidgetTicketSalesDocument,
    options
  );
}
export type EventWidgetTicketSalesQueryHookResult = ReturnType<typeof useEventWidgetTicketSalesQuery>;
export type EventWidgetTicketSalesLazyQueryHookResult = ReturnType<typeof useEventWidgetTicketSalesLazyQuery>;
export type EventWidgetTicketSalesQueryResult = Apollo.QueryResult<
  EventWidgetTicketSalesQuery,
  EventWidgetTicketSalesQueryVariables
>;
export const FiltersDocument = gql`
  query filters($queryName: String!) {
    filters(queryName: $queryName) {
      ...filterItem
    }
  }
  ${FilterItemFragmentDoc}
`;

/**
 * __useFiltersQuery__
 *
 * To run a query within a React component, call `useFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFiltersQuery({
 *   variables: {
 *      queryName: // value for 'queryName'
 *   },
 * });
 */
export function useFiltersQuery(baseOptions: ApolloReactHooks.QueryHookOptions<FiltersQuery, FiltersQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<FiltersQuery, FiltersQueryVariables>(FiltersDocument, options);
}
export function useFiltersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FiltersQuery, FiltersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<FiltersQuery, FiltersQueryVariables>(FiltersDocument, options);
}
export type FiltersQueryHookResult = ReturnType<typeof useFiltersQuery>;
export type FiltersLazyQueryHookResult = ReturnType<typeof useFiltersLazyQuery>;
export type FiltersQueryResult = Apollo.QueryResult<FiltersQuery, FiltersQueryVariables>;
export const LoyaltyItemDocument = gql`
  query loyaltyItem($id: Int!) {
    loyaltyItem(id: $id) {
      ...loyaltyItem
    }
  }
  ${LoyaltyItemFragmentDoc}
`;

/**
 * __useLoyaltyItemQuery__
 *
 * To run a query within a React component, call `useLoyaltyItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoyaltyItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoyaltyItemQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLoyaltyItemQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<LoyaltyItemQuery, LoyaltyItemQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<LoyaltyItemQuery, LoyaltyItemQueryVariables>(LoyaltyItemDocument, options);
}
export function useLoyaltyItemLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LoyaltyItemQuery, LoyaltyItemQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<LoyaltyItemQuery, LoyaltyItemQueryVariables>(LoyaltyItemDocument, options);
}
export type LoyaltyItemQueryHookResult = ReturnType<typeof useLoyaltyItemQuery>;
export type LoyaltyItemLazyQueryHookResult = ReturnType<typeof useLoyaltyItemLazyQuery>;
export type LoyaltyItemQueryResult = Apollo.QueryResult<LoyaltyItemQuery, LoyaltyItemQueryVariables>;
export const LoyaltyPartnerDocument = gql`
  query loyaltyPartner($id: Int!) {
    loyaltyPartner(id: $id) {
      ...loyaltyPartner
    }
  }
  ${LoyaltyPartnerFragmentDoc}
`;

/**
 * __useLoyaltyPartnerQuery__
 *
 * To run a query within a React component, call `useLoyaltyPartnerQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoyaltyPartnerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoyaltyPartnerQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLoyaltyPartnerQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<LoyaltyPartnerQuery, LoyaltyPartnerQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<LoyaltyPartnerQuery, LoyaltyPartnerQueryVariables>(LoyaltyPartnerDocument, options);
}
export function useLoyaltyPartnerLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LoyaltyPartnerQuery, LoyaltyPartnerQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<LoyaltyPartnerQuery, LoyaltyPartnerQueryVariables>(
    LoyaltyPartnerDocument,
    options
  );
}
export type LoyaltyPartnerQueryHookResult = ReturnType<typeof useLoyaltyPartnerQuery>;
export type LoyaltyPartnerLazyQueryHookResult = ReturnType<typeof useLoyaltyPartnerLazyQuery>;
export type LoyaltyPartnerQueryResult = Apollo.QueryResult<LoyaltyPartnerQuery, LoyaltyPartnerQueryVariables>;
export const LoyaltyPartnerEntityByProviderCodeDocument = gql`
  query loyaltyPartnerEntityByProviderCode($providerId: Int, $providerCode: String) {
    loyaltyPartnerEntityByProviderCode(providerId: $providerId, providerCode: $providerCode)
  }
`;

/**
 * __useLoyaltyPartnerEntityByProviderCodeQuery__
 *
 * To run a query within a React component, call `useLoyaltyPartnerEntityByProviderCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoyaltyPartnerEntityByProviderCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoyaltyPartnerEntityByProviderCodeQuery({
 *   variables: {
 *      providerId: // value for 'providerId'
 *      providerCode: // value for 'providerCode'
 *   },
 * });
 */
export function useLoyaltyPartnerEntityByProviderCodeQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    LoyaltyPartnerEntityByProviderCodeQuery,
    LoyaltyPartnerEntityByProviderCodeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    LoyaltyPartnerEntityByProviderCodeQuery,
    LoyaltyPartnerEntityByProviderCodeQueryVariables
  >(LoyaltyPartnerEntityByProviderCodeDocument, options);
}
export function useLoyaltyPartnerEntityByProviderCodeLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    LoyaltyPartnerEntityByProviderCodeQuery,
    LoyaltyPartnerEntityByProviderCodeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    LoyaltyPartnerEntityByProviderCodeQuery,
    LoyaltyPartnerEntityByProviderCodeQueryVariables
  >(LoyaltyPartnerEntityByProviderCodeDocument, options);
}
export type LoyaltyPartnerEntityByProviderCodeQueryHookResult = ReturnType<
  typeof useLoyaltyPartnerEntityByProviderCodeQuery
>;
export type LoyaltyPartnerEntityByProviderCodeLazyQueryHookResult = ReturnType<
  typeof useLoyaltyPartnerEntityByProviderCodeLazyQuery
>;
export type LoyaltyPartnerEntityByProviderCodeQueryResult = Apollo.QueryResult<
  LoyaltyPartnerEntityByProviderCodeQuery,
  LoyaltyPartnerEntityByProviderCodeQueryVariables
>;
export const LoyaltyPromoDocument = gql`
  query loyaltyPromo($id: Int!) {
    loyaltyPromo(id: $id) {
      ...loyaltyPromo
    }
  }
  ${LoyaltyPromoFragmentDoc}
`;

/**
 * __useLoyaltyPromoQuery__
 *
 * To run a query within a React component, call `useLoyaltyPromoQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoyaltyPromoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoyaltyPromoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLoyaltyPromoQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<LoyaltyPromoQuery, LoyaltyPromoQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<LoyaltyPromoQuery, LoyaltyPromoQueryVariables>(LoyaltyPromoDocument, options);
}
export function useLoyaltyPromoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LoyaltyPromoQuery, LoyaltyPromoQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<LoyaltyPromoQuery, LoyaltyPromoQueryVariables>(LoyaltyPromoDocument, options);
}
export type LoyaltyPromoQueryHookResult = ReturnType<typeof useLoyaltyPromoQuery>;
export type LoyaltyPromoLazyQueryHookResult = ReturnType<typeof useLoyaltyPromoLazyQuery>;
export type LoyaltyPromoQueryResult = Apollo.QueryResult<LoyaltyPromoQuery, LoyaltyPromoQueryVariables>;
export const LoyaltyPromoCodeDocument = gql`
  query loyaltyPromoCode($id: Int!) {
    loyaltyPromoCode(id: $id) {
      ...loyaltyPromoCode
    }
  }
  ${LoyaltyPromoCodeFragmentDoc}
`;

/**
 * __useLoyaltyPromoCodeQuery__
 *
 * To run a query within a React component, call `useLoyaltyPromoCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoyaltyPromoCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoyaltyPromoCodeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLoyaltyPromoCodeQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<LoyaltyPromoCodeQuery, LoyaltyPromoCodeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<LoyaltyPromoCodeQuery, LoyaltyPromoCodeQueryVariables>(
    LoyaltyPromoCodeDocument,
    options
  );
}
export function useLoyaltyPromoCodeLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LoyaltyPromoCodeQuery, LoyaltyPromoCodeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<LoyaltyPromoCodeQuery, LoyaltyPromoCodeQueryVariables>(
    LoyaltyPromoCodeDocument,
    options
  );
}
export type LoyaltyPromoCodeQueryHookResult = ReturnType<typeof useLoyaltyPromoCodeQuery>;
export type LoyaltyPromoCodeLazyQueryHookResult = ReturnType<typeof useLoyaltyPromoCodeLazyQuery>;
export type LoyaltyPromoCodeQueryResult = Apollo.QueryResult<LoyaltyPromoCodeQuery, LoyaltyPromoCodeQueryVariables>;
export const LoyaltySummaryWidgetDocument = gql`
  query loyaltySummaryWidget($where: LoyaltySummaryWidgetInput) {
    loyaltySummaryWidget(where: $where) {
      ...loyaltySummaryWidget
    }
  }
  ${LoyaltySummaryWidgetFragmentDoc}
`;

/**
 * __useLoyaltySummaryWidgetQuery__
 *
 * To run a query within a React component, call `useLoyaltySummaryWidgetQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoyaltySummaryWidgetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoyaltySummaryWidgetQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useLoyaltySummaryWidgetQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<LoyaltySummaryWidgetQuery, LoyaltySummaryWidgetQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<LoyaltySummaryWidgetQuery, LoyaltySummaryWidgetQueryVariables>(
    LoyaltySummaryWidgetDocument,
    options
  );
}
export function useLoyaltySummaryWidgetLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LoyaltySummaryWidgetQuery, LoyaltySummaryWidgetQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<LoyaltySummaryWidgetQuery, LoyaltySummaryWidgetQueryVariables>(
    LoyaltySummaryWidgetDocument,
    options
  );
}
export type LoyaltySummaryWidgetQueryHookResult = ReturnType<typeof useLoyaltySummaryWidgetQuery>;
export type LoyaltySummaryWidgetLazyQueryHookResult = ReturnType<typeof useLoyaltySummaryWidgetLazyQuery>;
export type LoyaltySummaryWidgetQueryResult = Apollo.QueryResult<
  LoyaltySummaryWidgetQuery,
  LoyaltySummaryWidgetQueryVariables
>;
export const MessengerMailingDocument = gql`
  query messengerMailing($id: Int!) {
    messengerMailing(id: $id) {
      ...messengerMailing
    }
  }
  ${MessengerMailingFragmentDoc}
`;

/**
 * __useMessengerMailingQuery__
 *
 * To run a query within a React component, call `useMessengerMailingQuery` and pass it any options that fit your needs.
 * When your component renders, `useMessengerMailingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMessengerMailingQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMessengerMailingQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<MessengerMailingQuery, MessengerMailingQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<MessengerMailingQuery, MessengerMailingQueryVariables>(
    MessengerMailingDocument,
    options
  );
}
export function useMessengerMailingLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MessengerMailingQuery, MessengerMailingQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<MessengerMailingQuery, MessengerMailingQueryVariables>(
    MessengerMailingDocument,
    options
  );
}
export type MessengerMailingQueryHookResult = ReturnType<typeof useMessengerMailingQuery>;
export type MessengerMailingLazyQueryHookResult = ReturnType<typeof useMessengerMailingLazyQuery>;
export type MessengerMailingQueryResult = Apollo.QueryResult<MessengerMailingQuery, MessengerMailingQueryVariables>;
export const MessengerMailingChannelsDocument = gql`
  query messengerMailingChannels {
    messengerMailingChannels {
      channel
      value
    }
  }
`;

/**
 * __useMessengerMailingChannelsQuery__
 *
 * To run a query within a React component, call `useMessengerMailingChannelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMessengerMailingChannelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMessengerMailingChannelsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMessengerMailingChannelsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<MessengerMailingChannelsQuery, MessengerMailingChannelsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<MessengerMailingChannelsQuery, MessengerMailingChannelsQueryVariables>(
    MessengerMailingChannelsDocument,
    options
  );
}
export function useMessengerMailingChannelsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    MessengerMailingChannelsQuery,
    MessengerMailingChannelsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<MessengerMailingChannelsQuery, MessengerMailingChannelsQueryVariables>(
    MessengerMailingChannelsDocument,
    options
  );
}
export type MessengerMailingChannelsQueryHookResult = ReturnType<typeof useMessengerMailingChannelsQuery>;
export type MessengerMailingChannelsLazyQueryHookResult = ReturnType<typeof useMessengerMailingChannelsLazyQuery>;
export type MessengerMailingChannelsQueryResult = Apollo.QueryResult<
  MessengerMailingChannelsQuery,
  MessengerMailingChannelsQueryVariables
>;
export const MessengerMailingStatDocument = gql`
  query messengerMailingStat($mailingId: Int) {
    messengerMailingStat(mailingId: $mailingId) {
      state
      value
    }
  }
`;

/**
 * __useMessengerMailingStatQuery__
 *
 * To run a query within a React component, call `useMessengerMailingStatQuery` and pass it any options that fit your needs.
 * When your component renders, `useMessengerMailingStatQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMessengerMailingStatQuery({
 *   variables: {
 *      mailingId: // value for 'mailingId'
 *   },
 * });
 */
export function useMessengerMailingStatQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<MessengerMailingStatQuery, MessengerMailingStatQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<MessengerMailingStatQuery, MessengerMailingStatQueryVariables>(
    MessengerMailingStatDocument,
    options
  );
}
export function useMessengerMailingStatLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MessengerMailingStatQuery, MessengerMailingStatQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<MessengerMailingStatQuery, MessengerMailingStatQueryVariables>(
    MessengerMailingStatDocument,
    options
  );
}
export type MessengerMailingStatQueryHookResult = ReturnType<typeof useMessengerMailingStatQuery>;
export type MessengerMailingStatLazyQueryHookResult = ReturnType<typeof useMessengerMailingStatLazyQuery>;
export type MessengerMailingStatQueryResult = Apollo.QueryResult<
  MessengerMailingStatQuery,
  MessengerMailingStatQueryVariables
>;
export const MessengerQueueNotificationDocument = gql`
  query messengerQueueNotification($id: String!) {
    messengerQueueNotification(id: $id) {
      ...messengerQueueNotification
    }
  }
  ${MessengerQueueNotificationFragmentDoc}
`;

/**
 * __useMessengerQueueNotificationQuery__
 *
 * To run a query within a React component, call `useMessengerQueueNotificationQuery` and pass it any options that fit your needs.
 * When your component renders, `useMessengerQueueNotificationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMessengerQueueNotificationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMessengerQueueNotificationQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    MessengerQueueNotificationQuery,
    MessengerQueueNotificationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<MessengerQueueNotificationQuery, MessengerQueueNotificationQueryVariables>(
    MessengerQueueNotificationDocument,
    options
  );
}
export function useMessengerQueueNotificationLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    MessengerQueueNotificationQuery,
    MessengerQueueNotificationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<MessengerQueueNotificationQuery, MessengerQueueNotificationQueryVariables>(
    MessengerQueueNotificationDocument,
    options
  );
}
export type MessengerQueueNotificationQueryHookResult = ReturnType<typeof useMessengerQueueNotificationQuery>;
export type MessengerQueueNotificationLazyQueryHookResult = ReturnType<typeof useMessengerQueueNotificationLazyQuery>;
export type MessengerQueueNotificationQueryResult = Apollo.QueryResult<
  MessengerQueueNotificationQuery,
  MessengerQueueNotificationQueryVariables
>;
export const MessengerQueueTaskDocument = gql`
  query messengerQueueTask($id: Int!) {
    messengerQueueTask(id: $id) {
      ...messengerQueueTask
    }
  }
  ${MessengerQueueTaskFragmentDoc}
`;

/**
 * __useMessengerQueueTaskQuery__
 *
 * To run a query within a React component, call `useMessengerQueueTaskQuery` and pass it any options that fit your needs.
 * When your component renders, `useMessengerQueueTaskQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMessengerQueueTaskQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMessengerQueueTaskQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<MessengerQueueTaskQuery, MessengerQueueTaskQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<MessengerQueueTaskQuery, MessengerQueueTaskQueryVariables>(
    MessengerQueueTaskDocument,
    options
  );
}
export function useMessengerQueueTaskLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MessengerQueueTaskQuery, MessengerQueueTaskQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<MessengerQueueTaskQuery, MessengerQueueTaskQueryVariables>(
    MessengerQueueTaskDocument,
    options
  );
}
export type MessengerQueueTaskQueryHookResult = ReturnType<typeof useMessengerQueueTaskQuery>;
export type MessengerQueueTaskLazyQueryHookResult = ReturnType<typeof useMessengerQueueTaskLazyQuery>;
export type MessengerQueueTaskQueryResult = Apollo.QueryResult<
  MessengerQueueTaskQuery,
  MessengerQueueTaskQueryVariables
>;
export const MessengerSettingDocument = gql`
  query messengerSetting {
    messengerSetting {
      ...messengerSetting
    }
  }
  ${MessengerSettingFragmentDoc}
`;

/**
 * __useMessengerSettingQuery__
 *
 * To run a query within a React component, call `useMessengerSettingQuery` and pass it any options that fit your needs.
 * When your component renders, `useMessengerSettingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMessengerSettingQuery({
 *   variables: {
 *   },
 * });
 */
export function useMessengerSettingQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<MessengerSettingQuery, MessengerSettingQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<MessengerSettingQuery, MessengerSettingQueryVariables>(
    MessengerSettingDocument,
    options
  );
}
export function useMessengerSettingLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MessengerSettingQuery, MessengerSettingQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<MessengerSettingQuery, MessengerSettingQueryVariables>(
    MessengerSettingDocument,
    options
  );
}
export type MessengerSettingQueryHookResult = ReturnType<typeof useMessengerSettingQuery>;
export type MessengerSettingLazyQueryHookResult = ReturnType<typeof useMessengerSettingLazyQuery>;
export type MessengerSettingQueryResult = Apollo.QueryResult<MessengerSettingQuery, MessengerSettingQueryVariables>;
export const MessengerTransportDocument = gql`
  query messengerTransport($id: Int!) {
    messengerTransport(id: $id) {
      ...messengerTransport
    }
  }
  ${MessengerTransportFragmentDoc}
`;

/**
 * __useMessengerTransportQuery__
 *
 * To run a query within a React component, call `useMessengerTransportQuery` and pass it any options that fit your needs.
 * When your component renders, `useMessengerTransportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMessengerTransportQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMessengerTransportQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<MessengerTransportQuery, MessengerTransportQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<MessengerTransportQuery, MessengerTransportQueryVariables>(
    MessengerTransportDocument,
    options
  );
}
export function useMessengerTransportLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MessengerTransportQuery, MessengerTransportQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<MessengerTransportQuery, MessengerTransportQueryVariables>(
    MessengerTransportDocument,
    options
  );
}
export type MessengerTransportQueryHookResult = ReturnType<typeof useMessengerTransportQuery>;
export type MessengerTransportLazyQueryHookResult = ReturnType<typeof useMessengerTransportLazyQuery>;
export type MessengerTransportQueryResult = Apollo.QueryResult<
  MessengerTransportQuery,
  MessengerTransportQueryVariables
>;
export const MobileTranslationDocument = gql`
  query mobileTranslation($id: Int!) {
    mobileTranslation(id: $id) {
      ...mobileTranslation
    }
  }
  ${MobileTranslationFragmentDoc}
`;

/**
 * __useMobileTranslationQuery__
 *
 * To run a query within a React component, call `useMobileTranslationQuery` and pass it any options that fit your needs.
 * When your component renders, `useMobileTranslationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMobileTranslationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMobileTranslationQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<MobileTranslationQuery, MobileTranslationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<MobileTranslationQuery, MobileTranslationQueryVariables>(
    MobileTranslationDocument,
    options
  );
}
export function useMobileTranslationLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MobileTranslationQuery, MobileTranslationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<MobileTranslationQuery, MobileTranslationQueryVariables>(
    MobileTranslationDocument,
    options
  );
}
export type MobileTranslationQueryHookResult = ReturnType<typeof useMobileTranslationQuery>;
export type MobileTranslationLazyQueryHookResult = ReturnType<typeof useMobileTranslationLazyQuery>;
export type MobileTranslationQueryResult = Apollo.QueryResult<MobileTranslationQuery, MobileTranslationQueryVariables>;
export const MyApplicationsFamilySectorDocument = gql`
  query myApplicationsFamilySector {
    myApplicationsFamilySector {
      ...userApplication
    }
  }
  ${UserApplicationFragmentDoc}
`;

/**
 * __useMyApplicationsFamilySectorQuery__
 *
 * To run a query within a React component, call `useMyApplicationsFamilySectorQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyApplicationsFamilySectorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyApplicationsFamilySectorQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyApplicationsFamilySectorQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    MyApplicationsFamilySectorQuery,
    MyApplicationsFamilySectorQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<MyApplicationsFamilySectorQuery, MyApplicationsFamilySectorQueryVariables>(
    MyApplicationsFamilySectorDocument,
    options
  );
}
export function useMyApplicationsFamilySectorLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    MyApplicationsFamilySectorQuery,
    MyApplicationsFamilySectorQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<MyApplicationsFamilySectorQuery, MyApplicationsFamilySectorQueryVariables>(
    MyApplicationsFamilySectorDocument,
    options
  );
}
export type MyApplicationsFamilySectorQueryHookResult = ReturnType<typeof useMyApplicationsFamilySectorQuery>;
export type MyApplicationsFamilySectorLazyQueryHookResult = ReturnType<typeof useMyApplicationsFamilySectorLazyQuery>;
export type MyApplicationsFamilySectorQueryResult = Apollo.QueryResult<
  MyApplicationsFamilySectorQuery,
  MyApplicationsFamilySectorQueryVariables
>;
export const MyEventOrderDocument = gql`
  query myEventOrder($id: Int!) {
    myEventOrder(id: $id) {
      ...eventOrder
    }
  }
  ${EventOrderFragmentDoc}
`;

/**
 * __useMyEventOrderQuery__
 *
 * To run a query within a React component, call `useMyEventOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyEventOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyEventOrderQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMyEventOrderQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<MyEventOrderQuery, MyEventOrderQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<MyEventOrderQuery, MyEventOrderQueryVariables>(MyEventOrderDocument, options);
}
export function useMyEventOrderLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MyEventOrderQuery, MyEventOrderQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<MyEventOrderQuery, MyEventOrderQueryVariables>(MyEventOrderDocument, options);
}
export type MyEventOrderQueryHookResult = ReturnType<typeof useMyEventOrderQuery>;
export type MyEventOrderLazyQueryHookResult = ReturnType<typeof useMyEventOrderLazyQuery>;
export type MyEventOrderQueryResult = Apollo.QueryResult<MyEventOrderQuery, MyEventOrderQueryVariables>;
export const MyEventReservesDocument = gql`
  query myEventReserves {
    myEventReserves {
      ...reserve
    }
  }
  ${ReserveFragmentDoc}
`;

/**
 * __useMyEventReservesQuery__
 *
 * To run a query within a React component, call `useMyEventReservesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyEventReservesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyEventReservesQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyEventReservesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<MyEventReservesQuery, MyEventReservesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<MyEventReservesQuery, MyEventReservesQueryVariables>(
    MyEventReservesDocument,
    options
  );
}
export function useMyEventReservesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MyEventReservesQuery, MyEventReservesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<MyEventReservesQuery, MyEventReservesQueryVariables>(
    MyEventReservesDocument,
    options
  );
}
export type MyEventReservesQueryHookResult = ReturnType<typeof useMyEventReservesQuery>;
export type MyEventReservesLazyQueryHookResult = ReturnType<typeof useMyEventReservesLazyQuery>;
export type MyEventReservesQueryResult = Apollo.QueryResult<MyEventReservesQuery, MyEventReservesQueryVariables>;
export const NavigationDocument = gql`
  query navigation {
    navigation {
      ...navigation
    }
  }
  ${NavigationFragmentDoc}
`;

/**
 * __useNavigationQuery__
 *
 * To run a query within a React component, call `useNavigationQuery` and pass it any options that fit your needs.
 * When your component renders, `useNavigationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNavigationQuery({
 *   variables: {
 *   },
 * });
 */
export function useNavigationQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<NavigationQuery, NavigationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<NavigationQuery, NavigationQueryVariables>(NavigationDocument, options);
}
export function useNavigationLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<NavigationQuery, NavigationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<NavigationQuery, NavigationQueryVariables>(NavigationDocument, options);
}
export type NavigationQueryHookResult = ReturnType<typeof useNavigationQuery>;
export type NavigationLazyQueryHookResult = ReturnType<typeof useNavigationLazyQuery>;
export type NavigationQueryResult = Apollo.QueryResult<NavigationQuery, NavigationQueryVariables>;
export const PaymentAccountDocument = gql`
  query paymentAccount($id: Int!) {
    paymentAccount(id: $id) {
      ...paymentAccount
    }
  }
  ${PaymentAccountFragmentDoc}
`;

/**
 * __usePaymentAccountQuery__
 *
 * To run a query within a React component, call `usePaymentAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentAccountQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePaymentAccountQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<PaymentAccountQuery, PaymentAccountQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<PaymentAccountQuery, PaymentAccountQueryVariables>(PaymentAccountDocument, options);
}
export function usePaymentAccountLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PaymentAccountQuery, PaymentAccountQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<PaymentAccountQuery, PaymentAccountQueryVariables>(
    PaymentAccountDocument,
    options
  );
}
export type PaymentAccountQueryHookResult = ReturnType<typeof usePaymentAccountQuery>;
export type PaymentAccountLazyQueryHookResult = ReturnType<typeof usePaymentAccountLazyQuery>;
export type PaymentAccountQueryResult = Apollo.QueryResult<PaymentAccountQuery, PaymentAccountQueryVariables>;
export const PaymentCurrencyDocument = gql`
  query paymentCurrency($id: Int!) {
    paymentCurrency(id: $id) {
      ...paymentCurrency
    }
  }
  ${PaymentCurrencyFragmentDoc}
`;

/**
 * __usePaymentCurrencyQuery__
 *
 * To run a query within a React component, call `usePaymentCurrencyQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentCurrencyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentCurrencyQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePaymentCurrencyQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<PaymentCurrencyQuery, PaymentCurrencyQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<PaymentCurrencyQuery, PaymentCurrencyQueryVariables>(
    PaymentCurrencyDocument,
    options
  );
}
export function usePaymentCurrencyLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PaymentCurrencyQuery, PaymentCurrencyQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<PaymentCurrencyQuery, PaymentCurrencyQueryVariables>(
    PaymentCurrencyDocument,
    options
  );
}
export type PaymentCurrencyQueryHookResult = ReturnType<typeof usePaymentCurrencyQuery>;
export type PaymentCurrencyLazyQueryHookResult = ReturnType<typeof usePaymentCurrencyLazyQuery>;
export type PaymentCurrencyQueryResult = Apollo.QueryResult<PaymentCurrencyQuery, PaymentCurrencyQueryVariables>;
export const PaymentProviderDocument = gql`
  query paymentProvider($id: Int!) {
    paymentProvider(id: $id) {
      ...paymentProvider
    }
  }
  ${PaymentProviderFragmentDoc}
`;

/**
 * __usePaymentProviderQuery__
 *
 * To run a query within a React component, call `usePaymentProviderQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentProviderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentProviderQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePaymentProviderQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<PaymentProviderQuery, PaymentProviderQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<PaymentProviderQuery, PaymentProviderQueryVariables>(
    PaymentProviderDocument,
    options
  );
}
export function usePaymentProviderLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PaymentProviderQuery, PaymentProviderQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<PaymentProviderQuery, PaymentProviderQueryVariables>(
    PaymentProviderDocument,
    options
  );
}
export type PaymentProviderQueryHookResult = ReturnType<typeof usePaymentProviderQuery>;
export type PaymentProviderLazyQueryHookResult = ReturnType<typeof usePaymentProviderLazyQuery>;
export type PaymentProviderQueryResult = Apollo.QueryResult<PaymentProviderQuery, PaymentProviderQueryVariables>;
export const PaymentTransactionDocument = gql`
  query paymentTransaction($id: Int!) {
    paymentTransaction(id: $id) {
      ...paymentTransaction
    }
  }
  ${PaymentTransactionFragmentDoc}
`;

/**
 * __usePaymentTransactionQuery__
 *
 * To run a query within a React component, call `usePaymentTransactionQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentTransactionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentTransactionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePaymentTransactionQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<PaymentTransactionQuery, PaymentTransactionQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<PaymentTransactionQuery, PaymentTransactionQueryVariables>(
    PaymentTransactionDocument,
    options
  );
}
export function usePaymentTransactionLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PaymentTransactionQuery, PaymentTransactionQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<PaymentTransactionQuery, PaymentTransactionQueryVariables>(
    PaymentTransactionDocument,
    options
  );
}
export type PaymentTransactionQueryHookResult = ReturnType<typeof usePaymentTransactionQuery>;
export type PaymentTransactionLazyQueryHookResult = ReturnType<typeof usePaymentTransactionLazyQuery>;
export type PaymentTransactionQueryResult = Apollo.QueryResult<
  PaymentTransactionQuery,
  PaymentTransactionQueryVariables
>;
export const RedirectAnalyticsDocument = gql`
  query redirectAnalytics($data: RedirectAnalyticsInput!) {
    redirectAnalytics(data: $data) {
      utmCampaign
      redirectsCount
    }
  }
`;

/**
 * __useRedirectAnalyticsQuery__
 *
 * To run a query within a React component, call `useRedirectAnalyticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRedirectAnalyticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRedirectAnalyticsQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useRedirectAnalyticsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<RedirectAnalyticsQuery, RedirectAnalyticsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<RedirectAnalyticsQuery, RedirectAnalyticsQueryVariables>(
    RedirectAnalyticsDocument,
    options
  );
}
export function useRedirectAnalyticsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RedirectAnalyticsQuery, RedirectAnalyticsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<RedirectAnalyticsQuery, RedirectAnalyticsQueryVariables>(
    RedirectAnalyticsDocument,
    options
  );
}
export type RedirectAnalyticsQueryHookResult = ReturnType<typeof useRedirectAnalyticsQuery>;
export type RedirectAnalyticsLazyQueryHookResult = ReturnType<typeof useRedirectAnalyticsLazyQuery>;
export type RedirectAnalyticsQueryResult = Apollo.QueryResult<RedirectAnalyticsQuery, RedirectAnalyticsQueryVariables>;
export const SystemLocaleDocument = gql`
  query systemLocale($id: Int!) {
    systemLocale(id: $id) {
      ...systemLocale
    }
  }
  ${SystemLocaleFragmentDoc}
`;

/**
 * __useSystemLocaleQuery__
 *
 * To run a query within a React component, call `useSystemLocaleQuery` and pass it any options that fit your needs.
 * When your component renders, `useSystemLocaleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSystemLocaleQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSystemLocaleQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<SystemLocaleQuery, SystemLocaleQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<SystemLocaleQuery, SystemLocaleQueryVariables>(SystemLocaleDocument, options);
}
export function useSystemLocaleLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SystemLocaleQuery, SystemLocaleQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<SystemLocaleQuery, SystemLocaleQueryVariables>(SystemLocaleDocument, options);
}
export type SystemLocaleQueryHookResult = ReturnType<typeof useSystemLocaleQuery>;
export type SystemLocaleLazyQueryHookResult = ReturnType<typeof useSystemLocaleLazyQuery>;
export type SystemLocaleQueryResult = Apollo.QueryResult<SystemLocaleQuery, SystemLocaleQueryVariables>;
export const SystemPrinterDocument = gql`
  query systemPrinter($id: Int!) {
    systemPrinter(id: $id) {
      ...systemPrinter
    }
  }
  ${SystemPrinterFragmentDoc}
`;

/**
 * __useSystemPrinterQuery__
 *
 * To run a query within a React component, call `useSystemPrinterQuery` and pass it any options that fit your needs.
 * When your component renders, `useSystemPrinterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSystemPrinterQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSystemPrinterQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<SystemPrinterQuery, SystemPrinterQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<SystemPrinterQuery, SystemPrinterQueryVariables>(SystemPrinterDocument, options);
}
export function useSystemPrinterLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SystemPrinterQuery, SystemPrinterQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<SystemPrinterQuery, SystemPrinterQueryVariables>(SystemPrinterDocument, options);
}
export type SystemPrinterQueryHookResult = ReturnType<typeof useSystemPrinterQuery>;
export type SystemPrinterLazyQueryHookResult = ReturnType<typeof useSystemPrinterLazyQuery>;
export type SystemPrinterQueryResult = Apollo.QueryResult<SystemPrinterQuery, SystemPrinterQueryVariables>;
export const SystemTranslationDocument = gql`
  query systemTranslation($id: Int!) {
    systemTranslation(id: $id) {
      ...systemTranslation
    }
  }
  ${SystemTranslationFragmentDoc}
`;

/**
 * __useSystemTranslationQuery__
 *
 * To run a query within a React component, call `useSystemTranslationQuery` and pass it any options that fit your needs.
 * When your component renders, `useSystemTranslationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSystemTranslationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSystemTranslationQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<SystemTranslationQuery, SystemTranslationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<SystemTranslationQuery, SystemTranslationQueryVariables>(
    SystemTranslationDocument,
    options
  );
}
export function useSystemTranslationLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SystemTranslationQuery, SystemTranslationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<SystemTranslationQuery, SystemTranslationQueryVariables>(
    SystemTranslationDocument,
    options
  );
}
export type SystemTranslationQueryHookResult = ReturnType<typeof useSystemTranslationQuery>;
export type SystemTranslationLazyQueryHookResult = ReturnType<typeof useSystemTranslationLazyQuery>;
export type SystemTranslationQueryResult = Apollo.QueryResult<SystemTranslationQuery, SystemTranslationQueryVariables>;
export const SystemTranslationsDocument = gql`
  query systemTranslations($query: DefaultQueryInput) {
    systemTranslations(query: $query) {
      ...systemTranslation
    }
  }
  ${SystemTranslationFragmentDoc}
`;

/**
 * __useSystemTranslationsQuery__
 *
 * To run a query within a React component, call `useSystemTranslationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSystemTranslationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSystemTranslationsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useSystemTranslationsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<SystemTranslationsQuery, SystemTranslationsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<SystemTranslationsQuery, SystemTranslationsQueryVariables>(
    SystemTranslationsDocument,
    options
  );
}
export function useSystemTranslationsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SystemTranslationsQuery, SystemTranslationsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<SystemTranslationsQuery, SystemTranslationsQueryVariables>(
    SystemTranslationsDocument,
    options
  );
}
export type SystemTranslationsQueryHookResult = ReturnType<typeof useSystemTranslationsQuery>;
export type SystemTranslationsLazyQueryHookResult = ReturnType<typeof useSystemTranslationsLazyQuery>;
export type SystemTranslationsQueryResult = Apollo.QueryResult<
  SystemTranslationsQuery,
  SystemTranslationsQueryVariables
>;
export const SystemUserDocument = gql`
  query systemUser($id: ID!) {
    systemUser(id: $id) {
      ...systemUser
    }
  }
  ${SystemUserFragmentDoc}
`;

/**
 * __useSystemUserQuery__
 *
 * To run a query within a React component, call `useSystemUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useSystemUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSystemUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSystemUserQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<SystemUserQuery, SystemUserQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<SystemUserQuery, SystemUserQueryVariables>(SystemUserDocument, options);
}
export function useSystemUserLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SystemUserQuery, SystemUserQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<SystemUserQuery, SystemUserQueryVariables>(SystemUserDocument, options);
}
export type SystemUserQueryHookResult = ReturnType<typeof useSystemUserQuery>;
export type SystemUserLazyQueryHookResult = ReturnType<typeof useSystemUserLazyQuery>;
export type SystemUserQueryResult = Apollo.QueryResult<SystemUserQuery, SystemUserQueryVariables>;
export const SystemUserAclDocument = gql`
  query systemUserACL($userId: ID!, $clientId: ID!) {
    systemUserACL(userId: $userId, clientId: $clientId) {
      ...acl
    }
  }
  ${AclFragmentDoc}
`;

/**
 * __useSystemUserAclQuery__
 *
 * To run a query within a React component, call `useSystemUserAclQuery` and pass it any options that fit your needs.
 * When your component renders, `useSystemUserAclQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSystemUserAclQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      clientId: // value for 'clientId'
 *   },
 * });
 */
export function useSystemUserAclQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<SystemUserAclQuery, SystemUserAclQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<SystemUserAclQuery, SystemUserAclQueryVariables>(SystemUserAclDocument, options);
}
export function useSystemUserAclLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SystemUserAclQuery, SystemUserAclQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<SystemUserAclQuery, SystemUserAclQueryVariables>(SystemUserAclDocument, options);
}
export type SystemUserAclQueryHookResult = ReturnType<typeof useSystemUserAclQuery>;
export type SystemUserAclLazyQueryHookResult = ReturnType<typeof useSystemUserAclLazyQuery>;
export type SystemUserAclQueryResult = Apollo.QueryResult<SystemUserAclQuery, SystemUserAclQueryVariables>;
export const SystemUserProfileDocument = gql`
  query systemUserProfile {
    systemUserProfile {
      ...systemUser
    }
  }
  ${SystemUserFragmentDoc}
`;

/**
 * __useSystemUserProfileQuery__
 *
 * To run a query within a React component, call `useSystemUserProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useSystemUserProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSystemUserProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useSystemUserProfileQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<SystemUserProfileQuery, SystemUserProfileQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<SystemUserProfileQuery, SystemUserProfileQueryVariables>(
    SystemUserProfileDocument,
    options
  );
}
export function useSystemUserProfileLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SystemUserProfileQuery, SystemUserProfileQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<SystemUserProfileQuery, SystemUserProfileQueryVariables>(
    SystemUserProfileDocument,
    options
  );
}
export type SystemUserProfileQueryHookResult = ReturnType<typeof useSystemUserProfileQuery>;
export type SystemUserProfileLazyQueryHookResult = ReturnType<typeof useSystemUserProfileLazyQuery>;
export type SystemUserProfileQueryResult = Apollo.QueryResult<SystemUserProfileQuery, SystemUserProfileQueryVariables>;
export const SystemWebHookDocument = gql`
  query systemWebHook($id: Int!) {
    systemWebHook(id: $id) {
      ...systemWebHook
    }
  }
  ${SystemWebHookFragmentDoc}
`;

/**
 * __useSystemWebHookQuery__
 *
 * To run a query within a React component, call `useSystemWebHookQuery` and pass it any options that fit your needs.
 * When your component renders, `useSystemWebHookQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSystemWebHookQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSystemWebHookQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<SystemWebHookQuery, SystemWebHookQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<SystemWebHookQuery, SystemWebHookQueryVariables>(SystemWebHookDocument, options);
}
export function useSystemWebHookLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SystemWebHookQuery, SystemWebHookQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<SystemWebHookQuery, SystemWebHookQueryVariables>(SystemWebHookDocument, options);
}
export type SystemWebHookQueryHookResult = ReturnType<typeof useSystemWebHookQuery>;
export type SystemWebHookLazyQueryHookResult = ReturnType<typeof useSystemWebHookLazyQuery>;
export type SystemWebHookQueryResult = Apollo.QueryResult<SystemWebHookQuery, SystemWebHookQueryVariables>;
export const TemplateApplePassDocument = gql`
  query templateApplePass($id: Int!) {
    templateApplePass(id: $id) {
      ...templateApplePass
    }
  }
  ${TemplateApplePassFragmentDoc}
`;

/**
 * __useTemplateApplePassQuery__
 *
 * To run a query within a React component, call `useTemplateApplePassQuery` and pass it any options that fit your needs.
 * When your component renders, `useTemplateApplePassQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTemplateApplePassQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTemplateApplePassQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<TemplateApplePassQuery, TemplateApplePassQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<TemplateApplePassQuery, TemplateApplePassQueryVariables>(
    TemplateApplePassDocument,
    options
  );
}
export function useTemplateApplePassLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TemplateApplePassQuery, TemplateApplePassQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<TemplateApplePassQuery, TemplateApplePassQueryVariables>(
    TemplateApplePassDocument,
    options
  );
}
export type TemplateApplePassQueryHookResult = ReturnType<typeof useTemplateApplePassQuery>;
export type TemplateApplePassLazyQueryHookResult = ReturnType<typeof useTemplateApplePassLazyQuery>;
export type TemplateApplePassQueryResult = Apollo.QueryResult<TemplateApplePassQuery, TemplateApplePassQueryVariables>;
export const TemplateNotificationDocument = gql`
  query templateNotification($id: Int!) {
    templateNotification(id: $id) {
      ...templateNotification
    }
  }
  ${TemplateNotificationFragmentDoc}
`;

/**
 * __useTemplateNotificationQuery__
 *
 * To run a query within a React component, call `useTemplateNotificationQuery` and pass it any options that fit your needs.
 * When your component renders, `useTemplateNotificationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTemplateNotificationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTemplateNotificationQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<TemplateNotificationQuery, TemplateNotificationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<TemplateNotificationQuery, TemplateNotificationQueryVariables>(
    TemplateNotificationDocument,
    options
  );
}
export function useTemplateNotificationLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TemplateNotificationQuery, TemplateNotificationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<TemplateNotificationQuery, TemplateNotificationQueryVariables>(
    TemplateNotificationDocument,
    options
  );
}
export type TemplateNotificationQueryHookResult = ReturnType<typeof useTemplateNotificationQuery>;
export type TemplateNotificationLazyQueryHookResult = ReturnType<typeof useTemplateNotificationLazyQuery>;
export type TemplateNotificationQueryResult = Apollo.QueryResult<
  TemplateNotificationQuery,
  TemplateNotificationQueryVariables
>;
export const TemplatePdfDocument = gql`
  query templatePDF($id: Int!) {
    templatePDF(id: $id) {
      ...templatePDF
    }
  }
  ${TemplatePdfFragmentDoc}
`;

/**
 * __useTemplatePdfQuery__
 *
 * To run a query within a React component, call `useTemplatePdfQuery` and pass it any options that fit your needs.
 * When your component renders, `useTemplatePdfQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTemplatePdfQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTemplatePdfQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<TemplatePdfQuery, TemplatePdfQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<TemplatePdfQuery, TemplatePdfQueryVariables>(TemplatePdfDocument, options);
}
export function useTemplatePdfLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TemplatePdfQuery, TemplatePdfQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<TemplatePdfQuery, TemplatePdfQueryVariables>(TemplatePdfDocument, options);
}
export type TemplatePdfQueryHookResult = ReturnType<typeof useTemplatePdfQuery>;
export type TemplatePdfLazyQueryHookResult = ReturnType<typeof useTemplatePdfLazyQuery>;
export type TemplatePdfQueryResult = Apollo.QueryResult<TemplatePdfQuery, TemplatePdfQueryVariables>;
export const TournamentDocument = gql`
  query tournament($id: Int!) {
    tournament(id: $id) {
      ...tournament
    }
  }
  ${TournamentFragmentDoc}
`;

/**
 * __useTournamentQuery__
 *
 * To run a query within a React component, call `useTournamentQuery` and pass it any options that fit your needs.
 * When your component renders, `useTournamentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTournamentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTournamentQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<TournamentQuery, TournamentQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<TournamentQuery, TournamentQueryVariables>(TournamentDocument, options);
}
export function useTournamentLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TournamentQuery, TournamentQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<TournamentQuery, TournamentQueryVariables>(TournamentDocument, options);
}
export type TournamentQueryHookResult = ReturnType<typeof useTournamentQuery>;
export type TournamentLazyQueryHookResult = ReturnType<typeof useTournamentLazyQuery>;
export type TournamentQueryResult = Apollo.QueryResult<TournamentQuery, TournamentQueryVariables>;
export const TournamentDisputeDocument = gql`
  query tournamentDispute($id: Int!) {
    tournamentDispute(id: $id) {
      ...tournamentDispute
    }
  }
  ${TournamentDisputeFragmentDoc}
`;

/**
 * __useTournamentDisputeQuery__
 *
 * To run a query within a React component, call `useTournamentDisputeQuery` and pass it any options that fit your needs.
 * When your component renders, `useTournamentDisputeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTournamentDisputeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTournamentDisputeQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<TournamentDisputeQuery, TournamentDisputeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<TournamentDisputeQuery, TournamentDisputeQueryVariables>(
    TournamentDisputeDocument,
    options
  );
}
export function useTournamentDisputeLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TournamentDisputeQuery, TournamentDisputeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<TournamentDisputeQuery, TournamentDisputeQueryVariables>(
    TournamentDisputeDocument,
    options
  );
}
export type TournamentDisputeQueryHookResult = ReturnType<typeof useTournamentDisputeQuery>;
export type TournamentDisputeLazyQueryHookResult = ReturnType<typeof useTournamentDisputeLazyQuery>;
export type TournamentDisputeQueryResult = Apollo.QueryResult<TournamentDisputeQuery, TournamentDisputeQueryVariables>;
export const TournamentDisqualificationDocument = gql`
  query tournamentDisqualification($id: Int!) {
    tournamentDisqualification(id: $id) {
      ...tournamentDisqualificationFull
    }
  }
  ${TournamentDisqualificationFullFragmentDoc}
`;

/**
 * __useTournamentDisqualificationQuery__
 *
 * To run a query within a React component, call `useTournamentDisqualificationQuery` and pass it any options that fit your needs.
 * When your component renders, `useTournamentDisqualificationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTournamentDisqualificationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTournamentDisqualificationQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    TournamentDisqualificationQuery,
    TournamentDisqualificationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<TournamentDisqualificationQuery, TournamentDisqualificationQueryVariables>(
    TournamentDisqualificationDocument,
    options
  );
}
export function useTournamentDisqualificationLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    TournamentDisqualificationQuery,
    TournamentDisqualificationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<TournamentDisqualificationQuery, TournamentDisqualificationQueryVariables>(
    TournamentDisqualificationDocument,
    options
  );
}
export type TournamentDisqualificationQueryHookResult = ReturnType<typeof useTournamentDisqualificationQuery>;
export type TournamentDisqualificationLazyQueryHookResult = ReturnType<typeof useTournamentDisqualificationLazyQuery>;
export type TournamentDisqualificationQueryResult = Apollo.QueryResult<
  TournamentDisqualificationQuery,
  TournamentDisqualificationQueryVariables
>;
export const TournamentMatchDocument = gql`
  query tournamentMatch($id: Int!) {
    tournamentMatch(id: $id) {
      ...tournamentMatchDetails
    }
  }
  ${TournamentMatchDetailsFragmentDoc}
`;

/**
 * __useTournamentMatchQuery__
 *
 * To run a query within a React component, call `useTournamentMatchQuery` and pass it any options that fit your needs.
 * When your component renders, `useTournamentMatchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTournamentMatchQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTournamentMatchQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<TournamentMatchQuery, TournamentMatchQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<TournamentMatchQuery, TournamentMatchQueryVariables>(
    TournamentMatchDocument,
    options
  );
}
export function useTournamentMatchLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TournamentMatchQuery, TournamentMatchQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<TournamentMatchQuery, TournamentMatchQueryVariables>(
    TournamentMatchDocument,
    options
  );
}
export type TournamentMatchQueryHookResult = ReturnType<typeof useTournamentMatchQuery>;
export type TournamentMatchLazyQueryHookResult = ReturnType<typeof useTournamentMatchLazyQuery>;
export type TournamentMatchQueryResult = Apollo.QueryResult<TournamentMatchQuery, TournamentMatchQueryVariables>;
export const TournamentMatchEventDocument = gql`
  query tournamentMatchEvent($id: Int!) {
    tournamentMatchEvent(id: $id) {
      ...tournamentMatchEvent
    }
  }
  ${TournamentMatchEventFragmentDoc}
`;

/**
 * __useTournamentMatchEventQuery__
 *
 * To run a query within a React component, call `useTournamentMatchEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useTournamentMatchEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTournamentMatchEventQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTournamentMatchEventQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<TournamentMatchEventQuery, TournamentMatchEventQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<TournamentMatchEventQuery, TournamentMatchEventQueryVariables>(
    TournamentMatchEventDocument,
    options
  );
}
export function useTournamentMatchEventLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TournamentMatchEventQuery, TournamentMatchEventQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<TournamentMatchEventQuery, TournamentMatchEventQueryVariables>(
    TournamentMatchEventDocument,
    options
  );
}
export type TournamentMatchEventQueryHookResult = ReturnType<typeof useTournamentMatchEventQuery>;
export type TournamentMatchEventLazyQueryHookResult = ReturnType<typeof useTournamentMatchEventLazyQuery>;
export type TournamentMatchEventQueryResult = Apollo.QueryResult<
  TournamentMatchEventQuery,
  TournamentMatchEventQueryVariables
>;
export const TournamentMatchLineUpsDocument = gql`
  query tournamentMatchLineUps($query: DefaultQueryInput!) {
    tournamentMatchLineUps(query: $query) {
      ...tournamentMatchLineUp
    }
  }
  ${TournamentMatchLineUpFragmentDoc}
`;

/**
 * __useTournamentMatchLineUpsQuery__
 *
 * To run a query within a React component, call `useTournamentMatchLineUpsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTournamentMatchLineUpsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTournamentMatchLineUpsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useTournamentMatchLineUpsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<TournamentMatchLineUpsQuery, TournamentMatchLineUpsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<TournamentMatchLineUpsQuery, TournamentMatchLineUpsQueryVariables>(
    TournamentMatchLineUpsDocument,
    options
  );
}
export function useTournamentMatchLineUpsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TournamentMatchLineUpsQuery, TournamentMatchLineUpsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<TournamentMatchLineUpsQuery, TournamentMatchLineUpsQueryVariables>(
    TournamentMatchLineUpsDocument,
    options
  );
}
export type TournamentMatchLineUpsQueryHookResult = ReturnType<typeof useTournamentMatchLineUpsQuery>;
export type TournamentMatchLineUpsLazyQueryHookResult = ReturnType<typeof useTournamentMatchLineUpsLazyQuery>;
export type TournamentMatchLineUpsQueryResult = Apollo.QueryResult<
  TournamentMatchLineUpsQuery,
  TournamentMatchLineUpsQueryVariables
>;
export const TournamentMatchProtocolDocument = gql`
  query tournamentMatchProtocol($id: Int!) {
    tournamentMatchProtocol(id: $id) {
      ...tournamentMatchProtocol
    }
  }
  ${TournamentMatchProtocolFragmentDoc}
`;

/**
 * __useTournamentMatchProtocolQuery__
 *
 * To run a query within a React component, call `useTournamentMatchProtocolQuery` and pass it any options that fit your needs.
 * When your component renders, `useTournamentMatchProtocolQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTournamentMatchProtocolQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTournamentMatchProtocolQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<TournamentMatchProtocolQuery, TournamentMatchProtocolQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<TournamentMatchProtocolQuery, TournamentMatchProtocolQueryVariables>(
    TournamentMatchProtocolDocument,
    options
  );
}
export function useTournamentMatchProtocolLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    TournamentMatchProtocolQuery,
    TournamentMatchProtocolQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<TournamentMatchProtocolQuery, TournamentMatchProtocolQueryVariables>(
    TournamentMatchProtocolDocument,
    options
  );
}
export type TournamentMatchProtocolQueryHookResult = ReturnType<typeof useTournamentMatchProtocolQuery>;
export type TournamentMatchProtocolLazyQueryHookResult = ReturnType<typeof useTournamentMatchProtocolLazyQuery>;
export type TournamentMatchProtocolQueryResult = Apollo.QueryResult<
  TournamentMatchProtocolQuery,
  TournamentMatchProtocolQueryVariables
>;
export const TournamentPlayerStatDocument = gql`
  query tournamentPlayerStat($id: Int!) {
    tournamentPlayerStat(id: $id) {
      ...tournamentPlayerStatFull
    }
  }
  ${TournamentPlayerStatFullFragmentDoc}
`;

/**
 * __useTournamentPlayerStatQuery__
 *
 * To run a query within a React component, call `useTournamentPlayerStatQuery` and pass it any options that fit your needs.
 * When your component renders, `useTournamentPlayerStatQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTournamentPlayerStatQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTournamentPlayerStatQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<TournamentPlayerStatQuery, TournamentPlayerStatQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<TournamentPlayerStatQuery, TournamentPlayerStatQueryVariables>(
    TournamentPlayerStatDocument,
    options
  );
}
export function useTournamentPlayerStatLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TournamentPlayerStatQuery, TournamentPlayerStatQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<TournamentPlayerStatQuery, TournamentPlayerStatQueryVariables>(
    TournamentPlayerStatDocument,
    options
  );
}
export type TournamentPlayerStatQueryHookResult = ReturnType<typeof useTournamentPlayerStatQuery>;
export type TournamentPlayerStatLazyQueryHookResult = ReturnType<typeof useTournamentPlayerStatLazyQuery>;
export type TournamentPlayerStatQueryResult = Apollo.QueryResult<
  TournamentPlayerStatQuery,
  TournamentPlayerStatQueryVariables
>;
export const TournamentSeasonDocument = gql`
  query tournamentSeason($id: Int!) {
    tournamentSeason(id: $id) {
      ...tournamentSeasonFull
    }
  }
  ${TournamentSeasonFullFragmentDoc}
`;

/**
 * __useTournamentSeasonQuery__
 *
 * To run a query within a React component, call `useTournamentSeasonQuery` and pass it any options that fit your needs.
 * When your component renders, `useTournamentSeasonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTournamentSeasonQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTournamentSeasonQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<TournamentSeasonQuery, TournamentSeasonQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<TournamentSeasonQuery, TournamentSeasonQueryVariables>(
    TournamentSeasonDocument,
    options
  );
}
export function useTournamentSeasonLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TournamentSeasonQuery, TournamentSeasonQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<TournamentSeasonQuery, TournamentSeasonQueryVariables>(
    TournamentSeasonDocument,
    options
  );
}
export type TournamentSeasonQueryHookResult = ReturnType<typeof useTournamentSeasonQuery>;
export type TournamentSeasonLazyQueryHookResult = ReturnType<typeof useTournamentSeasonLazyQuery>;
export type TournamentSeasonQueryResult = Apollo.QueryResult<TournamentSeasonQuery, TournamentSeasonQueryVariables>;
export const TournamentSeasonTeamDocument = gql`
  query tournamentSeasonTeam($id: Int!) {
    tournamentSeasonTeam(id: $id) {
      ...tournamentSeasonTeam
    }
  }
  ${TournamentSeasonTeamFragmentDoc}
`;

/**
 * __useTournamentSeasonTeamQuery__
 *
 * To run a query within a React component, call `useTournamentSeasonTeamQuery` and pass it any options that fit your needs.
 * When your component renders, `useTournamentSeasonTeamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTournamentSeasonTeamQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTournamentSeasonTeamQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<TournamentSeasonTeamQuery, TournamentSeasonTeamQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<TournamentSeasonTeamQuery, TournamentSeasonTeamQueryVariables>(
    TournamentSeasonTeamDocument,
    options
  );
}
export function useTournamentSeasonTeamLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TournamentSeasonTeamQuery, TournamentSeasonTeamQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<TournamentSeasonTeamQuery, TournamentSeasonTeamQueryVariables>(
    TournamentSeasonTeamDocument,
    options
  );
}
export type TournamentSeasonTeamQueryHookResult = ReturnType<typeof useTournamentSeasonTeamQuery>;
export type TournamentSeasonTeamLazyQueryHookResult = ReturnType<typeof useTournamentSeasonTeamLazyQuery>;
export type TournamentSeasonTeamQueryResult = Apollo.QueryResult<
  TournamentSeasonTeamQuery,
  TournamentSeasonTeamQueryVariables
>;
export const TournamentSeasonTeamStatDocument = gql`
  query tournamentSeasonTeamStat($id: Int!) {
    tournamentSeasonTeamStat(id: $id) {
      ...tournamentSeasonTeamStat
    }
  }
  ${TournamentSeasonTeamStatFragmentDoc}
`;

/**
 * __useTournamentSeasonTeamStatQuery__
 *
 * To run a query within a React component, call `useTournamentSeasonTeamStatQuery` and pass it any options that fit your needs.
 * When your component renders, `useTournamentSeasonTeamStatQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTournamentSeasonTeamStatQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTournamentSeasonTeamStatQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<TournamentSeasonTeamStatQuery, TournamentSeasonTeamStatQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<TournamentSeasonTeamStatQuery, TournamentSeasonTeamStatQueryVariables>(
    TournamentSeasonTeamStatDocument,
    options
  );
}
export function useTournamentSeasonTeamStatLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    TournamentSeasonTeamStatQuery,
    TournamentSeasonTeamStatQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<TournamentSeasonTeamStatQuery, TournamentSeasonTeamStatQueryVariables>(
    TournamentSeasonTeamStatDocument,
    options
  );
}
export type TournamentSeasonTeamStatQueryHookResult = ReturnType<typeof useTournamentSeasonTeamStatQuery>;
export type TournamentSeasonTeamStatLazyQueryHookResult = ReturnType<typeof useTournamentSeasonTeamStatLazyQuery>;
export type TournamentSeasonTeamStatQueryResult = Apollo.QueryResult<
  TournamentSeasonTeamStatQuery,
  TournamentSeasonTeamStatQueryVariables
>;
export const TournamentTeamDocument = gql`
  query tournamentTeam($id: Int!) {
    tournamentTeam(id: $id) {
      ...tournamentTeamDetails
    }
  }
  ${TournamentTeamDetailsFragmentDoc}
`;

/**
 * __useTournamentTeamQuery__
 *
 * To run a query within a React component, call `useTournamentTeamQuery` and pass it any options that fit your needs.
 * When your component renders, `useTournamentTeamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTournamentTeamQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTournamentTeamQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<TournamentTeamQuery, TournamentTeamQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<TournamentTeamQuery, TournamentTeamQueryVariables>(TournamentTeamDocument, options);
}
export function useTournamentTeamLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TournamentTeamQuery, TournamentTeamQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<TournamentTeamQuery, TournamentTeamQueryVariables>(
    TournamentTeamDocument,
    options
  );
}
export type TournamentTeamQueryHookResult = ReturnType<typeof useTournamentTeamQuery>;
export type TournamentTeamLazyQueryHookResult = ReturnType<typeof useTournamentTeamLazyQuery>;
export type TournamentTeamQueryResult = Apollo.QueryResult<TournamentTeamQuery, TournamentTeamQueryVariables>;
export const TournamentTeamMemberDocument = gql`
  query tournamentTeamMember($id: Int!) {
    tournamentTeamMember(id: $id) {
      ...tournamentTeamMember
    }
  }
  ${TournamentTeamMemberFragmentDoc}
`;

/**
 * __useTournamentTeamMemberQuery__
 *
 * To run a query within a React component, call `useTournamentTeamMemberQuery` and pass it any options that fit your needs.
 * When your component renders, `useTournamentTeamMemberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTournamentTeamMemberQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTournamentTeamMemberQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<TournamentTeamMemberQuery, TournamentTeamMemberQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<TournamentTeamMemberQuery, TournamentTeamMemberQueryVariables>(
    TournamentTeamMemberDocument,
    options
  );
}
export function useTournamentTeamMemberLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TournamentTeamMemberQuery, TournamentTeamMemberQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<TournamentTeamMemberQuery, TournamentTeamMemberQueryVariables>(
    TournamentTeamMemberDocument,
    options
  );
}
export type TournamentTeamMemberQueryHookResult = ReturnType<typeof useTournamentTeamMemberQuery>;
export type TournamentTeamMemberLazyQueryHookResult = ReturnType<typeof useTournamentTeamMemberLazyQuery>;
export type TournamentTeamMemberQueryResult = Apollo.QueryResult<
  TournamentTeamMemberQuery,
  TournamentTeamMemberQueryVariables
>;
export const TournamentTourDocument = gql`
  query tournamentTour($id: Int!) {
    tournamentTour(id: $id) {
      ...tournamentTour
    }
  }
  ${TournamentTourFragmentDoc}
`;

/**
 * __useTournamentTourQuery__
 *
 * To run a query within a React component, call `useTournamentTourQuery` and pass it any options that fit your needs.
 * When your component renders, `useTournamentTourQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTournamentTourQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTournamentTourQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<TournamentTourQuery, TournamentTourQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<TournamentTourQuery, TournamentTourQueryVariables>(TournamentTourDocument, options);
}
export function useTournamentTourLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TournamentTourQuery, TournamentTourQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<TournamentTourQuery, TournamentTourQueryVariables>(
    TournamentTourDocument,
    options
  );
}
export type TournamentTourQueryHookResult = ReturnType<typeof useTournamentTourQuery>;
export type TournamentTourLazyQueryHookResult = ReturnType<typeof useTournamentTourLazyQuery>;
export type TournamentTourQueryResult = Apollo.QueryResult<TournamentTourQuery, TournamentTourQueryVariables>;
export const UserDocument = gql`
  query user($id: Int!) {
    user(id: $id) {
      ...user
    }
  }
  ${UserFragmentDoc}
`;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserQuery(baseOptions: ApolloReactHooks.QueryHookOptions<UserQuery, UserQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<UserQuery, UserQueryVariables>(UserDocument, options);
}
export function useUserLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserQuery, UserQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<UserQuery, UserQueryVariables>(UserDocument, options);
}
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserQueryResult = Apollo.QueryResult<UserQuery, UserQueryVariables>;
export const UserGroupDocument = gql`
  query userGroup($id: Int!) {
    userGroup(id: $id) {
      ...userGroup
    }
  }
  ${UserGroupFragmentDoc}
`;

/**
 * __useUserGroupQuery__
 *
 * To run a query within a React component, call `useUserGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserGroupQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserGroupQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<UserGroupQuery, UserGroupQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<UserGroupQuery, UserGroupQueryVariables>(UserGroupDocument, options);
}
export function useUserGroupLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserGroupQuery, UserGroupQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<UserGroupQuery, UserGroupQueryVariables>(UserGroupDocument, options);
}
export type UserGroupQueryHookResult = ReturnType<typeof useUserGroupQuery>;
export type UserGroupLazyQueryHookResult = ReturnType<typeof useUserGroupLazyQuery>;
export type UserGroupQueryResult = Apollo.QueryResult<UserGroupQuery, UserGroupQueryVariables>;
export const UserProfileDocument = gql`
  query userProfile {
    userProfile {
      ...user
    }
  }
  ${UserFragmentDoc}
`;

/**
 * __useUserProfileQuery__
 *
 * To run a query within a React component, call `useUserProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserProfileQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<UserProfileQuery, UserProfileQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<UserProfileQuery, UserProfileQueryVariables>(UserProfileDocument, options);
}
export function useUserProfileLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserProfileQuery, UserProfileQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<UserProfileQuery, UserProfileQueryVariables>(UserProfileDocument, options);
}
export type UserProfileQueryHookResult = ReturnType<typeof useUserProfileQuery>;
export type UserProfileLazyQueryHookResult = ReturnType<typeof useUserProfileLazyQuery>;
export type UserProfileQueryResult = Apollo.QueryResult<UserProfileQuery, UserProfileQueryVariables>;
export const UserProfileGroupDocument = gql`
  query userProfileGroup {
    userProfile {
      groups {
        ...userGroupMin
      }
    }
  }
  ${UserGroupMinFragmentDoc}
`;

/**
 * __useUserProfileGroupQuery__
 *
 * To run a query within a React component, call `useUserProfileGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserProfileGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserProfileGroupQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserProfileGroupQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<UserProfileGroupQuery, UserProfileGroupQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<UserProfileGroupQuery, UserProfileGroupQueryVariables>(
    UserProfileGroupDocument,
    options
  );
}
export function useUserProfileGroupLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserProfileGroupQuery, UserProfileGroupQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<UserProfileGroupQuery, UserProfileGroupQueryVariables>(
    UserProfileGroupDocument,
    options
  );
}
export type UserProfileGroupQueryHookResult = ReturnType<typeof useUserProfileGroupQuery>;
export type UserProfileGroupLazyQueryHookResult = ReturnType<typeof useUserProfileGroupLazyQuery>;
export type UserProfileGroupQueryResult = Apollo.QueryResult<UserProfileGroupQuery, UserProfileGroupQueryVariables>;
export const UserSubscriberDocument = gql`
  query userSubscriber($id: String!) {
    userSubscriber(id: $id) {
      ...userSubscriber
    }
  }
  ${UserSubscriberFragmentDoc}
`;

/**
 * __useUserSubscriberQuery__
 *
 * To run a query within a React component, call `useUserSubscriberQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserSubscriberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserSubscriberQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserSubscriberQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<UserSubscriberQuery, UserSubscriberQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<UserSubscriberQuery, UserSubscriberQueryVariables>(UserSubscriberDocument, options);
}
export function useUserSubscriberLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserSubscriberQuery, UserSubscriberQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<UserSubscriberQuery, UserSubscriberQueryVariables>(
    UserSubscriberDocument,
    options
  );
}
export type UserSubscriberQueryHookResult = ReturnType<typeof useUserSubscriberQuery>;
export type UserSubscriberLazyQueryHookResult = ReturnType<typeof useUserSubscriberLazyQuery>;
export type UserSubscriberQueryResult = Apollo.QueryResult<UserSubscriberQuery, UserSubscriberQueryVariables>;
export const UserSubscriptionDocument = gql`
  query userSubscription($id: Int!) {
    userSubscription(id: $id) {
      ...userSubscription
    }
  }
  ${UserSubscriptionFragmentDoc}
`;

/**
 * __useUserSubscriptionQuery__
 *
 * To run a query within a React component, call `useUserSubscriptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserSubscriptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserSubscriptionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserSubscriptionQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<UserSubscriptionQuery, UserSubscriptionQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<UserSubscriptionQuery, UserSubscriptionQueryVariables>(
    UserSubscriptionDocument,
    options
  );
}
export function useUserSubscriptionLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserSubscriptionQuery, UserSubscriptionQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<UserSubscriptionQuery, UserSubscriptionQueryVariables>(
    UserSubscriptionDocument,
    options
  );
}
export type UserSubscriptionQueryHookResult = ReturnType<typeof useUserSubscriptionQuery>;
export type UserSubscriptionLazyQueryHookResult = ReturnType<typeof useUserSubscriptionLazyQuery>;
export type UserSubscriptionQueryResult = Apollo.QueryResult<UserSubscriptionQuery, UserSubscriptionQueryVariables>;
export const WebTranslationsUpdatedAtDocument = gql`
  query webTranslationsUpdatedAt($locale: String!) {
    webTranslationsUpdatedAt(locale: $locale)
  }
`;

/**
 * __useWebTranslationsUpdatedAtQuery__
 *
 * To run a query within a React component, call `useWebTranslationsUpdatedAtQuery` and pass it any options that fit your needs.
 * When your component renders, `useWebTranslationsUpdatedAtQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWebTranslationsUpdatedAtQuery({
 *   variables: {
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useWebTranslationsUpdatedAtQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<WebTranslationsUpdatedAtQuery, WebTranslationsUpdatedAtQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<WebTranslationsUpdatedAtQuery, WebTranslationsUpdatedAtQueryVariables>(
    WebTranslationsUpdatedAtDocument,
    options
  );
}
export function useWebTranslationsUpdatedAtLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    WebTranslationsUpdatedAtQuery,
    WebTranslationsUpdatedAtQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<WebTranslationsUpdatedAtQuery, WebTranslationsUpdatedAtQueryVariables>(
    WebTranslationsUpdatedAtDocument,
    options
  );
}
export type WebTranslationsUpdatedAtQueryHookResult = ReturnType<typeof useWebTranslationsUpdatedAtQuery>;
export type WebTranslationsUpdatedAtLazyQueryHookResult = ReturnType<typeof useWebTranslationsUpdatedAtLazyQuery>;
export type WebTranslationsUpdatedAtQueryResult = Apollo.QueryResult<
  WebTranslationsUpdatedAtQuery,
  WebTranslationsUpdatedAtQueryVariables
>;
export const WidgetsDocument = gql`
  query widgets {
    widgets {
      commerceWidget: commerceWidget {
        shops: shops {
          id
          clientId
          state
          name
          description
          codeMask
          catalogueId
          currencyId
          saleEnabled
          createdAt
          updatedAt
          items
          orders
        }
      }
    }
  }
`;

/**
 * __useWidgetsQuery__
 *
 * To run a query within a React component, call `useWidgetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useWidgetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWidgetsQuery({
 *   variables: {
 *   },
 * });
 */
export function useWidgetsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<WidgetsQuery, WidgetsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<WidgetsQuery, WidgetsQueryVariables>(WidgetsDocument, options);
}
export function useWidgetsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<WidgetsQuery, WidgetsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<WidgetsQuery, WidgetsQueryVariables>(WidgetsDocument, options);
}
export type WidgetsQueryHookResult = ReturnType<typeof useWidgetsQuery>;
export type WidgetsLazyQueryHookResult = ReturnType<typeof useWidgetsLazyQuery>;
export type WidgetsQueryResult = Apollo.QueryResult<WidgetsQuery, WidgetsQueryVariables>;
