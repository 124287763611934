import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { GET_COLOR_CONTRAST_1, GET_COLOR_PRIMARY_1, GET_FONT_WEIGHT_SEMI_BOLD } from '../../lib/themeGetters';

interface IProps {}

const MenuItem = styled(NavLink)<{ $isLast?: boolean }>`
  display: block;
  color: ${GET_COLOR_CONTRAST_1};
  font-weight: ${GET_FONT_WEIGHT_SEMI_BOLD};
  &.active {
    color: ${GET_COLOR_PRIMARY_1};
  }
`;

const HeaderMenu: React.FC<IProps> = props => {
  const { t } = useTranslation();

  return (
    <div className="d-flex justify-content-between align-items-center flex-wrap">
      <div className="ml-3">
        <MenuItem to="/tickets">{t('route_tickets')}</MenuItem>
      </div>
      <MenuItem to="/tours">{t('route_tours')}</MenuItem>
      <MenuItem to="/membership">{t('route_membership')}</MenuItem>
      <MenuItem to="/parkings">{t('route_parkings')}</MenuItem>
      <MenuItem to="/loyalty">{t('route_loyalty')}</MenuItem>
      <MenuItem $isLast to="/family-sector">
        {t('route_family_sector')}
      </MenuItem>
    </div>
  );
};

export default React.memo(HeaderMenu);
