import React, { useContext, useEffect, useMemo } from 'react';
import {
  EventOrderMinFragment,
  EventOrderPaymentState,
  EventOrderState,
  useAllMyEventOrdersLightLazyQuery,
} from '../../types/graphql';
import { AuthContext } from '../AuthProvider/AuthProvider';

interface IProps {}

interface ICartContextValue {
  orders: EventOrderMinFragment[];
  isLoading: boolean;
}

export const CartContext = React.createContext<ICartContextValue>({
  orders: [],
  isLoading: false,
});

const CartProvider: React.FC<IProps> = props => {
  const { profile } = useContext(AuthContext);
  const [callAllMyEventOrders, ordersResult] = useAllMyEventOrdersLightLazyQuery({
    fetchPolicy: 'network-only',
    variables: {
      query: {
        filters: [
          {
            field: 'state',
            value: EventOrderState.NEW,
          },
          {
            field: 'paymentState',
            value: EventOrderPaymentState.UNPAID,
          },
        ],
      },
    },
  });

  useEffect(() => {
    if (profile) {
      callAllMyEventOrders();
    }
  }, [callAllMyEventOrders, profile]);

  const orders = useMemo(() => ordersResult?.data?.allMyEventOrders?.edges ?? [], [ordersResult]);

  const isLoading = ordersResult.loading;

  const ctx = useMemo(() => ({ orders, isLoading }), [orders, isLoading]);
  return <CartContext.Provider value={ctx}>{props.children}</CartContext.Provider>;
};

export default React.memo(CartProvider);
