import React from 'react';
import { Route, RouteProps } from 'react-router';
import styled from 'styled-components';
import { GET_COLOR_MAIN_1 } from '../../lib/themeGetters';

interface IProps extends RouteProps {
  params?: { [x: string]: any };
}

const LayoutWrapper = styled.div`
  background-color: ${GET_COLOR_MAIN_1};
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const ExternalRoute: React.FC<IProps> = props => {
  const { params, ...otherProps } = props;
  return (
    <LayoutWrapper>
      <Route {...otherProps} {...params} />
    </LayoutWrapper>
  );
};

export default React.memo(ExternalRoute);
