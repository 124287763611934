import { DefaultTheme } from 'styled-components';

export const RANDOM_COLORS = [
  '#e13100',
  '#518107',
  '#174ac1',
  '#df117b',
  '#dd8500',
  '#0c8e70',
  '#571c88',

  '#ff6527',
  '#7db917',
  '#3278fb',
  '#fe4658',
  '#ffb70d',
  '#24c7a4',
  '#8736c1',

  '#ff9154',
  '#a6d549',
  '#63a4ff',
  '#ff7487',
  '#ffd14a',
  '#56ddc6',
  '#ae65da',

  '#feb583',
  '#c6e57d',
  '#90c2ff',
  '#ff9dac',
  '#ffe27d',
  '#86ebdb',
  '#ca93e8',

  // Picked by contrast colors
  '#fa6284',
  '#36a2eb',
  '#ffb0eb',
  '#fecd56',
  '#008080',
  '#808000',
  '#ffd8b1',
  '#fc8607',
  '#bcf60c',
  '#911eb4',
  '#aaffc3',
  '#543ad8',
  '#46f0f0',
  '#808080',
  '#9a6324',
  '#f032e6',
  '#800000',

  // TODO: Decide about using below listed colors
  '#fabebe',
  '#e6beff',
  '#5b90ff',
  '#000075',
  '#003f5c',
  '#2f4b7c',
  '#665191',
  '#a05195',
  '#d45087',
  '#f95d6a',
  '#ff7c43',
  '#ffa600',
  '#4FC3F7',
  '#F44336',
  '#E91E63',
  '#9C27B0',
  '#673AB7',
  '#3F51B5',
  '#2196F3',
  '#03A9F4',
  '#00BCD4',
  '#01708c',
  '#5355ba',
  '#4bc635',
  '#bcc902',
  '#ba373a',
  '#ac1de0',
];

export const COMMON_THEME_FIELDS: Omit<
  DefaultTheme,
  'colors' | 'name' | 'shadows' | 'randomColors' | 'gradients' | 'separator' | 'background' | 'lodgeColor'
> = {
  margins: {
    margin1: '0.25rem',
    margin2: '0.5rem',
    margin3: '1rem',
    margin4: '1.5rem',
    margin5: '3rem',
  },
  paddings: {
    padding1: '0.25rem',
    padding2: '0.5rem',
    padding3: '1rem',
    padding4: '1.5rem',
    padding5: '3rem',
  },
  animations: {
    easing: 'ease',
    duration: '100ms',
  },
  breakpoints: {
    xs: 320,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
  },
  fontWeights: {
    extraBold: 900,
    bold: 700,
    semiBold: 600,
    normal: 400,
    light: 300,
  },
  fontSizes: {
    text: '1rem',
    button: '1rem',
  },
  text: {
    fontBase: 14,
    lineHeight: 1.2,
    fontFamily: 'Open Sans',
  },
  other: {
    borderRadius1: 4,
    borderRadius2: 6,
    borderRadius3: 8,
    interactionOpacity: 0.75,
    interactionOpacity2: 0.3,
  },
};
