import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useCallback } from 'react';
import { CheckMatchAccessContext, TCheckResult } from '../../contexts/contexts';
import useTimer from '../../hooks/useTimer';
import { END_EVENT_ACCESS_TIME, START_EVENT_ACCESS_TIME } from '../../lib/constants';
import { useCheckMatchAccessMutation } from '../../types/graphql';
import { AuthContext } from '../AuthProvider/AuthProvider';

interface IProps {}

const CheckMatchAccessProvider: React.FC<IProps> = props => {
  const [callCheckMatchAccess, checkMatchAccessResult] = useCheckMatchAccessMutation({
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      const result = data.checkMatchAccess?.result;
      const seatName = data.checkMatchAccess?.seatName;
      setCheckResult({ result, seatName });
    },
  });

  const [checkResult, setCheckResult] = useState<null | TCheckResult | undefined>(null);
  const authContext = useContext(AuthContext);
  const profile = authContext.profile;

  const timer = useTimer();
  const runTimer = timer.runTimer;
  const timerEnded = timer.time === 0;

  const shouldShowAccessWidget = useMemo(() => {
    if (!timerEnded) {
      return false;
    }
    const now = Date.now();
    if (START_EVENT_ACCESS_TIME > now) {
      const timeLeft = Math.round((START_EVENT_ACCESS_TIME - now) / 1000);
      runTimer(timeLeft);
    }
    return START_EVENT_ACCESS_TIME < now && now < END_EVENT_ACCESS_TIME;
  }, [runTimer, timerEnded]);

  useEffect(() => {
    if (profile && shouldShowAccessWidget) {
      callCheckMatchAccess();
    } else {
      setCheckResult(null);
    }
  }, [callCheckMatchAccess, profile, shouldShowAccessWidget]);

  const callCheck = useCallback(
    (data?: { sector: string; row: string; seat: string } | null) => {
      if (!shouldShowAccessWidget) {
        return;
      }

      const vars = { data };
      if (data) {
        callCheckMatchAccess({ variables: vars });
        return;
      }
      callCheckMatchAccess();
    },
    [callCheckMatchAccess, shouldShowAccessWidget]
  );

  const setCheck = useCallback((data: TCheckResult) => {
    setCheckResult(data);
  }, []);

  const isLoading = checkMatchAccessResult.loading;

  const ctx = useMemo(() => ({ checkResult, isLoading, callCheck, setCheck, shouldShowAccessWidget }), [
    checkResult,
    isLoading,
    callCheck,
    setCheck,
    shouldShowAccessWidget,
  ]);

  return <CheckMatchAccessContext.Provider value={ctx}>{props.children}</CheckMatchAccessContext.Provider>;
};

export default React.memo(CheckMatchAccessProvider);
