import React from 'react';
import * as Bootstrap from 'reactstrap';
import styled from 'styled-components';

const StyledContainer = styled(Bootstrap.Container)``;

export interface IContainerProps extends Bootstrap.ContainerProps {}

const Container: React.FC<IContainerProps> = props => {
  return <StyledContainer {...props} />;
};

export default Container;
