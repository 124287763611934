import * as React from 'react';
import { ApolloError } from '@apollo/client';
import { IS_PRODUCTION } from '../../lib/constants';
import { Alert } from 'reactstrap';
import Raven from 'raven-js';
import { getWithExpiry, setWithExpiry } from '../../lib/withExpiry';
import styled from 'styled-components';
import { Loader } from 'react-feather';

interface IProps {}

interface IState {
  error: Error | null;
  errorInfo: React.ErrorInfo | null;
  reloading: boolean;
}

const StyledContainer = styled.div`
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

class ErrorBoundary extends React.PureComponent<IProps, IState> {
  public state: IState = {
    error: null,
    errorInfo: null,
    reloading: false,
  };

  componentDidCatch(error: Error | ApolloError, errorInfo: React.ErrorInfo): void {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    if (error?.message && chunkFailedMessage.test(error.message)) {
      if (!getWithExpiry('chunk_failed')) {
        setWithExpiry('chunk_failed', 'true', 10000);
        window.location.reload();
        this.setState({ reloading: true });
        return;
      }
    }

    if (IS_PRODUCTION) {
      Raven.captureException(error.message);
    }

    this.setState({ error, errorInfo });
  }

  public render() {
    const { error, errorInfo, reloading } = this.state;

    if (reloading) {
      return (
        <StyledContainer>
          <Loader />
        </StyledContainer>
      );
    }

    if (error && errorInfo) {
      return (
        <Alert color="danger">
          <div>{error.message}</div>
          <div>{errorInfo.componentStack}</div>
        </Alert>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
