import React from 'react';
import { useTheme } from 'styled-components';

interface IProps {
  color?: string;
  width?: number;
  height?: number;
}

const UserIcon: React.FC<IProps> = props => {
  const { width = 24, height = 24 } = props;
  const theme = useTheme();
  const color = props.color || theme.colors.secondary1;
  return (
    <svg width={width} height={height} viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 10.5C12.6234 10.5 14.75 8.26142 14.75 5.5C14.75 2.73858 12.6234 0.5 10 0.5C7.37665 0.5 5.25 2.73858 5.25 5.5C5.25 8.26142 7.37665 10.5 10 10.5ZM4.25 13.5C2.17893 13.5 0.5 15.1789 0.5 17.25C0.5 19.3211 2.17805 21 4.24912 21H10H15.7509C17.8219 21 19.5 19.3211 19.5 17.25C19.5 15.1789 17.8211 13.5 15.75 13.5H4.25Z"
        fill={color}
      />
    </svg>
  );
};

export default React.memo(UserIcon);
