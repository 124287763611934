import React, { useCallback, useState } from 'react';
import { Popover, PopoverBody } from 'reactstrap';
import styled from 'styled-components';
import { GET_BOX_SHADOW_2, GET_COLOR_MAIN_2 } from '../../lib/themeGetters';
import Col from '../Col/Col';
import { WebArenaLogo } from '../Common/Common';
import DotsIcon from '../DotsIcon/DotsIcon';
import HeaderCart from '../HeaderCart/HeaderCart';
import HeaderLangToggle from '../HeaderLangToggle/HeaderLangToggle';
import HeaderUser from '../HeaderUser/HeaderUser';
import NavBurger from '../NavBurger/NavBurger';
import ThemeToggle from '../ThemeToggle/ThemeToggle';

const StyledCol = styled(Col)`
  display: inline-flex;
  align-items: center;
  justify-content: space-evenly;
  padding-left: 0;
  padding-right: 0;
`;

const StyledPopover = styled(Popover)`
  .popover {
    border-radius: 8px;
    border-width: 0px;
    box-shadow: ${GET_BOX_SHADOW_2};
    background-color: ${GET_COLOR_MAIN_2};
  }
`;

const StyledMdScreenBlock = styled.div`
  display: flex;
  flex: 100;
  justify-content: flex-end;
  @media (min-width: ${p => p.theme.breakpoints.xl}px) {
    justify-content: space-evenly;
  }
  @media (max-width: ${p => p.theme.breakpoints.sm}px) {
    display: none;
  }
`;

const LangToggleWrapper = styled.div`
  min-width: 58px;
  display: flex;
  justify-content: flex-end;
`;

interface IProps {}

const HeaderActions: React.FC<IProps> = () => {
  const [popoverOpen, setPopoverOpen] = useState(false);

  const renderThemeToggle = useCallback(() => {
    return <ThemeToggle />;
  }, []);

  const renderNavBurger = useCallback(() => {
    return (
      <div className="d-flex flex-grow-1 d-lg-none justify-content-end align-items-center cursor-pointer ml-3 p-1">
        <NavBurger />
      </div>
    );
  }, []);

  const toggle = useCallback(() => setPopoverOpen(!popoverOpen), [popoverOpen]);

  const renderLangToggle = useCallback(
    (contentClassName?: string) => <HeaderLangToggle contentClassName={contentClassName} />,
    []
  );

  const renderNavDots = useCallback(
    () => (
      <div className="d-lg-flex d-none d-xl-none cursor-pointer ml-1 p-1">
        <button
          className="button btn p-0 d-flex align-items-center justify-content-center"
          id="nav-dots-popover"
          type="button"
        >
          <DotsIcon />
        </button>
        <StyledPopover
          className="d-lg-flex d-none d-xl-none"
          placement="bottom"
          isOpen={popoverOpen}
          target="nav-dots-popover"
          toggle={toggle}
        >
          <PopoverBody>
            <div className="d-flex align-items-center justify-content-center p-2">
              <div className="d-none d-md-flex d-lg-none align-items-center justify-content-center">
                <HeaderCart />
              </div>
              <div className="d-none d-md-flex d-lg-none align-items-center justify-content-center ml-4">
                <HeaderUser />
              </div>
              <div className="d-flex d-md-none d-lg-flex align-items-center justify-content-center">
                <HeaderUser />
              </div>
              <div className="d-flex align-items-center justify-content-center ml-4">{renderThemeToggle()}</div>
              <LangToggleWrapper>{renderLangToggle('d-md-flex d-none d-xl-none')}</LangToggleWrapper>
            </div>
          </PopoverBody>
        </StyledPopover>
      </div>
    ),
    [popoverOpen, renderLangToggle, renderThemeToggle, toggle]
  );

  return (
    <StyledCol xs={12} md={12} lg={12}>
      <StyledMdScreenBlock>
        <div className="d-none d-sm-flex d-lg-flex d-md-flex align-items-center justify-content-center">
          <HeaderCart />
        </div>
        <div className="d-none d-sm-flex d-md-flex  d-lg-none d-xl-flex align-items-center justify-content-center">
          <HeaderUser />
        </div>
        <div className="d-none ml-2 d-sm-flex d-md-flex d-lg-none d-xl-flex align-items-center justify-content-center">
          {renderThemeToggle()}
        </div>
        {/* <div className="d-none ml-2 d-sm-flex d-md-flex d-lg-none d-xl-flex align-items-center justify-content-center">
          {renderLangToggle('d-none d-sm-flex d-md-flex d-lg-none d-xl-flex')}
        </div> */}

        {renderNavDots()}
        <div className="d-none d-lg-flex d-xl-flex ml-1">
          <WebArenaLogo />
        </div>
      </StyledMdScreenBlock>
      {renderNavBurger()}
    </StyledCol>
  );
};

export default React.memo(HeaderActions);
