import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { getFromLocalStorage, removeFromLocalStorage, writeToLocalStorage } from '../../lib/localStorage';
import { IS_BROWSER } from '../../lib/constants';
import { useInitMyEventSessionMutation, UserAuthResponseFragment, UserFragment, useUserProfileQuery } from '../../types/graphql';
import { useApolloClient } from '@apollo/client';
import { CallbackFnWithoutArguments } from '../../types/local';

// Check auth logic
export const getIsTokenStored = (): boolean => {
  if (!IS_BROWSER) {
    return false;
  }

  const token = getFromLocalStorage('accessToken');
  return Boolean(token);
};

interface IProps {}

interface IAuthContextValue {
  isAuthorized: boolean;
  login: (authResponse: UserAuthResponseFragment, callback?: CallbackFnWithoutArguments) => void;
  logout: (callback?: CallbackFnWithoutArguments) => void;
  profile: UserFragment | null;
  isLoading: boolean;
}

export const AuthContext = React.createContext<IAuthContextValue>({
  profile: null,
  isAuthorized: false,
  logout: () => {},
  login: () => {},
  isLoading: false,
});

// Component to redirect if not authorized
const AuthProvider: React.FC<IProps> = props => {
  const [isAuthorized, setAuthState] = useState(getIsTokenStored());
  const client = useApolloClient();

  // Fetch user profile if token stored locally
  const profileResult = useUserProfileQuery({
    skip: !isAuthorized,
  });

  const [initSession, initSessionResult] = useInitMyEventSessionMutation({
    fetchPolicy: 'no-cache',
  })

  useEffect(() => {
    if (getFromLocalStorage('session')) {
      console.log('session already open', getFromLocalStorage('session'))
      return
    }
    initSession().then(result => {
      console.log('session: ', result)
      if (!result.data) {
        return
      }
      writeToLocalStorage('session', result.data.initMyEventSession.id)
    })
  }, [initSession])

  // Extract data from response
  const userProfile = profileResult?.data?.userProfile || null;

  // for testing loyalty accounts
  // const userProfile = useMemo(() => {
  //   const res = profileResult?.data?.userProfile;
  //   if (!res) {
  //     return null;
  //   }
  //   return {
  //     ...res,
  //     loyaltyAccount: null,
  //     loyaltyAccounts: [],
  //   };
  // }, [profileResult?.data?.userProfile]);
  const profile = isAuthorized ? userProfile : null;
  const isLoading = profileResult.loading;

  // Update UI state based on data stored in localStorage
  const updateAuthState = useCallback(
    (callback?: CallbackFnWithoutArguments) => {
      setAuthState(getIsTokenStored());

      if (callback) {
        callback();
      }
    },
    [setAuthState]
  );

  // Store auth response data and update UI state
  const handleLogin = useCallback<IAuthContextValue['login']>(
    (authResponse, callback) => {
      writeToLocalStorage('accessToken', authResponse.accessToken);
      writeToLocalStorage('refreshToken', authResponse.refreshToken);
      writeToLocalStorage('user', authResponse.user);

      updateAuthState(callback);
    },
    [updateAuthState]
  );

  // Remove auth related data from local storage, update UI state and clear ApolloClient
  const handleLogout = useCallback<IAuthContextValue['logout']>(
    callback => {
      removeFromLocalStorage('accessToken');
      removeFromLocalStorage('refreshToken');
      removeFromLocalStorage('user');

      updateAuthState(callback);

      client.clearStore();
    },
    [updateAuthState, client]
  );

  // useEffect(() => {
  //   EventService.listen(EVENTS.LOGIN, () => updateAuthState());
  //   EventService.listen(EVENTS.LOGOUT, () => handleLogout());
  //   EventService.listen(EVENTS.UNAUTHENTICATED_ERROR_FOUND, () => handleLogout());
  // }, [updateAuthState, handleLogout]);

  const value = useMemo(
    (): IAuthContextValue => ({
      isAuthorized: isAuthorized,
      logout: handleLogout,
      login: handleLogin,
      profile: profile,
      isLoading: isLoading,
    }),
    [isAuthorized, handleLogout, handleLogin, profile, isLoading]
  );

  return <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>;
};

export default AuthProvider;
