import { DefaultTheme } from 'styled-components';
import { COMMON_THEME_FIELDS, RANDOM_COLORS } from './assets/common';

export const THEME_WHITE: DefaultTheme = {
  name: 'white',
  randomColors: RANDOM_COLORS,
  colors: {
    main1: '#F5F6F7',
    main2: '#ffffff',
    main3: '#F5F5F7',

    contrast1: '#061043',
    contrast2: '#1D243A',
    contrast3: '#6e7486',
    contrast4: '#7a7b81',

    contrastVibrant1: '#ffffff',
    contrastVibrant2: '#ffffff',
    contrastVibrant3: '#ffffff',

    info1: '#77A2E4',
    info2: '#71C3E0',
    info3: '#6BD9DA',

    danger1: '#DB0815',
    danger2: '#E65F5E',
    danger3: '#D91521',

    success1: '#7AE994',
    success2: '#7AE994',
    success3: '#7AE994',

    warning1: '#EC9460',
    warning2: '#EC9460',
    warning3: '#EC9460',

    primary1: '#FE0000',
    primary2: '#FFE0E0',
    primary3: '#ffe3e3',
    secondary1: '#1C3483',
    secondary2: '#E3E6F0',
    secondary3: '#bec0c8',

    parkingWidgetBackground: '#287CE2',
  },
  shadows: {
    shadow1: `0px 2px 3px 0 rgba(0, 0, 0, 0.1)`,
    shadow2: `0px 4px 5px 0 rgba(0, 0, 0, 0.1)`,
    shadow3: `0px 6px 8px 0 rgba(0, 0, 0, 0.1)`,
    danger1: `1px 3px 8px 2px rgb(243 32 19 / 50%)`,
  },
  separator: {
    colors: {
      main1: '#1C3483',
    },
  },
  background: {
    parkingTicket: 'rgba(255,255,255,0.29)',
    matchTicket: 'rgba(255,255,255,0.33)',
  },
  gradients: {},
  lodgeColor: {
    active: '#90192B',
    sectorSale: '#84744A',
    inactive: '#DCDCDC',
  },
  ...COMMON_THEME_FIELDS,
};
