import React, { useCallback, useContext } from 'react';
import styled from 'styled-components';
import { AuthContext } from '../AuthProvider/AuthProvider';
import { EventTypesConfigContext } from '../EventTypesConfigProvider/EventTypesConfigProvider';
import { I18nContext } from '../I18nProvider/I18nProvider';
import Loader from '../Loader/Loader';

interface IProps {}

const Wrapper = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const InitializationLoader: React.FC<IProps> = props => {
  // const { t } = useTranslation();
  const authContext = useContext(AuthContext);
  const i18nContext = useContext(I18nContext);
  const eventTypesConfigContext = useContext(EventTypesConfigContext);

  const getIsLoading = useCallback((): boolean => {
    return authContext.isLoading || eventTypesConfigContext.isLoading || i18nContext.isLoading;
  }, [authContext.isLoading, eventTypesConfigContext.isLoading, i18nContext.isLoading]);

  const getLoaderText = useCallback((): string => {
    const { activeLanguage } = i18nContext;
    if (authContext.isLoading) {
      return activeLanguage === 'en' ? 'Loading user profile' : 'Загружаем профиль пользователя';
    }

    if (i18nContext.isLoading) {
      return activeLanguage === 'en' ? 'Loading translations' : 'Загружаем переводы';
    }

    if (eventTypesConfigContext.isLoading) {
      return activeLanguage === 'en' ? 'Loading event types' : 'Загружаем типы событий';
    }

    return activeLanguage === 'en' ? 'loading' : 'загружаемся';
  }, [authContext.isLoading, eventTypesConfigContext.isLoading, i18nContext]);

  if (getIsLoading()) {
    const text = getLoaderText();
    return (
      <Wrapper>
        <Loader text={text} />
      </Wrapper>
    );
  }

  return <>{props.children}</>;
};

export default (React.memo(InitializationLoader) as unknown) as typeof InitializationLoader;
