import { ApolloClient, InMemoryCache, ApolloLink } from '@apollo/client';
import { getAuthMiddleware, getErrorMiddleware, getHttpMiddleware } from './configureMiddlewares';
import { getFromLocalStorage } from './localStorage';

type TOptions = { lang?: string };

export function getMiddleWare(options?: TOptions) {
  const storedLocale = getFromLocalStorage('locale');
  const lang = options?.lang || storedLocale || undefined;
  const authLink = getAuthMiddleware(lang);
  const httpLink = getHttpMiddleware();
  const errorLink = getErrorMiddleware();

  // Combine links
  return ApolloLink.from([errorLink, authLink, httpLink]);
}

export function configureApollo(): ApolloClient<any> {
  const cache = new InMemoryCache();
  const link = getMiddleWare();

  // And construct ApolloClient
  return new ApolloClient({
    link: link,
    cache: cache,
  });
}
