import './styles/index.scss';

import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';

import reportWebVitals from './reportWebVitals';
import SuspenseFallback from './components/SuspenseFallback/SuspenseFallback';
import App from './components/App/App';
import { SENTRY_DSN } from './lib/constants';
import Raven from 'raven-js';
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';

const fallback = <SuspenseFallback />;

if (process.env.NODE_ENV !== 'development') {
  Raven.config(SENTRY_DSN, {
    environment: process.env.NODE_ENV,
    release: process.env.REACT_APP_GIT_COMMIT ?? '',
    tags: {
      channel: process.env.REACT_APP_CHANNEL ?? '',
    },
  }).install();
}

ReactDOM.render(
  <ErrorBoundary>
    <Suspense fallback={fallback}>
      <App />
    </Suspense>
  </ErrorBoundary>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
