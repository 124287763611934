import { LocaleISO } from '../types/local';

export const NOOP = 'noop';

export const IS_BROWSER = Boolean(window);

export const PROJECT_NAME = 'CSKA';

export const IS_PRODUCTION = process.env.NODE_ENV === 'production';

export const DEFAULT_PAGE_SIZE = 20;

export const SEARCH_SUBMIT_DELAY = 500;

export const TRANSLATIONS_CHANNEL = 'web';
export const MY_ORDERS_SOURCE = 'WEB';

export const PLACEHOLDER_IMAGE_PATH = '/images/placeholder-image.png';

export const ERROR_FALLBACK_KEY = 'error';

export const DATE_FORMAT = {
  DAY: 'd',
  DATE: 'dd MMMM',
  DATE_SHORT: 'd MMMM',
  DATE_FULL_DOTS: 'd.MM.yy',
  MONTH_STANDALONE: 'LLLL',
  YEAR_STANDALONE: 'yyyy',
  MONTH_YEAR_STANDALONE: 'LLLL yyyy',
  DATE_WITH_YEAR: 'dd MMMM yyyy',
  MONTH_WITH_YEAR: 'MMMM yyyy',
  DATE_WITH_YEAR_SHORT: 'dd.MM.yyyy',

  DATE_AND_TIME: 'dd MMMM HH:mm',
  DATE_AND_TIME_FULL: 'dd MMMM yyyy HH:mm:ss',
  DATE_AND_TIME_FULL_WITHOUT_SECONDS: 'dd MMMM yyyy HH:mm',

  DATE_SERVER_FORMAT: 'yyyy-MM-dd',

  DATE_EXPIRY_DATE: 'MM/yy',

  TIME: 'HH:mm',
  TIME_FULL: 'HH:mm:ss',
  RESERVED_TILL_TIME: 'd.MM.yy, HH:mm',
};

export const OPERATIONS = {
  UPDATE: 'update',
  CREATE: 'create',
  DELETE: 'delete',
};

export const DEFAULT_RESET_TIMEOUT = 60;

export const BASE_LIMIT = 20;

export const RUBLE_SIGN = '₽';

export enum DISCOUNT_TYPES {
  FAMILY = 2,
  ABONEMENT = 3,
}
export const SEATS_IMAGE_BASE_URL = 'https://cska.sportstar.me/arena-img/';

export const BASE_PAYMENT_REDIRECT_URL = '/tickets-order';

export const EVENT_ORDER_POLLING_INTERVAL = 10000;

export const ANDROID_STORE_APP_LINK = 'https://play.google.com/store/apps/details?id=ru.highglossy.pfccska';

export const IOS_STORE_APP_LINK =
  'https://apps.apple.com/ru/app/%D0%BF%D1%84%D0%BA-%D1%86%D1%81%D0%BA%D0%B0/id420262660';

export const KID_MATURITY_YEAR = 18;

export const FAMILY_SECTOR_ABOUT_URL = 'https://pfc-cska.com/kids';

export const ACTIVE_LANGUAGES: LocaleISO[] = ['ru', 'en'];

export const FACEBOOK_LINK = 'https://facebook.com/vebarena/';

export const VK_LNIK = 'https://vk.com/pfc_cska';

export const INSTAGRAM_LINK = 'https://www.instagram.com/vebarena/';

export const MEMBERSHIP_URL = 'https://pfc-cska.com/tickets/abonement/';

export const CLOUD_PAYMENTS_PAYMENT_URL = '/api/orders?source=new-web'; //'https://cska-web-events.de8.srv.sydev.tech/api/orders?source=new-web';

// Default keys
const TEST_CLOUDPAYMENTS_PUBLIC_ID = 'pk_453fb6b7f0f862837b23a3c835ae7';
const PROD_CLOUDPAYMENTS_PUBLIC_ID = 'pk_72921c9e505c956f0b9e0687f3524';

// Agent keys
const AGENT_TEST_CLOUDPAYMENTS_PUBLIC_ID = 'pk_623ea11a54cf3268b8df048046f40';
const AGENT_PROD_CLOUDPAYMENTS_PUBLIC_ID = 'pk_623ea11a54cf3268b8df048046f40';



export const SENTRY_DSN = 'https://16fe4e6303cc4ad3b3ece3052569c29c@sentry.sydev.tech/13';
export const GA_CODE = 'G-0F1W0JDR99';

const IS_STAGING = process.env.REACT_APP_CHANNEL === 'production' ? false : true;
const IS_PROD = process.env.APP_ENV === 'production';

export const CLOUDPAYMENTS_PUBLIC_ID = IS_STAGING ? TEST_CLOUDPAYMENTS_PUBLIC_ID : PROD_CLOUDPAYMENTS_PUBLIC_ID;
export const GRAPH_ENDPOINT = '/graphql';

// in seconds
export const SEATS_RESERVE_TIME = 1803;

export const STORAGE_KEY_RESERVE_TIME = 'reserves_time_key';

export const START_EVENT_ACCESS_TIME = 1629363600000;
export const END_EVENT_ACCESS_TIME = 1629554400000;

// CheckApologizePresent
// export const PRESENT_TYPES: { [x: string]: { id: number } } = {
//   1: { id: 1 },
//   2: { id: 2 },
//   3: { id: 3 },
//   4: { id: 4 },
//   5: { id: 5 },
//   6: { id: 6 },
// };

export const PRESENT_TYPES: { [x: string]: { id: number } } = {
  1: { id: 1 },
  2: { id: 2 },
  3: { id: 3 },
  4: { id: 4 },
};
export const START_GIFT_CAMPAIGN_TIME = 1639000800000;
export const END_GIFT_CAMPAIGN_TIME = 1648760400000;
export const CURRENT_GIFT_CAMPAIGN_NAME = 'ZGifts';

export const CHECK_SEASON_TICKET_GROUP_ID = 5;

export const CLOUDPAYMENTS_AGENT_PUBLIC_ID = IS_PROD ? AGENT_PROD_CLOUDPAYMENTS_PUBLIC_ID : AGENT_TEST_CLOUDPAYMENTS_PUBLIC_ID;
export const AGENT_EVENT_ID = ['2304', '2305', '2306', '2307', '2369']
export const FAN_ID_BANNER_LINK = 'https://pfc-cska.com/fanid'
export const CUSTOM_CONDITIONS_EVENT_IDX = ['2429']

export const IMAGE_UPLOAD_LIMIT_IN_MB = 6;

export const LUZHNIKI_STADIUM_PLACE_ID = 29;

export const LUZHNIKI_PROMO_CODE = 76;