import React from 'react';
import styled from 'styled-components';
import { GET_COLOR_MAIN_1 } from '../../lib/themeGetters';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';

interface IProps {}

const LayoutWrapper = styled.div`
  background-color: ${GET_COLOR_MAIN_1};
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const PublicLayout: React.FC<IProps> = props => {
  return (
    <LayoutWrapper>
      <Header />
      <div>{props.children}</div>
      <Footer />
    </LayoutWrapper>
  );
};

export default React.memo(PublicLayout);
