import { createGlobalStyle } from 'styled-components';
import {
  GET_COLOR_CONTRAST_1,
  GET_COLOR_CONTRAST_2,
  GET_COLOR_CONTRAST_3,
  GET_COLOR_MAIN_1,
  GET_COLOR_MAIN_2,
  GET_COLOR_PRIMARY_1,
  GET_COLOR_SECONDARY_1,
  GET_COLOR_SECONDARY_2,
  GET_FONT_WEIGHT_NORMAL,
  GET_PADDING_1,
  GET_TEXT_FONT_BASE,
  GET_TEXT_FONT_FAMILY,
} from '../../lib/themeGetters';

export const GlobalStyles = createGlobalStyle`
  body, html {
    height: 100%;
    font-family: ${GET_TEXT_FONT_FAMILY};
    font-size: ${GET_TEXT_FONT_BASE}px;
    background-color: ${GET_COLOR_MAIN_1};
    color: ${GET_COLOR_CONTRAST_1};
    ::-webkit-scrollbar {
    width: 15px;
    height: 15px;
  }

  ::-webkit-scrollbar-thumb {
    background: ${GET_COLOR_SECONDARY_2};
  }

  ::-webkit-scrollbar-track {
    background: ${GET_COLOR_MAIN_1};
  }
  }

  a {
    color: ${GET_COLOR_SECONDARY_1};
    
    &:hover, &:focus, &:active {
      color: ${GET_COLOR_PRIMARY_1};
    } 
  }
  
  p {
    color: ${GET_COLOR_CONTRAST_2};
  }
  
  hr {
    border-color: ${GET_COLOR_MAIN_1};
  }
  
  h1 {
    display: block;
    font-size: 3rem;
    font-weight: ${GET_FONT_WEIGHT_NORMAL};
    margin-bottom: 0.75rem;
    
    // @media screen and (min-width: ${p => p.theme.breakpoints.xs}px) {
    //   font-size: 2rem;
    // }
    //
    // @media screen and (min-width: ${p => p.theme.breakpoints.lg}px) {
    //   font-size: 3rem;
    // }
  }
  
  h2 {
    display: block;
    font-size: 2.25rem;
    font-weight: ${GET_FONT_WEIGHT_NORMAL};
    margin-bottom: 0.75rem;
  } 
  
  h3 {
    display: block;
    font-size: 1.75rem;
    font-weight: ${GET_FONT_WEIGHT_NORMAL};
    margin-bottom: 0.75rem;
  }
  
  h4 {
    display: block;
    font-size: 1.2rem;
    font-weight: ${GET_FONT_WEIGHT_NORMAL};
    margin-bottom: 0.75rem;
  }
  
  input {
    background-color: ${GET_COLOR_MAIN_2};
  }
  
  .text {
    &-muted {
      color: ${GET_COLOR_CONTRAST_3};
    }
    &-primary {
      color: ${GET_COLOR_PRIMARY_1};
    }
    &-secondary {
      color: ${GET_COLOR_SECONDARY_1};
    }
  }

  .cursor {
    &-pointer { cursor: pointer; }
    &-default { cursor: default; }
    &-not-allowed { cursor: not-allowed; }
    &-progress { cursor: progress; }
  }

  .text {
    &-right { text-align: right; }
    &-left { text-align: left; }
    &-center { text-align: center; }
  }
  
  .form-control {
    border-radius: 0;
    border-top: 1px transparent solid;
    border-left: 1px transparent solid;
    border-right: 1px transparent solid;
    border-bottom: 1px ${GET_COLOR_CONTRAST_3} solid;
    //background-color: ${GET_COLOR_MAIN_2};
    background-color: transparent;
    font-size: 1.2rem;
    
    &:focus {
      color: inherit;
      background-color: inherit;
      border-color: transparent;
      border-bottom-color: ${GET_COLOR_PRIMARY_1};
      outline: none;
      box-shadow: none !important;
    }
  }
  
  textarea.form-control {
    border-radius: 0;
    padding: ${GET_PADDING_1};
    border-top: none;
    border-left: none;
    border-right: none;
    border-color: ${GET_COLOR_MAIN_1};
    
    &:focus {
      outline: 1px ${GET_COLOR_PRIMARY_1} solid;
      outline-offset: -1px;
    }
  }
  
  select.form-control {
    //border: none;
    border-radius: 0;
    padding-left: 0;
  }
  
  .modal-backdrop.show {
    opacity: 0.7;
  }
  
  .rc-calendar-today .rc-calendar-date {
    border-color: ${GET_COLOR_SECONDARY_1};
  }

  .rc-calendar-selected-day .rc-calendar-date {
    background-color: transparent !important;
    color: ${GET_COLOR_CONTRAST_1};
  }

  .rc-calendar-header {
    border-color: ${GET_COLOR_CONTRAST_3};
  }

  .rc-calendar-date {
    color: ${GET_COLOR_CONTRAST_1};
    
    &:hover,
    &:focus {
      background-color: ${GET_COLOR_SECONDARY_2};
    }
  }
`;
