import { ApolloError, ApolloQueryResult, FetchResult } from '@apollo/client';
import React from 'react';
import {
  CreateMyEventReserveMutation,
  CreateMyEventReserveMutationVariables,
  DeleteMyEventReserveMutation,
  DeleteMyEventReserveMutationVariables,
  EventOrderFragment,
  LoyaltyDiscountFragment,
  MyEventReservesQuery,
  PaymentTransactionOrderType,
  ReserveFragment,
} from '../types/graphql';

interface ITimerContextValue {
  timer: number;
  startTimer: () => void;
  isTimerEnabled: boolean;
}

export const TimerContext = React.createContext<ITimerContextValue>({
  timer: 0,
  startTimer: () => {},
  isTimerEnabled: false,
});

type TData = { loyaltyDiscount?: LoyaltyDiscountFragment | null; reserves: ReserveFragment[] };
export type TSeatsReserveObject = Record<number, TData>;

interface ISeatsReserveValue {
  getDataByEventId: (eventId: number) => TData | void | null;
  getLoyaltyDiscount: (eventId: number) => LoyaltyDiscountFragment | void | null;
  getEventReserves: (eventId: number) => ReserveFragment[] | void | null;
  setLoyaltyDiscount: (eventId: number, loyalty: LoyaltyDiscountFragment | null) => void;
  isLoading: boolean;
  error?: ApolloError;
  createMyEventReserve: (
    variables: CreateMyEventReserveMutationVariables
  ) => Promise<FetchResult<CreateMyEventReserveMutation, Record<string, any>, Record<string, any>>> | Promise<void>;
  deleteMyEventReserve: (
    variables: DeleteMyEventReserveMutationVariables
  ) => Promise<FetchResult<DeleteMyEventReserveMutation, Record<string, any>, Record<string, any>>> | Promise<void>;
  refetchReserves: () => Promise<ApolloQueryResult<MyEventReservesQuery> | undefined>;
  getAllMappedReserves: () => TSeatsReserveObject;
  getReservesLength: () => number;
  allReserves: ReserveFragment[];
}

export const SeatsContext = React.createContext<ISeatsReserveValue>({
  isLoading: false,
  getDataByEventId: () => {},
  getLoyaltyDiscount: () => {},
  getEventReserves: () => {},
  setLoyaltyDiscount: () => {},
  createMyEventReserve: () => Promise.resolve(),
  deleteMyEventReserve: () => Promise.resolve(),
  refetchReserves: () => Promise.resolve(undefined),
  getAllMappedReserves: () => ({}),
  getReservesLength: () => 0,
  allReserves: [],
});

interface ICloudPaymentsModal {
  modalState: any;
  setModalState: (state: any) => void;
  showCloudPaymentsModal: (order: EventOrderFragment, cpPublicKey: string) => void;
  onError?: () => void;
}

export const CloudPaymentsModalContext = React.createContext<ICloudPaymentsModal>({
  modalState: {},
  setModalState: (state: any) => {},
  showCloudPaymentsModal: (order: EventOrderFragment, cpPublicKey: string) => {},
  onError: () => {},
});

export type TCheckResult = { result?: boolean | null; seatName?: string | null };

interface ICheckMatchAccess {
  isLoading: boolean;
  checkResult?: null | TCheckResult;
  setCheck?: (result: TCheckResult) => void;
  callCheck?: (data?: { sector: string; row: string; seat: string } | null) => void;
  shouldShowAccessWidget?: boolean;
}

export const CheckMatchAccessContext = React.createContext<ICheckMatchAccess>({
  isLoading: false,
});
