import React from 'react';
import { useTranslation } from 'react-i18next';

interface IProps {
  text?: string;
  isFull?: boolean;
  className?: string;
}

const EmptyIcon = () => {
  return (
    <svg width="126" height="68" viewBox="0 0 126 68" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.15789 29.6667H47.2105C47.2105 37.0305 52.8659 43 59.8421 43H66.1579C73.1341 43 78.7895 37.0305 78.7895 29.6667H119.842M15.6316 65H110.368C117.345 65 123 59.0305 123 51.6667V29.6667L112 9.66667C109.5 5 107.172 3 103.684 3H63H23.3158C19.8277 3 16 4 13 9.66667L3 29.6667V51.6667C3 59.0305 8.65535 65 15.6316 65Z"
        stroke="#C9C9CF"
        strokeWidth="6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const Empty: React.FC<IProps> = props => {
  const { text, className, children } = props;
  const { t } = useTranslation();
  const fallback = t('no_data');
  const content = children || text || fallback;

  return (
    <div className={`d-flex flex-column justify-content-center align-items-center ${className ? className : ''}`}>
      <div>{content}</div>
      <div className="mt-4">
        <EmptyIcon />
      </div>
    </div>
  );
};

export default (React.memo(Empty) as unknown) as typeof Empty;
