import React, { useCallback, useContext, useMemo, useState } from 'react';
import { CloudPaymentsModalContext } from '../../contexts/contexts';
import { CLOUDPAYMENTS_PUBLIC_ID } from '../../lib/constants';
import getCloudpaymentsPublicKey from '../../lib/getCloudpaymentsPublicKey';
import { ymPurchaseEventOrder } from '../../lib/yandexMetrika';
import { EventOrderFragment, PaymentTransactionOrderType, useValidateOrderPaymentMutation } from '../../types/graphql';
import { AuthContext } from '../AuthProvider/AuthProvider';

interface IProps {}

const CloudpaymentsModalProvider: React.FC<IProps> = props => {
  const [modalState, setModalState] = useState<null | any>(null);

  const authContext = useContext(AuthContext);
  const [checkOrderPaymentStatus, checkOrderPaymentStatusResult] = useValidateOrderPaymentMutation();

  const showCloudPaymentsModal = useCallback(
    (order: EventOrderFragment, cpPublicKey: string) => {
      // @ts-ignore
      var widget = new window.cp.CloudPayments();
      widget.pay(
        'charge',
        {
          publicId: cpPublicKey,
          description: order.event.title,
          amount: order.total,
          currency: 'RUB',
          accountId: undefined,
          invoiceId: order.code,
          email: authContext.profile?.email || undefined,
          skin: 'mini',
        },
        {
          onSuccess: function (options: any) {
            // success
            //действие при успешной оплате
            ymPurchaseEventOrder(order);
          },
          onFail: function (reason: any, options: any) {
            // fail
            //действие при неуспешной оплате
          },
          onComplete: function (paymentResult: any, options: any) {
            //Вызывается как только виджет получает от api.cloudpayments ответ с результатом транзакции.
            //например вызов вашей аналитики Facebook Pixel
            checkOrderPaymentStatus({
              variables: {
                data: {
                  orderId: order.id,
                  orderType: PaymentTransactionOrderType.TICKET,
                },
              },
            }).then(result => {
              if (result.data?.validateOrderPayment.success) {
                ymPurchaseEventOrder(order);

                setTimeout(() => {
                  window.location.href = `/tickets-order/${order.id}`;
                }, 200);
              }
            });
          },
        }
      );
    },
    [authContext.profile?.email, checkOrderPaymentStatus]
  );

  const value = useMemo(
    () => ({
      modalState,
      setModalState,
      showCloudPaymentsModal,
    }),
    [modalState, showCloudPaymentsModal]
  );

  return (
    <CloudPaymentsModalContext.Provider value={value}>
      {/* {renderModal()} */}
      {props.children}
    </CloudPaymentsModalContext.Provider>
  );
};

export default React.memo(CloudpaymentsModalProvider);
