import React, { useCallback, useContext } from 'react';
import styled, { useTheme } from 'styled-components';
import { GET_COLOR_CONTRAST_1, GET_PADDING_2 } from '../../lib/themeGetters';
import Moon from '../Moon/Moon';
import Sun from '../Sun/Sun';
import { ThemeContext } from '../ThemeProvider/ThemeProvider';

interface IProps {}

const ThemeToggleContainer = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${GET_PADDING_2};
`;

const ThemeToggle: React.FC<IProps> = props => {
  const theme = useTheme();
  const { activeThemeName, toggle } = useContext(ThemeContext);
  const handleThemeToggle = useCallback(
    evt => {
      evt.preventDefault();
      evt.stopPropagation();
      toggle();
    },
    [toggle]
  );
  return (
    <ThemeToggleContainer onClick={handleThemeToggle}>
      {activeThemeName === 'white' ? (
        <Moon color={GET_COLOR_CONTRAST_1({ theme })} />
      ) : (
        <Sun color={GET_COLOR_CONTRAST_1({ theme })} />
      )}
    </ThemeToggleContainer>
  );
};

export default React.memo(ThemeToggle);
