import { KID_MATURITY_YEAR } from './constants';

export const LOCAL_TRANSLATIONS = {
  en: {
    translation: {
      h_tickets: 'Tickets',
      h_nearest_match: 'Neareset match',
      discount_seats_label_zero: 'You have {{ quantity }} child tickets',
      discount_seats_label_one: 'You have {{ quantity }} child ticket',
      discount_seats_label_many: 'You have {{ quantity }} child tickets',
      discount_seats_label_few: 'You have {{ quantity }} child tickets',
      cost: 'Cost',
      label_created_orders: 'Created orders',
      h_create_event_order: 'Create order',
      reserved_sector: 'Sector',
      reserved_row: 'Row',
      reserved_seat: 'Seat',
      promocode_placeholder: 'Enter promocode',
      action_apply: 'Apply',
      title_order: 'Order №',
      promo_code: 'Promo-code {{ discountPercent }}%',
      route_tours: 'Excursions',
      route_tickets: 'Tickets',
      route_loyalty: 'Loyalty',
      route_family_sector: 'Family sector',
      lodge: 'Lodge',
      route_parkings: 'Parkings',
      action_lodge_buy: 'Buy all lodge',
      label_add_image: 'Download',
      h_your_applications: 'Your applications',
      h_family_sector_events: 'Family sector events',
      label_link_to_mobile_app: 'In the app on',
      label_or: 'or',
      label_link_to_mobile_app_2: 'will be more convenient :-)',
      label_mobile_app: 'Приложение ЦСКА на {{ os }}',
      label_point_camera: 'Просто наведите камеру своего смартфона на этот код!',
      label_link: 'Или перейдите по',
      link: 'ссылке',
      h_about_membership: 'Абонементская программа',
      membership_description: `Уважаемые болельщики!

      Стартует продажа абонементов на сезон-2020/21
      
      Продажа абонементов будет осуществляться строго при предъявлении паспорта!
      
      При продлении абонемента заполнение анкеты не требуется. При первичной покупке необходимо заполнить анкету и ознакомиться с правилами поведения болельщиков на стадионе во время матча. Анкету можно получить на месте либо распечатать и заполнить заблаговременно. Скачать анкету можно по ссылке: Анкета и правила. Обращаем ваше внимание, что файл должен быть распечатать на одном листе с двух сторон.
      
      В Детский сектор 202 на трибуне К продаются семейные абонементы для родителей с детьми. Взрослый абонемент продается только в случае приобретения детского абонемента на детей в возрасте до 13 лет при предъявлении паспорта и свидетельства о рождении ребенка.
      
      Абонементы дают право посещения домашних матчей в соответствии с категорией приобретенного абонемента.
      
      Дополнительная информация о ценах и привилегиях получаемых при покупке абонемента можно прочитать здесь:`,
      more_tickets: '{{ quantity }} tickets left',
      more_tickets_one: '{{ quantity }} ticket left',
      more_tickets_few: '{{ quantity }} tickets left',
      more_tickets_many: '{{ quantity }} tickets left',
      title_CSKA: 'CSKA',
      loyalty_orders: 'Заказы программы лояльности',
      selected_tickets: '{{ quantity }} tickets selected',
      selected_tickets_one: '{{ quantity }} ticket selected',
      selected_tickets_few: '{{ quantity }} tickets selected',
      selected_tickets_many: 'Выбрано {{ quantity }} tickets selected',
      h_your_balance_is_zero: 'Your balance is {{ quantity }} bithorses',
      h_your_balance_is_one: 'Your balance is {{ quantity }} bithorse',
      h_your_balance_is_few: 'Your balance is {{ quantity }} bithorses',
      h_your_balance_is_many: 'Your balance is {{ quantity }} bithorses',
      avaliable_loyalty_items_count_zero: 'Enough to buy {{ quantity }} items!',
      avaliable_loyalty_items_count_one: 'Enough to buy {{ quantity }} item!',
      avaliable_loyalty_items_count_few: 'Enough to buy {{ quantity }} items!',
      avaliable_loyalty_items_count_many: 'Enough to buy {{ quantity }} items!',
      stadium_legend_selected_seat: 'Selected',
      stadium_legend_available_seat: 'Available',
      stadium_legend_not_available_seat: 'Not available',
      PAYMENT_INSUFFICIENT_FUNDS: 'Insuffisient funds',
      USER_NOT_FOUND: 'User not found',
      INCORRECT_CREDENTIONALS: 'Wrong username and/or password',
      INCORRECT_PIN_CODE: 'Incorrect pin',
      INTERNAL_SERVER_ERROR: 'Server error!',
      tickets_count_one: '{{ quantity }} ticket',
      tickets_count_few: '{{ quantity }} tickets',
      tickets_count_many: '{{ quantity }} tickets',
    },
  },

  ru: {
    translation: {
      h_tickets: 'Билеты',
      h_nearest_match: 'Ближайший матч',
      discount_seats_label_zero: 'У вас есть {{ quantity }} детских билетов',
      discount_seats_label_one: 'У вас есть {{ quantity }} детский билет',
      discount_seats_label_many: 'У вас есть {{ quantity }} детских билетов',
      discount_seats_label_few: 'У вас есть {{ quantity }} детских билета',
      cost: 'Стоимость',
      total: 'Итого',
      label_created_orders: 'Оформленные заказы',
      h_create_event_order: 'Создать заказ',
      reserved_sector: 'Сектор',
      reserved_row: 'Ряд',
      reserved_seat: 'Место',
      promocode_placeholder: 'Введите промо-код',
      action_apply: 'Применить',
      title_order: 'Заказ №',
      promo_code: 'Промо-код {{ discountPercent }}%',
      route_tours: 'Экскурсии',
      route_tickets: 'Билеты',
      route_loyalty: 'Каталог лояльности',
      route_family_sector: 'Семейный сектор',
      lodge: 'Ложи',
      route_parkings: 'Парковки',
      action_lodge_buy: 'Выкуп ложи',
      label_add_image: 'Загрузить',
      action_logout: 'Выйти',
      h_events: 'События',
      eventsList_empty: 'Событий нет',
      h_calendar: 'Календарь',
      label_today: 'Сегодня',
      h_stadium_parking: 'Паркинг ВЭБ-Арены',
      action_open_tickets_policy: 'Условия предоставления сервиса',
      action_buy_parking: 'Оплатить парковку',
      // family sector
      h_your_applications: 'Ваши заявки',
      h_family_sector_events: 'События в семейном секторе',
      h_about_family_sector: 'О семейном секторе',
      family_sector_about: 'Подробнее о детском клубе и семейном секторе',
      family_sector_description_1: `Здесь комфортно вместе с детьми смотреть футбол. Ребенок может болеть вместе с родителями на трибуне, а может развлекаться в отдельной детской комнате под присмотром аниматоров.`,
      family_sector_description_2: '',
      family_sector_description_3: '',
      label_edit: 'Редактировать',
      label_cancel: 'Отменить',
      label_add_application: 'Добавить заявку',
      application_enum_ACCEPTED: 'Одобрено',
      application_enum_DELETED: 'Отменено',
      application_enum_NEW: 'На рассмотрении',
      application_enum_DECLINED: 'Отклонено',
      kids_club_apply_family_sector_description: '',
      kids_club_apply_family_sector_header: 'Создание заявки',
      kids_club_update_family_sector_description: '',
      kids_club_update_family_sector_header: 'Редактирование заявки',
      label_kids_club_parentFIO: 'ФИО родителя',
      email: 'Эл. почта',
      parent_phone: 'Телефон родителя',
      label_kids_club_FIO: 'ФИО ребенка',
      kids_club_kid_birthday: 'Дата рождения',
      kids_club_kid_has_abonement: 'Есть абонемент',
      kids_club_kid_had_abonement: 'Был абонемент',
      kids_club_upload_label: 'Свидетельство о рождении',
      kids_club_empty_applications: 'Список пуст',
      validation_error_kid_is_mature: `Возраст ребенка превышает ${KID_MATURITY_YEAR}`,
      action_about_family_sector: 'О семейном секторе',
      //common
      action_confirm: 'Подтвердить',
      action_save: 'Сохранить',
      action_cancel: 'Отменить изменения',
      action_change: 'Изменить',
      action_buy_ticket: 'Купить билет',
      action_all_tickets: 'Все билеты',
      action_more_info: 'Подробнее',
      action_buy_online: 'Купить онлайн',
      h_membership: 'Абонемент',
      action_login: 'Войти',
      action_register: 'Зарегистрироваться',
      title_unregistered_fallback: 'Блок доступен только авторизованным пользователям',
      // profile
      h_profile: 'Профиль',
      h_subscriptions: 'Подписки',
      h_your_balance_is_zero: 'Ваш баланс {{ quantity }} битконей',
      h_your_balance_is_one: 'Ваш баланс {{ quantity }} битконь',
      h_your_balance_is_few: 'Ваш баланс {{ quantity }} битконя',
      h_your_balance_is_many: 'Ваш баланс {{ quantity }} битконей',
      avaliable_loyalty_items_count_zero: 'Хватит на {{ quantity }} товаров!',
      avaliable_loyalty_items_count_one: 'Хватит на {{ quantity }} товар!',
      avaliable_loyalty_items_count_few: 'Хватит на {{ quantity }} товара!',
      avaliable_loyalty_items_count_many: 'Хватит на {{ quantity }} товаров!',
      h_loyalty_items_title: 'Заказы программы лояльности',
      edit_profile: 'Редактировать',
      lastname: 'Фамилия',
      firstname: 'Имя',
      middleName: 'Отчество',
      phone: 'Телефон',
      PAID: 'Оплачен',
      label_loyalty_archive: 'История покупок',
      h_orders: 'Заказы',
      h_unpaid_events: 'Не оплаченные',
      h_nearest_events: 'Ближайшие события',
      order_archive_label: 'Архив заказов',
      h_loyalty_archive: 'Ваши покупки',
      label_paid_at: 'Оплачен',
      h_orders_archive: 'Архив заказов',
      label_year: 'Год',
      action_load_more: 'Показать еще',
      label_sort: 'Сортировка',
      no_data: 'Здесь пока ничего нет',
      sector: 'Сектор',
      seat: 'Место',
      action_create_order: 'Оформить заказ',
      action_buy: 'Оформить',
      // event types
      parking: 'Парковка',
      tour: 'Экскурсия',
      ticket: 'Билет',
      tickets_count_one: '{{ quantity }} билет',
      tickets_count_few: '{{ quantity }} билета',
      tickets_count_many: '{{ quantity }} билетов',
      action_cancel_ticket: 'Отменить заказ',
      h_edit_profile: 'Редактирование профиля',
      edit_profile_first_name_label: 'Имя',
      edit_profile_middle_name_label: 'Отчество',
      edit_profile_last_name_label: 'Фамилия',
      action_cancel_changes: 'Отменить изменения',
      login: 'Войти',
      empty_orders: 'У вас пока нет билетов на ближайшие события',
      field_required_error: 'Поле обязательно',
      email_error: 'Неправильная почта',
      phone_length_error: 'Неправильный формат телефона',
      validation_error_bigger_than_today: 'Дата рождения больше текущего времени',
      // main
      stadium_tour_description: `Вы сможете посидеть на месте главного тренера в зале для пресс-конференций и в комфортабельном кресле владельца VIP-ложи, узнаете  историю старой «Песчанки» — культового места для всех армейских болельщиков и многое другое!`,
      h_stadium_tour: 'Экскурсии по ВЭБ Арене',
      h_loyalty_catalogue: 'Каталог лояльности',
      action_open_loyalty_page: 'Весь каталог',
      h_fan_bank_card: 'Карта болельщика',
      h_family_sector: 'Семейный сектор',
      fan_bank_card_description_1: `Станьте ближе к любимому клубу с новой картой болельщика ПФК ЦСКА от ПСБ! Получайте билеты на матчи, подарки и привилегии за накопленные баллы — БитКони.`,
      label_link_to_mobile_app: 'В мобильном приложении на ',
      label_or: 'или',
      label_link_to_mobile_app_2: 'будет удобнее :-)',
      label_mobile_app: 'Приложение ЦСКА на {{ os }}',
      label_point_camera: 'Просто наведите камеру своего смартфона на этот код!',
      label_link: 'Или перейдите по',
      link: 'ссылке',
      // loyalty
      sort_byDate: 'По дате — сначала новые',
      sort_byPrice_ASC: 'По цене — сначала дешевые',
      sort_byPrice_DESC: 'По цене — сначала дорогие',
      sort_byName: 'По названию',
      show_length: 'Показано',
      from: 'из',
      // select sector page
      go_back: 'Назад',
      select_sector: 'Выбрать сектор',
      select_seat: 'Выбрать место',
      create_event_order: 'Создать заказ',
      step_1: 'Шаг 1',
      step_2: 'Шаг 2',
      step_3: 'Шаг 3',
      tickets_policy: 'Условия предоставления сервиса',
      select_sector_description: 'Выберите интересующий вас сектор или группу лож на плане стадиона:',
      stadium_legend_spectators: 'Зрители',
      stadium_legend_spectator_lounges: 'Зрительские ложи',
      stadium_legend_cska_active_support_sector: 'Сектор активной поддержки ЦСКА',
      stadium_legend_fonbet_lounge: 'FONBET Lounge',
      stadium_legend_guests: 'Гости',
      stadium_legend_press: 'Пресса',
      stadium_legend_family_sector: 'Детский сектор',
      stadium_legend_psb_vip: 'ПСБ VIP',
      stadium_legend_unavailable_sector: 'Недоступный сектор',
      selected_sector: 'Сектор',
      row: 'Ряд',
      // select seat page
      sector_label: 'Сектор',
      stadium_legend_selected_seat: 'Выбран',
      stadium_legend_available_seat: 'Доступен',
      stadium_legend_not_available_seat: 'Не доступен',
      h_about_membership: 'Абонементская программа',
      membership_description: `Уважаемые болельщики!

      Стартует продажа абонементов на сезон-2021/22
      Продажа абонементов будет осуществляться строго при предъявлении паспорта!

      При продлении абонемента заполнение анкеты не требуется. При первичной покупке необходимо заполнить анкету и ознакомиться с правилами поведения болельщиков на стадионе во время матча. Анкету можно получить на месте либо распечатать и заполнить заблаговременно. Скачать анкету можно по ссылке: Анкета и правила. Обращаем ваше внимание, что файл должен быть распечатать на одном листе с двух сторон.
      
      В Детский сектор 202 на трибуне К продаются семейные абонементы для родителей с детьми. Взрослый абонемент продается только в случае приобретения детского абонемента на детей в возрасте до 13 лет при предъявлении паспорта и свидетельства о рождении ребенка.
      
      Абонементы дают право посещения домашних матчей в соответствии с категорией приобретенного абонемента.
      
      Дополнительная информация о ценах и привилегиях получаемых при покупке абонемента можно прочитать здесь:`,
      h_vip: 'VIP-ложи',
      vip_description_1: `
        На любые вопросы о VIP-ложах вам сможет ответить
        менеджер по гостеприимству ПФК ЦСКА
        по телефону +7 (495) 128 44 11`,
      // burger menu
      label_tickets: 'Билеты',
      label_tours: 'Экскурсии',
      label_membership: 'Абонемент',
      label_parkings: 'Парковка',
      label_loyalty: 'Каталог лояльности',
      label_familySector: 'Семейный сектор',
      label_profile: 'Личный кабинет',
      footer_coNavLinkyright_text_without_years: ' Professional Football Club CSKA Moscow.',
      match_policy: 'Правила возврата купленных билетов',
      policy: 'Политика конфиденциальности',
      match_rules: 'Правила поведения зрителей',
      h_cart: 'Корзина',
      loyalty_bank_card_widget_text: 'Больше впечатлений при оплате картой болельщика ЦСКА!',
      select_lodge_description: 'Выберите интересующую вас ложу',
      lodge_buy_all_sector: 'Выкупается целиком',
      lodge_buy_one_sector: 'Места выкупаются по одному',
      lodge_other_sectors: 'Секторы',
      selected_lodge: 'Ложе',
      action_go_to_payment: 'Перейти к оплате',
      action_disable_loyalty_discount: 'Отменить скидку',
      wrong_promo: 'Неправильный промо-код',
      h_tours: 'Билеты на экскурсии',
      h_about_tour: 'Об экскурсии',
      slots_available_zero: '{{ quantity }} свободных мест',
      slots_available_one: '{{ quantity }} свободное место',
      slots_available_few: '{{ quantity }} свободных места',
      slots_available_many: '{{ quantity }} свободных мест',
      h_memberships: 'Абонементы в продаже',
      h_about_parking: 'О парковках',
      NEW: 'Новый',
      CANCELLED: 'Отмененный',
      PROCESSING: 'В процессе',
      DONE: 'Завершен',
      ACCEPTED: 'Принято',
      action_cancel_order: 'Отменить заказ',
      action_cancel_loyalty: 'Отмена',
      more_tickets: 'Еще {{ quantity }} билетов',
      more_tickets_one: 'Еще {{ quantity }} билет',
      more_tickets_few: 'Еще {{ quantity }} билета',
      more_tickets_many: 'Еще {{ quantity }} билетов',
      h_home_empty_tickets: 'Билетов на ближайшие игры пока нет',
      action_alert_about_matches: 'Сообщить о появлении',
      h_modal_family_discount: 'У вас есть скидка',
      modal_family_discount_description: `Вы можете купить это место со скидкой {{ percent }}% `,
      modal_family_discount_description2: `Если передумаете, то нужно будет удалить место
      и снова его зарезервировать.`,
      action_apply_discount: 'Применить скидку {{ percent }}%',
      action_not_needed: 'Не надо',
      h_modal_loyalty_discount: 'У вас применена скидка {{ percent }}%',
      modal_loyalty_discount_description: 'Вы можете купить со скидкой {{ percent }}% только 1 билет',
      modal_loyalty_discount_description2: 'Если вы забронируете это место, то скидка будет отменена',
      action_cancel_discount: 'Отменить скидку {{ percent }}%',
      label_discount: 'Применить скидку {{ percent }}%',
      label_not_needed: 'Не надо',
      label_match: 'Матч',
      label_places: 'Места',
      label_price: 'Стоимость',
      label_event: 'Событие',
      reserved_till: 'Резерв до',
      pay_till: 'Оплатить до',
      title_CSKA: 'ЦСКА',
      title_select_seat: 'Выбор места',
      title_policy: 'Политика конфиденциальности',
      title_matchPolicy: 'Правила возврата купленных билетов',
      title_matchRules: 'Правила поведения зрителей',
      title_loyalty_archive: 'История покупок лояльности',
      title_event_orders_archive: 'Архив заказов',
      title_notFound: 'Не найдено',
      title_select_lodge: 'Выбор ложе',
      title_validate_code: 'Подтвердить код',
      h_menu: 'Меню',
      h_confirm_purchase: 'Подтвердите покупку',
      h_purchase_success: 'Готово!',
      loyalty_order_purchase_success_text:
        'С вами свяжется наш менеджер и сообщит, где и когда забрать ваш заказ. Хорошего дня!',
      action_super: 'Супер!',
      order_code: 'Номер заказа',
      action_try_again: 'Попробовать еще раз',
      action_go_back: 'Назад',
      h_error: 'Ошибка',
      title_unregistered_action_fallback: 'Действие доступно только авторизованным пользователям',
      loyalty_orders: 'Заказы программы лояльности',
      error_message: 'Произошла ошибка!',
      login_form_description: '',
      title_login: 'Авторизация',
      action_forgotPassword: 'Забыли пароль?',
      action_submit: 'Подтвердить',
      forgotPassword_sendEmail_form_description:
        'Укажите эл.почту указанную при регистрации и вам придет письмо с кодом для восстановления',
      forgotPassword_recoverWithEmail_form_description: 'Введите код из письма или перейдите по отправленной ссылке',
      fp_sendAgain_active: 'Отправить повторно',
      fp_sendAgain_inactive: 'Отправить повторно',
      forgot_password_confirmationKey: 'Проверка кода',
      title_forgot_password: 'Забыли пароль',
      key_invalid: 'Неправильный код',
      h_recoverByCode: 'Восстановление через код',
      forgot_password_newPassword_label: 'Новый пароль',
      forgot_password_newPassword_placeholder: 'Введите новый пароль',
      password: 'Пароль',
      h_parking: 'Парковки',
      selected_tickets_one: 'Выбран {{ quantity }} билет',
      selected_tickets_few: 'Выбрано {{ quantity }} билета',
      selected_tickets_many: 'Выбрано {{ quantity }} билетов',
      ticket_with_discount: 'Билет со скидкой',
      ticket_with_loyalty_discount_instruction: 'Чтобы отменить скидку достаточно нажать на кнопку',
      ticket_with_family_discount_instruction: 'Чтобы отменить скидку нужно удалить место и снова его выбрать',
      action_reset_discount: 'Убрать скидку',
      action_delete_reserve: 'Удалить резерв',
      reserve_success: 'Успешно зарезервировано',
      remove_seat_reserve: 'Резерв успешно снят',
      label_select_seats: 'Выберите интересующие вас места на Секторе ',
      login_success: 'Успешный вход в систему',
      action_pay: 'Оплатить',
      label_card_year_and_month: 'ММ/ГГ',
      label_card_number: 'Номер карты',
      label_card_holder_name: 'Имя владельца карты',
      get_email_receipt: 'получить квитанцию на email',
      label_cvv: 'CVV',
      label_order_unpaid: 'Не оплачен',
      label_order_paid: 'Оплачен',
      label_order_cancelled: 'Отменен',
      action_send_to_mail: 'Отправить на почту',
      h_login: 'Авторизация',
      seats_zero: '{{ quantity }} мест',
      seats_one: '{{ quantity }} место',
      seats_few: '{{ quantity }} места',
      seats_many: '{{ quantity }} мест',
      PAYMENT_INSUFFICIENT_FUNDS: 'Недостаточно средств',
      USER_NOT_FOUND: 'Пользователь не найден',
      INCORRECT_CREDENTIONALS: 'Неправильный логин и/или пароль',
      INCORRECT_PIN_CODE: 'Неправильный пин',
      INTERNAL_SERVER_ERROR: 'Ошибка сервера!',
      description_covid_widget: `Уважаемые болельщики!

      Сообщаем, что изменения, внесенные в Указ Мэра Москвы от 08.06.2020 №
      68-УМ в редакции от 16.07.2021, не отменяют ограничений для проведения
      массовых спортивных мероприятий. В соответствии с ранее принятыми
      решениями без QR-кодов разрешается проведение спортивных мероприятий с
      одновременным присутствием не более 500 человек. Для проведения
      мероприятий со зрителями выше этой нормы у всех посетителей матчей
      должны быть QR-коды, которые введены мэрией для вакцинированных,
      переболевших коронавирусной инфекцией либо сдавших отрицательный
      ПЦР-тест (не ранее 3 суток до даты матча).

      Таким образом, до внесения соответствующих изменений в действующий
      указ проход зрителей в сектора стадиона будет осуществляться по
      QR-коду. Проверка QR-кода будет происходить в досмотровой зоне. Также
      при себе необходимо иметь паспорт для проверки личности.
      `,
      action_confirm_phone: 'Подтвердить телефон',
      phone_unverified: 'Нужно подтвердить номер телефона',
      description_apologize_present_widget_2:
        'Вы выбрали Экскурсионный тур по ВЭБ Арене (по предварительной записи) в качестве компенсации за пропущенный матч. Спасибо, что остаётесь с командой! В ближайшее время мы направим вам информацию о том, как вы можете получить компенсацию.',
    },
  },
};
