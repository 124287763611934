import React, { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, NavLink } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import { GET_COLOR_CONTRAST_1, GET_COLOR_SECONDARY_2, GET_PADDING_2 } from '../../lib/themeGetters';
import { AuthContext } from '../AuthProvider/AuthProvider';
import UserIcon from '../UserIcon/UserIcon';
import { metrikaAuthStart } from '../../lib/yandexMetrika';

interface IProps {}

const UserIconWrapper = styled.div`
  background-color: ${GET_COLOR_SECONDARY_2};
  padding: ${GET_PADDING_2};
  border-radius: 6px;
  display: inline-flex;
  align-items: center;
`;

const UserIconContainer = styled.div`
  padding: ${GET_PADDING_2};
`;

const LoginText = styled.div`
  margin-left: 0.5rem;
`;

const HeaderUser: React.FC<IProps> = () => {
  const { t } = useTranslation();
  const authContext = useContext(AuthContext);
  const { isAuthorized, profile } = authContext;
  const theme = useTheme();

  const renderLoginUserBlock = useCallback(() => {
    return (
      <NavLink
        className="d-flex align-items-center"
        to="/login"
        onClick={() => {
          // yandex
          metrikaAuthStart();
        }}
      >
        <UserIconWrapper>
          <UserIcon color={GET_COLOR_CONTRAST_1({ theme })} />
          <LoginText>{t('login')}</LoginText>
        </UserIconWrapper>
      </NavLink>
    );
  }, [t, theme]);

  if (!isAuthorized || !profile) {
    return renderLoginUserBlock();
  }

  return (
    <Link className="ml-2" to="/profile">
      <UserIconContainer>
        <UserIcon color={GET_COLOR_CONTRAST_1({ theme })} />
      </UserIconContainer>
    </Link>
  );
};

export default React.memo(HeaderUser);
