import React from 'react';
import * as Bootstrap from 'reactstrap';
import styled from 'styled-components';

const StyledCol = styled(Bootstrap.Col)``;

export interface IColProps extends Bootstrap.ColProps {}

const Col: React.FC<IColProps> = props => {
  return <StyledCol {...props} />;
};

export default Col;
