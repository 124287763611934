import React, { useContext, useMemo } from 'react';
import { Loader } from 'react-feather';
import { NavLink } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import { SeatsContext } from '../../contexts/contexts';
import { GET_COLOR_CONTRAST_1, GET_COLOR_PRIMARY_1, GET_PADDING_2 } from '../../lib/themeGetters';
import { AuthContext } from '../AuthProvider/AuthProvider';
import CartIcon from '../CartIcon/CartIcon';
import { CartContext } from '../CartProvider/CartProvider';
import { metrikaOrderStart } from '../../lib/yandexMetrika';

const getAllTicketsLength = (items: { tickets: any[] }[]) =>
  items.reduce((total, item) => {
    if (item?.tickets?.length) {
      total += item.tickets.length;
    }

    return total;
  }, 0);

interface IProps {
  ordersLength?: number;
}

const StyledNav = styled.div`
  padding: ${GET_PADDING_2};
  position: relative;
  cursor: pointer;
`;

const QuantityIndicator = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  right: 0;
  background-color: ${GET_COLOR_PRIMARY_1};
  border-radius: 50%;
  width: 18px;
  height: 18px;
  color: #ffffff;
`;

const QuantityLoader = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  right: 0;
  border-radius: 50%;
  width: 18px;
  height: 18px;
`;

const HeaderCart: React.FC<IProps> = props => {
  const cartContext = useContext(CartContext);
  const seatReserveContext = useContext(SeatsContext);
  const { profile } = useContext(AuthContext);
  const theme = useTheme();

  const allOrderTicketsLength = getAllTicketsLength(cartContext.orders);
  const reservesLength = useMemo(() => seatReserveContext.getReservesLength(), [seatReserveContext]);

  const totalLength = allOrderTicketsLength + reservesLength;

  const size = theme.text.fontBase;
  // if (!profile) {
  //   return null;
  // }
  return (
    <StyledNav
      style={{ cursor: 'pointer' }}
      onClick={() => {
        window.location.href = '/cart';
        //yandex
        metrikaOrderStart();
      }}
    >
      <CartIcon color={GET_COLOR_CONTRAST_1({ theme })} width={26} height={26} />
      {cartContext.isLoading && (
        <QuantityLoader>
          <Loader color={GET_COLOR_CONTRAST_1({ theme })} size={size} />
        </QuantityLoader>
      )}
      {totalLength > 0 && <QuantityIndicator>{totalLength}</QuantityIndicator>}
    </StyledNav>
  );
};

export default React.memo(HeaderCart);
