import * as React from 'react';
import { ApolloError } from '@apollo/client';
import { IS_PRODUCTION } from '../../lib/constants';
import { Alert } from 'reactstrap';
import Raven from 'raven-js';
import { getWithExpiry, setWithExpiry } from '../../lib/withExpiry';
import { Loader } from 'react-feather';
import styled, { DefaultTheme, withTheme } from 'styled-components';
import { GET_COLOR_CONTRAST_1, GET_COLOR_MAIN_1 } from '../../lib/themeGetters';

const StyledContainer = styled.div`
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${GET_COLOR_MAIN_1};
`;

interface IState {
  error: Error | null;
  errorInfo: React.ErrorInfo | null;
  reloading: boolean;
}

class RouteBoundary extends React.PureComponent<any, IState> {
  public state: IState = {
    error: null,
    errorInfo: null,
    reloading: false,
  };

  componentDidCatch(error: Error | ApolloError, errorInfo: React.ErrorInfo): void {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    if (error?.message && chunkFailedMessage.test(error.message)) {
      if (!getWithExpiry('chunk_failed')) {
        setWithExpiry('chunk_failed', 'true', 10000);
        window.location.reload();
        this.setState({ reloading: true });
        return;
      }
    }

    if (IS_PRODUCTION) {
      Raven.captureException(error.message);
    }

    this.setState({ error, errorInfo });
  }

  public render() {
    const { error, errorInfo, reloading } = this.state;

    if (error && errorInfo) {
      return (
        <Alert color="danger">
          <div>{error.message}</div>
          <div>{errorInfo.componentStack}</div>
        </Alert>
      );
    }

    if (reloading) {
      const theme = this.props.theme as DefaultTheme;
      return (
        <StyledContainer>
          <Loader color={GET_COLOR_CONTRAST_1({ theme })} />
        </StyledContainer>
      );
    }

    return this.props.children;
  }
}

export default withTheme(RouteBoundary);
