import React from 'react';
import { ApolloError } from '@apollo/client';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import Loader from '../Loader/Loader';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import Empty from '../Empty/Empty';
import styled from 'styled-components';
import Card, { ICardProps } from '../Card/Card';

export const Title = styled.h3`
  margin-bottom: 0.5rem;
`;

type Maybe<T> = T | null;

interface IExternalProps {
  // Quick props for wrapper rendering
  isWrapped?: Maybe<boolean>;
  fullHeight?: boolean;
  title?: Maybe<string | JSX.Element>;
  extra?: Maybe<string | JSX.Element>;
  className?: string;
  wrapperProps?: Partial<ICardProps>;

  // For automatic renderContent
  error?: Maybe<ApolloError>;
  isLoading?: Maybe<boolean>;
  isEmpty?: Maybe<boolean>;
  emptyText?: Maybe<React.ReactNode>;
  // useSkeleton?: boolean;

  // Main renderers
  renderContent?: Maybe<() => React.ReactNode>;
  renderEssence?: Maybe<() => React.ReactNode>;

  // Content block renderers
  renderLoader?: Maybe<() => React.ReactNode>;
  renderEmpty?: Maybe<() => React.ReactNode>;
  renderError?: Maybe<(error: ApolloError) => React.ReactNode>;
}

export type RcmProps = Partial<IExternalProps>;

interface IProps extends IExternalProps {}

interface IState {}

// RCM stands for RenderContentMethods
class Rcm extends React.PureComponent<IProps, IState> {
  private renderEssence = () => {
    const { renderEssence, children } = this.props;
    if (renderEssence) {
      return renderEssence();
    }

    if (typeof children === 'function') {
      return children();
    }

    return children;
  };

  private renderContent = () => {
    const { renderContent } = this.props;
    if (renderContent) {
      return renderContent();
    }

    const { isLoading, error, isEmpty } = this.props;
    if (error) {
      return this.renderError(error);
    }

    if (isLoading) {
      return this.renderLoader();
    }

    if (isEmpty) {
      return this.renderEmpty();
    }

    return this.renderEssence();
  };

  private renderEmpty = () => {
    const { renderEmpty, emptyText } = this.props;
    if (renderEmpty) {
      return renderEmpty();
    }

    return <Empty className="d-flex flex-column align-items-center">{emptyText}</Empty>;
  };

  private renderLoader = () => {
    const { renderLoader } = this.props;
    if (renderLoader) {
      return renderLoader();
    }

    return <Loader isFull />;
  };

  private renderError = (error: NonNullable<IProps['error']>) => {
    const { renderError } = this.props;
    if (renderError) {
      return renderError(error);
    }

    return <ErrorMessage isFull error={error} />;
  };

  private renderWithoutWrapper = () => {
    const { className, title: providedTitle } = this.props;
    const title = providedTitle || null;

    return (
      <div className={className}>
        {title && <Title>{title}</Title>}
        {this.renderContent()}
      </div>
    );
  };

  private renderWithWrapper = () => {
    const { className, wrapperProps, fullHeight, extra } = this.props;
    const title = this.props.title || null;

    return (
      <Card extra={extra} fullHeight={fullHeight} className={className} title={title} {...wrapperProps}>
        <ErrorBoundary>{this.renderContent()}</ErrorBoundary>
      </Card>
    );
  };

  private renderWrapper = () => {
    const { isWrapped } = this.props;

    if (!isWrapped) {
      return this.renderWithoutWrapper();
    }

    return this.renderWithWrapper();
  };

  private renderBoundary = () => {
    return <ErrorBoundary>{this.renderWrapper()}</ErrorBoundary>;
  };

  render() {
    return this.renderBoundary();
  }
}

export default Rcm;
