import React from 'react';

import loadable from '@loadable/component';

import { BrowserRouter, Switch } from 'react-router-dom';
import AppRoute from '../AppRoute/AppRoute';
import RouteBoundary from '../RouteBoundary/RouteBoundary';
import ScrollToTop from '../ScrollToTop/ScrollToTop';
import ExternalRoute from '../ExternalRoute/ExternalRoute';

const HomePage = loadable(() => import(/* webpackPrefetch: true */ '../HomePage/HomePage'));
const LoginPage = loadable(() => import(/* webpackPrefetch: true */ '../LoginPage/LoginPage'));
const ProfilePage = loadable(() => import(/* webpackPrefetch: true */ '../ProfilePage/ProfilePage'));
const NotFoundPage = loadable(() => import(/* webpackPrefetch: true */ '../NotFoundPage/NotFoundPage'));
const ToursPage = loadable(() => import(/* webpackPrefetch: true */ '../ToursPage/ToursPage'));
const FamilySectorPage = loadable(() => import(/* webpackPrefetch: true */ '../FamilySectorPage/FamilySectorPage'));
const CartPage = loadable(() => import(/* webpackPrefetch: true */ '../CartPage/CartPage'));
const LoyaltyCataloguePage = loadable(
  () => import(/* webpackPrefetch: true */ '../LoyaltyCataloguePage/LoyaltyCataloguePage')
);
const LoyaltyItemPage = loadable(() => import(/* webpackPrefetch: true */ '../LoyaltyItemPage/LoyaltyItemPage'));
const TicketsPage = loadable(() => import(/* webpackPrefetch: true */ '../TicketsPage/TicketsPage'));
const EventPage = loadable(() => import(/* webpackPrefetch: true */ '../EventPage/EventPage'));
const ForgotPasswordPage = loadable(
  () => import(/* webpackPrefetch: true */ '../ForgotPasswordPage/ForgotPasswordPage')
);
const RegisterPage = loadable(() => import(/* webpackPrefetch: true */ '../RegisterPage/RegisterPage'));
const ValidateCodePage = loadable(() => import(/* webpackPrefetch: true */ '../ValidateCodePage/ValidateCodePage'));
const MatchRulesPage = loadable(() => import(/* webpackPrefetch: true */ '../MatchRulesPage/MatchRulesPage'));
const PolicyPage = loadable(() => import(/* webpackPrefetch: true */ '../PolicyPage/PolicyPage'));
const MatchPolicy = loadable(() => import(/* webpackPrefetch: true */ '../MatchPolicy/MatchPolicy'));
const LoyaltyArchivePage = loadable(
  () => import(/* webpackPrefetch: true */ '../LoyaltyArchivePage/LoyaltyArchivePage')
);
const EventOrdersArchive = loadable(
  () => import(/* webpackPrefetch: true */ '../EventOrdersArchive/EventOrdersArchive')
);
const CreateEventOrderPage = loadable(
  () => import(/* webpackPrefetch: true */ '../CreateEventOrderPage/CreateEventOrderPage')
);
const EventOrderPage = loadable(() => import(/* webpackPrefetch: true */ '../EventOrderPage/EventOrderPage'));
const EventLodgesPage = loadable(() => import(/* webpackPrefetch: true */ '../EventLodgesPage/EventLodgesPage'));
const ParkingsPage = loadable(() => import(/* webpackPrefetch: true */ '../ParkingsPage/ParkingsPage'));
const EventSeatsPage = loadable(() => import(/* webpackPrefetch: true */ '../EventSeatsPage/EventSeatsPage'));
const MembershipPage = loadable(() => import(/* webpackPrefetch: true */ '../MembershipPage/MembershipPage'));
const PaymentFailPage = loadable(() => import(/* webpackPrefetch: true */ '../PaymentFailPage/PaymentFailPage'));
const SuccessPaymentPage = loadable(
  () => import(/* webpackPrefetch: true */ '../SuccessPaymentPage/SuccessPaymentPage')
);
const TokenAuth = loadable(() => import(/* webpackPrefetch: true */ '../TokenAuth/TokenAuth'));
const ConfirmEmail = loadable(() => import(/* webpackPrefetch: true */ '../ConfirmEmail/ConfirmEmail'));

const Unsubscribe = loadable(() => import('../Unsubscribe/Unsubscribe'));
const UnsubscribeFail = loadable(() => import('../UnsubscribeFail/UnsubscribeFail'));
interface IExternalProps {}

interface IProps extends IExternalProps {}

class Routes extends React.PureComponent<IProps> {
  render() {
    return (
      <BrowserRouter>
        <>
          <RouteBoundary>
            <ScrollToTop />
            <Switch>
              <AppRoute exact path="/" component={HomePage} layout="public" />

              <AppRoute exact path="/login" component={LoginPage} layout="public" />
              <AppRoute exact path="/register" component={RegisterPage} layout="public" />
              <AppRoute exact path="/forgot-password" component={ForgotPasswordPage} layout="public" />
              <AppRoute exact path="/validate-code" component={ValidateCodePage} layout="public" />

              <AppRoute exact path="/tours" component={ToursPage} layout="public" />
              <AppRoute exact path="/parkings" component={ParkingsPage} layout="public" />

              <AppRoute exact path="/family-sector" component={FamilySectorPage} layout="public" />

              <AppRoute exact path="/tickets" component={TicketsPage} layout="public" />
              <AppRoute exact path="/tickets/:eventId" component={EventPage} layout="public" />
              <AppRoute exact path="/tickets/:eventId/:sectorId" component={EventSeatsPage} layout="public" />

              <AppRoute
                exact
                path="/tickets-order/:orderId/:errorReason?"
                component={EventOrderPage}
                layout="private"
              />
              <AppRoute exact path="/tickets-lodge/:eventId" component={EventLodgesPage} layout="public" />

              <AppRoute
                exact
                path="/create-event-order/:eventId/:qty?"
                component={CreateEventOrderPage}
                layout="public"
              />

              <AppRoute exact path="/membership" component={MembershipPage} layout="public" />

              <AppRoute exact path="/cart" component={CartPage} layout="public" />
              <AppRoute exact path="/loyalty" component={LoyaltyCataloguePage} layout="public" />
              <AppRoute exact path="/loyalty/:itemId" component={LoyaltyItemPage} layout="public" />
              <AppRoute exact path="/loyalty-archive" component={LoyaltyArchivePage} layout="private" />

              <AppRoute exact path="/match-rules" component={MatchRulesPage} layout="public" />
              <AppRoute exact path="/policy" component={PolicyPage} layout="public" />
              <AppRoute exact path="/match-policy" component={MatchPolicy} layout="public" />

              <AppRoute exact path="/event-orders-archive" component={EventOrdersArchive} layout="private" />

              <AppRoute exact path="/profile" component={ProfilePage} layout="private" />

              <AppRoute
                exact
                path="/failPayment/:orderId?/:errorReason?"
                component={PaymentFailPage}
                layout="private"
              />
              <AppRoute exact path="/successPayment/:orderId?" component={SuccessPaymentPage} layout="private" />
              <AppRoute exact path="/auth/callback" component={TokenAuth} layout="public" />
              <AppRoute exact path="/confirm-email" component={ConfirmEmail} layout="public" />
              <ExternalRoute exact path="/unsubscribe/success" component={Unsubscribe} />
              <ExternalRoute exact path="/unsubscribe/fail" component={UnsubscribeFail} />
              <AppRoute component={NotFoundPage} layout="public" />
            </Switch>
          </RouteBoundary>
        </>
      </BrowserRouter>
    );
  }
}

export default Routes;
