import React from 'react';
import styled, { useTheme } from 'styled-components';

interface IProps {
  color?: string;
  direction: 'left' | 'right' | 'up' | 'down';
}

const getRotation = (direction: IProps['direction']) => {
  if (direction === 'left') {
    return '0deg';
  }
  if (direction === 'right') {
    return '180deg';
  }
  if (direction === 'up') {
    return '90deg';
  }
  if (direction === 'down') {
    return '270deg';
  }
  return '0deg';
};

const Svg = styled.svg<{ $direction: IProps['direction'] }>`
  transform: rotate(${p => getRotation(p.$direction)});
`;

const ArrowIcon: React.FC<IProps> = props => {
  const theme = useTheme();
  const color = props.color || theme.colors.secondary1;

  return (
    <Svg
      $direction={props.direction}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M9 7L4 12L9 17V7Z" fill={`${color}`} />
      <path d="M5 12H19" stroke={`${color}`} />
    </Svg>
  );
};

export default React.memo(ArrowIcon);
