import React, { BaseSyntheticEvent, useCallback, useContext, useMemo } from 'react';
import { Loader } from 'react-feather';
import { useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components';
import { ACTIVE_LANGUAGES } from '../../lib/constants';
import {
  GET_BOX_SHADOW_2,
  GET_COLOR_CONTRAST_1,
  GET_COLOR_MAIN_2,
  GET_INTERACTIONS_OPACITY,
} from '../../lib/themeGetters';
import { LocaleISO } from '../../types/local';
import { I18nContext } from '../I18nProvider/I18nProvider';
import Popper from '../Popper/Popper';

interface IProps {
  contentClassName?: string;
}

const ToggleWrapper = styled.div<{ $isLoading?: boolean }>`
  cursor: ${p => (p.$isLoading ? 'not-allowed' : 'pointer')};
  opacity: ${p => (p.$isLoading ? GET_INTERACTIONS_OPACITY(p) : 1)};
`;

const PopoverContent = styled.div`
  background-color: ${GET_COLOR_MAIN_2};
  border-radius: 8px;
  border-width: 0px;
  box-shadow: ${GET_BOX_SHADOW_2};
`;

const LangImg = styled.img`
  min-width: 24px;
  min-height: 25px;
`;

const LangLabel = styled.span`
  color: ${GET_COLOR_CONTRAST_1};
`;

const getImageSrc = (lng: LocaleISO) => {
  if (lng === 'ru') {
    return '/images/icons/ruFlagIcon.png';
  }
  if (lng === 'en') {
    return '/images/icons/enFlagIcon.png';
  }
  return '/images/icons/ruFlagIcon.png';
};

const HeaderLangToggle: React.FC<IProps> = props => {
  const { contentClassName } = props;
  const { t } = useTranslation();
  const theme = useTheme();

  const { isExternalTranslationsLoading, activeLanguage, changeLanguage } = useContext(I18nContext);
  const imageSrc = useMemo(() => getImageSrc(activeLanguage), [activeLanguage]);

  const handleChangeLanguage = useCallback(
    (lng: LocaleISO) => () => {
      if (isExternalTranslationsLoading || lng === activeLanguage) {
        return;
      }
      changeLanguage(lng);
    },
    [activeLanguage, changeLanguage, isExternalTranslationsLoading]
  );

  const getLangLabel = useCallback(
    (lng: LocaleISO) => {
      if (lng === 'ru') {
        return t('label_lang_ru');
      }
      if (lng === 'en') {
        return t('label_lang_en');
      }
    },
    [t]
  );

  const renderItem = useCallback(
    (lng: LocaleISO, index: number) => {
      const isFirst = index === 0;
      return (
        <button
          key={lng}
          onClick={handleChangeLanguage(lng)}
          className={`button p-2 btn d-flex align-items-center justify-content-start ${isFirst ? '' : 'mt-2'}`}
        >
          <LangImg alt={t(`toggle_lang_${lng}`)} src={getImageSrc(lng)} />
          <LangLabel className="ml-3">{getLangLabel(lng)}</LangLabel>
        </button>
      );
    },
    [getLangLabel, handleChangeLanguage, t]
  );

  const renderPopoverBody = useCallback(
    () => <div className="d-flex flex-column">{ACTIVE_LANGUAGES.map(renderItem)}</div>,
    [renderItem]
  );

  const renderSpinner = useCallback(() => {
    if (!isExternalTranslationsLoading) {
      return null;
    }

    const size = theme.text.fontBase;

    return <Loader color={GET_COLOR_CONTRAST_1({ theme })} size={size} className="mr-2" />;
  }, [isExternalTranslationsLoading, theme]);

  const handleToggleClick = useCallback((evt: BaseSyntheticEvent<MouseEvent>) => {
    if (evt) {
      evt.preventDefault();
      evt.stopPropagation();
    }
  }, []);

  return (
    <>
      <ToggleWrapper $isLoading={isExternalTranslationsLoading}>
        <Popper
          buttonContent={
            <div className="p-2 d-flex">
              {renderSpinner()}
              <LangImg alt={t('toggle_lang')} src={imageSrc} />
            </div>
          }
          content={
            <PopoverContent className={contentClassName} onClick={handleToggleClick}>
              {renderPopoverBody()}
            </PopoverContent>
          }
        />
      </ToggleWrapper>
    </>
  );
};

export default React.memo(HeaderLangToggle);
