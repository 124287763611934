import React, { useContext } from 'react';
import { Redirect, Route, RouteProps } from 'react-router';
import { LayoutContext } from '../LayoutProvider/LayoutProvider';
import { AuthContext } from '../AuthProvider/AuthProvider';

interface IExternalProps extends RouteProps {
  layout?: 'private' | 'public';
}

interface IProps extends IExternalProps {}

const AppRoute: React.FC<IProps> = props => {
  const layoutContext = useContext(LayoutContext);
  const authContext = useContext(AuthContext);

  const layoutType: IProps['layout'] = props.layout || 'public';
  const LayoutComponent = layoutContext[layoutType];

  if (layoutType === 'private' && !authContext.isAuthorized) {
    return <Redirect to="/login" />;
  }

  const { layout, ...routeProps } = props;

  return (
    <LayoutComponent>
      <Route {...routeProps} />
    </LayoutComponent>
  );
};

export default AppRoute;
